import React from "react";
import { IViewsCountResponse } from "@src/hooks/queries/useViewsCount";
import { css } from "@emotion/react";
import { tr } from "date-fns/locale";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { Label } from "@src/components";

interface IUserViewsTableProps {
    data?: {
        userNo?: number;
        email?: string;
        name?: string;
        phone?: string;
        gender?: string;
        birthdate?: string;
        createdAt?: string;
        leavedAt?: string;
        partnerName?: string;
        partnerStatus?: string;
        viewCount?: number;
    }[];
    navigate: any;
}

const UserViewsCountTable = ({ data, navigate }: IUserViewsTableProps) => {
    return (
        <div css={tableRootStyle}>
            <table>
                <colgroup>
                    <col width="5%" />
                    <col width="8%" />
                    <col width="10%" />
                    <col width="17%" />
                    <col width="9%" />
                    <col width="10%" />
                    <col width="5%" />
                    <col width="12%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="6%" />
                </colgroup>
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>이름</th>
                        <th>연락처</th>
                        <th>이메일</th>
                        <th>물건열람횟수</th>
                        <th>생년월일</th>
                        <th>성별</th>
                        <th>가입일시</th>
                        <th>상태</th>
                        <th>파트너 여부</th>
                        <th>더보기</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((view) => (
                        <tr key={view?.userNo}>
                            <td>
                                {view?.userNo ? view?.userNo : INVALID_VALUE}
                            </td>
                            <td>{view?.name ? view?.name : INVALID_VALUE}</td>
                            <td>{view?.phone ? view?.phone : INVALID_VALUE}</td>
                            <td>{view?.email ? view?.email : INVALID_VALUE}</td>
                            <td>
                                {view?.viewCount
                                    ? view?.viewCount
                                    : INVALID_VALUE}
                            </td>
                            <td>
                                {view?.birthdate
                                    ? view?.birthdate
                                    : INVALID_VALUE}
                            </td>
                            <td>
                                {view?.gender === "0"
                                    ? "여"
                                    : view?.gender === "1"
                                    ? "남"
                                    : INVALID_VALUE}
                            </td>
                            <td>
                                {ConvertDateTime(view?.createdAt).replace(
                                    " ",
                                    "\n",
                                )}
                            </td>
                            <td>
                                {view?.leavedAt ? (
                                    <Label color={"black"} text={"탈퇴"} />
                                ) : (
                                    <Label color={"purple"} text={"정상"} />
                                )}
                            </td>
                            <td>
                                {view?.partnerStatus !== null
                                    ? view?.partnerStatus === "cancel"
                                        ? `${view?.partnerName}(취소)`
                                        : view?.partnerStatus === "deleted"
                                        ? `${view?.partnerName}(삭제)`
                                        : view?.partnerStatus === "leaved"
                                        ? `${view?.partnerName}(탈퇴)`
                                        : `${view?.partnerName}`
                                    : "미입력"}
                            </td>
                            <td
                                onClick={() => {
                                    navigate(`/users/detail/${view?.userNo}`);
                                }}
                            >
                                {">"}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserViewsCountTable;

const tableRootStyle = css`
    margin-top: 18px;
    width: 100%;
    height: 470px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 2px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
    table {
        width: 100%;
        thead {
            tr {
                position: sticky;
                top: 0;
                background-color: #ffffff;
            }
            th {
                padding: 10px;
            }
        }
        tr {
            border-bottom: 1px solid #b5b5b5;
        }
        tbody {
            vertical-align: middle;
            border-bottom: 1px solid #b5b5b5;
            td {
                padding: 8px;
                text-align: center;
                text-overflow: ellipsis;
                cursor: pointer;
                max-width: 200px;
                word-break: break-all;
                white-space: pre-wrap;
            }
        }
    }
`;
