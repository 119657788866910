import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { DateRangeCalendarType } from "@src/components/calendar/DateRangeCalendar";
import {
    chartSampleMember,
    chartSampleMemberSum,
    chartSampleMemberSum2,
    chartSampleVisitor,
} from "@src/data/HomeChartSampleData";
import { sub, startOfMonth } from "date-fns";
import { useQuery } from "react-query";
import { Common } from "@src/styles/Common";
import { queryKeys } from "./queryKeys";
import {
    ISalesStat,
    ISubsJoinedUserList,
    ISubsLeavedUserList,
    ISubsRefundUserList,
    ISubsUserListParams,
    IDeungiUserRes,
} from "@src/pages/user/types";

export interface ParametersType {
    basis: string;
    start_date: string;
    end_date: string;
}

export interface SalesStatType {
    basis?: any;
    cycle?: string | undefined;
    productType?: string;
    startDate: string;
    endDate: string;
}

const fetchData = async (
    params: SalesStatType,
    parse: (data: any, cycle: string) => void,
) => {
    const response = await axiosInstance.get(`${axiosPath.dailyVisitor}`, {
        params,
    });
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData?.list;

    return originData.length
        ? parse(originData, params?.cycle ?? "daily")
        : { labels: [], datasets: [] };
};

const useHomeUserChart = (
    params: SalesStatType,
    parse: (data: any, cycle: string) => any,
) => {
    return useQuery<any, Error>([queryKeys.HOME_USER_CHART, params], () =>
        fetchData(params, parse),
    );
};

const newPaidFetchData = async (
    params: SalesStatType,
    parse: (data: any, cycle: string) => any,
) => {
    const response = await axiosInstance.get(`${axiosPath.paidUser}`, {
        params,
    });
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData?.list;

    return originData.length
        ? parse(originData, params?.cycle ?? "daily")
        : { labels: [], datasets: [] };
};

const useHomeUserPaidChart = (
    params: SalesStatType,
    parse: (data: any, cycle: string) => {},
) => {
    return useQuery<any, Error>([queryKeys.HOME_PAID_USER_CHART, params], () =>
        newPaidFetchData(params, parse),
    );
};

const newMembersFetchData = async (
    params: SalesStatType,
    parse: (data: any, cycle: string) => any,
) => {
    const response = await axiosInstance.get(`${axiosPath.newMember}`, {
        params,
    });
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData?.list;

    return originData.length
        ? parse(originData, params?.cycle ?? "daily")
        : { labels: [], datasets: [] };
};

const useHomeNewMembersChart = (
    params: SalesStatType,
    parse: (data: any, cycle: string) => {},
) => {
    return useQuery<any, Error>(
        [queryKeys.HOME_NEW_MEMBERS_CHART, params],
        () => newMembersFetchData(params, parse),
    );
};

const paymentStatFetchData = async (
    params: SalesStatType,
    productType: string,
    parse: (data: any, cycle: string) => any,
) => {
    const response = await axiosInstance.get(
        `${axiosPath.paymentStat}/${productType}`,
        {
            params,
        },
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData?.list;

    return originData.length
        ? parse(originData, params?.cycle ?? "monthly")
        : { labels: [], datasets: [] };
};

const useHomePaymentStatChart = (
    params: SalesStatType,
    productType: string,
    parse: (data: any, cycle: string) => {},
) => {
    return useQuery<any, Error>(
        [queryKeys.HOME_PAYMENT_STAT_CHART, params, productType, parse],
        () => paymentStatFetchData(params, productType, parse),
    );
};

const itemSaleFetchData = async (
    params: SalesStatType,
    productType: string,
) => {
    const response = await axiosInstance.get(
        `${axiosPath.saleStatus}/${productType}`,
        // `${axiosPath.saleStatus}/campus-register`,
        {
            params,
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData.productList;

    return originData;
};

const useHomeItemSaleStatsData = (
    params: SalesStatType,
    productType: string,
) => {
    return useQuery<ISalesStat[]>(
        [queryKeys.HOME_ITEM_SALES_CHART, params, productType],
        () => itemSaleFetchData(params, productType),
    );
};

const itemRefundFetchData = async (
    params: SalesStatType,
    productType: string,
    parse: (data: any, cycle: string) => any,
) => {
    const response = await axiosInstance.get(
        `${axiosPath.refundStatus}/${productType}`,
        {
            params,
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData.list;

    return originData.length
        ? parse(originData, params?.cycle ?? "daily")
        : { labels: [], datasets: [] };
};

const useHomeItemRefundStatsData = (
    params: SalesStatType,
    productType: string,
    parse: (data: any, cycle: string) => any,
) => {
    return useQuery<any, Error>(
        [queryKeys.HOME_ITEM_REFUND_CHART, params, productType, parse],
        () => itemRefundFetchData(params, productType, parse),
    );
};

const subsOsFetchData = async () => {
    const response = await axiosInstance.get(`${axiosPath.subsOsPercents}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;

    return responseData;
};

const useHomeSubsOs = () => {
    return useQuery<any, Error>([queryKeys.HOME_SUBS_OS_CHART], () =>
        subsOsFetchData(),
    );
};

const today = new Date();
const initTime: {
    day: DateRangeCalendarType;
    week: DateRangeCalendarType;
    month: () => DateRangeCalendarType;
} = {
    day: {
        startDate: sub(today, { days: 7 }),
        endDate: today,
        key: "selection",
    },
    week: {
        startDate: sub(today, { months: 1 }),
        endDate: today,
        key: "selection",
    },
    month: () => {
        let months = today.getMonth() + 1;
        let year = months === 1 ? today.getFullYear() - 1 : today.getFullYear();
        return {
            startDate: new Date(year, 0, 1),
            endDate: today,
            key: "selection",
        };
    },
};

const initTimeTerm: {
    day: DateRangeCalendarType;
    week: DateRangeCalendarType;
    month: DateRangeCalendarType;
} = {
    day: {
        startDate: sub(today, { days: 0 }),
        endDate: today,
        key: "selection",
    },
    week: {
        startDate: sub(today, { days: 7 }),
        endDate: today,
        key: "selection",
    },
    month: {
        startDate: startOfMonth(today),
        endDate: today,
        key: "selection",
    },
};

const paymentInitTime: {
    day: DateRangeCalendarType;
    week: DateRangeCalendarType;
    month: DateRangeCalendarType;
} = {
    day: {
        startDate: startOfMonth(today),
        endDate: today,
        key: "selection",
    },
    week: {
        startDate: startOfMonth(sub(today, { months: 3 })),
        endDate: today,
        key: "selection",
    },
    month: {
        startDate: startOfMonth(sub(today, { months: 6 })),
        endDate: today,
        key: "selection",
    },
};
const basicInitTime: {
    month: DateRangeCalendarType;
} = {
    month: {
        startDate: sub(today, { months: 1 }),
        endDate: today,
        key: "selection",
    },
};

//* 이탈 회원관리
const useFindAllSubsLeavedUser = (params: ISubsUserListParams) => {
    return useQuery<ISubsLeavedUserList>(
        ["subsleavedList", params],
        async () => {
            const result = await axiosInstance
                .get(axiosPath.subsLeaved, {
                    params,
                })
                .then((res) => {
                    return res?.data ?? [];
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled: false },
    );
};

//* 가입 회원관리
const useFindAllSubsJoinedUser = (params: ISubsUserListParams) => {
    return useQuery<ISubsJoinedUserList>(
        ["subsJoinedList", params],
        async () => {
            const result = await axiosInstance
                .get(`${axiosPath.dashboardPath}/subsUserList`, {
                    params,
                })
                .then((res) => {
                    return res?.data ?? [];
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled: false },
    );
};

//* 환불 회원관리
const useFindAllSubsRefundUser = (params: ISubsUserListParams) => {
    return useQuery<ISubsRefundUserList>(
        ["subsJoinedList", params],
        async () => {
            const newPath = `${axiosPath.dashboardPath}/refundDetailList/${params.productType}`;
            const newParams = { ...params };
            delete newParams?.productType;
            const result = await axiosInstance
                .get(
                    `${axiosPath.dashboardPath}/refundDetailList/${params.productType}`,
                    {
                        params: newParams,
                    },
                )
                .then((res) => {
                    return res?.data ?? [];
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled: false },
    );
};

//* 등기열람 회원관리
const useFindAllDeungiListUser = (params: ISubsUserListParams) => {
    return useQuery<IDeungiUserRes>(
        ["deungiUserList", params],
        async () => {
            const result = await axiosInstance
                .get(`${axiosPath.dashboardPath}/deungiViewList`, {
                    params,
                })
                .then((res) => {
                    return res?.data ?? [];
                })
                .catch((err) => {
                    console.log("err>>", err);
                    return err;
                });
            return result;
        },
        { enabled: false },
    );
};

export {
    useHomeUserChart,
    useHomeItemSaleStatsData,
    useHomeSubsOs,
    useHomeUserPaidChart,
    useHomeNewMembersChart,
    useHomeItemRefundStatsData,
    useHomePaymentStatChart,
    initTime,
    initTimeTerm,
    paymentInitTime,
    basicInitTime,
    useFindAllSubsLeavedUser,
    useFindAllSubsJoinedUser,
    useFindAllSubsRefundUser,
    useFindAllDeungiListUser,
};
