import { Button, IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { useUpdateDeungiState } from "@src/hooks/queries/useDeungi";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { SettlementStatus, UserStatus } from "@src/constants/UserStatus";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { PartnerLabelState } from "@src/constants/PartnerStatus";

export const getColumns = (navigate: any, setPaymentListParams: any) => [
    {
        id: "1",
        Header: "정산번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => <div>{value ? value : INVALID_VALUE}</div>,
    },
    {
        id: "2",
        Header: "정산월",
        accessor: "settlementMonth",
        Cell: ({ cell: { value } }: any) => <div>{value ? value : INVALID_VALUE}</div>,
    },
    {
        id: "3",
        Header: "이름",
        accessor: "name",
        Cell: ({ cell: { value }, row }: any) => {
            const name = row.original.uname;
            const id = row.original.no;
            return (
                <div
                    onClick={() => {
                        navigate(`/users/detail/${id}`);
                    }}
                >
                    {name ? name : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        id: "4",
        Header: "닉네임",
        accessor: "partnerNickname",
        Cell: ({ cell: { value } }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "5",
        Header: "연락처",
        accessor: "phone",
        Cell: ({ cell: { value }, row }: any) => {
            const phone = row.original.phone;
            return (
                <div>
                    {!phone
                        ? INVALID_VALUE
                        : phone.length == 11
                        ? phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        : phone}
                </div>
            );
        },
    },
    {
        id: "6",
        Header: `당월 보상대상\n회원수`,
        accessor: "targetCount",
        Cell: ({ cell: { value }, row }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "7",
        Header: "등급",
        accessor: "level",
        Cell: ({ cell: { value }, row }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "8",
        Header: "계산값",
        accessor: "factor",
        Cell: ({ cell: { value }, row }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "9",
        Header: `정산 전\n결제취소액`,
        accessor: "cancelAmountBefore",
        Cell: ({ cell: { value } }: any) => <div>{value ? value : INVALID_VALUE}</div>,
    },
    {
        id: "10",
        Header: `정산 후\n결제취소액`,
        accessor: "cancelAmountAfter",
        Cell: ({ cell: { value, row } }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "11",
        Header: "순결제액",
        accessor: "paymentAmount",
        Cell: ({ cell: { value, row } }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "12",
        Header: "월정산 지급액",
        accessor: "monthlyPayouts",
        Cell: ({ cell: { value, row } }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "13",
        Header: "월정산일시",
        accessor: "settlementDate",
        Cell: ({ cell: { value, row } }: any) => {
            return <div>{value}</div>;
        },
    },
    {
        id: "14",
        Header: "결제내역",
        accessor: "no",
        Cell: ({ cell: { value, row } }: any) => {
            const no = row.original.no;
            const nickname = row.original.partnerNickname;
            const arraySettlement = row.original.settlementMonth?.split(" ");
            const year = arraySettlement?.[0]?.replaceAll("년", "")?.trim();
            const month = arraySettlement?.[1]?.replaceAll("월", "")?.trim();
            return (
                <div
                    onClick={() => {
                        setPaymentListParams({
                            page: 1,
                            limit: 10000,
                            partnerNo: no,
                            paidYear: year,
                            paidMonth: month,
                            nickname,
                        });
                    }}
                >
                    <Label color="lightGray" text="리스트" />
                </div>
            );
        },
    },
    {
        id: "15",
        Header: "정산상태",
        accessor: "settlementStatus",
        Cell: ({ cell: { value, row } }: any) => {
            const [text, color] = SettlementStatus[value];
            return (
                <div>
                    <Label color={color} text={text} />
                </div>
            );
        },
    },
    {
        id: "16",
        Header: "더보기",
        accessor: "no",
        Cell: ({ cell: { value, row } }: any) => {
            const no = row.original.no;
            return (
                <div>
                    <IconButton
                        type="arrow"
                        handleClick={() => {
                            navigate(`/partner/detail/${no}`, {
                                state: {
                                    no: no,
                                },
                            });
                        }}
                    />
                </div>
            );
        },
    },
];
