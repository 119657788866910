import { css } from "@emotion/react";
import { Button, ConfirmModal } from "@src/components";
import {
    useCouponFetchData,
    useUpdateCoupon,
} from "@src/hooks/queries/useCoupon";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CouponForm from "./CouponForm";

interface Props {}

const INIT_VALUE = {
    name: "",
    description: "",
    code_type: "",
    product_code: "",
    reg_start: "",
    reg_end: "",
    no_time_limit: "0",
    issued_count: "0",
    comments: "",
};

const CouponUpdate: React.FC<Props> = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [formContent, setFormContent] = useState<any>(INIT_VALUE);
    const updateCoupon = useUpdateCoupon(id ?? "", () => {
        setOpenDialog(false);
        navigate("/coupon/list", { replace: true });
    });
    const onSubmitUpdateCoupon = () => {
        const param = {
            name: formContent.name,
            description: formContent.description,
            comments: formContent.comments,
        };
        updateCoupon.mutate(param);
    };
    const { data, refetch } = useCouponFetchData(id ?? "");

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (data?.no) {
            const {
                name,
                description,
                code_type,
                reg_start,
                reg_end,
                no_time_limit,
                issued_count,
                status,
                comments,
                product_code,
                dupl_restrict,
            } = data;
            const newStart = reg_start
                ? reg_start.split(" ")[0].replaceAll("-", ".")
                : "";
            const newEnd = reg_end
                ? reg_end.split(" ")[0].replaceAll("-", ".")
                : "";
            setFormContent({
                name,
                description,
                code_type,
                reg_start: newStart,
                reg_end: newEnd,
                no_time_limit,
                issued_count,
                status,
                comments,
                product_code,
            });
        }
    }, [data]);

    return (
        <div css={createRootStyle}>
            <CouponForm
                title="쿠폰 조회 및 수정"
                content={formContent}
                setContent={setFormContent}
                isUpdate={true}
            />
            <div css={submitBtnRoot}>
                <Button
                    color="gd"
                    handleClick={() => navigate(-1)}
                    label="취소"
                    size="medium"
                    isDisabled={!formContent.name || !formContent.description}
                />
                <Button
                    color="gd"
                    handleClick={() => setOpenDialog(true)}
                    label="변경"
                    size="medium"
                    isDisabled={!formContent.name || !formContent.description}
                />
            </div>
            <ConfirmModal
                isModalShow={openDialog}
                buttonText="확인"
                handleCancel={() => setOpenDialog(false)}
                handleOk={onSubmitUpdateCoupon}
            >
                수정하시겠습니까?
            </ConfirmModal>
        </div>
    );
};

const createRootStyle = css`
    padding: 40px;
    width: 70%;
`;
const submitBtnRoot = css`
    display: flex;
    justify-content: center;
    margin: 3rem 0;
    & > Button:nth-of-type(1) {
        margin-right: 30px;
    }
`;

export default CouponUpdate;
