import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { NavLink, useLocation } from "react-router-dom";
import { Common } from "@src/styles/Common";
import SideMenuList from "./SideMenuList";
import LoginUser from "@src/layout/LoginUser";
import settingIcon from "@src/assets/settingIcon.png";
import activeSettingIcon from "@src/assets/activeSettingIcon.png";
import Logo from "@src/assets/logo_gd.png";
import inactiveIcon from "@src/assets/sideMenu_unactive.png";
import activeIcon from "@src/assets/sideMenu_active.png";

function SideMenu() {
    const getTitle =
        window?.location.host.indexOf("localhost") >= 0
            ? "LOCAL"
            : window?.location.host.indexOf("dev") >= 0
            ? "DEV"
            : window?.location.host.indexOf("sadmincho") >= 0
            ? "STAGE"
            : "";
    const { pathname } = useLocation();
    return (
        <>
            {!pathname.includes("/coupon/detail") && (
                <div css={GnbDiv} className="GnbDiv">
                    <h2 css={LogoH2} className="logo">
                        <NavLink to="/home">
                            <img src={Logo} alt="미스고" />
                        </NavLink>
                        <div className="text">{getTitle}</div>
                    </h2>
                    <LoginUser />
                    <ul css={userList}>
                        <li>
                            <NavLink
                                to="/myPage/list"
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            >
                                마이페이지
                            </NavLink>
                        </li>
                    </ul>
                    <SideMenuList />
                </div>
            )}
        </>
    );
}

SideMenu.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
};

const GnbDiv = css`
    min-width: 235px;
    height: 100vh;
    background: ${Common.colors.gray100};
    padding: 40px 30px 0;
    position: fixed;
    left: 0;
    top: 0;
`;

const LogoH2 = css`
    width: 82px;
    height: auto;
    margin-bottom: 20px;
    img {
        display: block;
        width: 100%;
    }
    & > .text {
        position: absolute;
        font-size: 34px;
        right: 50px;
    }
`;
const userList = css`
    border-bottom: 1.5px solid ${Common.colors.white};
    padding-bottom: 11px;
    margin-top: unset;
    li {
        ${Common.textStyle.title6_R14}
        a {
            display: block;
            width: 100%;
            height: 100%;
            text-indent: 20px;
            background: url(${settingIcon}) no-repeat left center,
                url(${inactiveIcon}) no-repeat right center;
            background-size: 13.33px 13.33px, 7px 11px;
        }
        a.active {
            color: ${Common.colors.focus};
            background: url(${activeSettingIcon}) no-repeat left center,
                url(${activeIcon}) no-repeat right center;
            background-size: 13.33px 13.33px, 11px 7px;
        }
    }
`;
export default SideMenu;
