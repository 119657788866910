import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { BaseUser, useUser, ListUserProps, FilterUserProps, ReadUserListDto } from "@src/interfaces/User.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import { queryList } from "./queryList";

const fetchData = async (params: ReadUserListDto) => {
    !params.searchText && delete params.searchType;
    const newParam: any = {
        ...params,
        statuses: params?.statuses?.split(","),
        subscriptionStatuses: params?.subscriptionStatuses?.split(","),
    };
    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");
    const url = `${axiosPath.nestUsers}`;
    // const url = `${axiosPath.nestUsers}?${queryString}`;
    const response = await axiosInstance.get(url, { params: newParam });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: useUser[] = responseData.list;
    queryList.setDataList(originData);
    queryList.setCursor(responseData.cursor);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.count);
    } else {
        queryList.setTotalCount(responseData.count);
    }

    return queryList.getData(parse);
};

const parse = (originData: useUser[]) => {
    const newData: ListUserProps[] = [];
    originData.forEach((item, index) => {
        const { no, id, status, userInfo, subscriptionType, createdAt, paybackPartnerConnection } = item;

        newData.push({
            no: index,
            userNo: no, //회원번호
            userName: userInfo.uname, //이름
            userPhoneNumber: userInfo.phone, //연락처
            userEmail: id, //이메일
            userBirthday: userInfo.birthdate, //생년월일
            userGender: userInfo.gender === "0" ? "여성" : "남성", //"0": 여성, "1": 남성
            userApplyDate: createdAt, //가입일시
            userStatus: status, //회원상태
            productName: subscriptionType,
            partnerName: paybackPartnerConnection?.userPaybackPartner?.name ?? null,
            partenrDeletedAt: paybackPartnerConnection?.userPaybackPartner?.deletedAt ?? null,
            partnerStatus: paybackPartnerConnection?.userPaybackPartner?.status ?? null,
            subscription: null, //구독상태(필터 검색 제외) 고스톱??
            viewMore: no, //더보기
        });
    });
    return newData;
};

const changeFormat = (date: string) => {
    return date.replaceAll(".", "-");
};

const getQueryString = (searchParams: FilterUserProps) => {
    let queryParams: any = {};
    const { userType, subType, startDate, endDate, searchText, searType, limit, page, q, cursor, partnerStatus } =
        searchParams;
    const createParams: ReadUserListDto = {
        statuses: userType,
        subscriptionStatuses: subType,
        joinStartAt: !startDate ? undefined : changeFormat(`${startDate}`),
        joinEndAt: !endDate ? undefined : changeFormat(`${endDate}`),
        searchText: searchText,
        searchType: searType,
        partnerStatus: partnerStatus,
        cursor: cursor,
        limit: Number(limit),
        page: Number(page),
        q,
    };

    Object.keys(createParams).forEach((value: any) => {
        let name = value as keyof ReadUserListDto;
        if (createParams[name]) {
            queryParams[name] = createParams[name];
        } else if (name === "partnerStatus") {
            queryParams[name] = createParams[name];
        }
    });
    return queryParams;
};
const useUserList = (filters: FilterUserProps, enabled: boolean = true) => {
    const queryParams = getQueryString(filters);

    if (!queryParams.searchType) queryParams.searchType = "name";
    if (!queryParams.searchText) queryParams.searchText = filters.q;

    return useQuery([queryKeys.USER_LIST, queryParams], () => fetchData(queryParams), {
        enabled,
    });
};

const TODAY = ConvertDate(new Date().getTime());

const initFilters: FilterUserProps = {
    startDate: null,
    endDate: null,
    // searType: [],
    searType: "",
    searchText: "",
    userType: "",
    subType: "",
    page: 1,
    limit: 10,
    cursor: "",
};
const dropList = [
    {
        id: 0,
        key: "name",
        value: "이름",
    },
    {
        id: 1,
        key: "phone",
        value: "연락처",
    },
    {
        id: 2,
        key: "email",
        value: "이메일",
    },
    {
        id: 3,
        key: "paybackPartnerName",
        value: "파트너",
    },
];
const usePushReceivingUserList = ({ limit, to_go }: FilterUserProps) => {
    const queryParams = { limit, to_go };
    return useQuery([queryKeys.PUSH_RECEIVING_USER_LIST, queryParams], () => fetchData(queryParams), {
        // initialData: initData,
    });
};

const useUserNoSearch = (params: {
    q: string;
    enabled: boolean;
}): {
    parseData: any[];
    isLoading: boolean;
    refetch: () => void;
    isRefetching: boolean;
} => {
    const { isLoading, data = { result: [], total: 0 }, refetch, isRefetching } = useUserList(params, params.enabled);

    const originData = data.result;
    let parseData: any[] = [];
    originData.forEach((item: any) => {
        const { userName, userEmail, userPhoneNumber, userNo } = item;
        parseData.push({
            userName,
            userEmail,
            userPhoneNumber,
            userNo,
        });
    });
    return { parseData, isLoading, refetch, isRefetching };
};

export { useUserList, initFilters, usePushReceivingUserList, getQueryString, dropList, useUserNoSearch };
