import { createSlice } from "@reduxjs/toolkit";

const caseAnalysisData = [
    {
        id: 0,
        period: "3",
        bidCount: "2",
        estPriceAverage: "",
        bidPriceAverage: "33120000",
        bidPriceRate: "50",
        skpCountAverage: "5.5",
    },
    {
        id: 1,
        period: "6",
        bidCount: "2",
        estPriceAverage: "48050000",
        bidPriceAverage: "33120000",
        bidPriceRate: "50",
        skpCountAverage: "5.5",
    },
    {
        id: 2,
        period: "12",
        bidCount: "",
        estPriceAverage: "97585714",
        bidPriceAverage: "68402714",
        bidPriceRate: "65",
        skpCountAverage: "2.9",
    },
];

const initialState = caseAnalysisData;

export const caseAnalysisSlice = createSlice({
    name: "caseAnalysis",
    initialState,
    reducers: {
        createCaseAnalysis: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateCaseAnalysis: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createCaseAnalysis, updateCaseAnalysis } =
    caseAnalysisSlice.actions;

export default caseAnalysisSlice.reducer;
