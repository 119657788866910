import styled from "@emotion/styled";
import { Button, Title } from "@src/components";
import { PartnerInfoProps } from "@src/interfaces/User.interface";
import moment from "moment";
import React, { Dispatch, SetStateAction } from "react";

interface PartnerInfoContainerProps {
    setIsPartnerModal: Dispatch<SetStateAction<boolean>>;
    partnerInfo: PartnerInfoProps;
    setIsPartnerEditModal: Dispatch<
        SetStateAction<{ isShowModal: boolean; type: string }>
    >;
}

const PartnerInfoContainer = ({
    setIsPartnerModal,
    partnerInfo,
    setIsPartnerEditModal,
}: PartnerInfoContainerProps) => {
    return (
        <>
            <Title title="파트너 입력 정보" size="small" />
            {partnerInfo.userPaybackPartner ? (
                <StyledContents>
                    <div className="itemRow">
                        <span className="name">닉네임</span>
                        <span className="text">
                            {partnerInfo.userPaybackPartner?.name
                                ? partnerInfo.userPaybackPartner?.deletedAt
                                    ? `${partnerInfo.userPaybackPartner?.name}(삭제)`
                                    : partnerInfo.userPaybackPartner?.status ===
                                      "cancel"
                                    ? `${partnerInfo.userPaybackPartner?.name}(취소)`
                                    : `${partnerInfo.userPaybackPartner?.name}`
                                : "미입력"}
                        </span>
                        <div className="btnDiv">
                            <Button
                                color="gd"
                                handleClick={() => {
                                    setIsPartnerEditModal({
                                        isShowModal: true,
                                        type: "update",
                                    });
                                }}
                                label="파트너 수정"
                                size="small"
                                styles={{
                                    width: 123,
                                    height: 23,
                                }}
                            />
                            <Button
                                color="gd"
                                handleClick={() => {
                                    setIsPartnerModal(true);
                                }}
                                label="파트너 해지"
                                size="small"
                                styles={{
                                    width: 123,
                                    height: 23,
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span className="name">유효기간</span>
                        <span className="text">
                            {partnerInfo.expiredAt &&
                                moment(partnerInfo.expiredAt).format(
                                    "YYYY-MM-DD HH:mm:ss",
                                )}
                        </span>
                    </div>
                </StyledContents>
            ) : (
                <StyledNoData>
                    <div className="noDataBox">입력된 파트너가 없습니다</div>
                    <Button
                        color="gd"
                        handleClick={() => {
                            setIsPartnerEditModal({
                                isShowModal: true,
                                type: "create",
                            });
                        }}
                        label="파트너 입력"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledNoData>
            )}
        </>
    );
};

export default PartnerInfoContainer;

const StyledContents = styled.div`
    margin-top: 20px;
    gap: 12px;
    padding-bottom: 40px;
    .itemRow {
        display: flex;
        align-items: center;
        gap: 30px;

        .btnDiv {
            display: flex;
            gap: 8px;

            box-sizing: border-box;
            & > button {
                width: 123px;
                height: 23px;
                min-height: 0px;
            }
        }
        .name {
            color: #9d9fa5;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
        }
        .text {
            color: #222222;
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
        }
    }
`;

const StyledNoData = styled.div`
    display: flex;
    gap: 10px;

    .noDataBox {
        width: 238px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f4f4;
        color: #222222;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
    }
    & > button {
        margin-top: 10px;
    }
`;
