import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    statuses: [],
    toTypes: [],
    categories: [],
    orderColumn: "common.reservationAt",
    orderType: "DESC",
    limit: 10,
    page: 1,
    searchText: "",
};

export const pushSlice = createSlice({
    name: "push",
    initialState,
    reducers: {
        createPush: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updatePush: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createPush, updatePush } = pushSlice.actions;

export default pushSlice.reducer;
