export const dropIdToText = (init: number | undefined, arr: any[]): string => {
    return arr.find((v) => v.id === init)?.value || "";
};

export interface dropDownListType {
    id: number;
    key: string;
    value: string;
}

export const arrayToDropdown = (array: string[]) => {
    const data = [];
    for (let i = 0, len = array.length; i < len; i++) {
        data.push({ id: i, key: array[i], value: array[i] });
    }

    return data;
};

export const objectToDropdown = (data: any) => {
    return Object.entries(data).map(([code, title], index) => {
        return { id: index, key: code, value: title };
    });
};

export const object2depthToDropdown = (object: any) => {
    const array = Object.entries(object);
    const data: any = [];
    array.forEach((value, i) => {
        const { name, code }: any = value[1];
        data.push({ id: i, key: code, value: name });
    });

    return data;
};

export const dropdownFormat = (data: any) => {
    return Object.entries(data).map(([code, title], index) => {
        return { id: index, key: code, value: title };
    });
};

export const timeDropdown = (maxTime: number) => {
    return new Array(maxTime).fill(0).map((_, i) => {
        return {
            id: i,
            key: i < 10 ? `0${i}` : i.toString(),
            value: i.toString(),
        };
    });
};
