import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, Title, SearchModal } from "@src/components";
import PasswordModal from "@src/pages/popup/PasswordModal";
import { useLoginDetail } from "@src/hooks/queries/useLogin";
import { useUserNoSearch } from "@src/hooks/queries/useUser";
import { useUpdateAdmin } from "@src/hooks/queries/useAdminDetail";

function MyPage() {
    const [isModalShow, setIsModalShow] = useState(false);
    const [isSearchModalShow, setIsSearchModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState<any>();
    const handleOpenModal = () => setIsModalShow((prev) => !prev);
    const email = localStorage.getItem("user");
    const {
        isLoading,
        data: adminData,
        refetch: adminRefetch,
    }: any = useLoginDetail();
    let data;
    if (!isLoading) {
        data = adminData;
    }
    const adminNo = adminData?.admin_no || 0;
    const myList = [
        {
            id: 0,
            title: "이름",
            value: adminData?.admin_name || "",
        },
        {
            id: 1,
            title: "직위",
            value: adminData?.admin_rank || "",
        },
        {
            id: 2,
            title: "연락처",
            value: adminData?.admin_phone || "",
        },
        {
            id: 3,
            title: "이메일",
            value: email || "",
        },
    ];

    const headers = ["이름", "이메일", "연락처", "회원번호"];
    const [list, setList] = useState<any[]>([]);
    const [params, setParams] = useState<{
        q: string;
        page: 1;
        limit: 100;
        enabled: boolean;
    }>({
        q: "",
        page: 1,
        limit: 100,
        enabled: false,
    });
    const {
        parseData,
        isLoading: searchLoading,
        refetch: searchRefetch,
        isRefetching: searchRefetching,
    } = useUserNoSearch(params);

    useEffect(() => {
        if (searchLoading) {
            setParams((prevState: any) => ({
                ...prevState,
                ...{ enabled: false },
            }));
        } else {
            setList(parseData);
        }
    }, [searchLoading]);
    useEffect(() => {
        searchRefetching && setList(parseData);
    }, [searchRefetching]);

    const handleSearch = (value?: string) => {
        value === undefined && (value = "");
        setParams({ q: value, page: 1, limit: 100, enabled: true });
        searchRefetch();
    };

    const { mutate: updateMutate } = useUpdateAdmin(adminNo);

    const handleSave = (item: any) => {
        let params = {
            user_no: item.userNo,
        };
        updateMutate(params, {
            onSuccess: () => {
                setIsSearchModalShow(!isSearchModalShow);
                setErrorMessage("");
                adminRefetch();
            },
            onError: (error: any) => {
                setErrorMessage(error.toString());
            },
        });
    };
    useEffect(() => {
        isSearchModalShow || setErrorMessage(""); //검색 모달 팝업 닫히면 에러메세지 초기화
    }, [isSearchModalShow]);
    return (
        <div css={myPageStyle}>
            <Title title="마이페이지" />
            <ul css={myPageUl}>
                {myList.map((item) => {
                    return (
                        <li key={item.id}>
                            <span>{item.title}</span>
                            <span>{item.value}</span>
                        </li>
                    );
                })}

                <li>
                    <span>회원번호</span>
                    <span>{data?.user_no || 0}</span>
                    <span>
                        <Button
                            color="gray"
                            label="회원번호 변경"
                            size="xSmall"
                            handleClick={() =>
                                setIsSearchModalShow(!isSearchModalShow)
                            }
                        />
                        <SearchModal
                            isModalShow={isSearchModalShow}
                            title="회원번호"
                            buttonText="저장"
                            placeholder={"이름을 입력하세요."}
                            isLoading={searchLoading}
                            list={list}
                            headers={headers}
                            handleOpenModal={() =>
                                setIsSearchModalShow(!isSearchModalShow)
                            }
                            handleSave={handleSave}
                            handleSearch={handleSearch}
                            errorMessage={errorMessage}
                        />
                    </span>
                </li>
                <li>
                    <span>비밀번호</span>
                    <Button
                        color="gray"
                        label="비밀번호 변경"
                        size="xSmall"
                        handleClick={handleOpenModal}
                    />
                    <PasswordModal
                        isModalShow={isModalShow}
                        handleOpenModal={handleOpenModal}
                        title="비밀번호 등록"
                        buttonText="등록"
                    />
                </li>
            </ul>
        </div>
    );
}

const myPageStyle = css`
    padding: 50px;
    width: 100%;
    height: 100%;
    .title {
        ${Common.textStyle.title2_M16}
    }
`;

const myPageUl = css`
    margin-top: 32px;
    li {
        margin-bottom: 29px;
        span {
            display: inline-block;
            ${Common.textStyle.title6_R14}
        }
        span {
            width: 100px;
        }
    }
    Button {
        display: inline-block;
    }
    .passwordModal span {
        width: 120px;
    }
`;

export default MyPage;
