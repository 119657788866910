export const BannerView = [
    {
        id: 1,
        key: 0,
        value: "미노출",
    },
    {
        id: 2,
        key: 1,
        value: "노출",
    },
    {
        id: 3,
        key: 2,
        value: "테스터노출",
    },
];
