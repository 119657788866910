import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { ItemInformationProps } from "@src/interfaces/product/ItemInformationContainer.interface";
import { sampleData } from "@src/data/ItemInfoContainerSampleData";

const convertValidString = (string: any) => {
    return string ? string : "-";
};
const getBuildingApi = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.buildingInfo}/${productNo}`,
    );

    const responseData = response.data;
    const originData = responseData.data.data;
    if (originData) {
        Object.keys(originData).map((key) => {
            originData[key] = convertValidString(originData[key]);
        });
    }
    return originData;
};
const getLandApi = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.landInfo}/${productNo}`,
    );

    const responseData = response.data;
    const originData = responseData.data.data;
    if (originData) {
        Object.keys(originData).map((key) => {
            originData[key] = convertValidString(originData[key]);
        });
    }
    return originData;
};
const fetchData = async (productNo: any) => {
    const building = await getBuildingApi(productNo);
    const land = await getLandApi(productNo);

    return parse({ building, land });
};

const parse = ({ building, land }: any) => {
    const {
        buldNm,
        agbldgSeCodeNm,
        mainPrposCodeNm,
        detailPrposCodeNm,
        useConfmDe,
        buldTotar,
        groundFloorCo,
        undgrndFloorCo,
        measrmtRt,
        btlRt,
        buldBildngAr,
        buldPlotAr,
    } = building;
    const {
        ldCodeNm,
        regstrSeCodeNm,
        lndpclAr,
        lndcgrCodeNm,
        prposArea1Nm,
        prposArea2Nm,
        pblntfPclnd,
        lastUpdtDt,
        ladUseSittnNm,
        posesnSeCodeNm,
        ownshipChgDe,
        ownshipChgCauseCodeNm,
        tpgrphHgCodeNm,
        tpgrphFrmCodeNm,
        roadSideCodeNm,
    } = land;

    let addr = `${regstrSeCodeNm}/${ldCodeNm}`;
    let specialPur = `${prposArea1Nm}/${prposArea2Nm}`;

    return {
        buildingName: buldNm,
        type: agbldgSeCodeNm,
        highFloor: groundFloorCo,
        rowFloor: undgrndFloorCo,
        floorAreaRatio: measrmtRt,
        buildingToLandRatio: btlRt,
        mainUse: mainPrposCodeNm,
        detailUse: detailPrposCodeNm,
        approveDate: useConfmDe,
        buildingArea: buldBildngAr,
        buildingLandArea: buldPlotAr,
        totalFloorArea: buldTotar,
        address: addr,
        ownerType: posesnSeCodeNm,
        landArea: lndpclAr,
        landName: lndcgrCodeNm,
        ownerUpdateDate: ownshipChgDe,
        ownerUpdateReason: ownshipChgCauseCodeNm,
        specialPurposeArea: specialPur,
        landHigh: tpgrphHgCodeNm,
        officialPrice: pblntfPclnd,
        landShape: tpgrphFrmCodeNm,
        dataDate: lastUpdtDt,
        road: roadSideCodeNm,
        currentLandUse: ladUseSittnNm,
    };
};
const useGetItemInformation = (productNo: any) => {
    return useQuery(
        [queryKeys.ITEM_INFORMATION, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};
export { useGetItemInformation };
