import React, { useEffect, useState, ChangeEvent } from "react";
import "@src/styles/CommonTable.css";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input } from "@src/components";
import { validNumberCheck } from "@src/utils/validationCheck";
import { useSelector } from "react-redux";
import { resultPrice } from "@src/utils/textUtil";
import { useGetExpectedReturn } from "@src/hooks/queries/product/useExpectedReturn";

interface IExpectedReturnContainer {
    isEdit: boolean;
    handleUpdate?: Function;
    productNo: string;
    isUpdating: boolean;
}
function ExpectedReturnContainer({
    isEdit = false,
    handleUpdate,
    productNo,
}: IExpectedReturnContainer) {
    // const PRODUCT_NO = productNo;
    const PRODUCT_NO = "20210130107993-1";
    const headerMeta = ["무주택", "1주택", "다주택"];
    const [tableData, setTableData] = useState<any[]>([]);
    const { isLoading, isError, data } = useGetExpectedReturn(PRODUCT_NO);
    useEffect(() => {
        if (!isLoading && !isError) {
            setTableData(data);
        }
    }, [isLoading, isError, data]);

    return (
        <div>
            {tableData !== null && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            <th></th>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data: any) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isEdit={isEdit}
                                    handleChange={() => {}}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
            {tableData === null && (
                <table className="commonTable" css={noData}>
                    <thead>
                        <tr>
                            <th></th>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={4}>데이터가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
}
const TableRow = ({ data, isEdit, handleChange }: any) => {
    const [rowData, setRowData] = useState(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const { none, one, many, title } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let [validCheckResult, validCheckValue] = validNumberCheck(value);
        if (validCheckResult) {
            setRowData({
                ...rowData,
                [name]: validCheckValue,
            });
        }
    };
    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);
    return (
        <tr key={data.id} style={isEdit ? { borderBottom: "none" } : undefined}>
            <td>
                <span className="subTitle">{title}</span>
            </td>
            <td>
                <Input
                    name="none"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isEdit ? none : resultPrice(none)}
                />
            </td>
            <td>
                <Input
                    name="one"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isEdit ? one : resultPrice(one)}
                />
            </td>
            <td>
                <Input
                    name="many"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isEdit ? many : resultPrice(many)}
                />
            </td>
        </tr>
    );
};

const noData = css`
    td {
        text-align: center;
        height: 200px;
        vertical-align: middle;
        ${Common.textStyle.text1_R13}
    }
`;
export default ExpectedReturnContainer;
