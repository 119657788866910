import React from "react";
import { IconButton, Label } from "@src/components";
import {
    UserStatus,
    ExpertStatus,
    SubscriptionStatus,
} from "@src/constants/UserStatus";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
export const getColumns = (navigate: any) => [
    {
        Header: "번호",
        accessor: "paidNo",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ width: "100%", minWidth: "80px", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "결제일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "200px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "결제 상품",
        accessor: "productName",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "200px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "금액",
        accessor: "paidPrice",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "80px",
                    width: "100%",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "상태",
        accessor: "state",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "80px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },

    {
        Header: "더보기",
        accessor: "viewMore",
        Cell: ({ cell: { value }, row: { values } }: any) => {
            const status = values.userStatus;
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    <IconButton
                        type={"arrow"}
                        color={"black"}
                        handleClick={() => {
                            navigate("/products/public/20210130056387");
                        }}
                        isDisabled={false}
                    />
                </div>
            );
        },
    },
];
