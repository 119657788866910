import styled from "@emotion/styled";
import {
    Checkbox,
    ConfirmModal,
    EditModal,
    Input,
    Title,
} from "@src/components";
import { useUserCampusDetail } from "@src/hooks/queries/useUserCampus";
import { useRefundPGSaver } from "@src/hooks/queries/useUserDetail";
import { DeungiDetailList } from "@src/interfaces/User.interface";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
    open: boolean;
    onCloseView: () => void;
    info: any;
}

const UserSugangRefundModal: React.FC<Props> = ({
    open,
    onCloseView,
    info,
}) => {
    const { id } = useParams();
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);
    const [param, setParam] = useState<any>({
        amount: 0,
        comments: "",
        userNo: id,
    });
    const { data, refetch } = useUserCampusDetail(info?.registerNo);
    const refundSaver = useRefundPGSaver(() => {
        setIsShowConfirmModal(false);
        onCloseView();
    });

    const onClickRefund = () => {
        refundSaver.mutate(param);
    };

    useEffect(() => {
        if (open && info?.orderNo) {
            refetch();
            setParam({ ...param, orderNo: info.orderNo });
        }
    }, [open, info]);

    return (
        <>
            <EditModal
                isModalShow={open}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowConfirmModal(true);
                }}
                handleOpenModal={onCloseView}
                size="big"
                title="수강강의 환불"
                checkCloseModal={true}
                styles={{ backgroundColor: "#ECEFF1 !important" }}
            >
                <StyledWrap>
                    <StyledContens>
                        <div className="row">
                            <div>
                                강의명 <span>{data?.title}</span>
                            </div>
                            <div>
                                결제액{" "}
                                <span>{data?.paidPrice.toLocaleString()}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                총 재생시간<span>{data?.totalViewTime}</span>
                            </div>
                        </div>
                    </StyledContens>
                    <StyledRefundInfo>
                        <Title title="환불정보" />
                        <div className="row">
                            <div>
                                <span className="star">*</span>환불예정액{" "}
                                <Input
                                    errorMessage=""
                                    handleChange={(e) => {
                                        const onlyNumber =
                                            e.target.value.replace(
                                                /[^0-9]/g,
                                                "",
                                            );
                                        setParam({
                                            ...param,
                                            amount: onlyNumber,
                                        });
                                    }}
                                    onKeyDown={function noRefCheck() {}}
                                    placeholder="금액 입력"
                                    size="medium"
                                    type="text"
                                    value={param.amount}
                                />
                                <Checkbox
                                    defaultValue=""
                                    handleChange={(key, check) => {
                                        check &&
                                            setParam({
                                                ...param,
                                                amount: data?.paidPrice,
                                            });
                                    }}
                                    label="전액환불"
                                />
                            </div>
                        </div>
                        <StyledReasonWrapper>
                            <span>
                                <span className="essential">*</span>사유
                            </span>
                            <input
                                type="text"
                                placeholder="사유를 작성해 주세요"
                                onChange={(e: any) => {
                                    setParam({
                                        ...param,
                                        comments: e.target.value,
                                    });
                                }}
                                value={param.comments}
                            />
                        </StyledReasonWrapper>
                    </StyledRefundInfo>
                </StyledWrap>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => setIsShowConfirmModal(false)}
                handleOk={onClickRefund}
            >
                저장하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default UserSugangRefundModal;

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
`;

const StyledContens = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    & > .row {
        display: flex;
        & > div {
            width: 50%;
            & > span {
                margin-left: 15%;
            }
        }
    }
`;

const StyledRefundInfo = styled.div`
    margin-bottom: 25px;
    & > .row {
        display: flex;
        margin-top: 15px;
        & > div {
            width: 50%;
            display: flex;
            align-items: center;
            & > .star {
                color: #f45b5b;
                margin-left: 0;
            }
            & > span {
                margin-left: 15%;
            }

            & > input {
                margin-left: 20px;
                width: 50%;
                margin-right: 10px;
            }
        }
    }
`;

const StyledReasonWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    width: 648px;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 296px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;
