import styled from "@emotion/styled";
import { Title } from "@src/components";
import { ConvertDateTime } from "@src/utils/dateUtil";
import React, { useEffect } from "react";

interface ItemProps {
    data: any;
}

const Item = ({ data }: ItemProps) => {
    return (
        <StyledItemWrap>
            <Title title="혜택1" />
            <div className="itemRow">
                <span className="name">적용일시</span>
                <span className="value">
                    {data.createAt ? ConvertDateTime(data.createdAt) : ""}
                </span>
            </div>
            <div className="itemRow">
                <span className="name">혜택명</span>
                <span className="value">{data.title}</span>
            </div>
            <div className="itemRow">
                <span className="name">상품연결</span>
                <table>
                    <thead>
                        <tr>
                            <th>상품명</th>
                            <th>상품코드</th>
                            <th>가격</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{data.product.name}</td>
                            <td>{data.product.code}</td>
                            <td>{data.product.term1DiscountedPrice}원</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </StyledItemWrap>
    );
};

export default Item;

const StyledItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    .itemRow {
        display: flex;
        gap: 16px;
        align-items: center;
        & > span {
            font-weight: 400;
            line-height: 18px;
            color: black;
        }
        .name {
            width: 96px;
        }
        & > table {
            width: 600px;
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            & > thead {
                border-bottom: 1px solid #b5b5b5;
                & > tr {
                    height: 32px;
                    padding: 6px 12px;
                    box-sizing: border-box;
                    & > th {
                        text-align: left;
                        vertical-align: middle;
                        font-weight: 400;
                    }
                }
            }
            & > tbody {
                & > tr {
                    height: 32px;
                    & > td {
                        text-align: left;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
`;
