import { createSlice } from "@reduxjs/toolkit";

const distributionOrBondData = [
    {
        id: 0,
        rightType: "전세권",
        rightsHolder: "한국공항공사(전세권자)",
        rightDate: "2013.04.04",
        rightPrice: "100000000",
        distributionDate: "2013.10.10",
        distributionPrice: "배분요구없음",
    },
    {
        id: 1,
        rightType: "전세권",
        rightsHolder: "한국공항공사(전세권자)",
        rightDate: "2013.04.04",
        rightPrice: "100000000",
        distributionDate: "2013.10.10",
        distributionPrice: "100000000",
    },
];

const initialState = distributionOrBondData;

export const distributionOrBondSlice = createSlice({
    name: "distributionOrBond",
    initialState,
    reducers: {
        createDistributionOrBond: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateDistributionOrBond: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createDistributionOrBond, updateDistributionOrBond } =
    distributionOrBondSlice.actions;

export default distributionOrBondSlice.reducer;
