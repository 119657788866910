import React, { useEffect, useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface IRadioButton {
    isDisabled?: boolean;
    isChecked?: boolean;
    label: string;
    handleChange: () => void;
    color?: string;
}

function RadioButton({ isDisabled = false, isChecked = false, label, handleChange, color = "purple" }: IRadioButton) {
    const [checked, setChecked] = useState(isChecked);

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    return (
        <div css={[radioStyle, colors[color]]} onClick={() => !isDisabled && handleChange()}>
            <input type="radio" checked={checked} onChange={handleChange} disabled={isDisabled} />
            <label htmlFor="ckBox"></label>
            {label && <p>{label}</p>}
        </div>
    );
}

const radioStyle = css`
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
        margin-top: 2px;
        padding: 0 10px;
        width: 100%;
        ${Common.textStyle.title6_R14}
    }
    [type="radio"] {
        vertical-align: middle;
        appearance: none;
        /* border: max(2px, 1px) solid #757575; */
        border: 2px solid #757575;
        border-radius: 50%;
        width: 16px;
        height: 15px;
        min-width: 16px;
        min-height: 15px;
        max-width: 16px;
        max-height: 15px;
    }
    /* [type="radio"]:checked {
        border: 4px solid #bd00ff;
    } */
    [type="radio"]:hover {
        box-shadow: 0 0 0 max(2px, 2px) #bdbdbd;
        cursor: pointer;
    }
    [type="radio"]:disabled {
        border: max(2px, 1px) solid #bdbdbd;
        box-shadow: none;
        background: #9e9e9e;
    }
`;

const colors: { [index: string]: any } = {
    purple: css`
        [type="radio"]:checked {
            border: 4px solid ${Common.colors.purple500};
        }
    `,
    orange: css`
        [type="radio"]:checked {
            border: 4px solid ${Common.colors.orange};
        }
    `,
    black: css`
        [type="radio"]:checked {
            border: 4px solid ${Common.colors.black};
        }
    `,
    red: css`
        [type="radio"]:checked {
            border: 4px solid ${Common.colors.error};
        }
    `,
};

export default RadioButton;
