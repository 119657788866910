import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { css } from "@emotion/react";

import { EditModal } from "@src/components";
import { useFindAllDeungiListUser } from "@src/hooks/queries/useHomeChart";
import DateRangeCalendar, { DateRangeCalendarType } from "@src/components/calendar/DateRangeCalendar";
import { dateFormat } from "@src/utils/dateUtil";
import { Common } from "@src/styles/Common";
import { closeModal } from "@src/reducers/modal";

interface Props {
    open: boolean;
    onCloseView: () => void;
}

const mainInfoTitle = ["등기열람 일자", "등기열람권 일 사용자수", "등기열람권 일 사용수량"];
const columns = [
    { value: 2, name: "회원번호" },
    { value: 2, name: "이름" },
    { value: 2, name: "열람권사용일시" },
    // { value: 2, name: "열람일시" },
    { value: 2, name: "열람권번호" },
    { value: 2, name: "결제수단" },
    { value: 2, name: "누적사용수량" },
    { value: 2, name: "사용종류" },
    { value: 1, name: "기존등기열람권" },
    { value: 1, name: "실시간 등기열람권" },
];

const secondColumns = ["구매수량", "관리자지급", "구독지급", "잔여수량"];

const DeungiMemberModal: React.FC<Props> = ({ open, onCloseView }) => {
    const navigate = useNavigate();
    const limit = 10;
    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [userCount, setUserCount] = useState<number>(0);
    const [deungiAmount, setDeungiAmount] = useState<number>(0);
    const [date, setDate] = useState<DateRangeCalendarType>({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });

    const dispatch = useDispatch();

    const { data, refetch, isLoading } = useFindAllDeungiListUser({
        page,
        limit,
        startDate: dateFormat(date?.startDate),
        endDate: dateFormat(date?.endDate),
    });

    const handlePageClick = (evt: any) => {
        setPage(evt.selected + 1);
    };

    useEffect(() => {
        if (open) {
            setTotalCount(data?.totalCount ?? 0);
            setUserCount(data?.userCount ?? 0);
            setDeungiAmount(data?.deungiCount ?? 0);
            refetch();
        }
        return () => {
            setPage(1);
            setTotalCount(0);
            setUserCount(0);
            setDeungiAmount(0);
        };
    }, [open]);

    useEffect(() => {
        if (data && page === 1) {
            setTotalCount(data?.totalCount ?? 0);
            setUserCount(data?.userCount ?? 0);
            setDeungiAmount(data?.deungiCount ?? 0);
        }
    }, [data]);

    useEffect(() => {
        if (date.endDate && open) {
            page !== 1 ? setPage(1) : refetch();
        }
    }, [date.endDate]);

    useEffect(() => {
        date.endDate && open && refetch();
    }, [page]);

    return (
        <div css={rootBoxStyle}>
            <EditModal
                handleBtnClick={() => {}}
                handleOpenModal={onCloseView}
                size="big"
                title="등기열람 관리"
                isModalShow={open}
                checkCloseModal={true}
                deleteButton={true}
                dimClose={true}
            >
                <>
                    <div css={mainInfoBox}>
                        {mainInfoTitle.map((title, idx) => (
                            <div key={title} css={mainInfoTitleBox}>
                                <p className="titleText">{title}</p>
                                {idx === 0 && (
                                    <>
                                        <DateRangeCalendar
                                            getUserDate={(range: DateRangeCalendarType) => {
                                                setDate(range);
                                            }}
                                            setDate={date}
                                        />
                                    </>
                                )}
                                {idx === 1 && <p className="titleValue">{userCount?.toLocaleString()}</p>}
                                {idx === 2 && <p className="titleValue">{deungiAmount?.toLocaleString()}</p>}
                            </div>
                        ))}
                    </div>
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <Fragment key={column.name}>
                                            <th
                                                key={column.name}
                                                rowSpan={column.value}
                                                colSpan={column.value === 1 ? 4 : 1}
                                            >
                                                {column.name}
                                                {column.value === 1 &&
                                                    secondColumns.map((column, idx) => (
                                                        <th className="noPadding" key={idx.toString()}>
                                                            {column}
                                                        </th>
                                                    ))}
                                            </th>
                                        </Fragment>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={12} className="dataCheck">
                                            loading...
                                        </td>
                                    </tr>
                                ) : data?.list?.length ? (
                                    data?.list?.map((user, idx) => (
                                        <tr key={`${user?.userNo}${idx.toString()}`}>
                                            <td>{user.userNo}</td>
                                            <td
                                                onClick={() => {
                                                    navigate(`/users/detail/${user?.userNo}`);
                                                    dispatch(closeModal());
                                                }}
                                                className="underlineText"
                                            >
                                                {user?.userName}
                                            </td>
                                            <td>{user?.useDate?.slice(2, -3)}</td>
                                            {/* <td>{user?.viewDate}</td> */}
                                            <td>{user?.ticketNo}</td>
                                            <td>{user?.payType}</td>
                                            <td>{user?.useCountAcmlt}장</td>
                                            <td>{user?.deungiType}</td>
                                            <td>{user?.exPaidQuantity}장</td>
                                            <td>{user?.exAdminServeQuantity}장</td>
                                            <td>{user?.exPaidBalanceQuantity}장</td>
                                            <td>{user?.exBalanceQuantity}장</td>
                                            <td>{user?.rPaidQuantity}장</td>
                                            <td>{user?.rAdminServeQuantity}장</td>
                                            <td>{user?.rSubsServeQuantity}장</td>
                                            <td>{user?.rBalanceQuantity}장</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={12} className="dataCheck">
                                            데이터가 없습니다.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!!totalCount && (
                        <div css={paginationBoxStyle}>
                            <ReactPaginate
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil((totalCount ?? 0) / limit)}
                                breakLabel="..."
                                activeClassName="pagination-current"
                                containerClassName="pagination-wrapper"
                                pageClassName="pagination-item"
                                previousClassName="pagination-arrow"
                                nextClassName="pagination-arrow"
                                disableInitialCallback={true}
                                forcePage={page - 1}
                            />
                        </div>
                    )}
                </>
            </EditModal>
        </div>
    );
};

export default DeungiMemberModal;

const rootBoxStyle = css`
    & > div {
        & > .modal {
            width: 1300px;
            max-width: 80vw;
            height: 50vh;
            min-height: 800px;
        }
    }
    .pagination-current > a {
        background-color: ${Common.colors.blue};
        color: #ffffff;
        border: 1px solid #9d9fa5;
    }
    .pagination-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
    .pagination-item > a {
        min-width: 25px;
        width: fit-content;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        border-radius: 4px;
    }
    .pagination-arrow > a {
        width: 20px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #9d9fa5;
        font-size: 12px;
        cursor: pointer;
    }
`;

const mainInfoBox = css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
`;
const mainInfoTitleBox = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 97px;
    padding: 20px 10px;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    gap: 8px;
    .titleText {
        font-size: 15px;
        font-weight: 700;
        color: #616161;
    }
    .titleValue {
        font-size: 18px;
        font-weight: 700;
        color: #212121;
    }
`;

const tableRootStyle = css`
    margin-top: 18px;
    width: 100%;
    height: 470px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 2px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
    table {
        border: 1px solid #bdbdbd;
        width: 100%;
        thead {
            tr {
                position: sticky;
                top: 0;
                background-color: #ffffff;
            }
            th {
                vertical-align: middle;
                border: 1px solid #bdbdbd;
                padding: 8px 5px;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;
            }
            .noPadding {
                border: none;
                padding: 4px 5px;
                font-size: 14px;
                width: 25%;
                line-height: 150%;
                font-weight: 500;
            }
            .noPadding:nth-of-type(1) {
                padding-left: 0;
            }
            .noPadding:nth-of-type(2) {
                white-space: nowrap;
            }
        }
        tr:last-of-type {
            border-bottom: 1px solid #b5b5b5;
        }
        tbody {
            vertical-align: middle;
            border-bottom: 1px solid #b5b5b5;
            td {
                border: 1px solid #bdbdbd;
                padding: 10px 5px;
                text-align: center;
                text-overflow: ellipsis;
                cursor: pointer;
            }
        }
        .dataCheck {
            padding: 100px;
        }
    }
    .underlineText {
        white-space: nowrap;
        text-decoration-line: underline;
        text-underline-position: under;
    }
`;

const paginationBoxStyle = css`
    padding: 10px;
`;
