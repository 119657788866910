const saleStatusSampleData: any = {
    list: [
        {
            id: "0",
            type: "집합건물",
            location: "서울특별시 관악구 신림동 1730",
            detail: "1",
        },
        {
            id: "1",
            type: "집합건물",
            location: "서울특별시 관악구 신림동 1730",
            detail: "2",
        },
    ],
    options:
        "일괄매각, 제시 외 건물 포함, [목록6] 현황 '법면등', [목록9] 소재 현황 '내원리 46-5'",
    appraisal:
        "1.구분건물감정평가요항표 1) 위치 및 주위환경 본건은 서울특별시 구로구 신도림동 소재 1호선 및 2호선 '신도림역' 북서측 인근에 위치 하며, 부근은 아파트단지, 상업용건물, 공공시설 등이 소재하는 지",
};
const rightsAnalysisSampleData: any = {
    list: [
        {
            dispose: "소유권",
            type: "갑1",
            createDate: "2015.06.09",
            rightType: "소유권이전",
            creditPrice: null,
            creditor: "곽장락",
            option: "단독소유",
        },
        {
            dispose: "인수이자있음",
            type: "을2",
            createDate: "2016.06.01",
            rightType: "임차권",
            creditPrice: 161120,
            creditor: "이은미",
            option: "존속기간: 2017.05.10~2019.05.12",
        },
        {
            dispose: "인수",
            type: "을2",
            createDate: "2016.06.01",
            rightType: "전세권",
            creditPrice: "161120",
            creditor: "이은미",
            option: "존속기간: 2017.09.15~2019.09.14",
        },
        {
            dispose: "말소기준",
            type: "을3",
            createDate: "2016.06.10",
            rightType: "임의경매",
            creditPrice: 161120,
            creditor: "권오택",
            option: "-",
        },
        {
            dispose: "소멸",
            type: "을4",
            createDate: "2016.06.12",
            rightType: "근저당권",
            creditPrice: 161120,
            creditor: "주식회사제이너트대부",
            option: "-",
        },
        {
            dispose: "말소기준",
            type: "을5",
            createDate: "2016.06.12",
            rightType: "근저당권",
            creditPrice: 161120,
            creditor: "김지연",
            option: "-",
        },
    ],
    bidDate: "2022.08.01",
    list2: [
        {
            counterForce: true,
            expectPrice: "전액매수인인수",
            deposit: "보 7,000,000원 월 5,00,000원",
            detail: "전입: 2018.04.24 확정: 미상 배당: 2021.08.03",
            lessee: "(주)우림원건설",
            occupied: "점포 1층 전부",
        },
        {
            counterForce: false,
            expectPrice: "배당금 없음",
            deposit: "미상",
            detail: "전입: 2018.04.24 확정: 미상 배당: 없음",
            lessee: "주식회사베네핏펀드(이남경)",
            occupied: "점포 1층 101호(34㎡)",
        },
    ],
    bidAnalysis:
        "본건 전입세대주 공유자 박기범(채무자 겸 공유자 박은자의 배우자)과의 전화문답에 의하면 본건 부동산에는 본인들 가족들(주민등록상 전입세대원 전원)이 거주한다하니 그 점유관계(공유자들간 점유관계)등은 별도의 확인을 요함.",
};
export { saleStatusSampleData, rightsAnalysisSampleData };
