import styled from "@emotion/styled";
import { Title, UpDown } from "@src/components";
import { useGetExpertHome } from "@src/hooks/queries/useExpert";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import addExpert from "../../assets/addExpert.png";

const ExpertHome = () => {
    const navigate = useNavigate();
    const { data, refetch } = useGetExpertHome();

    useEffect(() => {
        refetch();
    }, []);
    return (
        <>
            <StyledExpertWrap>
                <Title title="전문가 회원" />
                <div className="addExpertDiv">
                    <Title title="전문가 등록 혜택" size="small" />
                    <div className="addExpertContents">
                        <div className="box"></div>
                        <img
                            src={addExpert}
                            alt=""
                            className="addImg"
                            onClick={() => {
                                navigate("/experts/register");
                            }}
                        />
                    </div>
                </div>
                <div className="registrationStatusDiv">
                    <Title title="전문가 등록 현황" />
                    <div className="registerationStatusContents">
                        <div className="boxWrap">
                            <div className="firstRow">
                                <UpDown
                                    title="일 등록자"
                                    mainText={
                                        data?.todayCount.toString() ?? "0"
                                    }
                                    upDownCount={
                                        data?.yesterdayCount && data.todayCount
                                            ? (
                                                  data?.yesterdayCount -
                                                  data?.todayCount
                                              )
                                                  .toString()
                                                  .replaceAll("-", "")
                                            : 0
                                    }
                                    isUp={
                                        data?.yesterdayCount && data.todayCount
                                            ? (
                                                  data?.yesterdayCount -
                                                  data?.todayCount
                                              )
                                                  .toString()
                                                  .includes("-")
                                                ? false
                                                : true
                                            : false
                                    }
                                    same={false}
                                    errorMessage={""}
                                />
                                <UpDown
                                    title="전체 전문가수"
                                    mainText={data?.count.toString() ?? "0"}
                                    upDownCount={0}
                                    isUp={false}
                                    same={false}
                                    errorMessage={""}
                                />
                            </div>
                            <div className="secondRow">
                                <div className="secondData">
                                    <div className="dataTitle first">
                                        공인중개사
                                    </div>
                                    <div>
                                        <div className="dataText">
                                            <div className="name">오늘</div>
                                            <div className="text">
                                                {data?.agentTodayCount}
                                            </div>
                                        </div>
                                        <div className="dataText">
                                            <div className="name">전체</div>
                                            <div className="text">
                                                {data?.agentCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondData">
                                    <div className="dataTitle">
                                        공인중개사
                                        <br />
                                        (경매 대리인)
                                    </div>
                                    <div>
                                        <div className="dataTextSecond">
                                            <div className="text">
                                                {data?.auctionAgentTodayCount}
                                            </div>
                                        </div>
                                        <div className="dataTextSecond">
                                            <div className="text">
                                                {data?.auctionAgentCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="secondData">
                                    <div className="dataTitle">법무사</div>
                                    <div>
                                        <div className="dataTextSecond">
                                            <div className="text">
                                                {data?.scrivenerTodayCount}
                                            </div>
                                        </div>
                                        <div className="dataTextSecond">
                                            <div className="text">
                                                {data?.scrivenerCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box">secound</div>
                    </div>
                </div>
                <div className="inquiryStatus">
                    <Title title="문의 현황" />
                    <div className="inquiryStatusContents">
                        <div className="items">
                            <Title title="오늘 문의 현황" size="small" />
                            <div className="itemBoxs">
                                <UpDown
                                    title="전화문의"
                                    mainText={`${data?.inquiryCallTodayCount}건`}
                                    upDownCount={
                                        data
                                            ? (
                                                  data?.inquiryCallTodayCount -
                                                  data?.inquiryCallYesterdayCount
                                              )
                                                  .toString()
                                                  .replaceAll("-", "") === "0"
                                                ? null
                                                : (
                                                      data?.inquiryCallTodayCount -
                                                      data?.inquiryCallYesterdayCount
                                                  )
                                                      .toString()
                                                      .replaceAll("-", "")
                                            : null
                                    }
                                    isUp={
                                        data
                                            ? data?.inquiryCallTodayCount -
                                                  data?.inquiryCallYesterdayCount >
                                              0
                                            : true
                                    }
                                    same={
                                        data
                                            ? data?.inquiryCallTodayCount -
                                                  data?.inquiryCallYesterdayCount ===
                                              0
                                            : false
                                    }
                                    errorMessage={""}
                                />
                                <UpDown
                                    title="문자문의"
                                    mainText={`${data?.inquiryMessageTodayCount}건`}
                                    upDownCount={
                                        data
                                            ? (
                                                  data?.inquiryMessageTodayCount -
                                                  data?.inquiryMessageYesterdayCount
                                              )
                                                  .toString()
                                                  .replaceAll("-", "") === "0"
                                                ? null
                                                : (
                                                      data?.inquiryMessageTodayCount -
                                                      data?.inquiryMessageYesterdayCount
                                                  )
                                                      .toString()
                                                      .replaceAll("-", "")
                                            : null
                                    }
                                    isUp={
                                        data
                                            ? data?.inquiryMessageTodayCount -
                                                  data?.inquiryMessageYesterdayCount >
                                              0
                                            : true
                                    }
                                    same={
                                        data
                                            ? data?.inquiryMessageTodayCount -
                                                  data?.inquiryMessageYesterdayCount ===
                                              0
                                            : false
                                    }
                                    errorMessage={""}
                                />
                            </div>
                        </div>
                        <div className="items">
                            <Title title="전체 문의 현황" size="small" />
                            <div className="itemBoxs allInquiry">
                                <UpDown
                                    title="전화문의"
                                    mainText={`${data?.inquiryCallCount}건`}
                                    upDownCount={0}
                                    isUp={false}
                                    same={false}
                                    errorMessage={""}
                                />
                                <UpDown
                                    title="문자문의"
                                    mainText={`${data?.inquiryMessageCount}건`}
                                    upDownCount={0}
                                    isUp={false}
                                    same={false}
                                    errorMessage={""}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </StyledExpertWrap>
        </>
    );
};

export default ExpertHome;

const StyledExpertWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 45px;
    .addExpertDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        gap: 4px;
        .addExpertContents {
            display: flex;
            gap: 24px;
            width: 100%;
            box-sizing: border-box;
            .box {
                width: 685px;
                height: 108px;
                background-color: #bababa;
            }
            .addImg {
                width: 240px;
                height: 108px;
            }
        }
    }
    .registrationStatusDiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        .registerationStatusContents {
            display: flex;
            gap: 27px;
            .boxWrap {
                display: flex;
                flex-direction: column;
                gap: 7px;
                .firstRow {
                    display: flex;
                    gap: 9px;
                    & > div:last-of-type {
                        & > div {
                            justify-content: flex-end;
                        }
                    }
                }
                .secondRow {
                    display: flex;
                    padding: 14px 16px;
                    border: 1.5px solid #e0e0e0;
                    & > .secondData:last-of-type {
                        border-right: none;
                    }
                    & > .secondData:first-of-type {
                        padding-right: 15px;
                        padding-left: 0;
                    }
                    .secondData {
                        display: flex;
                        flex-direction: column;
                        padding: 0 15px;
                        border-right: 1.5px solid #e0e0e0;
                        gap: 40px;

                        width: 160px;
                        justify-content: space-between;
                        .first {
                            display: flex;
                            align-items: center;
                            justify-content: center !important;
                        }
                        .dataTitle {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 24px;
                            color: #b1b1b1;
                        }
                        .dataText {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .name {
                                font-size: 10px;
                                font-weight: 400;
                                line-height: 16px;
                                color: #b1b1b1;
                            }
                            .text {
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 32px;
                                color: #000000;
                            }
                        }
                        .dataTextSecond {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            .text {
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 32px;
                                color: #000000;
                            }
                        }
                    }
                }
            }
            .box {
                width: 462px;
                height: 279px;
                background-color: #bababa;
            }
        }
    }
    .inquiryStatus {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 14px;
        .inquiryStatusContents {
            display: flex;
            gap: 20px;
            .items {
                display: flex;
                flex-direction: column;
                gap: 6px;
                .itemBoxs {
                    display: flex;
                    & > :first-of-type {
                        border-right: none;
                        border-radius: 8px 0 0 8px;
                        width: fit-content;
                    }
                    & > :last-of-type {
                        border-left: none;
                        border-radius: 0 8px 8px 0;
                        width: fit-content;
                    }
                }
                .allInquiry {
                    & > div {
                        & > div {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
`;
