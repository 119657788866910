import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Constants } from "@src/constants/Constants";
import { Button } from "@src/components";
import {
    CreateTemplateDto,
    TemplateFormProps,
} from "@src/interfaces/Template.interface";
import TemplateForm from "./TemplateForm";
import { ddlTemplateCategory } from "@src/constants/TemplateStatus";
import { useCreateTemplate } from "@src/hooks/queries/useTemplateDetail";
import { useNavigate } from "react-router-dom";
import { useTemplateList } from "@src/hooks/queries/useTemplate";

function TemplateCreate() {
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<string>("");
    const [updateData, setUpdateData] = useState<TemplateFormProps>({
        templateBody: "",
        isEdit: 0,
    });

    useEffect(() => {
        setDisabled(!updateData.isEdit);
    }, [updateData]);

    const { refetch: listRefetch } = useTemplateList({ limit: 10, page: 1 });
    const { mutate: createMutate } = useCreateTemplate();
    const handleSubmit = () => {
        if (updateData.isEdit) {
            const params: CreateTemplateDto = {
                template_code: updateData?.templateCode || "",
                category: updateData?.templateCategory || 0,
                msg_title: updateData?.templateTitle || "",
                msg_body: updateData.templateBody,
                msg_click_action: updateData?.templateLink || "",
            };
            createMutate(params, {
                onSuccess: () => {
                    navigate("/notify/template");
                },
                onError: (error: any) => {
                    setPageErrorMessage(error);
                },
                onSettled: () => {
                    listRefetch();
                },
            });
        }
    };

    return (
        <div css={createDiv}>
            <TemplateForm
                title={`${Constants.TEMPLATE} 등록`}
                handleUpdate={setUpdateData}
                isUpdate={false}
                isDetail={false}
                initData={{
                    templateCategory: Number(ddlTemplateCategory[0].key),
                    templateBody: "",
                    isEdit: 0,
                }}
            />
            <div className="buttonBox">
                <p>{pageErrorMessage}</p>
                <Button
                    label={"등록"}
                    color={"gd"}
                    size="medium"
                    isDisabled={disabled}
                    handleClick={handleSubmit}
                    deleteBtn={false}
                    defaultValue={""}
                />
            </div>
        </div>
    );
}

const createDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;

export default TemplateCreate;
