import { createSlice } from "@reduxjs/toolkit";

const comment: string =
    "지분매각임. 공유자 우선매수권 행사 제한 있음(공유자 우선매수신청을 한 공유자는 매각기일 종결 전까지 보증금을 제공하여야 유효하며 매수신청권리를 행사하지 않는 경우에 차회기일부터는 우선권이 없음).";

const initialState = comment;

export const commentSlice = createSlice({
    name: "comment",
    initialState,
    reducers: {
        createComment: (state, action) => {
            const result = action.payload;
            return result;
        },
        updateComment: (state, action) => {
            const result = action.payload;
            return result;
        },
    },
});

export const { createComment, updateComment } = commentSlice.actions;

export default commentSlice.reducer;
