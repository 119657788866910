import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import "@src/styles/CommonTable.css";
import { Calendar, Input } from "@src/components";
import { useSelector } from "react-redux";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { ConvertDate } from "@src/utils/dateUtil";
import { DistributionOrBondStateType } from "@src/types";
import { dateParse } from "@src/utils/dateUtil";

interface IDistributionOrBond {
    isUpdate: boolean;
    handleUpdate?: (update: any) => void;
}

function DistributionOrBondContainer({
    isUpdate = false,
    handleUpdate,
}: IDistributionOrBond) {
    const headerMeta = [
        "번호",
        "권리종류",
        "권리자명",
        "설정일",
        "설정금액(원)",
        "배분요구일시",
        "배분요구채권액(원)",
    ];
    const defaultData = useSelector((state: any) => state.distributionOrBond);
    const [tableData, setTableData] =
        useState<DistributionOrBondStateType[]>(defaultData);
    useEffect(() => {
        setTableData([...defaultData]);
    }, [defaultData]);
    const handleChange = (updateData: any, index: number) => {
        let newTableData = [...tableData];
        newTableData[index] = updateData;
        setTableData([...newTableData]);
    };

    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);
    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: any) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isUpdate={isUpdate}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

const TableRow = ({ data, isUpdate, handleChange }: any) => {
    const [rowData, setRowData] = useState<DistributionOrBondStateType>(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const {
        id,
        rightType,
        rightsHolder,
        rightDate,
        rightPrice,
        distributionDate,
        distributionPrice,
    } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRowData({
            ...rowData,
            [name]: value,
        });
    };
    const onChangeDate = (name: string, value: number) => {
        setRowData((prev) => ({
            ...prev,
            [name]: ConvertDate(value, "yyyy.MM.dd"),
        }));
    };
    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);

    return (
        <tr key={id} style={isUpdate ? { borderBottom: "none" } : undefined}>
            <td>
                <Input
                    name="id"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={true}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={id + 1}
                />
            </td>
            <td>
                <Input
                    name="rightType"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rightType : ConvertEmptyValue(rightType)}
                />
            </td>
            <td>
                <Input
                    name="rightsHolder"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? rightsHolder
                            : ConvertEmptyValue(rightsHolder)
                    }
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(rightDate)}
                    getUserDate={(value) => onChangeDate("rightDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
            <td>
                <Input
                    name="rightPrice"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rightPrice : resultPrice(rightPrice)}
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(distributionDate)}
                    getUserDate={(value) =>
                        onChangeDate("distributionDate", value)
                    }
                    isDisabled={!isUpdate}
                />
            </td>
            <td>
                <Input
                    name="distributionPrice"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? distributionPrice
                            : resultPrice(distributionPrice)
                    }
                />
            </td>
        </tr>
    );
};

const calendarItem = css`
    & input {
        height: 47px;
    }
`;
export default DistributionOrBondContainer;
