import { ConvertPrice } from "@src/utils/units";

export const user = [
    {
        id: 0,
        title: "이름",
        value: "김수한무",
    },
    {
        id: 1,
        title: "생년월일",
        value: "2000.01.01",
    },
    {
        id: 2,
        title: "연락처",
        value: "010-1234-5678",
    },
    {
        id: 3,
        title: "성별",
        value: "남자",
    },
    {
        id: 4,
        title: "이메일",
        value: "abc@naver.com",
    },
    {
        id: 5,
        title: "가입일",
        value: "2021.02.02 14:25:11",
    },
];

export const isMarketingInfo = {
    marketingValue: true,
    marketingDate: "2021.02.02 14:25:11",
};

export const isTrial = {
    trialValue: true,
    trialDate: "2021.02.02 14:25:11",
};

export const isMembership = {
    membershipValue: false,
};

export const paymentHistory = [
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: true,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: false,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: true,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: false,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: true,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: false,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: true,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: false,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: true,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: true,
        receipt: false,
        detail: true,
    },
    {
        no: "32890",
        date: "2021.02.02 14:25:11",
        product: "멤버십",
        price: ConvertPrice(58800),
        state: false,
        receipt: false,
        detail: true,
    },
];
export const registerHistory = [
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: true,
        errorMessage: "",
    },
    {
        date: "2021.02.02 14:25:11",
        address: "충북 청주시 상당구 455-1",
        state: false,
        errorMessage: "[오류 내용 출력]",
    },
];
