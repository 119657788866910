import React, { useEffect, useState } from "react";
import {
    Checkbox,
    ConfirmModal,
    EditModal,
    Input,
    Title,
} from "@src/components";
import { IExistingDeunggi } from "./types";
import { css } from "@emotion/react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    selectedDeunggi?: IExistingDeunggi | null;
    onSubmitRefundDeunggi: (price: number, desc: string) => void;
}

const UserExistingDeunggiRefundModal: React.FC<Props> = ({
    open,
    onCloseView,
    selectedDeunggi,
    onSubmitRefundDeunggi,
}) => {
    const [refundPrice, setRefundPrice] = useState<number>(0);
    const [refundDesc, setRefundDesc] = useState<string>("");
    const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setOpenCheckModal(false);
        };
    }, [open]);

    return (
        <>
            <EditModal
                isModalShow={open}
                buttonText="환불하기"
                handleBtnClick={() => setOpenCheckModal(true)}
                handleOpenModal={onCloseView}
                size="big"
                title="기존 등기열람권 환불"
                isDisabled={
                    !refundPrice ||
                    !refundDesc ||
                    Boolean(
                        !selectedDeunggi?.refundQuantity && refundPrice === 0,
                    )
                }
                checkCloseModal={true}
            >
                <div css={rootBoxStyle}>
                    <div css={rowBoxStyle}>
                        <p css={rowTextStyle}>
                            충전 정보
                            <span>{selectedDeunggi?.productName}</span>
                        </p>
                        <p css={rowTextStyle}>
                            결제액
                            <span>
                                {selectedDeunggi?.paidPrice?.toLocaleString()}원
                            </span>
                        </p>
                    </div>
                    <p css={rowTextStyle}>
                        사용 수량
                        <span>{selectedDeunggi?.usedQuantity}</span>
                    </p>
                    <Title title="환불정보" />
                    <div css={rowBoxStyle}>
                        <p css={[rowTextStyle, { width: "fit-content" }]}>
                            환불 수량
                            <span>{selectedDeunggi?.refundQuantity}</span>
                        </p>
                        <div css={refundBoxStyle}>
                            <span className="essential">*</span>환불예정액
                            <Input
                                errorMessage=""
                                handleChange={(evt) => {
                                    const newVal = evt.target.value.replaceAll(
                                        ",",
                                        "",
                                    );
                                    if (
                                        isNaN(Number(newVal)) ||
                                        Number(newVal) >
                                            (selectedDeunggi?.paidPrice ?? 0)
                                    ) {
                                        return;
                                    }
                                    setRefundPrice(Number(newVal));
                                }}
                                onKeyDown={function noRefCheck() {}}
                                placeholder="금액 입력"
                                size="medium"
                                type="text"
                                value={
                                    refundPrice
                                        ? refundPrice.toLocaleString()
                                        : ""
                                }
                            />
                            <Checkbox
                                defaultValue=""
                                handleChange={(key, check) => {
                                    if (check && selectedDeunggi?.paidPrice) {
                                        setRefundPrice(
                                            selectedDeunggi?.paidPrice,
                                        );
                                    } else if (
                                        !check &&
                                        selectedDeunggi?.paidPrice
                                    ) {
                                        setRefundPrice(0);
                                    }
                                }}
                                label="전액환불"
                            />
                        </div>
                    </div>
                    <div css={descBoxStyle}>
                        <span className="essential">*</span>사유
                        <Input
                            errorMessage=""
                            handleChange={(evt) => {
                                setRefundDesc(evt.target.value);
                            }}
                            placeholder="사유를 작성해 주세요"
                            size="medium"
                            type="text"
                            value={refundDesc}
                        />
                    </div>
                </div>
            </EditModal>
            <ConfirmModal
                isModalShow={openCheckModal}
                buttonText="확인"
                handleCancel={() => setOpenCheckModal(false)}
                handleOk={() => onSubmitRefundDeunggi(refundPrice, refundDesc)}
            >
                저장하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default UserExistingDeunggiRefundModal;

const rootBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

const rowBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const rowTextStyle = css`
    width: 100%;
    white-space: nowrap;
    span {
        margin-left: 20px;
    }
`;

const refundBoxStyle = css`
    display: flex;
    align-items: center;
    .essential {
        color: #f45b5b;
    }
`;

const descBoxStyle = css`
    .essential {
        color: #f45b5b;
    }
    width: 100%;
    white-space: nowrap;
`;
