import { axiosDownloadInstance, axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import axios from "axios";
import { format } from "date-fns";
import { useInfiniteQuery, useQuery } from "react-query";

export interface IPaymentListFilter {
    page: number;
    limit: number;
    cursor?: string;
    searchType?: string;
    searchValue?: string;
    paymentStartDate?: string;
    paymentEndDate?: string;
    refundStartDate?: string;
    refundEndDate?: string;
    saleTypes?: number[];
    paymentMethods?: string[];
    paymentStatuses?: string[];
    partnerStatuses?: string[];
    minPaymentAmount?: number;
    maxPaymentAmount?: number;
}

export interface IPaymentListItem {
    paymentNo: number;
    userNo: number;
    name: string;
    email: string;
    productName: string;
    productCode: string;
    saleType: string;
    paymentMethod: string;
    cardName: string;
    paymentDate: string;
    paymentAmount: number;
    refundDate?: string;
    refundAmount?: number;
    paymentStatus: string;
    partnerStatus: string;
    paymentUid: string;
}

export interface IPaymentListRes {
    totalCount?: number;
    page: number;
    limit: number;
    cursor: string;
    totalPaymentAmount: number;
    totalRefundAmount: number;
    totalPaidAmount: number;
    list: IPaymentListItem[];
}

export const initFilters: IPaymentListFilter = {
    page: 1,
    limit: 10,
    minPaymentAmount: 1,
    searchType: "userName",
};

const fetchPaymentList = async (params: IPaymentListFilter) => {
    const response = await axiosInstance.get(`${axiosPath.payment}/getPaymentList`, { params });

    return response.data;
};

export const useGetPaymentList = (params: IPaymentListFilter, enabled: boolean = true) => {
    return useQuery<any>(
        ["getPaymentList", params],
        () => fetchPaymentList(params),

        { enabled, keepPreviousData: true },
    );
};

export const useGetExcelFile = async (params: IPaymentListFilter, reason: string) => {
    const response = await axiosDownloadInstance.get(`${axiosPath.payment}/downloadExcel`, {
        params: { ...params, reason },
    });

    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", decodeURI(response.headers["content-disposition"].split("filename*=UTF-8''")[1]));
    document.body.appendChild(link);
    link.click();
};

const fetchExcelDownloadLog = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.payment}/excelDownloadLog`, params);

    return response.data;
};

export const useGetExcelDownloadLog = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: "getInquiryList",
        queryFn: ({ pageParam = 1 }) =>
            fetchExcelDownloadLog({
                ...params,
                page: pageParam,
            }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage = pages.length * 10 < pages[0].totalCount ? pages.length + 1 : undefined;
            return newPage;
        },
        enabled,
    });
};

export const getQueryString = (searchParams: IPaymentListFilter) => {
    let queryParams: any = {};
    const {
        page,
        limit,
        cursor,
        searchType,
        searchValue,
        paymentStartDate,
        paymentEndDate,
        refundStartDate,
        refundEndDate,
        saleTypes,
        paymentMethods,
        paymentStatuses,
        partnerStatuses,
        minPaymentAmount,
        maxPaymentAmount,
    } = searchParams;

    Object.keys(searchParams).forEach((value: any) => {
        let name = value as keyof IPaymentListFilter;
        if (searchParams[name]) {
            queryParams[name] = searchParams[name];
        }
    });
    return queryParams;
};
