import React from "react";
import { IconButton, Label } from "@src/components";
import { SupportStatus } from "@src/constants/SupportStatus";

export const getColumns = ({ navigate }: any) => {
    return [
        {
            Header: "문의자",
            accessor: "userName",
            Cell: ({ cell: { value }, row }: any) => {
                const userId = row?.original?.userNo;
                return (
                    <div
                        style={{
                            textAlign: "center",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate(`/users/detail/${userId}`);
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "문의 유형",
            accessor: "qstType",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "구분",
            accessor: "device",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "제목",
            accessor: "title",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value ? value : "-"}</div>
            ),
        },
        {
            Header: "내용",
            accessor: "userContents",
            Cell: ({ cell: { value } }: any) => (
                // <div
                //     style={{
                //         textAlign: "left",
                //         overflow: "hidden",
                //         whiteSpace: "nowrap",
                //         textOverflow: "ellipsis",
                //         wordBreak: "break-all",
                //         width: "30%",
                //     }}
                //     className="supportContent"
                // >
                <div style={{ textAlign: "center" }} className="supportContent">
                    {value ? value : "-"}
                </div>
                // </div>
            ),
        },
        {
            Header: "문의 일시",
            accessor: "qstDate",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "답변 일시",
            accessor: "ansDate",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value ? value : "-"}
                </div>
            ),
        },
        {
            Header: "답변자",
            accessor: "adminUser",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value ? value : "-"}</div>
            ),
        },
        {
            Header: "상태",
            accessor: "isReply",
            Cell: ({ cell: { value } }: any) => {
                const [text, color] = SupportStatus[value % 2];
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Label color={color} text={text} />
                    </div>
                );
            },
        },
        {
            Header: "관리",
            accessor: "viewControl",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        type="edit"
                        handleClick={() => {
                            navigate(
                                `/help/support/reply/${row.original.viewMore}`,
                            );
                        }}
                        isDisabled={row.original.isReply}
                    />
                </div>
            ),
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        type="arrow"
                        handleClick={() => {
                            navigate(`/help/support/detail/${value}`);
                        }}
                    />
                </div>
            ),
        },
    ];
};
