import React, { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Button, MultiDropDown } from "@src/components";
import {
    useLocationBigData,
    useLocationSmallData,
} from "@src/hooks/queries/useLocation";

interface IFilterLocation {
    clear: boolean;
    setClear: Function;
    setPropsLocations: Function;
}
interface SelectedDropdown {
    code: string;
    name: string;
}

function FilterLocation({
    clear,
    setClear,
    setPropsLocations,
}: IFilterLocation) {
    const { data: LocationLargeCategory } = useLocationBigData();
    const { data: LocationMediumCategory } = useLocationSmallData();
    const defaultLocation = {
        first: LocationLargeCategory["서울"],
        second: {
            name: "서울전체",
            code: "999",
        },
    };

    const [selectedLocationL, setSelectedLocationL] =
        useState<SelectedDropdown>(defaultLocation.first);
    const [selectedLocationM, setSelectedLocationM] =
        useState<SelectedDropdown>(defaultLocation.second);

    const hasValueCheck = (list: any, value: string) => {
        return list.findIndex((v: string) => v.includes(value));
    };

    const duplicationCheck = (list: any, value: string) => {
        return list.findIndex((v: string) => v === value);
    };

    const [locations, setLocations] = useState<string[]>([]);

    const addLocation = () => {
        const currentBigName = selectedLocationL.name;
        const currentSmallName = selectedLocationM.name;
        const name = `${currentBigName}/${currentSmallName}`;
        const makeNameALL = `${currentBigName}/${currentBigName}전체`;
        if (hasValueCheck(locations, makeNameALL) > -1) {
            // 1. 서울전체가 들어있다.
            // 2. 서울의 다른 값들을 넣지 않는다.
            return;
        } else if (hasValueCheck(locations, currentBigName) > -1) {
            // 1. 서울 다른 값들이 있다.
            // 2. 서울전체를 넣지 않는다.
            if (name == makeNameALL) {
                return;
            } else if (
                // 중복값은 넣지 않는다.
                duplicationCheck(locations, name) === -1
            ) {
                setLocations((current) => [...current, name]);
                setPropsLocations((current: any) => [...current, name]);
            }
        } else {
            setLocations((current) => [...current, name]);
            setPropsLocations((current: any) => [...current, name]);
        }
    };

    const deleteLocation = (e: MouseEvent<HTMLButtonElement>) => {
        const findIndex = duplicationCheck(locations, e.currentTarget.value);
        locations.splice(findIndex, 1);

        setLocations([...locations]);
        setPropsLocations([...locations]);
    };

    const createDropDownList = (list: any) => {
        return Object.entries(list).map((value, index) => {
            const { name, code }: any = value[1];
            return {
                id: index,
                key: code,
                value: name,
            };
        });
    };

    const initLocation = {
        first: {
            key: defaultLocation.first.code,
            displayName: defaultLocation.first.name,
        },
        second: {
            key: defaultLocation.second.code,
            displayName: defaultLocation.second.name,
        },
    };

    useEffect(() => {
        setLocations([]);
        setPropsLocations([]);
    }, [clear]);

    return (
        <>
            <ul>
                <li>소재지</li>
                <li>
                    <MultiDropDown
                        firstData={createDropDownList(LocationLargeCategory)}
                        secondData={Object.fromEntries(
                            Object.entries(LocationMediumCategory).map((v) => {
                                const [code, values]: any = v;
                                return [
                                    code,
                                    Object.fromEntries(
                                        Object.entries(values).map((v) => v),
                                    ),
                                ];
                            }),
                        )}
                        defaultValue={initLocation}
                        handleChange={(
                            first: { key: string; displayName: string },
                            second: { key: string; displayName: string },
                        ) => {
                            const firstCode = first.key;
                            const secondCode = second.key;
                            if (firstCode) {
                                setSelectedLocationL({
                                    code: firstCode,
                                    name: first.displayName,
                                });
                            }

                            if (secondCode) {
                                setSelectedLocationM({
                                    code: second.displayName,
                                    name: secondCode,
                                });
                            }
                            setClear(false);
                        }}
                        clear={clear}
                    />
                    &nbsp;
                    <Button
                        color="purple"
                        label="추가"
                        size="small"
                        handleClick={addLocation}
                    ></Button>
                </li>
            </ul>
            {locations.length > 0 && (
                <ul css={WideArea}>
                    <li>
                        <div css={LocationDiv}>
                            {locations.map((element, index) => {
                                return (
                                    <Button
                                        key={index}
                                        label={element}
                                        color="purple"
                                        deleteBtn={true}
                                        size="small"
                                        defaultValue={element}
                                        handleClick={deleteLocation}
                                    />
                                );
                            })}
                        </div>
                    </li>
                </ul>
            )}
        </>
    );
}

const WideArea = css`
    display: flex;
    height: fit-content !important;

    & > li {
        width: 100% !important;
    }
`;

const LocationDiv = css`
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
`;

export default FilterLocation;
