import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";

const fetchData = async (userNo: number) => {
    const response = await axiosInstance.get(
        `${axiosPath.users}/${userNo}/history?method=PATCH`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: [] = responseData.data.list;
    return parse(originData);
};

const parse = (originData: any) => {
    const newData = [];
    newData.push(
        originData.map((item: any) => {
            const { comments, admin_name, created_at } = item;
            return {
                date: created_at,
                operatorName: admin_name,
                reason: comments,
            };
        }),
    );
    return newData;
};
const useGetUserUpdateHistory = (userNo: number) => {
    return useQuery(
        [queryKeys.USER_UPDATE_HISTORY, userNo],
        () => fetchData(userNo),
        {
            // initialData: initData,
            enabled: false,
        },
    );
};
export { useGetUserUpdateHistory };
