import { PRODUCT_TYPE } from "@src/constants/ProductCatalog";
import { ProductFilterType } from "@src/types/product";
import { BaseProductListType } from "@src/pages/product/types";

export class ProductFilterCondition {
    v: BaseProductListType;
    params: ProductFilterType;

    constructor(v: BaseProductListType, params: ProductFilterType) {
        this.v = v;
        this.params = params;
    }

    conditionType = () => {
        if (this.params.productType) {
            const bidType =
                this.params.productType === PRODUCT_TYPE.PUBLIC_AUCTION ? 1 : 0;
            return this.v.bidType === bidType;
        }

        return true;
    };

    conditionNo = () => {
        if (this.params.productId) {
            return this.v.itemId.includes(this.params.productId);
        }
        return true;
    };

    conditionStatus = () => {
        switch (this.params.productStatus) {
            case "유찰":
                return this.v.skpCnt > 0;
            case "입찰":
                return this.v.bfDay === 0;
        }
        return true;
    };

    conditionBidRangeDate = () => {
        const { startDate, endDate } = this.params;
        if (startDate && endDate) {
            const bidDate = new Date(this.v.bidDate).getTime();
            return startDate < bidDate && bidDate < endDate;
        }
        return true;
    };

    conditionExpectPrice = () => {
        const { valueMinPrice = 0, valueMaxPrice = 0 } = this.params;
        const min = Number(valueMinPrice);
        const max = Number(valueMaxPrice);
        const target = this.v.estPrice;
        // 감정가
        if (min > 0 && max > 0) {
            return min < target && max > target;
        } else if (min > 0) {
            return min < target;
        } else if (max > 0) {
            return max > target;
        }

        return true;
    };

    conditionMinBidPrice = () => {
        const { bidMinPrice = 0, bidMaxPrice = 0 } = this.params;
        const min = Number(bidMinPrice);
        const max = Number(bidMaxPrice);
        const target = this.v.bpLow;
        // 최저입찰가
        if (min > 0 && max > 0) {
            return min < target && max > target;
        } else if (min > 0) {
            return min < target;
        } else if (max > 0) {
            return max > target;
        }

        return true;
    };

    conditionUsedBuilding = () => {
        // 용도
        // console.log(this.params.buildings);
        return true;
    };

    conditionDisposeType = () => {
        const { disposeType } = this.params;
        const { isScheduled } = this.v;
        // 처분방식(공)
        if (disposeType) {
            switch (disposeType) {
                case "매각": {
                    return isScheduled === 1;
                }
            }
        }

        return true;
    };
}
