import React from "react";
import { Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { css } from "@emotion/react";
import { ConvertPrice } from "@src/utils/units";
export const getColumns = (navigate: any) => [
    {
        Header: "결제 번호",
        accessor: "paymentNo",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ width: "100%", minWidth: "80px", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "이름",
        accessor: "name",
        Cell: ({ cell: { value }, row }: any) => {
            const userNo = row?.original?.userNo;
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                    onClick={() => {
                        navigate(`/users/detail/${userNo}`);
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "이메일",
        accessor: "email",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "120px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "구매정보",
        accessor: "productName",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "200px",
                    width: "100%",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품코드",
        accessor: "productCode",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "100px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "판매형태",
        accessor: "saleType",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ minWidth: "50px", width: "100%", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "결제수단",
        accessor: "paymentMethod",
        Cell: ({ cell: { value }, row }: any) => {
            const card = row?.original?.cardName;
            return (
                <div
                    style={{
                        minWidth: "180px",
                        fontSize: "13px",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <span>{value}</span>
                    {value && value !== "App Store" && value !== "-" && (
                        <span>{card}</span>
                    )}
                </div>
            );
        },
    },
    {
        Header: (
            <div>
                총 결제액
                <br />
                <span css={subsHeadText}>환불액</span>
            </div>
        ),
        accessor: "paymentAmount",
        Cell: ({ cell: { value }, row }: any) => {
            const refundAmount = row?.original?.refundAmount;

            return (
                <div
                    style={{
                        minWidth: "100px",
                        display: "flex",
                        width: "100%",
                        textAlign: "center",
                        flexDirection: "column",
                    }}
                >
                    <span>{ConvertPrice(value)}</span>
                    {refundAmount ? (
                        <span css={subsHeadText}>
                            - {ConvertPrice(refundAmount)}
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            );
        },
    },

    {
        Header: (
            <div>
                결제일시
                <br />
                <span css={subsHeadText}>환불일시</span>
            </div>
        ),
        accessor: "paymentDate",
        Cell: ({ cell: { value }, row }: any) => {
            const refundDate = row?.original?.refundDate;
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <span>{ConvertDateTime(value)}</span>
                    {refundDate && (
                        <span css={subsHeadText}>
                            {ConvertDateTime(refundDate)}
                        </span>
                    )}
                </div>
            );
        },
    },
    {
        Header: "결제상태",
        accessor: "paymentStatus",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value === "정상" && (
                        <Label text={value} color={"purple1"}></Label>
                    )}
                    {value === "부분환불" && (
                        <Label text={value} color={"refund"}></Label>
                    )}
                    {value === "전체환불" && (
                        <Label text={value} color={"allRefund"}></Label>
                    )}
                </div>
            );
        },
    },
    {
        Header: "파트너 여부",
        accessor: "partnerStatus",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },

    {
        Header: "UID",
        accessor: "paymentUid",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
];

const subsHeadText = css`
    color: red;
    font-size: 13px;
`;
