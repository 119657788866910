import React, { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Label } from "@src/components";
import { UserStatus, ExpertStatus, SubscriptionStatus } from "@src/constants/UserStatus";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { useDeleteProvision } from "@src/hooks/queries/useCommodityProvision";
import { useGetProvisionUserList } from "@src/hooks/queries/useProvisionUser";
import { BaseProvisionUser, ProvisionUser } from "@src/interfaces/Commodity.interface";
import moment from "moment";
export const getColumns = (
    navigate: any,
    setIsModalShow: Dispatch<SetStateAction<boolean>>,
    setIssueNo: Dispatch<SetStateAction<number>>,
    setProvisionUserList: Dispatch<SetStateAction<ProvisionUser[]>>,
    setUserParams: Dispatch<SetStateAction<string[]>>,
    setIsDeleteModalShow: Dispatch<SetStateAction<boolean>>,
    setIsViewModal: Dispatch<SetStateAction<boolean>>,
    setIssue: Dispatch<SetStateAction<any>>,
    setWillSendPush: Dispatch<SetStateAction<boolean>>,
) => [
    {
        Header: "지급 번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "100%",
                    minWidth: "80px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "등록일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value ? moment(value).format("YYYY.MM.DD HH:mm:ss") : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "상품코드",
        accessor: "code",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "120px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품명",
        accessor: "name",
        Cell: ({ cell: { value }, row }: any) => {
            const subs = row.original.subsGroupName;

            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {subs} {value}
                </div>
            );
        },
    },
    {
        Header: "사유",
        accessor: "comments",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "180px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "관리자",
        accessor: "adminName",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ minWidth: "50px", width: "100%", textAlign: "center" }}>{value ? value : INVALID_VALUE}</div>
        ),
    },
    {
        Header: "상태",
        accessor: "status",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "50px",
                    fontSize: "13px",
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        width: "80%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {value === 300 && <Label color="purple" text="지급 성공" />}
                    {value === 200 && <Label color="purpleBorder" text="등록" />}
                    {value === 202 && <Label color="orange" text="지급 실패" />}
                    {value === 400 && <Label color="expired" text="지급 취소" />}
                </div>
            </div>
        ),
    },
    {
        Header: "회원선택",
        accessor: "user",
        Cell: ({ cell: { value }, row }: any) => {
            const type = row.original.status;
            return (
                <div
                    style={{
                        minWidth: "80px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    {type === 200 ? (
                        <Button
                            label="추가"
                            color="gd"
                            handleClick={() => {
                                setIsModalShow(true);
                                setIssueNo(value);
                                setUserParams([]);
                                setIsViewModal(false);
                                setIssue(row.original);
                                useGetProvisionUserList(value).then((res) => {
                                    setProvisionUserList(res);
                                });
                            }}
                            size="small"
                        />
                    ) : (
                        <Button
                            label="확인"
                            color="gd"
                            handleClick={() => {
                                setIsModalShow(true);
                                setIssueNo(value);
                                setUserParams([]);
                                setIsViewModal(true);
                                setIssue(row.original);
                                setWillSendPush(row.original.isSentPush);
                                useGetProvisionUserList(value).then((res) => {
                                    setProvisionUserList(res);
                                });
                            }}
                            size="small"
                        />
                    )}
                </div>
            );
        },
    },

    {
        Header: "관리",
        accessor: "",
        Cell: ({ cell: { value }, row }: any) => {
            const id = row.original.no;
            const text = ExpertStatus.find((v) => v.code === value)?.text;
            const status = row.original.status;
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        gap: 5,
                    }}
                >
                    <IconButton
                        type="trash"
                        handleClick={() => {
                            // if (status === 200) {
                            setIsDeleteModalShow(true);
                            setIssueNo(id);
                            // } else {
                            //     alert("등록상태의 상품만 삭제 가능합니다.");
                            // }
                        }}
                    />
                    <IconButton
                        type="edit"
                        handleClick={() => {
                            if (status === 200) {
                                navigate(`update/${id}`);
                            } else {
                                alert("등록상태의 상품만 수정 가능합니다.");
                            }
                        }}
                    />
                </div>
            );
        },
    },

    {
        Header: "더보기",
        accessor: "viewControl",
        Cell: ({ row }: any) => {
            const id = row.original.no;

            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    <IconButton
                        type="arrow"
                        handleClick={() => {
                            navigate(`/commodity/provision/view/${id}`);
                        }}
                    />
                </div>
            );
        },
    },
];
