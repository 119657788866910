import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { validChecker } from "@src/utils/validationCheck";

interface ITextBox {
    label?: string;
    placeholder: string;
    size: "small" | "big" | "dynamic";
    maxLength?: number;
    handleChange: (value: string, isError?: boolean) => void;
    isDisabled: boolean;
    defaultText: string | Array<string>;
    isUseErrorMessage?: boolean;
    required?: boolean;
    isRowStyle?: boolean;
}
function TextBox({
    label,
    placeholder,
    size = "small",
    maxLength = 1000,
    handleChange,
    isDisabled,
    defaultText,
    isUseErrorMessage = true,
    required = false,
    isRowStyle = false,
}: ITextBox) {
    const [errorMessage, setErrorMessage] = useState("");
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let value = e.target.value;
        let isError = false;
        const { result: maxLengthError, errMsg: maxLengthMessage } =
            validChecker("maxLength", value, maxLength);
        const { result: spacebarError, errMsg: spacebarMessage } = validChecker(
            "spacebar",
            value,
        );
        if (isUseErrorMessage) {
            if (spacebarError) {
                setErrorMessage(spacebarMessage);
                isError = spacebarError;
            } else if (maxLengthError) {
                e.target.value = value.slice(0, maxLength);
                setErrorMessage(maxLengthMessage);
                isError = maxLengthError;
            } else if (value === "") {
                setErrorMessage("");
                isError = true;
            } else {
                setErrorMessage("");
            }
        }
        handleChange(value, isError);
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Enter") {
            e.stopPropagation();
        }
    };
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const textAreaResize = () => {
        if (textareaRef && textareaRef.current) {
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    };
    useEffect(() => {
        if (size === "dynamic" && isDisabled) {
            textAreaResize();
        }
    }, [textareaRef, defaultText]);
    return (
        <div css={[textBoxStyle, sizes[size], isRowStyle && rowBoxStyle]}>
            <div>
                {required && <strong css={requiredStyle}>*</strong>}
                {label && <span css={labelStyle}>{label}</span>}
            </div>
            <textarea
                ref={textareaRef}
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                disabled={isDisabled}
                style={
                    isDisabled
                        ? {
                              background: "transparent",
                              border: 0,
                              resize: "none",
                          }
                        : { resize: "none" }
                }
                value={defaultText}
            ></textarea>
            {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
    );
}

const textBoxStyle = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    span {
        display: inline-block;
        text-align: left;
        margin-bottom: 8px;
    }
    textarea {
        padding: 12px;
        width: 100%;
        max-width: 876px;
        border: 1.5px solid ${Common.colors.gray200};
        border-radius: 8px;
        outline: none;
        ${Common.textStyle.body3_R14}
    }
    textarea::placeholder {
        ${Common.textStyle.body3_R14};
        color: ${Common.colors.gray400};
    }
    p {
        width: 100%;
        ${Common.textStyle.body4_R12}
        color: ${Common.colors.error};
        /* margin-left: 105px; */
    }
`;

const rowBoxStyle = css`
    display: -webkit-box;
`;
const sizes: { [index: string]: any } = {
    small: css`
        textarea {
            height: 72px;
        }
    `,
    big: css`
        textarea {
            height: 160px;
        }
    `,
    dynamic: css`
        textarea {
            /* height: 100%; */
        }
    `,
};
const requiredStyle = css`
    padding-right: 5px;
    color: ${Common.colors.error};
`;

const labelStyle = css`
    display: inline-block;
    vertical-align: bottom;
    line-height: 42px;
    ${Common.textStyle.title5_M14}
`;
export default TextBox;
