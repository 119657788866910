import styled from "@emotion/styled";
import { Button, ConfirmModal, EditModal, Input, Title } from "@src/components";
import {
    useGetPartnerBenefits,
    useGetPartnerBenefitsList,
    usePostPartnerBenefits,
    useUpdatePartnerBenefits,
} from "@src/hooks/queries/usePartner";
import RelatedProduct from "@src/pages/commodity/RelatedProduct";
import { Common } from "@src/styles/Common";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PartnerBenefitsRegister = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { type } = location.state as any;
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [productList, setProductList] = useState<any[]>([]);
    const [selectItem, setSelectItem] = useState<any>({});
    const [registerParams, setRegisterParams] = useState<{
        productNo: number;
        title: string;
    }>({
        productNo: 0,
        title: "",
    });
    const [updateParams, setUpdateParams] = useState<{
        productNo: number;
        title: string;
    }>({
        productNo: 0,
        title: "",
    });
    const [isSaveModalShow, setIsSaveModalShow] = useState<boolean>(false);

    const { data } = useGetPartnerBenefitsList();
    const { data: partnerData, refetch } = useGetPartnerBenefits();

    const tryPostPartnerBenefits = async () => {
        postPartnerBenefits.mutate(registerParams);
    };

    const postPartnerBenefits = usePostPartnerBenefits(() => {
        refetch();
        navigate("/partner/benefits");
    });

    const tryUpdatePartnerBenefits = async () => {
        updatePartnerBenefits.mutate({
            data: updateParams,
            no: partnerData.no,
        });
    };

    const updatePartnerBenefits = useUpdatePartnerBenefits(() => {
        refetch();
        navigate("/partner/benefits");
    });
    useEffect(() => {
        setProductList(data);
    }, [data]);

    useEffect(() => {
        if (partnerData) {
            setUpdateParams({
                title: partnerData.title,
                productNo: partnerData.product.no,
            });
            setSelectItem({
                name: partnerData.product.name,
                code: partnerData.product.code,
                term1DiscountedPrice: partnerData.product.term1DiscountedPrice,
            });
        }
    }, [partnerData]);

    return (
        <>
            <StyledWrap>
                <Title title="파트너 혜택 등록" />
                <div className="contents">
                    <Title title="혜택1" />
                    <div className="ItemRow">
                        <span className="name">
                            <span className="essential">*</span>혜택명
                        </span>
                        <Input
                            placeholder={
                                "혜택명을 입력해주세요(APP에 노출됩니다)"
                            }
                            size="medium"
                            handleChange={(e) => {
                                type === "create"
                                    ? setRegisterParams({
                                          ...registerParams,
                                          title: e.target.value,
                                      })
                                    : setUpdateParams({
                                          ...updateParams,
                                          title: e.target.value,
                                      });
                            }}
                            value={
                                type === "create"
                                    ? registerParams.title
                                    : updateParams.title
                            }
                        />
                    </div>
                    <div className="ItemRow">
                        <span className="name">
                            <span className="essential">*</span>상품연결
                        </span>
                        <table>
                            <thead>
                                <tr>
                                    <th>상품명</th>
                                    <th>상품코드</th>
                                    <th>가격</th>
                                    <th>해지</th>
                                </tr>
                            </thead>

                            <tbody>
                                {(type === "create" &&
                                    registerParams.productNo) ||
                                (type === "update" &&
                                    updateParams.productNo) ? (
                                    <tr>
                                        <td>{selectItem.name}</td>
                                        <td>{selectItem.code}</td>
                                        <td>
                                            {selectItem.term1DiscountedPrice}원
                                        </td>
                                        <td>
                                            <Button
                                                label="해지"
                                                color="whiteOp"
                                                size="small"
                                                handleClick={() => {
                                                    setSelectItem({});
                                                    if (type === "create") {
                                                        setRegisterParams({
                                                            ...registerParams,
                                                            productNo: 0,
                                                        });
                                                    } else {
                                                        setUpdateParams({
                                                            ...updateParams,
                                                            productNo: 0,
                                                        });
                                                    }
                                                }}
                                                styles={{ borderRadius: "4px" }}
                                            />
                                        </td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "#EEEEEE",
                                            }}
                                            colSpan={4}
                                        >
                                            <Button
                                                label="상품연결"
                                                color="whiteOp"
                                                size="small"
                                                handleClick={() => {
                                                    setIsModalShow(true);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <StlyedBtnDiv
                    disabled={
                        type === "create"
                            ? !(
                                  registerParams.productNo &&
                                  registerParams.title
                              )
                            : !(updateParams.productNo && updateParams.title)
                    }
                >
                    <Button
                        color="gd"
                        handleClick={() => {
                            setIsSaveModalShow(true);
                        }}
                        label="저장"
                        size="medium"
                        isDisabled={
                            type === "create"
                                ? !(
                                      registerParams.productNo &&
                                      registerParams.title
                                  )
                                : !(
                                      updateParams.productNo &&
                                      updateParams.title
                                  )
                        }
                    />
                </StlyedBtnDiv>
            </StyledWrap>
            <EditModal
                buttonText="입력"
                handleBtnClick={(value?: any) => {
                    if (type === "update") {
                        setUpdateParams({
                            ...updateParams,
                            productNo: selectItem.no,
                        });
                    } else {
                        setRegisterParams({
                            ...registerParams,
                            productNo: selectItem.no,
                        });
                    }
                }}
                handleOpenModal={() => {
                    setIsModalShow(false);
                }}
                size="big"
                title="상품 조회"
                isModalShow={isModalShow}
            >
                <div>
                    <RelatedProduct
                        connectedProductList={productList}
                        setSelectedItem={setSelectItem}
                        selectedItem={selectItem}
                        productHeaderType={[
                            { title: "상품명", key: "name" },
                            { title: "상품코드", key: "code" },
                        ]}
                    />
                </div>
            </EditModal>
            <ConfirmModal
                isModalShow={isSaveModalShow}
                buttonText="확인"
                handleCancel={() => setIsSaveModalShow(false)}
                handleOk={() => {
                    const { type } = location.state as any;
                    type === "create"
                        ? tryPostPartnerBenefits().then((res) => {
                              navigate("/partner/benefits");
                          })
                        : tryUpdatePartnerBenefits().then(() => {
                              navigate("/partner/benefits");
                          });
                }}
            >
                정말 저장 하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default PartnerBenefitsRegister;

const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 25px;

    .btnDiv {
        position: absolute;
        bottom: 23px;
        right: 39px;
        & > button {
            background: #0029ff;
        }
    }
    .contents {
        display: flex;
        flex-direction: column;
        gap: 35px;
        .ItemRow {
            display: flex;
            gap: 16px;
            align-items: center;
            .name {
                width: 96px;
                .essential {
                    color: #f45b5b;
                }
            }
            & > input {
                border: 1px solid ${Common.colors.gray200};
                border-radius: 8px;
                padding: 12px;
                height: 48px;
            }
            & > table {
                width: 600px;
                border-top: 1px solid #b5b5b5;
                border-bottom: 1px solid #b5b5b5;
                & > thead {
                    border-bottom: 1px solid #b5b5b5;
                    & > tr {
                        height: 32px;
                        padding: 6px 12px;
                        box-sizing: border-box;
                        & > th {
                            text-align: left;
                            vertical-align: middle;
                            font-weight: 400;
                            :nth-of-type(1) {
                                width: 60%;
                            }
                            :nth-of-type(2) {
                                width: 20%;
                            }
                            :nth-of-type(3) {
                                width: 10%;
                            }
                            :nth-of-type(4) {
                                text-align: center;
                                width: 10%;
                            }
                        }
                    }
                }
                & > tbody {
                    & > tr {
                        height: 48px;
                        & > td {
                            height: 35px;
                            text-align: left;
                            vertical-align: middle;
                            & > button {
                                border-radius: 100px;
                                height: 28px;
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const StlyedBtnDiv = styled.div<{ disabled: boolean }>`
    position: absolute;
    bottom: 23px;
    right: 39px;
    & > button {
        background: ${({ disabled }) => (disabled ? "" : "#0029ff")};
        /* background:$? #0029ff; */
    }
`;
