import { css } from "@emotion/react";
import { Checkbox, DropDown, Input, RangeCalendar } from "@src/components";
import {
    initFilters,
    IPaymentListFilter,
} from "@src/hooks/queries/usePaymentList";
import { Common } from "@src/styles/Common";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";

interface PaymentListFilterProps {
    dropList: {
        id: number;
        key: string;
        value: string;
    }[];
    filters: IPaymentListFilter;
    setFilters: Dispatch<SetStateAction<IPaymentListFilter>>;
}

const PaymentListFilter = ({
    dropList,
    filters,
    setFilters,
}: PaymentListFilterProps) => {
    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const dateLabel = [
        //날짜 라디오 버튼
        { id: 0, startDate: undefined, value: "전체" },
        { id: 1, startDate: TODAY, value: "오늘" },
        { id: 2, startDate: before7day, value: "7일" },
        { id: 3, startDate: before1Month, value: "1개월" },
        { id: 4, startDate: before6Month, value: "6개월" },
        { id: 5, startDate: before12Month, value: "12개월" },
    ];

    const renderDate = (item: number, start: string, end: string) => {
        if (item == 0) {
            setFilters({
                ...filters,
                [start]: undefined,
                [end]: undefined,
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                [start]: before7day,
                [end]: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                [start]: before1Month,
                [end]: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                [start]: before6Month,
                [end]: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                [start]: before12Month,
                [end]: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                [start]: TODAY,
                [end]: TODAY,
            });
        }
    };
    const saleTypeModel = [
        { value: 0, label: "일반판매 미노출", isChecked: false },
        { value: 1, label: "일반판매 노출", isChecked: false },
        { value: 2, label: "파트너스 제휴 판매 노출", isChecked: false },
        { value: 3, label: "직원용", isChecked: false },
        { value: 4, label: "무료 쿠폰용", isChecked: false },
        { value: 5, label: "유료 쿠폰용", isChecked: false },
        { value: 6, label: "AOS노출용", isChecked: false },
        { value: 7, label: "IOS노출용", isChecked: false },
        { value: 8, label: "WEB노출용", isChecked: false },
    ];
    const paymentMethodsModel = [
        { value: "카드결제", label: "카드결제", isChecked: false },
        { value: "AppStore", label: "App Store", isChecked: false },
        { value: "기타", label: "기타", isChecked: false },
    ];
    const paymentStatusesModel = [
        { value: "정상", label: "정상", isChecked: false },
        { value: "부분환불", label: "부분환불", isChecked: false },
        { value: "전체환불", label: "전체환불", isChecked: false },
    ];
    const partnerStatusesModel = [
        { value: "입력", label: "입력", isChecked: false },
        { value: "미입력", label: "미입력", isChecked: false },
    ];

    const changeCheckBox = (value: any, isChecked: boolean, type: string) => {
        const newFilters: any = { ...filters };
        let insertData: any = [];

        if (!JSON.stringify(newFilters[type])) {
            insertData = [value];
        } else if (JSON.stringify(newFilters[type]).includes(value)) {
            insertData = newFilters[type].filter((data: any) => data !== value);
        } else {
            insertData = newFilters[type].concat(value);
        }
        setFilters({
            ...filters,
            [type]: insertData,
        });
    };

    const handleDropChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setFilters({
            ...filters,
            searchType: e.target.value,
            searchValue: "",
        });
    };

    return (
        <>
            <div css={FilterDiv}>
                <ul>
                    <li>
                        <span className="subTitle">결제일시</span>
                        <div>
                            {dateLabel.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <button
                                            value={item.value}
                                            className={
                                                "datebtn" +
                                                (filters?.paymentStartDate ===
                                                item?.startDate
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                renderDate(
                                                    item.id,
                                                    "paymentStartDate",
                                                    "paymentEndDate",
                                                )
                                            }
                                        >
                                            {item.value}
                                        </button>
                                    </div>
                                );
                            })}
                            <RangeCalendar
                                setRangeDate={{
                                    startDate:
                                        filters.paymentStartDate !== null &&
                                        filters.paymentStartDate !== undefined
                                            ? dateParse(
                                                  filters.paymentStartDate,
                                              )
                                            : null,
                                    endDate:
                                        filters.paymentEndDate !== null &&
                                        filters.paymentEndDate !== undefined
                                            ? dateParse(filters.paymentEndDate)
                                            : null,
                                }}
                                getUserRangeDate={(date, isError) => {
                                    const { startDate: start, endDate: end } =
                                        date;

                                    setFilters({
                                        ...filters,
                                        paymentStartDate: start
                                            ? ConvertDate(start)
                                            : undefined,
                                        paymentEndDate: end
                                            ? ConvertDate(end)
                                            : undefined,
                                    });
                                }}
                                verticalAlign={false}
                                isDisabled={false}
                                timePicker={false}
                            />
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">환불일시</span>
                        <div>
                            {dateLabel.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <button
                                            value={item.value}
                                            className={
                                                "datebtn" +
                                                (filters?.refundStartDate ===
                                                item?.startDate
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                renderDate(
                                                    item.id,
                                                    "refundStartDate",
                                                    "refundEndDate",
                                                )
                                            }
                                        >
                                            {item.value}
                                        </button>
                                    </div>
                                );
                            })}
                            <RangeCalendar
                                setRangeDate={{
                                    startDate:
                                        filters.refundStartDate !== null &&
                                        filters.refundStartDate !== undefined
                                            ? dateParse(filters.refundStartDate)
                                            : null,
                                    endDate:
                                        filters.refundEndDate !== null &&
                                        filters.refundEndDate !== undefined
                                            ? dateParse(filters.refundEndDate)
                                            : null,
                                }}
                                getUserRangeDate={(date, isError) => {
                                    const { startDate: start, endDate: end } =
                                        date;
                                    setFilters({
                                        ...filters,
                                        refundStartDate: start
                                            ? ConvertDate(start)
                                            : undefined,
                                        refundEndDate: end
                                            ? ConvertDate(end)
                                            : undefined,
                                    });
                                }}
                                verticalAlign={false}
                                isDisabled={false}
                                timePicker={false}
                            />
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">판매형태</span>
                        <div className="chkBoxDiv">
                            {saleTypeModel.map((saleType: any, idx: number) => (
                                <Checkbox
                                    key={idx.toString()}
                                    isDisabled={false}
                                    isChecked={Boolean(
                                        JSON.stringify(filters?.saleTypes) &&
                                            JSON.stringify(
                                                filters?.saleTypes,
                                            ).includes(saleType?.value),
                                    )}
                                    label={saleType.label}
                                    handleChange={(n, v) => {
                                        changeCheckBox(
                                            saleType?.value,
                                            v,
                                            "saleTypes",
                                        );
                                    }}
                                />
                            ))}
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">결제수단</span>
                        <div className="chkBoxDiv">
                            {paymentMethodsModel.map(
                                (paymentMethods: any, idx: number) => (
                                    <Checkbox
                                        key={idx.toString()}
                                        isDisabled={false}
                                        isChecked={Boolean(
                                            JSON.stringify(
                                                filters?.paymentMethods,
                                            ) &&
                                                JSON.stringify(
                                                    filters?.paymentMethods,
                                                ).includes(
                                                    paymentMethods?.value,
                                                ),
                                        )}
                                        label={paymentMethods.label}
                                        handleChange={(n, v) => {
                                            changeCheckBox(
                                                paymentMethods?.value,
                                                v,
                                                "paymentMethods",
                                            );
                                        }}
                                    />
                                ),
                            )}
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">결제상태</span>
                        <div className="chkBoxDiv">
                            {paymentStatusesModel.map(
                                (paymentStatuses: any, idx: number) => (
                                    <Checkbox
                                        key={idx.toString()}
                                        isDisabled={false}
                                        isChecked={Boolean(
                                            JSON.stringify(
                                                filters?.paymentStatuses,
                                            ) &&
                                                JSON.stringify(
                                                    filters?.paymentStatuses,
                                                ).includes(
                                                    paymentStatuses?.value,
                                                ),
                                        )}
                                        label={paymentStatuses.label}
                                        handleChange={(n, v) => {
                                            changeCheckBox(
                                                paymentStatuses?.value,
                                                v,
                                                "paymentStatuses",
                                            );
                                        }}
                                    />
                                ),
                            )}
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">파트너상태</span>
                        <div className="chkBoxDiv">
                            {partnerStatusesModel.map(
                                (partnerStatuses: any, idx: number) => (
                                    <Checkbox
                                        key={idx.toString()}
                                        isDisabled={false}
                                        isChecked={Boolean(
                                            JSON.stringify(
                                                filters?.partnerStatuses,
                                            ) &&
                                                filters?.partnerStatuses?.filter(
                                                    (item) =>
                                                        item ===
                                                        partnerStatuses?.value,
                                                ).length,
                                        )}
                                        label={partnerStatuses.label}
                                        handleChange={(n, v) => {
                                            changeCheckBox(
                                                partnerStatuses?.value,
                                                v,
                                                "partnerStatuses",
                                            );
                                        }}
                                    />
                                ),
                            )}
                        </div>
                    </li>

                    <li>
                        <span className="subTitle">총 결제액</span>
                        <Input
                            placeholder={"금액입력"}
                            handleChange={(e) => {
                                setFilters({
                                    ...filters,
                                    minPaymentAmount: Number(e.target.value),
                                });
                            }}
                            size={"small"}
                            value={filters?.minPaymentAmount ?? ""}
                            type={"number"}
                            onKeyDown={(e) => {
                                if (filters.maxPaymentAmount === 0) {
                                    e.key === "Backspace" &&
                                        setFilters({
                                            ...filters,
                                            minPaymentAmount: undefined,
                                        });
                                }
                            }}
                        />
                        ~
                        <Input
                            placeholder={"금액입력"}
                            handleChange={(e) => {
                                setFilters({
                                    ...filters,
                                    maxPaymentAmount: Number(e.target.value),
                                });
                            }}
                            size={"small"}
                            value={filters?.maxPaymentAmount ?? ""}
                            type={"number"}
                            onKeyDown={(e) => {
                                if (filters.maxPaymentAmount === 0) {
                                    e.key === "Backspace" &&
                                        setFilters({
                                            ...filters,
                                            maxPaymentAmount: undefined,
                                        });
                                }
                            }}
                        />
                    </li>
                    <li>
                        <span className="subTitle">검색</span>
                        <div>
                            <DropDown
                                handleChange={handleDropChange}
                                data={dropList}
                                defaultValue={"이름"}
                            />
                            <Input
                                label=""
                                value={filters.searchValue ?? ""}
                                name="searchInput"
                                type="text"
                                size="medium"
                                handleChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        searchValue: e.target.value,
                                    });
                                }}
                                onKeyDown={(e: any) => {}}
                                isDisabled={false}
                                errorMessage=""
                                placeholder="입력해주세요"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default PaymentListFilter;

const FilterDiv = css`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .datebtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .datebtn.active {
        font-weight: 700;
    }
`;
