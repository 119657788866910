import { createSlice } from "@reduxjs/toolkit";
import { SeizeInfoType } from "@src/types";

const seizeInformationData: SeizeInfoType = {
    important:
        "- 공매 재산에 대하여 등기된 권리 또는 가처분으로서 매각으로 효력을 잃지 아니하는 것 \n- 공매 재산의 매수인으로서 일정한 자격을 필요로 하는 경우 그 사실",
    caution:
        "본 건은 권리신고한 임차인의 서류에 의하여 대항력 있는 임차인이 있을 수 있으므로 사전조사 후 입찰 바람",
};

const initialState = seizeInformationData;

export const seizeInfoSlice = createSlice({
    name: "seizeInfo",
    initialState,
    reducers: {
        createSeizeInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateSeizeInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createSeizeInfo, updateSeizeInfo } = seizeInfoSlice.actions;

export default seizeInfoSlice.reducer;
