import { css } from "@emotion/react";
import { Title } from "@src/components";
import { useCreateProvision } from "@src/hooks/queries/useCommodityProvision";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProvisionForm, { ProductType } from "./ProvisionForm";
import { ProductDataType } from "./ProvisionUpdate";

const ProvisionCreate = () => {
    const productInitialState: ProductType = {
        no: 0,
        type: "",
        code: "",
        name: "",
        subs_group: "",
        subs_group_name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    };
    const productDataInitalState: ProductDataType = {
        no: 0,
        productNo: 0,
        // type: "",
        // code: "",
        name: "",
        // subs_group: "",
        // subs_group_name: "",
        // postbox_situation_code: "",
        // issue_type: null,
        // status: 0,
        comments: "",
        // created_by: 0,
        // updated_by: 0,
        // created_at: "",
        // updated_at: "",
    };
    const navigate = useNavigate();
    const [productList, setProductList] = useState<ProductType[]>([
        productInitialState,
    ]);

    const [productData, setProductData] = useState<ProductDataType>(
        productDataInitalState,
    );
    const tryCreateProvision = async () => {
        const response = await useCreateProvision(productData);
        navigate("/commodity/provision", { state: true });
    };
    return (
        <>
            <div css={detailStyle}>
                <Title title="상품 지급 등록" />
                <ProvisionForm
                    type="create"
                    productList={productList}
                    setProductList={setProductList}
                    productData={productData}
                    setProductData={setProductData}
                    tryCreateProvision={tryCreateProvision}
                />
            </div>
        </>
    );
};

export default ProvisionCreate;

const detailStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 68px;
    gap: 20px;
`;
