import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { Button, DropDown, EditModal, Input, Title } from "@src/components";
import {
    ILotViewsCountFilter,
    useGetExcelDownloadList,
    useGetLotViewsCount,
    useGetLotViewsCountExcel,
} from "@src/hooks/queries/useViewsCount";

import { ConvertDateLine, ConvertDateTime } from "@src/utils/dateUtil";
import UserLotViewersFilters from "./UserLotViewersFilters";
import UserLotViewersTable from "./UserLotViewersTable";
import useInfiniteScroll from "react-infinite-scroll-hook";

const UserLotViewersList = () => {
    const navigate = useNavigate();
    const TODAY = ConvertDateLine(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before1Month = ConvertDateLine(
        new Date(year, month - 1, day).getTime(),
    );
    const [filters, setFilters] = useState<ILotViewsCountFilter>({
        startDate: before1Month,
        endDate: TODAY,
        sortType: 0,
        getCount: 100,
    });
    const [reason, setReason] = useState<string>("");
    const [isOpenReasonModal, setIsOpenReasonModal] = useState<boolean>(false);
    const [isOpenDownloadListModal, setIsOpenDownloadListModal] =
        useState<boolean>(false);

    const { data, refetch } = useGetLotViewsCount(filters);

    const {
        data: excelDownloadList,
        refetch: refetchExcelDownloadList,
        hasNextPage,
        fetchNextPage,
    } = useGetExcelDownloadList({ page: 1, limit: 10 });

    const sortList = [
        {
            key: 0,
            value: "경공매 열람횟수 높은순",
        },
        {
            key: 1,
            value: "필지 열람횟수(로그인)높은순",
        },
        {
            key: 2,
            value: "필지 열람횟수(비로그인)높은순",
        },
    ];

    const [viewMoreRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: () => {
            fetchNextPage();
        },
        disabled: false,
        delayInMs: 100,
    });

    useEffect(() => {
        refetch();
    }, []);

    return (
        <>
            <div css={wrapper}>
                <div className="titleWrap">
                    <Title title="필지상세 열람횟수 조회" />
                </div>
                <UserLotViewersFilters
                    filters={filters}
                    setFilters={setFilters}
                />
                <div css={buttonWrapStyle}>
                    <Button
                        color="gray"
                        handleClick={() => {
                            setFilters({});
                            refetch();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            refetch();
                        }}
                        label="검색"
                        size="medium"
                    />
                </div>
                <div css={btnWrap}>
                    <div css={buttonWrapStyle}>
                        <Button
                            color="white"
                            handleClick={() => {
                                setIsOpenReasonModal(true);
                                setReason("");
                            }}
                            label="엑셀 다운로드"
                            size="medium"
                        />
                        <Button
                            color="purple"
                            handleClick={() => {
                                setIsOpenDownloadListModal(true);
                                refetchExcelDownloadList();
                            }}
                            label="내역"
                            size="small"
                        />
                    </div>
                    <DropDown
                        handleChange={(e) => {
                            setFilters({
                                ...filters,
                                sortType: Number(e.target.value),
                            });
                            refetch();
                        }}
                        data={sortList}
                        defaultValue={"경공매 열람횟수 높은순"}
                    />
                </div>

                <div css={tableDiv}>
                    <div css={countDivStyle}>
                        <span>총 {data?.totalCount}건</span>
                    </div>
                    <UserLotViewersTable
                        data={data?.list ?? []}
                        navigate={navigate}
                    />
                </div>
            </div>
            <EditModal
                isModalShow={isOpenReasonModal}
                title={"엑셀 다운로드"}
                handleOpenModal={() => {
                    setIsOpenReasonModal(false);
                }}
                size={"fitContent"}
                handleBtnClick={() => {
                    useGetLotViewsCountExcel(filters, reason);
                }}
                isDisabled={!reason}
            >
                <div>
                    <Input
                        placeholder={"사유를 입력해주세요"}
                        handleChange={(e) => {
                            setReason(e.target.value);
                        }}
                        size={"medium"}
                        value={reason}
                        label="사유"
                    />
                </div>
            </EditModal>
            <EditModal
                isModalShow={isOpenDownloadListModal}
                title={"엑셀 다운로드 내역"}
                handleOpenModal={() => {
                    setIsOpenDownloadListModal(false);
                }}
                size={"fitContent"}
                handleBtnClick={() => {}}
                deleteButton
            >
                <div css={itemModalWrap}>
                    <table css={tableCss}>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>다운로드 일시</th>
                                <th>관리자 이름</th>
                                <th>사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {excelDownloadList?.pages.map((page: any) =>
                                page.list.map((item: any, index: number) => (
                                    <tr key={String([item, index])}>
                                        <td>{item?.no}</td>
                                        <td>
                                            {ConvertDateTime(
                                                item?.downloadDate,
                                            )}
                                        </td>
                                        <td>{item?.adminName}</td>
                                        <td>{item?.reason}</td>
                                    </tr>
                                )),
                            )}
                            <tr>
                                <td
                                    ref={viewMoreRef}
                                    css={scrollDiv}
                                    colSpan={8}
                                ></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </EditModal>
        </>
    );
};

export default UserLotViewersList;

const wrapper = css`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow: hidden;
    .titleWrap {
        display: flex;
        gap: 6px;
        button {
            cursor: pointer;
            img {
                width: 20px;
            }
        }
    }
`;

const modalWrap = css`
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
    line-height: 24px;
`;

const btnWrap = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

const buttonWrapStyle = css`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin: 32px 0;
`;

const tableDiv = css`
    width: 100%;
    height: 90vh;
    & > div {
        & > div:nth-of-type(1) {
            display: none;
        }
    }
`;

const countDivStyle = css`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    & > span {
        padding-right: 10px;
        border-right: 1px solid black;
    }
    & > span:last-of-type {
        border-right: none;
    }
`;

const itemModalWrap = css`
    width: 1000px;
`;

const tableCss = css`
    width: 100%;
    & > thead {
        background-color: #f3f3f3;
        & > tr {
            display: table;
            width: 100%;
            & > th {
                width: 25%;
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
    & > tbody {
        display: block;
        max-height: 500px;
        overflow-y: scroll;
        & > tr {
            display: table;
            width: 100%;
            & > td {
                width: 25%;
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
`;

const scrollDiv = css`
    padding: 40px;
`;
