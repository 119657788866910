import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { useDispatch, useSelector } from "react-redux";
import {
    Title,
    IconButton,
    ToggleButton,
    EditModal,
    ConfirmModal,
} from "@src/components";
import { ViewCategoryButton } from "./containers";
import {
    STANDARD_INFORMATION,
    EXPECTED_RETURN,
    COMMENT,
    DETAIL_INFORMATION,
    BUILDING_INFORMATION,
    SURROUND_INFORMATION,
    BID_HISTORY,
    LOCATION_STATUS,
    SEIZE_INFORMATION,
    SALE_CASE,
    REGISTERED_INFO_PUBLIC,
    RENT_INFO_PUBLIC,
    OCCUPANCY_RELATION,
    DISTRIBUTION_OR_BOND,
} from "@src/constants/ProductAuctionTitles";
import { startLoading, endLoading } from "@src/reducers/loading";
import {
    ProductPublicContainers,
    renderComponent,
} from "./ProductPublicContainers";

const components = ProductPublicContainers;

function ConfirmPopUpModal({
    isModalShow,
    handleBtnClick,
    handleOpenModal,
}: any) {
    return (
        <ConfirmModal
            isModalShow={isModalShow}
            buttonText="저장"
            handleCancel={handleOpenModal}
            handleOk={handleBtnClick}
        >
            <>
                정보를 수정하시겠습니까? 올바르게 입력하였는지 다시 한번
                확인해주세요.
            </>
        </ConfirmModal>
    );
}

function PopUpModal({
    container,
    isModalShow,
    title,
    handleBtnClick,
    handleOpenModal,
}: any) {
    const SelectContainer = components[container]["component"];
    const [updateData, setUpdateData] = useState("");
    const handleUpdate = (data: any) => setUpdateData(data);
    //수정 확인 팝업
    const [isConfirmModalShow, setIsConfirmModalShow] = useState(false);
    const handleOkClick = () => {
        setIsConfirmModalShow((prev) => !prev);
        handleBtnClick(updateData, container);
    };
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    return (
        <>
            <EditModal
                isModalShow={isModalShow}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsConfirmModalShow(true);
                }}
                handleOpenModal={handleOpenModal}
                size={
                    [
                        "STANDARD_INFORMATION",
                        "BID_HISTORY",
                        "SALE_CASE",
                        "DISTRIBUTION_OR_BOND",
                    ].includes(container)
                        ? "fitContent"
                        : "big"
                }
                title={title}
                isDisabled={submitDisabled}
            >
                <SelectContainer
                    isUpdate={true}
                    handleUpdate={handleUpdate}
                    handleSubmitDisabled={(value: boolean) => {
                        setSubmitDisabled(value);
                    }}
                />
            </EditModal>
            <ConfirmPopUpModal
                isModalShow={isConfirmModalShow}
                handleBtnClick={handleOkClick}
                handleOpenModal={() => setIsConfirmModalShow((prev) => !prev)}
            />
        </>
    );
}

function ProductPublicDetail() {
    useEffect(() => {
        window.scrollTo(0, 0); // 페이지 이동 시 스크롤 상단으로 고정
    }, []);
    const dispatch = useDispatch();
    const handleSubmit = (patchData: any, container: any) => {
        const updateAction = components[container]["updateAction"];

        dispatch(startLoading());

        setTimeout(() => {
            if (updateAction) {
                dispatch(updateAction(patchData));
            }
            dispatch(endLoading());
        }, 700);
    };

    //개별 모달 팝업 에러 발생 시
    const [networkErrorModalShow, setNetworkErrorModalShow] = useState(false);
    const [networkErrorMessage] = useState("");
    const handleErrorBtnClick = () => setNetworkErrorModalShow((prev) => !prev);

    //수정 팝업
    const [isModalShow, setIsModalShow] = useState(false);
    const [container, setContainer] = useState("STANDARD_INFORMATION");
    const [title, setTitle] = useState("");

    //노출 카테고리
    const defaultViewCategoryList = useSelector(
        (state: any) => state.productViewCategory,
    );
    const [defaultViewCategory, setDefaultViewCategory] = useState<{
        [key: string]: any;
    }>({});

    useEffect(() => {
        if (defaultViewCategoryList) {
            let result = {};
            for (let i = 0; i < defaultViewCategoryList.length; i++) {
                result = {
                    ...result,
                    [defaultViewCategoryList[i]]: true,
                };
            }
            setDefaultViewCategory({
                ...defaultViewCategory,
                ...result,
            });
            setUpdateViewCategory(defaultViewCategoryList);
        }
    }, [defaultViewCategoryList]);
    const [updateViewCategory, setUpdateViewCategory] = useState<any>([]);
    const handleToggleOfViewCategory = (name: string, checked: boolean) => {
        if (checked) {
            setUpdateViewCategory([...updateViewCategory, name]);
        } else {
            const filterCategory = updateViewCategory.filter(
                (v: any) => v !== name,
            );
            setUpdateViewCategory([...filterCategory]);
        }
    };

    const handleClick = (containerName: string, title: string) => {
        setIsModalShow((prev) => !prev);
        setContainer(containerName);
        setTitle(title);
    };
    const handleOpenModal = () => setIsModalShow((prev) => !prev);

    return (
        <div css={formDiv}>
            <Title title="매물 상세" />
            <EditModal
                isModalShow={networkErrorModalShow}
                buttonText="확인"
                handleBtnClick={() => {}}
                handleOpenModal={handleErrorBtnClick}
                size="medium"
                title="오류 발생"
            >
                <>{networkErrorMessage}</>
            </EditModal>
            <PopUpModal
                container={container}
                title={title}
                isModalShow={isModalShow}
                handleBtnClick={handleSubmit}
                handleOpenModal={handleOpenModal}
            />
            <div>
                <Title title={STANDARD_INFORMATION} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick(
                            "STANDARD_INFORMATION",
                            STANDARD_INFORMATION,
                        )
                    }
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"STANDARD_INFORMATION"}
                    value={defaultViewCategory["STANDARD_INFORMATION"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div
                    className="container"
                    style={{ padding: "0", border: "none" }}
                >
                    {renderComponent("STANDARD_INFORMATION", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={EXPECTED_RETURN} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick("EXPECTED_RETURN", EXPECTED_RETURN)
                    }
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"EXPECTED_RETURN"}
                    value={defaultViewCategory["EXPECTED_RETURN"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div
                    className="container"
                    style={{ padding: "0", border: "none" }}
                >
                    {renderComponent("EXPECTED_RETURN", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={COMMENT} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() => handleClick("COMMENT", COMMENT)}
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"COMMENT"}
                    value={defaultViewCategory["COMMENT"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">{renderComponent("COMMENT")}</div>
            </div>
            <div>
                <Title title={DETAIL_INFORMATION} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick("DETAIL_INFORMATION", DETAIL_INFORMATION)
                    }
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"DETAIL_INFORMATION"}
                    value={defaultViewCategory["DETAIL_INFORMATION"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    {renderComponent("DETAIL_INFORMATION", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={BUILDING_INFORMATION} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick(
                            "BUILDING_INFORMATION",
                            BUILDING_INFORMATION,
                        )
                    }
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"BUILDING_INFORMATION"}
                    value={defaultViewCategory["BUILDING_INFORMATION"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    {renderComponent("BUILDING_INFORMATION", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={BID_HISTORY} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() => handleClick("BID_HISTORY", BID_HISTORY)}
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"BID_HISTORY"}
                    value={defaultViewCategory["BID_HISTORY"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    {renderComponent("BID_HISTORY", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={LOCATION_STATUS} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick("LOCATION_STATUS", LOCATION_STATUS)
                    }
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"LOCATION_STATUS"}
                    value={defaultViewCategory["LOCATION_STATUS"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    {renderComponent("LOCATION_STATUS", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={SURROUND_INFORMATION} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick(
                            "SURROUND_INFORMATION",
                            SURROUND_INFORMATION,
                        )
                    }
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"SURROUND_INFORMATION"}
                    value={defaultViewCategory["SURROUND_INFORMATION"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    {renderComponent("SURROUND_INFORMATION", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div className="seizeContainer">
                <Title title={SEIZE_INFORMATION} />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"SEIZE_INFORMATION"}
                    value={defaultViewCategory["SEIZE_INFORMATION"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    <IconButton
                        type="edit"
                        color="orange"
                        handleClick={() =>
                            handleClick("SEIZE_INFORMATION", SEIZE_INFORMATION)
                        }
                    />
                    {renderComponent("SEIZE_INFORMATION", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div className="subSeizeContainer">
                <Title title={RENT_INFO_PUBLIC} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick("RENT_INFO_PUBLIC", RENT_INFO_PUBLIC)
                    }
                />
                <div className="container">
                    {renderComponent("RENT_INFO_PUBLIC", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div className="subSeizeContainer">
                <Title title={REGISTERED_INFO_PUBLIC} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick(
                            "REGISTERED_INFO_PUBLIC",
                            REGISTERED_INFO_PUBLIC,
                        )
                    }
                />
                <div className="container">
                    {renderComponent("REGISTERED_INFO_PUBLIC", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div className="subSeizeContainer">
                <Title title={DISTRIBUTION_OR_BOND} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick(
                            "DISTRIBUTION_OR_BOND",
                            DISTRIBUTION_OR_BOND,
                        )
                    }
                />
                <div className="container">
                    {renderComponent("DISTRIBUTION_OR_BOND", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div className="subSeizeContainer">
                <Title title={OCCUPANCY_RELATION} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() =>
                        handleClick("OCCUPANCY_RELATION", OCCUPANCY_RELATION)
                    }
                />
                <div className="container">
                    {renderComponent("OCCUPANCY_RELATION", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <div>
                <Title title={SALE_CASE} />
                <IconButton
                    type="edit"
                    color="orange"
                    handleClick={() => handleClick("SALE_CASE", SALE_CASE)}
                />
                <span>카테고리 노출</span>
                <ToggleButton
                    name={"SALE_CASE"}
                    value={defaultViewCategory["SALE_CASE"]}
                    handleToggle={handleToggleOfViewCategory}
                />
                <div className="container">
                    {renderComponent("SALE_CASE", {
                        isUpdate: false,
                    })}
                </div>
            </div>
            <ViewCategoryButton
                updateViewCategory={updateViewCategory}
                dispatch={dispatch}
            />
        </div>
    );
}

ProductPublicDetail.propTypes = {
    defaultViewCategoryList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
};

const formDiv = css`
    padding: 40px;
    width: 100%;
    & > div:first-of-type {
        margin-bottom: 40px;
    }
    & > div {
        width: 100%;
        min-width: 1200px;
        max-width: 1800px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    button {
        margin-left: 20px;
        margin-right: auto;
        & + span {
            ${Common.textStyle.title6_R14}
            color: ${Common.colors.purple500};
            padding-right: 10px;
        }
    }
    .disabledEdit button {
        opacity: 0;
        visibility: hidden;
    }
    .container {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;
        height: 100%;
        border: none;
        padding: 0;
    }
    .shadow {
        height: 1000vh;
    }
    .notEdit {
        & > button {
            opacity: 0;
            visibility: hidden;
        }
    }
    & > button:last-of-type {
        float: right;
    }
    .seizeContainer {
        position: relative;
        & > span {
            ${Common.textStyle.title6_R14}
            color: ${Common.colors.purple500};
            padding-right: 10px;
            margin-left: auto;
        }
        .container > button {
            position: absolute;
            left: 150px;
            top: 55px;
        }
    }
    .subSeizeContainer > div:first-of-type {
        padding-left: 20px;
        font-size: 14px;
    }
`;
export default ProductPublicDetail;
