import React, { useEffect, useState, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, Checkbox } from "@src/components";
import {
    useUsageBigData,
    useUsageSmallData,
    LIVING,
    LAND,
    COMMERCE,
    INDUSTRY,
    UNCLASSIFIED,
} from "@src/hooks/queries/useUsage";

interface IFilterUsage {
    clear: boolean;
    setClear: Function;
    setPropsBuildings: Function;
}

function FilterUsage({ clear, setClear, setPropsBuildings }: IFilterUsage) {
    const { data: UsageLargeCategoryData } = useUsageBigData();
    const { data: UsageMediumCategoryData } = useUsageSmallData();
    const [showBuildingUse, setShowBuildingUse] = useState(false);
    const [buildings, setBuildings] = useState<string[]>([]);
    const [customMediumCategory, setCustomMediumCategory] = useState(
        UsageMediumCategoryData,
    );

    useEffect(() => {
        setCustomMediumCategory((current: any) => ({
            ...current,
            ...customMediumCategory,
        }));
    }, [buildings]);

    const findSelectedMediumCategories = (value: string) => {
        return buildings.findIndex((v) => v === value);
    };

    const getUsageLargeCategoryList = (code: string) => {
        const category = UsageLargeCategoryData[code];
        return category.name;
    };

    const getUsageMediumCategoryList = (code: number) => {
        const category = customMediumCategory[code];
        return Object.values(category).map((v) => v);
    };

    const checkedLargeCategory = (parentName: string, checked: boolean) => {
        const usageCode = findCodeFromLargeCategory(parentName);
        const subCodes = getUsageMediumCategoryList(usageCode).map(
            (v: any) => v.code,
        );
        const category = customMediumCategory[usageCode];
        const names = Object.values(category).map(
            (v: any) => `${parentName}/${v.name}`,
        );

        if (checked) {
            // 전체 선택시 추가
            const removeDuplicateValues = (oldObj: any, newObj: any) => {
                const mergeObj = new Set([...oldObj, ...newObj]);
                return Array.from(mergeObj);
            };
            setBuildings((current) => removeDuplicateValues(current, subCodes));
            setPropsBuildings((current: any) =>
                removeDuplicateValues(current, names),
            );
        } else {
            // 전체 해제시 삭제
            setBuildings((prev: any[]) =>
                prev.filter((code) => {
                    return !subCodes.includes(code);
                }),
            );
            // 부모는 텍스트로 보낸다
            setPropsBuildings((prev: any[]) =>
                prev.filter((name) => {
                    return !names.includes(name);
                }),
            );
        }
        setClear(false);
    };

    const checkedMediumCategory = (
        subCode: string,
        checked: boolean,
        displayName: string,
        parentName: string,
    ) => {
        const fullName = `${parentName}/${displayName.trim()}`;
        if (checked && findSelectedMediumCategories(subCode)) {
            setBuildings((current) => [...current, subCode]);
            setPropsBuildings((current: any) => [...current, fullName]);
        } else {
            // 중복값이면 제거한다.
            setBuildings((prev: any[]) =>
                prev.filter((code) => {
                    return code !== subCode;
                }),
            );
            setPropsBuildings((prev: any[]) =>
                prev.filter((name) => {
                    return name !== fullName;
                }),
            );
        }
        setClear(false);
    };

    const findCodeFromLargeCategory = (name: string) => {
        return UsageLargeCategoryData[name].code;
    };

    const setCheckedALL = (largeCode: number) => {
        const largeCodes = Object.values(customMediumCategory[largeCode]).map(
            (v: any) => v.code,
        );
        return largeCodes.every((code) => buildings.includes(code));
    };

    useEffect(() => {
        if (clear) {
            setBuildings([]);
            setPropsBuildings([]);
        }
    }, [clear]);

    return (
        <>
            <ul>
                <li>용도</li>
                <li>
                    <span style={{ padding: "0 5px" }}>전체</span>
                    <Button
                        color="purple"
                        label={showBuildingUse ? "복수선택" : "전체선택"}
                        size="small"
                        handleClick={() => {
                            setShowBuildingUse(!showBuildingUse);
                            if (showBuildingUse) {
                                setBuildings([]);
                                setPropsBuildings([]);
                            }
                        }}
                    ></Button>
                </li>
            </ul>
            {showBuildingUse && (
                <div css={BuildingUseDiv}>
                    <ul>
                        <li>
                            <div css={CheckboxWrapper}>
                                <Checkbox
                                    label={getUsageLargeCategoryList(
                                        LIVING.value,
                                    )}
                                    defaultValue={LIVING.value}
                                    isChecked={setCheckedALL(LIVING.key)}
                                    handleChange={checkedLargeCategory}
                                />
                            </div>
                        </li>
                        <li>
                            <div css={CheckboxWrapper}>
                                {getUsageMediumCategoryList(LIVING.key).map(
                                    (value, index) => {
                                        const { name, code }: any = value;
                                        return (
                                            <div
                                                key={index}
                                                css={CheckboxContainer}
                                            >
                                                <Checkbox
                                                    label={name}
                                                    defaultValue={code}
                                                    isChecked={buildings.includes(
                                                        code,
                                                    )}
                                                    handleChange={(
                                                        key,
                                                        checked,
                                                        displayName,
                                                    ) =>
                                                        checkedMediumCategory(
                                                            key,
                                                            checked,
                                                            displayName || "",
                                                            LIVING.value,
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <div css={CheckboxWrapper}>
                                <Checkbox
                                    label={getUsageLargeCategoryList(
                                        LAND.value,
                                    )}
                                    defaultValue={LAND.value}
                                    isChecked={setCheckedALL(LAND.key)}
                                    handleChange={checkedLargeCategory}
                                />
                            </div>
                        </li>
                        <li>
                            <div css={CheckboxWrapper}>
                                {getUsageMediumCategoryList(LAND.key).map(
                                    (value, index) => {
                                        const { name, code }: any = value;
                                        return (
                                            <div
                                                key={index}
                                                css={CheckboxContainer}
                                            >
                                                <Checkbox
                                                    label={name}
                                                    defaultValue={code}
                                                    isChecked={buildings.includes(
                                                        code,
                                                    )}
                                                    handleChange={(
                                                        key,
                                                        checked,
                                                        displayName,
                                                    ) =>
                                                        checkedMediumCategory(
                                                            key,
                                                            checked,
                                                            displayName || "",
                                                            LAND.value,
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <div css={CheckboxWrapper}>
                                <Checkbox
                                    label={getUsageLargeCategoryList(
                                        COMMERCE.value,
                                    )}
                                    defaultValue={COMMERCE.value}
                                    isChecked={setCheckedALL(COMMERCE.key)}
                                    handleChange={checkedLargeCategory}
                                />
                            </div>
                        </li>
                        <li>
                            <div css={CheckboxWrapper}>
                                {getUsageMediumCategoryList(COMMERCE.key).map(
                                    (value, index) => {
                                        const { name, code }: any = value;
                                        return (
                                            <div
                                                key={index}
                                                css={CheckboxContainer}
                                            >
                                                <Checkbox
                                                    label={name}
                                                    defaultValue={code}
                                                    isChecked={buildings.includes(
                                                        code,
                                                    )}
                                                    handleChange={(
                                                        key,
                                                        checked,
                                                        displayName,
                                                    ) =>
                                                        checkedMediumCategory(
                                                            key,
                                                            checked,
                                                            displayName || "",
                                                            COMMERCE.value,
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <div css={CheckboxWrapper}>
                                <Checkbox
                                    label={getUsageLargeCategoryList(
                                        INDUSTRY.value,
                                    )}
                                    defaultValue={INDUSTRY.value}
                                    isChecked={setCheckedALL(INDUSTRY.key)}
                                    handleChange={checkedLargeCategory}
                                />
                            </div>
                        </li>
                        <li>
                            <div css={CheckboxWrapper}>
                                {getUsageMediumCategoryList(INDUSTRY.key).map(
                                    (value, index) => {
                                        const { name, code }: any = value;
                                        return (
                                            <div
                                                key={index}
                                                css={CheckboxContainer}
                                            >
                                                <Checkbox
                                                    label={name}
                                                    defaultValue={code}
                                                    isChecked={buildings.includes(
                                                        code,
                                                    )}
                                                    handleChange={(
                                                        key,
                                                        checked,
                                                        displayName,
                                                    ) =>
                                                        checkedMediumCategory(
                                                            key,
                                                            checked,
                                                            displayName || "",
                                                            INDUSTRY.value,
                                                        )
                                                    }
                                                />
                                            </div>
                                        );
                                    },
                                )}
                            </div>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <div css={CheckboxWrapper}>
                                <Checkbox
                                    label={getUsageLargeCategoryList(
                                        UNCLASSIFIED.value,
                                    )}
                                    defaultValue={UNCLASSIFIED.value}
                                    isChecked={setCheckedALL(UNCLASSIFIED.key)}
                                    handleChange={checkedLargeCategory}
                                />
                            </div>
                        </li>
                        <li>
                            <div css={CheckboxWrapper}>
                                {getUsageMediumCategoryList(
                                    UNCLASSIFIED.key,
                                ).map((value, index) => {
                                    const { name, code }: any = value;
                                    return (
                                        <div
                                            key={index}
                                            css={CheckboxContainer}
                                        >
                                            <Checkbox
                                                label={name}
                                                defaultValue={code}
                                                isChecked={buildings.includes(
                                                    code,
                                                )}
                                                handleChange={(
                                                    key,
                                                    checked,
                                                    displayName,
                                                ) =>
                                                    checkedMediumCategory(
                                                        key,
                                                        checked,
                                                        displayName || "",
                                                        UNCLASSIFIED.value,
                                                    )
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

const BuildingUseDiv = css`
    display: flex;
    flex-direction: column;
    ${Common.textStyle.title6_R14}

    & > ul {
        display: flex;
        flex-direction: row;

        & > li:nth-of-type(1) {
            padding-left: 15px;
            width: 130px;
            height: 40px;
            background-color: ${Common.colors.gray300};
            border: 1px solid ${Common.colors.gray400};
            border-bottom: none;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        & > li:nth-of-type(2) {
            flex: 1;
            height: 40px;
            padding-left: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid ${Common.colors.gray400};
            border-bottom: none;
            border-left: none;
            background-color: ${Common.colors.gray200};
        }
    }
    & > ul:last-of-type {
        li {
            border-bottom: 1px solid ${Common.colors.gray400};
        }
    }
`;

const CheckboxWrapper = css`
    display: flex;
    line-height: 25px;
`;

const CheckboxContainer = css`
    display: flex;
    padding-right: 10px;
`;

export default FilterUsage;
