import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { DropDown } from "@src/components";
import RadioButton from "@src/components/radio/RadioButton";
import { saleTypeDropDown, typeDropDown } from "@src/constants/Commodity";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ProductType } from "./ProvisionForm";

interface ProvisionProductProps {
    ProductList: ProductType[];

    selectedItem: any;
    setSelectedItem: Dispatch<SetStateAction<any>>;
}

const ProvisionProduct = ({
    ProductList,
    selectedItem,
    setSelectedItem,
}: ProvisionProductProps) => {
    const [filterSearch, setFilterSearch] = useState<string>("");
    const [filterIsFree, setFilterIsFree] = useState<boolean>(true);
    const [filterType, setFilterType] = useState<string>(typeDropDown[0].value);

    const onChangeSearchHandler = (e: any) => {
        setFilterSearch(e.target.value);
    };

    return (
        <>
            <>
                <StyledProductWrapper>
                    <div className={"filterBox"}>
                        <label>검색</label>
                        <input
                            type="text"
                            value={filterSearch}
                            onChange={onChangeSearchHandler}
                            placeholder={"상품명 및 상품코드"}
                        />
                    </div>
                    <div className={"filterBox"}>
                        <label>구분</label>
                        <div onClick={() => setFilterIsFree(true)}>
                            <input type="radio" checked={filterIsFree} />
                            무료 지급
                        </div>
                        <div onClick={() => setFilterIsFree(false)}>
                            <input type="radio" checked={!filterIsFree} />
                            유료 지급
                        </div>
                    </div>
                    <div className={"filterBox"}>
                        <label>상품종류</label>
                        <DropDown
                            data={typeDropDown}
                            defaultValue={filterType}
                            placeholder="항목을 선택해주세요"
                            border={false}
                            handleChange={(e: any) => {
                                setFilterType(e.target.value);
                            }}
                        />
                    </div>
                    <ul css={searchResultList}>
                        {ProductList.filter(
                            (el) =>
                                (el.name.indexOf(filterSearch) >= 0 ||
                                    el.code.indexOf(filterSearch) >= 0) &&
                                el.type === filterType &&
                                (filterIsFree
                                    ? el.discountedPrice === 0
                                    : el.discountedPrice > 0),
                        ).map((product, idx) => (
                            <li
                                key={idx}
                                className={"subItems"}
                                onClick={() => {
                                    setSelectedItem(product);
                                }}
                            >
                                <input
                                    type={"radio"}
                                    id={`item${idx}`}
                                    name={"item"}
                                />
                                <label htmlFor={`item${idx}`}>
                                    <ul className="subItem">
                                        <li>
                                            <div className="header">상품명</div>
                                            <span className="text">
                                                {product.name}
                                            </span>
                                        </li>
                                        <li>
                                            <div className="header">
                                                상품코드
                                            </div>
                                            <span className="text">
                                                {product.code}
                                            </span>
                                        </li>
                                        <li>
                                            <div className="header">정가</div>
                                            <span className="text">
                                                {`${
                                                    product?.price?.toLocaleString() ||
                                                    0
                                                }원`}
                                            </span>
                                        </li>
                                        <li>
                                            <div className="header">
                                                할인가(판매가)
                                            </div>
                                            <span className="text">
                                                {`${
                                                    product?.discountedPrice?.toLocaleString() ||
                                                    0
                                                }원`}
                                            </span>
                                        </li>
                                        <li>
                                            <div className="header">
                                                판매형태
                                            </div>
                                            <span className="text">
                                                {
                                                    saleTypeDropDown.filter(
                                                        (el) =>
                                                            el.key ===
                                                            product.saleType,
                                                    )[0]?.value
                                                }
                                            </span>
                                        </li>
                                    </ul>
                                </label>
                            </li>
                        ))}
                    </ul>
                </StyledProductWrapper>
            </>
        </>
    );
};

export default ProvisionProduct;

const StyledProductWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 25px;
    font-weight: 400;
    font-size: 13px;

    color: #000000;
    & > div {
        display: flex;
        gap: 50px;
        & > .title {
            width: 50px;
        }
        & > .code {
            font-weight: 600;
        }
    }
    .filterBox {
        align-items: center;
        justify-items: center;
        gap: 0;
        & > label {
            width: 80px;
        }
        & > div {
            display: flex;
            gap: 5px;
            margin-left: 10px;
            cursor: pointer;
        }
        & > input[type="text"] {
            margin-left: 10px;
            width: 200px;
        }
    }
`;

const searchResultList = css`
    height: 100%;
    min-height: 280px;
    max-height: 320px;
    overflow: scroll;
    .noData {
        margin-top: 20px;
        height: 400px;
        text-align: center;
        padding-top: 25%;
        ${Common.textStyle.body3_R14}
        background-color: rgba(0,0,0,0.1);
        display: block;
    }
    .subItems {
        border-bottom: 1px solid ${Common.colors.gray100};
        &:first-of-type {
            margin-top: 10px;
        }
        input {
            display: none;
        }
    }
    input[type="radio"]:checked + label {
        display: block;
        width: 100%;
    }
    input[type="radio"]:checked + label > ul {
        background-color: rgba(154, 73, 242, 0.15);
    }
    .subItem {
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
        div {
            margin-bottom: 15px;
            span:first-of-type {
                text-indent: 10px;
                display: inline-block;
                width: 40%;
                color: ${Common.colors.gray600};
                ${Common.textStyle.title6_R14}
            }
            span:last-of-type {
                display: inline-block;
                width: 60%;
                ${Common.textStyle.title5_M14}
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        li {
            padding-left: 20px;
            margin-bottom: 10px;
            display: flex;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .header {
            color: ${Common.colors.gray600};
            ${Common.textStyle.title6_R14}
            width:100px;
            margin-right: 100px;
        }
        .text {
            ${Common.textStyle.title5_M14}
        }
    }
`;
