import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosDownloadInstance, axiosInstance } from "@src/api/axiosConfig";
import { BannerSampleData } from "@src/data/BannerSampleData";
import { BaseBannerType, ResultBannerType, FormBannerType } from "@src/pages/banner/types";
import { noImage } from "@src/constants/Images";
import axios, { AxiosError } from "axios";
import { getBannerStatRequestDto, getBannerStatRequestResponse } from "@src/pages/expert/types";
import { queryList } from "./queryList";
import { error } from "console";

const initialData = BannerSampleData;

interface ParametersType {
    page?: number | undefined;
    limit?: number | undefined;
    value: string;
}

const fetchData = async (params: ParametersType) => {
    const response = await axiosInstance.get(`${axiosPath.banner}/${params.value}`, {
        params,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;

    const originData: BaseBannerType[] = responseData.data.list;
    return originData.length
        ? {
              result: parse(originData),
              total: responseData.data.totalCnt,
          }
        : { result: [], total: 0 };
};

const fetchBannerList = async (params: ParametersType) => {
    const response = await axiosInstance.get(`${axiosPath.bannerList}/${params.value}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;

    return responseData;
};

const fetchBannerClickCount = async (params: getBannerStatRequestDto) => {
    const response = await axiosInstance.post(`${axiosPath.getBannerClickCount}`, params);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;

    return responseData;
};

export const useGetBannerExcelFile = async (params: any, reason: string) => {
    const response = await axiosInstance.get(`${axiosPath.bannerList}/download/excel`, {
        params: { ...params, reason },
    });

    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers["content-type"] }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", decodeURI(response.headers["content-disposition"].split("filename*=UTF-8''")[1]));
    document.body.appendChild(link);
    link.click();
};

const fetchBannerExcelDownloadLog = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.bannerList}/history/excel`, params);

    return response.data;
};

export const useGetBannerExcelDownloadLog = (params: any, enabled: boolean = false) => {
    return useInfiniteQuery({
        queryKey: "getBanenrExcelDownloadList",
        queryFn: ({ pageParam = 1 }) =>
            fetchBannerExcelDownloadLog({
                ...params,
                page: pageParam,
            }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage = pages.length * 10 < pages[0].totalCount ? pages.length + 1 : undefined;
            return newPage;
        },
        enabled,
    });
};

const parse = (originData: BaseBannerType[]) => {
    const newData: FormBannerType[] = [];
    originData.forEach((item: BaseBannerType, index: number) => {
        const { no, type, imgurl, linkurl, display_order, open_at, close_at, is_open, testers } = item;
        newData.push({
            listNo: index,
            type: type,
            bannerNo: no,
            bannerImage: imgurl,
            bannerLink: linkurl,
            bannerDate: open_at,
            startDate: open_at,
            endDate: close_at,
            bannerView: is_open,
            order: display_order,
            viewMore: no,
            index: no,
            testers: testers,
        });
    });

    return newData;
};

const useBannerList = (params: ParametersType) => {
    return useQuery<ResultBannerType, Error>([queryKeys.BANNER_LIST, { ...params }], () => fetchData(params), {
        // initialData,
    });
};

const useGetFilterBannerList = (params: ParametersType, enabled = false) => {
    return useQuery<any, Error>([queryKeys.FILTER_BANNER_LIST, { ...params }], () => fetchBannerList(params), {
        enabled,
    });
};

const useGetBannerClickCount = (params: getBannerStatRequestDto, enabled = false) => {
    return useQuery<any, Error>([queryKeys.BANNER_CLICK_COUNT, { ...params }], () => fetchBannerClickCount(params), {
        enabled,
    });
};

export { useBannerList, useGetFilterBannerList, useGetBannerClickCount };
