import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import {
    BaseNews,
    ResultNewsProps,
    ListNewsProps,
} from "@src/interfaces/News.interface";
import { axiosPath } from "@src/api/axiosPath";
import { noImage } from "@src/constants/Images";
import { axiosInstance } from "@src/api/axiosConfig";
import { dateParse } from "@src/utils/dateUtil";

interface ParametersType {
    page?: number | undefined;
    query?: string | undefined;
    keywords?: string | null;
}
const fetchData = async (params: ParametersType) => {
    const response = await axiosInstance.get(axiosPath.article2, {
        params,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: BaseNews[] = responseData.data.result;
    return originData.length
        ? {
              result: parse(originData),
              total: responseData.data.totalCnt,
          }
        : { result: [], total: 0 };
};

const parse = (originData: BaseNews[]) => {
    const newData: ListNewsProps[] = [];
    originData.forEach((item: BaseNews, index: number) => {
        const {
            thumb,
            url,
            date,
            title,
            is_show,
            press,
            views,
            article_id,
            keyword,
            portal,
            keyword_code,
            close_at,
        } = item;
        newData.push({
            articleId: article_id,
            newsNo: index,
            newsImage: thumb === "" ? noImage : thumb,
            newsLink: url,
            newsDate: date,
            startDate: dateParse(date),
            mediaCompany: press,
            newsViewCount: views,
            viewMore: index,
            index: index,
            newsTitle: title,
            view: is_show ? true : false,
            keyword: keyword,
            keywordCode: keyword_code,
            endDate: close_at,
            portal,
        });
    });

    return newData;
};

const useNewsData = (params: ParametersType) => {
    return useQuery<ResultNewsProps, Error>(
        [queryKeys.NEWS_DATA, { ...params }],
        () => fetchData(params),
        {
            // initialData,
        },
    );
};
const initParams = {
    page: 1,
};
export { useNewsData, initParams };
