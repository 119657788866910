import React, { useState, useEffect } from "react";
import Button from "@src/components/button/Button";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { useEventListener } from "@src/hooks/useEventListener";
import closeIcon from "@src/assets/closeIcon.png";

interface IEditModal {
    isModalShow: boolean;
    title: string;
    buttonText?: string;
    children: JSX.Element | string | Element[] | string;
    handleOpenModal: () => void;
    size: "big" | "medium" | "fitContent";
    handleBtnClick: () => void;
    isDisabled?: boolean;
    errorMessage?: string;
    deleteButton?: boolean;
    dimClose?: boolean;
    checkCloseModal?: boolean;
    styles?: any;
}

function EditModal({
    isModalShow,
    title,
    buttonText = "확인",
    children,
    handleOpenModal,
    size = "big",
    handleBtnClick,
    isDisabled,
    errorMessage,
    deleteButton,
    dimClose = false,
    checkCloseModal = false,
    styles,
}: IEditModal) {
    const [show, setShow] = useState(isModalShow);
    useEffect(() => {
        setShow(isModalShow);
        isModalShow &&
            (document.body.style.cssText = `
        overflow: hidden;
    `);
        return () => {
            document.body.style.cssText = `
        overflow: scroll;
    `;
        };
    }, [isModalShow]);

    return (
        <div>
            {show ? (
                <Modal
                    styles={styles}
                    title={title}
                    buttonText={buttonText}
                    children={children}
                    handleOpenModal={handleOpenModal}
                    handleBtnClick={handleBtnClick}
                    size={size}
                    isDisabled={isDisabled}
                    errorMessage={errorMessage}
                    deleteButton={deleteButton}
                    dimClose={dimClose}
                    checkCloseModal={checkCloseModal}
                />
            ) : null}
        </div>
    );
}

function Modal({
    title,
    buttonText,
    children,
    handleOpenModal,
    handleBtnClick,
    size,
    isDisabled,
    errorMessage,
    deleteButton,
    dimClose,
    checkCloseModal,
    styles,
}: any) {
    const handleClick = () => {
        handleBtnClick();
        if (typeof errorMessage === "undefined" && !checkCloseModal) {
            handleOpenModal(false);
        }
    };
    const handleCloseClick = () => handleOpenModal(false);
    // const afterKeyPress = ({ key }: KeyboardEvent) => {
    //     if (key === "Enter") {
    //         // handleClick();
    //     }
    // };

    // useEventListener(afterKeyPress);
    return (
        <>
            <div
                className="modal"
                css={[modalStyle, sizes[size], styles ? styles : ""]}
            >
                <p css={titleP}>{title}</p>
                <button type="button" onClick={handleCloseClick} css={closeBtn}>
                    X
                </button>

                {children}

                <div css={buttonBox}>
                    {errorMessage && (
                        <p className="updateError">{errorMessage}</p>
                    )}
                    {!deleteButton && (
                        <Button
                            label={buttonText}
                            color="gd"
                            size="medium"
                            handleClick={handleClick}
                            isDisabled={isDisabled}
                        />
                    )}
                </div>
            </div>
            <div
                className="shadow"
                css={shadowStyle}
                onClick={dimClose ? () => handleCloseClick() : undefined}
            ></div>
        </>
    );
}

const modalStyle = css`
    max-height: 98%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    padding: 25px 24px 20px;
    background-color: ${Common.colors.white};
    border-radius: 8px;
    position: fixed;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: none;
    &.modal > button:last-of-type {
        position: fixed;
        right: 40px;
        bottom: 24px;
        border: none;
    }
`;
const shadowStyle = css`
    display: block;
    width: 100vw;
    height: 200vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 89;
    background-color: rgba(0, 0, 0, 0.4);
`;
const closeBtn = css`
    width: 16px;
    height: 16px;
    text-indent: -9999px;
    font-size: 0;
    background: url(${closeIcon}) no-repeat center;
    background-size: cover;
    position: fixed;
    right: 44px;
    top: 28px;
    cursor: pointer;
`;
const titleP = css`
    ${Common.textStyle.title2_M16}
    margin-bottom: 47px;
`;
const sizes: { [index: string]: any } = {
    big: css`
        width: 944px;
    `,
    medium: css`
        width: 608px;
    `,
    fitContent: css`
        width: fit-content;
    `,
};

const buttonBox = css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    position: fixed;
    right: 40px;
    bottom: 24px;
    border: none;
    position: static;
    padding-top: 20px;
    p {
        color: ${Common.colors.error};
        white-space: pre-line;
        ${Common.textStyle.title5_M14};
    }
    button.submitBtn {
        margin: 0;
    }
`;

export default EditModal;
