import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button } from "@src/components";

interface IConfirmModal {
    children: JSX.Element | string;
    isModalShow: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    buttonText?: string;
    disabledCancelBtn?: boolean;
    disabled?: boolean;
}

function ConfirmModal({
    children,
    isModalShow,
    handleOk,
    handleCancel,
    buttonText = "확인",
    disabled = false,
    disabledCancelBtn = false,
}: IConfirmModal) {
    const [show, setShow] = useState(isModalShow);
    useEffect(() => {
        setShow(isModalShow);
        isModalShow &&
            (document.body.style.cssText = `
                overflow: hidden;
            `);
        return () => {
            document.body.style.cssText = `
                overflow: scroll;
            `;
        };
    }, [isModalShow]);

    const onCancel = () => {
        setShow(false);
        handleCancel();
    };

    return (
        <div>
            {show ? (
                <Modal
                    handleOK={handleOk}
                    handleCancel={onCancel}
                    buttonText={buttonText}
                    disabledCancelBtn={disabledCancelBtn}
                    disabled={disabled}
                >
                    {children}
                </Modal>
            ) : null}
        </div>
    );
}

function Modal({
    children,
    handleOK,
    handleCancel,
    buttonText,
    disabledCancelBtn,
    disabled,
}: any) {
    return (
        <>
            <div css={modalStyle}>
                <div css={contentStyle}>{children}</div>
                <div css={boxStyle}>
                    <Button
                        color="gd"
                        handleClick={handleOK}
                        label={buttonText}
                        size="medium"
                        isDisabled={disabled}
                    />
                    {!disabledCancelBtn && (
                        <Button
                            color="gray"
                            handleClick={handleCancel}
                            label="취소"
                            size="medium"
                        />
                    )}
                </div>
            </div>
            <div className="shadow" css={shadowStyle}></div>
        </>
    );
}

const modalStyle = css`
    width: max-content;
    min-width: 695px;
    max-width: 1200px;
    height: fit-content;
    overflow: scroll;
    max-height: 800px;
    min-height: 140px;
    background-color: ${Common.colors.white};
    border-radius: 8px;
    position: fixed;
    z-index: 999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const contentStyle = css`
    padding: 25px 24px;
`;

const boxStyle = css`
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    border: none;
    Button {
        margin-left: 12px;
    }
`;

const shadowStyle = css`
    display: block;
    width: 100vw;
    height: 500vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.4);
`;

export default ConfirmModal;
