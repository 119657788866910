import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { MixedChart } from "@src/components";
import ChartHeader, { changeDateType } from "./ChartHeader";
import {
    initTime,
    useHomeUserPaidChart,
    SalesStatType,
} from "@src/hooks/queries/useHomeChart";
import { dateFormat, noYearFormat } from "@src/utils/dateUtil";
import { add, set, sub } from "date-fns";
import moment from "moment";
import { getRelativePosition } from "chart.js/helpers";
import "moment/locale/ko";
moment.locale("ko");

function NewPaidSubsChart({ onClickOpenJoinedMemberModal }: any) {
    const [filterUserChart, setFilterUerChart] = useState<SalesStatType>({
        cycle: "daily",
        startDate: dateFormat(initTime.day.startDate),
        endDate: dateFormat(initTime.day.endDate),
    });

    const parsePaidUserChartAndCreateOption = (data: any, cycle: string) => {
        return {
            labels: data.map((v: any) =>
                cycle === "weekly"
                    ? `${noYearFormat(new Date(v.date))} ~ ${noYearFormat(
                          add(new Date(v.date), {
                              days: 6,
                          }),
                      )}`
                    : v.date,
            ),
            datasets: [
                {
                    type: "line",
                    label: "누적유료가입자수",
                    yAxisID: "right",
                    data: data.map((v: any) => v.paidSubsAll),
                    borderColor: "#FF007A",
                    backgroundColor: "#FF007A",
                    borderWidth: 3,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "bar",
                    label: "오늘 유료가입자수",
                    yAxisID: "left",
                    data: data.map((v: any) => v.subscriptionNew),
                    borderColor: "#3776ED",
                    backgroundColor: "#3776ED",
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
            ],
        };
    };

    const {
        isLoading: paidUserLoading,
        data: paidUserData,
        isError: paidUserError,
    } = useHomeUserPaidChart(
        filterUserChart,
        parsePaidUserChartAndCreateOption,
    );

    const [PaidUsersChartData, setPaidUsersChartData] = useState([]);

    useEffect(() => {
        if (!paidUserLoading && !paidUserError) {
            setPaidUsersChartData(paidUserData);
        }
    }, [paidUserData]);

    const getDateStatsUserChart = ({ range, type }: changeDateType) => {
        const { startDate, endDate } = range;

        setFilterUerChart({
            cycle: type,
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        });
    };

    const [options, setOptions] = useState({});
    useEffect(() => {
        switch (filterUserChart.cycle) {
            case "daily": {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "day",
                                displayFormats: {
                                    day: "MM.dd",
                                },
                                tooltipFormat: "YYY.MM.dd",
                            },
                        },
                    },
                    events: ["mousemove", "mouseout", "click"],
                    onClick: (evt: any) => {
                        const chart = evt.chart;
                        const newIndex = chart.getActiveElements()?.[0]?.index;
                        if (newIndex) {
                            onClickOpenJoinedMemberModal(
                                add(new Date(filterUserChart.startDate), {
                                    days: newIndex,
                                }),
                                add(new Date(filterUserChart.startDate), {
                                    days: newIndex,
                                }),
                            );
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                title: (context: any) => {
                                    const label = context[0].label;
                                    const date = moment(label);
                                    return `${date.format("YYYY.MM.DD (dd)")}`;
                                },
                            },
                        },
                    },
                });
                break;
            }
            case "weekly": {
                setOptions({
                    scales: {
                        x: {
                            time: {
                                tooltipFormat: "YYY.MM.dd",
                            },
                        },
                    },
                    events: ["mousemove", "mouseout", "click"],
                    onClick: (evt: any) => {
                        const chart = evt.chart;
                        const currentDataIndex =
                            chart.getActiveElements()?.[0]?.index;
                        const canvasPosition = getRelativePosition(evt, chart);
                        if (currentDataIndex) {
                            const totalData = evt.chart.scales.x.ticks;
                            const checkStartDate =
                                totalData[0]?.label.split("~")[0];
                            const newStartDate = set(
                                new Date(filterUserChart.startDate),
                                {
                                    month: checkStartDate.split(".")[0] - 1,
                                    date: checkStartDate.split(".")[1],
                                },
                            );
                            onClickOpenJoinedMemberModal(
                                add(new Date(newStartDate), {
                                    days: currentDataIndex * 7,
                                }),
                                add(new Date(newStartDate), {
                                    days: currentDataIndex * 7 + 6,
                                }),
                            );
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
            case "monthly": {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "month",
                                displayFormats: {
                                    month: "yyyy.MM",
                                },
                                tooltipFormat: "YYY.MM.dd",
                            },
                        },
                    },
                    events: ["mousemove", "mouseout", "click"],
                    onClick: (evt: any) => {
                        const chart = evt.chart;
                        const currentDataIndex =
                            chart.getActiveElements()?.[0]?.index;
                        const totalData = evt.chart.scales.x.ticks;
                        onClickOpenJoinedMemberModal(
                            new Date(totalData[currentDataIndex].value),
                            sub(
                                add(
                                    new Date(totalData[currentDataIndex].value),
                                    {
                                        months: 1,
                                    },
                                ),
                                {
                                    days: 1,
                                },
                            ),
                        );
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
            default: {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                displayFormats: { hour: "HH:mm" },
                                tooltipFormat: "YYY.MM.dd HH:mm",
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
        }
    }, [filterUserChart]);

    const scrollRef = useRef<HTMLDivElement | null>(null);

    return (
        <div css={subChartDiv}>
            <p css={titleP}>유료구독자 전환 현황</p>
            <div className="mainChartBox">
                <ChartHeader
                    getChangeDate={getDateStatsUserChart}
                    defaultBasisType="daily"
                />
                <div css={subContainer}>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#3776ED"} />
                        <p>오늘 유료가입자수</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#FF007A"} />
                        <p>누적유료가입자수</p>
                    </div>
                </div>
                <div style={{ height: "20px" }}></div>
                {/* <div css={chartBoxStyle} ref={scrollRef}> */}
                <MixedChart
                    data={PaidUsersChartData}
                    options={options}
                    onScrollEnd={(val: number) =>
                        scrollRef.current?.scrollTo(val, 0)
                    }
                    boxStyles={chartBoxStyle}
                />
                {/* </div> */}
            </div>
        </div>
    );
}

const subChartDiv = css`
    width: 610px;
    height: 360px;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1.5px solid ${Common.colors.gray200};
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
    font-size: 15px;
`;
const chartBoxStyle = css`
    width: 550px;
    height: 220px;
    overflow: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 8px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
`;
const subTitle = css`
    display: flex;
    /* width: 50px; */
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    ${Common.textStyle.title7_M12}
`;

const subContainer = css`
    display: flex;
    flex-direction: row;
`;

const LabelName = ({ nameColorBg }: { nameColorBg: string }) => {
    const chartColor = css`
        width: 15px !important;
        height: 15px;
        margin: 8px 5px;
        border-radius: 3px;
        background-color: ${nameColorBg};
    `;
    return <div css={chartColor} />;
};

export default NewPaidSubsChart;
