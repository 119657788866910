export interface userExpertDto {
    userNo: number;
    profile?: string;
    info: {
        type: string;
        phone: string;
        officeInfo: {
            name: string;
            ceo: string;
            registrationNumber?: string;
            type?: string;
            phone: string;
            address: string;
        };
        counselingCategories?: string[];
        counselingFees?: {
            content: string;
            fee: string;
        }[];
        career?: string;
        qualification?: string;
        introduction?: string;
        file?: {
            url: string;
        };
    };
    agentNo: number;
}

export const initUserExpertState: userExpertDto = {
    userNo: 0,
    agentNo: 0,
    profile: "",
    info: {
        type: "agent",
        phone: "",
        officeInfo: {
            name: "",
            ceo: "",
            registrationNumber: "",
            type: "agent",
            phone: "",
            address: "",
        },
        counselingCategories: [],
        counselingFees: [],
        career: "",
        qualification: "",
        introduction: "",
    },
};

export interface getBannerStatRequestDto {
    bannerNo: number;
    startDate?: string | null;
    endDate?: string | null;
    userSubTypes?: number[];
    searchType?: string;
    searchText?: string;
    order: number;
    limit: number;
    page: number;
}

interface getBannerStatRequestList {
    no: number;
    banenrNo: number;
    userNo: number;
    username: string;
    phone: string;
    userId: string;
    birth: string;
    gender: string;
    joinedAt: string;
    status: string;
    subsInfo: string;
    paybackPartnerInfo: string;
    bannerClickCount: number;
}

export interface getBannerStatRequestResponse {
    list: getBannerStatRequestList[];
    totalUserCount: string;
    totalClickCount: string;
}

export const initBannerStateRequestDto = {
    bannerNo: 0,
    order: 1,
    limit: 10,
    page: 1,
    searchType: "name",
};

export interface userExpertListDto {
    page: number;
    limit: number;
    cursor?: string;
    types?: string[];
    isAgent?: boolean;
    counselingCategories?: string[];
    searchType: string;
    searchValue?: string;
    searchStartDate?: string;
    searchEndDate?: string;
    noneCounselingCategory?: boolean;
    sort: string;
    order: string;
}

export interface userExpertListResponse {
    no: number;
    types: string;
    name: string;
    phone: string;
    officeName: string;
    adItemCount: number;
    inquiryCount: number;
    createdAt: string;
}

export const initUserExpertListDto = {
    page: 1,
    limit: 10,
    searchType: "userInfo.uname",
    sort: "userExpert.createdAt",
    order: "DESC",
};

export interface userExpertResponse {
    infos: {
        no: number;
        phone: string;
        type: string;
        officeInfo: {
            name: string;
            ceo: string;
            type?: string;
            phone: string;
            address: string;
        };
        counselingCategories: string[];
        counselingFees?: { content: string; fee: string }[];
        career: string;
        qualification: string;
        introduction: string;
        file?: string;
    }[];
    no: number;
    profile: string;
    user: {
        no?: number;
        info?: {
            name?: string;
        };
    };
    agentNo?: number;
}

export interface expertInquiriesListResponse {
    no: number;
    bidType: string;
    itemSubId: string;
    useDetail: string;
    address: string;
    inquiryUserNo: number;
    inquiryUserName: string;
    expertUserNo: number;
    expertUserName: string;
    type: string;
    createdAt: string;
}

export interface userExpertInquiryListDto {
    page: number;
    limit: number;
    cursor?: string;
    types?: string[];
    searchType: string;
    searchValue?: string;
    searchStartDate?: string;
    searchEndDate?: string;
    sort: string;
    order: string;
}

export interface ExpertHome {
    count: number;
    todayCount: number;
    yesterdayCount: number;
    agentCount: number;
    agentTodayCount: number;
    auctionAgentCount: number;
    auctionAgentTodayCount: number;
    scrivenerCount: number;
    scrivenerTodayCount: number;
    inquiryCallCount: number;
    inquiryCallTodayCount: number;
    inquiryCallYesterdayCount: number;
    inquiryMessageCount: number;
    inquiryMessageTodayCount: number;
    inquiryMessageYesterdayCount: number;
}

export interface ExpertListColumns {
    no: number;
    types: string;
    name: string;
    phone: string;
    officeName: string;
    createdAt: string;
    adItemCount: number;
    inquiryCount: number;
    viewMore: number;
}
export interface ExpertInquiriesListColumns {
    no: number;
    bidType: string;
    itemSubId: string;
    useDetail: string;
    address: string;
    inquiryUserName: string;
    inquiryUserNo: number;
    expertUserName: string;
    expertUserNo: number;
    type: string;
    createdAt: string;
    viewMore: number;
}

export interface NotExpertUserListDto {
    page: number;
    limit: number;
    cursor?: string;
    searchValue?: string;
    searchType: string;
}

export interface NotExpertUserListResponse {
    totalCount?: number;
    page: number;
    limit: number;
    cursor: string;
    list: NotExpertUserList;
}

export interface NotExpertUserList {
    no: number;
    name: string;
    phone: string;
    birthdate: string;
    email: string;
    gender: string;
    createdAt: string;
    subscription: {
        type?: string;
        productName?: string;
    };
}

export interface AgentParamsDto {
    name?: string;
    limit?: number;
    page?: number;
    sggCode: string;
}

export interface OfficeParamsDto {
    searchText?: string;
    limit?: number;
    page?: number;
}
