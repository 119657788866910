import { arrayToDropdown } from "@src/utils/dropdownUtil";

export const searchDeungiChangeList = arrayToDropdown([
    "이름",
    "이메일",
    "소재지",
    "부동산고유번호",
]);

export const duengiChangeStatus: { [key: string]: any } = {
    확인실패: ["확인불가", "red"],
    변동확인전: ["확인중(변동 확인 전)", "purple"],
    변동없음: ["확인중(확인된 변동없음)", "purple"],
    변동있음: ["확인중(변동있음)", "purple"],
    소유자불일치: ["확인중(소유자불일치)", "purple"],
    기간만료: ["알림종료", "grey"],
    관리자삭제: ["관리자 삭제", "black"],
};
