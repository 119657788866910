export const adminCreate = {
    title: "관리자 추가",
    buttonLabel: "관리자 등록",
    buttonTitle: "등록",
    confirmMsg: "정말 등록하시겠습니까?",
    passwordTitle: "비밀번호 등록",
};

export const adminUpdate = {
    title: "관리자 수정",
    buttonLabel: "관리자 수정",
    buttonTitle: "수정",
    confirmMsg: "정말 수정하시겠습니까?",
    passwordTitle: "비밀번호 수정",
};

export const adminPlaceholders = {
    adminName: "이름을 작성해주세요.",
    adminPhone: "하이픈(-) 제외한 숫자 형식으로 작성해주세요.",
    adminId: "이메일 형식으로 작성해주세요.",
    adminRank: "직위를 작성해주세요.",
    userNo: "회원번호가 있을 경우만 작성해주세요.",
    password: "영문, 숫자 포함 7글자 이상 작성해주세요.",
    noData: "데이터가 없습니다.",
};
