import styled from "@emotion/styled";
import { Button, Calendar, EditModal, Label } from "@src/components";
import { useChangeSubscriptionInfo } from "@src/hooks/queries/useUserDetail";

import { DetailUserProps } from "@src/interfaces/User.interface";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { subscriptionInfoType } from "./UserDetail";

interface UserSubscriptionEditModalProps {
    isModalShow: boolean;
    setIsSubscriptionEditModalShow: Dispatch<SetStateAction<boolean>>;
    user?: DetailUserProps;
    subscriptionInfo?: subscriptionInfoType;
    nextPaid?: string;
    setNextPaid: Dispatch<SetStateAction<string | undefined>>;
}

const UserSubscriptionEditModal = ({
    isModalShow,
    setIsSubscriptionEditModalShow,
    user,
    subscriptionInfo,
    nextPaid,
    setNextPaid,
}: UserSubscriptionEditModalProps) => {
    const [expiredDate, setExpiredDate] = useState<string>("");

    const [reasonValue, setReasonValue] = useState<string>();

    const showContents = () => {
        return (
            <>
                <StyleModalContents>
                    <StyledModalWrapper>
                        <StyledModalItem>
                            <span>구독이름</span>
                            <div className="subText">
                                {subscriptionInfo?.subs_group_name}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>결제일</span>
                            <div className="subText">
                                {subscriptionInfo?.last_paid_at}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>결제액</span>
                            <div className="subText">
                                {subscriptionInfo?.paid_price?.toLocaleString(
                                    "ko-KR",
                                )}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>환불액</span>
                            <div className="subText">
                                {" "}
                                {subscriptionInfo?.refund_price?.toLocaleString(
                                    "ko-KR",
                                )}
                            </div>
                        </StyledModalItem>
                    </StyledModalWrapper>
                    <StyledModalWrapper>
                        <StyledModalItem>
                            <span>상품명</span>
                            <div className="subText">
                                {subscriptionInfo?.product_name}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>상태</span>

                            <div style={{ width: 80, height: 26 }}>
                                {subscriptionInfo?.cancel_reason !==
                                "(해지예약)" ? (
                                    <Label text="정상" color="purple" />
                                ) : (
                                    <Label text="해지 예약" color="gray" />
                                )}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>만료일</span>

                            {subscriptionInfo?.expired_at && (
                                <Calendar
                                    getUserDate={(date) => {
                                        if (
                                            subscriptionInfo?.renew_term ===
                                                "monthly" ||
                                            subscriptionInfo?.renew_term ===
                                                "yearly"
                                        ) {
                                            setNextPaid(
                                                moment(date - 86399000).format(
                                                    "YYYY-MM-DD HH:mm:ss",
                                                ),
                                            );
                                        }
                                        setExpiredDate(
                                            moment(date).format(
                                                "YYYY-MM-DD HH:mm:ss",
                                            ),
                                        );
                                    }}
                                    setDate={Number(
                                        new Date(subscriptionInfo.expired_at),
                                    )}
                                />
                            )}
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>다음 결제일</span>
                            <div className="subText">
                                {subscriptionInfo?.next_renew_at
                                    ? nextPaid
                                    : "-"}
                            </div>
                        </StyledModalItem>
                    </StyledModalWrapper>
                </StyleModalContents>
                <StyledReasonWrapper>
                    <span>
                        <span className="essential">*</span>사유
                    </span>
                    <input
                        type="text"
                        placeholder="사용자 문의(123456번)로 등록"
                        onChange={(e: any) => {
                            setReasonValue(e.target.value);
                        }}
                        value={reasonValue}
                    />
                </StyledReasonWrapper>
            </>
        );
    };

    return (
        <>
            {expiredDate !== "" &&
            subscriptionInfo?.expired_at !== expiredDate &&
            reasonValue ? (
                <EditModal
                    isModalShow={isModalShow}
                    buttonText="저장"
                    handleBtnClick={() => {
                        user &&
                            subscriptionInfo &&
                            useChangeSubscriptionInfo(
                                user.userNo,
                                subscriptionInfo.subs_no,
                                expiredDate,
                                nextPaid,
                                reasonValue,
                            );
                    }}
                    handleOpenModal={() =>
                        setIsSubscriptionEditModalShow(false)
                    }
                    size="big"
                    title="구독 정보 수정"
                    deleteButton={false}
                >
                    {showContents()}
                </EditModal>
            ) : (
                <EditModal
                    isModalShow={isModalShow}
                    buttonText="저장"
                    handleBtnClick={() => {}}
                    handleOpenModal={() =>
                        setIsSubscriptionEditModalShow(false)
                    }
                    size="big"
                    title="구독 정보 수정"
                    deleteButton={false}
                    isDisabled
                >
                    {showContents()}
                </EditModal>
            )}
        </>
    );
};

export default UserSubscriptionEditModal;
const StyledModalItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
    & > span {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        width: 88px;
        height: 20px;
        display: flex;
        align-items: center;
    }
    & > .subText {
        font-size: 14px;
        padding-left: 12px;
        font-weight: 400;
        color: #222222;
    }
`;

const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;
`;

const StyleModalContents = styled.div`
    display: flex;
    gap: 35px;
    width: 100%;
`;

const StyledReasonWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    width: 648px;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;
