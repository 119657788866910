import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { RangeCalendar, DropDown, Checkbox, Input } from "@src/components";
import { Common } from "@src/styles/Common";
import { UserFilterType } from "./types";
import { ConvertDate } from "@src/utils/dateUtil";
import { initFilters } from "@src/hooks/queries/useUser";
import { useLocation, useSearchParams } from "react-router-dom";
import { dateParse } from "@src/utils/dateUtil";
import { set } from "date-fns";

interface IUserFilter {
    handleSubmit: (data: any) => void;
    handleSearchClick: () => void;
    dropList: {
        id: number;
        key: string;
        value: string;
    }[];
    clear?: boolean;
}

function UserFilter({
    handleSubmit,
    handleSearchClick,
    dropList,
    clear,
}: IUserFilter) {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const parseQuery = useMemo(
        () => Object.fromEntries(searchParams),
        [location.search],
    );
    const initChecked = {
        200: false,
        102: false,
        101: false,
        1: false,
        0: false,
    };
    const subsChecked = {
        none: false,
        paid: false,
        free: false,
    };
    const initPartnerChecked = {
        noInput: false,
        input: false,
    };

    const [searchType, setSearchType] = useState<string>("name"); //검색 카테고리
    const [searchKeyword, setSearchKeyword] = useState<string>(""); //검색 키워드
    const [filters, setFilters] = useState<UserFilterType>(initFilters);
    const [isChecked, setIsChecked] = useState<any>(initChecked);
    const [subChecked, setSubChecked] = useState<any>(subsChecked);
    const [partnerChecked, setPartnerChecked] =
        useState<any>(initPartnerChecked);
    const [selectedDate, setSelectedDate] = useState<number | null>(0);
    const dateLabel = [
        //날짜 라디오 버튼
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];
    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());

    const renderDate = (item: number) => {
        if (item == 0) {
            setFilters({
                ...filters,
                startDate: "",
                endDate: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                startDate: before7day,
                endDate: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                startDate: before1Month,
                endDate: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                startDate: before6Month,
                endDate: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                startDate: before12Month,
                endDate: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                startDate: TODAY,
                endDate: TODAY,
            });
        }
        setSelectedDate(item);
    };

    useEffect(() => {
        const { userType, subType, startDate, endDate } = parseQuery;
        const queryUserChecked = userType?.toString();
        const queryUserCheckedObj = queryUserChecked
            ?.split(",")
            .reduce((a, v) => ({ ...a, [v]: true }), {});
        const querySubChecked = subType?.toString();
        const querySubCheckedObj = querySubChecked
            ?.split(",")
            .reduce((a, v) => ({ ...a, [v]: true }), {});

        if (parseQuery) {
            setFilters({
                ...filters,
                startDate: startDate ? startDate.toString() : null,
                endDate: endDate ? endDate.toString() : null,
                userType: queryUserChecked,
                subType: querySubChecked,
                searType: searchType,
                searchText: searchKeyword,
            });
            setIsChecked(Object.assign(initChecked, queryUserCheckedObj));
            setSubChecked(Object.assign(subsChecked, querySubCheckedObj));
        }
    }, [parseQuery]);
    const handleCheckBoxChange = (name: string, checked: boolean) => {
        let setCheckedList = {
            ...isChecked,
            [name]: checked,
        };

        const statusType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedList, statusType };
    };
    const handleSubStatusChange = (name: string, checked: boolean) => {
        let setCheckedListSub = {
            ...subChecked,
            [name]: checked,
        };

        const subType = Object.entries(setCheckedListSub)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedListSub, subType };
    };

    const handleDropChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setFilters({
            ...filters,
            searType: e.target.value,
            searchText: "",
        });

        setSearchKeyword(""); //검색 카테고리 변경시 이전 카테고리의 키워드 초기화
        setSearchType(e.target.value);
    };
    const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);

        setFilters({
            ...filters,
            searchText: e.target.value,
        });
    };
    const removeEmptyObj = (update: UserFilterType) => {
        return Object.fromEntries(
            Object.entries(update).filter(([_, value]) => value !== ""),
        );
    };
    useEffect(() => {
        handleSubmit(removeEmptyObj(filters));
    }, [filters]);

    useEffect(() => {
        //전체 해제 버튼 클릭시
        if (clear) {
            setFilters(initFilters);
            setSearchType("name");
            setSearchKeyword("");
            setIsChecked({
                200: false,
                102: false,
                101: false,
                1: false,
                0: false,
            });
            setSubChecked({
                none: false,
                paid: false,
                free: false,
            });
            setPartnerChecked({
                noInput: false,
                input: false,
            });
        }
    }, [clear]);

    useEffect(() => {
        if (partnerChecked.noInput === partnerChecked.input) {
            setFilters({
                ...filters,
                partnerStatus: undefined,
            });
        } else if (partnerChecked.input) {
            setFilters({
                ...filters,
                partnerStatus: true,
            });
        } else {
            setFilters({
                ...filters,
                partnerStatus: false,
            });
        }
    }, [partnerChecked]);

    return (
        <div css={FilterDiv}>
            <ul>
                <li>
                    <span className="subTitle">가입일시</span>
                    <div>
                        {dateLabel.map((item) => {
                            return (
                                <div key={item.id}>
                                    <button
                                        value={item.value}
                                        className={
                                            "datebtn" +
                                            (item.key == selectedDate
                                                ? " active"
                                                : "")
                                        }
                                        onClick={() => renderDate(item.key)}
                                    >
                                        {item.value}
                                    </button>
                                </div>
                            );
                        })}
                        <RangeCalendar
                            setRangeDate={{
                                startDate:
                                    filters.startDate !== null &&
                                    filters.startDate !== undefined
                                        ? dateParse(filters.startDate)
                                        : null,
                                endDate:
                                    filters.endDate !== null &&
                                    filters.endDate !== undefined
                                        ? dateParse(filters.endDate)
                                        : null,
                            }}
                            getUserRangeDate={(date, isError) => {
                                const { startDate: start, endDate: end } = date;
                                setFilters({
                                    ...filters,
                                    startDate: start ? ConvertDate(start) : "",
                                    endDate: end ? ConvertDate(end) : "",
                                    isError,
                                });
                            }}
                            verticalAlign={false}
                            isDisabled={false}
                            timePicker={false}
                            clear={clear}
                        />
                    </div>
                </li>

                <li>
                    <span className="subTitle">상태</span>
                    <div className="chkBoxDiv">
                        {Object.keys(isChecked)
                            .reverse()
                            .map((check: any, idx) => (
                                <Checkbox
                                    key={idx.toString()}
                                    isDisabled={false}
                                    isChecked={isChecked[Number(check)]}
                                    label={
                                        idx === 0
                                            ? `일반`
                                            : idx === 1
                                            ? `정지`
                                            : idx === 2
                                            ? `휴면`
                                            : idx === 4
                                            ? `탈퇴`
                                            : `탈퇴(가입제한)`
                                    }
                                    defaultValue={check}
                                    handleChange={(n, v) => {
                                        const { setCheckedList, statusType } =
                                            handleCheckBoxChange(n, v);
                                        setFilters({
                                            ...filters,
                                            // userType: statusType,
                                            userType: statusType.join(),
                                        });
                                        setIsChecked(setCheckedList);
                                    }}
                                />
                            ))}
                    </div>
                </li>
                <li>
                    <span className="subTitle">구독상태</span>
                    <div className="chkBoxDiv">
                        {Object.keys(subChecked)
                            .reverse()
                            .map((check, idx) => (
                                <Checkbox
                                    key={check}
                                    isDisabled={false}
                                    isChecked={subChecked[check]}
                                    label={
                                        idx === 0
                                            ? "정기구독-무료"
                                            : idx === 1
                                            ? "정기구독-유료"
                                            : "정기구독-미구독"
                                    }
                                    defaultValue={check}
                                    handleChange={(n, v) => {
                                        const { setCheckedListSub, subType } =
                                            handleSubStatusChange(n, v);
                                        setFilters({
                                            ...filters,
                                            subType: subType.join(),
                                        });
                                        setSubChecked(setCheckedListSub);
                                    }}
                                />
                            ))}
                        {/* <Checkbox
                            isDisabled={false}
                            isChecked={subChecked["paid"]}
                            label="정기구독-유료"
                            defaultValue={"paid"}
                            handleChange={(n, v) => {
                                const { setCheckedListSub, subType } =
                                    handleSubStatusChange(n, v);
                                setFilters({
                                    ...filters,
                                    subType: subType.join(),
                                });
                                setSubChecked(setCheckedListSub);
                            }}
                        />
                        <Checkbox
                            isDisabled={false}
                            isChecked={subChecked["none"]}
                            label="정기구독-미구독"
                            defaultValue={"none"}
                            handleChange={(n, v) => {
                                const { setCheckedListSub, subType } =
                                    handleSubStatusChange(n, v);
                                setFilters({
                                    ...filters,
                                    subType: subType.join(),
                                });
                                setSubChecked(setCheckedListSub);
                            }}
                        /> */}
                    </div>
                </li>
                <li>
                    <span className="subTitle">파트너상태</span>
                    <div className="chkBoxDiv">
                        {Object.keys(partnerChecked)
                            .reverse()
                            .map((check: any, idx) => (
                                <Checkbox
                                    key={idx.toString()}
                                    isDisabled={false}
                                    isChecked={partnerChecked[check]}
                                    label={idx === 0 ? `입력` : "미입력"}
                                    defaultValue={check}
                                    handleChange={(n, v) => {
                                        setPartnerChecked({
                                            ...partnerChecked,
                                            [n]: v,
                                        });
                                    }}
                                />
                            ))}
                    </div>
                </li>
                <li>
                    <span className="subTitle">검색</span>
                    <div>
                        <DropDown
                            handleChange={handleDropChange}
                            data={dropList}
                            defaultValue={searchType}
                        />
                        <Input
                            label=""
                            value={searchKeyword}
                            name="searchInput"
                            type="text"
                            size="medium"
                            handleChange={handleKeywordChange}
                            onKeyDown={(e: any) => {
                                if (e.key === "Enter") {
                                    handleSearchClick();
                                }
                            }}
                            isDisabled={false}
                            errorMessage=""
                            placeholder="이름이나 연락처, 이메일, 파트너 닉네임을 입력하세요"
                        />
                    </div>
                </li>
            </ul>
        </div>
    );
}
const FilterDiv = css`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .datebtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .datebtn.active {
        font-weight: 700;
    }
`;
export default UserFilter;
