import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import "@src/styles/CommonTable.css";
import { Calendar, Input } from "@src/components";
import { useSelector } from "react-redux";
import { RegisInfoPublicType } from "@src/types";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { ConvertDate } from "@src/utils/dateUtil";
import { dateParse } from "@src/utils/dateUtil";

interface IRegisteredInfoPublic {
    isUpdate: boolean;
    handleUpdate?: Function;
}
function RegisteredInfoPublicContainer({
    isUpdate = false,
    handleUpdate,
}: IRegisteredInfoPublic) {
    const headerMeta = [
        "번호",
        "권리종류",
        "권리자명",
        "설정일",
        "설정금액(원)",
    ];
    const defaultData = useSelector((state: any) => state.registeredInfoPublic);
    const [tableData, setTableData] =
        useState<RegisInfoPublicType[]>(defaultData);
    useEffect(() => {
        setTableData(defaultData);
    }, [defaultData]);

    const handleChange = (updateData: RegisInfoPublicType, index: number) => {
        let newTableData = [...tableData];
        newTableData[index] = updateData;
        setTableData([...newTableData]);
    };
    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);
    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: any) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isUpdate={isUpdate}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

const TableRow = ({ data, isUpdate, handleChange }: any) => {
    const [rowData, setRowData] = useState<RegisInfoPublicType>(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const { id, rightType, rightsHolder, rightDate, rightPrice } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRowData({
            ...rowData,
            [name]: value,
        });
    };

    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);
    const onChangeDate = (name: string, value: number) => {
        setRowData((prev) => ({
            ...prev,
            [name]: ConvertDate(value, "yyyy.MM.dd"),
        }));
    };
    return (
        <tr key={id} style={isUpdate ? { borderBottom: "none" } : undefined}>
            <td>
                <Input
                    name="id"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={true}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={id + 1}
                />
            </td>
            <td>
                <Input
                    name="rightType"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rightType : ConvertEmptyValue(rightType)}
                />
            </td>
            <td>
                <Input
                    name="rightsHolder"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? rightsHolder
                            : ConvertEmptyValue(rightsHolder)
                    }
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(rightDate)}
                    getUserDate={(value) => onChangeDate("rightDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
            <td>
                <Input
                    name="rightPrice"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rightPrice : resultPrice(rightPrice)}
                />
            </td>
        </tr>
    );
};
const calendarItem = css`
    & input {
        height: 47px;
        width: 100%;
        border-bottom-width: 1.5px;
    }
`;
export default RegisteredInfoPublicContainer;
