import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loading from "./loading";
import selectUser from "./selectUser";
import bannerView from "./bannerView";
import bannerType from "./bannerType";
import comment from "./comment";
import expectedReturn from "./expectedReturn";
import detailInfo from "./detailInformation";
import caseAnalysis from "./caseAnalysis";
import buildingInfo from "./buildingInformation";
import registeredInfo from "./registeredInformation";
import surroundInfo from "./surroundInformation";
import standardInfo from "./standardInformation";
import publicInformation from "./publicInformation";
import bidHistory from "./bidHistory";
import locationStatus from "./locationStatus";
import registeredInfoPublic from "./registeredInfoPublic";
import rentInfoPublic from "./rentInfoPublic";
import occupancyRelation from "./occupancyRelation";
import distributionOrBond from "./distributionOrBond";
import saleCase from "./saleCase";
import productViewCategory from "./ProductViewCategory";
import buildingRegisInfo from "./buildingRegisInfo";
import landRegisInfo from "./landRegisInfo";
import seizeInfo from "./seizeInfo";
import dateHistory from "./dateHistory";
import auctionProduct from "./auctionProduct";
import lesseeInfo from "./lesseeInfo";
import errorPopUp from "./errorPopUp";
import productUpdateResult from "./productUpdateResult";
import push from "./push";
import modal from "./modal";

const reducers = combineReducers({
    loading,
    selectUser,
    bannerView,
    bannerType,
    comment,
    expectedReturn,
    detailInfo,
    caseAnalysis,
    buildingInfo,
    registeredInfo,
    surroundInfo,
    standardInfo,
    publicInformation,
    bidHistory,
    locationStatus,
    registeredInfoPublic,
    rentInfoPublic,
    occupancyRelation,
    distributionOrBond,
    saleCase,
    productViewCategory,
    buildingRegisInfo,
    landRegisInfo,
    seizeInfo,
    dateHistory,
    auctionProduct,
    lesseeInfo,
    errorPopUp,
    productUpdateResult,
    push,
    modal,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default persistedReducer;
