export const Common = {
    colors: {
        white: "#fff",
        black: "#222",

        purple600:
            "radial-gradient(116.28% 116.28% at 0% -16.28%, #634AFD 0%, #634AFD 0.01%, #9A49F2 100%, #9A49F2 100%)",
        purple500: "#9A49F2",
        purple400: "#AE6DF5",
        purple300: "#C292F7",
        purple200: "#D7B6FA",
        purple100: "#EBDBFC",
        purple50: "#F8F1FF",
        purple1: "#BB85E1",

        gray700: "#666666",
        gray600: "#818181",
        gray500: "#9D9FA5",
        gray400: "#B4B4B9",
        gray300: "#D4D4D8",
        gray200: "#E3E3E6",
        gray100: "#F4F5F7",

        orange: "#FF752F",
        blue: "#0060F9",

        success: "#4B87FC",
        error: "#F45B5B",
        focus: "#9A49F2",
    },
    fontSize: {
        fs18: "18px",
        fs16: "16px",
        fs14: "14px",
        fs12: "12px",
        fs11: "11px",
    },
    textStyle: {
        display1_B18: `
        font-size: 18px;
        font-weight: 600;
        line-height: 165%;
    `,
        display2_M18: `
        font-size: 18px;
        font-weight: 500;
        line-height: 165%;
    `,
        display3_R18: `
        font-size: 18px;
        font-weight: 400;
        line-height: 175%;
    `,
        title1_B16: `
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.4px;
    `,
        title2_M16: `
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.4px;
    `,
        title3_R16: `
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.4px;
    `,
        title4_B14: `
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
    `,
        title5_M14: `
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
    `,
        title6_R14: `
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
    `,
        title7_M12: `
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
    `,
        title8_R12: `
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
    `,
        body1_M16: `
        font-size: 16px;
        font-weight: 500;
        line-height: 175%;
        letter-spacing: -0.4px;
    `,
        body2_R16: `
        font-size: 16px;
        font-weight: 400;
        line-height: 175%;
        letter-spacing: -0.4px;
    `,
        body3_R14: `
        font-size: 14px;
        font-weight: 400;
        line-height: 170%;
    `,
        body4_R12: `
        font-size: 12px;
        font-weight: 400;
        line-height: 180%; 
    `,
        caption_R11: `
        font-size: 11px;
        font-weight: 400;
        line-height: 165%;
    `,
        text1_R13: `
        font-size: 13px;
        font-weight: 400;
        line-height: 160%;
    `,
    },
};
