import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/react";
import { ko } from "date-fns/locale";
import { Common } from "@src/styles/Common";
import calendarIcon from "@src/assets/calenderIcon.png";

interface ICalendar {
    setDate: EpochTimeStamp | null;
    getUserDate: (date: EpochTimeStamp) => void;
    isDisabled?: boolean;
    timePicker?: boolean;
    timeInterval?: number;
    minDate?: EpochTimeStamp;
}

function Calendar({
    getUserDate,
    setDate,
    isDisabled = false,
    timePicker = false,
    timeInterval = 30,
    minDate,
}: ICalendar) {
    const [startDate, setStartDate] = useState<EpochTimeStamp | null>(
        setDate ? setDate : null,
    );
    const handleChange = (date: any) => {
        getUserDate(+date);
        setStartDate(date);
    };
    useEffect(() => {
        setStartDate(setDate ? setDate : null);
    }, [setDate]);
    return (
        <div css={WrapperStyle}>
            <DatePicker
                locale={ko}
                css={
                    isDisabled ? [CalenderStyle, DisabledStyle] : CalenderStyle
                }
                dateFormat={timePicker ? "yyyy.MM.dd HH:mm" : "yyyy.MM.dd"}
                placeholderText="날짜 선택"
                selected={startDate ? new Date(startDate) : null}
                onChange={handleChange}
                onChangeRaw={(e) => e.preventDefault()}
                readOnly={isDisabled}
                showTimeSelect={timePicker}
                timeIntervals={timeInterval}
                minDate={minDate ? new Date(minDate) : new Date(0)}
            />
        </div>
    );
}

const WrapperStyle = css`
    .react-datepicker__input-container {
        caret-color: transparent;
    }
`;
const CalenderStyle = css`
    border: none;
    border-bottom: 1.5px solid ${Common.colors.gray200};
    padding: 13px 12px;
    cursor: pointer;
    ${Common.textStyle.title6_R14}
    background: url(${calendarIcon}) no-repeat;
    background-position: right 0 center;
    background-size: 24px 24px;
    outline: none;
`;
const DisabledStyle = css`
    background: none;
    border-bottom: none;
`;
export default Calendar;
