import { css } from "@emotion/react";
import { Title } from "@src/components";
import {
    useGetViewData,
    useUpdateProvision,
} from "@src/hooks/queries/useCommodityProvision";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProvisionForm, { ProductType } from "./ProvisionForm";

export interface ProductDataType {
    no: number;
    productNo: number;
    // type: string;
    // code: string;
    name: string;
    // subs_group: string;
    // subs_group_name: string;
    // postbox_situation_code: string;
    // issue_type: null;
    // status: number;
    comments: string;
}

const ProvisionUpdate = () => {
    const navigate = useNavigate();
    const productInitialState: ProductType = {
        no: 0,
        type: "",
        code: "",
        name: "",
        subs_group: "",
        subs_group_name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    };

    const productDataInitalState: ProductDataType = {
        no: 0,
        productNo: 0,
        // type: "",
        // code: "",
        name: "",
        // subs_group: "",
        // subs_group_name: "",
        // postbox_situation_code: "",
        // issue_type: null,
        // status: 0,
        comments: "",
        // created_by: 0,
        // updated_by: 0,
        // created_at: "",
        // updated_at: "",
    };
    const { id } = useParams();
    const [productList, setProductList] = useState<ProductType[]>([
        productInitialState,
    ]);

    const [prevData, setPrevData] = useState();
    const [isChange, setIsChange] = useState<boolean>(false);
    const [productData, setProductData] = useState<ProductDataType>(
        productDataInitalState,
    );

    const tryGetViewData = async () => {
        const response = await useGetViewData(id);
        const data = response.data;
        setPrevData(data);
        setProductData(data);
    };

    useEffect(() => {
        tryGetViewData();
    }, []);

    useEffect(() => {
        if (JSON.stringify(prevData) === JSON.stringify(productData)) {
            setIsChange(false);
        } else {
            setIsChange(true);
        }
    }, [productData, prevData]);

    const tryUpdateProvision = async () => {
        const response = await useUpdateProvision(productData);
        navigate("/commodity/provision", { state: true });
    };

    return (
        <>
            <div css={detailStyle}>
                <Title title="상품 지급 수정" />
                <ProvisionForm
                    type="update"
                    productList={productList}
                    setProductList={setProductList}
                    productData={productData}
                    setProductData={setProductData}
                    isChange={isChange}
                    tryUpdateProvision={tryUpdateProvision}
                />
            </div>
        </>
    );
};

export default ProvisionUpdate;

const detailStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 68px;
    gap: 20px;
`;
