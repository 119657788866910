import React, { useState } from "react";
import { startLoading, endLoading } from "@src/reducers/loading";
import { updateProductViewCategory } from "@src/reducers/ProductViewCategory";
import { ConfirmModal, Button } from "@src/components";

function ViewCategoryButton({ updateViewCategory, dispatch }: any) {
    //노출 카테고리 업데이트 버튼과 수정 확인 팝업
    const [isModalShow, setIsModalShow] = useState(false);
    const handleClick = () => setIsModalShow(true);
    const handleOk = () => {
        setIsModalShow((prev) => !prev);
        dispatch(startLoading());

        console.log(updateViewCategory);
        setTimeout(() => {
            dispatch(updateProductViewCategory(updateViewCategory));
            dispatch(endLoading());
        }, 700);
    };
    return (
        <>
            <Button
                label="카테고리 노출 여부 저장"
                color="gd"
                size="medium"
                handleClick={handleClick}
            />
            <ConfirmModal
                buttonText="저장"
                isModalShow={isModalShow}
                handleOk={handleOk}
                handleCancel={() => {
                    setIsModalShow((prev) => !prev);
                }}
            >
                <>
                    정보를 수정하시겠습니까? 올바르게 입력하였는지 다시 한번
                    확인해주세요.
                </>
            </ConfirmModal>
        </>
    );
}

export default ViewCategoryButton;
