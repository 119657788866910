import { Button, Calendar, IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { getDate } from "date-fns";
import React, { Dispatch, SetStateAction } from "react";

// interface getUserProductListColumnsProps {
//     setIsDeungiEditModalShow: ;
// }

export const getUserDeungiEditColumns = (
    setEditTime: Dispatch<SetStateAction<string>>,
) =>
    // setIsDeungiEditModalShow: Dispatch<SetStateAction<boolean>>,
    // setIsProductModalShow: Dispatch<SetStateAction<boolean>>,
    [
        {
            Header: "충전일시",
            accessor: "startedAt",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        width: "128px",
                        textAlign: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            ),
        },
        {
            Header: "충전정보",
            accessor: "productName",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        width: "156px",
                        textAlign: "center",
                    }}
                >
                    {value ? value + " 충전" : INVALID_VALUE}
                </div>
            ),
        },
        {
            Header: "사용 수량",
            accessor: "usedQuantity",
            Cell: ({ cell: { value }, row }: any) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            textAlign: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },

        {
            Header: "유효기안",
            accessor: "expiredAt",
            Cell: ({ cell: { value }, row }: any) => {
                const time = new Date(value).getTime();

                return (
                    <div
                        style={{
                            width: "180px",
                            textAlign: "center",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        <Calendar
                            getUserDate={(date) => {
                                setEditTime(
                                    new Date(date).toISOString().slice(0, 10),
                                );
                            }}
                            setDate={time}
                        />
                    </div>
                );
            },
        },
    ];
