export const BannerType = [
    {
        id: 1,
        key: "home",
        value: "APP-홈",
    },
    {
        id: 2,
        key: "alarm",
        value: "알림함",
    },
    {
        id: 3,
        key: "alarm_setting",
        value: "알림설정",
    },
    {
        id: 4,
        key: "detail_duehist",
        value: "매물상세1경매",
    },
    {
        id: 5,
        key: "detail_bidhist",
        value: "매물상세1공매",
    },
    {
        id: 6,
        key: "detail_rightcheck",
        value: "매물상세2",
    },
    {
        id: 7,
        key: "mypage",
        value: "내정보",
    },
    {
        id: 8,
        key: "popup",
        value: "팝업",
    },
    {
        id: 9,
        key: "subs",
        value: "정기구독이용권",
    },
    {
        id: 10,
        key: "deungi",
        value: "열람권충전",
    },
    {
        id: 11,
        key: "splash",
        value: "로딩화면",
    },
    {
        id: 12,
        key: "campus",
        value: "캠퍼스",
    },
    {
        id: 13,
        key: "goStop",
        value: "고스톱",
    },
    {
        id: 14,
        key: "pcHome",
        value: "PC-홈",
    },
    {
        id: 15,
        key: "pcBidDetail",
        value: "PC-매물상세1경매",
    },
    {
        id: 16,
        key: "pcBidDetail2",
        value: "PC-매물상세2경매",
    },
    {
        id: 17,
        key: "pcSubscriptionPage",
        value: "PC-구독결제페이지",
    },
    {
        id: 18,
        key: "pcAiChat",
        value: "PC-대화형 검색",
    },
    {
        id: 19,
        key: "homeNewFunc",
        value: "APP홈-신규기능 배너",
    },
    {
        id: 20,
        key: "homeCampus",
        value: "APP홈-캠퍼스 배너",
    },
    {
        id: 21,
        key: "aiChatSearch",
        value: "APP-대화형검색",
    },
    {
        id: 22,
        key: "appSubscriptionPage",
        value: "APP-구독페이지",
    },
    {
        id: 23,
        key: "pcHomeNewFunc",
        value: "PC홈-신규기능 배너",
    },
    {
        id: 24,
        key: "pcHomeCampus",
        value: "PC홈-캠퍼스 배너",
    },
    {
        id: 25,
        key: "pcHomeWide",
        value: "PC홈-와이드 배너",
    },
    {
        id: 26,
        key: "pcPopup",
        value: "PC-홈 팝업 배너",
    },
    {
        id: 27,
        key: "pcInquiryPopup",
        value: "PC-개선문의 팝업 배너",
    },
    {
        id: 28,
        key: "pcSubsPrice",
        value: "PC-구독요금제",
    },
    {
        id: 29,
        key: "pcStatelandPrice",
        value: "PC-국공유지요금제",
    },
    {
        id: 30,
        key: "pcRoadPlanPrice",
        value: "PC-도로확장 데이터맵 요금제",
    },
    {
        id: 31,
        key: "pcGoStopPrice",
        value: "PC-고스톱 요금제",
    },
];
