import { css } from "@emotion/react";
import {
    Button,
    Calendar,
    ConfirmModal,
    DropDown,
    EditModal,
    Input,
    PageTable,
    Title,
} from "@src/components";
import {
    useChangeExpertBanner,
    useCreatedExpertBanner,
    useDeleteAdvertisment,
    useEndAdvertisment,
    useGetExpertsAdList,
    useGetInquiryList,
    useGetSearchItem,
    useRestoreAdvertisment,
    useRestoreTicket,
} from "@src/hooks/queries/Advertisement";
import { ConvertPhoneNumber } from "@src/utils/units";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { getExpertAdvertisementColumns } from "./ExpertAdvertisementColumns";
import ExpertAdvertisementListFilter from "./ExpertAdvertisementListFilter";
import {
    ExpertAdvertisementFilterDto,
    IExpertInquiryListParams,
    initExpertAdvertisementFilter,
} from "./types";

const ExpertAdvertisementList = () => {
    const [params, setParams] = useState<ExpertAdvertisementFilterDto>(
        initExpertAdvertisementFilter,
    );
    const [filters, setFilters] = useState<ExpertAdvertisementFilterDto>(
        initExpertAdvertisementFilter,
    );
    const [errorModalState, setErrorModalState] = useState<{
        isShowModal: boolean;
        errorMessage: string;
    }>({
        isShowModal: false,
        errorMessage: "",
    });
    const initAdvertisementStatus = {
        광고중: false,
        광고종료: false,
        관리자삭제: false,
    };
    const initAdvertisementType = {
        item: false,
        search: false,
    };
    const initAdvertisementManager = {
        공동관리: false,
        담당자있음: false,
    };
    const [advertisementStatus, setAdvertisementStatus] = useState<any>(
        initAdvertisementStatus,
    );
    const [advertisementType, setAdvertisementType] = useState<any>(
        initAdvertisementType,
    );

    const [advertisementManager, setAdvertisementManager] = useState<any>(
        initAdvertisementManager,
    );
    const [getInquiryState, setGetInquiryState] =
        useState<IExpertInquiryListParams>({
            bannerNo: 0,
            page: 1,
            limit: 10,
        });
    // const [inquiryState, setInquiryState] = useState<{
    //     page?: number;
    //     limit?: number;
    //     inquiryType?: string;
    // }>({
    //     page: 1,
    //     limit: 10,
    // });
    const [isShowInfoEditModal, setIsShowInfoEditModal] =
        useState<boolean>(false);
    const [changeAdInfoState, setChangeAdInfoState] = useState<any>();
    const [confirmModalState, setConfirmModalState] = useState<{
        isShowModal: boolean;
        modalText: string;
        modalType: number;
        bannerNo: number;
        expertUserNo: number;
    }>({
        isShowModal: false,
        modalText: "",
        modalType: 0,
        bannerNo: 0,
        expertUserNo: 0,
    });
    const [isShowInquiryList, setIsShowInquiryList] = useState<boolean>(false);
    const columns = getExpertAdvertisementColumns(
        (isShow: boolean) => {
            setIsShowInfoEditModal(isShow);
        },
        setConfirmModalState,
        setChangeAdInfoState,
        setIsShowInquiryList,
        setGetInquiryState,
        setErrorModalState,
    );
    const sortDropDownList: {
        id: number;
        key: string;
        value: string;
        order: string;
    }[] = [
        {
            id: 0,
            key: "expertBanner.created_at",
            value: "광고 최신순",
            order: "DESC",
        },
        {
            id: 1,
            key: "expertBanner.created_at",
            value: "광고 오래된순",
            order: "ASC",
        },
        {
            id: 2,
            key: "inquiryCount",
            value: "총 문의 건수 많은순",
            order: "DESC",
        },
        {
            id: 3,
            key: "inquiryCount",
            value: "총 문의 건수 적은순",
            order: "ASC",
        },
    ];

    const bidTypeDropDownList: {
        id: number;
        key: string;
        value: string;
    }[] = [
        {
            id: 0,
            key: "0",
            value: "전체",
        },
        {
            id: 1,
            key: "1",
            value: "경매",
        },
        {
            id: 2,
            key: "2",
            value: "공매",
        },
    ];

    const onClickOk = () => {
        switch (confirmModalState.modalType) {
            case 1:
                onClickEndAdvertisment();
                break;
            case 2:
                onClickDeleteAdvertisment();
                break;
            case 3:
                onClickRestoreAdvertisment();
                break;
            case 4:
                onClickRestoreTicket();
                break;
        }
        setConfirmModalState({
            isShowModal: false,
            modalText: "",
            modalType: 0,
            bannerNo: 0,
            expertUserNo: 0,
        });
    };

    const [isShowGetItemInfoModal, setIsShowGetItemInfoModal] =
        useState<boolean>(false);

    const { data, refetch } = useGetExpertsAdList(params);
    useEffect(() => {
        refetch();
    }, [params]);

    const endAdvertisment = useEndAdvertisment(
        (res) => {
            // if(res.)
            if (res === "success") {
                refetch();
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
            } else {
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
                setErrorModalState({ isShowModal: true, errorMessage: res });
            }
        },
        () => {
            setConfirmModalState({
                isShowModal: false,
                modalText: "",
                modalType: 0,
                bannerNo: 0,
                expertUserNo: 0,
            });
        },
    );

    const onClickEndAdvertisment = () => {
        endAdvertisment.mutate({
            bannerNo: String(confirmModalState.bannerNo),
            expertUserNo: String(confirmModalState.expertUserNo),
        });
    };

    const deleteAdvertisment = useDeleteAdvertisment(
        (res) => {
            if (res === "success") {
                refetch();
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
            } else {
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
                setErrorModalState({ isShowModal: true, errorMessage: res });
            }
        },
        () => {
            setConfirmModalState({
                isShowModal: false,
                modalText: "",
                modalType: 0,
                bannerNo: 0,
                expertUserNo: 0,
            });
        },
    );
    const onClickDeleteAdvertisment = () => {
        deleteAdvertisment.mutate({
            bannerNo: String(confirmModalState.bannerNo),
            expertUserNo: String(confirmModalState.expertUserNo),
        });
    };
    const restoreAdvertisment = useRestoreAdvertisment(
        (res) => {
            if (res === "success") {
                refetch();
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
            } else {
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
                setErrorModalState({ isShowModal: true, errorMessage: res });
            }
        },
        () => {
            setConfirmModalState({
                isShowModal: false,
                modalText: "",
                modalType: 0,
                bannerNo: 0,
                expertUserNo: 0,
            });
        },
    );

    const onClickRestoreAdvertisment = () => {
        restoreAdvertisment.mutate({
            bannerNo: String(confirmModalState.bannerNo),
            expertUserNo: String(confirmModalState.expertUserNo),
        });
    };

    const restoreTicket = useRestoreTicket(
        (res) => {
            if (res === "success") {
                refetch();
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
            } else {
                setConfirmModalState({
                    isShowModal: false,
                    modalText: "",
                    modalType: 0,
                    bannerNo: 0,
                    expertUserNo: 0,
                });
                setErrorModalState({ isShowModal: true, errorMessage: res });
            }
        },
        () => {
            setConfirmModalState({
                isShowModal: false,
                modalText: "",
                modalType: 0,
                bannerNo: 0,
                expertUserNo: 0,
            });
        },
    );

    const onClickRestoreTicket = () => {
        restoreTicket.mutate({
            bannerNo: String(confirmModalState.bannerNo),
            expertUserNo: String(confirmModalState.expertUserNo),
        });
    };

    const trySubsmit = () => {
        setParams(filters);
    };
    const tryClear = () => {
        setParams(initExpertAdvertisementFilter);
        setFilters(initExpertAdvertisementFilter);
    };

    const [searchItemState, setSearchItemState] = useState<any>({
        cursor: {
            sortType: 0,
        },
        limit: 10,
        bidType: 0,
        searchText: "",
    });

    const {
        data: searchItem,
        refetch: refetchSearchItem,
        hasNextPage: searchItemHasNextPage,
        fetchNextPage: searchItemfetchNextPage,
    } = useGetSearchItem(searchItemState);
    useEffect(() => {
        if (searchItemState.searchText) {
            refetchSearchItem();
        }
    }, [searchItemState.bidType]);

    const [itemRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(searchItemHasNextPage),
        onLoadMore: () => {
            searchItemfetchNextPage();
        },
        disabled: false,
        delayInMs: 100,
    });

    const updateExpertBanner = useChangeExpertBanner(
        (res) => {
            if (res === "success") {
                setIsShowInfoEditModal(false);
                setSearchItemState({
                    cursor: {
                        sortType: 0,
                    },
                    limit: 10,
                    bidType: 0,
                    searchText: "",
                });
                setChangeAdInfoState({});
                refetch();
            } else {
                setIsShowInfoEditModal(false);
                setSearchItemState({
                    cursor: {
                        sortType: 0,
                    },
                    limit: 10,
                    bidType: 0,
                    searchText: "",
                });
                setChangeAdInfoState({});
                setErrorModalState({ isShowModal: true, errorMessage: res });
            }
        },
        () => {
            setSearchItemState({
                cursor: {
                    sortType: 0,
                },
                limit: 10,
                bidType: 0,
                searchText: "",
            });
            setChangeAdInfoState({});
            setIsShowInfoEditModal(false);
        },
    );

    const onClickUpdateExpertBanner = () => {
        updateExpertBanner.mutate({
            bannerNo: changeAdInfoState.bannerNo,
            itemId: changeAdInfoState.itemId,
            startDate: changeAdInfoState.createdAt,
            endDate: changeAdInfoState.expiredAt,
        });
    };
    const createExpertBanner = useCreatedExpertBanner(
        (res) => {
            if (res === "success") {
                setIsShowInfoEditModal(false);
                setSearchItemState({
                    cursor: {
                        sortType: 0,
                    },
                    limit: 10,
                    bidType: 0,
                    searchText: "",
                });
                setChangeAdInfoState({});
                refetch();
            } else {
                setIsShowInfoEditModal(false);
                setSearchItemState({
                    cursor: {
                        sortType: 0,
                    },
                    limit: 10,
                    bidType: 0,
                    searchText: "",
                });
                setChangeAdInfoState({});
                setErrorModalState({ isShowModal: true, errorMessage: res });
            }
        },
        () => {
            setSearchItemState({
                cursor: {
                    sortType: 0,
                },
                limit: 10,
                bidType: 0,
                searchText: "",
            });
            setChangeAdInfoState({});
            refetch();
            setIsShowInfoEditModal(false);
        },
    );

    const onClickCreateExpertBanner = () => {
        createExpertBanner.mutate({
            userNo: changeAdInfoState.userNo,
            bannerNo: changeAdInfoState.bannerNo,
            itemId: changeAdInfoState.itemId,
            startDate: changeAdInfoState.createdAt,
            endDate: changeAdInfoState.expiredAt,
        });
    };

    const {
        data: inquiryList,
        refetch: refetchInquiryList,
        hasNextPage: inquiryListHasNextPage,
        fetchNextPage: inquiryListFetechNextPage,
    } = useGetInquiryList(getInquiryState);

    useEffect(() => {
        if (getInquiryState?.bannerNo) {
            refetchInquiryList();
        }
    }, [getInquiryState?.bannerNo, getInquiryState?.inquiryType]);

    const [inquiryRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(inquiryListHasNextPage),
        onLoadMore: () => {
            inquiryListFetechNextPage();
        },
        disabled: false,
        delayInMs: 100,
    });

    return (
        <>
            <div css={mainWrap}>
                <Title title="전문가 광고 관리" />
                <ExpertAdvertisementListFilter
                    filters={filters}
                    setFilters={setFilters}
                    advertisementStatus={advertisementStatus}
                    // setAdvertisementStatus={setAdvertisementStatus}
                    setAdvertisementStatus={(text: string, value: boolean) => {
                        setAdvertisementStatus({
                            ...advertisementStatus,
                            [text]: value,
                        });
                    }}
                    advertisementType={advertisementType}
                    setAdvertisementType={(text: string, value: boolean) => {
                        setAdvertisementType({
                            ...advertisementType,
                            [text]: value,
                        });
                    }}
                    advertisementManager={advertisementManager}
                    setAdvertisementManager={(text: string, value: boolean) => {
                        setAdvertisementManager({
                            ...advertisementManager,
                            [text]: value,
                        });
                    }}
                />
                <div css={filterBtnBox}>
                    <Button
                        color="gray"
                        handleClick={() => {
                            tryClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            trySubsmit();
                        }}
                        label="검색"
                        size="medium"
                        isDisabled={false}
                    />
                </div>
                <div css={tableWrap}>
                    <div css={sortWrap}>
                        <DropDown
                            handleChange={(e, text, value) => {
                                if (value && text) {
                                    setFilters({
                                        ...filters,
                                        sort: value,
                                        order:
                                            text.includes("최신순") ||
                                            text.includes("많은순")
                                                ? "DESC"
                                                : "ASC",
                                    });
                                    setParams({
                                        ...filters,
                                        sort: value,
                                        order:
                                            text.includes("최신순") ||
                                            text.includes("많은순")
                                                ? "DESC"
                                                : "ASC",
                                    });
                                }
                            }}
                            data={sortDropDownList}
                            defaultValue={""}
                        />
                    </div>
                    <PageTable
                        columns={columns}
                        data={data?.list ?? []}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        FixedHeight={500}
                        showTotalCount={true}
                        totalCount={data?.totalCount ?? 0}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParams({
                                ...params,
                                page,
                            });
                        }}
                    />
                </div>
                <ConfirmModal
                    buttonText="확인"
                    handleCancel={() =>
                        setConfirmModalState({
                            ...confirmModalState,
                            ...{ isShowModal: false },
                        })
                    }
                    handleOk={onClickOk}
                    isModalShow={confirmModalState.isShowModal}
                >
                    <>{confirmModalState.modalText}</>
                </ConfirmModal>
                <EditModal
                    buttonText="저장"
                    handleBtnClick={() => {
                        setIsShowInfoEditModal(false);
                        changeAdInfoState.type === "create"
                            ? onClickCreateExpertBanner()
                            : onClickUpdateExpertBanner();
                    }}
                    handleOpenModal={() => {
                        setIsShowInfoEditModal(false);
                        setChangeAdInfoState({});
                    }}
                    size="fitContent"
                    title="광고 정보 수정"
                    isModalShow={isShowInfoEditModal}
                    checkCloseModal={true}
                >
                    <div css={editModalWrap}>
                        <div css={modalContentsWrap}>
                            <div css={modalContentsTitle}>물건정보</div>
                            <div css={modalContentsItem}>
                                <span>구분</span>
                                <span>{changeAdInfoState?.bidType}</span>
                                <div css={changeButtonDiv}>
                                    <span>{changeAdInfoState?.itemSubId}</span>
                                    <Button
                                        color="purple"
                                        handleClick={() => {
                                            setIsShowInfoEditModal(false);
                                            setIsShowGetItemInfoModal(true);
                                        }}
                                        label="변경"
                                        size="medium"
                                    />
                                </div>
                            </div>
                        </div>
                        <div css={modalContentsWrap}>
                            <div css={modalContentsTitle}>광고일시</div>
                            <div css={modalContentsDate}>
                                <span css={titleSpan}>
                                    <span css={essential}>*</span>광고 시작일시
                                </span>
                                <Calendar
                                    getUserDate={(date) => {
                                        setChangeAdInfoState({
                                            ...changeAdInfoState,
                                            createdAt: format(
                                                new Date(date),
                                                "yyyy-MM-dd",
                                            ),
                                        });
                                    }}
                                    setDate={changeAdInfoState?.createdAt}
                                />
                                <span css={graySpan}>부터</span>
                            </div>
                            <div css={modalContentsDate}>
                                <span css={titleSpan}>
                                    <span css={essential}>*</span>광고 종료일시
                                </span>
                                <Calendar
                                    getUserDate={(date) => {
                                        setChangeAdInfoState({
                                            ...changeAdInfoState,
                                            expiredAt: format(
                                                new Date(date),
                                                "yyyy-MM-dd",
                                            ),
                                        });
                                    }}
                                    setDate={changeAdInfoState?.expiredAt}
                                />
                                <span css={graySpan}>까지</span>
                            </div>
                        </div>
                    </div>
                </EditModal>
                <EditModal
                    buttonText="저장"
                    handleBtnClick={() => {}}
                    handleOpenModal={() => {
                        setSearchItemState({
                            cursor: {
                                sortType: 0,
                            },
                            limit: 10,
                            bidType: 0,
                            searchText: "",
                        });
                        setIsShowGetItemInfoModal(false);
                    }}
                    size="fitContent"
                    title="물건정보 가져오기"
                    isModalShow={isShowGetItemInfoModal}
                    checkCloseModal={true}
                    deleteButton
                >
                    <div css={itemModalWrap}>
                        <div css={modalSearchDiv}>
                            <DropDown
                                handleChange={(e, text, value) => {
                                    value &&
                                        setSearchItemState({
                                            ...searchItemState,
                                            bidType: Number(value),
                                        });
                                }}
                                data={bidTypeDropDownList}
                                defaultValue={""}
                            />
                            <Input
                                placeholder="사건번호/물건관리번호를 입력하세요"
                                handleChange={(e) => {
                                    setSearchItemState({
                                        ...searchItemState,
                                        searchText: e.target.value,
                                    });
                                }}
                                size="medium"
                                value={searchItemState.searchText}
                            />
                            <Button
                                color="purple"
                                handleClick={() => {
                                    searchItemState?.searchText &&
                                        refetchSearchItem();
                                }}
                                label="검색"
                                size="medium"
                            />
                        </div>
                        <div>
                            총 {searchItem?.pages[0].totalCount}건
                            <table css={tableCss}>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>사건번호/물건관리번호</th>
                                        <th>용도</th>
                                        <th>소재지</th>
                                        <th>상태</th>
                                        <th>선택</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchItemState.searchText &&
                                    searchItem?.pages[0]?.list?.length ? (
                                        <>
                                            {searchItem?.pages.map(
                                                (page: any) =>
                                                    page.list.map(
                                                        (
                                                            item: any,
                                                            index: number,
                                                        ) => (
                                                            <tr
                                                                key={String([
                                                                    item,
                                                                    index,
                                                                ])}
                                                            >
                                                                <td>
                                                                    {
                                                                        item?.bidType
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item?.itemSubId
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item?.useDetail
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item?.address
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item?.status
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        size="small"
                                                                        color="purple"
                                                                        label="선택"
                                                                        handleClick={() => {
                                                                            setChangeAdInfoState(
                                                                                {
                                                                                    ...changeAdInfoState,
                                                                                    itemId: item.itemId,
                                                                                    itemSubId:
                                                                                        item.itemSubId,
                                                                                    bidType:
                                                                                        item.bidType,
                                                                                },
                                                                            );
                                                                            setIsShowGetItemInfoModal(
                                                                                false,
                                                                            );
                                                                            setIsShowInfoEditModal(
                                                                                true,
                                                                            );
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ),
                                                    ),
                                            )}
                                            <tr ref={itemRef}>
                                                <td
                                                    css={scrollDiv}
                                                    colSpan={8}
                                                ></td>
                                            </tr>
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>
                                                검색어를 입력해주세요
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </EditModal>
                <EditModal
                    buttonText="저장"
                    handleBtnClick={() => {}}
                    handleOpenModal={() => {
                        setIsShowInquiryList(false);
                    }}
                    size="fitContent"
                    title="문의 내역"
                    isModalShow={isShowInquiryList}
                    checkCloseModal={true}
                    deleteButton={true}
                >
                    <div css={inquiryListModal}>
                        <div css={inquiryCount}>
                            <span
                                onClick={() => {
                                    const newInquiryState = {
                                        ...getInquiryState,
                                    };
                                    delete newInquiryState.inquiryType;
                                    setGetInquiryState({
                                        ...newInquiryState,
                                        page: 1,
                                        limit: 10,
                                    });
                                }}
                            >
                                전체 : {inquiryList?.pages[0].totalCount}건
                            </span>
                            <span
                                onClick={() => {
                                    setGetInquiryState({
                                        ...getInquiryState,
                                        page: 1,
                                        limit: 10,
                                        inquiryType: "call",
                                    });
                                }}
                            >
                                전화문의 :{" "}
                                {inquiryList?.pages[0].callCount ?? 0}건
                            </span>
                            <span
                                onClick={() => {
                                    setGetInquiryState({
                                        ...getInquiryState,
                                        page: 1,
                                        limit: 10,
                                        inquiryType: "message",
                                    });
                                }}
                            >
                                문자문의 : {inquiryList?.pages[0].messageCount}
                                건
                            </span>
                        </div>
                        <table css={tableCss}>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>구분</th>
                                    <th>용도</th>
                                    <th>소재지</th>
                                    <th>문의 회원 이름</th>
                                    <th>문의 회원 전화번호</th>
                                    <th>문의 유형</th>
                                    <th>문의 일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inquiryList?.pages[0]?.list?.length ? (
                                    <>
                                        {inquiryList?.pages.map((page: any) =>
                                            page.list.map(
                                                (item: any, index: number) => (
                                                    <tr
                                                        key={String([
                                                            item,
                                                            index,
                                                        ])}
                                                    >
                                                        <td>
                                                            {item?.inquiryNo}
                                                        </td>
                                                        <td>
                                                            {item?.itemSubId}
                                                        </td>
                                                        <td>
                                                            {item?.useDetail}
                                                        </td>
                                                        <td>{item?.address}</td>
                                                        <td>
                                                            {item?.userName}
                                                        </td>
                                                        <td>
                                                            {ConvertPhoneNumber(
                                                                item?.userPhone ??
                                                                    0,
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item?.inquiryType ===
                                                            "call"
                                                                ? "전화문의"
                                                                : "문자문의"}
                                                        </td>
                                                        <td>
                                                            {format(
                                                                new Date(
                                                                    item?.inquiryAt,
                                                                ),
                                                                "yyyy.MM.dd hh:MM:ss",
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            ),
                                        )}
                                        <tr>
                                            <td
                                                ref={inquiryRef}
                                                css={scrollDiv}
                                                colSpan={8}
                                            ></td>
                                        </tr>
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={8}>
                                            검색어를 입력해주세요
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </EditModal>
                <ConfirmModal
                    buttonText="확인"
                    handleCancel={() => {}}
                    handleOk={() => {
                        setErrorModalState({
                            isShowModal: false,
                            errorMessage: "",
                        });
                    }}
                    isModalShow={errorModalState.isShowModal}
                    disabledCancelBtn
                >
                    {errorModalState.errorMessage}
                </ConfirmModal>
            </div>
        </>
    );
};

export default ExpertAdvertisementList;

const mainWrap = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const filterBtnBox = css`
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
`;

const tableWrap = css`
    margin-top: 43px;
    width: 100%;
    height: 80vh;
`;
const sortWrap = css`
    display: flex;
    justify-content: flex-end;
`;

const modalContentsTitle = css`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: black;
`;

const editModalWrap = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: fit-content;
    gap: 60px;
`;

const modalContentsWrap = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: 500px;
`;

const modalContentsItem = css`
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    & > span:nth-of-type(1) {
        margin-right: 79px;
    }
    & > span:nth-of-type(2) {
        margin-right: 38px;
    }
`;

const changeButtonDiv = css`
    display: flex;
    align-items: center;
    gap: 25px;
    & > button {
        width: 80px;
        height: 40px;
        min-width: 80px !important;
    }
`;

const modalContentsDate = css`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    & > div {
        width: 292px;
        & > div {
            & > div {
                & > input {
                    width: 292px;
                }
            }
        }
    }
`;

const titleSpan = css`
    font-size: 16px;
    margin-right: 24px;
    display: flex;
    align-items: center;
`;

const graySpan = css`
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    margin-left: 11px;
`;

const essential = css`
    font-size: 16px;
    color: #ff0000;
`;

const modalSearchDiv = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    & > button {
        min-width: 80px;
        height: 40px;
        width: 80px;
    }
    & > input {
        width: 80%;
        margin: 0 10px;
        border: 1px solid #d9d9d9;
    }
    & > div {
        height: 40px;
        & > label {
            height: 100%;
            & > select {
                height: 100%;
            }
        }
    }
`;

const itemModalWrap = css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 1000px;
`;

const inquiryListModal = css`
    width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const inquiryCount = css`
    display: flex;
    gap: 10px;
`;

const tableCss = css`
    width: 100%;
    min-height: 300px;
    & > thead {
        background-color: #f3f3f3;
        & > tr {
            & > th {
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
    & > tbody {
        width: 100%;
        & > tr {
            & > td {
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
`;

const scrollDiv = css`
    padding: 40px;
`;
