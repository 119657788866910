import { css } from "@emotion/react";
import { Button, Checkbox, Input, RangeCalendar } from "@src/components";
import { Common } from "@src/styles/Common";
import { dateParse, ConvertDate } from "@src/utils/dateUtil";
import React, {
    ChangeEvent,
    Dispatch,
    KeyboardEvent,
    useEffect,
    useState,
} from "react";

interface Props {
    filter: any;
    setFilter: Dispatch<any>;
    onClickSearch: () => void;
    searchParams?: URLSearchParams;
    clearFilter: () => void;
}

const DEFAULT_CHECK = [
    { name: "대기", checked: false, value: 100 },
    { name: "진행중", checked: false, value: 200 },
    { name: "종료", checked: false, value: 300 },
];

const CouponFilter: React.FC<Props> = ({
    filter,
    setFilter,
    onClickSearch,
    searchParams,
    clearFilter,
}) => {
    const [checkList, setCheckList] = useState(DEFAULT_CHECK);
    const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            q: e.target.value,
        });
    };
    useEffect(() => {
        if (searchParams?.toString()) {
            const newParam = Object.fromEntries(searchParams);
            const newFilter = {
                ...filter,
                [`created_dt[start]`]: newParam[`created_dt[start]`]
                    ? newParam[`created_dt[start]`]
                          .split(" ")[0]
                          .replaceAll("-", ".")
                    : 0,
                [`created_dt[end]`]: newParam[`created_dt[end]`]
                    ? newParam[`created_dt[end]`]
                          .split(" ")[0]
                          .replaceAll("-", ".")
                    : 0,
                status: newParam.status ?? 0,
                q: newParam.q ?? "",
            };
            let newCheckList = [...checkList];
            if (newParam.status === "0") {
                newCheckList = newCheckList.map((c) => ({
                    ...c,
                    checked: true,
                }));
            } else if (newParam.status) {
                const statusArray = newParam.status.split(",");
                newCheckList = newCheckList.map((c) => {
                    const newChecked = statusArray.some(
                        (s) => Number(s) === c.value,
                    );
                    return {
                        ...c,
                        checked: newChecked,
                    };
                });
            }
            setCheckList(newCheckList);
            setFilter(newFilter);
        }
    }, []);

    return (
        <>
            <div css={FilterRoot}>
                <ul>
                    <li>
                        <span className="subTitle">등록일시</span>
                        <RangeCalendar
                            setRangeDate={{
                                startDate:
                                    filter[`created_dt[start]`] !== null &&
                                    filter[`created_dt[start]`] !== undefined
                                        ? dateParse(filter[`created_dt[start]`])
                                        : null,
                                endDate:
                                    filter[`created_dt[end]`] !== null &&
                                    filter[`created_dt[end]`] !== undefined
                                        ? dateParse(filter[`created_dt[end]`])
                                        : null,
                            }}
                            getUserRangeDate={(date) => {
                                const { startDate: start, endDate: end } = date;
                                setFilter({
                                    ...filter,
                                    [`created_dt[start]`]: start
                                        ? ConvertDate(start)
                                        : "",
                                    [`created_dt[end]`]: end
                                        ? ConvertDate(end)
                                        : "",
                                });
                            }}
                            verticalAlign={false}
                            isDisabled={false}
                            timePicker={false}
                        />
                    </li>
                    <li>
                        <span className="subTitle">사용 내역</span>
                        <div className="checkBoxRoot">
                            <Checkbox
                                isChecked={checkList.every((c) => c.checked)}
                                label="전체"
                                handleChange={() => {
                                    if (checkList.every((c) => c.checked)) {
                                        const newCheckList = checkList.map(
                                            (c) => ({ ...c, checked: false }),
                                        );
                                        setCheckList(newCheckList);
                                    } else {
                                        const newCheckList = checkList.map(
                                            (c) => ({ ...c, checked: true }),
                                        );
                                        setCheckList(newCheckList);
                                    }
                                    setFilter({ ...filter, status: 0 });
                                }}
                            />
                            {checkList.map((check, idx) => (
                                <Checkbox
                                    key={check.name}
                                    isChecked={check.checked}
                                    label={check.name}
                                    handleChange={() => {
                                        const newCheck = [...checkList];
                                        newCheck[idx].checked =
                                            !newCheck[idx].checked;
                                        setCheckList(newCheck);
                                        setFilter({
                                            ...filter,
                                            status: newCheck
                                                .filter((c) => c.checked)
                                                .map((c) => c.value)
                                                .join(","),
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">검색</span>
                        <Input
                            name="searchInput"
                            value={filter.q || ""}
                            size="medium"
                            handleChange={handleKeywordChange}
                            placeholder="쿠폰명 또는 쿠폰 고유번호를 입력해주세요."
                            onKeyDown={(
                                evt: KeyboardEvent<HTMLInputElement>,
                            ) => {
                                evt.key === "Enter" && onClickSearch();
                            }}
                        />
                    </li>
                </ul>
            </div>
            <div css={submitBtnRoot}>
                <Button
                    color="gray"
                    handleClick={() => {
                        clearFilter();
                        const newCheckList = checkList.map((c) => ({
                            ...c,
                            checked: false,
                        }));
                        setCheckList(newCheckList);
                    }}
                    label="전체 해제"
                    size="medium"
                />
                <Button
                    color="purple"
                    handleClick={onClickSearch}
                    label="검색"
                    size="medium"
                />
            </div>
        </>
    );
};

const FilterRoot = css`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .checkBoxRoot > div {
        margin-right: 16px;
    }
`;

const submitBtnRoot = css`
    & > Button:nth-of-type(1) {
        margin-right: 30px;
    }
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
`;

export default CouponFilter;
