import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";

export const LIVING = { key: 101, value: "주거용" };
export const LAND = { key: 102, value: "토지" };
export const COMMERCE = { key: 103, value: "상업" };
export const INDUSTRY = { key: 104, value: "산업용" };
export const UNCLASSIFIED = { key: 105, value: "기타" };

const BuildingUseLargeCategory: { [key: string]: any } = {
    주거용: { name: "주거용", code: "101" },
    토지: { name: "토지", code: "102" },
    상업: { name: "상업/업무용", code: "103" },
    산업용: { name: "산업용", code: "104" },
    기타: { name: "기타", code: "105" },
};

const BuildingUseMediumCategory: { [key: number]: any } = {
    101: {
        아파트: { name: "아파트", code: "301" },
        다세대: { name: "다세대(빌라등)", code: "302" },
        다가구: { name: "다가구(단독등)", code: "303" },
        기타: { name: "기타", code: "304" },
    },
    102: {
        대지: { name: "대지", code: "305" },
        농지: { name: "농지", code: "306" },
        임야: { name: "임야", code: "307" },
        도로: { name: "도로", code: "308" },
        잡종지: { name: "잡종지", code: "309" },
        과수원: { name: "과수원", code: "310" },
        공장용지: { name: "공장용지", code: "311" },
        창고용지: { name: "창고용지", code: "312" },
        목장용지: { name: "목장용지", code: "313" },
        주차장: { name: "주차장", code: "314" },
        기타: { name: "기타", code: "315" },
    },
    103: {
        오피스텔: { name: "오피스텔", code: "316" },
        근린상가: { name: "근린상가", code: "317" },
        사무실: { name: "사무실", code: "318" },
        숙박시설: { name: "숙박시설", code: "319" },
        기타: { name: "기타", code: "320" },
    },
    104: {
        창고: { name: "창고", code: "321" },
        공장: { name: "공장", code: "322" },
        기타: { name: "기타", code: "323" },
    },
    105: {
        광업권: { name: "광업권", code: "324" },
        어업권: { name: "어업권", code: "325" },
        양어장: { name: "양어장", code: "326" },
        // 기타: { name: "기타", code: "327" },
    },
};

const fetchData = async () => {
    const response = await fetch("");

    if (!response.ok) {
        throw new Error("Problem fetching data");
    }

    return [];
};

const useUsageBigData = () => {
    return useQuery<any, Error>(queryKeys.USAGE_BIG, fetchData, {
        initialData: BuildingUseLargeCategory,
    });
};

const useUsageSmallData = () => {
    return useQuery<any, Error>(queryKeys.USAGE_SMALL, fetchData, {
        initialData: BuildingUseMediumCategory,
    });
};

export { useUsageBigData, useUsageSmallData };
