import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { css } from "@emotion/react";
import { EditModal } from "@src/components";
import { useFindAllSubsJoinedUser } from "@src/hooks/queries/useHomeChart";
import DateRangeCalendar, {
    DateRangeCalendarType,
} from "@src/components/calendar/DateRangeCalendar";
import { dateFormat, dateSimpleFormat } from "@src/utils/dateUtil";
import { Common } from "@src/styles/Common";
import { useNavigate } from "react-router-dom";

interface Props {
    open: boolean;
    onCloseView: () => void;
    date: DateRangeCalendarType;
    setDate: Dispatch<SetStateAction<DateRangeCalendarType>>;
}

const mainInfoTitle = [
    "유료가입 일자",
    "해당 기간동안 신규 상품(유료)가입자 수",
    "유료구독 총 금액",
];
const columns = [
    { value: 1, name: "회원번호" },
    { value: 1, name: "이름" },
    { value: 1, name: "상품명" },
    { value: 1, name: "구독이름" },
    { value: 1, name: "판매형태" },
    { value: 1, name: "상품가격" },
    { value: 1, name: "갱신일자" },
    { value: 1, name: "파트너여부" },
];

const NewMemberModal: React.FC<Props> = ({
    open,
    onCloseView,
    date,
    setDate,
}) => {
    const navigate = useNavigate();
    const limit = 10;
    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [subsCount, setSubsCount] = useState<number>(0);
    const [subsAmount, setSubsAmount] = useState<number>(0);

    const { data, refetch, isLoading } = useFindAllSubsJoinedUser({
        page,
        limit,
        startDate: dateFormat(date?.startDate),
        endDate: dateFormat(date?.endDate),
    });

    const handlePageClick = (evt: any) => {
        setPage(evt.selected + 1);
    };

    useEffect(() => {
        if (open) {
            setTotalCount(data?.totalCount ?? 0);
            setSubsCount(data?.subsCount ?? 0);
            setSubsAmount(data?.subsAmount ?? 0);
            refetch();
        }
        return () => {
            setPage(1);
            setTotalCount(0);
            setSubsCount(0);
            setSubsAmount(0);
        };
    }, [open]);

    useEffect(() => {
        if (data && page === 1) {
            setTotalCount(data?.totalCount ?? 0);
            setSubsCount(data?.subsCount ?? 0);
            setSubsAmount(data?.subsAmount ?? 0);
        }
    }, [data]);

    useEffect(() => {
        if (date.endDate && open) {
            page !== 1 ? setPage(1) : refetch();
        }
    }, [date.endDate]);

    useEffect(() => {
        date.endDate && open && refetch();
    }, [page]);

    return (
        <div css={rootBoxStyle}>
            <EditModal
                handleBtnClick={() => {}}
                handleOpenModal={onCloseView}
                size="big"
                title="신규 유료상품 구매 회원 관리"
                isModalShow={open}
                checkCloseModal={true}
                deleteButton={true}
                dimClose={true}
            >
                <>
                    <div css={mainInfoBox}>
                        {mainInfoTitle.map((title, idx) => (
                            <div key={title} css={mainInfoTitleBox}>
                                <p className="titleText">{title}</p>
                                {idx === 0 && (
                                    <>
                                        <DateRangeCalendar
                                            getUserDate={(
                                                range: DateRangeCalendarType,
                                            ) => {
                                                setDate(range);
                                            }}
                                            setDate={date}
                                        />
                                    </>
                                )}
                                {idx === 1 && (
                                    <p className="titleValue">
                                        {subsCount.toLocaleString()}
                                    </p>
                                )}
                                {idx === 2 && (
                                    <p className="titleValue">
                                        {subsAmount?.toLocaleString()}원
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column.name}>{column.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={10} className="dataCheck">
                                            loading...
                                        </td>
                                    </tr>
                                ) : data?.list?.length ? (
                                    data?.list?.map((user, idx) => (
                                        <tr
                                            key={`${
                                                user.userNo
                                            }${idx.toString()}`}
                                        >
                                            <td>{user.userNo}</td>
                                            <td
                                                onClick={() => {
                                                    navigate(
                                                        `/users/detail/${user.userNo}`,
                                                    );
                                                }}
                                                className="underlineText"
                                            >
                                                {user.userName}
                                            </td>
                                            <td>{user.productName}</td>
                                            <td>{user.subsGroupName}</td>
                                            <td>{user.saleType}</td>
                                            <td>
                                                {user.productPrice?.toLocaleString()}
                                                원
                                            </td>
                                            <td>
                                                {user.lastPaidAt
                                                    ? dateSimpleFormat(
                                                          new Date(
                                                              user.lastPaidAt,
                                                          ),
                                                      )
                                                    : "-"}
                                            </td>
                                            <td>{user.partner}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={10} className="dataCheck">
                                            데이터가 없습니다.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!!totalCount && (
                        <div css={paginationBoxStyle}>
                            <ReactPaginate
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={handlePageClick}
                                pageCount={Math.ceil((totalCount ?? 0) / limit)}
                                breakLabel="..."
                                activeClassName="pagination-current"
                                containerClassName="pagination-wrapper"
                                pageClassName="pagination-item"
                                previousClassName="pagination-arrow"
                                nextClassName="pagination-arrow"
                                disableInitialCallback={true}
                                forcePage={page - 1}
                            />
                        </div>
                    )}
                </>
            </EditModal>
        </div>
    );
};

export default NewMemberModal;

const rootBoxStyle = css`
    & > div {
        & > .modal {
            width: 1300px;
            max-width: 80vw;
            height: 50vh;
            min-height: 800px;
        }
    }
    .pagination-current > a {
        background-color: ${Common.colors.blue};
        color: #fff;
        border: 1px solid #9d9fa5;
    }
    .pagination-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }
    .pagination-item > a {
        min-width: 25px;
        width: fit-content;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
        border-radius: 4px;
    }
    .pagination-arrow > a {
        width: 20px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #9d9fa5;
        font-size: 12px;
        cursor: pointer;
    }
`;

const mainInfoBox = css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
`;
const mainInfoTitleBox = css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 97px;
    padding: 20px 10px;
    border: 1px solid #bdbdbd;
    border-radius: 10px;
    gap: 8px;
    .titleText {
        font-size: 15px;
        font-weight: 700;
        color: #616161;
    }
    .titleValue {
        font-size: 18px;
        font-weight: 700;
        color: #212121;
    }
`;

const tableRootStyle = css`
    margin-top: 18px;
    width: 100%;
    height: 470px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 2px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
    table {
        width: 100%;
        thead {
            tr {
                position: sticky;
                top: 0;
                background-color: #ffffff;
            }
            th {
                padding: 10px;
            }
        }
        tr {
            border-bottom: 1px solid #b5b5b5;
        }
        tbody {
            vertical-align: middle;
            border-bottom: 1px solid #b5b5b5;
            td {
                padding: 13px;
                text-align: center;
                text-overflow: ellipsis;
                cursor: pointer;
            }
        }
        .dataCheck {
            padding: 100px;
        }
    }
    .underlineText {
        text-decoration-line: underline;
        text-underline-position: under;
    }
`;

const paginationBoxStyle = css`
    padding: 10px;
`;
