import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
    Title,
    PageTable,
    Button,
    ConfirmModal,
    EditModal,
} from "@src/components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { usePushList } from "@src/hooks/queries/usePush";
import { PushListType, PushType } from "@src/pages/notify/types";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useDeletePushData } from "@src/hooks/queries/usePushDetail";
import { getColumns } from "./PushListColumns";
import PushNavigator from "@src/pages/notify/containers/PushNavigator";
import PushFilter from "./containers/PushFilter";
import { dateFormat, dateParse } from "@src/utils/dateUtil";
import { PushListDto } from "@src/interfaces/Push.interface";
import { Constants } from "@src/constants/Constants";
import { useGetPushReceivers } from "@src/hooks/queries/usePushForm";
import { getReceiveColumns } from "./PushReceiveColumns";
import { useSelector } from "react-redux";
import { createPush, updatePush } from "@src/reducers/push";

export interface NotificationFilterType {
    startDate?: string | null;
    endDate?: string | null;
    page?: number;
    limit?: number;
    saleTypes?: string | null;
    q?: string;
}
interface IPushForm {
    title: string;
    isUpdate?: boolean;
    initData: PushType;
    handleUpdate: Function;
    isDetail?: boolean;
    isDisabledCheckbox?: boolean;
}
function PushList() {
    const initParams = {
        statuses: [],
        toTypes: [],
        categories: [],
        orderColumn: "common.reservationAt",
        orderType: "DESC",
        limit: 10,
        page: 1,
        searchText: "",
    };

    const pushDefaultData = useSelector((state: any) => state.push);
    const [filter, setFilter] = useState<PushListDto>(initParams);
    const [isDelete, setIsDelete] = useState<{
        isModalShow: boolean;
        deleteNo: number;
    }>({
        isModalShow: false,
        deleteNo: -1,
    });
    const [isList, setIsList] = useState<{
        isModalShow: boolean;
        No: number;
    }>({
        isModalShow: false,
        No: 0,
    });
    const navigate = useNavigate();
    const [data, setData] = useState<PushListType[]>([]);
    const [params, setParams] = useState({ page: 1, limit: 10 });
    const {
        isLoading,
        data: pushData = { result: [], total: 0 },
        isError,
        refetch: listRefetch,
    } = usePushList(filter);

    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isLoading && !isError && pushData) {
            setData(pushData.result);
            if (params.page === 1) {
                setTotalCount(pushData.total);
            }
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());
        }
    }, [pushData.result]);

    const { mutate: deleteMutate } = useDeletePushData();
    const onDelete = (deleteNo: string) => {
        deleteMutate(deleteNo, {
            onSuccess: () => {
                listRefetch();
            },
            onError: (error: any) => {
                console.log(error);
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });

        setIsDelete({ ...isDelete, ...{ isModalShow: false } });
    };
    const [deleteNo, setDeleteNo] = useState("");
    const [listNo, setListNo] = useState("");
    const columns = getColumns({
        navigate,
        setDeleteNo,
        setIsDelete,
        setIsList,
        setListNo,
    });

    const [receiveData, setReceiveData] = useState<any>([]);
    const [receiverParams, setReceiverParams] = useState<any>({
        page: 1,
        limit: 10,
    });
    const [receiverCount, setReceiverCount] = useState(0);

    const {
        isLoading: receiverIsLoading,
        data: receiversList = { result: [], total: 0 },
        refetch: receiverRefetch,
        isError: receiverIsError,
    } = useGetPushReceivers(receiverParams, listNo ? listNo : "0");

    useEffect(() => {
        if (!receiverIsLoading && !receiverIsError && receiversList) {
            setReceiveData(receiversList.result);
            if (receiverParams.page === 1) {
                setReceiverCount(receiversList.total);
            }
            dispatch(endLoading());
        } else if (receiverIsError) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());
        }
    }, [receiversList.result]);

    const handleSubmit = () => {
        setIsList({ ...isList, isModalShow: !isList.isModalShow });
    };

    const receiveColumns = getReceiveColumns();

    return (
        <div css={listStyle}>
            <PushNavigator />

            <div css={searchDiv}>
                <Title title="PUSH 관리" />
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/notify/push/create");
                    }}
                    label="PUSH 전송 등록"
                    size="medium"
                />
            </div>
            <PushFilter
                refetch={listRefetch}
                filter={filter}
                setFilter={setFilter}
                initParams={initParams}
            />
            <div css={tableDiv}>
                <PageTable
                    columns={columns}
                    data={data}
                    FixedHeight={600}
                    showCheckbox={false}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParams({ page, limit: 10 });
                        setFilter({
                            ...filter,
                            page: page,
                            limit: params.limit,
                        });
                    }}
                    showTotalCount={true}
                    forcePage={params.page === 1}
                />
            </div>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() =>
                    setIsDelete({ ...isDelete, ...{ isModalShow: false } })
                }
                handleOk={() => onDelete(deleteNo)}
                isModalShow={isDelete.isModalShow}
            >
                <>정말 삭제하시겠습니까?</>
            </ConfirmModal>
            <EditModal
                buttonText="확인"
                handleBtnClick={handleSubmit}
                handleOpenModal={() =>
                    setIsList({ ...isList, ...{ isModalShow: false } })
                }
                isModalShow={isList.isModalShow}
                size="big"
                title={"수신인 리스트"}
                isDisabled={false}
                errorMessage={""}
            >
                <div css={tableDiv}>
                    <PageTable
                        columns={receiveColumns}
                        data={receiveData}
                        FixedHeight={600}
                        showCheckbox={false}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        totalCount={receiverCount}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setReceiverParams({ page, limit: 10 });
                        }}
                        showTotalCount={true}
                        forcePage={receiverParams.page === 1}
                    />
                </div>
            </EditModal>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const searchDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        padding: 8px 0;
        button:nth-of-type(2) {
            margin-left: 15px;
        }
    }
`;

export default PushList;
