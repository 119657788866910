import React, { Dispatch, SetStateAction } from "react";
import { IconButton } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTimeNoSecond } from "@src/utils/dateUtil";

export const DeungiNoticeListColumns = (
    navigate: any,
    setDeletedId: Dispatch<SetStateAction<number>>,
    setOpenDialog: Dispatch<SetStateAction<boolean>>,
) => [
    {
        Header: "등록 번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "점검 대상",
        accessor: "category",
        Cell: ({ cell: { value } }: any) => {
            const category = {
                deungi: "등기소",
                buildingRegister: "세움터",
            } as any;
            return (
                <div style={{ textAlign: "center" }}>
                    {value ? category[value] : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "점검 기간",
        accessor: "name",
        Cell: ({ row }: any) => {
            const newStart = ConvertDateTimeNoSecond(row.original.openAt);
            const newEnd = ConvertDateTimeNoSecond(row.original.closeAt);
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {newStart} ~ {newEnd}
                </div>
            );
        },
    },
    {
        Header: "설명",
        accessor: "title",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "노출 여부",
        accessor: "isOpen",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value ? "O" : "X"}
            </div>
        ),
    },
    {
        Header: "관리",
        accessor: "viewControl",
        Cell: ({ row }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <IconButton
                    type="trash"
                    handleClick={() => {
                        setDeletedId(row.original.no);
                        setOpenDialog(true);
                    }}
                />
                <IconButton
                    type="edit"
                    handleClick={() => {
                        navigate(`/deungi/notice/update/${row.original.no}`, {
                            state: {
                                ...row.original,
                            },
                        });
                    }}
                />
            </div>
        ),
    },
];
