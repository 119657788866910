import React, { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ToastContainer, toast } from "react-toastify";
import { css } from "@emotion/react";
import { Button, ConfirmModal, EditModal, Label } from "@src/components";
import {
    useFindAllExistingDeunggiList,
    useRemoverExistingDeunggi,
    useSaverExistingDeunggiExpired,
    useSaverRefundExistingDeunggi,
} from "@src/hooks/queries/useUserDetail";
import "react-toastify/dist/ReactToastify.css";
import UserExistingDeunggiRefundModal from "./UserExistingDeunggiRefundModal";
import { IExistingDeunggi } from "./types";
import UserExistingDeunggiUpdateModal from "./UserExistingDeunggiUpdateModal";

interface Props {
    open: boolean;
    onCloseView: () => void;
    reOpenView: () => void;
    userNo: number;
    existingDeunggiCount: any;
    refetchExistingDeunggiCount: any;
}

const columns = [
    "열람권 번호",
    "충전일시",
    "충전정보",
    "충전 수량",
    "사용 수량",
    "결제액",
    "결제번호",
    "결제수단",
    "환불 수량",
    "환불액",
    "취소 수량",
    "유효기간",
    "상태",
    "환불",
    "취소",
    "수정",
];

const UserExistingDeunggiListModal: React.FC<Props> = ({
    open,
    onCloseView,
    reOpenView,
    userNo,
    existingDeunggiCount,
    refetchExistingDeunggiCount,
}) => {
    const cancelSelectedTicketNo = useRef<number | null>(null);
    const [selectedDeunggi, setSelectedDeunggi] =
        useState<IExistingDeunggi | null>();
    const [openModal, setOpenModal] = useState<number>(0); // 1 환불 2 취소 3 수정
    const [page, setPage] = useState<number>(0);
    const { data, refetch, fetchNextPage, hasNextPage, isFetching } =
        useFindAllExistingDeunggiList({
            userNo,
            limit: 20,
            page: 1,
            type: 0,
            totalCount: existingDeunggiCount?.curHaveCnt ?? 0,
        });

    const [loadingRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: !!hasNextPage,
        onLoadMore: fetchNextPage,
        disabled: false,
        delayInMs: 100,
    });

    const cancelDeunggi = useRemoverExistingDeunggi(
        () => {
            refetchExistingDeunggiCount();
            cancelSelectedTicketNo.current = null;
            setOpenModal(0);
            refetch({
                refetchPage: (_p: any, index: number) => index === page,
            });
            toast.success("취소 처리되었습니다.");
        },
        (err) => {
            cancelSelectedTicketNo.current = null;
            setOpenModal(0);
            toast.error("취소 실패하였습니다.");
        },
    );

    const refundDeunggi = useSaverRefundExistingDeunggi(
        () => {
            refetchExistingDeunggiCount();
            setSelectedDeunggi(null);
            setOpenModal(0);
            reOpenView();
            toast.success("환불 처리되었습니다.");
            refetch({
                refetchPage: (_p: any, index: number) => index === page,
            });
        },
        (err) => {
            setSelectedDeunggi(null);
            setOpenModal(0);
            toast.error("환불 실패하였습니다.");
        },
    );

    const deunggiEdit = useSaverExistingDeunggiExpired(
        () => {
            refetchExistingDeunggiCount();
            setSelectedDeunggi(null);
            setOpenModal(0);
            reOpenView();
            toast.success("수정되었습니다.");
            refetch({
                refetchPage: (_p: any, index: number) => index === page,
            });
        },
        (err) => {
            setSelectedDeunggi(null);
            setOpenModal(0);
            toast.error("수정 실패하였습니다.");
        },
    );

    const onSubmitRefundDeunggi = (refundPrice: number, reason: string) => {
        const params = {
            ticketNo: selectedDeunggi?.no,
            refundPrice,
            reason,
        };
        refundDeunggi.mutate(params);
    };

    const onSubmitDeunggiEdit = (expiredAt: string) => {
        const params = {
            ticketNo: selectedDeunggi?.no,
            expiredAt,
        };
        deunggiEdit.mutate(params);
    };

    useEffect(() => {
        open && refetch();
        return () => {
            setPage(0);
        };
    }, [open]);

    return (
        <>
            <div css={rootStyle}>
                <EditModal
                    isModalShow={open}
                    buttonText=""
                    handleBtnClick={() => {}}
                    handleOpenModal={onCloseView}
                    size="big"
                    title="기존 등기열람권 정보"
                    deleteButton={true}
                >
                    <>
                        <div css={titleRootBoxStyle}>
                            <div css={ticketCountBoxStyle}>
                                총 잔여
                                <span>
                                    {existingDeunggiCount?.curHaveCnt ?? 0}장
                                </span>
                            </div>
                            <div css={titleTextStyle}>충전내역</div>
                        </div>
                        <div css={tableRootStyle}>
                            <table>
                                <thead>
                                    <tr>
                                        {columns.map((val) => (
                                            <th key={val}>{val}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.pages?.map((page, idx) =>
                                        page?.list?.map((data) => {
                                            const refundDisabledCheck =
                                                data.totalPrice ===
                                                    data.paidPrice &&
                                                data.no > 0 &&
                                                !/(App Store|Google Paly)/.test(
                                                    data.paidCardName,
                                                ) &&
                                                data.status;
                                            return (
                                                <tr key={data.no}>
                                                    <td>{data.no}</td>
                                                    <td>{data.startedAt}</td>
                                                    <td>{data.productName}</td>
                                                    <td>
                                                        {data.serveQuantity}
                                                    </td>
                                                    <td>{data.usedQuantity}</td>
                                                    <td>{data.paidPrice}</td>
                                                    <td>{data.paidNo}</td>
                                                    <td>{data.paidCardName}</td>
                                                    <td>
                                                        {data.refundQuantity}
                                                    </td>
                                                    <td>{data.refundPrice}</td>
                                                    <td>
                                                        {data.status === 109
                                                            ? data.refundQuantity
                                                            : 0}
                                                    </td>
                                                    <td>{data.expiredAt}</td>
                                                    <td>
                                                        {data.state ===
                                                            "정상" && (
                                                            <Label
                                                                text={
                                                                    data.state
                                                                }
                                                                color={
                                                                    "purple1"
                                                                }
                                                            />
                                                        )}
                                                        {data.state ===
                                                            "부분환불" && (
                                                            <Label
                                                                text={
                                                                    data.state
                                                                }
                                                                color={"refund"}
                                                            />
                                                        )}
                                                        {data.state ===
                                                            "전체환불" && (
                                                            <Label
                                                                text={
                                                                    data.state
                                                                }
                                                                color={
                                                                    "allRefund"
                                                                }
                                                            />
                                                        )}
                                                        {data.state ===
                                                            "기간만료" && (
                                                            <Label
                                                                text={
                                                                    data.state
                                                                }
                                                                color={
                                                                    "expired"
                                                                }
                                                            />
                                                        )}
                                                        {data.state ===
                                                            "관리자취소" && (
                                                            <Label
                                                                text={"취소"}
                                                                color={
                                                                    "request"
                                                                }
                                                            />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            label="환불하기"
                                                            color="purple"
                                                            size="small"
                                                            handleClick={() => {
                                                                setPage(idx);
                                                                setSelectedDeunggi(
                                                                    data,
                                                                );
                                                                onCloseView();
                                                                setOpenModal(1);
                                                            }}
                                                            styles={{
                                                                width: "100%",
                                                                height: "30px",
                                                                fontSize:
                                                                    "13px",
                                                                fontWeight: 400,
                                                                margin: 0,
                                                            }}
                                                            isDisabled={
                                                                !refundDisabledCheck
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            label="취소하기"
                                                            color="purple"
                                                            size="small"
                                                            handleClick={() => {
                                                                setPage(idx);
                                                                if (
                                                                    data.status ===
                                                                    200
                                                                ) {
                                                                    cancelSelectedTicketNo.current =
                                                                        data.no;
                                                                    setOpenModal(
                                                                        2,
                                                                    );
                                                                }
                                                            }}
                                                            styles={{
                                                                width: "100%",
                                                                height: "30px",
                                                                fontSize:
                                                                    "13px",
                                                                fontWeight: 400,
                                                                margin: 0,
                                                            }}
                                                            isDisabled={
                                                                data.status !==
                                                                200
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            label="수정"
                                                            color="whiteOp"
                                                            size="small"
                                                            handleClick={() => {
                                                                setPage(idx);
                                                                setSelectedDeunggi(
                                                                    data,
                                                                );
                                                                onCloseView();
                                                                setOpenModal(3);
                                                            }}
                                                            styles={{
                                                                width: "100%",
                                                                height: "30px",
                                                                fontWeight: 400,
                                                                margin: 0,
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }),
                                    )}
                                </tbody>
                            </table>
                            <div ref={loadingRef} css={scrollStyle} />
                        </div>
                    </>
                </EditModal>
            </div>
            <UserExistingDeunggiRefundModal
                open={openModal === 1}
                onCloseView={() => {
                    setOpenModal(0);
                    reOpenView();
                    setSelectedDeunggi(null);
                    setPage(0);
                }}
                selectedDeunggi={selectedDeunggi}
                onSubmitRefundDeunggi={onSubmitRefundDeunggi}
            />
            <ConfirmModal
                isModalShow={openModal === 2}
                handleCancel={() => {
                    cancelSelectedTicketNo.current = null;
                    setOpenModal(0);
                    setPage(0);
                }}
                handleOk={() => {
                    cancelSelectedTicketNo.current &&
                        cancelDeunggi.mutate(cancelSelectedTicketNo.current);
                }}
                children={<div>정말 해당 등기열람권을 취소하시겠습니까?</div>}
            />
            <UserExistingDeunggiUpdateModal
                open={openModal === 3}
                onCloseView={() => {
                    setOpenModal(0);
                    reOpenView();
                    setSelectedDeunggi(null);
                    setPage(0);
                }}
                selectedDeunggi={selectedDeunggi}
                onSubmitDeunggiEdit={onSubmitDeunggiEdit}
            />
            <ToastContainer />
        </>
    );
};

export default UserExistingDeunggiListModal;

const rootStyle = css`
    & > div {
        & > .modal {
            width: 1300px;
            max-width: 90vw;
            & > p {
                margin-bottom: 20px;
            }
        }
    }
`;

const titleRootBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 18px;
`;

const ticketCountBoxStyle = css`
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    span {
        margin-left: 8px;
        font-size: 14px;
        font-weight: 400;
        color: #9a49f2;
    }
`;

const titleTextStyle = css`
    font-weight: 700;
    font-size: 14px;
    color: #222222;
    margin-bottom: 9px;
`;

const tableRootStyle = css`
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 2px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
    table {
        width: 100%;
        vertical-align: middle;
        thead {
            tr {
                position: sticky;
                top: -1px;
                background-color: #ffffff;
            }
            th {
                padding: 15px 5px;
                white-space: nowrap;
                font-size: 14px;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        tr {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            font-size: 13px;
            vertical-align: middle;
            padding: 14px 5px;
            text-align: center;
            /* white-space: nowrap;
            word-break: keep-all; */
            text-overflow: ellipsis;
        }
    }
`;

const scrollStyle = css`
    padding: 70px;
    margin-top: -120px;
`;
