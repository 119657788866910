import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { Title, Button, PageTable } from "@src/components";
import {
    searchStatusList,
    ansStatusList,
    CATEGORY_LIST,
} from "@src/constants/SupportStatus";
import { useDispatch } from "react-redux";
import SupportFilter from "./SupportFilter";
import { SupportFilterType } from "@src/types/supportFilterType";
import {
    initFilters,
    useCacheSupportData,
    useSupportData,
} from "@src/hooks/queries/useSupport";
import { endLoading, startLoading } from "@src/reducers/loading";
import { SupportListType } from "./supportTypes";
import { getColumns } from "./SupportListColumns";
import { makeQueryString } from "@src/utils/queryStringUtil";

function SupportList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [options, setOptions] = useState<SupportFilterType>(initFilters);
    const [params, setParams] = useState<SupportFilterType>(initFilters);
    const [totalCount, setTotalCount] = useState(0);
    const {
        isLoading,
        data = { result: [], total: 0 },
        isError,
        refetch,
    } = useSupportData(params);

    const [list, setList] = useState<SupportListType[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = useMemo(
        () => Object.fromEntries(searchParams),
        [location.search],
    );
    const cacheData: { result: any[]; total: number } =
        useCacheSupportData(searchQuery);

    useEffect(() => {
        if (cacheData) {
            setList(cacheData.result);
            params.page === 1 && setTotalCount(cacheData.total);
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setList(data.result);
                if (params.page === 1) {
                    setTotalCount(data.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [data.result, cacheData]);

    const columns = getColumns({ navigate });
    const filterChange = (data: any) => {
        if (data.qstType) {
            data.qstType = data.qstType.map((key: string) => {
                const category = key as keyof {
                    PAYMENT_QST: string;
                    SERVICE_QST: string;
                    SUBSCRIBE_QST: string;
                    EXPERT_QST: string;
                    EXPERT_SERVICE_QST: string;
                };
                return CATEGORY_LIST[category];
            });
        }
        setOptions(data);
        setClear(false);
    };
    const handleSearchClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setParams(options);
        setClear(false);
        // const queryString = makeQueryString(options);
        // setSearchParams(queryString);
    };
    const [clear, setClear] = useState(false);
    const handleClearClick = () => {
        setClear(true);
        const queryString = makeQueryString(initFilters);
        setSearchParams(queryString);
    };

    useEffect(() => {
        if (params) {
            refetch();
        }
    }, [params]);

    return (
        <div css={listStyle}>
            <Title title="1:1 문의" />
            <SupportFilter
                filterChange={filterChange}
                searchStatusList={searchStatusList}
                ansStatusList={ansStatusList}
                clear={clear}
                searchQuery={searchQuery}
            />
            <div className="filterBtnBox" css={filterBtnBox}>
                <Button
                    color="gray"
                    handleClick={handleClearClick}
                    label="전체 해제"
                    size="medium"
                />
                <Button
                    color="purple"
                    handleClick={handleSearchClick}
                    label="검색"
                    size="medium"
                    isDisabled={params.qstError || params.ansError}
                />
            </div>
            <div css={tableDiv}>
                <PageTable
                    initialState={{ pageIndex: 0, pageSize: 10 }}
                    columns={columns}
                    data={list}
                    FixedHeight={700}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParams((prevState) => {
                            let newParams = { ...prevState, page };
                            const queryString = makeQueryString(newParams);
                            setSearchParams(queryString);
                            return newParams;
                        });
                    }}
                    showTotalCount={true}
                    forcePage={params.page === 1}
                ></PageTable>
            </div>
        </div>
    );
}
const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const tableDiv = css`
    margin-top: 43px;
    width: 100%;
    height: 80vh;
    table {
        th:first-of-type {
            width: 6%;
        }
        th:nth-of-type(2) {
            width: 6%;
        }
        th:nth-of-type(3) {
            width: 12%;
        }
        th:nth-of-type(4) {
            width: 13%;
        }
        th:nth-of-type(5) {
            width: 25%;
        }
        th:nth-of-type(6) {
            width: 8%;
        }
        th:nth-of-type(7) {
            width: 6%;
        }
        th:nth-of-type(8) {
            width: 4%;
        }
        th:nth-of-type(9) {
            width: 4%;
        }
        th:nth-of-type(10) {
            width: 4%;
        }
    }
    td {
        padding: 10px 0;
    }
    .supportContent {
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
        word-break: break-all;
    }
`;
const filterBtnBox = css`
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
`;

export default SupportList;
