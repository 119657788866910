import styled from "@emotion/styled";
import { Button, Title } from "@src/components";
import { DetailUserProps } from "@src/interfaces/User.interface";

import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { pages } from "@src/constants/Menu";
import { subscriptionInfoType } from "../UserDetail";

interface SubscriptionInfoProps {
    user?: DetailUserProps;
    setIsSubscriptionInfoModalShow: Dispatch<SetStateAction<boolean>>;
    subscriptionInfo?: subscriptionInfoType;
}

const SubscriptionInfo = ({
    user,
    setIsSubscriptionInfoModalShow,
    subscriptionInfo,
}: SubscriptionInfoProps) => {
    const navigate = useNavigate();
    return (
        <>
            <Title title="구독정보" size="small" />

            <StyledContents>
                <StyledRow>
                    <span>정기구독</span>
                    <StyledSubText>
                        {user?.productName
                            ? `${subscriptionInfo?.subs_group_name} ${user?.productName}`
                            : "미구독"}
                        {/* {user?.productName ? user?.productName : "미구독"} */}
                    </StyledSubText>
                    {user?.productName ? (
                        <Button
                            color="gd"
                            handleClick={() =>
                                setIsSubscriptionInfoModalShow(true)
                            }
                            label="구독 정보"
                            size="small"
                            styles={{
                                width: 123,
                                height: 23,
                            }}
                        />
                    ) : (
                        <Button
                            color="gd"
                            handleClick={() => {
                                navigate("/commodity/provision");
                            }}
                            label="구독 지급"
                            size="small"
                            styles={{
                                width: 123,
                                height: 23,
                            }}
                        />
                    )}
                </StyledRow>
                <StyledRow>
                    <span>고스톱</span>
                    <StyledSubText>미구독</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={() => {
                            navigate("/commodity/provision");
                        }}
                        label="구독 지급"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
            </StyledContents>
        </>
    );
};

export default SubscriptionInfo;

const StyledContents = styled.div`
    margin-top: 20px;
    gap: 12px;
    padding-bottom: 40px;
`;

const StyledRow = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    & > span {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        width: 52px;
    }
`;

const StyledSubText = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #9a49f2;
    margin: 0;
    min-width: 101px !important;
    width: fit-content !important;
`;
