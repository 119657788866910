import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isErrorPopUpShow: false,
    errorCode: 0,
    errorMessage: "",
};

export const errorPopUpSlice = createSlice({
    name: "errorPopUp",
    initialState,
    reducers: {
        showErrorPopUp: (state, err) => {
            state.isErrorPopUpShow = true;
            state.errorCode = err.payload.response.data.statusCode;
            state.errorMessage = err.payload.response.data.message;
        },
        hideErrorPopUp: (state) => {
            state.isErrorPopUpShow = false;
        },
    },
});

export const { showErrorPopUp, hideErrorPopUp } = errorPopUpSlice.actions;

export default errorPopUpSlice.reducer;
