import styled from "@emotion/styled";
import { Button, DropDown, PageTable, Title } from "@src/components";
import { useGetExpertInquiryList } from "@src/hooks/queries/useExpert";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getExpertInquiryColumns } from "./ExpertInquiryColumns";
import ExpertInquiryFilter from "./ExpertInquiryFilter";
import { userExpertInquiryListDto } from "./types";

const ExpertInquiryList = () => {
    const initFilters = {
        page: 1,
        limit: 10,
        searchType: "userExpertInfoInquiry.itemSubId",
        sort: "userExpertInfoInquiry.createdAt",
        order: "DESC",
    };

    const [filters, setFilters] =
        useState<userExpertInquiryListDto>(initFilters);
    const [totalCnt, setTotalCnt] = useState<number>(0);
    const initInquiryType = {
        message: false,
        call: false,
    };

    const [inquiryType, setInquiryType] = useState<any>(initInquiryType);
    const columns = getExpertInquiryColumns();
    const [params, setParams] = useState<userExpertInquiryListDto>(initFilters);
    const { data, refetch } = useGetExpertInquiryList(params);

    useEffect(() => {
        refetch();
    }, [params]);

    useEffect(() => {
        data?.total && setTotalCnt(data?.total);
    }, [data]);

    const trySubmit = () => {
        setParams(filters);
    };

    const tryClear = () => {
        setParams(initFilters);
        setFilters(initFilters);
        setInquiryType(initInquiryType);
    };

    const sortDropDownList: {
        id: number;
        key: string;
        value: string;
    }[] = [
        {
            id: 0,
            key: "userExpertInfoInquiry.createdAt",
            value: "문의일시 최신순",
        },
    ];

    return (
        <>
            <StyledExpertInquiryList>
                <Title title="문의 관리" />
                <ExpertInquiryFilter
                    filters={filters}
                    setFilters={setFilters}
                    inquiryType={inquiryType}
                    setInquiryType={setInquiryType}
                />
                <div className="filterBtnBox">
                    <Button
                        color="gray"
                        handleClick={() => {
                            tryClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            trySubmit();
                        }}
                        label="검색"
                        size="medium"
                        isDisabled={false}
                    />
                </div>
                <div className="tableDiv">
                    <div className="sortDiv">
                        <DropDown
                            handleChange={(e, text, value) => {
                                value &&
                                    setFilters({
                                        ...filters,
                                        sort: value,
                                    });
                            }}
                            data={sortDropDownList}
                            defaultValue={""}
                        />
                    </div>
                    <PageTable
                        columns={columns}
                        data={data?.result ?? []}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        FixedHeight={500}
                        showTotalCount={true}
                        totalCount={totalCnt}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParams({
                                ...params,
                                page,
                            });
                        }}
                    ></PageTable>
                </div>
            </StyledExpertInquiryList>
        </>
    );
};

export default ExpertInquiryList;

const StyledExpertInquiryList = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .filterBtnBox {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 10px;
    }
    .tableDiv {
        margin-top: 43px;
        width: 100%;
        height: 80vh;
        .sortDiv {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
