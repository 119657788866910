import { axiosInstance } from "@src/api/axiosConfig";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { queryList } from "./queryList";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";

const fetchData = async (no: string, params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/classes/${no}`,
        {
            params: {
                ...params,
            },
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    queryList.setDataList(responseData.list);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCnt);
    } else {
        queryList.setTotalCount(responseData.totalCnt);
    }

    return queryList.getData(() => {
        return responseData.list;
    });
};

const fetchSugangCount = async (no: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/count/${no}`,
    );

    return response.data;
};

const fetchSugangDetail = async (no: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/user/${no}`,
    );

    return response.data;
};

const useUserCampusList = (
    no: string,
    filters: any,
    enabled: boolean = false,
) => {
    return useQuery([queryKeys.CAMPUS, filters], () => fetchData(no, filters), {
        enabled,
    });
};

const useUserCampusCount = (no: string, enabled: boolean = true) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/count`, no],
        () => fetchSugangCount(no),
        {
            enabled,
        },
    );
};

const useUserCampusDetail = (no: string, enabled: boolean = false) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/user`, no],
        () => fetchSugangDetail(no),
        {
            enabled,
        },
    );
};

const useCampusExpireUpdate = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/user`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.post(`${url}/${no}`, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusDelete = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/user`;

    return useMutation(
        async (registerNo: number) => {
            try {
                const res = await axiosInstance.delete(`${url}/${registerNo}`);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export {
    useUserCampusList,
    useUserCampusCount,
    useUserCampusDetail,
    useCampusExpireUpdate,
    useCampusDelete,
};
