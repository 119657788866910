import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

export interface IUserRoadExpansionData {
    no?: number;
    isSubs: boolean;
    productName: string;
    isActive: boolean;
    expiredAt?: string;
    firstUsedAt?: string;
}

export interface IRoadExpansionItem {
    no: number;
    orderNo: number;
    subsNo: number;
    firstUsedAt: string;
    createdAt: string;
    expiredAt: string;
    refundedAt: string;
    productName: string;
    paidNo: number;
    paidPrice: number;
    cardName: string;
    refundPrice: number;
    stateToString: string;
    isUsed: boolean;
    canRefund: boolean;
    canDelete: boolean;
}

export interface IRoadExpansionItems {
    list: IRoadExpansionItem[];
    totalCount: number;
}
const fetchUserRoadExpansionData = async (userNo: number) => {
    const response = await axiosInstance.get(`${axiosPath.roadExpansion}/${userNo}`);
    return response.data;
};

export const useGetUserRoadExpansionData = (userNo: number, enabled: boolean = false) => {
    return useQuery<IUserRoadExpansionData>(
        ["getUserRoadExpansion", userNo],
        () => fetchUserRoadExpansionData(userNo),
        {
            enabled,
        },
    );
};

const fetchRoadExpansionItems = async (params: { limit?: number; page?: number; userNo: number }) => {
    const response = await axiosInstance.get(`${axiosPath.roadExpansion}/items/${params.userNo}`, {
        params: { page: params.page, limit: params.limit },
    });
    return response.data;
};

export const useGetRoadExpansionItems = (
    params: { limit?: number; page?: number; userNo: number },
    enabled: boolean = false,
) => {
    return useQuery<IRoadExpansionItems>(["getRoadExpansionItems", params], () => fetchRoadExpansionItems(params), {
        enabled,
    });
};

export const useEditRoadExpansionExpiredAt = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: { roadPlanNo: number; expiredAt: string }) => {
            try {
                const body = {
                    roadPlanNo: params.roadPlanNo,
                    expiredAt: params.expiredAt,
                };
                const response = await axiosInstance.post(`${axiosPath.roadExpansion}/expired`, body);
                console.log(response.data);
                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};
export const useDeleteRoadExpansion = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (roadPlanNo: number) => {
            try {
                const response = await axiosInstance.delete(`${axiosPath.roadExpansion}/${roadPlanNo}`);

                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};
