import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { TextBox, Button } from "@src/components";
import { useSelector } from "react-redux";
import { SeizeInfoType } from "@src/types";

interface ISeizeInformationContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
}

function SeizeInformationContainer({
    isUpdate = false,
    handleUpdate,
}: ISeizeInformationContainer) {
    const defaultData = useSelector((state: any) => state.seizeInfo);
    const [currentData, setCurrentData] = useState<SeizeInfoType>(defaultData);

    useEffect(() => {
        handleUpdate && handleUpdate(currentData);
    }, [currentData]);

    useEffect(() => {
        setCurrentData({ ...defaultData });
    }, [defaultData]);

    const onChangeField = (name: string, value: string) => {
        const obj = { [name]: value };
        setCurrentData({ ...currentData, ...obj });
    };

    const { important, caution } = currentData;
    return (
        <div css={SurroundInfoDiv}>
            {currentData && (
                <>
                    <ul>
                        <li className="subTitle">
                            입찰 전 알아야 할 주요 사항
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <TextBox
                                        defaultText={important || ""}
                                        label=""
                                        placeholder=""
                                        size="dynamic"
                                        isDisabled={true}
                                        handleChange={(value) =>
                                            onChangeField("important", value)
                                        }
                                    />
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li className="subTitle">- 유의 사항</li>
                        <li>
                            <ul>
                                <li>
                                    <TextBox
                                        defaultText={caution || ""}
                                        label=""
                                        placeholder="유의 사항을 입력해주세요."
                                        size="dynamic"
                                        isDisabled={!isUpdate}
                                        handleChange={(value) =>
                                            onChangeField("caution", value)
                                        }
                                    />
                                </li>
                            </ul>
                        </li>
                    </ul>
                </>
            )}
            <div css={BtnDiv}>
                {!isUpdate && (
                    <Button
                        color="gd"
                        handleClick={() => alert("재산 명세서 원본")}
                        label="재산 명세서 원본"
                        size="xSmall"
                    />
                )}
            </div>
        </div>
    );
}

SeizeInformationContainer.propTypes = {};
const SurroundInfoDiv = css`
    color: ${Common.colors.gray500};
    & > ul {
        display: flex;
        flex-direction: column;
        .subTitle {
            width: 100%;
            ${Common.textStyle.title5_M14}
            color: ${Common.colors.black};
            padding-top: 12px;
        }
        li:nth-of-type(even) {
            width: 100%;
        }
        &:last-of-type {
            .subTitle {
                color: ${Common.colors.gray500};
            }
            textarea {
                color: #222;
            }
        }
    }
    input {
        width: 400px;
    }
    input:disabled {
        background-color: transparent;
        border-bottom: none;
        color: #222;
    }
    input:disabled + p {
        display: none;
    }
    input[name="officeName"] {
        font-weight: bold;
    }
    textarea:disabled {
        padding-left: 0;
    }
`;

const BtnDiv = css`
    position: relative;
    left: -20px;
`;
export default SeizeInformationContainer;
