// import { useMutation, useQuery } from "react-query";
// import { queryKeys } from "./queryKeys";
// import { axiosPath } from "@src/api/axiosPath";
// import { axiosInstance } from "@src/api/axiosConfig";
// import { BaseUser, DetailUserProps } from "@src/interfaces/User.interface";
// import { ConvertPhoneNumber } from "@src/utils/units";
// import { asyncCall } from "@src/api/asyncCall";

// interface UserPaymenthistoryType {
//     user_no: number | null;
//     paid_no: number | null;
//     total_price: number | null;
//     paid_price: number | null;
//     refund_price: number | null;
//     card_name: string | null;
//     card_number_last4: string | null;
//     apply_num: string | null;
//     order_code: string | null;
//     subs_group_name: string | null;
//     product_name: string | null;
//     product_type: string | null;
//     created_at: string | null;
//     refund_at: string | null;
// }

// interface UserPaymenthistoryParseType {
//     userNo: number | null;
//     paidNo: number | null;
//     totalPrice: number | null;
//     paidPrice: number | null;
//     refundPrice: number | null;
//     cardName: string | null;
//     createdAt: string | null;
//     cardNumberLast4: string | null;
//     applyNum: string | null;
//     orderCode: string | null;
//     subsGroupName: string | null;
//     productName: string | null;
//     refundAt: string | null;
//     productType: string | null;
// }
// const fetchData = async (userNo: number) => {
//     console.log("a", userNo);
//     const response = await axiosInstance.get(
//         `${axiosPath.users}/${userNo}/payment/history`,
//     );

//     if (!response.status) {
//         throw new Error("Problem fetching data");
//     }
//     const responseData = response.data;
//     const originData: UserPaymenthistoryType = responseData.data.data;

//     const errorCode = responseData.data.errCode;
//     if (errorCode === 0) {
//         return parse(originData);
//     } else {
//         return null;
//     }
// };
// const parse = (originData: UserPaymenthistoryType) => {
//     const {
//         user_no,
//         paid_no,
//         total_price,
//         paid_price,
//         refund_price,
//         card_name,
//         card_number_last4,
//         apply_num,
//         order_code,
//         subs_group_name,
//         product_type,
//         refund_at,
//         created_at,
//         product_name,
//     } = originData;

//     return {
//         userNo: user_no,
//         paidNo: paid_no,
//         totalPrice: total_price,
//         paidPrice: paid_price,
//         refundPrice: refund_price,
//         cardName: card_name,
//         createdAt: created_at,
//         cardNumberLast4: card_number_last4,
//         applyNum: apply_num,
//         orderCode: order_code,
//         subsGroupName: subs_group_name,
//         productName: product_name,
//         refundAt: refund_at,
//         productType: product_type,
//     };
// };
// //회원 상세 조회
// const useGetUserPaymentHistory = (userNo: number) => {
//     console.log(userNo);
//     return useQuery<UserPaymenthistoryParseType | null>(
//         [queryKeys.USER_PAYMENT_HISTORY, userNo],
//         () => fetchData(userNo),
//         {
//             // initialData: initialUserDetailData,
//         },
//     );
// };

// export { useGetUserPaymentHistory };

import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { makeQueryString } from "@src/utils/queryStringUtil";
import {
    BaseRecentViewList,
    ReadRecentViewDto,
    PaymentViewList,
} from "@src/interfaces/User.interface";

const fetchData = async (params: ReadRecentViewDto) => {
    const queryString = makeQueryString(params);
    const response = await axiosInstance.get(
        `${axiosPath.users}/${params.user_no}/payment/history`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: PaymentViewList[] = responseData.data.data;
    return parse(originData)[0];
};

const parse = (originData: PaymentViewList[]) => {
    const newData = [];
    newData.push(
        originData.map((item: PaymentViewList) => {
            const {
                paid_no,
                product_name,
                created_at,
                paid_price,
                subs_group_name,
            } = item;
            return {
                paidNo: paid_no,
                productName: subs_group_name + " " + product_name,
                createdAt: created_at,
                paidPrice: paid_price,
                viewMore: paid_no,
                nextItem: "",
            };
        }),
    );
    return newData;
};
const useGetUserPaymentHistory = (
    params: ReadRecentViewDto,
    isSearch: boolean,
) => {
    return useQuery(
        [queryKeys.USER_PAYMENT_HISTORY, params.user_no],
        () => fetchData(params),
        {
            // initialData: initData,
            enabled: isSearch,
        },
    );
};
export { useGetUserPaymentHistory };
