import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Title, Checkbox } from "@src/components";

interface IPushReceiverType {
    initData: {
        userTypes: [];
        subscriptionTypes: [];
    };
    isUpdate: boolean;
    handleSubmit: Function;
}

export default function PushReceiverType({
    initData,
    isUpdate = false,
    handleSubmit,
}: IPushReceiverType) {
    const isDisabled = !isUpdate;

    const [edit, setEdit] = useState(false);
    const [chkAll, setChkAll] = useState(false);
    //
    const [isCheckedAll, setIsCheckedAll] = useState(false);

    const [userTypes, setUserTypes] = useState<ICheckItem[]>([
        { name: "일반", value: "none", isChecked: false },
        { name: "전문가", value: "expert", isChecked: false },
    ]);
    const [subscriptionTypes, setSubscriptionTypes] = useState<ICheckItem[]>([
        { name: "정기구독 - 무료", value: "free", isChecked: false },
        { name: "정기구독 - 유료", value: "paid", isChecked: false },
        { name: "정기구독 - 미구독", value: "none", isChecked: false },
    ]);
    useEffect(() => {
        // userTypes, subscriptionTypes 모두 true 일 경우 chkAll true 아니면 false
        const isChkAll =
            userTypes.every((item) => item.isChecked) &&
            subscriptionTypes.every((item) => item.isChecked);
        setChkAll(isChkAll);
        // true 인 애들만 데이터 가공해서 넘기기
        handleSubmit({
            userTypes: userTypes
                .filter((userType) => userType.isChecked)
                .map((userType) => userType.value),
            subscriptionTypes: subscriptionTypes
                .filter((subscriptionType) => subscriptionType.isChecked)
                .map((subscriptionType) => subscriptionType.value),
            edit,
        });
    }, [userTypes, subscriptionTypes]);

    useEffect(() => {
        // 초기값 세팅
        initData.userTypes?.forEach((userType: string) => {
            userTypes.forEach((item, i) => {
                if (item.value === userType) {
                    item.isChecked = true;
                }
            });
            setUserTypes([...userTypes]);
        });
        initData.subscriptionTypes?.forEach((subscriptionType: string) => {
            subscriptionTypes.forEach((item, i) => {
                if (item.value === subscriptionType) {
                    item.isChecked = true;
                }
            });
            setSubscriptionTypes([...subscriptionTypes]);
        });
    }, [initData]);

    return (
        <div css={subjectChkBox}>
            <div>
                {/* <Checkbox
                    isDisabled={isDisabled}
                    isChecked={chkAll}
                    label={"전체"}
                    defaultValue={"all"}
                    handleChange={(value, isChecked) => {
                        setChkAll(isChecked);
                        setUserTypes(
                            userTypes.map((item) => ({
                                ...item,
                                isChecked: isChecked,
                            })),
                        );
                        setSubscriptionTypes(
                            subscriptionTypes.map((item) => ({
                                ...item,
                                isChecked: isChecked,
                            })),
                        );
                        setEdit(true);
                    }}
                /> */}
                <Checkbox
                    isDisabled={isDisabled}
                    isChecked={isCheckedAll}
                    label={"전체"}
                    defaultValue={"all"}
                    handleChange={(value, isChecked) => {
                        setIsCheckedAll(isChecked);
                        setSubscriptionTypes(
                            subscriptionTypes.map((item) => ({
                                ...item,
                                isChecked: isChecked,
                            })),
                        );
                        setEdit(true);
                    }}
                />
            </div>
            <div>
                <Title title={"회원유형"} size={"small"} />
                <SubjectCheckbox
                    isUpdate={isDisabled}
                    checkList={userTypes}
                    check={setUserTypes}
                    edit={setEdit}
                />
            </div>
            <div>
                <Title title={"구독상태"} size={"small"} />
                <SubjectCheckbox
                    isUpdate={isDisabled}
                    checkList={subscriptionTypes}
                    check={setSubscriptionTypes}
                    edit={setEdit}
                />
            </div>
        </div>
    );
}

interface ICheckItem {
    name: string;
    value: string;
    isChecked: boolean;
}

interface ISubjectCheckBox {
    isUpdate: boolean;
    checkList: ICheckItem[];
    check: Function;
    edit: Function;
}

const SubjectCheckbox = ({
    isUpdate = false,
    checkList,
    check,
    edit,
}: ISubjectCheckBox) => {
    return (
        <>
            {checkList.map((item, i) => (
                <Checkbox
                    key={i}
                    isDisabled={isUpdate}
                    isChecked={item.isChecked}
                    label={item.name}
                    defaultValue={item.value}
                    handleChange={(value, isChecked, name) => {
                        checkList[i] = {
                            ...checkList[i],
                            isChecked: isChecked,
                        };
                        check([...checkList]);
                        edit(true);
                    }}
                />
            ))}
        </>
    );
};

const subjectChkBox = css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    & > div {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        & > div {
            margin-right: 15px;
        }
    }
`;
