import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import AWS from "aws-sdk";
import ServerChart from "@src/components/chart/ServerChart";

function NetworkStatsChart() {
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_BACKEND_USER_ACCESS_KEY,
        secretAccessKey:
            process.env.REACT_APP_AWS_BACKEND_USER_ACCESS_SECRET_KEY,
    });
    const [lastTimestamp, setLastTimestamp] = useState("");
    const [timePeriodType, setTimePeriodType] = useState(0);
    const [timePeriod, setTimePeriod] = useState(1000 * 60 * 60 * 3);
    const [timeInterval, setTimeInterval] = useState(500);
    const [chartData, setChartData] = useState([] as any);

    const createOption = (data: any) => {
        const colors = [
            "#4D455D",
            "#E96479",
            "#F5E9CF",
            "#7DB9B6",
            "#FFB84C",
            "#A459D1",
            "#34c9c1",
        ];
        const labels = data?.map((el: any) => el.Id);
        const times = data?.map((el: any) => el.Timestamps);
        const dataList = data?.map((el: any) => el.Values);

        return {
            labels: times[0],
            datasets: dataList.map((el: any, idx: number) => {
                return {
                    type: "line",
                    label: labels[idx],
                    data: el,
                    borderColor: colors[idx],
                    backgroundColor: colors[idx],
                    borderWidth: 2,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 3,
                };
            }),
        };
    };

    useEffect(() => {
        const timerId = setInterval(() => {
            AWS.config.update({
                region: "ap-northeast-2",
            });
            const cw = new AWS.CloudWatch({ apiVersion: "2010-08-01" });
            const today = new Date();
            const params = {
                StartTime: new Date(today.getTime() - timePeriod),
                EndTime: today,
                MetricDataQueries: [
                    {
                        Id: "apiLiveServer",
                        Label: "ApplicationELB",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/ApplicationELB",
                                MetricName: "RequestCount",
                                Dimensions: [
                                    {
                                        Name: "TargetGroup",
                                        Value: "targetgroup/ApiServer-App-Live-ECS-TG/dfc612da6368015b",
                                    },
                                    {
                                        Name: "LoadBalancer",
                                        Value: "app/ALB-ECS/3e0af23deeccd5f8",
                                    },
                                ],
                            },
                            Period: 600,
                            Stat: "Sum",
                            Unit: "Count",
                        },
                    },
                    {
                        Id: "adminLiveServer",
                        Label: "ApplicationELB",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/ApplicationELB",
                                MetricName: "RequestCount",
                                Dimensions: [
                                    {
                                        Name: "TargetGroup",
                                        Value: "targetgroup/ApiServer-Admin-Live-ECS-TG/078e328be475c674",
                                    },
                                    {
                                        Name: "LoadBalancer",
                                        Value: "app/ALB-ECS/3e0af23deeccd5f8",
                                    },
                                ],
                            },
                            Period: 600,
                            Stat: "Sum",
                            Unit: "Count",
                        },
                    },
                    {
                        Id: "foreignLiveServer",
                        Label: "ApplicationELB",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/ApplicationELB",
                                MetricName: "RequestCount",
                                Dimensions: [
                                    {
                                        Name: "TargetGroup",
                                        Value: "targetgroup/ApiServer-Foreign-Live-TG/8202d466c6c5b26a",
                                    },
                                    {
                                        Name: "LoadBalancer",
                                        Value: "app/ALB-dev/30fbcf346d919ed5",
                                    },
                                ],
                            },
                            Period: 600,
                            Stat: "Sum",
                            Unit: "Count",
                        },
                    },
                    {
                        Id: "deungiServer",
                        Label: "ApplicationELB",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/ApplicationELB",
                                MetricName: "RequestCount",
                                Dimensions: [
                                    {
                                        Name: "TargetGroup",
                                        Value: "targetgroup/ApiServer-Deungi-Live-TG/629ddbcc6cf40e44",
                                    },
                                    {
                                        Name: "LoadBalancer",
                                        Value: "app/ALB-deungi/a6446cddfd5458b7",
                                    },
                                ],
                            },
                            Period: 600,
                            Stat: "Sum",
                            Unit: "Count",
                        },
                    },
                    {
                        Id: "schedulerServer",
                        Label: "ApplicationELB",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/ApplicationELB",
                                MetricName: "RequestCount",
                                Dimensions: [
                                    {
                                        Name: "TargetGroup",
                                        Value: "targetgroup/TG-dev/2210c9459611df81",
                                    },
                                    {
                                        Name: "LoadBalancer",
                                        Value: "app/ALB-dev/30fbcf346d919ed5",
                                    },
                                ],
                            },
                            Period: 600,
                            Stat: "Sum",
                            Unit: "Count",
                        },
                    },
                ],
            };

            cw.getMetricData(params, (err, data) => {
                setChartData(createOption(data.MetricDataResults));
                setLastTimestamp(
                    new Date(new Date().getTime() + 32400000)
                        .toISOString()
                        .replace("T", " ")
                        .split(".")[0],
                );
                setTimeInterval(300 * 1000);
            });
        }, timeInterval);

        return () => clearInterval(timerId);
    }, [timeInterval]);

    const setTimeType = (type: number) => {
        setTimeInterval(500);
        setTimePeriodType(type);

        switch (type) {
            case 0:
                setTimePeriod(1000 * 60 * 60 * 3);
                break;
            case 1:
                setTimePeriod(1000 * 60 * 60 * 24);
                break;
            case 2:
                setTimePeriod(1000 * 60 * 60 * 24 * 3);
                break;
        }
    };
    return (
        <div css={subChartDiv}>
            <p css={titleP}>서버 현황 - Request count ({lastTimestamp})</p>
            <div id="loading-root"></div>
            <div css={btnBox}>
                <button
                    className={timePeriodType === 0 ? "active" : ""}
                    onClick={(e) => {
                        setTimeType(0);
                    }}
                >
                    3시간
                </button>
                <button
                    className={timePeriodType === 1 ? "active" : ""}
                    onClick={(e) => {
                        setTimeType(1);
                    }}
                >
                    1일
                </button>
                <button
                    className={timePeriodType === 2 ? "active" : ""}
                    onClick={(e) => {
                        setTimeType(2);
                    }}
                >
                    3일
                </button>
            </div>
            <div className="mainChartBox">
                <ServerChart data={chartData} />
            </div>
        </div>
    );
}

const btnBox = css`
    position: absolute;
    top: 13px;
    right: 20px;

    button {
        padding: 3px 5px;
        margin-left: 5px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        cursor: pointer;

        &.active {
            border: 1px solid #9a49f2;
            color: #9a49f2;
        }
    }
`;

const subChartDiv = css`
    position: relative;
    height: fit-content;
    max-height: 450px;
    canvas {
        height: 330px !important;
    }
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
`;

export default NetworkStatsChart;
