import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Table, Title, Button } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { usePushLog } from "@src/hooks/queries/usePushLog";

interface ILogBoxContainer {
    pushNo: string;
}

function PushLogBox({ pushNo }: ILogBoxContainer) {
    const dispatch = useDispatch();
    const [logData, setLogData] = useState<any>([]);
    const [isBtnClick, setIsBtnClick] = useState(false);
    const getUserUpdateHistory = () => {
        dispatch(startLoading());
        refetch();
        setIsBtnClick(true);
    };
    const { isLoading, isError, data, refetch, isRefetching } =
        usePushLog(pushNo);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setLogData(data.result);
            dispatch(endLoading());
        }
    }, [isLoading, isError, data]);
    useEffect(() => {
        if (!isRefetching) {
            dispatch(endLoading());
        }
    }, [isRefetching]);
    const columns = [
        {
            Header: () => {
                return <div style={{ textAlign: "center" }}>발송 일시</div>;
            },
            accessor: "createdAt",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "200px", textAlign: "center" }}>
                    {ConvertDate(value, "yyyy.MM.dd HH:mm:ss")}
                </div>
            ),
        },
        {
            Header: () => {
                return <div style={{ textAlign: "center" }}>사유</div>;
            },
            accessor: "results",
            Cell: ({ cell: { value } }: any) => {
                const message = value.split("|")[1];
                if (message?.includes("result")) {
                    const result =
                        JSON.parse(message).result?.results.map(
                            (v: any) => v?.error?.message,
                        ) || value;
                    return (
                        <div style={{ width: "1200px", textAlign: "left" }}>
                            {result}
                        </div>
                    );
                }

                return (
                    <div style={{ width: "1200px", textAlign: "left" }}>
                        {value}
                    </div>
                );
            },
        },
    ];
    return (
        <div css={isBtnClick ? [logBoxDiv, afterBtnClick] : logBoxDiv}>
            <div style={{ marginBottom: "20px" }}>
                <Title title="발송 로그" size="small" />
                <Button
                    label="조회"
                    color={"gd"}
                    size={"medium"}
                    isDisabled={false}
                    handleClick={() => getUserUpdateHistory()}
                    deleteBtn={false}
                    defaultValue={"조회"}
                />
            </div>
            <Table
                columns={columns}
                data={logData}
                initialState={{ pageIndex: 0, pageSize: 5 }}
                FixedHeight={270}
                showNumber={false}
            />
        </div>
    );
}
const logBoxDiv = css`
    width: 100%;
    min-width: 980px;
    margin-top: 70px;

    & > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const afterBtnClick = css`
    tbody {
        opacity: 1;
        background-color: ${Common.colors.white};
    }
`;

export default PushLogBox;
