import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Input, Checkbox, DropDown, IconButton } from "@src/components";
import {
    checkBoxList,
    ddlProgressStatus,
} from "@src/constants/SummaryInformationList";
import { Common } from "@src/styles/Common";
import {
    useGetSummaryInformation,
    useUpdateSummaryInformation,
} from "@src/hooks/queries/product/useSummaryInformation";
import {
    SummaryInformationContainerProps,
    UpdateSummaryInformationDto,
} from "@src/interfaces/product/SummaryInformationContainter.interface";
import { useDispatch } from "react-redux";
import { setSuccess, setError } from "@src/reducers/productUpdateResult";

interface SummaryInformationProps {
    initData: any;
    isEdit: boolean;
    productNo: string;
    isUpdating: boolean;
}
function SummaryInformationContainer({
    isEdit = false,
    productNo,
    isUpdating = false,
}: SummaryInformationProps) {
    const dispatch = useDispatch();
    const PRODUCT_NO = productNo;
    const [initData, setInitData] = useState<any>({
        isAggBid: false,
        stockScale: "",
        stockScalePyeong: "",
        landScale: "",
        landScalePyeong: "",
        buildingScale: "",
        buildingScalePyeong: "",
        buildingStockScale: "",
        buildingStockScalePyeong: "",
        bidType: "",
        status: "1",
        specialNote: {},
    });
    const [updateData, setUpdateData] = useState(initData);
    useEffect(() => {
        setUpdateData(initData);
    }, [initData]);
    const {
        isAggBid,
        stockScale,
        stockScalePyeong,
        landScale,
        landScalePyeong,
        buildingScale,
        buildingScalePyeong,
        buildingStockScale,
        buildingStockScalePyeong,
        bidType,
        status,
        specialNote,
    } = updateData;
    const { isLoading, isError, data, refetch } =
        useGetSummaryInformation(PRODUCT_NO);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
        }
    }, [isLoading, isError, data]);

    const calculationAreaToPyeong = (area: string): number => {
        return Number(Number(+area / 3.305785).toFixed(1));
    };
    const handleAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;
        setUpdateData({
            ...updateData,
            [name]: value,
            [name + "Pyeong"]: calculationAreaToPyeong(value),
        });
    };

    const updateParams = (
        updateData: SummaryInformationContainerProps,
    ): UpdateSummaryInformationDto => {
        const {
            stockScale,
            stockScalePyeong,
            landScale,
            landScalePyeong,
            buildingScale,
            buildingScalePyeong,
            buildingStockScale,
            buildingStockScalePyeong,
            bidType,
            status,
            specialNote,
        } = updateData;
        return {
            land_area: {
                square: {
                    all: landScale ?? null,
                    split: stockScale ?? null,
                },
                pyong: {
                    all: landScalePyeong ?? null,
                    split: stockScalePyeong ?? null,
                },
            },
            buid_area: {
                square: {
                    all: buildingScale ?? null,
                    split: buildingStockScale ?? null,
                },
                pyong: {
                    all: buildingScalePyeong ?? null,
                    split: buildingStockScalePyeong ?? null,
                },
            },
            status: status ?? null,
            types: bidType ?? null,
            counter_force: specialNote.counterForce,
            r_lien: specialNote.rightOfLien,
            r_tomb: specialNote.rightOfTombBase,
            shr_sale: specialNote.shareSale,
            only_land: specialNote.landBid,
            land_unreg: specialNote.unregisteredOfLandRight,
            cert_farm: specialNote.needOfLandCertification,
            r_surface: specialNote.statutorySuperficies,
            violation: specialNote.violationBuilding,
            only_buid: specialNote.buildingBid,
            land_sep_reg: specialNote.separateRegisteredLand,
        };
    };
    const { mutate } = useUpdateSummaryInformation(productNo);
    const updateMutate = (params: any) => {
        mutate(params, {
            onSuccess: () => {
                dispatch(setSuccess());
                refetch();
            },
            onError: (error: any) => {
                dispatch(setError({ errMsg: error.toString() }));
            },
        });
    };
    useEffect(() => {
        if (isUpdating) {
            let params = updateParams(updateData);
            updateMutate(params);
        }
    }, [isUpdating]);
    return (
        <div css={SummaryInformationDiv}>
            <ul css={SummaryInformationUl}>
                <li>
                    <Input
                        label={isAggBid ? "토지 면적(㎡)" : "대지권(㎡)"}
                        placeholder="-"
                        type="number"
                        min={0}
                        size="default"
                        handleChange={handleAreaChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="landScale"
                        value={landScale || ""}
                        border={isEdit}
                    />
                    {stockScale && (
                        <Input
                            label={"지분(㎡)"}
                            placeholder="-"
                            type="number"
                            min={0}
                            size="default"
                            handleChange={handleAreaChange}
                            isDisabled={!isEdit}
                            errorMessage={""}
                            name="stockScale"
                            value={stockScale || ""}
                            border={isEdit}
                        />
                    )}
                </li>
                <li>
                    <Input
                        label={"건물 면적(㎡)"}
                        placeholder="-"
                        type="number"
                        min={0}
                        size="default"
                        handleChange={handleAreaChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="buildingScale"
                        value={buildingScale || ""}
                        border={isEdit}
                    />
                    {buildingStockScale && (
                        <Input
                            label={"지분(㎡)"}
                            placeholder="-"
                            type="number"
                            min={0}
                            size="default"
                            handleChange={handleAreaChange}
                            isDisabled={!isEdit}
                            errorMessage={""}
                            name="buildingStockScale"
                            value={buildingStockScale || ""}
                            border={isEdit}
                        />
                    )}
                </li>
                <li>
                    <Input
                        label={isAggBid ? "토지 면적(평)" : "대지권(평)"}
                        placeholder="-"
                        type="number"
                        min={0}
                        size="default"
                        handleChange={() => {}}
                        isDisabled={true}
                        errorMessage={""}
                        name="landScalePyeong"
                        value={landScalePyeong || ""}
                        border={isEdit}
                    />
                    {stockScale && (
                        <Input
                            label={"지분(평)"}
                            placeholder="-"
                            type="number"
                            min={0}
                            size="default"
                            handleChange={() => {}}
                            isDisabled={true}
                            errorMessage={""}
                            name="stockScalePyeong"
                            value={stockScalePyeong || ""}
                            border={isEdit}
                        />
                    )}
                </li>
                <li>
                    <Input
                        label={"건물 면적(평)"}
                        placeholder="-"
                        type="number"
                        min={0}
                        size="default"
                        handleChange={() => {}}
                        isDisabled={true}
                        errorMessage={""}
                        name="buildingScalePyeong"
                        value={buildingScalePyeong || ""}
                        border={isEdit}
                    />
                    {buildingStockScalePyeong && (
                        <Input
                            label={"지분(평)"}
                            placeholder="-"
                            type="number"
                            min={0}
                            size="default"
                            handleChange={() => {}}
                            isDisabled={true}
                            errorMessage={""}
                            name="buildingStockScalePyeong"
                            value={buildingStockScalePyeong || ""}
                            border={isEdit}
                        />
                    )}
                </li>
                <li>
                    <Input
                        label={"매각형태"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={(e) => {
                            const { value } = e.target;
                            setUpdateData({ ...updateData, bidType: value });
                        }}
                        isDisabled={false}
                        errorMessage={""}
                        name="bidType"
                        value={bidType}
                        border={isEdit}
                    />
                </li>
                <li>
                    <span>진행상태</span>
                    <DropDown
                        data={ddlProgressStatus}
                        handleChange={(e, text) => {
                            setUpdateData({ ...updateData, status: text });
                        }}
                        defaultValue={status}
                        border={isEdit}
                        placeholder={""}
                        isDisabled={!isEdit}
                        label={"진행상태"}
                    />
                </li>
                <li>
                    <span>물건 특이사항</span>
                    <div css={checkBoxWrap}>
                        {checkBoxList.map((item, index) => {
                            const key = item.key;
                            return (
                                <Checkbox
                                    isDisabled={!isEdit}
                                    isChecked={specialNote[key]}
                                    label={item.value}
                                    defaultValue={item.value}
                                    handleChange={(_, c) => {
                                        setUpdateData({
                                            ...updateData,
                                            specialNote: {
                                                ...specialNote,
                                                [key]: c,
                                            },
                                        });
                                    }}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </li>
            </ul>
        </div>
    );
}

const SummaryInformationDiv = css`
    min-height: 200px;
`;
const SummaryInformationUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 15px;
    justify-content: flex-start;
    li {
        display: flex;
        align-items: center;
        width: 45%;
        & > span {
            width: 120px;
            display: inline-block;
            vertical-align: bottom;
            line-height: 60px;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
    }
    li:first-of-type {
        min-width: 400px;
    }
    li:last-of-type {
        width: 100%;
        height: 60px;
    }
    input:disabled {
        background-color: ${Common.colors.white};
        color: ${Common.colors.black};
    }
`;
const checkBoxWrap = css`
    display: flex;
    flex-wrap: wrap;
    & > div {
        margin-right: 20px;
    }
`;
export default SummaryInformationContainer;
