import { Button, IconButton, Label } from "@src/components";
import TrashButton from "@src/components/button/TrashButton";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { DeungiDetailList } from "@src/interfaces/User.interface";
import { ConvertDateTime } from "@src/utils/dateUtil";
import React, { Dispatch, SetStateAction } from "react";

// interface getUserProductListColumnsProps {
//     setIsDeungiEditModalShow: ;
// }

export const UserSugangListColumns = (
    onClickOpenRefund: () => void,
    onClickOpenUpdate: () => void,
    onChangeSelectedLecture: (val: any) => void,
    setOpenDeleteModal: () => void,
    setRegisterNo: (registerNum: number) => void,
) => [
    {
        Header: "강의 번호",
        accessor: "registerNo",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "98px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "등록일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "157px",
                    textAlign: "center",
                }}
            >
                {value ? ConvertDateTime(value) : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "강의명",
        accessor: "title",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "185px",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "총 재생시간",
        accessor: "totalViewTime",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "138px",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "자료다운여부",
        accessor: "isDownload",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "138px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? "O" : "X"}
                </div>
            );
        },
    },
    {
        Header: "지급쿠폰 사용여부",
        accessor: "usedCouponState",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "138px",
                        textAlign: "center",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "결제액",
        accessor: "paidPrice",
        Cell: ({ cell: { value }, row }: any) => {
            const userNo = row.values.userNo;
            return (
                <div
                    style={{
                        width: "99px",
                        textAlign: "center",
                    }}
                >
                    {value.toLocaleString()}원
                </div>
            );
        },
    },
    {
        Header: "결제번호",
        accessor: "paidNo",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "80px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "결제수단",
        accessor: "payType",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "80px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "환불액",
        accessor: "refundPrice",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "99px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value.toLocaleString()}원
                </div>
            );
        },
    },
    {
        Header: "수강기한",
        accessor: "expiredAt",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "146px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? ConvertDateTime(value) : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "상태",
        accessor: "state",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        // width: "65px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        boxSizing: "border-box",
                    }}
                >
                    {value === "수강중" && (
                        <Label text={value} color={"purple1"}></Label>
                    )}
                    {value === "부분환불" && (
                        <Label text={value} color={"refund"}></Label>
                    )}
                    {value === "전체환불" && (
                        <Label text={value} color={"allRefund"}></Label>
                    )}
                    {value === "수강완료" && (
                        <Label text={value} color={"expired"}></Label>
                    )}
                    {value === "관리자삭제" && (
                        <Label text={value} color={"black"}></Label>
                    )}
                </div>
            );
        },
    },
    {
        Header: "환불",
        accessor: "status",
        Cell: ({ row }: any) => {
            const disable = !(
                row?.values?.state.includes("수강") &&
                row?.values?.paidPrice !== 0
            );
            return (
                <div
                    style={{
                        width: "56px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Button
                        label="환불하기"
                        color={disable ? "gray" : "purple"}
                        size="small"
                        handleClick={() => {
                            onChangeSelectedLecture(row.values);
                            onClickOpenRefund();
                        }}
                        styles={{
                            width: "100%",
                            height: "30px",
                            fontSize: "13px",
                            fontWeight: 400,
                            margin: 0,
                        }}
                        isDisabled={disable}
                    />
                </div>
            );
        },
    },
    {
        Header: "수정",
        accessor: "no",
        Cell: ({ row }: any) => {
            const disable = !(
                row?.values?.state.includes("수강") &&
                row?.values?.paidPrice !== 0
            );
            return (
                <div
                    style={{
                        width: "37px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Button
                        label="수정"
                        color="orange"
                        size="small"
                        handleClick={() => {
                            onChangeSelectedLecture(row.values);
                            onClickOpenUpdate();
                        }}
                        styles={{
                            width: "100%",
                            height: "30px",
                            fontWeight: 400,
                            margin: 0,
                        }}
                    />
                </div>
            );
        },
    },
    {
        Header: "삭제",
        accessor: "registerNo",
        Cell: ({ cell: { value }, row }: any) => {
            // const disable = !(
            //     row?.values?.state.includes("수강") &&
            //     row?.values?.paidPrice !== 0
            // );

            return (
                <div
                    style={{
                        width: "37px",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <IconButton
                        type="trash"
                        handleClick={() => {
                            setOpenDeleteModal();
                            setRegisterNo(value);
                        }}
                        isDisabled={row.values.state === "관리자삭제"}
                    />
                </div>
            );
        },
    },
];
