import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import reducers from "./reducers";
import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";

const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // serializableCheck: {
            //     ignoredActions: [
            //         FLUSH,
            //         REHYDRATE,
            //         PAUSE,
            //         PERSIST,
            //         PURGE,
            //         REGISTER,
            //     ],
            // },
            serializableCheck: false,
        }),
});

const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export { store, persistor };
