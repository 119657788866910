import React from "react";
import ErrorPopUpPortal from "./ErrorPopUpPortal";
import EditModal from "./modal/EditModal";
import { useSelector, useDispatch } from "react-redux";
import { hideErrorPopUp } from "@src/reducers/errorPopUp";

export default function ErrorPopUp() {
    const {
        isErrorPopUpShow: isModalShow,
        errorCode,
        errorMessage,
    } = useSelector((state: any) => state.errorPopUp);

    const dispatch = useDispatch();
    return (
        <ErrorPopUpPortal>
            <EditModal
                title={"알림"}
                isModalShow={isModalShow}
                buttonText={"확인"}
                handleOpenModal={() => dispatch(hideErrorPopUp())}
                handleBtnClick={() => dispatch(hideErrorPopUp())}
                size={"medium"}
            >
                {
                    <>
                        오류 코드: {errorCode}
                        <div style={{ marginTop: "10px" }}>
                            {errorMessage ?? "최고 관리자에게 문의하세요."}
                        </div>
                    </>
                }
            </EditModal>
        </ErrorPopUpPortal>
    );
}
