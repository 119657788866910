import React from "react";
import { css } from "@emotion/react";
import { IconButton, Label } from "@src/components";
import {
    TemplateCategory,
    TemplateStatus,
} from "@src/constants/TemplateStatus";

export const getColumns = ({ navigate, setDeleteNo, setIsDelete }: any) => {
    return [
        {
            Header: "템플릿 코드",
            accessor: "template_code",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "유형",
            accessor: "category",
            Cell: ({ cell: { value } }: any) => {
                const category =
                    TemplateCategory[Number(value)] || TemplateCategory[0];
                return <div style={{ textAlign: "center" }}>{category}</div>;
            },
        },
        {
            Header: "템플릿 이름",
            accessor: "msg_title",
            Cell: ({ cell: { value } }: any) => <div css={maxLow}>{value}</div>,
        },
        {
            Header: "상태",
            accessor: "status",
            Cell: ({ row }: any) => {
                const color =
                    row.original.status === 100 ? "purpleBorder" : "purple";
                const name = TemplateStatus[row.original.status];
                return (
                    <div>
                        <Label color={color} text={name} />
                    </div>
                );
            },
        },
        {
            Header: "관리",
            accessor: "add",
            Cell: ({ row }: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "120px",
                    }}
                >
                    <IconButton
                        type="trash"
                        isDisabled={row.original.status !== 100}
                        handleClick={() => {
                            setIsDelete({
                                deleteNo: row.original.no,
                                isModalShow: true,
                            });
                            setDeleteNo(row.original.no);
                        }}
                    />
                    <IconButton
                        type="edit"
                        isDisabled={row.original.status !== 100}
                        handleClick={() => {
                            navigate(
                                `/notify/template/update/${row.original.no}`,
                            );
                        }}
                    />
                </div>
            ),
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ row }: any) => {
                return (
                    <div
                        style={{
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="arrow"
                            handleClick={() => {
                                navigate(
                                    `/notify/template/detail/${row.original.no}`,
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];
};

const maxLow = css`
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: -10px;
    text-align: left;
`;
