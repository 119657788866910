import { Calendar } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTime } from "@src/utils/dateUtil";
import React from "react";

export const getUserRoadExpansionEditModalColumns = (setEditTime: (time: string) => void) => [
    {
        Header: "결제일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "128px",
                    textAlign: "center",
                }}
            >
                {value ? ConvertDateTime(value) : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품명",
        accessor: "productName",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "156px",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },

    {
        Header: "이용기한",
        accessor: "expiredAt",
        Cell: ({ cell: { value }, row }: any) => {
            const time = new Date(value).getTime();

            return (
                <div
                    style={{
                        width: "180px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Calendar
                        getUserDate={(date) => {
                            setEditTime(new Date(date).toISOString().slice(0, 10));
                        }}
                        setDate={time}
                    />
                </div>
            );
        },
    },
];
