import { axiosDownloadInstance, axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import axios from "axios";
import { format } from "date-fns";
import { useInfiniteQuery, useQuery } from "react-query";

export interface IViewsCountFilter {
    startDate?: string;
    endDate?: string;
    viewCountMin?: number;
    viewCountMax?: number;
    sortType?: number;
    getCount?: number;
}
export interface IViewsCountResponse {
    list?: [
        {
            userNo?: number;
            email?: string;
            name?: string;
            phone?: string;
            gender?: string;
            birthdate?: string;
            createdAt?: string;
            leavedAt?: string;
            partnerName?: string;
            partnerStatus?: string;
            viewCount?: number;
        },
    ];
    totalCount?: number;
}

export interface ILotViewsCountFilter {
    startDate?: string;
    endDate?: string;
    sortType?: number;
    getCount?: number;
}

export interface ILotViewsCountResponse {
    list?: [
        {
            userNo: number;
            email: string;
            name: string;
            phone: string;
            gender: string;
            birthdate: number;
            status: number;
            createdAt: string;
            leavedAt: string;
            partnerName: string;
            partnerStatus: string;
            itemViewCount: number;
            lotViewCountWithLogin: number;
            lotViewCountWithoutLogin: number;
            totalViewCount: number;
        },
    ];
    totalCount?: number;
}

const fetchViewsCount = async (params: IViewsCountFilter) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/topViewers`,
        {
            params,
        },
    );

    return response.data;
};

export const useGetViewsCount = (
    params: IViewsCountFilter,
    enabled: boolean = false,
) => {
    return useQuery<IViewsCountResponse>(
        ["getViewsCount", params],
        () => fetchViewsCount(params),

        { enabled },
    );
};
const fetchLotViewsCount = async (params: ILotViewsCountFilter) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/lotViewers`,
        {
            params,
        },
    );

    return response.data;
};

export const useGetLotViewsCount = (
    params: ILotViewsCountFilter,
    enabled: boolean = false,
) => {
    return useQuery<ILotViewsCountResponse>(
        ["getLotViewsCount", params],
        () => fetchLotViewsCount(params),

        { enabled },
    );
};

const fetchLotViewerExcelDownloadList = async (params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/lotViewerDownloadExcel`,
        params,
    );

    return response.data;
};

export const useGetLotViewsCountExcel = async (
    params: IViewsCountFilter,
    reason: string,
) => {
    const response = await axiosDownloadInstance.get(
        `${axiosPath.nestUsers}/lotViewerDownloadExcel`,
        { params: { ...params, reason } },
    );

    const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] }),
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        decodeURI(
            response.headers["content-disposition"].split(
                "filename*=UTF-8''",
            )[1],
        ),
    );
    document.body.appendChild(link);
    link.click();
};

export const useGetViewsCountExcel = async (
    params: IViewsCountFilter,
    reason: string,
) => {
    const response = await axiosDownloadInstance.get(
        `${axiosPath.nestUsers}/topViewerDownloadExcel`,
        { params: { ...params, reason } },
    );

    const url = window.URL.createObjectURL(
        new Blob([response.data], { type: response.headers["content-type"] }),
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
        "download",
        decodeURI(
            response.headers["content-disposition"].split(
                "filename*=UTF-8''",
            )[1],
        ),
    );
    document.body.appendChild(link);
    link.click();
};

const fetchExcelDownloadList = async (params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/excelDownloadLog`,
        params,
    );

    return response.data;
};

export const useGetExcelDownloadList = (
    params: any,
    enabled: boolean = false,
) => {
    return useInfiniteQuery({
        queryKey: "getViewsCountExcelDownloadList",
        queryFn: ({ pageParam = 1 }) =>
            fetchExcelDownloadList({
                ...params,
                page: pageParam,
            }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage =
                pages.length * 10 < pages[0].totalCount
                    ? pages.length + 1
                    : undefined;
            return newPage;
        },
        enabled,
    });
};
