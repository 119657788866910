import { EditModal } from "@src/components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IBlockPageModal {
    isShowModal: boolean;
    url: string;
}
function BlockPageModal({ isShowModal, url }: IBlockPageModal) {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(isShowModal);

    useEffect(() => {
        setShowModal(isShowModal);
    }, [isShowModal]);
    return (
        <EditModal
            isModalShow={showModal}
            title={"알림"}
            buttonText={"확인"}
            children={"올바르지 않은 페이지입니다. 이전 페이지로 이동합니다."}
            handleOpenModal={() => {
                navigate(url);
            }}
            size={"medium"}
            handleBtnClick={() => {
                navigate(url);
                setShowModal((prev) => !prev);
            }}
            isDisabled={false}
            errorMessage={""}
        />
    );
}

export default BlockPageModal;
