import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";
import {
    AdminFilterType,
    AdminListType,
    BaseAdminListType,
} from "@src/types/adminType";
import { ResponseMissgoApi } from "@src/interfaces/MissgoApi";
import { queryKeys } from "./queryKeys";
import { ConvertPhoneNumber } from "@src/utils/units";

const initData = (max = 500) => {
    const arr = [];
    for (let i = 1; i <= max; i++) {
        arr.push({
            userNo: i,
            userName: Math.floor(Math.random() * 2) ? "은하수" : "김견우",
            userPhoneNumber: "010-1234-5678",
            userEmail: "abc@naver.com",
            userBirthday: 1651384425000,
            userGender: Math.floor(Math.random() * 2) ? "여자" : "남자",
            userAge: 30,
            userApplyDate: 1652334825000,
            userStatus: Math.floor(Math.random() * 5),
            userMarketing: Math.floor(Math.random() * 2) ? true : false,
            userMarketingDate: 1652334825000,
            jobTitle: Math.floor(Math.random() * 2) ? "사원" : "대리",
            viewMore: i,
        });
    }
    return arr;
};

interface ParametersType {
    page: number;
    limit: number;
    userjoinedonly?: number;
    to_go?: string;
    q?: string;
}
const fetchData = async (params: ParametersType) => {
    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");
    const url = `${axiosPath.admins}?${queryString}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: BaseAdminListType[] = responseData.data.data;

    return originData?.length
        ? {
              result: parse(
                  originData,
                  params?.page || 0,
                  responseData.data?.totalCnt || 100,
              ),
              total: responseData.data?.totalCnt || 100,
          }
        : { result: [], total: 0 };
};

const parse = (
    originData: BaseAdminListType[],
    page: number,
    total: number,
) => {
    const newData: AdminListType[] = [];
    const pageIndex = page === 0 ? 1 : page;
    originData.forEach((item, index) => {
        const {
            admin_no,
            admin_id,
            admin_name,
            admin_phone,
            admin_rank,
            user_no,
            created_at,
            updated_at,
        } = item;
        newData.push({
            adminName: admin_name,
            adminPhone: ConvertPhoneNumber(admin_phone),
            adminId: admin_id,
            adminApplyDate: created_at ?? "",
            adminRank: admin_rank,
            adminNo: admin_no,
            userNo: user_no,
        });
    });

    return newData;
};
const useAdminList = (params: ParametersType) => {
    return useQuery<ResponseMissgoApi<AdminListType>, Error>(
        [queryKeys.ADMIN_LIST, { ...params }],
        () => fetchData(params),
        {
            //initialData: initData
        },
    );
};

const getQueryString = (searchParams: AdminFilterType) => {
    let queryParams: any = {};
    const { userjoinedonly, limit, to_go, page, q } = searchParams;

    const createParams: ParametersType = {
        page,
        limit,
        to_go,
        q,
        userjoinedonly,
    };

    Object.keys(createParams).forEach((value: any) => {
        let name = value as keyof ParametersType;
        if (createParams[name]) {
            queryParams[name] = createParams[name];
        }
    });
    return queryParams;
};
const usePushReceivingAdminList = (filters: AdminFilterType) => {
    const queryParams = getQueryString(filters);
    return useQuery(
        [queryKeys.PUSH_RECEIVING_ADMIN_LIST, queryParams],
        () => fetchData(queryParams),
        {
            // initialData: initData,
        },
    );
};

export { useAdminList, usePushReceivingAdminList };
