export const typeDropDown = [
    {
        id: 1,
        key: "subscription",
        value: "구독권",
    },
    {
        id: 2,
        key: "deungi_ticket",
        value: "실시간 등기",
    },
    {
        id: 3,
        key: "campus_registration",
        value: "캠퍼스 강의",
    },
    {
        id: 4,
        key: "exist_deungi_ticket",
        value: "기존 등기",
    },
    {
        id: 5,
        key: "items_ads_ticket",
        value: "매물광고",
    },
    {
        id: 6,
        key: "region_ads_ticket",
        value: "지역광고",
    },
    {
        id: 7,
        key: "state_land_filter",
        value: "국공유지 필터",
    },
    {
        id: 8,
        key: "road_plan",
        value: "도로확장",
    },
    {
        id: 9,
        key: "premium_filter",
        value: "주거/토지 고스톱",
    },
    {
        id: 10,
        key: "Retail_profit_rate",
        value: "상가 고스톱",
    },
];

export const subsDropDown = [
    {
        id: 1,
        key: "subs_basic_01",
        value: "subs_basic_01",
    },
    {
        id: 2,
        key: "subs_gostop_01",
        value: "subs_gostop_01",
    },
];

export const itemDropDown = [
    {
        id: 1,
        key: "subs_basic_01",
        value: "subs_basic_01",
    },
    {
        id: 2,
        key: "subs_gostop_01",
        value: "subs_gostop_01",
    },
];

export const renewTermDropDown = [
    {
        id: 1,
        key: "none",
        value: "없음",
    },
    {
        id: 2,
        key: "monthly",
        value: "1개월",
    },
    {
        id: 3,
        key: "yearly",
        value: "1년",
    },
];

export const recommendDropDown = [
    {
        id: 1,
        key: 1,
        value: "추천",
    },
    {
        id: 2,
        key: 0,
        value: "미추천",
    },
];

export const purchaseRestrictionsDropDown = [
    {
        id: 1,
        key: 0,
        value: "제한없음",
    },
    {
        id: 2,
        key: 1,
        value: "CI별 1회만 구입가능",
    },
    {
        id: 3,
        key: 2,
        value: "회원별 1회만 구입가능",
    },
    {
        id: 4,
        key: 3,
        value: "전화번호별 1회만 구입 가능",
    },
    {
        id: 5,
        key: 4,
        value: "email별 1회만 구입가능",
    },
];

export const isEventDropDown = [
    {
        id: 1,
        key: 0,
        value: "X",
    },
    {
        id: 2,
        key: 1,
        value: "O",
    },
];

export const subsCategoryDropDown = [
    {
        id: 1,
        key: 0,
        value: "없음",
    },
    {
        id: 2,
        key: 1,
        value: "베이직",
    },
    {
        id: 3,
        key: 2,
        value: "스탠다드",
    },
    {
        id: 4,
        key: 3,
        value: "프리미엄",
    },
];

export const saleTypeDropDown = [
    {
        id: 1,
        key: 0,
        value: "일반판매 미노출",
    },
    {
        id: 2,
        key: 1,
        value: "일반판매 노출",
    },
    {
        id: 3,
        key: 2,
        value: "파트너스 제휴 판매 노출",
    },
    {
        id: 4,
        key: 3,
        value: "직원용",
    },
    {
        id: 5,
        key: 4,
        value: "무료 쿠폰용",
    },
    {
        id: 6,
        key: 5,
        value: "유료 쿠폰용",
    },
    {
        id: 7,
        key: 6,
        value: "AOS노출용",
    },
    {
        id: 8,
        key: 7,
        value: "IOS노출용",
    },
    {
        id: 9,
        key: 8,
        value: "WEB노출용",
    },
];

export const serveDurationTypeDropDown = [
    {
        id: 1,
        key: "year",
        value: "년",
    },
    {
        id: 2,
        key: "month",
        value: "개월",
    },
    {
        id: 3,
        key: "day",
        value: "일",
    },
];

export const productIssueTermDropDown = [
    {
        id: 1,
        key: "once",
        value: "1회",
    },
    {
        id: 2,
        key: "monthly",
        value: "매달",
    },
    {
        id: 3,
        key: "yearly",
        value: "매년",
    },
];

export const includeProductTermDropDown = [
    {
        id: 1,
        key: "once",
        value: "1회",
    },
    {
        id: 2,
        key: "month",
        value: "매달",
    },
    {
        id: 3,
        key: "year",
        value: "매년",
    },
];

export const monthlyInstallmentPlanEventDropDown = [
    {
        id: 1,
        key: "",
        value: "할부없음",
    },
    {
        id: 2,
        key: "1",
        value: "1개월",
    },
    {
        id: 3,
        key: "2",
        value: "2개월",
    },
    {
        id: 4,
        key: "3",
        value: "3개월",
    },
    {
        id: 5,
        key: "4",
        value: "4개월",
    },
    {
        id: 6,
        key: "5",
        value: "5개월",
    },
    {
        id: 7,
        key: "6",
        value: "6개월",
    },
    {
        id: 8,
        key: "7",
        value: "7개월",
    },
    {
        id: 9,
        key: "8",
        value: "8개월",
    },
    {
        id: 10,
        key: "9",
        value: "9개월",
    },
    {
        id: 11,
        key: "10",
        value: "10개월",
    },
    {
        id: 12,
        key: "11",
        value: "11개월",
    },
    {
        id: 13,
        key: "12",
        value: "12개월",
    },
];
