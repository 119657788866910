import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
    Button,
    DropDown,
    Input,
    SearchModal,
    TextBox,
    Title,
} from "@src/components";
import { arrayToDropdown, timeDropdown } from "@src/utils/dropdownUtil";
import { SituationFormProps } from "@src/interfaces/Situation.interface";
import { validChecker } from "@src/utils/validationCheck";
import { isEmpty, isNotEmpty } from "@src/utils/textUtil";
import { searchItemTitle } from "./SituationListColumns";
import { useTemplateList } from "@src/hooks/queries/useTemplate";
import { Common } from "@src/styles/Common";
import { SituationStatusType } from "@src/constants/SituationStatus";
import { useDuplicateSituation } from "@src/hooks/queries/useSituationDetail";
import { ErrorCode } from "@src/constants/ErrorCode";

interface ISituationForm {
    title: string;
    isUpdate: boolean;
    isDetail: boolean;
    handleUpdate: Function;
    initData: SituationFormProps;
}

function SituationForm({
    title,
    isUpdate,
    isDetail,
    handleUpdate,
    initData,
}: ISituationForm) {
    const DUPLICATION_SUCCESS = ErrorCode.Situation[0];
    const pushStatus = SituationStatusType; // 테스트: 300, 라이브: 400 (템플릿 매칭불가)
    const hours = timeDropdown(24);
    const minutes = timeDropdown(60);
    const [isModalShow, setIsModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [commentsErrorMessage, setCommentsErrorMessage] = useState("");
    const [situationCodeErrorMessage, setSituationCodeErrorMessage] =
        useState("");
    const [codeDuplicationMessage, setCodeDuplicationMessage] = useState("");
    const [updateData, setUpdateData] = useState<SituationFormProps>(initData);
    const updateFormData = (name: string, value: string | number) => {
        setUpdateData({
            ...updateData,
            [name]: value,
        });
    };

    const [reservationHour, setReservationHour] =
        useState<string | undefined>();
    const [reservationMinute, setReservationMinute] =
        useState<string | undefined>();

    useEffect(() => {
        const [hh, mm] = initData.situationReservationTime?.split(":") || [
            undefined,
            undefined,
        ];
        setReservationHour(hh);
        setReservationMinute(mm);
    }, [initData.situationReservationTime]);

    const [params, setParams] = useState({});
    const {
        isLoading: templateListLoading,
        data: templateList = { result: [], total: 0 },
        isError: templateListError,
    } = useTemplateList(params);

    type SearchListType = { template_code: string; msg_title: string };
    const [searchList, setSearchList] = useState<SearchListType[]>([]);

    useEffect(() => {
        if (Object.keys(params).length) {
            const filterList: SearchListType[] = [];
            templateList.result.forEach((v: any) =>
                filterList.push({
                    template_code: v.template_code,
                    msg_title: v.msg_title,
                }),
            );
            setSearchList(filterList);
        }
    }, [templateListError, templateListLoading, templateList.result]);

    useEffect(() => {
        // 예약시간 설정

        const hour = Number(reservationHour);
        const hh = hour < 10 ? `0${hour}` : hour;
        const minute = Number(reservationMinute);
        const mm = minute < 10 ? `0${minute}` : minute;
        const situationReservationTime = `${hh}:${mm}:00`;
        if (!isNaN(hour) && !isNaN(minute)) {
            updateFormData(
                "situationReservationTime",
                situationReservationTime,
            );
        }
    }, [reservationHour, reservationMinute]);

    const editChecker = () => {
        const emptyChecker =
            isNotEmpty(updateData?.situationCode || "") &&
            isNotEmpty(updateData?.situationComments || "") &&
            isNotEmpty(updateData?.templateCode || "");

        if (isUpdate) {
            return emptyChecker;
        } else {
            // 상황코드 중복값 체크
            return (
                emptyChecker && codeDuplicationMessage === DUPLICATION_SUCCESS
            );
        }
    };

    useEffect(() => {
        handleUpdate({ ...updateData, isFormError: editChecker() });
    }, [updateData, codeDuplicationMessage, situationCodeErrorMessage]);

    const handleSave = (selectedItem: any) => {
        updateFormData("templateCode", selectedItem.template_code);
        setIsModalShow(false);
    };
    const handleSearch = (value?: string) => {
        if (value) {
            setParams({ q: value, limit: 50 });
        }
    };
    const handleOpenModal = () => {
        setIsModalShow(!isModalShow);
        setParams({});
    };

    const { mutate: duplicateMutate } = useDuplicateSituation();
    const handleDuplicate = () => {
        const situationCode = updateData?.situationCode || "";
        if (situationCode) {
            duplicateMutate(situationCode, {
                onSuccess: () => {
                    setCodeDuplicationMessage(DUPLICATION_SUCCESS);
                },
                onError: (error: any) => {
                    setCodeDuplicationMessage(error || "");
                },
            });
        }
    };

    return (
        <div css={formDiv}>
            <Title title={title} />
            <ul>
                <li>
                    <Title title={"상황 코드"} size={"small"} required={true} />
                    <div style={{ width: "220px" }}>
                        <Input
                            placeholder="영문, 숫자만 사용하여 입력해주세요"
                            type="text"
                            size="dynamic"
                            handleChange={(e) => {
                                const text = e.target.value;
                                updateFormData("situationCode", text);
                                const { result, errMsg } = validChecker(
                                    "code",
                                    text,
                                );

                                setSituationCodeErrorMessage(
                                    result ? errMsg : "",
                                );
                                setCodeDuplicationMessage("");
                            }}
                            isDisabled={isDetail || isUpdate}
                            value={updateData.situationCode || ""}
                        />
                    </div>
                    {!isUpdate && (
                        <div
                            style={{
                                paddingLeft: "20px",
                                paddingBottom: "25px",
                            }}
                        >
                            <Button
                                label={"중복검사"}
                                color={"gd"}
                                size={"xSmall"}
                                isDisabled={false}
                                handleClick={handleDuplicate}
                                deleteBtn={false}
                            />
                        </div>
                    )}
                    <Input
                        data-testid="situation-code-message"
                        placeholder=""
                        size={"big"}
                        handleChange={(e) => {
                            const text = e.target.value;
                            setCodeDuplicationMessage(text);
                        }}
                        border={false}
                        isDisabled={isDetail}
                        value={codeDuplicationMessage || ""}
                        color={
                            codeDuplicationMessage === DUPLICATION_SUCCESS
                                ? "success"
                                : "error"
                        }
                    />
                </li>
                <li>
                    <Title title={"발생 상황"} size={"small"} required={true} />
                    <TextBox
                        placeholder="상황 내용을 입력해주세요"
                        size={"big"}
                        maxLength={100}
                        handleChange={(value) => {
                            const text = value;
                            updateFormData("situationComments", text);
                            const { result, errMsg } = validChecker(
                                "spacebar",
                                text,
                            );
                            // setCommentsErrorMessage(result ? errMsg : "");
                        }}
                        isDisabled={isDetail}
                        defaultText={updateData.situationComments || ""}
                    />
                </li>
                <li>
                    <Title
                        title={"템플릿 코드"}
                        size={"small"}
                        required={true}
                    />
                    <>
                        <div style={{ width: "130px" }}>
                            <Input
                                placeholder="템플릿 코드"
                                type="text"
                                size="dynamic"
                                handleChange={(e) => {
                                    const text = e.target.value;
                                    updateFormData("templateCode", text);
                                }}
                                isDisabled={true}
                                value={updateData.templateCode || ""}
                            />
                        </div>

                        {!isDetail && (
                            <div
                                style={{
                                    paddingLeft: "20px",
                                    paddingBottom: "25px",
                                }}
                            >
                                <Button
                                    label={"수정"}
                                    color={"gd"}
                                    size={"xSmall"}
                                    isDisabled={false}
                                    handleClick={() => {
                                        setIsModalShow(true);
                                    }}
                                    deleteBtn={false}
                                />
                            </div>
                        )}
                    </>
                </li>
                <li>
                    <Title title={"예약 시간"} size={"small"} />
                    {isDetail ? (
                        <span css={disabledStyle}>
                            {updateData.situationReservationTime}
                        </span>
                    ) : (
                        <>
                            <DropDown
                                data={hours}
                                handleChange={(e) => {
                                    const { value } = e.target;

                                    setReservationHour(value);
                                }}
                                border={true}
                                placeholder={"시"}
                                isDisabled={isDetail}
                                defaultValue={reservationHour}
                            />
                            <span>시</span>
                            <DropDown
                                data={minutes}
                                handleChange={(e) => {
                                    const { value } = e.target;

                                    setReservationMinute(value);
                                }}
                                border={true}
                                placeholder={"분"}
                                isDisabled={isDetail}
                                defaultValue={reservationMinute}
                            />
                            <span>분</span>
                        </>
                    )}
                </li>
                <li>
                    <Title title={"지연 시간"} size={"small"} />
                    <Input
                        placeholder="시간 단위(초)"
                        type="number"
                        min={0}
                        size="small"
                        handleChange={(e) => {
                            const text = e.target.value;
                            updateFormData("situationDelaySec", text);
                        }}
                        isDisabled={isDetail}
                        value={updateData.situationDelaySec || ""}
                    />
                </li>
                <li>
                    <Title title={"상태"} size={"small"} />
                    <DropDown
                        data={pushStatus}
                        handleChange={(e, text, value) => {
                            updateFormData("situationStatus", Number(value));
                        }}
                        placeholder={""}
                        border={!isDetail}
                        isDisabled={isDetail}
                    />
                </li>
            </ul>
            <SearchModal
                title="템플릿 코드"
                isModalShow={isModalShow}
                handleOpenModal={handleOpenModal}
                handleSave={handleSave}
                handleSearch={handleSearch}
                list={searchList}
                headers={searchItemTitle}
                isLoading={isLoading}
                placeholder={"템플릿 이름 입력"}
            />
        </div>
    );
}

const formDiv = css`
    padding: 40px;
    li {
        display: flex;
        align-items: center;

        & > div:first-of-type {
            width: 100px;
            font-weight: 500;
            line-height: 46px;
        }
    }
    & > ul > li {
        padding-top: 30px;

        span {
            padding: 10px;
        }
    }
    input:disabled {
        background: transparent;
    }
    textarea {
        width: 100%;
    }

    & > ul:first-of-type {
        select:disabled {
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }
`;

const disabledStyle = css`
    ${Common.textStyle.title6_R14}
    color: ${Common.colors.gray700};
`;

export default SituationForm;
