import styled from "@emotion/styled";
import { Checkbox, DropDown, Input, RangeCalendar } from "@src/components";
import { Common } from "@src/styles/Common";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import { userExpertInquiryListDto } from "./types";

interface ExpertInquiryFilterProps {
    filters: userExpertInquiryListDto;
    setFilters: Dispatch<SetStateAction<userExpertInquiryListDto>>;
    inquiryType: any;
    setInquiryType: Dispatch<SetStateAction<any>>;
}

const ExpertInquiryFilter = ({
    filters,
    setFilters,
    inquiryType,
    setInquiryType,
}: ExpertInquiryFilterProps) => {
    const dropList: {
        id: number;
        key: string;
        value: string;
    }[] = [
        { id: 0, key: "userExpertInfoInquiry.itemSubId", value: "사건번호" },
        { id: 1, key: "userExpertInfoInquiry.address", value: "소재지" },
        { id: 3, key: "inquiryUserInfo.uname", value: "문의 회원" },
        { id: 4, key: "expertUserInfo.uname", value: "전문가 회원" },
    ];
    const dateLabel = [
        //날짜 라디오 버튼
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];
    const [selectedDate, setSelectedDate] = useState<number | null>(0);

    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const renderDate = (item: number) => {
        if (item == 0) {
            setFilters({
                ...filters,
                searchStartDate: "",
                searchEndDate: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                searchStartDate: before7day,
                searchEndDate: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                searchStartDate: before1Month,
                searchEndDate: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                searchStartDate: before6Month,
                searchEndDate: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                searchStartDate: before12Month,
                searchEndDate: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                searchStartDate: TODAY,
                searchEndDate: TODAY,
            });
        }
        setSelectedDate(item);
    };

    const tryChangeValue = (text: string, value: any) => {
        if (value) {
            if (filters.types) {
                setFilters({
                    ...filters,
                    types: filters.types.concat(text),
                });
            } else {
                setFilters({
                    ...filters,
                    types: [text],
                });
            }
            setInquiryType({ ...inquiryType, [text]: value });
        } else {
            if (filters.types) {
                setFilters({
                    ...filters,
                    types: filters.types.filter((type: any) => type !== text),
                });
            } else {
                setFilters({
                    ...filters,
                    types: [],
                });
            }
        }
    };
    return (
        <>
            <StyledExpertInquiryFilter>
                <ul>
                    <li>
                        <span className="subTitle">검색</span>
                        <div>
                            <DropDown
                                handleChange={(e, text, value) => {
                                    value &&
                                        setFilters({
                                            ...filters,
                                            searchType: value,
                                        });
                                }}
                                data={dropList}
                                defaultValue={"사건번호"}
                            />
                            <Input
                                label=""
                                value={filters?.searchValue ?? ""}
                                name="searchInput"
                                type="text"
                                size="medium"
                                handleChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        searchValue: e.target.value,
                                    });
                                }}
                                isDisabled={false}
                                errorMessage=""
                                placeholder="사건번호, 소재지, 문의 회원, 전문가 회원을 입력하세요"
                            />
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">문의 일시</span>
                        <div>
                            {dateLabel.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <button
                                            value={item.value}
                                            className={
                                                "datebtn" +
                                                (item.key == selectedDate
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() => renderDate(item.key)}
                                        >
                                            {item.value}
                                        </button>
                                    </div>
                                );
                            })}
                            <RangeCalendar
                                setRangeDate={{
                                    startDate:
                                        filters.searchStartDate !== null &&
                                        filters.searchStartDate !== undefined
                                            ? dateParse(filters.searchStartDate)
                                            : null,

                                    endDate:
                                        filters.searchEndDate !== null &&
                                        filters.searchEndDate !== undefined
                                            ? dateParse(filters.searchEndDate)
                                            : null,
                                }}
                                getUserRangeDate={(date, isError) => {
                                    const { startDate: start, endDate: end } =
                                        date;
                                    setFilters({
                                        ...filters,
                                        searchStartDate: start
                                            ? ConvertDate(start)
                                            : "",
                                        searchEndDate: end
                                            ? ConvertDate(end)
                                            : "",
                                    });
                                }}
                                verticalAlign={false}
                                isDisabled={false}
                                timePicker={false}
                                clear={false}
                            />
                        </div>
                    </li>

                    <li>
                        <span className="subTitle">문의 유형</span>
                        <div className="chkBoxDivWrap">
                            <div className="chkBoxDiv">
                                {Object.keys(inquiryType)
                                    .reverse()
                                    .map((check: any, idx) => (
                                        <Fragment key={idx}>
                                            {idx < 2 && (
                                                <Checkbox
                                                    key={idx.toString()}
                                                    isDisabled={false}
                                                    isChecked={
                                                        inquiryType[check]
                                                    }
                                                    label={
                                                        idx === 0
                                                            ? `전화 문의`
                                                            : `문자 문의`
                                                    }
                                                    defaultValue={check}
                                                    handleChange={(n, v) => {
                                                        tryChangeValue(n, v);
                                                    }}
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                            </div>
                        </div>
                    </li>
                </ul>
            </StyledExpertInquiryFilter>
        </>
    );
};

export default ExpertInquiryFilter;

const StyledExpertInquiryFilter = styled.div`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDivWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }
    .chkBoxDiv {
        display: flex;
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .datebtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .datebtn.active {
        font-weight: 700;
    }
`;
