import React, { Dispatch, SetStateAction } from "react";
import { userExpertDto } from "./types";

export const getColumns = (
    expertData: userExpertDto,
    setExpertData: Dispatch<SetStateAction<userExpertDto>>,
    setIsShowOfficeModal: Dispatch<SetStateAction<boolean>>,
) => {
    return [
        {
            Header: "번호",
            accessor: "no",
            Cell: ({ cell: { value }, row }: any) => {
                return (
                    <div
                        onClick={() => {
                            setExpertData({
                                ...expertData,
                                agentNo: row?.original?.no,
                                info: {
                                    ...expertData.info,
                                    officeInfo: {
                                        name: row.original.name,
                                        ceo: row.original.ceo,
                                        registrationNumber: row.original.registrationNumber,
                                        type: "agent",
                                        phone: row.original.phone,
                                        address: row.original.address,
                                    },
                                },
                            });
                            setIsShowOfficeModal(false);
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "상호",
            accessor: "name",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    onClick={() => {
                        setExpertData({
                            ...expertData,
                            agentNo: row?.original?.no,
                            info: {
                                ...expertData.info,
                                officeInfo: {
                                    name: row.original.name,
                                    ceo: row.original.ceo,
                                    registrationNumber: row.original.registrationNumber,
                                    type: "agent",
                                    phone: row.original.phone,
                                    address: row.original.address,
                                },
                            },
                        });
                        setIsShowOfficeModal(false);
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "등록번호",
            accessor: "registrationNumber",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    onClick={() => {
                        setExpertData({
                            ...expertData,
                            agentNo: row?.original?.no,
                            info: {
                                ...expertData.info,
                                officeInfo: {
                                    name: row.original.name,
                                    ceo: row.original.ceo,
                                    registrationNumber: row.original.registrationNumber,
                                    type: "agent",
                                    phone: row.original.phone,
                                    address: row.original.address,
                                },
                            },
                        });
                        setIsShowOfficeModal(false);
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "대표",
            accessor: "ceo",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => {
                        setExpertData({
                            ...expertData,
                            agentNo: row?.original?.no,
                            info: {
                                ...expertData.info,
                                officeInfo: {
                                    name: row.original.name,
                                    ceo: row.original.ceo,
                                    registrationNumber: row.original.registrationNumber,
                                    type: "agent",
                                    phone: row.original.phone,
                                    address: row.original.address,
                                },
                            },
                        });
                        setIsShowOfficeModal(false);
                    }}
                >
                    {value}
                </div>
            ),
        },

        {
            Header: "소재지",
            accessor: "address",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    onClick={() => {
                        setExpertData({
                            ...expertData,
                            agentNo: row?.original?.no,
                            info: {
                                ...expertData.info,
                                officeInfo: {
                                    name: row.original.name,
                                    ceo: row.original.ceo,
                                    registrationNumber: row.original.registrationNumber,
                                    type: "agent",
                                    phone: row.original.phone,
                                    address: row.original.address,
                                },
                            },
                        });
                        setIsShowOfficeModal(false);
                    }}
                >
                    {value}
                </div>
            ),
        },
    ];
};
