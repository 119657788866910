import { ConfirmModal, PageTable } from "@src/components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeungiNoticeListColumns } from "./DeungiNoticeListColumns";
import {
    useDeungiNoticeList,
    useDeungiNoticeRemover,
} from "@src/hooks/queries/useDeungiNotice";
import { DeungiNoticeDto } from "@src/interfaces/Deungi.interface";

interface Props {}

const DeungiNoticeList: React.FC<Props> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [param, setParam] = useState<DeungiNoticeDto>({ page: 1, limit: 10 });
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [deletedId, setDeletedId] = useState<number>(0);

    const columns = DeungiNoticeListColumns(
        navigate,
        setDeletedId,
        setOpenDialog,
    );
    const { data, refetch } = useDeungiNoticeList(param);
    const deleteNotice = useDeungiNoticeRemover(
        () => {
            setOpenDialog(false);
            setDeletedId(0);
            refetch();
        },
        () => {
            setOpenDialog(false);
            setDeletedId(0);
        },
    );
    const onSubmitDeleteDeungiNotice = () => {
        const param = deletedId;
        deleteNotice.mutate(param);
    };

    useEffect(() => {
        pathname.includes("deungi/notice/list") && refetch();
    }, [pathname]);

    return (
        <>
            <PageTable
                columns={columns}
                data={data?.result ?? []}
                initialState={{
                    pageIndex: 0,
                    pageSize: 10,
                }}
                FixedHeight={500}
                showTotalCount={true}
                totalCount={data?.total ?? 0}
                handlePageChange={(index: number) => {
                    const page = index + 1;
                    setParam({ ...param, page });
                }}
                forcePageNumber={param.page}
            />
            <ConfirmModal
                isModalShow={openDialog}
                buttonText="삭제"
                handleCancel={() => {
                    setDeletedId(0);
                    setOpenDialog(false);
                }}
                handleOk={onSubmitDeleteDeungiNotice}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default DeungiNoticeList;
