import React, { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Label } from "@src/components";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { ConvertPhoneNumber } from "@src/utils/units";

export const getConnectionAdminColumns = (
    onSubmitSaveExpertMangaer: (val: number, isModify: boolean) => void,
    isModify: boolean,
) => {
    return [
        {
            Header: "번호",
            accessor: "userNo",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "이름",
            accessor: "adminName",
            Cell: ({ cell: { value }, row }: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>;
            },
        },
        {
            Header: "휴대폰번호",
            accessor: "adminPhone",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },

        {
            Header: "이메일",
            accessor: "adminId",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "직위",
            accessor: "adminRank",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "추가",
            accessor: "adminNo",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        color="orange"
                        handleClick={() =>
                            onSubmitSaveExpertMangaer(value, isModify)
                        }
                        label="선택"
                        size="small"
                    />
                </div>
            ),
        },
    ];
};
