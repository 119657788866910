import React from "react";
import { IconButton, Label } from "@src/components";
// import {
//     UserStatus,
//     ExpertStatus,
//     SubscriptionStatus,
// } from "constants/UserStatus";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
export const getColumns = ({ navigate, setDelete }: any) => [
    {
        Header: "관리자 번호",
        accessor: "adminNo",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ width: "80px", textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "관리자 ID",
        accessor: "adminId",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "180px",
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "이름",
        accessor: "adminName",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ width: "80px", textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "연락처",
        accessor: "adminPhone",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ width: "120px", textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "직급",
        accessor: "adminRank",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ width: "100px", textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "회원번호",
        accessor: "userNo",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ width: "50px", textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "관리",
        accessor: "",
        Cell: ({ row: { values } }: any) => (
            <div
                style={{
                    width: "100px",
                    fontSize: "13px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    columnGap: "15px",
                }}
            >
                <IconButton
                    type={"edit"}
                    color={"black"}
                    handleClick={() => {
                        const adminNo = values.adminNo;
                        navigate(`/admins/update/${adminNo}`);
                    }}
                />
                <IconButton
                    type={"trash"}
                    color={"black"}
                    handleClick={() => {
                        const adminNo = values.adminNo;
                        setDelete(adminNo);
                    }}
                />
            </div>
        ),
    },
    {
        Header: "더보기",
        accessor: "",
        Cell: ({ row: { values } }: any) => (
            <div
                style={{
                    width: "100px",
                    fontSize: "13px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <IconButton
                    type={"arrow"}
                    color={"black"}
                    handleClick={() => {
                        const adminNo = values.adminNo;
                        navigate(`/admins/detail/${adminNo}`);
                    }}
                />
            </div>
        ),
    },
];
