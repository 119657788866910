import { useQuery, useMutation } from "react-query";
import { queryKeys } from "./queryKeys";
import {
    FormFAQType,
    BaseBoardType,
    UpdateBoardType,
} from "@src/pages/help/types";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { asyncCall } from "@src/api/asyncCall";

const fetchData = async (id: number) => {
    const response = await axiosInstance.get(`${axiosPath.board}/faq/${id}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: BaseBoardType = responseData.data.data;
    return parse(originData);
};

const parse = (originData: BaseBoardType): FormFAQType => {
    const { no, category, title, contents, is_open, created_at, updated_at } =
        originData;
    return {
        faqType: category,
        faqTitle: title,
        faqContent: contents.replaceAll(" ", "&nbsp;"),
        view: is_open,
        faqDate: created_at,
        updateDate: updated_at,
        more: 1,
        edit: true,
        no: no,
    };
};

const useFAQDetailData = (id: number, isUpdate: boolean) => {
    return useQuery<FormFAQType, Error>(
        [queryKeys.FAQ_DETAIL_DATA, id],
        () => fetchData(id),
        {
            // initialData,
            enabled: isUpdate,
        },
    );
};

const useUpdateFAQData = (id: number | undefined) => {
    const updateFAQ = async (params: UpdateBoardType) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.board}/faq/${id}`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateFAQ);
};

const useDeleteFAQData = () => {
    const deleteFAQ = async (id: number | undefined) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.board}/faq/${id}`,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(deleteFAQ);
};

const useCreateFAQData = () => {
    const createFAQ = async (params: UpdateBoardType) => {
        const promise = await axiosInstance.post(
            `${axiosPath.board}/faq`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(createFAQ);
};

export {
    useFAQDetailData,
    useUpdateFAQData,
    useDeleteFAQData,
    useCreateFAQData,
};
