import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Menu from "./SideMenu";
import { useNavigate } from "react-router-dom";
import authService from "@src/api/authService";
import { firstPage } from "@src/constants/Menu";
import { css } from "@emotion/react";

function Layout() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isLogin = authService.isUserLogin();

    useEffect(() => {
        if (!isLogin) {
            navigate(firstPage);
        }
    });

    return (
        <div
            css={
                pathname.includes("/coupon/detail")
                    ? rootNoMenuStyle
                    : rootStyle
            }
        >
            <Menu />
            <Outlet />
        </div>
    );
}

const rootStyle = css`
    display: flex;
    padding-left: 235px;
`;

const rootNoMenuStyle = css`
    display: flex;
    padding-left: 0;
`;

export default Layout;
