import { createSlice } from "@reduxjs/toolkit";
import { SurroundInfoType } from "@src/types";

const SurroundInformationData: SurroundInfoType = {
    stationLocation: [
        "*1호선 신도림역 430m",
        "*2호선 신도림역 453m",
        "*2호선 도림천역 430m",
    ],
    governmentOffice: {
        officeName: "괴정제3동 주민센터",
        officeAddress: "[49351] 부산 사하구 낙동대로171번길 30-5",
    },
    nearSchool: ["*계산중학교 400m"],
};

const initialState = SurroundInformationData;

export const surroundInformationSlice = createSlice({
    name: "surroundInfo",
    initialState,
    reducers: {
        createSurroundInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateSurroundInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createSurroundInfo, updateSurroundInfo } =
    surroundInformationSlice.actions;

export default surroundInformationSlice.reducer;
