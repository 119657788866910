import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { queryList } from "./queryList";

import {
    DeungiChangeListDto,
    DeungiChangeListResult,
    DeungiChangeLogResult,
    IDeungiChangeLogParams,
} from "@src/interfaces/DeungiChange.interface";
import { AxiosError } from "axios";

const fetchData = async (params: DeungiChangeListDto) => {
    const response = await axiosInstance.get(
        `${axiosPath.deungi}/deungiChangeList`,
        {
            params: {
                ...params,
            },
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: DeungiChangeListResult[] = responseData.list;
    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCount);
    } else {
        queryList.setTotalCount(responseData.totalCount);
    }

    return queryList.getData(() => {
        return originData;
    });
};

const getQueryString = (searchParams: DeungiChangeListDto) => {
    let queryParams: any = {};

    Object.keys(searchParams).forEach((value: any) => {
        let name = value as keyof DeungiChangeListDto;
        if (searchParams[name]) {
            queryParams[name] = searchParams[name];
        }
    });
    return queryParams;
};
const useDeungiChangeList = (
    filters: DeungiChangeListDto,
    enabled: boolean = true,
) => {
    const queryParams = getQueryString(filters);
    console.log("dd", filters, queryParams);

    return useQuery(
        [queryKeys.DEUNGI_CHANGE_LIST, queryParams],
        () => fetchData(queryParams),
        {
            enabled,
        },
    );
};

const fetchLogData = async (params: IDeungiChangeLogParams) => {
    const response = await axiosInstance.get(`${axiosPath.deungi}/alarmLog`, {
        params,
    });
    const responseData = response.data;
    return responseData;
};

const useGetLogList = (
    params: IDeungiChangeLogParams,
    enabled: boolean = false,
) => {
    return useQuery<DeungiChangeLogResult>(
        [`${queryKeys.DEUNGI_CHANGE_LOG_LIST}`, params],
        () => fetchLogData(params),
        { enabled },
    );
};

const useDeungiChangeRemover = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.deungi}/alarmLog`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.delete(`${url}?id=${data}`);
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useDeungiChangeRestore = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.deungi}/restore`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.put(`${url}?id=${data}`);
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

// const useRegistePatner = (
//     onSuccess: (datum: any) => void = () => {},
//     onError: (err: AxiosError) => void = () => {},
// ) => {
//     const url = `${axiosPath.partner}`;
//     return useMutation(
//         async (data: PartnerRegisterDto) => {
//             try {
//                 const res = await axiosInstance.post(url, data);
//                 return res.data;
//             } catch (Err) {
//                 console.log("saveError>>", Err);
//                 throw Err;
//             }
//         },
//         {
//             onSuccess: onSuccess,
//             onError: onError,
//         },
//     );
// };

// const useUpdatePartner = (
//     onSuccess: (datum: any) => void = () => {},
//     onError: (err: AxiosError) => void = () => {},
// ) => {
//     const url = `${axiosPath.partner}`;
//     return useMutation(
//         async (params: { data: PartnerRegisterDto; no: any }) => {
//             try {
//                 const res = await axiosInstance.put(
//                     `${url}/${params.no}`,
//                     params.data,
//                 );
//                 return res.data;
//             } catch (Err) {
//                 console.log("saveError>>", Err);
//                 throw Err;
//             }
//         },
//         {
//             onSuccess: onSuccess,
//             onError: onError,
//         },
//     );
// };

// const useCheckNameDuplicated = async (name: string, userNo: number) => {
//     const response = await axiosInstance.get(
//         `${axiosPath.partner}/is-duplicated-name/${name}/${userNo}`,
//     );
//     const responseData = response.data;
//     return responseData;
// };

// const useCheckCodeDuplicated = async (code: string, userNo: number) => {
//     const response = await axiosInstance.get(
//         `${axiosPath.partner}/is-duplicated-code/${code}/${userNo}`,
//     );
//     const responseData = response.data;
//     return responseData;
// };

// const useGetPartnerDetail = async (no: any) => {
//     const response = await axiosInstance.get(`${axiosPath.partner}/${no}`);
//     const responseData = response.data;
//     return responseData;
// };

// const useGetBankList = async () => {
//     const response = await axiosInstance.get(`${axiosPath.partner}/banks`);
//     return response.data;
// };

// const fetchBenefitsList = async () => {
//     const response = await axiosInstance.get(
//         `${axiosPath.nestProducts}/insertable-payback-partner-benefits`,
//     );

//     return response.data;
// };

// const useGetPartnerBenefitsList = () => {
//     return useQuery([queryKeys.PARTNER_BENEFITS_LIST], fetchBenefitsList, {
//         enabled: true,
//     });
// };
// const fetchBenefits = async () => {
//     const response = await axiosInstance.get(`${axiosPath.partnerBenefits}`);
//     return response.data?.title ? response.data : null;
// };

// const useGetPartnerBenefits = () => {
//     return useQuery([queryKeys.PARTNER_BENEFITS], fetchBenefits, {
//         enabled: true,
//     });
// };

// const usePostPartnerBenefits = (
//     onSuccess: (datum: any) => void = () => {},
//     onError: (err: AxiosError) => void = () => {},
// ) => {
//     const url = `${axiosPath.partnerBenefits}`;
//     return useMutation(
//         async (data: any) => {
//             try {
//                 const res = await axiosInstance.post(`${url}`, data);
//                 return res.data;
//             } catch (Err) {
//                 console.log("saveError>>", Err);
//                 throw Err;
//             }
//         },
//         {
//             onSuccess: onSuccess,
//             onError: onError,
//         },
//     );
// };
// const useUpdatePartnerBenefits = (
//     onSuccess: (datum: any) => void = () => {},
//     onError: (err: AxiosError) => void = () => {},
// ) => {
//     const url = `${axiosPath.partnerBenefits}`;
//     return useMutation(
//         async (params: { data: any; no: any }) => {
//             try {
//                 const res = await axiosInstance.put(
//                     `${url}/${params.no}`,
//                     params.data,
//                 );
//                 return res.data;
//             } catch (Err) {
//                 console.log("saveError>>", Err);
//                 throw Err;
//             }
//         },
//         {
//             onSuccess: onSuccess,
//             onError: onError,
//         },
//     );
// };

// const connectionParse = (originData: any, responseData: any) => {
//     const newData = originData.map((item: any) => {
//         return {
//             id: item.user.no,
//             createdAt: item.createdAt,
//             name: item.user.info.uname,
//             phone: item.user.info.phone,
//             email: item.user.id,
//             expiredAt: item.expiredAt,
//             paymentAmount: item.paymentAmount,
//             statusName: item.statusName,
//         };
//     });
//     const totalData =
//         responseData.paybackCount +
//         responseData.expiredPartnerCount +
//         responseData.canceledPartnerCount;

//     return {
//         list: newData,
//         total: totalData,
//         paybackCount: responseData.paybackCount,
//         expiredPartnerCount: responseData.expiredPartnerCount,
//         canceledPartnerCount: responseData.canceledPartnerCount,
//     };
// };

// const fetchConnectionList = async (no: number) => {
//     const response = await axiosInstance.get(
//         `${axiosPath.partner}/${no}/connections`,
//         { params: { page: 1, limit: 999999 } },
//     );

//     if (!response.status) {
//         throw new Error("Problem fetching data");
//     }
//     const responseData = response.data;
//     const originData = responseData.list;
//     queryList.setDataList(originData);
//     queryList.setTotalCount(1);
//     return queryList.getData(() => connectionParse(originData, responseData));
// };

// const useGetConnectionList = (no: number, enabled: boolean = false) => {
//     return useQuery(
//         [queryKeys.PARTNER_CONNETIONS_LIST, no],
//         () => fetchConnectionList(no),
//         { enabled },
//     );
// };

// const subsListParse = (originData: any) => {
//     const newData = originData.map((item: any) => {
//         return {
//             createdAt: item.createdAt,
//             no: item.user.no,
//             name: item.user.name,
//             description: item.description,
//             count: item.count,
//             accumulatedCount: item.accumulatedCount,
//         };
//     });
//     return newData;
// };

// const fetchSubsList = async (no: number) => {
//     const response = await axiosInstance.get(
//         `${axiosPath.partner}/${no}/histories`,
//     );
//     if (!response.status) {
//         throw new Error("Problem fetching data");
//     }
//     const responseData = response.data;
//     queryList.setDataList(responseData);
//     queryList.setTotalCount(1);

//     return queryList.getData(() => subsListParse(responseData));
// };

// const useGetSusList = (no: number, enabled: boolean = false) => {
//     return useQuery(
//         [queryKeys.PARTNER_SUBS_LIST, no],
//         () => fetchSubsList(no),
//         { enabled },
//     );
// };

export {
    useDeungiChangeList,
    getQueryString,
    useGetLogList,
    useDeungiChangeRemover,
    useDeungiChangeRestore,
};
