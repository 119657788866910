import { createSlice } from "@reduxjs/toolkit";

const buildingInformationData = {
    constructionCompany: "태산건설",
    totalNumberOfBuildings: "5",
    totalNumberOfHouseholds: "408",
    highestFloor: "6",
    lowestFloor: "6",
    totalParkingNumber: "",
    managementOfficeNumber: "0325459712",
    heatingSystem: "개별난방",
    heatingFuel: "도시가스",
    areaType: "55㎡, 55B㎡, 58㎡, 59㎡, 69㎡",
};

const initialState = buildingInformationData;

export const buildingInfoSlice = createSlice({
    name: "buildingInfo",
    initialState,
    reducers: {
        createBuildingInfo: (state, action) => {
            const result = action.payload;
            return { ...state, ...result };
        },
        updateBuildingInfo: (state, action) => {
            const result = action.payload;
            return { ...state, ...result };
        },
    },
});

export const { createBuildingInfo, updateBuildingInfo } =
    buildingInfoSlice.actions;

export default buildingInfoSlice.reducer;
