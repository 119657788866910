import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { MixedChart } from "@src/components";
import ChartHeader, { changeDateType } from "./ChartHeader";
import {
    initTime,
    useHomeNewMembersChart,
    SalesStatType,
} from "@src/hooks/queries/useHomeChart";
import { dateFormat, noYearFormat } from "@src/utils/dateUtil";
import { add } from "date-fns";
import moment from "moment";
import "moment/locale/ko";
moment.locale("ko");

function NewMembersChart() {
    const [filterUserChart, setFilterUerChart] = useState<SalesStatType>({
        cycle: "daily",
        startDate: dateFormat(initTime.day.startDate),
        endDate: dateFormat(initTime.day.endDate),
    });

    const parseNewUserChartAndCreateOption = (data: any, cycle: string) => {
        return {
            labels: data.map((v: any) =>
                cycle === "weekly"
                    ? `${noYearFormat(new Date(v.date))} ~ ${noYearFormat(
                          add(new Date(v.date), {
                              days: 6,
                          }),
                      )}`
                    : v.date,
            ),
            datasets: [
                {
                    type: "line",
                    label: "전체가입자 누적",
                    yAxisID: "right",
                    data: data.map((v: any) => v.userJoinAcmlt),
                    borderColor: "#FF007A",
                    backgroundColor: "#FF007A",
                    borderWidth: 3,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "line",
                    label: "탈퇴자 누적",
                    yAxisID: "right",
                    data: data.map((v: any) => v.userLeaveAcmlt),
                    borderColor: "#FF652F",
                    backgroundColor: "#FF652F",
                    borderWidth: 3,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "bar",
                    label: "오늘 가입자 수",
                    yAxisID: "left",
                    data: data.map((v: any) => v.userJoin),
                    borderColor: "#9A49F2",
                    backgroundColor: "#9A49F2",
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "bar",
                    label: "오늘 탈퇴자 수",
                    yAxisID: "left",
                    data: data.map((v: any) => v.userLeave),
                    borderColor: "#33B247",
                    backgroundColor: "#33B247",
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
            ],
        };
    };

    const {
        isLoading: newMembersLoading,
        data: newMembersData,
        isError: newMembersError,
    } = useHomeNewMembersChart(
        filterUserChart,
        parseNewUserChartAndCreateOption,
    );

    const [newMembersChartData, setNewMembersChartData] = useState([]);

    useEffect(() => {
        if (!newMembersLoading && !newMembersError) {
            setNewMembersChartData(newMembersData);
        }
    }, [newMembersData]);

    const getDateStatsUserChart = ({ range, type }: changeDateType) => {
        const { startDate, endDate } = range;

        setFilterUerChart({
            cycle: type,
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        });
    };

    const [options, setOptions] = useState({});
    useEffect(() => {
        switch (filterUserChart.cycle) {
            case "daily": {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "day",
                                displayFormats: {
                                    day: "MM.dd",
                                },
                                tooltipFormat: "yyyy.MM.dd",
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                title: (context: any) => {
                                    const label = context[0].label;
                                    const date = moment(label);
                                    return `${date.format("yyyy.MM.DD (dd)")}`;
                                },
                            },
                        },
                    },
                });
                break;
            }
            case "weekly": {
                setOptions({
                    scales: {
                        x: {
                            time: {
                                tooltipFormat: "yyyy.MM.dd",
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
            case "monthly": {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "month",
                                displayFormats: {
                                    month: "yyyy.MM",
                                },
                                tooltipFormat: "yyyy.MM.dd",
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    responsive: false,
                });
                break;
            }
            default: {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                displayFormats: { hour: "HH:mm" },
                                tooltipFormat: "yyyy.MM.dd HH:mm",
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
        }
    }, [filterUserChart]);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    return (
        <div css={subChartDiv}>
            <p css={titleP}>가입자</p>
            <div className="mainChartBox">
                <ChartHeader
                    getChangeDate={getDateStatsUserChart}
                    defaultBasisType="daily"
                />
                <div css={subContainer}>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#9A49F2"} />
                        <p>오늘 가입자 수</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#33B247"} />
                        <p>오늘 탈퇴자 수</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#FF007A"} />
                        <p>전체가입자 누적</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#FF652F"} />
                        <p>탈퇴자 누적</p>
                    </div>
                </div>
                <div style={{ height: "20px" }}></div>
                {/* <div css={chartBoxStyle} ref={scrollRef}> */}
                <MixedChart
                    data={newMembersChartData}
                    options={options}
                    onScrollEnd={(val: number) =>
                        scrollRef.current?.scrollTo(val, 0)
                    }
                    boxStyles={chartBoxStyle}
                />
                {/* </div> */}
            </div>
        </div>
    );
}

const subChartDiv = css`
    width: 600px;
    height: 360px;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1.5px solid ${Common.colors.gray200};
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
    font-size: 15px;
`;
const chartBoxStyle = css`
    width: 550px;
    height: 220px;
    overflow: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 8px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
`;
const subTitle = css`
    display: flex;
    /* width: 50px; */
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    ${Common.textStyle.title7_M12}
`;

const subContainer = css`
    display: flex;
    flex-direction: row;
`;

const LabelName = ({ nameColorBg }: { nameColorBg: string }) => {
    const chartColor = css`
        width: 15px !important;
        height: 15px;
        margin: 8px 3px;
        border-radius: 3px;
        background-color: ${nameColorBg};
    `;
    return <div css={chartColor} />;
};
export default NewMembersChart;
