import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import AdminForm from "./AdminForm";
import { useParams, useNavigate } from "react-router-dom";
import { Button, ConfirmModal } from "@src/components";
import {
    useAdminDetail,
    useUpdateAdmin,
} from "@src/hooks/queries/useAdminDetail";
import { useAdminList } from "@src/hooks/queries/useAdmin";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useDispatch } from "react-redux";

function AdminUpdate() {
    const { id: ADMIN_ID } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initAdmin, setInitAdmin] = useState<any>();
    const [updatedAdmin, setUpdatedAdmin] = useState({ edit: false });
    const [isDisabled, setIsDisabled] = useState(true);
    const [isModalShow, setIsModalShow] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState("");
    useEffect(() => {
        updatedAdmin.edit && setIsDisabled(false);
    }, [updatedAdmin]);
    const {
        isError,
        isLoading,
        data: initAdmins,
        refetch: getRefetch,
    } = useAdminDetail(ADMIN_ID ? ADMIN_ID : "0");
    useEffect(() => {
        if (!isLoading && !isError && initAdmins) {
            setInitAdmin(initAdmins);
        }
    }, [isLoading, isError, initAdmins]);
    const { refetch: listRefetch } = useAdminList({ page: 1, limit: 10 });
    const { mutate: updateMutate } = useUpdateAdmin(
        ADMIN_ID ? Number(ADMIN_ID) : 0,
    );
    const createParams = (updatedData: any) => {
        return {
            ...(updatedData.adminName && { admin_name: updatedData.adminName }),
            ...(updatedData.adminPhone && {
                admin_phone: updatedData.adminPhone,
            }),
            ...(updatedData.adminRank && { admin_rank: updatedData.adminRank }),
        };
    };
    const handleUpdate = (updatedData: any) => {
        let params = createParams(updatedData);
        dispatch(startLoading());
        updateMutate(params, {
            onSuccess: () => {
                listRefetch();
                getRefetch();
                navigate("/admins/list");
            },
            onError: (error: any) => {
                setPageErrorMessage(error.toString());
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };
    return (
        <div css={adminUpdateWrap}>
            <AdminForm
                isUpdate={true}
                title={"관리자 수정"}
                initData={initAdmin}
                handleSubmit={(data: any) => {
                    setUpdatedAdmin({ ...data });
                    setPageErrorMessage("");
                }}
            />
            <div css={submitBtnDiv}>
                <p css={pageErrMsgBox}>{pageErrorMessage}</p>
                <Button
                    label={"수정"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={isDisabled}
                    handleClick={() => {
                        setIsModalShow(true);
                    }}
                    deleteBtn={false}
                    defaultValue={"등록"}
                />
            </div>
            <ConfirmModal
                children={"관리자 정보를 수정하시겠습니까?"}
                isModalShow={isModalShow}
                handleOk={() => {
                    handleUpdate(updatedAdmin);
                    setIsModalShow(false);
                }}
                handleCancel={() => {
                    setIsModalShow(false);
                }}
                buttonText={"수정"}
                disabled={false}
            />
        </div>
    );
}

const adminUpdateWrap = css`
    width: 100%;
    padding: 40px;
`;
const submitBtnDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
`;
const pageErrMsgBox = css`
    color: ${Common.colors.error};
`;
export default AdminUpdate;
