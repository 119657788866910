import React, { ChangeEvent, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input, TextBox } from "@src/components";
import { useSelector } from "react-redux";

interface ISurroundInformation {
    isUpdate: boolean;
    handleUpdate?: (update: any) => void;
}
interface IDefaultInfo {
    stationLocation: Array<string>;
    governmentOffice: {
        [key: string]: string;
    };
    nearSchool: Array<string>;
}
function SurroundInformationContainer({
    isUpdate = false,
    handleUpdate,
}: ISurroundInformation) {
    const defaultInfo = useSelector((state: any) => state.surroundInfo);
    const [surroundData, setSurroundData] = useState<IDefaultInfo>({
        stationLocation: [],
        governmentOffice: {},
        nearSchool: [],
    });

    useEffect(() => {
        setSurroundData({ ...defaultInfo });
    }, [defaultInfo]);
    let { stationLocation, governmentOffice, nearSchool }: IDefaultInfo =
        surroundData;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let copyGovernmentOffice = { ...governmentOffice };
        copyGovernmentOffice[name] = value;
        setSurroundData((prev: any) => ({
            ...prev,
            governmentOffice: copyGovernmentOffice,
        }));
    };
    const onStationLocationChange = (value: string) => {
        let copyStationLocation = [value];
        setSurroundData({
            ...surroundData,
            stationLocation: copyStationLocation,
        });
    };
    const onNearSchoolChange = (value: string) => {
        let copyNearSchool = [value];
        setSurroundData({
            ...surroundData,
            nearSchool: copyNearSchool,
        });
    };

    useEffect(() => {
        if (handleUpdate !== undefined) {
            let copySurroundData = { ...surroundData };
            handleUpdate(copySurroundData);
        }
    }, [surroundData]);
    return (
        <div css={SurroundInfoDiv}>
            {surroundData && (
                <>
                    <ul>
                        <li className="subTitle">
                            인근역과의 거리
                            <br />
                            (반경 1km 이내)
                        </li>
                        <li>
                            <TextBox
                                handleChange={onStationLocationChange}
                                label=""
                                maxLength={1000}
                                placeholder="인근 역과의 거리를 작성해주세요"
                                size="dynamic"
                                isDisabled={!isUpdate}
                                defaultText={stationLocation.join("\n")}
                            />
                        </li>
                    </ul>
                    <ul>
                        <li className="subTitle">주민센터</li>
                        <li>
                            <ul>
                                <li>
                                    <Input
                                        value={
                                            (governmentOffice &&
                                                governmentOffice[
                                                    "officeName"
                                                ]) ||
                                            ""
                                        }
                                        name="officeName"
                                        label=""
                                        placeholder=""
                                        size="dynamic"
                                        isDisabled={!isUpdate}
                                        handleChange={onChange}
                                    />
                                </li>
                                <li>
                                    <Input
                                        value={
                                            (governmentOffice &&
                                                governmentOffice[
                                                    "officeAddress"
                                                ]) ||
                                            ""
                                        }
                                        name="officeAddress"
                                        label=""
                                        placeholder=""
                                        size="dynamic"
                                        isDisabled={!isUpdate}
                                        handleChange={onChange}
                                    />
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul>
                        <li className="subTitle">
                            주변 학교
                            <br />
                            (반경 1km 이내)
                        </li>
                        <li>
                            <TextBox
                                handleChange={onNearSchoolChange}
                                label=""
                                maxLength={1000}
                                placeholder="주변학교를 작성해주세요"
                                size="dynamic"
                                isDisabled={!isUpdate}
                                defaultText={nearSchool.join("\n")}
                            />
                        </li>
                    </ul>
                </>
            )}
        </div>
    );
}

SurroundInformationContainer.propTypes = {};
const SurroundInfoDiv = css`
    color: ${Common.colors.gray500};
    & > ul {
        display: flex;
        .subTitle {
            width: 140px;
            ${Common.textStyle.text1_R13}
            padding-top: 12px;
        }
        li {
            width: 100%;
        }
    }
    input {
        width: 400px;
    }
    input:disabled {
        background-color: transparent;
        border-bottom: none;
        color: #222;
    }
    input:disabled + p {
        display: none;
    }
    input[name="officeName"] {
        font-weight: bold;
    }
`;
export default SurroundInformationContainer;
