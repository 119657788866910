import { css } from "@emotion/react";
import { Button, ConfirmModal, EditModal } from "@src/components";
import {
    useCampusReviewUpdate,
    useSugangUsersReviews,
} from "@src/hooks/queries/useCampus";
import React, { Fragment, useEffect, useRef, useState } from "react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    no: string;
}

const CampusReviewModal: React.FC<Props> = ({ open, onCloseView, no }) => {
    const tableRootRef = useRef<any>(null);
    const scrollRef = useRef<any>(null);
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);
    const [selectedReview, setSelectedReview] = useState<any>();
    const { data, fetchNextPage, hasNextPage, refetch } =
        useSugangUsersReviews(no);

    const handleObserver = (entries: any[]) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage) {
            fetchNextPage();
        }
    };

    const patchReview = useCampusReviewUpdate(() => {
        setIsShowConfirmModal(false);
        setSelectedReview(null);
        refetch();
    });

    const onClickUpdateReview = (reviewNo: number, isDelete: boolean) => {
        const param = { no, reviewNo, isDelete: !isDelete };
        patchReview.mutate(param);
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                const option = {
                    root: tableRootRef.current,
                    rootMargin: "0px",
                    threshold: 0.5,
                };
                const observer = new IntersectionObserver(
                    handleObserver,
                    option,
                );
                if (scrollRef.current && !hasNextPage) {
                    observer.disconnect();
                    return;
                }
                if (scrollRef.current) {
                    observer.observe(scrollRef.current);
                }
            }, 500);
        }
    }, [handleObserver, open]);

    return (
        <>
            <EditModal
                buttonText="닫기"
                handleBtnClick={onCloseView}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="평점 리스트"
                isModalShow={open}
            >
                <div css={rootStyle}>
                    <p css={totalTitleStyle}>
                        평균 평점{" "}
                        <span>{data?.pages[0]?.avgList?.avgReviewScore}점</span>
                    </p>
                    <div css={titleRootStyle}>
                        <p>
                            총 :{" "}
                            <span>
                                {data?.pages[0].avgList?.totalUserCnt}명
                            </span>
                        </p>
                        <p>
                            5점 : <span>{data?.pages[0].avgList?.five}명</span>
                        </p>
                        <p>
                            4점 : <span>{data?.pages[0].avgList?.four}명</span>
                        </p>
                        <p>
                            3점 : <span>{data?.pages[0].avgList?.three}명</span>
                        </p>
                        <p>
                            2점 : <span>{data?.pages[0].avgList?.two}명</span>
                        </p>
                        <p>
                            1점 : <span>{data?.pages[0].avgList?.one}명</span>
                        </p>
                    </div>
                    <div css={tableRootStyle} ref={tableRootRef}>
                        <table>
                            <colgroup>
                                <col width="16%" />
                                <col width="8%" />
                                <col width="12%" />
                                <col width="17%" />
                                <col width="8%" />
                                <col width="20%" />
                                <col width="13%" />
                                <col width="10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>등록일시</th>
                                    <th>이름</th>
                                    <th>연락처</th>
                                    <th>이메일</th>
                                    <th>평점</th>
                                    <th>의견</th>
                                    <th>후기상태</th>
                                    <th>관리</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.pages.map((group, i) => (
                                    <Fragment key={i.toString()}>
                                        {group.list.length ? (
                                            group.list.map((d: any) => (
                                                <tr key={d.no}>
                                                    <td>
                                                        {d.createdAtToString}
                                                    </td>
                                                    <td>{d.name}</td>
                                                    <td>{d.phone}</td>
                                                    <td>{d.email}</td>
                                                    <td>{d.score}</td>
                                                    <td>{d.contents}</td>
                                                    <td>
                                                        <div
                                                            css={{
                                                                padding:
                                                                    ".5rem 1rem",
                                                                borderRadius:
                                                                    "20px",
                                                                backgroundColor:
                                                                    d.statusToString ===
                                                                    "정상"
                                                                        ? "#2979FF"
                                                                        : d.statusToString ===
                                                                          "수강완료"
                                                                        ? "#424242"
                                                                        : "#FF1744",
                                                                color: "#ffffff",
                                                            }}
                                                        >
                                                            {d.statusToString}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            label={
                                                                d.status === -1
                                                                    ? "복구"
                                                                    : "삭제"
                                                            }
                                                            color={
                                                                d.status === -1
                                                                    ? "orange"
                                                                    : "purple"
                                                            }
                                                            size="small"
                                                            handleClick={(
                                                                e,
                                                            ) => {
                                                                setSelectedReview(
                                                                    d,
                                                                );
                                                                setIsShowConfirmModal(
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={8}
                                                    css={noDataStyle}
                                                >
                                                    <div>평점이 없습니다.🥲</div>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div ref={scrollRef} css={scrollStyle} />
                    </div>
                </div>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => setIsShowConfirmModal(false)}
                handleOk={() =>
                    onClickUpdateReview(
                        selectedReview?.no,
                        selectedReview?.status === -1,
                    )
                }
            >
                <p>
                    {selectedReview?.status === -1
                        ? "복구하시겠습니까?"
                        : "삭제하시겠습니까?"}
                </p>
            </ConfirmModal>
        </>
    );
};

export default CampusReviewModal;

const titleRootStyle = css`
    display: flex;
    align-items: center;
    p {
        font-size: 1rem;
        color: #424242;
        font-weight: 500;
        margin-right: 1rem;
        span {
            font-weight: 400;
            color: #333333;
        }
    }
`;

const totalTitleStyle = css`
    font-size: 1.1rem;
    color: #424242;
    font-weight: 600;
    margin-right: 1rem;
    margin-bottom: 1rem;
    span {
        font-weight: 500;
        color: #333333;
    }
`;

const tableRootStyle = css`
    width: 80vw;
    max-width: 1200px;
    margin-top: 2rem;
    height: 500px;
    overflow: scroll;
    max-height: fit-content;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            background-color: #eeeeee;
            position: sticky;
            top: 0px;
        }
        th {
            padding: 1.2rem;
            span {
                color: #ff0000;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
            border-bottom: 1px solid #b5b5b5;
        }
    }
`;

const scrollStyle = css`
    height: 3rem;
`;

const rootStyle = css`
    background-color: #ffffff;
`;

const noDataStyle = css`
    padding: 10rem 0 !important;
`;
