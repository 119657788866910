import { css } from "@emotion/react";
import {
    Button,
    ConfirmModal,
    EditModal,
    IconButton,
    Input,
} from "@src/components";
import {
    useCampusCurriculumSaver,
    useVimeoInfo,
} from "@src/hooks/queries/useCampus";
import React, { useCallback, useEffect, useState } from "react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    data: any;
    mainData: any;
    no?: string;
}

interface ICurriculum {
    no?: number;
    mainOrder: number;
    subOrder: number;
    title: string;
    subTitle: string;
    videoUrl: string;
    videoLength: number;
}

const LectureVideoManagementModal: React.FC<Props> = ({
    open,
    onCloseView,
    data,
    mainData,
    no,
}) => {
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);
    const [mainOrder, setMainOrder] = useState<any>([
        { mainOrder: 0, title: "" },
    ]);
    const [curriculumList, setCurriculumList] = useState<ICurriculum[]>([
        {
            mainOrder: 0,
            subOrder: 0,
            title: "",
            subTitle: "",
            videoUrl: "",
            videoLength: 0,
        },
    ]);

    const setTime = (time: number) => {
        if (time / 3600 >= 1) {
            const hour = Math.floor(time / 3600);
            const minute = time % 3600;
            const seconds = (time % 3600) * 60;
            return `${hour}:${minute > 9 ? minute : `0${minute}`}:${
                seconds > 9 ? seconds : `0${seconds}`
            }`;
        }
        if (time / 60 >= 1) {
            const minute = Math.floor(time / 60);
            const seconds = time % 60;
            return `00:${minute > 9 ? minute : `0${minute}`}:${
                seconds > 9 ? seconds : `0${seconds}`
            }`;
        }
        return `00:00:${time > 9 ? time : `0${time}`}`;
    };

    const onChangeMainOrder = (
        val: string,
        id: number,
        mainOrderNo: number,
    ) => {
        setMainOrder(
            [...mainOrder].map((o, i) => ({
                ...o,
                title: id === i ? val : o.title,
            })),
        );
        setCurriculumList(
            [...curriculumList].map((c, i) => ({
                ...c,
                title: c.mainOrder === mainOrderNo ? val : c.title,
            })),
        );
    };

    const onChangeSub = (type: string, val: string, id: number) => {
        setCurriculumList(
            [...curriculumList].map((c: any) => ({
                ...c,
                [type]: id === c.subOrder ? val : c[type],
            })),
        );
    };

    const getVimeoInfo = async (no: number, val: string) => {
        const newInfo = await useVimeoInfo({ url: val });
        onChangeSub("videoLength", newInfo.duration, no);
    };

    const curriCulumSaver = useCampusCurriculumSaver(() => {
        setIsShowConfirmModal(false);
        onCloseView();
    });

    const onClickSave = () => {
        const param = {
            curriculums: [...curriculumList].map((c, idx) => ({
                ...c,
                subOrder: idx,
                no: undefined,
            })),
            no: no,
        };
        curriCulumSaver.mutate(param);
    };

    useEffect(() => {
        if (open && data.length && mainData.length) {
            setMainOrder([...mainData]);
            setCurriculumList([...data]);
        }
    }, [open]);

    return (
        <>
            <EditModal
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowConfirmModal(true);
                }}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="강의영상 관리"
                isModalShow={open}
                dimClose={true}
                checkCloseModal={true}
            >
                <>
                    <div>
                        <Button
                            label="목차 추가"
                            size="small"
                            color="white"
                            handleClick={() => {
                                setCurriculumList([
                                    ...curriculumList,
                                    {
                                        mainOrder:
                                            mainOrder[mainOrder.length - 1]
                                                .mainOrder + 1,
                                        subOrder:
                                            curriculumList[
                                                curriculumList.length - 1
                                            ].subOrder + 1,
                                        title: "",
                                        subTitle: "",
                                        videoUrl: "",
                                        videoLength: 0,
                                    },
                                ]);
                                setMainOrder([
                                    ...mainOrder,
                                    {
                                        mainOrder: mainOrder.length,
                                        title: "",
                                    },
                                ]);
                            }}
                            styles={{ marginBottom: "1rem" }}
                        />
                    </div>
                    {mainOrder.map((main: any, idx: number) => (
                        <div css={rootStyle} key={main.mainOrder + idx}>
                            <div css={boxStyle}>
                                <div>
                                    <p css={boxTitleStyle}>
                                        <span>*</span> 목차명
                                    </p>
                                    <Input
                                        errorMessage=""
                                        handleChange={(evt) => {
                                            onChangeMainOrder(
                                                evt.target.value,
                                                idx,
                                                main.mainOrder,
                                            );
                                        }}
                                        label=""
                                        onKeyDown={() => {}}
                                        placeholder="목차명을 입력해주세요"
                                        size="medium"
                                        type="text"
                                        value={main.title}
                                        border={true}
                                    />
                                </div>
                                <Button
                                    label="목차 삭제"
                                    size="small"
                                    color="gray"
                                    handleClick={() => {
                                        if (mainOrder.length < 2) {
                                            return;
                                        }
                                        const newMainOrder = [...mainOrder]
                                            .filter(
                                                (_v: any, i: number) =>
                                                    i !== idx,
                                            )
                                            .map((v: any, index: number) => ({
                                                ...v,
                                                mainOrder: index,
                                            }));
                                        const newCurriculumList = [
                                            ...curriculumList,
                                        ]
                                            .filter((v) => v.mainOrder !== idx)
                                            .map((value) => ({
                                                ...value,
                                                mainOrder: newMainOrder.filter(
                                                    (q) =>
                                                        q.title === value.title,
                                                )[0].mainOrder,
                                            }));
                                        setMainOrder(newMainOrder);
                                        setCurriculumList(newCurriculumList);
                                    }}
                                />
                            </div>
                            <div css={tableRootStyle}>
                                <table>
                                    <colgroup>
                                        <col width="40%" />
                                        <col width="35%" />
                                        <col width="15%" />
                                        <col width="10%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <span>* </span>세부 강의명
                                            </th>
                                            <th>
                                                <span>* </span>영상 주소{" "}
                                                <span>
                                                    url입력 후 ENTER를
                                                    눌러주세요!
                                                </span>
                                            </th>
                                            <th>
                                                <span>* </span>영상 시간
                                            </th>
                                            <th>삭제</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {curriculumList
                                            .filter(
                                                (list) =>
                                                    list.mainOrder ===
                                                    main.mainOrder,
                                            )
                                            .map((sub, i) => (
                                                <tr key={i.toString()}>
                                                    <td>
                                                        <Input
                                                            handleChange={(
                                                                evt,
                                                            ) => {
                                                                onChangeSub(
                                                                    "subTitle",
                                                                    evt.target
                                                                        .value,
                                                                    sub.subOrder,
                                                                );
                                                            }}
                                                            label=""
                                                            onKeyDown={() => {}}
                                                            placeholder="세부강의명을 입력해주세요"
                                                            size="dynamic"
                                                            type="text"
                                                            value={sub.subTitle}
                                                            border={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            handleChange={(
                                                                evt,
                                                            ) => {
                                                                onChangeSub(
                                                                    "videoUrl",
                                                                    evt.target
                                                                        .value,
                                                                    sub.subOrder,
                                                                );
                                                            }}
                                                            label=""
                                                            onKeyDown={(
                                                                evt: any,
                                                            ) => {
                                                                if (
                                                                    evt.code ===
                                                                    "Enter"
                                                                ) {
                                                                    getVimeoInfo(
                                                                        sub.subOrder,
                                                                        evt
                                                                            .target
                                                                            .value,
                                                                    );
                                                                }
                                                            }}
                                                            placeholder="영상 주소 입력 후 엔터를 쳐주세요 ex) https://vimeo.com/1234567"
                                                            size="dynamic"
                                                            type="text"
                                                            value={sub.videoUrl}
                                                            border={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            handleChange={(
                                                                evt,
                                                            ) => {
                                                                // onChangeSub(
                                                                //     "videoLength",
                                                                //     evt.target
                                                                //         .value,
                                                                //     sub.subOrder,
                                                                // );
                                                            }}
                                                            label=""
                                                            placeholder="hh:mm:ss"
                                                            size="dynamic"
                                                            type="text"
                                                            value={setTime(
                                                                sub.videoLength,
                                                            )}
                                                            border={true}
                                                        />
                                                    </td>
                                                    <td css={deleteBtnStyle}>
                                                        <div>
                                                            <IconButton
                                                                type="trash"
                                                                handleClick={(
                                                                    e,
                                                                ) => {
                                                                    if (
                                                                        curriculumList.filter(
                                                                            (
                                                                                list,
                                                                            ) =>
                                                                                list.mainOrder ===
                                                                                main.mainOrder,
                                                                        )
                                                                            .length <
                                                                        2
                                                                    ) {
                                                                        return;
                                                                    }
                                                                    const newCurriculumList =
                                                                        [
                                                                            ...curriculumList,
                                                                        ].filter(
                                                                            (
                                                                                v,
                                                                            ) =>
                                                                                v.subOrder !==
                                                                                sub.subOrder,
                                                                        );
                                                                    setCurriculumList(
                                                                        newCurriculumList,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        <tr>
                                            <td colSpan={4} css={addBtnStyle}>
                                                <button
                                                    onClick={() => {
                                                        const currentIndex =
                                                            [
                                                                ...curriculumList,
                                                            ].findIndex(
                                                                (c: any) =>
                                                                    c.mainOrder ===
                                                                    main.mainOrder +
                                                                        1,
                                                            ) === -1
                                                                ? curriculumList.length
                                                                : [
                                                                      ...curriculumList,
                                                                  ].findIndex(
                                                                      (
                                                                          c: any,
                                                                      ) =>
                                                                          c.mainOrder ===
                                                                          main.mainOrder +
                                                                              1,
                                                                  );
                                                        const newCurriculumList =
                                                            [
                                                                ...curriculumList.slice(
                                                                    0,
                                                                    currentIndex,
                                                                ),
                                                                {
                                                                    mainOrder:
                                                                        main.mainOrder,
                                                                    subOrder:
                                                                        currentIndex,
                                                                    title: main.title,
                                                                    subTitle:
                                                                        "",
                                                                    videoUrl:
                                                                        "",
                                                                    videoLength: 0,
                                                                },
                                                                ...curriculumList.slice(
                                                                    currentIndex,
                                                                    curriculumList.length,
                                                                ),
                                                            ];
                                                        setCurriculumList([
                                                            ...newCurriculumList.map(
                                                                (val, i) => ({
                                                                    ...val,
                                                                    subOrder: i,
                                                                }),
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <div>
                                                        <p>+</p>
                                                        <p>세부강의 추가</p>
                                                    </div>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowConfirmModal(false);
                }}
                handleOk={() => {
                    onClickSave();
                }}
            >
                저장하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default LectureVideoManagementModal;

const rootStyle = css`
    padding: 0.5rem 1rem;
    border: 1px solid #bebebe;
`;

const boxStyle = css`
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    justify-content: space-between;
    div {
        display: flex;
        align-items: center;
    }
`;

const boxTitleStyle = css`
    font-size: 1rem;
    line-height: 120%;
    color: #959595;
    font-weight: 500;
    width: 10rem;
    span {
        color: #ff0000;
    }
`;

const tableRootStyle = css`
    width: 80vw;
    max-width: 1200px;
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 1.2rem;
            span:first-of-type {
                color: #ff0000;
            }
            span {
                font-weight: 400;
                font-size: 0.85rem;
                color: #b5b5b5;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
        }
    }
`;

const deleteBtnStyle = css`
    vertical-align: middle !important;
    div {
        justify-content: center;
        display: flex;
        align-items: center;
    }
`;

const addBtnStyle = css`
    padding: 10px !important;
    button {
        cursor: pointer;
        border: 1px solid #757575;
        border-radius: 24px;
        padding: 0.3rem 0.6rem;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #757575;
            p:first-of-type {
                padding-right: 0.5rem;
                font-weight: 700;
                font-size: 1.5rem;
            }
            p {
                font-size: 1rem;
            }
        }
    }
`;
