import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { RangeCalendar, DropDown, Checkbox, Input } from "@src/components";
import { Common } from "@src/styles/Common";
import { SupportFilterType } from "@src/types/supportFilterType";
import { ConvertDate } from "@src/utils/dateUtil";
import { dropIdToText, dropDownListType } from "@src/utils/dropdownUtil";
import { initFilters } from "@src/hooks/queries/useSupport";
import { CATEGORY_LIST, inquiryTypeList } from "@src/constants/SupportStatus";
import { dateParse } from "@src/utils/dateUtil";

interface ISupportFilter {
    filterChange: (data: any) => void;
    searchStatusList: dropDownListType[];
    ansStatusList: dropDownListType[];
    clear?: boolean;
    searchQuery: any;
}

function SupportFilter({
    filterChange,
    searchStatusList,
    ansStatusList,
    clear,
    searchQuery = {},
}: ISupportFilter) {
    const [filters, setFilters] = useState<SupportFilterType>(initFilters);
    const [ansStatus, setAnsStatus] = useState(dropIdToText(0, ansStatusList));
    const [device, setDevice] = useState<string>("전체");
    const [searchType, setSearchType] = useState(
        dropIdToText(0, searchStatusList),
    );
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    type QstCheckboxType = {
        [key: string]: boolean;
    };
    const initChecked: QstCheckboxType = {
        PAYMENT_QST: false,
        SERVICE_QST: false,
        SUBSCRIBE_QST: false,
        EXPERT_QST: false,
        EXPERT_SERVICE_QST: false,
    };
    const [isChecked, setIsChecked] = useState(initChecked);
    // 답변상태 드롭다운 변경시
    const handleDropChange = (
        e: ChangeEvent<HTMLSelectElement>,
        key: string,
    ) => {
        const { selectedIndex } = e.target.options;
        setFilters({
            ...filters,
            ...{
                [key]: selectedIndex,
            },
        });
        setAnsStatus(dropIdToText(selectedIndex, ansStatusList));
    };

    //검색 카테고리 변경시
    const handleSearchTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSearchType(e.target.value);
        setFilters({
            ...filters,
            searchKey: e.target.value,
            // searchValue: "",
        });
        // setSearchKeyword(""); //검색 카테고리 변경시 이전 카테고리의 키워드 초기화
    };
    //체크박스 선택시
    const handleCheckBoxChange = (name: string, checked: boolean) => {
        const setCheckedList = {
            ...isChecked,
            [name]: checked,
        };
        const qstType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);

        setFilters({ ...filters, qstType });
        setIsChecked(setCheckedList);
    };
    // 빈 값을 가지는 프로퍼티 삭제하기
    const removeEmptyObj = (update: SupportFilterType) => {
        return Object.fromEntries(
            Object.entries(update).filter(([_, value]) => value !== ""),
        );
    };
    useEffect(() => {
        filterChange(removeEmptyObj(filters));
    }, [filters]);

    useEffect(() => {
        //전체 해제 버튼 클릭시
        setFilters(initFilters);
        setAnsStatus(dropIdToText(0, ansStatusList));
        setSearchType(dropIdToText(0, searchStatusList));
        setSearchKeyword("");
        setIsChecked(initChecked);
    }, [clear]);

    const setFieldValueInSearchQuery = (searchQuery: any) => {
        if (searchQuery) {
            if (searchQuery.qstType) {
                const test = searchQuery.qstType;
                const updateQstType: QstCheckboxType = Object.entries(
                    CATEGORY_LIST,
                )
                    .filter(([_, value]) => {
                        return test.includes(value);
                    })
                    .reduce((acc: QstCheckboxType, [key, _]: any) => {
                        Object.assign(acc, { [key]: true });
                        return acc;
                    }, {});

                setIsChecked(updateQstType);
                setFilters({
                    ...filters,
                    ...{ qstType: searchQuery.qstType },
                });
            }

            if (searchQuery.isAnswer) {
                setAnsStatus(
                    dropIdToText(Number(searchQuery.isAnswer), ansStatusList),
                );
                setFilters({
                    ...filters,
                    ...{
                        isAnswer: +searchQuery.isAnswer,
                    },
                });
            }
        }
    };
    useEffect(() => {
        setFieldValueInSearchQuery(searchQuery);
    }, [searchQuery]);

    const [qstSelectedDate, setQstSelectedDate] = useState<number | null>(0);
    const [ansSelectedDate, setAnsSelectedDate] = useState<number | null>(0);
    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const dateLabel = [
        //날짜 라디오 버튼
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];
    const renderDate = (item: number, startName: string, endName: string) => {
        if (item == 0) {
            setFilters({
                ...filters,
                [startName]: "",
                [endName]: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                [startName]: before7day,
                [endName]: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                [startName]: before1Month,
                [endName]: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                [startName]: before6Month,
                [endName]: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                [startName]: before12Month,
                [endName]: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                [startName]: TODAY,
                [endName]: TODAY,
            });
        }
        if (startName === "qstStartDate") {
            setQstSelectedDate(item);
        } else {
            setAnsSelectedDate(item);
        }
    };

    useEffect(() => {
        if (filters.qstStartDate && !filters.qstEndDate) {
            setFilters({
                ...filters,
                qstEndDate: TODAY,
            });
        }
        if (filters.ansStartDate && !filters.ansEndDate) {
            setFilters({
                ...filters,
                ansEndDate: TODAY,
            });
        }
    }, [filters.qstStartDate, filters.ansStartDate]);

    return (
        <div css={FilterDiv}>
            <ul>
                <li>
                    <span className="subTitle">종류</span>
                    <div className="chkBoxDiv">
                        <Checkbox
                            isDisabled={false}
                            isChecked={isChecked.SERVICE_QST}
                            label={CATEGORY_LIST.SERVICE_QST}
                            defaultValue={"SERVICE_QST"}
                            handleChange={handleCheckBoxChange}
                        />
                        <Checkbox
                            isDisabled={false}
                            isChecked={isChecked.SUBSCRIBE_QST}
                            label={CATEGORY_LIST.SUBSCRIBE_QST}
                            defaultValue={"SUBSCRIBE_QST"}
                            handleChange={handleCheckBoxChange}
                        />
                        <Checkbox
                            isDisabled={false}
                            isChecked={isChecked.EXPERT_QST}
                            label={CATEGORY_LIST.EXPERT_QST}
                            defaultValue={"EXPERT_QST"}
                            handleChange={handleCheckBoxChange}
                        />
                        <Checkbox
                            isDisabled={false}
                            isChecked={isChecked.EXPERT_SERVICE_QST}
                            label={CATEGORY_LIST.EXPERT_SERVICE_QST}
                            defaultValue={"EXPERT_SERVICE_QST"}
                            handleChange={handleCheckBoxChange}
                        />
                        <Checkbox
                            isDisabled={false}
                            isChecked={isChecked.PAYMENT_QST}
                            label={CATEGORY_LIST.PAYMENT_QST}
                            defaultValue={"PAYMENT_QST"}
                            handleChange={handleCheckBoxChange}
                        />
                    </div>
                </li>
                <li>
                    <span className="subTitle">문의 일시</span>
                    <div>
                        {dateLabel.map((item) => {
                            return (
                                <div key={item.id}>
                                    <button
                                        value={item.value}
                                        className={
                                            "datebtn" +
                                            (item.key == qstSelectedDate
                                                ? " active"
                                                : "")
                                        }
                                        onClick={() =>
                                            renderDate(
                                                item.key,
                                                "qstStartDate",
                                                "qstEndDate",
                                            )
                                        }
                                    >
                                        {item.value}
                                    </button>
                                </div>
                            );
                        })}
                        <RangeCalendar
                            setRangeDate={{
                                startDate:
                                    filters.qstStartDate !== null &&
                                    filters.qstStartDate !== undefined
                                        ? dateParse(filters.qstStartDate)
                                        : null,
                                endDate:
                                    filters.qstEndDate !== null &&
                                    filters.qstEndDate !== undefined
                                        ? dateParse(filters.qstEndDate)
                                        : null,
                            }}
                            getUserRangeDate={(date, isError) => {
                                const { startDate: start, endDate: end } = date;
                                setFilters({
                                    ...filters,
                                    qstStartDate: start
                                        ? ConvertDate(start)
                                        : "",
                                    qstEndDate: end ? ConvertDate(end) : "",
                                    // isError,
                                });
                            }}
                            verticalAlign={false}
                            isDisabled={false}
                            timePicker={false}
                            clear={clear}
                        />
                    </div>
                </li>
                <li>
                    <span className="subTitle">답변 일시</span>
                    <div>
                        {dateLabel.map((item) => {
                            return (
                                <div key={item.id}>
                                    <button
                                        value={item.value}
                                        className={
                                            "datebtn" +
                                            (item.key == ansSelectedDate
                                                ? " active"
                                                : "")
                                        }
                                        onClick={() =>
                                            renderDate(
                                                item.key,
                                                "ansStartDate",
                                                "ansEndDate",
                                            )
                                        }
                                    >
                                        {item.value}
                                    </button>
                                </div>
                            );
                        })}
                        <RangeCalendar
                            setRangeDate={{
                                startDate:
                                    filters.ansStartDate !== null &&
                                    filters.ansStartDate !== undefined
                                        ? dateParse(filters.ansStartDate)
                                        : null,
                                endDate:
                                    filters.ansEndDate !== null &&
                                    filters.ansEndDate !== undefined
                                        ? dateParse(filters.ansEndDate)
                                        : null,
                            }}
                            getUserRangeDate={(date, isError) => {
                                const { startDate: start, endDate: end } = date;
                                setFilters({
                                    ...filters,
                                    ansStartDate: start
                                        ? ConvertDate(start)
                                        : "",
                                    ansEndDate: end ? ConvertDate(end) : "",
                                    // isError,
                                });
                            }}
                            verticalAlign={false}
                            isDisabled={false}
                            timePicker={false}
                            clear={clear}
                        />
                    </div>
                </li>
                <li>
                    <span className="subTitle">상태</span>
                    <div>
                        <DropDown
                            handleChange={(e) => {
                                handleDropChange(e, "isAnswer");
                            }}
                            data={ansStatusList}
                            defaultValue={ansStatus}
                        />
                    </div>
                </li>
                <li>
                    <span className="subTitle">구분</span>
                    <div>
                        <DropDown
                            handleChange={(evt) => {
                                const { selectedIndex } = evt.target.options;
                                console.log("selectedIndex", selectedIndex);
                                const newDevice =
                                    selectedIndex === 1
                                        ? "APP"
                                        : selectedIndex === 2
                                        ? "PC"
                                        : "all";
                                setDevice(newDevice);
                                // if (newDevice === "전체") {
                                //     const { }
                                //     setFilters({
                                //         ...filters,
                                //     });
                                // } else {
                                setFilters({
                                    ...filters,
                                    device: newDevice,
                                });
                                // }
                            }}
                            data={inquiryTypeList}
                            defaultValue={device}
                        />
                    </div>
                </li>
                <li>
                    <span className="subTitle">검색</span>
                    <div>
                        <DropDown
                            handleChange={handleSearchTypeChange}
                            data={searchStatusList}
                            defaultValue={searchType}
                        />
                        <Input
                            label=""
                            value={searchKeyword}
                            name="searchInput"
                            type="text"
                            size="medium"
                            handleChange={(
                                e: ChangeEvent<HTMLInputElement>,
                            ) => {
                                const { value } = e.target;
                                setSearchKeyword(value);
                                setFilters({
                                    ...filters,
                                    searchValue: value,
                                });
                            }}
                            // isDisabled={true}
                            errorMessage=""
                            placeholder="카테고리에 맞는 내용을 입력해주세요"
                        />
                    </div>
                </li>
            </ul>
        </div>
    );
}
const FilterDiv = css`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .datebtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .datebtn.active {
        font-weight: 700;
    }
`;
export default SupportFilter;
