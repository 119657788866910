import styled from "@emotion/styled";
import { Button, Title } from "@src/components";
import { useGetPartnerBenefits } from "@src/hooks/queries/usePartner";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Item from "./Item";

const PartnerBenefits = () => {
    const navigate = useNavigate();
    const { data } = useGetPartnerBenefits();

    return (
        <>
            <StyledWrap>
                <Title title="파트너 혜택 정보" />
                {data ? (
                    <Item data={data} />
                ) : (
                    <StyledNoData>등록된 혜택이 없습니다</StyledNoData>
                )}

                <div className="btnDiv">
                    <Button
                        color="gd"
                        handleClick={() => {
                            navigate("register", {
                                state: { type: data ? "update" : "create" },
                            });
                        }}
                        label={data ? "수정" : "등록"}
                        size="medium"
                    />
                </div>
            </StyledWrap>
        </>
    );
};

export default PartnerBenefits;

const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;

    gap: 25px;

    .btnDiv {
        position: absolute;
        bottom: 23px;
        right: 39px;
        & > button {
            background: #0029ff;
        }
    }
`;

const StyledNoData = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 200px;
    background-color: #ececec;
`;
