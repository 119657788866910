import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { BaseProductListType } from "@src/pages/product/types";
import ProductSampleList from "@src/data/MainItem.json";

const fetchData = async () => {
    const response = await fetch("");

    if (!response.ok) {
        throw new Error("Problem fetching data");
    }

    return [];
};

const useProductList = () => {
    return useQuery<BaseProductListType[], Error>(
        queryKeys.PRODUCT_LIST,
        fetchData,
        { initialData: ProductSampleList },
    );
};

export { useProductList };
