import React, { useEffect, useState, MouseEvent, useRef } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { MixedChart } from "@src/components";
import ChartHeader, { changeDateType } from "./ChartHeader";
import { initTime, SalesStatType, useHomeItemRefundStatsData } from "@src/hooks/queries/useHomeChart";
import { dateFormat, noYearFormat } from "@src/utils/dateUtil";
import { add, set, sub } from "date-fns";
import { getRelativePosition } from "chart.js/helpers";
import moment from "moment";
import "moment/locale/ko";
import { ProductType, productTypeList } from "../Home";

moment.locale("ko");

function ItemRefundStatsChart({ onClickOpenRefundMemberModal }: any) {
    const productTypeRef = useRef<ProductType>("subscription");
    const [productType, setProductType] = useState<ProductType>("subscription");

    const [filterRefundChart, setFilterRefundChart] = useState<SalesStatType>({
        cycle: "daily",
        startDate: dateFormat(initTime.day.startDate),
        endDate: dateFormat(initTime.day.endDate),
    });

    useEffect(() => {
        productTypeRef.current = productType;
    }, [productType]);

    const parseHomeUserRefundChartAndCreateOption = (data: any, cycle: string) => {
        return {
            labels: data.map((v: any) =>
                cycle === "weekly"
                    ? `${noYearFormat(new Date(v.date))} ~ ${noYearFormat(
                          add(new Date(v.date), {
                              days: 6,
                          }),
                      )}`
                    : v.date,
            ),
            datasets: [
                {
                    type: "line",
                    label: "환불금액",
                    yAxisID: "right",
                    data: data.map((v: any) => v.refundAmount),
                    borderColor: "#FF007A",
                    backgroundColor: "#FF007A",
                    borderWidth: 3,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "bar",
                    label: "환불건수",
                    yAxisID: "left",
                    data: data.map((v: any) => v.refundCount),
                    borderColor: "#D9D9D9",
                    backgroundColor: "#D9D9D9",
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
            ],
        };
    };

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        const dataType = e.currentTarget.getAttribute("product-type") as ProductType;
        console.log(dataType);
        setProductType(dataType);
    };

    const {
        isLoading: refundStatLoading,
        data: refundStatData,
        isError: refundStatError,
    } = useHomeItemRefundStatsData(filterRefundChart, productType, parseHomeUserRefundChartAndCreateOption);

    const [homeRefundChartData, setHomeRefundChartData] = useState<any>([]);

    useEffect(() => {
        if (!refundStatLoading && !refundStatError) {
            setHomeRefundChartData(refundStatData);
        }
    }, [refundStatData]);

    const getDateStatsSalesChart = ({ range, type }: changeDateType) => {
        const { startDate, endDate } = range;

        setFilterRefundChart({
            cycle: type,
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        });
    };

    const [options, setOptions] = useState({});
    useEffect(() => {
        switch (filterRefundChart.cycle) {
            case "daily": {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "day",
                                displayFormats: {
                                    day: "MM.dd",
                                },
                                tooltipFormat: "yyyy.MM.dd",
                            },
                        },
                    },
                    events: ["mousemove", "mouseout", "click"],
                    onClick: (evt: any) => {
                        const chart = evt.chart;
                        const newIndex = chart.getActiveElements()?.[0]?.index;
                        if (newIndex) {
                            onClickOpenRefundMemberModal(
                                productTypeRef.current,
                                add(new Date(filterRefundChart.startDate), {
                                    days: newIndex,
                                }),
                                add(new Date(filterRefundChart.startDate), {
                                    days: newIndex,
                                }),
                            );
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                title: (context: any) => {
                                    const label = context[0].label;
                                    const date = moment(label);
                                    return `${date.format("YYYY.MM.DD (dd)")}`;
                                },
                            },
                        },
                    },
                });
                break;
            }
            case "weekly": {
                setOptions({
                    scales: {
                        x: {
                            time: {
                                tooltipFormat: "yyyy.MM.dd",
                            },
                        },
                    },
                    interaction: {
                        mode: "index",
                        axis: "left",
                    },
                    events: ["mousemove", "mouseout", "click"],
                    onClick: (evt: any) => {
                        const chart = evt.chart;
                        const currentDataIndex = chart.getActiveElements()?.[0]?.index;
                        const canvasPosition = getRelativePosition(evt, chart);
                        if (currentDataIndex) {
                            const totalData = evt.chart.scales.x.ticks;
                            const checkStartDate = totalData[0]?.label.split("~")[0];
                            const newStartDate = set(new Date(filterRefundChart.startDate), {
                                month: checkStartDate.split(".")[0] - 1,
                                date: checkStartDate.split(".")[1],
                            });
                            onClickOpenRefundMemberModal(
                                productTypeRef.current,
                                add(new Date(newStartDate), {
                                    days: currentDataIndex * 7,
                                }),
                                add(new Date(newStartDate), {
                                    days: currentDataIndex * 7 + 6,
                                }),
                            );
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
            case "monthly": {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                unit: "month",
                                displayFormats: {
                                    month: "yyyy.MM",
                                },
                                tooltipFormat: "yyyy.MM.dd",
                            },
                        },
                    },
                    events: ["mousemove", "mouseout", "click"],
                    onClick: (evt: any) => {
                        const chart = evt.chart;
                        const currentDataIndex = chart.getActiveElements()?.[0]?.index;
                        const totalData = evt.chart.scales.x.ticks;
                        onClickOpenRefundMemberModal(
                            productTypeRef.current,
                            new Date(totalData[currentDataIndex].value),
                            sub(
                                add(new Date(totalData[currentDataIndex].value), {
                                    months: 1,
                                }),
                                {
                                    days: 1,
                                },
                            ),
                        );
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
            default: {
                setOptions({
                    scales: {
                        x: {
                            type: "time",
                            time: {
                                displayFormats: { hour: "HH:mm" },
                                tooltipFormat: "YYY.MM.dd HH:mm",
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                });
                break;
            }
        }
    }, [filterRefundChart]);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    return (
        <div>
            <div css={subChartDiv}>
                <p css={titleP}>상품별 환불 현황</p>
                <div css={chartHeaderBoxWrap}>
                    {productTypeList.map((type) => (
                        <div
                            key={type.value}
                            css={productType === type.value ? [chartHeaderBox, selected] : chartHeaderBox}
                            product-type={type.value}
                            onClick={handleClick}
                        >
                            {type.name}
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div className="mainChartBox">
                        <ChartHeader getChangeDate={getDateStatsSalesChart} defaultBasisType="daily" />
                        <div css={subContainer}>
                            <div css={subTitle}>
                                <LabelName nameColorBg={"#FF007A"} />
                                <p>환불금액</p>
                            </div>
                            <div css={subTitle}>
                                <LabelName nameColorBg={"#D9D9D9"} />
                                <p>환불건수</p>
                            </div>
                        </div>
                        {/* <div css={chartBoxStyle} ref={scrollRef}> */}
                        {homeRefundChartData && homeRefundChartData?.datasets?.length ? (
                            <MixedChart
                                data={homeRefundChartData}
                                options={options}
                                onScrollEnd={(val: number) => scrollRef.current?.scrollTo(val, 0)}
                                boxStyles={chartBoxStyle}
                            />
                        ) : (
                            <div>데이터가 없습니다.</div>
                        )}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

const subChartDiv = css`
    width: 600px;
    /* min-height: fit-content; */
    height: 360px;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    align-content: center;
    border: 1.5px solid ${Common.colors.gray200};
`;

const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
    font-size: 15px;
`;

const chartHeaderBoxWrap = css`
    display: flex;
    flex-direction: row;
    gap: 2px;
    cursor: pointer;
`;
const chartHeaderBox = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 1px 8px 0px; */
    height: 24px;
    background: ${Common.colors.white};
    border: 1px solid ${Common.colors.gray300};
    border-radius: 4px;
    font-size: 12px;
    line-height: 180%;
    white-space: nowrap;
`;
const selected = css`
    border: 1px solid ${Common.colors.purple500};
    color: ${Common.colors.purple500};
`;
const chartBoxStyle = css`
    width: 550px;
    overflow: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 8px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
`;
const subTitle = css`
    display: flex;
    /* width: 50px; */
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    ${Common.textStyle.title7_M12}
`;

const subContainer = css`
    display: flex;
    flex-direction: row;
`;

const LabelName = ({ nameColorBg }: { nameColorBg: string }) => {
    const chartColor = css`
        width: 15px !important;
        height: 15px;
        margin: 8px 5px;
        border-radius: 3px;
        background-color: ${nameColorBg};
    `;
    return <div css={chartColor} />;
};

export default ItemRefundStatsChart;
