import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Constants } from "@src/constants/Constants";
import { Button } from "@src/components";
import SituationForm from "./SituationForm";
import { useNavigate } from "react-router-dom";
import {
    CreateSituationDto,
    SituationFormProps,
} from "@src/interfaces/Situation.interface";
import { useSituationList } from "@src/hooks/queries/useSituation";
import { useCreateSituation } from "@src/hooks/queries/useSituationDetail";

function SituationCreate() {
    const navigate = useNavigate();
    const initData = {
        situationStatus: 200,
        isFormError: true,
    };
    const [disabled, setDisabled] = useState(initData.isFormError);
    const [pageErrorMessage, setPageErrorMessage] = useState<string>("");
    const [updateData, setUpdateData] = useState<SituationFormProps>(initData);

    useEffect(() => {
        setDisabled(!updateData.isFormError);
    }, [updateData]);

    const { refetch: listRefetch } = useSituationList({ limit: 10, page: 1 });
    const { mutate: createMutate } = useCreateSituation();
    const handleSubmit = () => {
        if (updateData.isFormError) {
            const params: CreateSituationDto = {
                situation_code: updateData?.situationCode || "",
                template_code: updateData?.templateCode || "",
                comments: updateData?.situationComments || "",
            };

            if (updateData.situationDelaySec) {
                params.delay_sec = updateData.situationDelaySec;
            } else if (updateData.situationReservationTime) {
                params.reservation_time = updateData?.situationReservationTime;
            }
            createMutate(params, {
                onSuccess: () => {
                    navigate("/notify/situation");
                },
                onError: (error: any) => {
                    setPageErrorMessage(error);
                },
                onSettled: () => {
                    listRefetch();
                },
            });
        }
    };

    return (
        <div css={createDiv}>
            <SituationForm
                title={`${Constants.SITUATION} 등록`}
                handleUpdate={setUpdateData}
                isUpdate={false}
                isDetail={false}
                initData={initData}
            />
            <div className="buttonBox">
                <p>{pageErrorMessage}</p>
                <Button
                    label={"등록"}
                    color={"gd"}
                    size="medium"
                    isDisabled={disabled}
                    handleClick={handleSubmit}
                    deleteBtn={false}
                    defaultValue={""}
                />
            </div>
        </div>
    );
}

const createDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;

export default SituationCreate;
