import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { sampleData } from "@src/data/CaseInfoContainerSampleData";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.itemsAuctionCaseInfo}/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: [] = responseData.data.data;
    return parse(originData);
};

const parse = (originData: any): any => {
    const {
        stated_date,
        case_title,
        owner,
        debtor,
        creditor,
        rel_case,
        munsong,
    } = originData;
    let parsedRelCase = rel_case;
    if (rel_case.length > 0) {
        parsedRelCase = rel_case.join(", ");
    }
    return {
        statedDate: stated_date,
        caseTitle: case_title,
        owner,
        debtor,
        creditor,
        relCase: parsedRelCase,
        docAndDel: munsong,
    };
};
const useGetCaseInformation = (productNo: any) => {
    return useQuery(
        [queryKeys.CASE_INFORMATION, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};
export { useGetCaseInformation };
