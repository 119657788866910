import { useQuery } from "react-query";
import {
    BaseSupportListType,
    ResultSupportListType,
    SupportListType,
} from "@src/pages/help/supportTypes";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { SupportFilterType } from "@src/types/supportFilterType";
import { ConvertDate } from "@src/utils/dateUtil";
import { getQueryData } from "@src/utils/useQueryClient";

// const random = (max: number) => {
//     const arr: SupportListType[] = [];
//     for (let i = 1; i <= max; i++) {
//         arr.push({
//             no: max - i + 1,
//             userName: Math.floor(Math.random() * 2) ? "은하수" : "김견우",
//             qstType: "사이트 이용 오류/개선",
//             userContents: "결제에서 단계가 안 넘어가요",
//             qstDate: "2022.02.28 12:24:22",
//             ansDate: "2022.02.28 12:24:22",
//             adminUser: "김철수 사원",
//             isReply: Math.floor(Math.random() * 2) ? 0 : 1,
//             viewMore: i.toString(),
//         });
//     }
//     return arr;
// };

// const initialSupportData = random(500);
interface ParametersType {
    page?: number | undefined;
    category?: string;
    resolved?: string; // 문의 상태 (0:미답변, 1:답변)
    [`inquiry_dt[start]`]?: string;
    [`inquiry_dt[end]`]?: string;
    [`resolved_dt[start]`]?: string;
    [`resolved_dt[end]`]?: string;
    device?: "PC" | "APP" | "all";
}
const fetchData = async (params: ParametersType) => {
    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");
    const url = `${axiosPath.chats}?${queryString}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const baseData: BaseSupportListType[] = responseData.data.list;
    return baseData.length
        ? {
              result: parse(baseData),
              total: responseData.data.totalCnt,
          }
        : { result: [], total: 0 };
};

const parse = (originData: BaseSupportListType[]) => {
    const newData: SupportListType[] = [];
    originData.forEach((item: BaseSupportListType) => {
        const {
            conversation_id,
            resolved,
            category,
            contents,
            uname,
            created_at,
            resolved_at,
            resolved_admin,
            no,
            title,
            user_no,
            device,
        } = item;
        newData.push({
            no: no, // 순서
            userName: uname, // 문의자
            qstType: category, // 문의유형
            userContents: contents, //문의내용
            qstDate: created_at, // 문의일시
            ansDate: resolved_at === null ? "" : resolved_at, // 답변일시
            adminUser: resolved_admin === null ? "" : resolved_admin, // 답변자
            isReply: resolved, // 0: 미답변, 1: 답변
            viewMore: conversation_id,
            title,
            userNo: user_no,
            device: device,
        });
    });

    return newData;
};

const createParams = ({
    page,
    qstType,
    isAnswer,
    qstStartDate,
    qstEndDate,
    ansStartDate,
    ansEndDate,
    device,
}: SupportFilterType): ParametersType => {
    return {
        page: +page,
        category: qstType?.join(","),
        resolved: isAnswer ? (isAnswer === 2 ? 0 : 1).toString() : undefined,
        "inquiry_dt[start]": !qstStartDate
            ? undefined
            : qstStartDate.replaceAll(".", "-"),
        "inquiry_dt[end]": !qstEndDate
            ? undefined
            : `${qstEndDate} ${"23:59:59"}`.replaceAll(".", "-"),
        "resolved_dt[start]": !ansStartDate
            ? undefined
            : ansStartDate.replaceAll(".", "-"),
        "resolved_dt[end]": !ansEndDate
            ? undefined
            : `${ansEndDate} ${"23:59:59"}`.replaceAll(".", "-"),
        device,
    };
};

const getQueryString = (searchParams: SupportFilterType) => {
    let queryParams: any = {};
    if (Object.values(searchParams).length !== 0) {
        const params = searchParams.searchKey
            ? {
                  ...createParams(searchParams),
                  [searchParams.searchKey]: searchParams.searchValue,
              }
            : createParams(searchParams);
        params.device === "all" && delete params.device;
        Object.keys(params).forEach((value: any) => {
            let name = value as keyof ParametersType;
            // console.log("name", name);
            if (params[name]) {
                queryParams[name] = params[name];
            }
        });
    }

    return queryParams;
};

const useSupportData = (filters: SupportFilterType) => {
    const queryParams = getQueryString(filters);

    return useQuery<ResultSupportListType, Error>(
        [queryKeys.SUPPORT_DATA, queryParams],
        () => fetchData(queryParams),
        {
            // initialData: initialSupportData,
            enabled: false,
            cacheTime: 0,
        },
    );
};

const useCacheSupportData = (query: any) => {
    if (typeof query.qstType === "string") {
        query.qstType = query.qstType.split(",");
    }
    if (typeof query.isAnswer === "string") {
        query.isAnswer = +query.isAnswer;
    }
    const params = getQueryString(query);
    return getQueryData([queryKeys.SUPPORT_DATA, params]);
};

const TODAY = ConvertDate(new Date().getTime());

const initFilters: SupportFilterType = {
    qstStartDate: "",
    qstEndDate: "",
    ansStartDate: "",
    ansEndDate: "",
    qstError: false,
    ansError: false,
    qstType: [],
    page: 1,
    device: "all",
};

export {
    useSupportData,
    initFilters,
    getQueryString,
    createParams,
    useCacheSupportData,
};
