import React from "react";
import { IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";

export const couponDetailColumns = (navigate: any) => [
    {
        Header: "쿠폰 고유번호",
        accessor: "coupon_no",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "쿠폰 코드번호",
        accessor: "code",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "회원 이메일",
        accessor: "email",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "발행 일시",
        accessor: "created_at",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    fontSize: "13px",
                    textAlign: "center",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "등록 일시",
        accessor: "reged_date",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        fontSize: "13px",
                        textAlign: "center",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "만료 일시",
        accessor: "expiry_date",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        fontSize: "13px",
                        textAlign: "center",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "사용 잔여일",
        accessor: "rest_day",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value === "-" ? value : `D - ${value ?? 0}`}
            </div>
        ),
    },
];
