import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input, Title } from "@src/components";

function FavoriteInfoContainer({ initData }: any) {
    const [favorite, setFavorite] = useState<{
        location?: string;
        usage?: string;
    }>({
        location: "",
        usage: "",
    });
    useEffect(() => {
        setFavorite(initData);
    }, [initData]);
    return (
        <div>
            <Title title="관심 정보" size="small" />
            <ul css={infoBox}>
                <li>
                    <Input
                        value={favorite?.location || ""}
                        name="location"
                        label="지역"
                        placeholder="없음"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>
                <li>
                    <Input
                        value={favorite?.usage || ""}
                        name="usage"
                        label="용도"
                        placeholder="없음"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>
            </ul>
        </div>
    );
}

const infoBox = css`
    width: 60%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 60px;

    li {
        width: 45%;
        display: flex;
        align-items: center;

        span:nth-of-type(1) {
            ${Common.textStyle.text1_R13}
            display: inline-block;
            width: 100px;
            color: ${Common.colors.gray500};
        }
        span:nth-of-type(2) {
            ${Common.textStyle.title6_R14}
            color: ${Common.colors.black};
        }
        &:nth-of-type(odd) {
            margin-right: 40px;
        }
    }
    input:disabled {
        background-color: ${Common.colors.white};
        color: ${Common.colors.black};
        border-bottom: none;
    }
    .emailItem {
        position: relative;
        p {
            position: absolute;
            left: 102px;
            bottom: -25px;
        }
    }
`;

export default FavoriteInfoContainer;
