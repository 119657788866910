import React, { useState, useEffect } from "react";
import BannerForm from "./BannerForm";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, Title, ConfirmModal } from "@src/components";
import { useParams, useNavigate } from "react-router-dom";
import {
    useBannerDetailData,
    useUpdateBannerData,
} from "@src/hooks/queries/useBannerDetail";
import { UpdateBannerType } from "./types";
import { useBannerList } from "@src/hooks/queries/useBanner";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";

function BannerUpdate() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [childData, setChildData] = useState<UpdateBannerType>();
    const type = useSelector((state: any) => state.bannerType);
    const [initData, setInitData] = useState<UpdateBannerType>({
        order: 0,
        imageFileCount: 0,
        bannerImage: "",
        startDate: new Date().setHours(0, 0, 0),
        endDate: new Date().setHours(0, 0, 0),
        bannerView: 0,
        bannerLink: "",
        allFiles: [],
        edit: false,
        testers: "",
    });
    const [disabled, setDisabled] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<any>("");
    const [isModalShow, setIsModalShow] = useState(false);
    const { refetch: listRefetch } = useBannerList({
        page: 1,
        limit: 10,
        value: type,
    });
    const {
        isLoading,
        data,
        isError,
        refetch: detailRefetch,
    } = useBannerDetailData(id || "0", true, type);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
        }
    }, [data]);

    const isNotEmpty = (value: string | null) => {
        return value !== "";
    };

    const imageEmptyCheck = (imageFileCount: any, bannerImage: any) => {
        return imageFileCount !== 0 && isNotEmpty(bannerImage);
    };

    useEffect(() => {
        // const isImageEmpty = imageEmptyCheck(
        //     childData?.imageFileCount,
        //     childData?.bannerImage,
        // );
        // if (isImageEmpty) {
        //     setPageErrorMessage("");
        //     setDisabled(!childData?.edit);
        // } else {
        //     setPageErrorMessage("배너 이미지를 넣어주세요");
        //     setDisabled(true);
        // }
    }, [childData]);

    const { mutate: updateMutate } = useUpdateBannerData(id);

    const handleSubmit = () => {
        if (childData) {
            // let newData = {
            //     order: 0,
            //     bannerImage: "",
            //     bannerLink: "",
            //     startDate: "",
            //     endDate: "",
            //     bannerView: false,
            //     testers: "",
            // };
            // for (let value of Object.keys(childData)) {
            //     let v = value as keyof UpdateBannerType;
            //     if (initData[v] !== childData[v]) {
            //         // newData = { ...newData, [v]: childData[v] };
            //     }
            // }

            const params = {
                is_open: childData.bannerView,
                ...{ display_order: childData.order },
                ...{ imgurl: childData.bannerImage },
                ...{ linkurl: childData.bannerLink },
                ...{
                    open_at: childData.startDate,
                },
                ...{
                    close_at: childData.endDate,
                },
                ...{
                    testers: childData.testers,
                },
                type,
            };
            dispatch(startLoading());

            updateMutate(params, {
                onSuccess: () => {
                    detailRefetch();
                    listRefetch();
                    navigate("/banners/list");
                },
                onError: (error) => {
                    setIsModalShow((prev) => !prev);
                    setPageErrorMessage(error);
                },
                onSettled: () => {
                    dispatch(endLoading());
                },
            });
        }
    };

    return (
        <div css={layoutDiv}>
            <Title title="배너 수정" />
            <BannerForm
                exposure={"update"}
                initData={initData}
                passChildData={setChildData}
                isUpdate={true}
            />
            <div css={btnDiv}>
                <p css={errorStyle}>{pageErrorMessage}</p>
                <Button
                    color="gd"
                    handleClick={() => setIsModalShow((prev) => !prev)}
                    label="수정"
                    size="medium"
                    isDisabled={disabled}
                />
            </div>
            <ConfirmModal
                children={"정말 수정하시겠습니까?"}
                isModalShow={isModalShow}
                handleOk={handleSubmit}
                handleCancel={() => setIsModalShow((prev) => !prev)}
                buttonText={"수정"}
            ></ConfirmModal>
        </div>
    );
}

const layoutDiv = css`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
`;

const btnDiv = css`
    display: flex;
    justify-content: flex-end;
`;
const errorStyle = css`
    padding-right: 15px;
    color: ${Common.colors.error};
    ${Common.textStyle.title6_R14}
    line-height: 38px;
`;
export default BannerUpdate;
