import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { PageTable, Title, Button, ConfirmModal } from "@src/components";
import { getColumns } from "./AdminListColumns";
import { useNavigate } from "react-router-dom";
import { useAdminList } from "@src/hooks/queries/useAdmin";
import { useDeleteAdmin } from "@src/hooks/queries/useAdminDetail";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { AdminFilterType } from "@src/types/adminType";

function AdminList() {
    const [params, setParams] = useState<AdminFilterType>({
        page: 1,
        limit: 10,
    });
    const [deleteAdmin, setDeleteAdmin] = useState(0);
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const setDelete = (adminNo: number) => {
        setIsDeleteModalShow(true);
        setDeleteAdmin(adminNo);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const columns = getColumns({ navigate, setDelete });
    const [admins, setAdmins] = useState<any>([]);
    const [totalCount, setTotalCount] = useState(0);
    const {
        isError,
        isLoading,
        data = { result: [], total: 0 },
        refetch: listRefetch,
    } = useAdminList(params);
    useEffect(() => {
        if (!isLoading && !isError && data.result) {
            let { result, total } = data;
            setAdmins(result);

            if (totalCount === 0) setTotalCount(total);
        }
    }, [isLoading, isError, data.result]);
    const { mutate: deleteMutate } = useDeleteAdmin();
    const handleDelete = (deleteAdmin: number) => {
        dispatch(startLoading());
        deleteMutate(deleteAdmin, {
            onSuccess: () => {
                listRefetch();
            },
            onError: (error: any) => {},
            onSettled: () => {
                dispatch(endLoading());
                setIsDeleteModalShow(false);
            },
        });
    };
    return (
        <div css={adminListWrap}>
            <Title title="관리자 관리" />
            <div css={buttonWrap}>
                <Button
                    label={"관리자 등록"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={false}
                    handleClick={() => {
                        navigate("/admins/create");
                    }}
                    defaultValue={"관리자 등록"}
                />
            </div>
            <div css={contents}>
                {admins.length !== 0 && (
                    <PageTable
                        columns={columns}
                        data={admins}
                        showCheckbox={false}
                        initialState={{ pageIndex: 0, pageSize: 10 }}
                        FixedHeight={500}
                        showTotalCount={true}
                        totalCount={totalCount}
                        handlePageChange={(index: number) => {
                            setParams((prev) => {
                                return {
                                    ...prev,
                                    page: index + 1,
                                };
                            });
                            listRefetch();
                        }}
                        onSelectedRowsChange={() => {}}
                        forcePageNumber={1}
                    />
                )}
            </div>
            <ConfirmModal
                children={"해당 관리자를 삭제하시겠습니까?"}
                isModalShow={isDeleteModalShow}
                handleOk={() => {
                    handleDelete(deleteAdmin);
                }}
                handleCancel={() => {
                    setIsDeleteModalShow(false);
                }}
                buttonText={"삭제"}
            />
        </div>
    );
}

const adminListWrap = css`
    padding: 40px;
    width: 100%;
    height: 100%;
`;
const contents = css`
    margin-top: 80px;
`;
const buttonWrap = css`
    & > button {
        margin-left: auto;
    }
`;
export default AdminList;
