import { useMutation, useQuery } from "react-query";
import {
    FormNoticeType,
    BaseNoticeType,
    CreateNoticeType,
    UpdateNoticeType,
} from "@src/types/NoticeType";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { asyncCall } from "@src/api/asyncCall";

const fetchData = async (id: number) => {
    const response = await axiosInstance.get(`${axiosPath.boardNotices}/${id}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseNoticeType = responseData.data.data;
    return parse(originData);
};
const parse = (originData: BaseNoticeType): FormNoticeType => {
    const {
        title,
        contents,
        isOpen,
        category,
        createdAt,
        updatedAt,
        displayOrder,
    } = originData;
    return {
        title: title,
        contents: contents.replaceAll(" ", "&nbsp;"),
        view: isOpen ? true : false,
        edit: false,
        category: category,
        displayOrder: displayOrder,
        isOpen: true,
    };
};
const useNoticeDetailData = (id: number, isUpdate: boolean) => {
    return useQuery<FormNoticeType, Error>(
        [queryKeys.NOTICE_DETAIL_DATA, id],
        () => fetchData(id),
        {
            // initialData: initialNoticeData,
            enabled: isUpdate,
        },
    );
};

const useCreateNotice = () => {
    const createNotice = async (params: CreateNoticeType) => {
        let promise: any;
        try {
            promise = await axiosInstance.post(
                `${axiosPath.boardNotices}`,
                params,
            );
        } catch (e) {
            console.log(e);
        }

        return promise;
    };

    return useMutation(createNotice);
};

const useUpdateNotice = (noticeId: string | undefined) => {
    const updateNotice = async (params: UpdateNoticeType) => {
        const promise = await axiosInstance.put(
            `${axiosPath.boardNotices}/${noticeId}`,
            params,
        );
        return promise;
    };
    return useMutation(updateNotice);
};

const useDeleteNotice = () => {
    const deleteNotice = async (id: number) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.board}/notice/${id}`,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(deleteNotice);
};
export {
    useNoticeDetailData,
    useCreateNotice,
    useUpdateNotice,
    useDeleteNotice,
};
