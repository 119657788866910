import { createSlice } from "@reduxjs/toolkit";
import { SaleCaseType } from "@src/types";

const SaleCaseData: Array<SaleCaseType> = [
    {
        id: 0,
        period: "3",
        insuredCasesCount: "3",
        winningBidCount: "",
        winningBidRate: "0",
        winningBidRateAppraisalL: "0",
        winningBidRateMinBidPrice: "0",
    },
    {
        id: 1,
        period: "6",
        insuredCasesCount: "4",
        winningBidCount: "1",
        winningBidRate: "25",
        winningBidRateAppraisalL: "78.58",
        winningBidRateMinBidPrice: "112.26",
    },
    {
        id: 2,
        period: "12",
        insuredCasesCount: "28",
        winningBidCount: "3",
        winningBidRate: "11",
        winningBidRateAppraisalL: "87.53",
        winningBidRateMinBidPrice: "116.27",
    },
];

const initialState = SaleCaseData;

export const saleCaseSlice = createSlice({
    name: "caseAnalysis",
    initialState,
    reducers: {
        createSaleCase: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateSaleCase: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createSaleCase, updateSaleCase } = saleCaseSlice.actions;

export default saleCaseSlice.reducer;
