import { INVALID_VALUE } from "@src/constants/InvalidValue";
import React from "react";
import { ConvertDateTime } from "@src/utils/dateUtil";

export const getPayBackSubsListColumns = (navigate: any) => [
    {
        Header: "일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "100%",
                    minWidth: "120px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? ConvertDateTime(value) : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "회원번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "이름",
        accessor: "name",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                    onClick={() => {
                        navigate(`/users/detail/${row.values.no}`);
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "내용",
        accessor: "description",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "페이백 대상 수 증감",
        accessor: "count",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "80%",
                        textAlign: "center",
                    }}
                >
                    {value
                        ? value.toString().includes("-")
                            ? value
                            : `+${value}`
                        : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "페이백 대상 수",
        accessor: "accumulatedCount",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
];
