import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Title, EditModal } from "@src/components";
import { ViewCategoryButton } from "./containers";
import { Constants } from "@src/constants/Constants";
import { startLoading, endLoading } from "@src/reducers/loading";
import RenderContainer from "./containers/aAuction/RenderContainer";
import UpdatePopUpModal from "./UpdatePopUpModal";

function ProductAuctionDetail() {
    const { id: productNo } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0); // 페이지 이동 시 스크롤 상단으로 고정
    }, []);
    const dispatch = useDispatch();

    //개별 모달 팝업 에러 발생 시
    const [networkErrorModalShow, setNetworkErrorModalShow] = useState(false);
    const [networkErrorMessage] = useState("");
    const handleErrorBtnClick = () => setNetworkErrorModalShow((prev) => !prev);
    //수정 팝업
    const [isModalShow, setIsModalShow] = useState(false);
    const [container, setContainer] = useState("SUMMARY_INFO");
    const [title, setTitle] = useState("");
    //노출 카테고리
    const defaultViewCategoryList = useSelector(
        (state: any) => state.productViewCategory,
    );
    const [defaultViewCategory, setDefaultViewCategory] = useState<{
        [key: string]: {};
    }>({});
    useEffect(() => {
        if (defaultViewCategoryList) {
            let result = {};
            for (let i = 0; i < defaultViewCategoryList.length; i++) {
                result = {
                    ...result,
                    [defaultViewCategoryList[i]]: true,
                };
            }
            setDefaultViewCategory({
                ...defaultViewCategory,
                ...result,
            });
            setUpdateViewCategory(defaultViewCategoryList);
        }
    }, [defaultViewCategoryList]);
    const [updateViewCategory, setUpdateViewCategory] = useState<any>([]);
    const handleToggleOfViewCategory = (name: string, checked: boolean) => {
        if (checked) {
            setUpdateViewCategory([...updateViewCategory, name]);
        } else {
            const filterCategory = updateViewCategory.filter(
                (v: any) => v !== name,
            );
            setUpdateViewCategory([...filterCategory]);
        }
    };

    const handleClick = (container: any, title: string) => {
        setIsModalShow(!isModalShow);
        setContainer(container);
        setTitle(title);
    };

    return (
        <div css={formDiv}>
            <Title title="매물 상세" />
            <EditModal
                isModalShow={networkErrorModalShow}
                buttonText="확인"
                handleBtnClick={() => {}}
                handleOpenModal={handleErrorBtnClick}
                size="medium"
                title="오류 발생"
            >
                <>{networkErrorMessage}</>
            </EditModal>
            <UpdatePopUpModal
                isModalShow={isModalShow}
                container={container}
                title={title}
                productNo={productNo}
                handleModalShow={(value: boolean) => setIsModalShow(value)}
            />
            <div className="notEdit">
                <RenderContainer
                    title={Constants.STANDARD_INFORMATION}
                    componentName={"STANDARD_INFO"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div>
                <RenderContainer
                    title={Constants.SUMMARY_INFO}
                    componentName={"SUMMARY_INFO"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div className="notEdit">
                <RenderContainer
                    title={Constants.DEFAULT_INFO}
                    componentName={"DEFAULT_INFO"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div>
                <RenderContainer
                    title={Constants.SKIP_BID_HISTORY}
                    componentName={"SKIP_BID_HISTORY"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div className="notEdit">
                <RenderContainer
                    title={Constants.SALES_STATUS}
                    componentName={"SALES_STATUS"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div className="notEdit">
                <RenderContainer
                    title={Constants.CASE_INFO}
                    componentName={"CASE_INFO"}
                    options={{
                        isEdit: false,
                        isUpdating: isModalShow,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div className="notEdit">
                <RenderContainer
                    title={Constants.RIGHTS_ANALYSIS}
                    componentName={"RIGHTS_ANALYSIS"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            {/* 실거래가 어드민에서 제외 */}
            {/* <div className="notEdit">
                <RenderContainer
                    title={Constants.REAL_PRICE}
                    componentName={"REAL_PRICE"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div> */}
            <div className="notEdit">
                <RenderContainer
                    title={Constants.EXPECTED_RETURN}
                    componentName={"EXPECTED_RETURN"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div className="notEdit">
                <RenderContainer
                    title={Constants.ITEM_INFO}
                    componentName={"ITEM_INFO"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <div className="notEdit">
                <RenderContainer
                    title={Constants.CASE_ANALYSIS}
                    componentName={"CASE_ANALYSIS"}
                    options={{
                        isEdit: false,
                        isUpdating: false,
                        productNo,
                    }}
                    defaultViewCategory={defaultViewCategory}
                    handleClick={handleClick}
                    handleToggleOfViewCategory={handleToggleOfViewCategory}
                />
            </div>
            <ViewCategoryButton
                updateViewCategory={updateViewCategory}
                dispatch={dispatch}
            />
        </div>
    );
}

ProductAuctionDetail.propTypes = {
    defaultViewCategoryList: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
};

const formDiv = css`
    padding: 40px;
    width: 100%;
    & > div:first-of-type {
        margin-bottom: 40px;
    }
    & > div {
        width: 100%;
        min-width: 1200px;
        max-width: 1800px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    button {
        margin-left: 20px;
        margin-right: auto;
        & + span {
            ${Common.textStyle.title6_R14}
            color: ${Common.colors.purple500};
            padding-right: 10px;
        }
    }
    .disabledEdit button {
        opacity: 0;
        visibility: hidden;
    }
    .container {
        width: 100%;
        height: fit-content;
        min-height: 300px;
        margin-top: 20px;
        margin-bottom: 40px;
        border: 1px solid ${Common.colors.gray500};
        padding: 30px;
    }
    .shadow {
        height: 1000vh;
    }
    .notEdit {
        & > button {
            opacity: 0;
            visibility: hidden;
        }
    }
    & > button:last-of-type {
        float: right;
    }
`;
export default ProductAuctionDetail;
