import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { makeQueryString } from "@src/utils/queryStringUtil";
import {
    BaseRecentViewList,
    ReadRecentViewDto,
} from "@src/interfaces/User.interface";
import { format } from "date-fns";

const fetchData = async (params: ReadRecentViewDto) => {
    const queryString = makeQueryString(params);
    const response = await axiosInstance.get(
        `${axiosPath.recentviews}?${queryString}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseRecentViewList[] = responseData.data.list;
    return parse(originData)[0];
};

const parse = (originData: BaseRecentViewList[]) => {
    const newData = [];
    newData.push(
        originData.map((item: BaseRecentViewList) => {
            const { item_id, favorite, opened_at, next_it } = item;
            let openedAt = opened_at.replaceAll("T", " ");
            let i = openedAt.indexOf(".");
            let parsedDate = format(new Date(opened_at), "yyyy.MM.dd hh:mm:ss");

            return {
                date: parsedDate,
                productNo: item_id,
                nextItem: next_it,
                favorite: Number(favorite) === 1,
                viewMore: item_id,
            };
        }),
    );
    return newData;
};
const useUserRecentViewHistory = (
    params: ReadRecentViewDto,
    isSearch: boolean,
) => {
    return useQuery(
        [queryKeys.USER_RECENT_VIEW_HISTORY, params.user_no],
        () => fetchData(params),
        {
            // initialData: initData,
            enabled: isSearch,
        },
    );
};
export { useUserRecentViewHistory };
