import { createSlice } from "@reduxjs/toolkit";
import { RentInfoPublicType } from "@src/types";

const rentInfoPublicData: Array<RentInfoPublicType> = [
    {
        id: 0,
        rentType: "임차인",
        name: "이순구",
        deposit: "115000000",
        rentPrice: "750000",
        conversionDeposit: "115000000",
        fixedDate: "2015.05.04",
        moveInDate: "2015.05.04",
    },
    {
        id: 1,
        rentType: "임차인",
        name: "",
        deposit: "115000000",
        rentPrice: "750000",
        conversionDeposit: "115000000",
        fixedDate: "2015.05.04",
        moveInDate: "2015.05.04",
    },
];

const initialState = rentInfoPublicData;

export const rentInfoPublicSlice = createSlice({
    name: "rentInfoPublic",
    initialState,
    reducers: {
        createRentInfoPublic: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateRentInfoPublic: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createRentInfoPublic, updateRentInfoPublic } =
    rentInfoPublicSlice.actions;

export default rentInfoPublicSlice.reducer;
