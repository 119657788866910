import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import AWS from "aws-sdk";

function APIVersionCheck() {
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_BACKEND_USER_ACCESS_KEY,
        secretAccessKey:
            process.env.REACT_APP_AWS_BACKEND_USER_ACCESS_SECRET_KEY,
    });
    const [stageApiVersion, setStageApiVersion] = useState([] as any);
    const [apiVersion, setApiVersion] = useState([] as any);
    const [adminVersion, setAdminVersion] = useState([] as any);

    useEffect(() => {
        AWS.config.update({
            region: "ap-northeast-2",
        });

        const ecs = new AWS.ECS();
        ecs.describeServices(
            {
                cluster: "AppApi-Live-Clu",
                services: ["AppApi-Live", "AdminApi-Live"],
            },
            (err, data) => {
                if (err) console.log(err, err.stack);

                if (
                    data.services &&
                    data.services[0].deployments &&
                    data.services[0].deployments[0]
                ) {
                    setApiVersion({
                        name: "LIVE API SERVER",
                        version: data.services[0].taskDefinition
                            ?.toString()
                            .split("AppApi:")[1],
                        date: data.services[0].deployments[0].updatedAt?.toString(),
                        runningCount:
                            data.services[0].deployments[0].runningCount || 0,
                        status: data.services[0].status,
                    });
                }

                if (
                    data.services &&
                    data.services[1].deployments &&
                    data.services[1].deployments[0]
                ) {
                    setAdminVersion({
                        name: "ADMIN API SERVER",
                        version: data.services[1].taskDefinition
                            ?.toString()
                            .split("AdminApi:")[1],
                        date: data.services[1].deployments[0].updatedAt?.toString(),
                        runningCount:
                            data.services[1].deployments[0].runningCount || 0,
                        status: data.services[1].status,
                    });
                }
            },
        );
        ecs.describeServices(
            {
                cluster: "AppApi-Stg-Clu",
                services: ["AppApi-Stage"],
            },
            (err, data) => {
                if (err) console.log(err, err.stack);

                if (
                    data.services &&
                    data.services[0].deployments &&
                    data.services[0].deployments[0]
                ) {
                    setStageApiVersion({
                        name: "STAGE API SERVER",
                        version: data.services[0].taskDefinition
                            ?.toString()
                            .split("AppApi:")[1],
                        date: data.services[0].deployments[0].updatedAt?.toString(),
                        runningCount:
                            data.services[0].deployments[0].runningCount || 0,
                        status: data.services[0].status,
                    });
                }
            },
        );
    }, []);

    const VersionBox = ({ versionObj }: any) => {
        const onClickHandler = () => {
            switch (versionObj.name) {
                case "STAGE API SERVER":
                    window.open(
                        "https://ap-northeast-2.console.aws.amazon.com/ecs/home?region=ap-northeast-2#/clusters/AppApi-Stg-Clu/services/AppApi-Stage/details",
                        "blank",
                    );
                    break;
                case "LIVE API SERVER":
                    window.open(
                        "https://ap-northeast-2.console.aws.amazon.com/ecs/home?region=ap-northeast-2#/clusters/AppApi-Live-Clu/services/AppApi-Live/details",
                        "blank",
                    );
                    break;
                case "ADMIN API SERVER":
                    window.open(
                        "https://ap-northeast-2.console.aws.amazon.com/ecs/home?region=ap-northeast-2#/clusters/AppApi-Live-Clu/services/AdminApi-Live/details",
                        "blank",
                    );
                    break;
            }
        };

        return (
            <div onClick={onClickHandler}>
                <p>{versionObj.name}</p>
                <div style={{ fontSize: "20px" }}>
                    <small>현재 상태</small>
                    {versionObj.status}
                </div>
                <div>
                    <small>태스크 개수</small>
                    {versionObj.runningCount}
                </div>
                <div>
                    <small>배포 버전</small>
                    {versionObj.version}
                </div>
                <div style={{ fontSize: "20px" }}>
                    <small>배포 일시</small>
                    {versionObj.date &&
                        new Date(new Date(versionObj.date).getTime() + 32400000)
                            .toISOString()
                            .replace("T", " ")
                            .split(".")[0]}
                </div>
            </div>
        );
    };
    return (
        <div css={subChartDiv}>
            <p css={titleP}>API 서버 배포 상태 (ECS Status)</p>
            <div className="mainBox">
                <VersionBox versionObj={stageApiVersion} />
                <VersionBox versionObj={apiVersion} />
                <VersionBox versionObj={adminVersion} />
            </div>
        </div>
    );
}

const subChartDiv = css`
    height: fit-content;
    max-height: 450px;
    canvas {
        height: 330px !important;
    }

    .mainBox {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-weight: bold;

        & > div {
            width: 200px;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
            border-radius: 15px;
            padding: 15px;
            margin: 10px;
            transition: all 0.2s ease;
            cursor: pointer;

            &:nth-child(1) {
                background: rgba(0, 0, 0, 0.7);
                color: #fff;
                opacity: 0.4;
            }
            &:hover {
                scale: 1.05;
            }

            p {
                font-size: 12px;
            }
            div {
                font-size: 30px;
                font-weight: bold;
                margin: 20px 0;
                padding-bottom: 15px;
                border-bottom: 1px solid #ccc;

                &:nth-child(5) {
                    border: none;
                    padding-bottom: 0;
                }
                & > small {
                    display: block;
                    font-size: 14px;
                    font-weight: lighter;
                    margin-bottom: 10px;
                }
            }
            span {
            }
        }
    }
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
`;

export default APIVersionCheck;
