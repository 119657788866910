import React, { useEffect, useRef } from "react";
// Toast 에디터
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";
import { getImageURLToS3, uploadImageToS3 } from "@src/api/uploadImage";

interface IEditorBox {
    handleChange: Function;
    defaultText?: string;
}
export default function EditorBox({
    handleChange,
    defaultText = "",
}: IEditorBox) {
    const editorRef = useRef<any>();
    useEffect(() => {
        defaultText && editorRef.current?.getInstance().setHTML(defaultText);
    }, [defaultText]);

    type HookCallback = (url: string, text?: string) => void;
    const addImageBlobHook = (file: File | Blob, callback: HookCallback) => {
        const isFileType = file instanceof File;
        if (isFileType) {
            isFileType &&
                getImageURLToS3(file).then((response) => {
                    uploadImageToS3(response).then((result) => {
                        result && callback(result, "by Missgo");
                    });
                });
        }
    };
    return (
        <div>
            <Editor
                ref={editorRef}
                placeholder="내용을 입력해주세요."
                height="400px" // 에디터 창 높이
                initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
                toolbarItems={[
                    // 툴바 옵션 설정
                    ["heading", "bold", "italic", "strike"],
                    ["hr", "quote"],
                    ["ul", "ol", "task", "indent", "outdent"],
                    ["link", "image"],
                ]}
                onChange={() => {
                    handleChange(editorRef.current?.getInstance().getHTML());
                }}
                hideModeSwitch={true}
                hooks={{ addImageBlobHook }}
            ></Editor>
        </div>
    );
}
