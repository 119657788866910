import styled from "@emotion/styled";
import {
    Button,
    ConfirmModal,
    EditModal,
    Input,
    SearchModal,
} from "@src/components";
import {
    useGetProducts,
    useGetPushCode,
} from "@src/hooks/queries/useCommodityProvision";
import comment from "@src/reducers/comment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ProvisionProduct from "./ProvisionProduct";
import { ProductDataType } from "./ProvisionUpdate";
export interface ProductType {
    no: number;
    type: string;
    saleType: number;
    code: string;
    name: string;
    price: number;
    discountedPrice: number;
    subs_group: string;
    subs_group_name: string;
    price_discount_display1: string;
    price_discount_display2: string;
    description: string;
    created_at: string;
    updated_at: string;
}

interface newItem {
    situationCode: string;
    comments: string;
}

interface ProvisionFormType {
    type: string;
    productList: ProductType[];
    setProductList: Dispatch<SetStateAction<ProductType[]>>;
    productData: ProductDataType;
    setProductData: Dispatch<SetStateAction<ProductDataType>>;
    isChange?: boolean;
    tryCreateProvision?: () => void;
    tryUpdateProvision?: () => void;
}

const ProvisionForm = ({
    type,
    productList,
    setProductList,
    productData,
    setProductData,
    isChange,
    tryCreateProvision,
    tryUpdateProvision,
}: ProvisionFormType) => {
    const productInitialState: ProductType = {
        no: 0,
        type: "",
        code: "",
        name: "",
        saleType: 0,
        price: 0,
        discountedPrice: 0,
        subs_group: "",
        subs_group_name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    };
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [isSearchModalShow, setIsSearchModalShow] = useState<boolean>(false);

    const [selectedItem, setSelectedItem] = useState<any>({
        productInitialState,
    });
    const handleGetProducts = async () => {
        const response = await useGetProducts();
        setProductList(response.data);
        setIsModalShow(true);
    };

    const { comments, name } = productData;

    const [situationList, setSituationList] = useState<newItem[]>([]);

    const [isDisable, setIsDisable] = useState<boolean>(false);

    const [isCheckModalShow, setIsCheckModalShow] = useState<boolean>(false);
    // const [searchParams, setSearchParams] = useState({ q: "" });

    const handleSearch = async (value?: string) => {
        value === undefined && (value = "");
        const searchParams = { q: value };
        const response = await useGetPushCode(searchParams);
        const data = response.data.data.list;
        const newData: newItem[] = data.map((item: any) => ({
            situationCode: item.situation_code,
            comments: item.comments,
        }));
        setSituationList(newData);
    };

    useEffect(() => {
        if (comments && name) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [comments, name]);

    return (
        <>
            <StyledWrapper>
                <div>
                    <div className="itemRow">
                        <StyledInputTitle>
                            <span>*</span>상품 선택
                        </StyledInputTitle>
                        {/* <StyledTextDiv>상품을 조회 해주세요</StyledTextDiv> */}
                        <StyledTextDiv text={name} type={type}>
                            {name ? name : "상품을 조회 해주세요"}
                        </StyledTextDiv>
                        {type !== "view" && (
                            <Button
                                color="gd"
                                handleClick={() => {
                                    setIsModalShow(true);
                                    handleGetProducts();
                                }}
                                label="조회"
                                size="small"
                            />
                        )}
                    </div>
                    <Description>
                        *구독 상품의 경우 해당 구독 상품을 구독 중인 회원에게는
                        상품이 지급되지 않습니다.
                    </Description>
                </div>
                {/*2023.06.01 push전송기능 비활성화 처리 추후 변동예정*/}
                <div className="itemRow" style={{ display: "none" }}>
                    <StyledInputTitle>
                        <span>PUSH</span>전송
                    </StyledInputTitle>
                    {/* <StyledTextDiv text={postbox_situation_code} type={type}>
                        {postbox_situation_code
                            ? postbox_situation_code
                            : type === "view"
                            ? "-"
                            : "검색버튼을 클릭해주세요."}
                    </StyledTextDiv> */}
                    <StyledTextDiv text={""} type={type}>
                        {type === "view" ? "-" : "검색버튼을 클릭해주세요."}
                    </StyledTextDiv>
                    {type !== "view" && (
                        <Button
                            color="gd"
                            handleClick={() => {
                                setIsSearchModalShow(true);
                            }}
                            label="검색"
                            size="small"
                            styles={{ position: "absolute", right: 60 }}
                        />
                    )}
                </div>
                <div className="itemRow">
                    <StyledInputTitle>
                        <span>*</span>사유
                    </StyledInputTitle>
                    {type === "view" ? (
                        <StyledTextDiv text={comments} type={type}>
                            {comments ? comments : "입력"}
                        </StyledTextDiv>
                    ) : (
                        <Input
                            errorMessage=""
                            handleChange={(e) => {
                                // setReason(e.target.value);
                                setProductData({
                                    ...productData,
                                    comments: e.target.value,
                                });
                            }}
                            placeholder="입력"
                            size="medium"
                            type="text"
                            value={comments}
                        />
                    )}
                </div>
                {type === "create" && (
                    <div style={{ width: "100%", position: "relative" }}>
                        <Button
                            label="등록"
                            color="gd"
                            handleClick={() => {
                                setIsCheckModalShow(true);
                            }}
                            size="small"
                            isDisabled={!isDisable}
                            styles={{
                                width: 70,
                                position: "absolute",
                                right: 0,
                            }}
                        />
                    </div>
                )}
                {type === "update" && (
                    <div style={{ width: "100%", position: "relative" }}>
                        <Button
                            label="수정"
                            color="gd"
                            handleClick={() => {
                                setIsCheckModalShow(true);
                            }}
                            size="small"
                            isDisabled={!isChange}
                            styles={{
                                width: 70,
                                position: "absolute",
                                right: 0,
                            }}
                        />
                    </div>
                )}

                <EditModal
                    buttonText="OK"
                    handleBtnClick={() => {
                        setProductData({
                            ...productData,
                            // type: selectedItem.type,
                            name: selectedItem.name,
                            // code: selectedItem.code,
                            // subs_group: selectedItem.subs_group,
                            // subs_group_name: selectedItem.subs_group_name,
                            productNo: selectedItem.no,
                        });
                        // setProduct(selectedItem.name);
                        // setProductCode(selectedItem.code);
                    }}
                    handleOpenModal={() => {
                        setIsModalShow(false);
                    }}
                    size="big"
                    title="상품 조회"
                    isModalShow={isModalShow}
                >
                    <div>
                        <ProvisionProduct
                            ProductList={productList}
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                        />
                    </div>
                </EditModal>
                <SearchModal
                    handleOpenModal={() => {
                        setIsSearchModalShow(false);
                    }}
                    handleSave={(value: any) => {
                        // setProductData({
                        //     ...productData,
                        //     postbox_situation_code: value.situationCode,
                        // });
                        // setSituationCode(value.situationCode);
                        // setIsSearchModalShow(false);
                    }}
                    handleSearch={handleSearch}
                    headers={["상황 코드", "발생 상황"]}
                    list={situationList}
                    placeholder="발생 상황 입력"
                    title="상황 코드"
                    isModalShow={isSearchModalShow}
                    isLoading={false}
                ></SearchModal>
                <ConfirmModal
                    buttonText="확인"
                    handleCancel={() => {
                        setIsCheckModalShow(false);
                    }}
                    handleOk={() => {
                        type === "create" &&
                            tryCreateProvision &&
                            tryCreateProvision();
                        type === "update" &&
                            tryUpdateProvision &&
                            tryUpdateProvision();
                        setIsCheckModalShow(false);
                    }}
                    isModalShow={isCheckModalShow}
                >
                    <>
                        {type === "create" && "등록 하시겠습니까?"}
                        {type === "update" && "수정 하시겠습니까?"}
                    </>
                </ConfirmModal>
            </StyledWrapper>
        </>
    );
};

export default ProvisionForm;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: fit-content;
    & > .itemRow {
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
    }
    & > div {
        & > .itemRow {
            margin-bottom: 5px;
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
        }
    }
`;

const StyledInputTitle = styled.div`
    font-weight: 700;
    font-size: 15px;
    color: #222222;
    width: 80px;
    margin-right: 30px;
    & > span {
        color: #f45b5b;
        margin-right: 3px;
    }
`;

const StyledTextDiv = styled.div<{ text: string; type: string }>`
    height: 48px;
    outline: none;
    background-color: #fff;
    white-space: nowrap;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    text-indent: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    width: 550px;
    border: ${(props) =>
        props.type === "view" ? "none" : "1.5px solid transparent"};
    border-bottom-color: #e3e3e6;
    display: flex;
    align-items: center;
    color: ${(props) => (props.text ? "#222222" : "#9D9FA5")};
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 12px;
    color: #ff7777;
    margin-bottom: 30px;
    margin-left: 125px;
`;
