import { css } from "@emotion/react";
import { Button, ConfirmModal, EditModal, IconButton } from "@src/components";
import UploadImg from "@src/assets/ico_upload.png";
import React, { useEffect, useState } from "react";
import FileUploadView from "@src/components/FileUploadView";
import { useCampusResourceSaver } from "@src/hooks/queries/useCampus";

interface Props {
    open: boolean;
    onCloseView: () => void;
    data?: any;
    id?: string;
}

const LectureDataManagementModal: React.FC<Props> = ({
    open,
    onCloseView,
    data,
    id,
}) => {
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);
    const [files, setFiles] = useState<any>([]);

    const dataSaver = useCampusResourceSaver(() => {
        setIsShowConfirmModal(false);
        onCloseView();
    });

    const onSaveResources = () => {
        const newResources = [...files].map((r) => ({ ...r, no: undefined }));
        const param = { resources: newResources, no: id };
        dataSaver.mutate(param);
    };

    useEffect(() => {
        data?.length && setFiles(data);
    }, [data]);

    return (
        <>
            <EditModal
                buttonText="저장"
                handleBtnClick={() => setIsShowConfirmModal(true)}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="강의자료 관리"
                isModalShow={open}
                checkCloseModal={true}
                // isDisabled={!files.length}
            >
                <>
                    <p css={titleStyle}>자료 업로드</p>
                    <div css={rootStyle}>
                        <div>
                            {/* <div css={uploadStyle}>
                                <img src={UploadImg} alt="" />
                                <p>
                                    이 곳에 파일을
                                    <br />
                                    드래그하세요
                                    <br />
                                    (안되니 하지마세요)
                                </p>
                            </div> */}
                            <FileUploadView
                                bucket="missgo-campus"
                                onChangeFile={(v) => {
                                    const newIndex = files.length;
                                    const newFiles = [...files];
                                    newFiles[newIndex] = {
                                        name: v.name,
                                        pdfUrl: v.path,
                                        pdfSize: v.size,
                                    };
                                    setFiles(newFiles);
                                }}
                                style="button"
                                possible="pdf"
                                // multi={true}
                            />
                        </div>
                        <div css={dataStyle}>
                            <div css={tableRootStyle}>
                                <table>
                                    <colgroup>
                                        <col width="150px" />
                                        <col width="150px" />
                                        <col width="380px" />
                                        <col width="50px" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>파일 이름</th>
                                            <th>파일 크기</th>
                                            <th>파일 URL</th>
                                            <th>삭제</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {files?.map(
                                            (resource: any, idx: number) => (
                                                <tr key={resource.name}>
                                                    <td>{resource.name}</td>
                                                    <td>
                                                        {resource.pdfSize /
                                                            1000 >
                                                        100
                                                            ? `${(
                                                                  resource.pdfSize /
                                                                  1000000
                                                              ).toFixed(2)}MB`
                                                            : `${(
                                                                  resource.pdfSize /
                                                                  1000
                                                              ).toFixed(2)}KB`}
                                                    </td>
                                                    <td
                                                        css={{
                                                            whiteSpace: "pre",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            maxWidth: "400px",
                                                        }}
                                                    >
                                                        <a
                                                            href={
                                                                resource.pdfUrl
                                                            }
                                                        >
                                                            {resource.pdfUrl}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                            type="trash"
                                                            handleClick={() => {
                                                                const newFiles =
                                                                    [
                                                                        ...files,
                                                                    ].filter(
                                                                        (
                                                                            f,
                                                                            i,
                                                                        ) =>
                                                                            i !==
                                                                            idx,
                                                                    );
                                                                setFiles(
                                                                    newFiles,
                                                                );
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => setIsShowConfirmModal(false)}
                handleOk={() => onSaveResources()}
            >
                저장하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default LectureDataManagementModal;

const rootStyle = css`
    display: flex;
    align-items: center;
`;

const titleStyle = css`
    font-size: 1rem;
    font-weight: 500;
    width: 10rem;
    margin-bottom: 10px;
`;

const uploadStyle = css`
    border: 1.5px dashed #8b8b8b;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 12vh;
    min-height: 120px;
    width: 200px;
    p {
        font-weight: 400;
        font-size: 0.85rem;
        margin-top: 0.85rem;
    }
`;

const dataStyle = css`
    border: 1px solid #8b8b8b;
    margin-left: 20px;
    border-radius: 4px;
    height: 14vh;
    min-height: 150px;
    width: 100%;
    overflow: auto;
`;

const tableRootStyle = css`
    width: 100%;
    table {
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 10px;
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
            white-space: nowrap;
            word-break: keep-all;
            text-overflow: ellipsis;
        }
    }
`;
