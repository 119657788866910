import React, { useEffect, useState } from "react";
import { Button, PageTable, Title, ViewMoreTable } from "@src/components";
import { css } from "@emotion/react";
import CommodityFilter, { CommodityFilterType } from "./CommodityFilter";
import { initFilters } from "@src/hooks/queries/useCommodity";
import { getColumns } from "./CommodityListColumns";
import { makeQueryString } from "@src/utils/queryStringUtil";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const CommodityMamagement = () => {
    const pagesInitialState = {
        pageIndex: 0,
        pageSize: 10,
    };
    const navigate = useNavigate();
    const columns = getColumns(navigate);
    const [pages, setPages] = useState(pagesInitialState);
    const [clear, setClear] = useState(false);
    const [option, setOption] = useState<CommodityFilterType>(initFilters);
    const [params, setParams] = useState<CommodityFilterType>(initFilters);
    const [list, setList] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [totalCount, setTotalCount] = useState<number>(0);
    const copyQuery: CommodityFilterType = Object.fromEntries(searchParams);
    const handleSubmit = (data: CommodityFilterType) => {
        setOption(data);
        setClear(false);
    };
    const location = useLocation();
    const isChangeUrl: any = location.state;
    const updateURL = (value: CommodityFilterType) => {
        const queryString = makeQueryString(value);
        setSearchParams(queryString);
    };

    const handleClearClick = () => {
        setOption(initFilters);
        setClear(true);
        updateURL(initFilters);
        setPages({
            pageIndex: 0,
            pageSize: 10,
        });
    };

    return (
        <div css={detailStyle}>
            <Title title="상품 관리" />
            <div className="btnDiv">
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/commodity/create");
                    }}
                    label="신규 등록"
                    size="medium"
                />
            </div>
            <CommodityFilter
                clear={clear}
                handleSubmit={handleSubmit}
                setClear={setClear}
                option={option}
                setList={setList}
                setTotalCount={setTotalCount}
                setParams={setParams}
                params={params}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                copyQuery={copyQuery}
                handleClearClick={handleClearClick}
                isChangeUrl={isChangeUrl}
            />
            <PageTable
                FixedHeight={500}
                columns={columns}
                data={list}
                handlePageChange={(index: number) => {
                    const page = index + 1;
                    const limit = 10;
                    setParams(() => {
                        let newParams = { ...copyQuery, page, limit };
                        updateURL(newParams);
                        return newParams;
                    });
                }}
                initialState={pages}
                totalCount={totalCount}
            />
        </div>
    );
};

export default CommodityMamagement;
const detailStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .btnDiv {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
`;
