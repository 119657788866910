import styled from "@emotion/styled";
import { Button, Checkbox, DropDown, RangeCalendar } from "@src/components";
import { PushListDto } from "@src/interfaces/Push.interface";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ConvertDate, ConvertDateLine, dateFormat } from "@src/utils/dateUtil";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";
import { useDispatch } from "react-redux";
import push from "@src/reducers/push";
import { createPush, updatePush } from "@src/reducers/push";
interface PushListFilterProps {
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<any | unknown>>;
    filter: PushListDto;
    setFilter: Dispatch<SetStateAction<PushListDto>>;
    initParams: any;
}

const PushFilter = ({
    refetch,
    filter,
    setFilter,
    initParams,
}: PushListFilterProps) => {
    const dispatch = useDispatch();
    const handleCheckBoxChange = (
        name: string,
        checked: boolean,
        checkList: any,
    ) => {
        let setCheckedList = {
            ...checkList,
            [name]: checked,
        };

        const statusType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedList, statusType };
    };

    const initToTypesChecked = {
        user_no: false,
        group: false,
    };
    const initStatus = {
        100: false,
        202: false,
        300: false,
        400: false,
        500: false,
        402: false,
    };
    const initCategories = {
        0: false,
        10: false,
        100: false,
        1000: false,
    };

    const [isStatusChecked, setIsStatusChecked] = useState(initStatus);
    const [statusesState, setStatusesState] = useState<string[]>([]);

    const [isToTypesChecked, setIsToTypesChecked] =
        useState(initToTypesChecked);
    const [toTypes, setToTypes] = useState<string[]>([]);

    const [isCategoriesChecked, setIsCategoriesChecked] =
        useState(initCategories);
    const [categories, setCategories] = useState<string[]>([]);

    const today = new Date().getTime();
    const [startDay, setStartDay] = useState<any>();
    const [endDay, setEndDay] = useState<any>();
    const [orderColumn, setOrderColumn] = useState<string>(
        "common.reservationAt",
    );
    const [orderType, setOrderType] = useState<string>("DESC");
    const [searchText, setSearchText] = useState<string>("");

    const date = new Date();

    const year = date.getFullYear(); // 년
    const month = date.getMonth(); // 월
    const day = date.getDate(); // 일

    const handleChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
        text: string | undefined,
        value: any,
    ) => {
        const splitArr = value?.split("-");
        setOrderColumn(splitArr[0]);
        setOrderType(splitArr[1]);
        setFilter((prev: any) => ({
            ...prev,
            orderColumn: orderColumn,
            orderType: orderType,
        }));
    };

    const handleSearch = () => {
        setFilter({
            page: 1,
            limit: 10,
            statuses: statusesState,
            toTypes: toTypes,
            categories: categories,
            orderColumn: orderColumn,
            orderType: orderType,
            sentStartDate: startDay,
            sentEndDate: endDay,
            searchText: searchText,
        });
    };

    // useEffect(() => {
    //     dispatch(updatePush(filter));
    // }, [filter]);

    const handleClear = () => {
        setIsStatusChecked(initStatus);
        setStatusesState([]);
        setIsToTypesChecked(initToTypesChecked);
        setToTypes([]);
        setIsCategoriesChecked(initCategories);
        setCategories([]);
        setOrderColumn("common.reservationAt");
        setOrderType("ASC");
        setStartDay(null);
        setEndDay(null);
        setSearchText("");
    };
    return (
        <>
            <StyledCommodityFilter>
                <StyledFilterBox>
                    <div className="itemRow">
                        <span>전송일시</span>
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay("");
                                setEndDay("");
                            }}
                            label="전체"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(ConvertDateLine(today));
                                setEndDay(null);
                            }}
                            label="오늘"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDateLine(
                                        new Date(
                                            year,
                                            month,
                                            day - 7,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDateLine(today));
                            }}
                            label="7일"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDateLine(
                                        new Date(
                                            year,
                                            month - 1,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDateLine(today));
                            }}
                            label="1개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDateLine(
                                        new Date(
                                            year,
                                            month - 6,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDateLine(today));
                            }}
                            label="6개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDateLine(
                                        new Date(
                                            year - 1,
                                            month,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDateLine(today));
                            }}
                            label="12개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <RangeCalendar
                            getUserRangeDate={(date) => {
                                const { startDate: start, endDate: end } = date;
                                setStartDay(
                                    start ? ConvertDateLine(start) : "",
                                );
                                setEndDay(end ? ConvertDateLine(end) : "");
                            }}
                            setRangeDate={{
                                startDate: startDay,
                                endDate: endDay,
                            }}
                            isDisabled={false}
                            timePicker={false}
                        />
                    </div>
                    <div className="itemRow">
                        <span>상태</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isStatusChecked[100]}
                                label="등록"
                                defaultValue={[100]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isStatusChecked,
                                        );
                                    setStatusesState(statusType);
                                    setIsStatusChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isStatusChecked[202]}
                                label="테스트 발송 실패"
                                defaultValue={[202]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isStatusChecked,
                                        );
                                    setStatusesState(statusType);
                                    setIsStatusChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isStatusChecked[300]}
                                label="테스트 발송 완료"
                                defaultValue={[300]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isStatusChecked,
                                        );
                                    setStatusesState(statusType);
                                    setIsStatusChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isStatusChecked[400]}
                                label="발송승인"
                                defaultValue={[400]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isStatusChecked,
                                        );
                                    setStatusesState(statusType);
                                    setIsStatusChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isStatusChecked[500]}
                                label="발송완료"
                                defaultValue={[500]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isStatusChecked,
                                        );
                                    setStatusesState(statusType);
                                    setIsStatusChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isStatusChecked[402]}
                                label="발송실패"
                                defaultValue={[402]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isStatusChecked,
                                        );
                                    setStatusesState(statusType);
                                    setIsStatusChecked(setCheckedList);
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>전송유형</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isToTypesChecked["user_no"]}
                                label="개별전송"
                                defaultValue={["user_no"]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isToTypesChecked,
                                        );
                                    setToTypes(statusType);
                                    setIsToTypesChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isToTypesChecked["group"]}
                                label="상황전송"
                                defaultValue={["group"]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isToTypesChecked,
                                        );
                                    setToTypes(statusType);
                                    setIsToTypesChecked(setCheckedList);
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>유형</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCategoriesChecked[0]}
                                label="서비스"
                                defaultValue={[0]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCategoriesChecked,
                                        );
                                    setIsCategoriesChecked(setCheckedList);
                                    setCategories(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCategoriesChecked[10]}
                                label="관심매물"
                                defaultValue={[10]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCategoriesChecked,
                                        );
                                    setIsCategoriesChecked(setCheckedList);
                                    setCategories(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCategoriesChecked[100]}
                                label="AI추천"
                                defaultValue={[100]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCategoriesChecked,
                                        );
                                    setIsCategoriesChecked(setCheckedList);
                                    setCategories(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCategoriesChecked[1000]}
                                label="혜택•이벤트"
                                defaultValue={[1000]}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCategoriesChecked,
                                        );
                                    setIsCategoriesChecked(setCheckedList);
                                    setCategories(statusType);
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>검색</span>
                        <StyledReasonWrapper>
                            <input
                                type="text"
                                placeholder="제목이나 내용, 링크를  입력해주세요 "
                                onChange={(e: any) => {
                                    setSearchText(e.target.value);
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={searchText}
                            />
                        </StyledReasonWrapper>
                    </div>
                </StyledFilterBox>
                <StyledSearchButton>
                    <Button
                        color="gray"
                        handleClick={() => {
                            handleClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            handleSearch();
                        }}
                        label="검색"
                        size="medium"
                    />
                </StyledSearchButton>
                <div className="dropDown">
                    <DropDown
                        border
                        data={[
                            {
                                id: 1,
                                key: "common.reservationAt-DESC",
                                value: "전송일시 최신순",
                            },
                            {
                                id: 2,
                                key: "common.reservationAt-ASC",
                                value: "전송일시 오래된순",
                            },
                            {
                                id: 3,
                                key: "stat_read_rate-DESC",
                                value: "오픈율 높은순",
                            },
                            {
                                id: 4,
                                key: "stat_read_rate-ASC",
                                value: "오픈율 낮은순",
                            },
                        ]}
                        defaultValue="common.reservationAt-DESC"
                        handleChange={(e, text, value) => {
                            handleChange(e, text, value);
                        }}
                    />
                </div>
            </StyledCommodityFilter>
        </>
    );
};

export default PushFilter;

const StyledCommodityFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    & > .dropDown {
        display: flex;
        justify-content: flex-end;
        height: 100%;
    }
`;

const StyledFilterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: fit-content;
    background-color: #f4f5f7;
    border-radius: 10px;
    padding: 10px 24px 24px 24px;
    margin-top: 20px;
    & > .itemRow {
        display: flex;
        gap: 16px;
        align-items: center;
        box-sizing: border-box;
        & > span {
            width: 63px;
            font-weight: 400;
            font-size: 13px;
            color: #222222;
            letter-spacing: -0.4px;
        }
        & > .checkBoxDiv {
            display: flex;
            gap: 12px;
            align-items: center;
        }
        & > .dropDown {
            height: 40px;
            & > div {
                height: 100%;
                & > label {
                    & > select {
                        height: 100%;
                    }
                }
            }
        }
    }
`;

const StyledReasonWrapper = styled.div`
    width: 648px;
    height: 40px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledSearchButton = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
`;
