import React, { useEffect, useState } from "react";
import BannerForm from "./BannerForm";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, Title } from "@src/components";
import { useNavigate } from "react-router-dom";
import { isNotNULL, isNotEmpty } from "@src/utils/textUtil";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import { CreateBannerType } from "./types";
import { useCreateBannerData } from "@src/hooks/queries/useBannerDetail";
import { useBannerList } from "@src/hooks/queries/useBanner";
import { useSelector } from "react-redux";

function BannerCreate() {
    const navigate = useNavigate();
    const type = useSelector((state: any) => state.bannerType);
    const initData = {
        order: 0,
        imageFileCount: 0,
        bannerImage: "",
        startDate: new Date().setHours(0, 0, 0),
        endDate: new Date().setHours(0, 0, 0),
        bannerView: 0,
        bannerLink: "",
        allFiles: [],
        testers: "",
    };
    const [childData, setChildData] = useState<CreateBannerType>();
    const [disabled, setDisabled] = useState(true);
    const [pageErrorMessage, setPageErrorMessage] = useState<any>();
    const changeValue = ({
        imageFileCount,
        bannerImage,
        startDate,
        endDate,
        order,
        bannerLink,
        edit,
        testers,
    }: any) => {
        // if (
        //     isNotNULL(imageFileCount) &&
        //     isNotEmpty(bannerImage) &&
        //     isNotNULL(startDate) &&
        //     isNotNULL(endDate) &&
        //     isNotNULL(bannerLink) &&
        //     isNotNULL(testers) &&
        //     imageFileCount !== 0 &&
        //     order !== 0
        // ) {
        //     setPageErrorMessage("");
        //     // 버튼 활성화
        //     return !edit;
        // } else {
        //     setPageErrorMessage("모든 항목을 입력해주세요");
        //     return true;
        // }
        setPageErrorMessage("");
        // 버튼 활성화
        return !edit;
    };

    useEffect(() => {
        const isChange = changeValue({ ...childData });
        setDisabled(isChange);
    }, [childData]);

    const { refetch: listRefetch } = useBannerList({
        page: 1,
        limit: 10,
        value: type,
    });
    const { mutate: createMutate } = useCreateBannerData();
    const handleSubmit = () => {
        if (childData) {
            const {
                order,
                bannerImage,
                bannerLink,
                startDate,
                endDate,
                bannerView,
                testers,
            } = childData;
            const params = {
                type: type,
                is_open: bannerView,
                display_order: order,
                imgurl: bannerImage,
                linkurl: bannerLink,
                open_at: startDate,
                close_at: endDate,
                testers: testers,
            };
            createMutate(params, {
                onSuccess: () => {
                    listRefetch();
                    navigate("/banners/list");
                },
                onError: (error) => {
                    setDisabled(true);
                    setPageErrorMessage(error);
                },
            });
        }
    };
    return (
        <div css={layoutDiv}>
            <Title title="배너 등록" />
            <BannerForm
                exposure={"create"}
                initData={initData}
                passChildData={setChildData}
                isUpdate={false}
            />
            <div css={btnDiv}>
                <p css={errorStyle}>{pageErrorMessage}</p>

                <Button
                    color="gd"
                    handleClick={() => {
                        handleSubmit();
                    }}
                    label="등록"
                    size="medium"
                    isDisabled={disabled}
                />
            </div>
        </div>
    );
}

const layoutDiv = css`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
`;

const btnDiv = css`
    display: flex;
    justify-content: flex-end;
`;
const errorStyle = css`
    padding-right: 15px;
    color: ${Common.colors.error};
    ${Common.textStyle.title6_R14}
    line-height: 38px;
`;
export default BannerCreate;
