import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import {
    BaseUser,
    ListUserProps,
    FilterUserProps,
    ReadUserListDto,
} from "@src/interfaces/User.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import { queryList } from "./queryList";

const fetchData = async (params: ReadUserListDto) => {
    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");
    const url = `${axiosPath.users}?${queryString}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseUser[] = responseData.data.list;
    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 &&
            queryList.setTotalCount(responseData.data.totalCnt);
    } else {
        queryList.setTotalCount(responseData.data.totalCnt);
    }

    return queryList.getData(parse);
};

const parse = (originData: BaseUser[]) => {
    const newData: ListUserProps[] = [];
    originData.forEach((item, index) => {
        const {
            user_no,
            id,
            status,
            uname,
            phone,
            expert_status,
            created_at,
            birthdate,
            gender,
            subscription_status,
            product_name,
        } = item;

        newData.push({
            no: index,
            userNo: user_no, //회원번호
            userName: uname, //이름
            userPhoneNumber: phone, //연락처
            userEmail: id, //이메일
            userBirthday: birthdate, //생년월일
            userGender: gender === "0" ? "여성" : "남성", //"0": 여성, "1": 남성
            userApplyDate: created_at, //가입일시
            userStatus: status, //회원상태
            experts: expert_status, //전문가 상태(필터 검색 제외)
            productName: product_name, // 정기구독
            subscription: null, //구독상태(필터 검색 제외) 고스톱??
            viewMore: user_no, //더보기
        });
    });
    return newData;
};

const changeFormat = (date: string) => {
    return date.replaceAll(".", "-");
};

const getQueryString = (searchParams: any) => {
    let queryParams: any = {};
    const {
        userType,
        startDate,
        endDate,
        userName,
        userPhoneNumber,
        userEmail,
        limit,
        page,
        q,
    } = searchParams;

    const createParams: any = {
        status: userType,
        "created_dt[start]": !startDate ? undefined : changeFormat(startDate),
        "created_dt[end]": !endDate
            ? undefined
            : changeFormat(`${endDate} ${"23:59:59"}`),
        uname: userName,
        phone: userPhoneNumber,
        id: userEmail,
        limit: Number(limit),
        page: Number(page),
        q,
    };

    Object.keys(createParams).forEach((value: any) => {
        let name = value as keyof ReadUserListDto;
        if (createParams[name]) {
            queryParams[name] = createParams[name];
        }
    });
    return queryParams;
};
const useUserList = (filters: FilterUserProps, enabled: boolean = true) => {
    const queryParams = getQueryString(filters);

    return useQuery(
        [queryKeys.USER_LIST, queryParams],
        () => fetchData(queryParams),
        {
            enabled,
        },
    );
};
const TODAY = ConvertDate(new Date().getTime());

const initFilters: any = {
    startDate: TODAY,
    endDate: TODAY,
    userName: "",
    userPhoneNumber: "",
    userEmail: "",
    userType: "",
    page: 1,
    limit: 10,
};
const dropList = [
    {
        id: 1,
        key: "userName",
        value: "이름",
    },
    {
        id: 2,
        key: "userPhoneNumber",
        value: "연락처",
    },
    {
        id: 3,
        key: "userEmail",
        value: "이메일",
    },
];
const usePushReceivingUserList = ({ limit, to_go }: FilterUserProps) => {
    const queryParams = { limit, to_go };
    return useQuery(
        [queryKeys.PUSH_RECEIVING_USER_LIST, queryParams],
        () => fetchData(queryParams),
        {
            // initialData: initData,
        },
    );
};

const useUserNoSearch = (params: {
    q: string;
    enabled: boolean;
}): {
    parseData: any[];
    isLoading: boolean;
    refetch: () => void;
    isRefetching: boolean;
} => {
    const {
        isLoading,
        data = { result: [], total: 0 },
        refetch,
        isRefetching,
    } = useUserList(params, params.enabled);

    const originData = data.result;
    let parseData: any[] = [];
    originData.forEach((item: any) => {
        const { userName, userEmail, userPhoneNumber, userNo } = item;
        parseData.push({
            userName,
            userEmail,
            userPhoneNumber,
            userNo,
        });
    });
    return { parseData, isLoading, refetch, isRefetching };
};
export {
    useUserList,
    initFilters,
    usePushReceivingUserList,
    getQueryString,
    dropList,
    useUserNoSearch,
};
