import { css } from "@emotion/react";
import { Button, ConfirmModal } from "@src/components";
import { useSaveCoupon } from "@src/hooks/queries/useCoupon";
import { dateFormat, dateParse } from "@src/utils/dateUtil";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CouponForm from "./CouponForm";

interface Props {}

const INIT_VALUE = {
    name: "",
    description: "",
    code_type: "",
    product_code: "",
    reg_start: "",
    reg_end: "",
    no_time_limit: "0",
    issued_count: "",
    comments: "",
};

const CouponCreate: React.FC<Props> = () => {
    const navigate = useNavigate();
    const [formContent, setFormContent] = useState<any>(INIT_VALUE);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const saveCoupon = useSaveCoupon(() => {
        setOpenDialog(false);
        navigate("/coupon/list", { replace: true });
    });
    const onSubmitSaveCoupon = () => {
        const param = {
            ...formContent,
            reg_start: dateFormat(
                new Date(dateParse(formContent["reg_start"])),
            ),
            reg_end: formContent["reg_end"]
                ? dateFormat(new Date(dateParse(formContent["reg_end"])))
                : undefined,
            no_time_limit: Number(formContent.no_time_limit),
            issued_count: Number(formContent.issued_count),
            code_type: Number(formContent.code_type),
        };
        saveCoupon.mutate(param);
    };

    return (
        <div css={createRootStyle}>
            <CouponForm
                title="신규 쿠폰 등록"
                content={formContent}
                setContent={setFormContent}
            />
            <div css={submitBtnRoot}>
                <Button
                    color="gd"
                    handleClick={() => setOpenDialog(true)}
                    label="등록"
                    size="medium"
                    isDisabled={
                        !Object.values({
                            ...formContent,
                            comments: "notRequire",
                            reg_start: "notRequire",
                            reg_end: "notRequire",
                        }).every((f) => f) ||
                        (formContent["no_time_limit"] === "1"
                            ? !formContent["reg_start"]
                            : !formContent["reg_start"] ||
                              !formContent["reg_end"])
                    }
                />
            </div>
            <ConfirmModal
                isModalShow={openDialog}
                buttonText="확인"
                handleCancel={() => setOpenDialog(false)}
                handleOk={onSubmitSaveCoupon}
            >
                등록하시겠습니까?
            </ConfirmModal>
        </div>
    );
};

const createRootStyle = css`
    padding: 40px;
    width: 70%;
`;
const submitBtnRoot = css`
    display: flex;
    justify-content: center;
    margin: 1rem 0;
`;

export default CouponCreate;
