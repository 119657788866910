import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Input, Button, IconButton } from "@src/components";
import { Common } from "@src/styles/Common";
import plusIcon from "@src/assets/plus.png";
import { EditModal } from "@src/components";
import ReceiverUser from "./ReceiverUser";
import ReceiverTester from "./ReceiverTester";
import { Constants } from "@src/constants/Constants";

interface IReceiverBox {
    receiverUserNo: string[];
    placeholder: string;
    pageName: string;
    isDetail?: boolean;
    isUpdate: boolean;
    changeReceiver?: Function;
    changeTester?: Function;
    handleUpdate?: (pageName: string, selectionUserNo: string[]) => void;
}
const ReceiverBox = ({
    receiverUserNo = [],
    placeholder,
    pageName,
    isDetail = false,
    isUpdate = false,
    handleUpdate = () => {},
}: IReceiverBox) => {
    const [isModalShow, setIsModalShow] = useState(false);
    const [selectionList, setSelectionList] = useState<any>(receiverUserNo);
    const [tempList, setTempList] = useState<string[]>([]);

    useEffect(() => {
        setSelectionList(receiverUserNo);
    }, [receiverUserNo]);

    const updateUserNo = (selectedRows: any) => {
        setTempList(selectedRows.map((v: any) => v.userNo));
    };

    const popupTitle = isDetail ? "목록" : isUpdate ? "수정" : "추가";
    const buttonName = isDetail ? "확인" : isUpdate ? "수정" : "저장";
    const handleSubmit = () => {
        setIsModalShow((prev: any) => !prev);
        handleUpdate(pageName, tempList);
        setSelectionList(tempList);
    };

    const DetailComponent = (
        <>
            <span css={totalCount}>총 {selectionList.length} 명</span>
            <Button
                label={"더보기"}
                color={"gd"}
                size={"xSmall"}
                isDisabled={false}
                handleClick={() => {
                    setIsModalShow(true);
                }}
                deleteBtn={false}
            />
        </>
    );

    const UpdateComponent = (
        <>
            <span css={totalCount}>총 {selectionList.length} 명</span>
            <IconButton
                type="plus"
                handleClick={() => {
                    setIsModalShow(true);
                }}
            />
        </>
    );

    const CreateComponent = (
        <div
            className="flexBox"
            onClick={() => {
                setIsModalShow(true);
            }}
            css={addInput}
        >
            <Input
                label={""}
                placeholder={placeholder}
                type={"text"}
                size={"medium"}
                handleChange={() => {}}
                isDisabled={false}
                errorMessage={""}
                name={""}
                readonly={true}
            />
        </div>
    );
    return (
        <div css={selectionListStyle}>
            <>
                {isDetail ? (
                    <>{DetailComponent}</>
                ) : (
                    <>
                        {selectionList.length > 0
                            ? UpdateComponent
                            : CreateComponent}
                    </>
                )}
            </>
            <EditModal
                buttonText={buttonName}
                handleBtnClick={handleSubmit}
                handleOpenModal={() => setIsModalShow((prev: any) => !prev)}
                isModalShow={isModalShow}
                size="fitContent"
                title={`${pageName} ${popupTitle}`}
                isDisabled={false}
                errorMessage={""}
            >
                {pageName === Constants.RECEIVER ? (
                    <ReceiverUser
                        receiverUserNo={selectionList}
                        handleUpdate={updateUserNo}
                        isDetail={isDetail}
                    />
                ) : (
                    <ReceiverTester
                        receiverUserNo={selectionList}
                        handleUpdate={updateUserNo}
                        isDetail={isDetail}
                    />
                )}
            </EditModal>
        </div>
    );
};

const addInput = css`
    input:read-only {
        background: url(${plusIcon}) no-repeat center right 10px;
        background-size: 24px 24px;
        cursor: pointer;
    }
`;

const selectionListStyle = css`
    max-width: 50%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;
`;

const totalCount = css`
    ${Common.textStyle.title8_R12}
    display: inline-block;
    padding-right: 10px;
`;

export default ReceiverBox;
