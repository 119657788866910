import { useQuery } from "react-query";
import { ResponseMissgoApi } from "@src/interfaces/MissgoApi";
import { BaseTemplate } from "@src/interfaces/Template.interface";
import { queryKeys } from "./queryKeys";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

interface IParameters {
    q?: string | undefined;
    page?: number | undefined;
    limit?: number | undefined;
}

const useSituationList = (params: IParameters) => {
    return useQuery<ResponseMissgoApi<BaseTemplate>, Error>(
        [queryKeys.SITUATION_LIST, { ...params }],
        () => fetchData(params),
        {},
    );
};

const fetchData = async (params: IParameters) => {
    const response = await axiosInstance.get(axiosPath.postboxSituation, {
        params,
    });

    const responseData = response.data;
    const originData = responseData.data.list;

    return originData.length
        ? {
              result: originData,
              total: responseData.data.totalCnt,
          }
        : { result: [], total: 0 };
};

export { useSituationList };
