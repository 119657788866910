import { css } from "@emotion/react";
import { Button, Title } from "@src/components";
import React, { useEffect } from "react";
import DeungiNoticeList from "./DeungiNoticeList";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

interface Props {}

const DeungiNotice: React.FC<Props> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    useEffect(() => {
        pathname === "/deungi/notice" && navigate("list");
    }, [pathname]);

    return (
        <div css={rootStyle}>
            <Title
                title={
                    pathname.includes("list")
                        ? "점검 일정 관리"
                        : pathname.includes("create")
                        ? "점검 일정 등록"
                        : "점검 일정 수정"
                }
            />
            {pathname.includes("list") && (
                <div css={btnStyle}>
                    <Button
                        color="purple"
                        handleClick={(e) => {
                            navigate("create");
                        }}
                        label="점검 일정 등록"
                        size="medium"
                    />
                </div>
            )}
            <Outlet />
        </div>
    );
};

export default DeungiNotice;

const rootStyle = css`
    padding: 40px;
    width: calc(100vw - 235px);
`;

const btnStyle = css`
    display: flex;
    justify-content: flex-end;
    padding: 40px 0;
`;
