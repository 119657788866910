import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "@src/api/authService";
import { persistor } from "@src/store";

function LogoutList() {
    const navigate = useNavigate();
    const purge = () => {
        persistor.purge();
    };
    useEffect(() => {
        authService.logout();
        setTimeout(() => purge(), 200); // clear storage
        navigate("/admin/login");
    });

    return <></>;
}

export default LogoutList;
