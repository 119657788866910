import React from "react";
import { IconButton, Label } from "@src/components";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { duengiChangeStatus } from "./DeungiChangeStatus";

export const getColumns = ({
    setLogId,
    setOpenAlarmLogList,
    setDelId,
    setOpenDelDialog,
    setOpenReDialog,
}: any) => {
    return [
        {
            Header: "등록일시",
            accessor: "created_at",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {ConvertDateTime(value)}
                </div>
            ),
        },
        {
            Header: "ID",
            accessor: "_id",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        wordBreak: "break-all",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "이름",
            accessor: "name",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        wordBreak: "break-all",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "이메일",
            accessor: "user_id",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        wordBreak: "break-all",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "부동산 고유번호",
            accessor: "pin",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "구분",
            accessor: "item_type",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "소재지",
            accessor: "address",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "등기상태",
            accessor: "deungi_type",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "최근확인일시",
            accessor: "updated_at",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {ConvertDateTime(value)}
                </div>
            ),
        },
        {
            Header: "종료일시",
            accessor: "expired_at",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {ConvertDateTime(value)}
                </div>
            ),
        },
        {
            Header: "알림상태",
            accessor: "change_state",
            Cell: ({ cell: { value } }: any) => {
                const [text, color] = duengiChangeStatus[value];
                return (
                    <div style={{ textAlign: "center" }}>
                        <Label color={color} text={text} />
                    </div>
                );
            },
        },
        {
            Header: "전송내역",
            accessor: "log",
            Cell: ({ cell: { row } }: any) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        <button
                            onClick={() => {
                                setLogId(row?.original?._id);
                                setOpenAlarmLogList(true);
                            }}
                        >
                            <Label color={"purple"} text={"전송내역"} />
                        </button>
                    </div>
                );
            },
        },
        {
            Header: "삭제",
            accessor: "delete",
            Cell: ({ cell: { row } }: any) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            overflow: "visible",
                        }}
                    >
                        <IconButton
                            type={
                                row?.original?.change_state === "관리자삭제"
                                    ? "restore"
                                    : "trash"
                            }
                            handleClick={() => {
                                if (
                                    row?.original?.change_state === "관리자삭제"
                                ) {
                                    setDelId(row?.original?._id);
                                    setOpenReDialog(true);
                                } else {
                                    setDelId(row?.original?._id);
                                    setOpenDelDialog(true);
                                }
                            }}
                        />
                    </div>
                );
            },
        },
    ];
};
