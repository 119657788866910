import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { sampleData } from "@src/data/DefaultInfoSampleData";
import {
    BaseBasicInfoContainer,
    BasicInfoContainerProps,
} from "@src/interfaces/product/BasicInfoContainer.interface";

const useGetBasicInformation = (productNo: any) => {
    return useQuery<BasicInfoContainerProps, Error>(
        [queryKeys.DEFAULT_INFORMATION, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.basicInfo}/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseBasicInfoContainer = responseData.data.data;
    return parse(originData);
};

const parse = (origin: any) => {
    const newObj: BasicInfoContainerProps = {
        bidDate: origin.bid_date,
        URL: origin.item_url,
        bidLocation: origin.bid_place,
        bidPrice: origin.est_price,
        miniPrice: origin.min_price,
        depositPrice: origin.deposit,
    };
    return newObj;
};
export { useGetBasicInformation };
