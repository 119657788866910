import React from "react";
import { IconButton } from "@src/components";

export const getColumns = ({ navigate, setDeleteNo, setIsModalShow }: any) => {
    return [
        {
            Header: "뉴스이미지",
            accessor: "newsImage",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "156px", textAlign: "center" }}>
                    <img src={value} width={156} height={74} />
                </div>
            ),
        },
        {
            Header: "기사 제목",
            accessor: "newsLink",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{
                        textAlign: "left",
                        width: "350px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                    }}
                >
                    <a
                        href={value}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "blue" }}
                    >
                        {row.original.newsTitle}
                    </a>
                </div>
            ),
        },
        {
            Header: "게시 일시",
            accessor: "newsDate",
            Cell: ({ cell: { value } }: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>;
            },
        },
        {
            Header: "게시 만료일",
            accessor: "endDate",
            Cell: ({ cell: { value } }: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>;
            },
        },
        {
            Header: "언론사",
            accessor: "mediaCompany",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "노출 여부",
            accessor: "view",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value ? "O" : "X"}
                </div>
            ),
        },
        {
            Header: "뉴스번호",
            accessor: "articleId",
        },
        {
            Header: "관리",
            accessor: "viewControl",
            Cell: ({ row: { values } }: any) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="trash"
                            handleClick={() => {
                                setIsModalShow((prev: any) => !prev);
                                setDeleteNo(values.articleId);
                            }}
                        />
                        <IconButton
                            type="edit"
                            handleClick={() => {
                                navigate(`/news/update/${values.articleId}`);
                            }}
                        />
                    </div>
                );
            },
        },
    ];
};
