import { objectToDropdown } from "@src/utils/dropdownUtil";

export const SituationStatus: { [key: number]: string } = {
    300: "테스트",
    400: "라이브",
};

export const SituationStatusType = objectToDropdown(SituationStatus);

export const SituationStatusColor: { [key: number]: string } = {
    300: "pink",
    400: "purple",
};
