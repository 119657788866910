import { css } from "@emotion/react";
import {
    Button,
    Checkbox,
    DropDown,
    EditModal,
    Input,
    RangeCalendar,
    TextBox,
    Title,
} from "@src/components";
import RadioButton from "@src/components/radio/RadioButton";
import {
    useCouponTypeFetchData,
    useProductListFetchData,
} from "@src/hooks/queries/useCoupon";
import { Common } from "@src/styles/Common";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import React, { Dispatch, useEffect, useState } from "react";
import RelatedProduct from "../commodity/RelatedProduct";

interface Props {
    title: string;
    isUpdate?: boolean;
    content: any;
    setContent: Dispatch<any>;
}

const CouponForm: React.FC<Props> = ({
    title,
    isUpdate = false,
    content,
    setContent,
}) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>();
    const [limitTime, setLimitTime] = useState<boolean>(false);
    const { data, refetch } = useProductListFetchData();
    const { data: couponType, refetch: refetchCouponType } =
        useCouponTypeFetchData();

    const onChangeContent = (t: string, val: any) => {
        setContent({ ...content, [t]: val });
    };

    useEffect(() => {
        refetch();
        refetchCouponType();
    }, []);

    return (
        <>
            <Title title={title} />
            <ul css={formRoot}>
                <li>
                    <Input
                        label={"쿠폰명"}
                        placeholder={
                            "최대 20자 이내로 쿠폰명 입력(예: 30일 무료쿠폰)"
                        }
                        type={"text"}
                        size={"default"}
                        handleChange={(evt) =>
                            onChangeContent("name", evt.target.value)
                        }
                        errorMessage={""}
                        name={"couponName"}
                        value={content.name}
                        required={true}
                        maxLength={20}
                    />
                </li>
                <li>
                    <TextBox
                        handleChange={(value) =>
                            onChangeContent("description", value)
                        }
                        label="쿠폰 설명"
                        maxLength={1000}
                        placeholder={`최대 1000자 이내로 설명 가능\n(예: 비갱신형 30일 무료 쿠폰)`}
                        required
                        size="small"
                        isDisabled={false}
                        defaultText={content.description}
                        isRowStyle={true}
                    />
                </li>
                <li>
                    <div css={dropRootStyle}>
                        <div>
                            <strong css={requiredStyle}>*</strong>
                            <span css={labelStyle}>쿠폰 형태</span>
                        </div>
                        <div>
                            <div css={radioStyle}>
                                <RadioButton
                                    isChecked={true}
                                    handleChange={() => {}}
                                    label="관리자 페이지 내 생성"
                                />
                            </div>
                            <DropDown
                                border
                                data={couponType?.map((q: any) => ({
                                    ...q,
                                    value: q.description,
                                    key: q.type,
                                }))}
                                defaultValue={content["code_type"]}
                                handleChange={(evt) => {
                                    onChangeContent(
                                        "code_type",
                                        evt.target.value,
                                    );
                                }}
                                label="쿠폰 형태"
                                placeholder="쿠폰 코드 생성 형태"
                                isDisabled={isUpdate}
                            />
                        </div>
                    </div>
                </li>
                <li>
                    <div css={dropRootStyle}>
                        <div>
                            <strong css={requiredStyle}>*</strong>
                            <span css={labelStyle}>쿠폰 상품코드</span>
                        </div>
                        {
                            <div
                                css={productButtonRoot}
                                aria-hidden
                                onClick={() => !isUpdate && setOpenDialog(true)}
                            >
                                <input
                                    value={content.product_code}
                                    readOnly
                                    placeholder="상품을 조회해주세요"
                                />
                                <Button
                                    color="gd"
                                    handleClick={() => {
                                        setOpenDialog(true);
                                    }}
                                    label="조회"
                                    size="small"
                                    isDisabled={isUpdate}
                                />
                            </div>
                        }
                    </div>
                </li>
                <li>
                    <div>
                        <strong css={requiredStyle}>*</strong>
                        <span css={labelStyle}>등록 유효 기간</span>
                    </div>
                    <RangeCalendar
                        setRangeDate={{
                            startDate:
                                content["reg_start"] !== null &&
                                content["reg_start"] !== undefined
                                    ? dateParse(content["reg_start"])
                                    : null,
                            endDate:
                                content["reg_end"] !== null &&
                                content["reg_end"] !== undefined
                                    ? dateParse(content["reg_end"])
                                    : null,
                        }}
                        getUserRangeDate={(date, isError) => {
                            const { startDate: start, endDate: end } = date;
                            setContent({
                                ...content,
                                reg_start: start ? ConvertDate(start) : "",
                                reg_end: end ? ConvertDate(end) : "",
                            });
                        }}
                        verticalAlign={false}
                        isDisabled={isUpdate}
                        isDateDisabled={limitTime ? 2 : 0}
                        timePicker={false}
                    />
                    <div css={checkBoxStyle}>
                        <Checkbox
                            handleChange={() => {
                                !limitTime
                                    ? setContent({
                                          ...content,
                                          reg_end: 0,
                                          no_time_limit: "1",
                                      })
                                    : setContent({
                                          ...content,
                                          no_time_limit: "0",
                                      });
                                setLimitTime((pre) => !pre);
                            }}
                            label="기간제한 없음"
                            isDisabled={isUpdate}
                        />
                    </div>
                </li>
                <li>
                    <Input
                        label="발행 수량"
                        placeholder="발행 개수 입력"
                        type="text"
                        size="default"
                        handleChange={(evt) => {
                            const newVal = evt.target.value;
                            if (Number.isNaN(Number(newVal))) {
                                console.log("err>>");
                                return;
                            }
                            if (newVal[newVal.length - 1] === ".") {
                                console.log("err>>");
                                return;
                            }
                            onChangeContent("issued_count", newVal);
                        }}
                        errorMessage={""}
                        name={"couponName"}
                        value={content["issued_count"]}
                        required={true}
                        isDisabled={isUpdate}
                    />
                </li>
                {isUpdate && (
                    <li>
                        <div>
                            <strong css={requiredStyle} />
                            <span css={labelStyle}>등록 제한</span>
                        </div>
                        <p>{content["dupl_restrict"]}</p>
                    </li>
                )}
                <li>
                    <div>
                        <strong css={requiredStyle} />
                        <span css={labelStyle}>사유</span>
                    </div>
                    <TextBox
                        handleChange={(value) => {
                            setContent({ ...content, comments: value });
                        }}
                        // label="사유"
                        maxLength={1000}
                        placeholder="예: 쿠폰 발행하고자 하는 이유 명시"
                        size="small"
                        isDisabled={false}
                        defaultText={content.comments}
                        isRowStyle={true}
                    />
                </li>
                {isUpdate && (
                    <li>
                        <div>
                            <strong css={requiredStyle} />
                            <span css={labelStyle}>진행 상태</span>
                        </div>
                        <DropDown
                            border
                            data={[
                                {
                                    id: 2,
                                    key: "100",
                                    value: "대기",
                                },
                                {
                                    id: 1,
                                    key: "200",
                                    value: "진행 중",
                                },
                                {
                                    id: 3,
                                    key: "300",
                                    value: "만료",
                                },
                            ]}
                            defaultValue={content["status"] || ""}
                            handleChange={(evt) => {
                                onChangeContent("status", evt.target.value);
                            }}
                            isDisabled={isUpdate}
                        />
                    </li>
                )}
            </ul>
            <EditModal
                buttonText="OK"
                handleBtnClick={() =>
                    onChangeContent("product_code", selectedItem.code)
                }
                handleOpenModal={() => {
                    setOpenDialog(false);
                    setSelectedItem(null);
                }}
                size="big"
                title="상품 조회"
                isModalShow={openDialog}
                isDisabled={isUpdate}
            >
                <div>
                    <RelatedProduct
                        connectedProductList={data?.result}
                        setSelectedItem={setSelectedItem}
                        selectedItem={selectedItem}
                        productHeaderType={[
                            { title: "상품명", key: "name" },
                            { title: "상품코드", key: "code" },
                            { title: "상품종류", key: "subs_group_name" },
                        ]}
                    />
                </div>
            </EditModal>
        </>
    );
};

const formRoot = css`
    margin-top: 40px;
    span {
        display: inline-block;
        width: 120px;
        ${Common.textStyle.title6_R14}
    }
    li {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
`;
const dropRootStyle = css`
    display: flex;
    width: 100%;
`;
const labelStyle = css`
    display: inline-block;
    vertical-align: bottom;
    line-height: 42px;
    ${Common.textStyle.title5_M14}
`;
const requiredStyle = css`
    padding-right: 5px;
    color: ${Common.colors.error};
`;

const checkBoxStyle = css`
    margin-left: 2rem;
`;

const radioStyle = css`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        width: 100%;
        ${Common.textStyle.title6_R14}
    }
`;

const productButtonRoot = css`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e3e6;
    input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 14rem;
        height: 42px;
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
`;

export default CouponForm;
