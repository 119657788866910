import { dropdownFormat } from "@src/utils/dropdownUtil";

export const NewsCategory: { [key: number]: string } = {
    1: "부동산 정책",
    2: "부동산 개발",
    3: "경매",
    4: "공매",
    6: "경매 타경",
    7: "공매 온비드 사건번호",
};

export const ddlNewsCategory = dropdownFormat(NewsCategory);
