import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { Title, DropDown, Button, Input, SearchModal, ConfirmModal } from "@src/components";
import RadioButton from "@src/components/radio/RadioButton";
import { PartnerDropDown, UpdatePartnerDropDown } from "@src/constants/PartnerStatus";
import {
    useCheckCodeDuplicated,
    useCheckNameDuplicated,
    useGetBankList,
    useGetPartnerDetail,
    useRegistePatner,
    useUpdatePartner,
} from "@src/hooks/queries/usePartner";
import { PartnerRegisterDto, PartnerUpdateDto } from "@src/interfaces/Partner.interface";
import { Common } from "@src/styles/Common";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { ConvertPhoneNumber } from "@src/utils/units";

const PartnerRegistration = () => {
    const navigate = useNavigate();
    const { no } = useParams();
    const location = useLocation() as any;
    const initalParams = {
        userNo: 0,
        name: "",
        reference: "",
        memo: "",
        status: "request",
        partnerType: 0,
    };

    const initalUpdateParams = {
        userNo: 0,
        name: "",
        reference: "",
        memo: "",
        status: "request",
        code: "",
        account: { bank: "NH농협", account: "" },
        partnerType: 0,
    };
    const [registerParams, setRegisterParams] = useState<PartnerRegisterDto>(initalParams);
    const [updateParams, setUpdateParams] = useState<PartnerUpdateDto>(initalUpdateParams);
    const [isDuplicatedCheck, setIsDuplicatedCheck] = useState<boolean>(false);
    const [isCodeDuplicatedCheck, setIsCodeDuplicatedCheck] = useState<boolean>(false);
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [connectedUser, setConnectedUser] = useState<any[]>([]);
    const [userInfo, setUserInfo] = useState<any>();
    const [isSaveModalShow, setIsSaveModalShow] = useState<boolean>(false);
    const [bankList, setBankList] = useState<{ id: number; key: string; value: string }[]>([
        { id: 0, key: "", value: "" },
    ]);
    const [duplicatedModal, setDuplicatedModal] = useState<{
        isShow: boolean;
        title: string;
        state: boolean;
    }>({
        isShow: false,
        title: "",
        state: false,
    });
    const [codeDuplicatedModal, setCodeDuplicatedModal] = useState<{
        isShow: boolean;
        title: string;
        state: boolean;
    }>({
        isShow: false,
        title: "",
        state: false,
    });

    const handleChange = (e: ChangeEvent<HTMLSelectElement>, text?: string, value?: string) => {
        if (no) {
            value && setUpdateParams({ ...updateParams, status: value });
        } else {
            value && setRegisterParams({ ...registerParams, status: value });
        }
    };

    const bankChange = (e: ChangeEvent<HTMLSelectElement>, text?: string, value?: string) => {
        value &&
            setUpdateParams({
                ...updateParams,
                account: {
                    bank: value,
                    account: updateParams.account?.account ?? "",
                },
            });
    };

    const handleSearch = async (value?: string) => {
        const newParam: any = {
            page: 1,
            limit: 9999,
            searchName: value,
        };

        const url = `${axiosPath.nestUsers}/not-payback-partner`;
        const response = await axiosInstance.get(url, { params: newParam });

        setConnectedUser(
            response.data.list.map((item: any) => ({
                name: item.info.uname,
                email: item.id,
                phone: item.info.phone,
                no: item.no,
                birth: item.info.birthdate,
                gender: item.info.gender,
                createdAt: item.createdAt,
            })),
        );
    };

    const tryGetDetailData = async () => {
        const response = await useGetPartnerDetail(no);
        setUpdateParams({
            status: response.status,
            userNo: response.user.no,
            name: response.name,
            memo: response.memo,
            reference: response.reference,
            code: response.code,
            account: {
                bank: response.account?.bank ?? "NH농협",
                account: response.account?.account,
            },
            partnerType: response?.partnerType === "일반회원" ? 0 : 1,
        });
        setUserInfo({
            birth: response.user.info.birthdate,
            createdAt: response.createdAt,
            email: response.user.id,
            gender: response.user.info.gender,
            name: response.user.info.uname,
            no: response.user.no,
            phone: response.user.info.phone,
        });
    };

    const tryGetBankList = async () => {
        const response = await useGetBankList();
        setBankList(
            response.map((item: any, idx: number) => ({
                id: idx + 1,
                key: item,
                value: item,
            })),
        );
    };

    useEffect(() => {
        if (location.state.type === "update" && no) {
            tryGetDetailData();
            tryGetBankList();
        }
    }, []);

    const postPartner = useRegistePatner(() => {
        navigate("/partner/list");
    });

    const putPartner = useUpdatePartner(() => {
        navigate(`/partner/detail/${no}`);
    });

    const tryPostPartner = () => {
        postPartner.mutate(registerParams);
    };

    const tryPutPartner = () => {
        const newParams = updateParams;
        if (newParams.account && !newParams.account?.account) {
            delete newParams.account;
        }
        no && putPartner.mutate({ data: newParams, no: no });
    };

    useEffect(() => {
        if (updateParams.status === "request" || updateParams.status === "reject") {
            setUpdateParams({
                ...updateParams,
                account: {
                    bank: "",
                    account: "",
                },
            });
        }
    }, [updateParams.status]);

    const regex = /^[ㄱ-ㅎ가-힣a-zA-Z0-9]+$/; //? 한글 영문 숫자만 가능
    const pattern = /\s/g; //? 띄어쓰기

    return (
        <>
            <StyledWrap>
                <Title title={location.state.type === "update" ? "파트너 수정" : "파트너 등록"} />
                <StyledContents>
                    <div className="title">기본정보</div>
                    <div className="partnerState">
                        <Title title="파트너 상태" />{" "}
                        <DropDown
                            data={location.state.type === "create" ? PartnerDropDown : UpdatePartnerDropDown}
                            handleChange={handleChange}
                            defaultValue={
                                location.state.type === "update" ? updateParams.status : registerParams.status
                            }
                        />
                    </div>
                    <div className="userInfo">
                        <div className="userInfoTitleDiv">
                            <div className="userInfoTitleDiv-title">
                                <span className="essential">*</span> <Title title="회원 정보" />
                            </div>
                            {location.state.type === "create" ? (
                                <Button
                                    label="회원번호 연결"
                                    color="gray"
                                    size="small"
                                    handleClick={() => {
                                        setIsModalShow(true);
                                    }}
                                />
                            ) : (
                                (updateParams.status === "request" || updateParams.status === "reject") && (
                                    <Button
                                        label="회원번호 변경"
                                        color="gray"
                                        size="small"
                                        handleClick={() => {
                                            setIsModalShow(true);
                                        }}
                                    />
                                )
                            )}
                        </div>
                        {userInfo?.no ? (
                            <div className="infoContents">
                                <div className="infoTextBox">
                                    <div className="itemRow">
                                        <span className="title">이름</span>
                                        <span className="text">{userInfo.name ? userInfo.name : "미입력"}</span>
                                    </div>
                                    <div className="itemRow">
                                        <span className="title">연락처</span>
                                        <span className="text">{ConvertPhoneNumber(userInfo.phone) ?? "미입력"}</span>
                                    </div>
                                    <div className="itemRow">
                                        <span className="title">이메일</span>
                                        <span className="text">{userInfo.email ? userInfo.email : "미입력"}</span>
                                    </div>
                                </div>
                                <div className="infoTextBox">
                                    <div className="itemRow">
                                        <span className="title">생년월일</span>
                                        <span className="text">
                                            {userInfo.birth ? (
                                                <>
                                                    {userInfo.birth.slice(0, 4)}.{userInfo.birth.slice(4, 6)}.
                                                    {userInfo.birth.slice(6, 8)}
                                                </>
                                            ) : (
                                                "미입력"
                                            )}
                                        </span>
                                    </div>
                                    <div className="itemRow">
                                        <span className="title">성별</span>
                                        <span className="text">{userInfo.gender === "0" ? "여자" : "남자"}</span>
                                    </div>
                                    <div className="itemRow">
                                        <span className="title">가입일</span>
                                        <span className="text">{ConvertDateTime(userInfo.createdAt) ?? "미입력"}</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="nullBox">회원번호를 연결해주세요</div>
                        )}
                    </div>
                    <div className="partnerInfo">
                        <Title title="파트너 정보" />
                        <div className="partnerInfoDiv">
                            <div className="infoInput">
                                <span>
                                    <span className="essential">*</span>유형
                                </span>
                                <RadioButton
                                    isChecked={
                                        location?.state?.type === "update"
                                            ? updateParams.partnerType === 0
                                            : registerParams.partnerType === 0
                                    }
                                    handleChange={() => {
                                        if (location.state.type === "update") {
                                            setUpdateParams({
                                                ...updateParams,
                                                partnerType: 0,
                                            });
                                        } else {
                                            setRegisterParams({
                                                ...registerParams,
                                                partnerType: 0,
                                            });
                                        }
                                    }}
                                    label="일반회원"
                                />
                                <RadioButton
                                    isChecked={
                                        location?.state?.type === "update"
                                            ? updateParams.partnerType === 1
                                            : registerParams.partnerType === 1
                                    }
                                    handleChange={() => {
                                        if (location.state.type === "update") {
                                            setUpdateParams({
                                                ...updateParams,
                                                partnerType: 1,
                                            });
                                        } else {
                                            setRegisterParams({
                                                ...registerParams,
                                                partnerType: 1,
                                            });
                                        }
                                    }}
                                    label="임직원"
                                />
                            </div>
                            <div className="infoInput">
                                <span>
                                    <span className="essential">*</span>닉네임
                                </span>
                                <Input
                                    placeholder={"닉네임을 입력해주세요(2~7자)"}
                                    size="medium"
                                    handleChange={(e) => {
                                        setIsDuplicatedCheck(false);
                                        if (location.state.type === "update") {
                                            setUpdateParams({
                                                ...updateParams,
                                                name: e.target.value,
                                            });
                                        } else {
                                            setRegisterParams({
                                                ...registerParams,
                                                name: e.target.value,
                                            });
                                        }
                                    }}
                                    value={location.state.type === "update" ? updateParams.name : registerParams.name}
                                />
                                <Button
                                    color="gd"
                                    handleClick={() => {
                                        if (
                                            location.state.type === "update" &&
                                            updateParams.name.length > 1 &&
                                            updateParams.name.length < 8 &&
                                            regex.test(updateParams.name) &&
                                            !updateParams.name.match(pattern)
                                        ) {
                                            useCheckNameDuplicated(updateParams.name, updateParams.userNo).then(
                                                (res) => {
                                                    setDuplicatedModal({
                                                        isShow: true,
                                                        title: res
                                                            ? "동일한 닉네임이 있습니다."
                                                            : "사용할 수 있는 닉네임입니다",
                                                        state: !res,
                                                    });
                                                },
                                            );
                                        } else if (
                                            location.state.type === "create" &&
                                            registerParams.name.length > 1 &&
                                            registerParams.name.length < 8 &&
                                            regex.test(registerParams.name) &&
                                            !registerParams.name.match(pattern)
                                        ) {
                                            useCheckNameDuplicated(registerParams.name, registerParams.userNo).then(
                                                (res) => {
                                                    setDuplicatedModal({
                                                        isShow: true,
                                                        title: res
                                                            ? "동일한 닉네임이 있습니다."
                                                            : "사용할 수 있는 닉네임입니다",
                                                        state: !res,
                                                    });
                                                },
                                            );
                                        } else {
                                            if (
                                                location.state.type === "update" &&
                                                (!regex.test(updateParams.name) || updateParams.name.match(pattern))
                                            ) {
                                                alert("닉네임은 한글, 영문, 숫자만 가능합니다.");
                                            } else if (
                                                location.state.type === "create" &&
                                                (!regex.test(registerParams.name) || registerParams.name.match(pattern))
                                            ) {
                                                alert("닉네임은 한글, 영문, 숫자만 가능합니다.");
                                            } else {
                                                alert("닉네임은 2~7글자만 가능합니다");
                                            }
                                        }
                                    }}
                                    label="중복검사"
                                    size="small"
                                    isDisabled={
                                        location.state.type === "update" ? !updateParams.name : !registerParams.name
                                    }
                                />
                            </div>
                            <div className="infoInput">
                                <span>참고 및 요청사항</span>
                                <Input
                                    placeholder={"참고 및 요청사항을 입력해주세요"}
                                    size="medium"
                                    handleChange={(e) => {
                                        if (location.state.type === "update") {
                                            setUpdateParams({
                                                ...updateParams,
                                                reference: e.target.value,
                                            });
                                        } else {
                                            setRegisterParams({
                                                ...registerParams,
                                                reference: e.target.value,
                                            });
                                        }
                                    }}
                                    value={
                                        location.state.type === "update"
                                            ? updateParams.reference
                                            : registerParams.reference
                                    }
                                />
                            </div>
                            {location.state.type === "update" &&
                                updateParams.status !== "request" &&
                                updateParams.status !== "reject" && (
                                    <>
                                        <div className="infoInput">
                                            <span>정산계좌</span>
                                            <div className="dropDownDiv">
                                                {bankList?.length > 1 && (
                                                    <DropDown
                                                        data={bankList}
                                                        handleChange={bankChange}
                                                        defaultValue={
                                                            updateParams.account?.bank ? updateParams.account?.bank : ""
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <Input
                                                placeholder={"계좌번호를 입력해주세요"}
                                                size="medium"
                                                handleChange={(e) => {
                                                    setUpdateParams({
                                                        ...updateParams,
                                                        account: {
                                                            bank: updateParams.account?.bank
                                                                ? updateParams.account.bank
                                                                : "NH농협",
                                                            account: e.target.value,
                                                        },
                                                    });
                                                }}
                                                value={updateParams.account?.account}
                                            />
                                        </div>
                                        <div className="infoInput">
                                            <span>
                                                <span className="essential">*</span>
                                                파트너코드
                                            </span>
                                            <Input
                                                placeholder={""}
                                                size="medium"
                                                handleChange={(e) => {
                                                    setIsCodeDuplicatedCheck(false);
                                                    setUpdateParams({
                                                        ...updateParams,
                                                        code: e.target.value,
                                                    });
                                                }}
                                                value={updateParams.code}
                                            />
                                            <Button
                                                color="gd"
                                                handleClick={() => {
                                                    useCheckCodeDuplicated(updateParams.code, updateParams.userNo).then(
                                                        (res) => {
                                                            setCodeDuplicatedModal({
                                                                isShow: true,
                                                                title: res
                                                                    ? "중복된 코드가 있습니다."
                                                                    : "사용가능한 코드입니다",
                                                                state: !res,
                                                            });
                                                        },
                                                    );
                                                }}
                                                label="중복검사"
                                                size="small"
                                                isDisabled={!updateParams.code}
                                            />
                                        </div>
                                    </>
                                )}
                            <div className="infoInput">
                                <span className="bold">관리자 메모</span>
                                <Input
                                    placeholder={"메모를 입력해주세요"}
                                    size="medium"
                                    handleChange={(e) => {
                                        if (location.state.type === "update") {
                                            setUpdateParams({
                                                ...updateParams,
                                                memo: e.target.value,
                                            });
                                        } else {
                                            setRegisterParams({
                                                ...registerParams,
                                                memo: e.target.value,
                                            });
                                        }
                                    }}
                                    value={location.state.type === "update" ? updateParams.memo : registerParams.memo}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="buttonDiv">
                        <Button
                            label="저장"
                            color="purple"
                            size="small"
                            handleClick={() => {
                                setIsSaveModalShow(true);
                            }}
                            isDisabled={
                                location.state.type === "update"
                                    ? updateParams.status !== "request" && updateParams.status !== "reject"
                                        ? !(
                                              updateParams.userNo &&
                                              updateParams.name &&
                                              isDuplicatedCheck &&
                                              isCodeDuplicatedCheck
                                          )
                                        : !(updateParams.userNo && updateParams.name && isDuplicatedCheck)
                                    : !(registerParams.userNo && registerParams.name && isDuplicatedCheck)
                            }
                        />
                    </div>
                </StyledContents>
            </StyledWrap>
            <SearchModal
                handleOpenModal={() => {
                    setIsModalShow(false);
                }}
                handleSave={(value: any) => {
                    if (location.state.type === "update") {
                        setUpdateParams({
                            ...updateParams,
                            userNo: value.no,
                        });
                    } else {
                        setRegisterParams({
                            ...registerParams,
                            userNo: value.no,
                        });
                    }

                    setUserInfo(value);
                    setIsModalShow(false);
                }}
                handleSearch={handleSearch}
                headers={["이름", "이메일", "연락처", "회원번호"]}
                list={connectedUser}
                placeholder="이름을 입력하세요"
                title="회원번호 검색"
                isModalShow={isModalShow}
                isLoading={false}
            ></SearchModal>
            <ConfirmModal
                isModalShow={isSaveModalShow}
                buttonText="확인"
                handleCancel={() => setIsSaveModalShow(false)}
                handleOk={() => {
                    location.state.type === "update" ? tryPutPartner() : tryPostPartner();
                }}
            >
                {location.state.type === "update" ? "정말 저장 하시겠습니까?" : "파트너를 등록 하시겠습니까?"}
            </ConfirmModal>
            <ConfirmModal
                isModalShow={duplicatedModal.isShow}
                buttonText="확인"
                handleCancel={() => {}}
                handleOk={() => {
                    setIsDuplicatedCheck(duplicatedModal.state);
                    setDuplicatedModal({
                        ...duplicatedModal,
                        isShow: false,
                    });
                }}
                disabledCancelBtn={true}
            >
                <StyledEditModalTitle>{duplicatedModal.title}</StyledEditModalTitle>
            </ConfirmModal>
            <ConfirmModal
                isModalShow={codeDuplicatedModal.isShow}
                buttonText="확인"
                handleCancel={() => {}}
                handleOk={() => {
                    setIsCodeDuplicatedCheck(codeDuplicatedModal.state);
                    setCodeDuplicatedModal({
                        ...codeDuplicatedModal,
                        isShow: false,
                    });
                }}
                disabledCancelBtn={true}
            >
                <StyledEditModalTitle>{codeDuplicatedModal.title}</StyledEditModalTitle>
            </ConfirmModal>
        </>
    );
};

export default PartnerRegistration;

const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 30px;
`;

const StyledContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }
    .partnerState {
        display: flex;
        gap: 42px;
        align-items: center;
    }
    .userInfo {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .userInfoTitleDiv {
            display: flex;
            gap: 5px;
            align-items: center;
            & > .userInfoTitleDiv-title {
                display: flex;
                .essential {
                    color: #f45b5b;
                }
            }
        }
        .infoContents {
            display: flex;
            gap: 32px;
            .infoTextBox {
                display: flex;
                flex-direction: column;
                gap: 16px;
                .itemRow {
                    display: flex;
                    gap: 24px;
                    & > .title {
                        width: 64px;
                        height: 21px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        color: #9d9fa5;
                    }
                    & > .text {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        color: #222222;
                    }
                }
            }
        }
        .nullBox {
            width: 560px;
            height: 208px;
            background-color: #f8f8f8;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #989898;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .partnerInfo {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .partnerInfoDiv {
            display: flex;
            flex-direction: column;
            gap: 9px;
            .infoInput {
                display: flex;
                gap: 16px;
                align-items: center;
                height: fit-content;
                & > span {
                    min-width: 120px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #222222;
                    .essential {
                        color: #f45b5b;
                    }
                }
                .dropDownDiv {
                    height: 48px;
                    & > div {
                        height: 100%;
                        & > label {
                            height: 100%;
                            & > select {
                                height: 100%;
                            }
                        }
                    }
                }
                .bold {
                    font-weight: 700;
                }
                & > input {
                    border: 1px solid ${Common.colors.gray200};
                    border-radius: 8px;
                    padding: 12px;
                    height: 48px;
                }
                & > button {
                    height: 48px;
                    font-size: 14px;
                    box-sizing: border-box;
                }
            }
        }
    }
    .buttonDiv {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
        & > button {
            width: 160px;
            height: 36px;
        }
    }
`;

const StyledEditModalTitle = styled.div`
    font-size: 20px;
`;
