import { Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import React from "react";
import { ConnectionUserStatus, UserStatus } from "@src/constants/UserStatus";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { ConvertPhoneNumber, ConvertPrice } from "@src/utils/units";

export const getPayBackUserListColumns = (navigate: any) => [
    {
        Header: "등록일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "100%",
                    minWidth: "120px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? ConvertDateTime(value) : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "이름",
        accessor: "name",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                    onClick={() => {
                        navigate(`/users/detail/${row.values.id}`);
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "연락처",
        accessor: "phone",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {ConvertPhoneNumber(value)}
                </div>
            );
        },
    },
    {
        Header: "이메일",
        accessor: "email",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "파트너 유효기간",
        accessor: "expiredAt",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "80%",
                        textAlign: "center",
                    }}
                >
                    {value ? ConvertDateTime(value) : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "순결제금액",
        accessor: "paymentAmount",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {ConvertPrice(value)}
                </div>
            );
        },
    },
    {
        Header: "고객회원 상태",
        accessor: "statusName",
        Cell: ({ cell: { value } }: any) => {
            const [text, color] = ConnectionUserStatus[value];
            return (
                <div
                    style={{
                        width: "100%",
                        minWidth: "50px",
                        height: "50px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Label color={color} text={text} />
                </div>
            );
        },
    },
];
