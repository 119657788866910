import { css } from "@emotion/react";
import { Button, Checkbox, DropDown, RangeCalendar } from "@src/components";
import RadioButton from "@src/components/radio/RadioButton";
import { ISettlementDTO } from "@src/hooks/queries/usePartnerSettlement";
import { ConvertDate } from "@src/utils/dateUtil";
import React, { Dispatch, SetStateAction, useState } from "react";

interface Props {
    setParams: Dispatch<SetStateAction<ISettlementDTO>>;
}

const SettlementFilter: React.FC<Props> = ({ setParams }) => {
    const [startDay, setStartDay] = useState<any>();
    const [endDay, setEndDay] = useState<any>();
    const [grade, setGrade] = useState<string[]>([]);
    const [status, setStatus] = useState<any>("");
    const [cancelPayment, setCancelPayment] = useState<any>("");
    const [searchType, setSearchType] = useState<any>();
    const [searchText, setSearchText] = useState<string>("");

    const onClickSubmit = () => {
        const newParams = {
            startDate: startDay,
            endDate: endDay,
            searchType: searchType ?? "userName",
            searchValue: searchText ? searchText : undefined,
            level: grade,
            status: status ? status : undefined,
            existCancel: cancelPayment ? cancelPayment : undefined,
            page: 1,
        };
        !searchText && delete newParams?.searchValue;
        !searchText && delete newParams?.searchType;
        !startDay && delete newParams?.startDate;
        !endDay && delete newParams?.endDate;
        !status && delete newParams?.status;
        !cancelPayment && delete newParams?.existCancel;
        setParams({ ...newParams });
    };

    const onClickReset = () => {
        console.log("rr");
        setStartDay(null);
        setEndDay(null);
        setGrade([]);
        setStatus("");
        setCancelPayment("");
        setSearchType(null);
        setSearchText("");
        setParams({ page: 1 });
    };

    const today = new Date();

    const year = today.getFullYear(); // 년
    const month = today.getMonth(); // 월
    const day = today.getDate(); // 일

    const dateButtonList = [
        {
            label: "전체",
            func: () => {
                setStartDay("");
                setEndDay("");
            },
        },
        {
            label: "오늘",
            func: () => {
                setStartDay(ConvertDate(today.getTime()));
                setEndDay(null);
            },
        },
        {
            label: "7일",
            func: () => {
                setStartDay(ConvertDate(new Date(year, month, day - 7).getTime()));
                setEndDay(ConvertDate(today.getTime()));
            },
        },
        {
            label: "1개월",
            func: () => {
                setStartDay(ConvertDate(new Date(year, month - 1, day).getTime()));
                setEndDay(ConvertDate(today.getTime()));
            },
        },
        {
            label: "6개월",
            func: () => {
                setStartDay(ConvertDate(new Date(year, month - 6, day).getTime()));
                setEndDay(ConvertDate(today.getTime()));
            },
        },
        {
            label: "12개월",
            func: () => {
                setStartDay(ConvertDate(new Date(year - 1, month, day).getTime()));
                setEndDay(ConvertDate(today.getTime()));
            },
        },
    ];

    const gradeList = [
        {
            label: "기본 / 5%",
            value: "0",
        },
        {
            label: "10명이상 / 10%",
            value: "10",
        },
        {
            label: "50명이상 / 20%",
            value: "50",
        },
        {
            label: "100명이상 / 40%",
            value: "100",
        },
    ];

    const settlementStatusList = [
        {
            label: "전체",
            value: "",
        },
        {
            label: "정산 대기",
            value: "정산대기",
        },
        {
            label: "정산 완료",
            value: "정산완료",
        },
    ];

    const checkCancelList = [
        {
            label: "전체",
            value: "",
        },
        {
            label: "취소액 없음",
            value: "취소액없음",
        },
        {
            label: "취소액 있음",
            value: "취소액있음",
        },
    ];
    return (
        <>
            <div css={filterStyle}>
                <div css={filterBoxStyle}>
                    <p className="title">월 정산일</p>
                    {dateButtonList?.map((date) => (
                        <Button
                            key={date.label}
                            color="white"
                            handleClick={() => date.func()}
                            label={date.label}
                            size="medium"
                            styles={dateButtonStyle}
                        />
                    ))}
                    <RangeCalendar
                        getUserRangeDate={(date) => {
                            const { startDate: start, endDate: end } = date;
                            setStartDay(start ? ConvertDate(start) : "");
                            setEndDay(end ? ConvertDate(end) : "");
                        }}
                        setRangeDate={{
                            startDate: startDay,
                            endDate: endDay,
                        }}
                        isDisabled={false}
                        timePicker={false}
                    />
                </div>
                <div css={filterBoxStyle}>
                    <p className="title">등급</p>
                    {gradeList.map((type) => (
                        <Checkbox
                            key={type.label}
                            isDisabled={false}
                            isChecked={false}
                            label={type.label}
                            defaultValue={type.value}
                            handleChange={(n, v) => {
                                if (v) {
                                    const newGrade = [...grade, n];
                                    setGrade(newGrade);
                                } else {
                                    const newGrade = grade.filter((val) => val !== n);
                                    setGrade(newGrade);
                                }
                            }}
                        />
                    ))}
                </div>
                <div css={filterBoxStyle}>
                    <p className="title">정산상태</p>
                    {settlementStatusList.map((val) => (
                        <RadioButton
                            key={val.label}
                            isChecked={status === val.value}
                            handleChange={() => setStatus(val.value)}
                            label={val.label}
                        />
                    ))}
                </div>
                <div css={filterBoxStyle}>
                    <p className="title">정산 후 취소액</p>
                    {checkCancelList.map((check) => (
                        <RadioButton
                            key={check.label}
                            isChecked={check.value === cancelPayment}
                            handleChange={() => setCancelPayment(check.value)}
                            label={check.label}
                        />
                    ))}
                </div>
                <div css={filterBoxStyle}>
                    <p className="title">검색</p>
                    <DropDown
                        border
                        data={[
                            {
                                id: 1,
                                key: "userName",
                                value: "이름",
                            },
                            {
                                id: 2,
                                key: "userEmail",
                                value: "이메일",
                            },
                            {
                                id: 3,
                                key: "userNo",
                                value: "아이디",
                            },
                            {
                                id: 4,
                                key: "nickname",
                                value: "닉네임",
                            },
                        ]}
                        defaultValue=""
                        handleChange={(e, text, value) => setSearchType(value)}
                    />
                    <input
                        type="text"
                        placeholder="이름이나 이메일, 아이디, 닉네임을 입력해주세요"
                        onChange={(evt: any) => setSearchText(evt.target.value)}
                        onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                                onClickSubmit();
                            }
                        }}
                        value={searchText}
                        css={inputStyle}
                    />
                </div>
            </div>
            <div css={buttonBoxStyle}>
                <Button color="gray" handleClick={onClickReset} label="전체 해제" size="medium" />
                <Button color="purple" handleClick={onClickSubmit} label="검색" size="medium" />
            </div>
        </>
    );
};

export default SettlementFilter;

const filterStyle = css`
    margin-top: 14px;
    background-color: #eeeeee;
    padding: 8px 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const dateButtonStyle = css`
    width: 50px;
    min-width: 50px;
    height: 30px;
`;

const filterBoxStyle = css`
    display: flex;
    align-items: center;
    gap: 4px;
    & > .title {
        width: 200px;
        font-size: 14px;
    }
`;

const inputStyle = css`
    width: 400px;
    padding: 5px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: #283237;
    border: 1px solid #e3e3e6;
    border-radius: 8px;
    outline: none;
`;

const buttonBoxStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: flex-end;
    margin-top: 14px;
`;
