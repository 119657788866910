import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { CaseAnalysisProps } from "@src/interfaces/product/CaseAnalysisContainer.interface";
import { sampleData } from "@src/data/CaseAnalysisContainerSampleData";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.itemsAuctionCaseAnalysis}/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: [] = responseData.data.result;

    return originData ? parse(originData) : [];
};

const parse = (originData: any) => {
    let result: CaseAnalysisProps[] = [];
    originData.map((item: any, index: any) => {
        const { period, count, est_price, sale_price, price_prct, avg_fails } =
            item;
        result.push({
            id: index,
            period: period.replace("개월", ""),
            bidCount: count.replace("건", ""),
            estPriceAverage: est_price.replaceAll(".", "").replace("원", ""),
            bidPriceAverage: sale_price.replaceAll(",", "").replace("원", ""),
            bidPriceRate: price_prct.replace("%", ""),
            skpCountAverage: avg_fails.replace("회", ""),
        });
    });
    return result;
};
const useGetCaseAnalysis = (productNo: any) => {
    return useQuery(
        [queryKeys.CASE_ANALYSIS, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};
export { useGetCaseAnalysis };
