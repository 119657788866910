import React from "react";
import { updateExpectedReturn } from "@src/reducers/expectedReturn";
import { Title, ToggleButton, IconButton } from "@src/components";
import ExpectedReturnContainer from "../ExpectedReturnContainer";
// import RealPriceContainer from "./RealPriceContainer";
import SummaryInformationContainer from "./SummaryInformationContainer";
import SkipBidHistoryContainer from "./SkipBidHistoryContainer";
import StandardContainer from "./MainInformationContainer";
import DefaultContainer from "./BasicContainer";
import CaseInformationContainer from "./CaseInformationContainer";
import ItemInformationContainer from "./ItemInformationContainer";
import CaseAnalysisContainer from "./CaseAnalysisContainer";
import rightsAnalysisContainer from "./RightsAnalysisContainer";
import SaleStatusContainer from "./SaleStatusContainer";

export const ProductAuctionContainer: { [key: string]: any } = {
    EXPECTED_RETURN: {
        component: ExpectedReturnContainer, // 예상 수익률
        updateAction: updateExpectedReturn,
    },
    // 실거래가 어드민에서 제외
    // REAL_PRICE: {
    //     component: RealPriceContainer, // 실거래가 - (수정기능이 없음)
    //     updateAction: "",
    // },
    SUMMARY_INFO: {
        component: SummaryInformationContainer, // 간략 정보
        updateAction: "",
    },
    SKIP_BID_HISTORY: {
        component: SkipBidHistoryContainer, //유찰 내역
        updateAction: "",
    },
    STANDARD_INFO: {
        component: StandardContainer, // 메인 상단 정보
        updateAction: "",
    },
    DEFAULT_INFO: {
        component: DefaultContainer, // 기본 정보
        updateAction: "",
    },
    CASE_INFO: {
        component: CaseInformationContainer, // 사건 개요
        updateAction: "",
    },
    ITEM_INFO: {
        component: ItemInformationContainer, // 물건 정보
        updateAction: "",
    },
    CASE_ANALYSIS: {
        component: CaseAnalysisContainer, // 인근 매각 사례
    },
    RIGHTS_ANALYSIS: {
        component: rightsAnalysisContainer, // 권리 분석
        updateAction: "",
    },
    SALES_STATUS: {
        component: SaleStatusContainer, // 매각물건현황
        updateAction: "",
    },
};

interface IContainer {
    title: string;
    componentName: string;
    options?: object;
    defaultViewCategory: { [key: string]: {} };
    handleClick: (container: any, title: string) => void;
    handleToggleOfViewCategory: (name: string, checked: boolean) => void;
}
function RenderContainer({
    title,
    componentName,
    options,
    defaultViewCategory,
    handleClick,
    handleToggleOfViewCategory,
}: IContainer) {
    const DynamicComponent = ProductAuctionContainer[componentName].component;

    return (
        <>
            <Title title={title} />
            <IconButton
                type="edit"
                color="orange"
                handleClick={() => handleClick(componentName, title)}
            />
            <span>카테고리 노출</span>
            <ToggleButton
                name={componentName}
                value={!!defaultViewCategory[componentName]}
                handleToggle={handleToggleOfViewCategory}
            />
            <div
                className="container"
                style={{ padding: "0", border: "none", minHeight: "250px" }}
            >
                <DynamicComponent {...options} />
            </div>
        </>
    );
}

export default RenderContainer;
