import { useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { queryList } from "./queryList";
import { DeungiNoticeDto } from "@src/interfaces/Deungi.interface";
import { AxiosError } from "axios";

const fetchNoticeData = async (params: DeungiNoticeDto) => {
    const response = await axiosInstance.get(`${axiosPath.deungi}/notices`, {
        params: {
            ...params,
        },
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    queryList.setDataList(responseData.list);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCnt);
    } else {
        queryList.setTotalCount(responseData.totalCnt);
    }

    return queryList.getData(() => {
        return responseData.list;
    });
};

const useDeungiNoticeList = (
    filters: DeungiNoticeDto,
    enabled: boolean = true,
) => {
    return useQuery(
        [queryKeys.DEUNGI_LIST, filters],
        () => fetchNoticeData(filters),
        {
            enabled,
        },
    );
};

const useDeungiNoticeSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.deungi}/notice`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.post(url, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useDeungiNoticeUpdate = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.deungi}/notice`;
    return useMutation(
        async (data: any) => {
            const { id, ...param } = data;
            console.log(param);
            try {
                const res = await axiosInstance.put(`${url}/${id}`, param);
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useDeungiNoticeRemover = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.deungi}/notice`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.delete(`${url}/${data}`);
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export {
    useDeungiNoticeList,
    useDeungiNoticeSaver,
    useDeungiNoticeUpdate,
    useDeungiNoticeRemover,
};
