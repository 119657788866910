import { useQuery, useMutation } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { asyncCall } from "@src/api/asyncCall";

const fetchData = async (bannerNo: string, type: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.banner}/${type.type}/${bannerNo}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: any = responseData.data.data;
    return parse(originData);
};

const parse = (originData: any): any => {
    const {
        no,
        imgurl,
        linkurl,
        display_order,
        open_at,
        close_at,
        is_open,
        testers,
    } = originData;
    return {
        bannerNo: no,
        order: display_order,
        bannerImage: imgurl,
        imageFileCount: imgurl.length,
        bannerLink: linkurl,
        startDate: open_at,
        endDate: close_at,
        bannerView: is_open,
        allFiles: [],
        edit: false,
        testers: testers,
    };
};

const useBannerDetailData = (
    bannerNo: string,
    isUpdate: boolean,
    type: any,
) => {
    return useQuery<any, Error>(
        [queryKeys.BANNER_DETAIL_DATA, bannerNo],
        () => fetchData(bannerNo, type),
        {
            // initialData,
            enabled: isUpdate,
        },
    );
};

const useUpdateBannerData = (bannerNo: string | undefined) => {
    const updateBanner = async (params: any) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.banner}/${params.type.type}/${bannerNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateBanner);
};

const useDeleteBannerData = (type: string) => {
    const deleteBanner = async (bannerNo: number) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.banner}/${type}/${bannerNo}`,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(deleteBanner);
};

const useCreateBannerData = () => {
    const createBanner = async (params: any) => {
        const promise = await axiosInstance.post(
            `${axiosPath.banner}/${params.type.type}`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(createBanner);
};

export {
    useBannerDetailData,
    useUpdateBannerData,
    useDeleteBannerData,
    useCreateBannerData,
};
