import React, { useEffect, useState } from "react";
import { Title, Button, PageTable } from "@src/components";
import { css } from "@emotion/react";
import CouponFilter from "./containers/CouponFilter";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { couponColumns } from "./CouponListColumns";
import { useCouponListFetchData } from "@src/hooks/queries/useCoupon";
import { ICouponListDto } from "@src/interfaces/Coupon.interface";
import { dateFormat, dateParse } from "@src/utils/dateUtil";

interface Props {}

const CouponList: React.FC<Props> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const columns = couponColumns(navigate);
    const [searchParams, setSearchParams] = useSearchParams();
    const [param, setParam] = useState<ICouponListDto>({});
    const [filter, setFilter] = useState<any>({});

    const { data, refetch } = useCouponListFetchData(param);

    const updateURL = (value: any) => {
        const queryString = new URLSearchParams(value);
        setSearchParams(queryString);
    };

    const onClickSearch = () => {
        const newFilter = {
            ...filter,
            [`created_dt[start]`]: filter[`created_dt[start]`]
                ? `${dateFormat(
                      new Date(dateParse(filter[`created_dt[start]`])),
                  )} 00:00:00`
                : "",
            [`created_dt[end]`]: filter[`created_dt[end]`]
                ? `${dateFormat(
                      new Date(dateParse(filter[`created_dt[end]`])),
                  )} 23:59:59`
                : "",
            page: 1,
        };

        setParam({ ...param, ...newFilter });
    };

    const clearFilter = () => {
        setFilter({});
        updateURL({});
        setParam({});
    };

    useEffect(() => {
        if (param) {
            updateURL(param);
            refetch();
        }
    }, [param]);

    useEffect(() => {
        const newParam = Object.fromEntries(searchParams);
        const newFilter = {
            ...filter,
            [`created_dt[start]`]: newParam[`created_dt[start]`]
                ? newParam[`created_dt[start]`]
                      .split(" ")[0]
                      .replaceAll("-", ".")
                : 0,
            [`created_dt[end]`]: newParam[`created_dt[end]`]
                ? newParam[`created_dt[end]`].split(" ")[0].replaceAll("-", ".")
                : 0,
            status: newParam.status ?? 0,
            q: newParam.q ?? "",
            page: newParam.page,
        };
        newParam.page ? setParam({ ...param, ...newFilter }) : refetch();
    }, []);

    return (
        <>
            <div css={searchStyle}>
                <Title title="쿠폰 상품 관리" />
                <div css={btnRoot}>
                    <Button
                        color="purple"
                        handleClick={() => navigate("/coupon/create")}
                        label="신규 쿠폰 등록"
                        size="medium"
                    />
                </div>
                <CouponFilter
                    filter={filter}
                    setFilter={setFilter}
                    clearFilter={clearFilter}
                    searchParams={searchParams}
                    onClickSearch={onClickSearch}
                />
                <div>
                    <PageTable
                        columns={columns}
                        data={data?.result ?? []}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        FixedHeight={500}
                        showTotalCount={true}
                        totalCount={data?.total ?? 0}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParam({ ...param, page });
                        }}
                        forcePageNumber={
                            Number(searchParams.get("page")) || param.page
                        }
                    />
                </div>
            </div>
        </>
    );
};

const searchStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const btnRoot = css`
    display: flex;
    justify-content: flex-end;
`;

export default CouponList;
