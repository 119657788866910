import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditModal, ConfirmModal } from "@src/components";
import { setInit } from "@src/reducers/productUpdateResult";
import { ProductAuctionContainer } from "./containers/aAuction/RenderContainer";

function ConfirmPopUpModal({
    isModalShow,
    handleBtnClick,
    handleOpenModal,
}: any) {
    return (
        <ConfirmModal
            isModalShow={isModalShow}
            buttonText="저장"
            handleCancel={handleOpenModal}
            handleOk={handleBtnClick}
        >
            <>
                정보를 수정하시겠습니까? 올바르게 입력하였는지 다시 한번
                확인해주세요.
            </>
        </ConfirmModal>
    );
}
function UpdatePopUpModal({
    container,
    isModalShow,
    title,
    productNo,
    handleModalShow,
}: any) {
    const dispatch = useDispatch();
    const SelectContainer = ProductAuctionContainer[container]["component"];
    const [isEditModalShow, setIsEditModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isUpdating, setIsUpdating] = useState(false);
    let isError = useSelector(
        (state: any) => state.productUpdateResult.isError,
    );
    let isSuccess = useSelector(
        (state: any) => state.productUpdateResult.isSuccess,
    );
    let errMsg = useSelector((state: any) => state.productUpdateResult.errMsg);

    //수정 확인 팝업
    const [isConfirmModalShow, setIsConfirmModalShow] = useState(false);
    const handleOkClick = () => {
        setIsUpdating(true);
        setIsConfirmModalShow((prev) => !prev);
    };
    useEffect(() => {
        isModalShow && setIsEditModalShow(isModalShow);
    }, [isModalShow]);

    useEffect(() => {
        isEditModalShow || dispatch(setInit()), setErrorMessage(""); // 리듀서 초기화
    }, [isEditModalShow]);

    useEffect(() => {
        if (isSuccess) {
            setIsEditModalShow(false);
            setIsUpdating(false);
            handleModalShow(false);
        } else if (isError) {
            setErrorMessage(errMsg);
        }
    }, [isSuccess, isError, errMsg]);

    return (
        <>
            <EditModal
                isModalShow={isEditModalShow}
                buttonText="저장"
                handleBtnClick={() => setIsConfirmModalShow(true)}
                handleOpenModal={() => {
                    setIsEditModalShow(false);
                    handleModalShow(false);
                }}
                size={"fitContent"}
                title={title}
                errorMessage={errorMessage}
            >
                <SelectContainer
                    isEdit={true}
                    handleUpdate={() => {}}
                    productNo={productNo}
                    isUpdating={isUpdating}
                />
            </EditModal>
            <ConfirmPopUpModal
                isModalShow={isConfirmModalShow}
                handleBtnClick={() => {
                    handleOkClick();
                }}
                handleOpenModal={() => setIsConfirmModalShow((prev) => !prev)}
            />
        </>
    );
}

export default UpdatePopUpModal;
