import { useMutation, useQuery } from "react-query";
import { FilterExpertProps } from "@src/interfaces/Expert.interface";
import { queryKeys } from "./queryKeys";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { queryList } from "./queryList";
import { AxiosError } from "axios";
import {
    AgentParamsDto,
    ExpertHome,
    ExpertInquiriesListColumns,
    ExpertListColumns,
    NotExpertUserListDto,
    NotExpertUserListResponse,
    OfficeParamsDto,
    userExpertDto,
    userExpertInquiryListDto,
    userExpertListDto,
    userExpertResponse,
} from "@src/pages/expert/types";
import {
    IExpertDetail,
    IExpertDetailCount,
    IExpertDetailCountDto,
    IExpertManagerDto,
} from "@src/pages/expert/Advertisement/types";

const usePutExpertProfile = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (data: any) => {
            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("userNo", data.userNo);
            try {
                const response = await axiosInstance.put(
                    `${axiosPath.userExpert}/profile`,
                    formData,
                );
                return response.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const usePutExpertInfoFile = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (data: any) => {
            const formData = new FormData();
            formData.append("file", data.file);
            formData.append("userNo", data.userNo);
            formData.append("type", "agent");
            try {
                const response = await axiosInstance.put(
                    `${axiosPath.userExpert}/info/file`,
                    formData,
                );
                return response.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

//!!!!
const fetchNotExpertList = async (params: NotExpertUserListDto) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/not-expert`,
        {
            params: {
                ...params,
            },
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: NotExpertUserListResponse = response.data;

    queryList.setDataList(responseData.list);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 &&
            queryList.setTotalCount(responseData?.totalCount ?? 0);
    } else {
        queryList.setTotalCount(responseData?.totalCount ?? 0);
    }

    return queryList.getData(() => {
        return responseData.list;
    });
};
const useUserNotExpertList = (filters: any, enabled: boolean = false) => {
    return useQuery(
        [queryKeys.NOTEXPERTUSER, filters],
        () => fetchNotExpertList(filters),
        {
            enabled,
        },
    );
};
const usePostExperts = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.v1Url}/user-experts`;
    return useMutation(
        async (data: userExpertDto) => {
            try {
                const res = await axiosInstance.post(`${url}`, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useUpdateExperts = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}`;
    return useMutation(
        async (data: { userExpertNo: string; body: userExpertDto }) => {
            try {
                const res = await axiosInstance.put(
                    `${url}/${data.userExpertNo}`,
                    data.body,
                );
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const fetchExpertHome = async () => {
    const response = await axiosInstance.get(`${axiosPath.userExpert}/home`);
    return response.data;
};

const useGetExpertHome = () => {
    return useQuery<ExpertHome>(
        [queryKeys.EXPERTHOME],
        () => fetchExpertHome(),
        {
            enabled: false,
        },
    );
};

const parseExpertList = (originData: any[]) => {
    const newData: ExpertListColumns[] = originData.map((item) => ({
        no: item.no,
        types: item.types,
        name: item.name,
        phone: item.phone,
        officeName: item.officeName,
        createdAt: item.createdAt,
        adItemCount: item.adItemCount,
        inquiryCount: item.inquiryCount,
        viewMore: item.no,
        expertUserNo: item.expertUserNo,
    }));
    return newData;
};

const fetchExpertList = async (params: userExpertListDto) => {
    const response = await axiosInstance.get(`${axiosPath.userExpert}`, {
        params: {
            ...params,
        },
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: any = response.data;
    queryList.setDataList(responseData.list);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCount);
    } else {
        queryList.setTotalCount(responseData.totalCount);
    }

    return queryList.getData(parseExpertList);
};
const useGetExpertList = (
    filters: userExpertListDto,
    enabled: boolean = false,
) => {
    return useQuery(
        [queryKeys.EXPERTLIST, filters],
        () => fetchExpertList(filters),
        {
            enabled,
        },
    );
};

const fetchExpertUser = async (no: string) => {
    const response = await axiosInstance.get(`${axiosPath.userExpert}/${no}`);
    return response.data;
};

const useGetUserExpert = (no: string) => {
    return useQuery<userExpertResponse>(
        [queryKeys.EXPERTUSER, no],
        () => fetchExpertUser(no),
        {
            enabled: false,
        },
    );
};

const fetchChangeHistory = async (params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.userExpert}/${params.userExpertNo}/infos/${params.userExpertInfoNo}/histories`,
        { params },
    );
    return response.data;
};

const useGetChangeHistory = (params: any) => {
    return useQuery(
        [queryKeys.CHANGEHISTORY],
        () => fetchChangeHistory(params),
        {
            enabled: false,
        },
    );
};

const fetchChangeHistoryDetails = async (params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.userExpert}/${params.userExpertNo}/infos/${params.userExpertInfoNo}/histories/${params.userExpertInfoHistoryNo}`,
    );
    return response.data;
};

const useGetChangeHistoryDetails = (params: any) => {
    return useQuery(
        [queryKeys.CHANGEHISTORYDETAILS],
        () => fetchChangeHistoryDetails(params),
        {
            enabled: false,
        },
    );
};

const parseExpertInquiries = (originData: any[]) => {
    const newData: ExpertInquiriesListColumns[] = originData.map((item) => ({
        no: item.no,
        bidType: item.bidType,
        itemSubId: item.itemSubId,
        useDetail: item.useDetail,
        address: item.address,
        inquiryUserName: item.inquiryUserName,
        inquiryUserNo: item.inquiryUserNo,
        expertUserName: item.expertUserName,
        expertUserNo: item.expertUserNo,
        type: item.type,
        createdAt: item.createdAt,
        viewMore: item.no,
    }));

    return newData;
};

const fetchExpertInquiryList = async (params: userExpertInquiryListDto) => {
    const response = await axiosInstance.get(`${axiosPath.expertInquiry}`, {
        params: {
            ...params,
        },
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: any = response.data;
    queryList.setDataList(responseData.list);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCount);
    } else {
        queryList.setTotalCount(responseData.totalCount);
    }

    return queryList.getData(parseExpertInquiries);
};
const useGetExpertInquiryList = (
    filters: userExpertInquiryListDto,
    enabled: boolean = false,
) => {
    return useQuery(
        [queryKeys.EXPERTINQUIRIES, filters],
        () => fetchExpertInquiryList(filters),
        {
            enabled,
        },
    );
};

const fetchSidoList = async () => {
    const response = await axiosInstance.get(`${axiosPath.address}/sidos`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: any = response.data;

    queryList.setTotalCount(1);
    queryList.setDataList(responseData);

    return queryList.getData(() => {
        return responseData;
    });
};

const useGetSidoList = () => {
    return useQuery([queryKeys.SIDOLIST], () => fetchSidoList(), {
        enabled: false,
    });
};

const fetchSggList = async (params: string) => {
    const response = await axiosInstance.get(`${axiosPath.address}/sggs`, {
        params: {
            sidoCode: params,
        },
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: any = response.data;

    queryList.setTotalCount(1);
    queryList.setDataList(responseData);

    return queryList.getData(() => {
        return responseData;
    });
};

const useGetSggList = (filters: string, enabled: boolean = false) => {
    return useQuery([queryKeys.SGGLIST, filters], () => fetchSggList(filters), {
        enabled,
    });
};

const fetchAgentList = async (params: AgentParamsDto) => {
    const response = await axiosInstance.get(`${axiosPath.agentList}`, {
        params,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: any = response.data;
    queryList.setTotalCount(1);
    queryList.setDataList(responseData);

    return queryList.getData(() => {
        return responseData;
    });
};

const useGetAgentList = (filters: AgentParamsDto, enabled: boolean = false) => {
    return useQuery(
        [queryKeys.AGENTLIST, filters],
        () => fetchAgentList(filters),
        {
            enabled,
        },
    );
};

const fetchOfficeList = async (params: OfficeParamsDto) => {
    const response = await axiosInstance.get(`${axiosPath.officeList}`, {
        params,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: any = response.data;
    queryList.setTotalCount(1);
    queryList.setDataList(responseData);

    return queryList.getData(() => {
        return responseData;
    });
};

const useGetOfficeList = (
    filters: OfficeParamsDto,
    enabled: boolean = false,
) => {
    return useQuery(
        [queryKeys.OFFICELIST, filters],
        () => fetchOfficeList(filters),
        {
            enabled,
        },
    );
};

//* expert manager add
const useSaverExpertManager = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = (err) => {
        console.log("err", err);
    },
) => {
    return useMutation(
        async (params: IExpertManagerDto) => {
            try {
                const result = await axiosInstance.post(
                    `${axiosPath.userExpert}/registerManager`,
                    params,
                );
                return result;
            } catch (Err) {
                console.log("saveErr>>>", Err);
                throw Err;
            }
        },
        {
            onSuccess,
            onError,
        },
    );
};

//* expert manager remove
const useSaverExpertManagerRemove = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = (err) => {
        console.log("err", err);
    },
) => {
    return useMutation(
        async (params: IExpertManagerDto) => {
            try {
                const result = await axiosInstance.post(
                    `${axiosPath.userExpert}/unregisterManager`,
                    params,
                );
                return result;
            } catch (Err) {
                console.log("saveErr>>>", Err);
                throw Err;
            }
        },
        {
            onSuccess,
            onError,
        },
    );
};

//* expert detail info
const useFindExpertDetail = (expertNo: string, enabled: boolean = false) => {
    return useQuery<IExpertDetail>(
        ["expertAdCount", "expertInquiryCount", expertNo],
        async () => {
            const result = await axiosInstance
                .get(`${axiosPath.userExpert}/expertDetail/${expertNo}`)
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return result;
        },
        { enabled },
    );
};

//* expert ad, inquiry count
const useFindExpertDetailCount = (
    params: IExpertDetailCountDto,
    enabled: boolean = false,
) => {
    return useQuery<IExpertDetailCount>(
        ["expertAdCount", "expertInquiryCount", params],
        async () => {
            const userNo = params.no;
            const newParams: any = { ...params };
            delete newParams?.no;
            const result = await axiosInstance
                .get(`${axiosPath.userExpert}/expertDetailCount/${userNo}`, {
                    params: newParams,
                })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => {
                    console.log("err>>>", err);
                    return err;
                });
            return result;
        },
        { enabled },
    );
};
export {
    usePutExpertProfile,
    usePutExpertInfoFile,
    useUserNotExpertList,
    usePostExperts,
    useGetExpertHome,
    useGetExpertList,
    useGetUserExpert,
    useUpdateExperts,
    useGetChangeHistory,
    useGetChangeHistoryDetails,
    useGetExpertInquiryList,
    useGetSidoList,
    useGetSggList,
    useGetAgentList,
    useGetOfficeList,
    useSaverExpertManager,
    useSaverExpertManagerRemove,
    useFindExpertDetailCount,
    useFindExpertDetail,
};
