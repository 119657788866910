import React, { useState } from "react";
import { css } from "@emotion/react";
import { IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTime } from "@src/utils/dateUtil";
import {
    getPushCategoryType,
    PushCodeAndColors,
} from "@src/constants/PushStatus";
import {
    UserStatus,
    ExpertStatus,
    SubscriptionStatus,
} from "@src/constants/UserStatus";

export const getReceiveColumns = () =>
    //     { }:
    //         navigate,
    //  setDeleteNo,
    // setIsDelete,
    // setIsList,
    //  setListNo,
    //         any
    {
        return [
            // {
            //     Header: "이름",
            //     accessor: "name",
            //     Cell: ({ cell: { value } }: any) => (
            //         <div
            //             style={{
            //                 minWidth: "80px",
            //                 width: "100%",
            //                 textAlign: "center",
            //             }}
            //         >
            //             {value ? value : INVALID_VALUE}
            //         </div>
            //     ),
            // },
            // {
            //     Header: "연락처",
            //     accessor: "phone",
            //     Cell: ({ cell: { value } }: any) => (
            //         <div
            //             style={{
            //                 minWidth: "120px",
            //                 width: "100%",
            //                 textAlign: "center",
            //             }}
            //         >
            //             {!value
            //                 ? INVALID_VALUE
            //                 : value.length == 11
            //                 ? value.replace(
            //                       /^(\d{2,3})(\d{3,4})(\d{4})$/,
            //                       `$1-$2-$3`,
            //                   )
            //                 : value}
            //         </div>
            //     ),
            // },
            // {
            //     Header: "이메일",
            //     accessor: "id",
            //     Cell: ({ cell: { value } }: any) => (
            //         <div
            //             style={{
            //                 minWidth: "200px",
            //                 width: "100%",
            //                 textAlign: "center",
            //                 overflow: "hidden",
            //                 textOverflow: "ellipsis",
            //             }}
            //         >
            //             {value}
            //         </div>
            //     ),
            // },
            // {
            //     Header: "생년월일",
            //     accessor: "birthdate",
            //     Cell: ({ cell: { value } }: any) => (
            //         <div
            //             style={{
            //                 minWidth: "100px",
            //                 width: "100%",
            //                 textAlign: "center",
            //             }}
            //         >
            //             {/* {value ? value : INVALID_VALUE} */}
            //             {!value
            //                 ? INVALID_VALUE
            //                 : value.length == 8
            //                 ? value.replace(
            //                       /^(\d{4})(\d{2})(\d{2})$/,
            //                       `$1.$2.$3`,
            //                   )
            //                 : value}
            //         </div>
            //     ),
            // },
            // {
            //     Header: "성별",
            //     accessor: "gender",
            //     Cell: ({ cell: { value } }: any) => (
            //         <div
            //             style={{
            //                 minWidth: "50px",
            //                 width: "100%",
            //                 textAlign: "center",
            //             }}
            //         >
            //             {value ? value : INVALID_VALUE}
            //         </div>
            //     ),
            // },
            // {
            //     Header: "가입 일시",
            //     accessor: "createdAt",
            //     Cell: ({ cell: { value } }: any) => (
            //         <div
            //             style={{
            //                 minWidth: "180px",
            //                 fontSize: "13px",
            //                 width: "100%",
            //                 textAlign: "center",
            //             }}
            //         >
            //             {ConvertDateTime(value ?? 0)}
            //         </div>
            //     ),
            // },
            // {
            //     Header: "상태",
            //     accessor: "userStatus",
            //     Cell: ({ cell: { value } }: any) => {
            //         const [text, color] = UserStatus[value];
            //         return (
            //             <div
            //                 style={{
            //                     minWidth: "100px",
            //                     display: "flex",
            //                     width: "100%",
            //                     justifyContent: "center",
            //                     textAlign: "center",
            //                 }}
            //             >
            //                 <Label color={color} text={text} />
            //             </div>
            //         );
            //     },
            // },
            {
                Header: "이름",
                accessor: "name",
                Cell: ({ cell: { value } }: any) => {
                    return <div css={maxLowContain}>{value}</div>;
                },
            },
            {
                Header: "연락처",
                accessor: "phone",
                Cell: ({ cell: { value } }: any) => {
                    return (
                        <div css={maxLowContain}>
                            {getPushCategoryType(value)}
                        </div>
                    );
                },
            },
            {
                Header: "이메일",
                accessor: "id",
                Cell: ({ cell: { value } }: any) => (
                    <div css={maxLowContain}>{value}</div>
                ),
            },
            {
                Header: "생년월일",
                accessor: "birthdate",
                Cell: ({ cell: { value } }: any) => (
                    <div css={maxLowContain}>{value}</div>
                ),
            },
            {
                Header: "성별",
                accessor: "gender",
                Cell: ({ cell: { value } }: any) => {
                    const genderText = value ? "남자" : "여자";
                    return (
                        <div
                            css={maxLowContain}
                            // style={{
                            //     textAlign: "center",
                            //     overflow: "hidden",
                            //     whiteSpace: "nowrap",
                            //     display: "inline-block",
                            //     marginRight: "20px",
                            // }}
                        >
                            <a href={value} target="_blank" rel="noreferrer">
                                {genderText}
                            </a>
                        </div>
                    );
                },
            },
            {
                Header: "가입일시",
                accessor: "createdAt",
                Cell: ({ cell: { value } }: any) => (
                    <div css={maxLowContain}>
                        <div>{value?.slice(0, 10)}</div>
                        <div>{value?.slice(11, -5)}</div>
                    </div>
                ),
            },
            {
                Header: "상태",
                accessor: "status",
                Cell: ({ cell: { value } }: any) => {
                    const [text, color] = UserStatus[value];
                    return (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                maxWidth: "100px",
                            }}
                        >
                            <Label color={color} text={text} />
                        </div>
                    );
                },
            },
            {
                Header: "오픈",
                accessor: "isOpened",
                Cell: ({ cell: { value }, row }: any) => {
                    const code = Number(row.original.status);
                    const openIcon = value ? "O" : "X";
                    const trashDisabled = code >= 400;
                    const editDisabled = code >= 400;
                    return <div css={maxLowContain}>{openIcon}</div>;
                },
            },
        ];
    };
const maxLow = css`
    width: 170px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const maxLowContain = css`
    width: fit-content;
    min-width: 100px;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
