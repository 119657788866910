import styled from "@emotion/styled";
import { ConfirmModal, PageTable, Title } from "@src/components";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import {
    getQueryString,
    useDeungiList,
    useUpdateDeungiState,
} from "@src/hooks/queries/useDeungi";
import { DeungiListDto } from "@src/interfaces/Deungi.interface";
import { endLoading, startLoading } from "@src/reducers/loading";
import { getQueryData } from "@src/utils/useQueryClient";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getColumns } from "./DeungiListColumns";
import DeungiListFilter from "./DeungiListFilter";

const DeungiList = () => {
    const dispatch = useDispatch();
    const pagesInitialState = {
        pageIndex: 0,
        pageSize: 10,
    };

    const initParams = {
        deungiState: [],
        crawlingType: [],
        name: "",
        email: "",
        address: "",
        pin: "",
        ticketNo: 0,
        limit: 10,
        page: 1,
    };

    const [filter, setFilter] = useState<DeungiListDto>(initParams);
    const [list, setList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const newQuery = getQueryString(filter);
    const [isCancelModalShow, setCancelIsModalShow] = useState<boolean>(false);
    const [deungiId, setDeungiId] = useState<string>("");
    const [isReopenModalShow, setIsReopenModalShow] = useState<boolean>(false);

    const queryData: { result: any[]; total: number } = getQueryData([
        queryKeys.DEUNGI_LIST,
        newQuery,
    ]);

    const columns = getColumns(
        setCancelIsModalShow,
        setDeungiId,
        setIsReopenModalShow,
    );
    const {
        isLoading,
        data = { result: [], total: 0 },
        isError,
        refetch,
    } = useDeungiList(filter);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (queryData) {
            setList(queryData.result);
            setTotalCount(queryData.total);
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setList(data.result);
                if (filter.page === 1) {
                    setTotalCount(data.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [isLoading, isError, data.result]);

    return (
        <>
            <StyledDeungiWrap>
                <Title title="등기 열람 관리" />
                <DeungiListFilter
                    refetch={refetch}
                    // filter={filter}
                    setFilter={setFilter}
                    initParams={initParams}
                />
                <TotalCountBox>
                    총 {totalCount.toLocaleString()}건
                </TotalCountBox>
                <PageTable
                    FixedHeight={700}
                    columns={columns}
                    data={list}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        const limit = 10;
                        setFilter({
                            ...filter,
                            page: page,
                            limit: limit,
                        });
                    }}
                    initialState={pagesInitialState}
                    totalCount={totalCount}
                />
                <ConfirmModal
                    buttonText="확인"
                    handleCancel={() => {
                        setCancelIsModalShow(false);
                    }}
                    handleOk={() => {
                        useUpdateDeungiState("열람취소", deungiId).then(() => {
                            setCancelIsModalShow(false);
                            refetch();
                        });
                    }}
                    isModalShow={isCancelModalShow}
                >
                    정말 취소하시겠습니까? 회원의 등기 내역에서 해당 내역이
                    취소됩니다.
                </ConfirmModal>
                <ConfirmModal
                    buttonText="확인"
                    handleCancel={() => {
                        setIsReopenModalShow(false);
                    }}
                    handleOk={() => {
                        useUpdateDeungiState("대기중", deungiId).then(() => {
                            setIsReopenModalShow(false);
                            refetch();
                        });
                    }}
                    isModalShow={isReopenModalShow}
                >
                    정말 재열람하시겠습니까? 인터넷 등기소에서 700원이
                    결제됩니다.
                </ConfirmModal>
            </StyledDeungiWrap>
        </>
    );
};

export default DeungiList;

const TotalCountBox = styled.div`
    margin: 12px;
    font-size: 14px;
    line-height: 17px;
`;

const StyledDeungiWrap = styled.div`
    padding: 40px;
    width: 100%;
`;
