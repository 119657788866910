import { createSlice } from "@reduxjs/toolkit";
import { LesseeStateType } from "@src/pages/product/types";

const sampleData: LesseeStateType = {
    table: [
        {
            id: "0",
            lessee: "(주)우림원건설",
            occupied: "점포 1층 전부",
            confirmInfo:
                "사업등록: 2018.04.24 \n확정일자: 미상 \n배당요구: 2021.08.03",
            depositInfo: "보 7000000원 \n월 500000원 \n환산 5,700만원",
            power: "있음",
            expectedPrice: "전액매수인인수",
            additional: "정철우",
        },
        {
            id: "1",
            lessee: "주식회사베네핏펀드(이남경)",
            occupied: "점포 1층 101호(34㎡)",
            confirmInfo:
                "사업등록: 2018.01.28 \n확정일자: 미상 \n배당요구: 없음",
            depositInfo: "",
            power: "없음",
            expectedPrice: "배당금 없음",
            additional: "임차권등기자, \n[등기부상보: 30,000,000원]",
        },
    ],
    addInfo:
        "본건 전입세대주 공유자 박기범(채무자 겸 공유자 박은자의 배우자)과의 전화문답에 의하면 본건 부동산에는 본인들 가족들(주민등록상 전입세대원 전원)이 거주한다하니 그 점유관계(공유자들간 점유관계)등은 별도의 확인을 요함.",
};

const initialState = sampleData;

export const LesseeInfoSlice = createSlice({
    name: "LesseeInfo",
    initialState,
    reducers: {
        createLesseeInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateLesseeInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createLesseeInfo, updateLesseeInfo } = LesseeInfoSlice.actions;

export default LesseeInfoSlice.reducer;
