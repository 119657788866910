import styled from "@emotion/styled";
import { ConfirmModal, EditModal, Title, ViewMoreTable } from "@src/components";
import { useUpdateDeungi } from "@src/hooks/queries/useUserDetail";
import { DeungiDetailList } from "@src/interfaces/User.interface";
import React, { Dispatch, SetStateAction, useState } from "react";
import { getUserDeungiEditColumns } from "./UserDeungiEditColumns";

interface UserDeungiEditModalProps {
    isModalShow: boolean;
    setIsModalShow: Dispatch<SetStateAction<boolean>>;
    deungiTicket: DeungiDetailList;
    setDeungiRefech: Dispatch<SetStateAction<boolean>>;
    setIsCheckModalShow: Dispatch<SetStateAction<boolean>>;
    setEditTime: Dispatch<SetStateAction<string>>;
    editTime: string;
    setDeungiType: Dispatch<SetStateAction<string>>;
}

const UserDeungiEditModal = ({
    isModalShow,
    setIsModalShow,
    deungiTicket,
    setDeungiRefech,
    setIsCheckModalShow,
    setEditTime,
    editTime,
    setDeungiType,
}: UserDeungiEditModalProps) => {
    const columns = getUserDeungiEditColumns(setEditTime);

    return (
        <>
            <EditModal
                isModalShow={isModalShow}
                buttonText="저장"
                handleBtnClick={() => {
                    setDeungiType("edit");
                    setIsCheckModalShow(true);
                }}
                handleOpenModal={() => {
                    setIsModalShow(false);
                }}
                size="big"
                title="실시간 등기열람권 정보"
                isDisabled={!editTime}
            >
                <>
                    <StyledEditContents>
                        <Title title="충전내역" />
                        <ViewMoreTable
                            FixedHeight={100}
                            columns={columns}
                            data={[deungiTicket]}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </StyledEditContents>
                </>
            </EditModal>
        </>
    );
};

export default UserDeungiEditModal;

const StyledEditContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
        & > button {
            display: none;
        }
    }
`;
