import React, { useState, useEffect, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import {
    Title,
    DropDown,
    Button,
    TextBox,
    ConfirmModal,
    Input,
    EditModal,
    UpdateCommentModal,
} from "@src/components";
import { ConsentBox } from "./UserDetail";
import { SUSPENDED_USER, NORMAL_USER } from "@src/constants/UserStatus";
import { UserInfoContainer, LogBox, FavoriteInfo } from "./containers";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { endLoading, startLoading } from "@src/reducers/loading";
import { useParams } from "react-router-dom";
import { DetailUserProps, UpdateUserDto } from "@src/interfaces/User.interface";
import {
    useGetUserDetailData,
    usePostUserPWEmail,
    useDeleteUser,
    useUpdateUserData,
    usePatchTestUserPassword,
} from "@src/hooks/queries/useUserDetail";
import { UserStatus } from "@src/constants/UserStatus";
import { validChecker } from "@src/utils/validationCheck";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserUpdate() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDisabledEnterModalShow, setIsDisabledEnterModalShow] =
        useState(false);
    const { id } = useParams();
    const userNo = Number(id);
    const [user, setUser] = useState<DetailUserProps>();

    const {
        isLoading,
        data,
        isError,
        refetch: detailRefetch,
    } = useGetUserDetailData(userNo);

    useEffect(() => {
        if (!isLoading && !isError && data) {
            setUser(data);
        } else if (data === null) {
            setIsDisabledEnterModalShow((prev) => !prev);
        }
    }, [isLoading, isError, data]);

    const [patchUserData, setPatchUserData] = useState<any>("");
    const handlePatchUserData = (inputs: any) => {
        setPatchUserData({
            ...patchUserData,
            ...inputs,
        });
    };
    const [errorMessage, setErrorMessage] = useState<any>("");
    const [isModalShow, setIsModalShow] = useState(false);
    const handleOpenModal = () => setIsModalShow((prev) => !prev);
    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        patchUserData.edit && setIsDisabled(false);
    }, [patchUserData]);
    useEffect(() => {
        setErrorMessage("");
    }, [patchUserData["updateReason"]]);

    //탈퇴
    const [deleteComments, setDeleteComments] = useState("");
    const [isDeleteModalShow, setDeleteModalShow] = useState(false);
    const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(true);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<any>("");
    const handleDeleteOpenModal = () => {
        setDeleteComments("");
        setDeleteErrorMessage("");
        setIsDeleteBtnDisabled(true);
    };
    const { mutate: deleteMutate } = useDeleteUser(userNo);
    const onDelete = (params: any) => {
        setDeleteModalShow((prev) => !prev);
        dispatch(startLoading());
        deleteMutate(params, {
            onSuccess: () => {
                handleDeleteOpenModal();
                navigate("/users/list");
            },
            onError: (error) => {
                setDeleteModalShow((prev) => !prev);
                setDeleteErrorMessage(error);
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };

    const { mutate: updateMutate } = useUpdateUserData(userNo);
    const onUpdate = (params: any) => {
        updateMutate(params, {
            onSuccess: () => {
                detailRefetch();
                navigate("/users/list");
                setErrorMessage("");
            },
            onError: (error) => {
                console.log(error);
                setErrorMessage(error);
            },
        });
    };
    const handleSubmit = (updateComment: string) => {
        handleOpenModal();
        let newData = {
            updateReason: "",
            userEmail: "",
            userStatus: 0,
            agreements: {},
        };

        // 변경값이 있는지 비교
        for (let value of Object.keys(patchUserData)) {
            let v = value as keyof DetailUserProps;
            if (user?.[v] !== patchUserData[v]) {
                newData = { ...newData, [v]: patchUserData[v] };
            }
            if (patchUserData.userStatus === "unchanged") {
                typeof user?.userStatus === "number" &&
                    (newData.userStatus = user?.userStatus);
            }
        }
        // 업데이트 파라미터 생성
        const params: UpdateUserDto = {
            comments: updateComment,
            ...(newData.userEmail && { id: newData?.userEmail }),
            ...(newData.userStatus && { status: Number(newData?.userStatus) }),
            ...patchUserData.agreements, //알람 수신은 boolean 으로 비교하지 않고 무조건 보낸다.
        };
        onUpdate(params);
    };
    const handleCancel = () => {
        handleOpenModal();
    };
    return (
        <div css={updateStyle}>
            <Title title="회원 정보 수정" />
            <UserInfoContainer
                user={user}
                isUpdate={true}
                handlePatchUserData={handlePatchUserData}
            />
            <FavoriteInfo />
            <ConsentBox
                isUpdate={true}
                initData={{
                    agreements: user?.agreements[0],
                    trial: user?.trial,
                    membership: user?.subscriptionStatus,
                }}
                handlePatchUserData={handlePatchUserData}
            />
            <UpdateBox
                user={user}
                handlePatchUserData={handlePatchUserData}
                userNo={userNo}
            />
            <LogBox userNo={userNo} />
            <div className="buttonBox">
                <p className="updateError">{errorMessage}</p>
                <Button
                    label="탈퇴"
                    color={"black"}
                    size="medium"
                    handleClick={() => setDeleteModalShow((prev) => !prev)}
                    isDisabled={false}
                />
                <Button
                    label="수정"
                    color="gd"
                    size="medium"
                    handleClick={handleOpenModal}
                    isDisabled={isDisabled}
                />
            </div>
            <UpdateCommentModal
                isModalShow={isModalShow}
                setIsModalShow={setIsModalShow}
                handleSubmit={handleSubmit}
                title={"회원 정보를 수정하시겠습니까?"}
            />
            {/* 탈퇴 모달 */}
            <EditModal
                isModalShow={isDeleteModalShow}
                buttonText={"탈퇴"}
                title={
                    "회원 탈퇴 시, 로그인 할 수 없으며 일정 기간후 회원정보가 삭제됩니다."
                }
                handleOpenModal={() => {
                    setDeleteModalShow((prev) => !prev);
                    handleDeleteOpenModal();
                }}
                size={"fitContent"}
                handleBtnClick={() => {
                    onDelete({
                        id: user?.userEmail,
                        comments: deleteComments,
                    });
                }}
                isDisabled={isDeleteBtnDisabled}
                errorMessage={deleteErrorMessage}
            >
                <div className="pwModalContents">
                    <Input
                        label={"사유"}
                        placeholder={"사유를 입력하세요."}
                        type={"text"}
                        size={"medium"}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setDeleteComments(value);
                            const { result, errMsg } = validChecker(
                                "spacebar",
                                value,
                            );
                            setIsDeleteBtnDisabled(result);
                            setDeleteErrorMessage(errMsg);
                        }}
                        isDisabled={false}
                        name={"comments"}
                        value={deleteComments}
                    />
                </div>
            </EditModal>
            {/* 탈퇴/탈퇴 예정 회원의 페이지 진입시 진입불가 알림 팝업 */}
            <EditModal
                isModalShow={isDisabledEnterModalShow}
                title={"알림"}
                buttonText={"확인"}
                children={
                    "이미 탈퇴하였거나 탈퇴 예정인 회원입니다. 회원목록으로 이동합니다."
                }
                handleOpenModal={() => {
                    navigate("/users/list");
                }}
                size={"medium"}
                handleBtnClick={() => {
                    navigate("/users/list");
                    setIsDisabledEnterModalShow((prev) => !prev);
                }}
                isDisabled={false}
                errorMessage={""}
            />
        </div>
    );
}
function UpdateBox({ user, handlePatchUserData, userNo }: any) {
    const dispatch = useDispatch();
    const [isModalShow, setIsModalShow] = useState(false);
    const handleOpenModal = () => {
        setPwErrorMessage("");
        setParams({ ...params, comments: "" });
        setIsPWBtnDisabled(true);
    };

    const dropList = [
        { key: "unchanged", value: "선택 안함" },
        { key: 200, value: NORMAL_USER },
        { key: 102, value: SUSPENDED_USER },
    ];

    const handleDrop = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        handlePatchUserData({ userStatus: value, edit: true });
    };

    const [params, setParams] = useState({
        id: user?.userEmail,
        status: user?.userStatus,
        comments: "",
    });
    useEffect(() => {
        setParams({
            id: user?.userEmail,
            status: user?.userStatus,
            comments: "",
        });
    }, [user]);
    const { mutate: postPWEmailMutate } = usePostUserPWEmail(userNo);
    const onPostPWEmail = () => {
        setIsModalShow((prev) => !prev);
        dispatch(startLoading());
        postPWEmailMutate(params, {
            onSuccess: () => {
                handleOpenModal();
            },
            onError: (error) => {
                setIsModalShow((prev) => !prev);
                setPwErrorMessage(error);
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };
    //회원 상태
    const [text] = UserStatus[user?.userStatus || 0];
    const [pwErrorMessage, setPwErrorMessage] = useState<any>("");
    const [isPWBtnDisabled, setIsPWBtnDisabled] = useState(true);

    const [password, setPassword] = useState("");

    const { mutate: patchTestUserPassword } = usePatchTestUserPassword(userNo);
    const updateTestUserPassword = () => {
        patchTestUserPassword(password, {
            onSuccess: () => {
                toast.success("비밀번호가 변경되었습니다.");
            },
            onError: (error) => {
                console.log(error);
            },
        });
    };

    return (
        <div css={updateBoxDiv}>
            <ul>
                <li>
                    <span>비밀번호</span>
                    <Button
                        color="gd"
                        handleClick={() => setIsModalShow((prev) => !prev)}
                        label="임시 비밀번호 전송"
                        size={"medium"}
                        isDisabled={false}
                    />
                    <p className={"pwNotice"}>
                        클릭시, 회원 이메일로 비밀번호 변경을 위한 이메일이
                        전송됩니다.
                    </p>
                </li>
                <li>
                    <span>상태</span>
                    <span className="fitContentSpan">
                        <Input
                            label={""}
                            placeholder={""}
                            size={"small"}
                            handleChange={() => {}}
                            isDisabled={true}
                            errorMessage={""}
                            name={"status"}
                            value={text}
                        />
                    </span>
                    <span>상태 변경</span>
                    <span>
                        <DropDown
                            data={dropList}
                            handleChange={handleDrop}
                            defaultValue={""}
                            border={false}
                        />
                    </span>
                </li>
                {user?.userEmail.lastIndexOf("@msga.msg") > -1 && (
                    <li>
                        <span>비밀번호 변경</span>
                        <span className="fitContentSpan">
                            <Input
                                label={""}
                                placeholder={""}
                                size={"small"}
                                handleChange={(e) => {
                                    const { value } = e.target;
                                    setPassword(value);
                                }}
                                isDisabled={false}
                                errorMessage={""}
                                name={"password"}
                                value={password}
                            />
                        </span>
                        <Button
                            color="gd"
                            handleClick={() => updateTestUserPassword()}
                            label="비밀번호 변경"
                            size={"medium"}
                            isDisabled={false}
                        />
                        <p className={"pwNotice"}>
                            테스트 계정(*@msga.msg)만 변경 가능합니다.
                        </p>
                    </li>
                )}
            </ul>
            <EditModal
                isModalShow={isModalShow}
                buttonText={"전송"}
                title={"회원에게 비밀번호 변경 이메일이 전송됩니다."}
                handleOpenModal={() => {
                    setIsModalShow((prev) => !prev);
                    handleOpenModal();
                }}
                size={"fitContent"}
                handleBtnClick={() => {
                    onPostPWEmail();
                }}
                isDisabled={isPWBtnDisabled}
                errorMessage={pwErrorMessage}
            >
                <div className="pwModalContents">
                    <Input
                        label={"사유"}
                        placeholder={"사유를 입력하세요."}
                        type={"text"}
                        size={"medium"}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setParams({
                                ...params,
                                comments: value,
                            });
                            const { result, errMsg } = validChecker(
                                "spacebar",
                                value,
                            );
                            setIsPWBtnDisabled(result);
                            setPwErrorMessage(errMsg);
                        }}
                        isDisabled={false}
                        name={"comments"}
                        value={params.comments}
                    />
                </div>
            </EditModal>
            <ToastContainer />
        </div>
    );
}

const updateStyle = css`
    padding: 40px;
    width: 100%;
    & > div:first-of-type {
        margin-bottom: 60px;
    }
    & > div:nth-of-type(n + 2) {
        & > div {
            margin-bottom: 25px;
        }
    }
    & > ul {
        margin-top: 20px;
        margin-bottom: 60px;
    }

    .buttonBox {
        width: 100%;
        padding-top: 40px;
        padding-right: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
    .pwNotice {
        ${Common.textStyle.body3_R14}
        color: ${Common.colors.gray400};
        padding-left: 20px;
    }
    .modalContents {
        p {
            padding-top: 10px;
            font-size: 18px;
            font-weight: 500;
        }
        span {
            padding-right: 10px;
            font-weight: 500;
        }
        input {
            margin-top: 15px;
            margin-bottom: 40px;
        }
    }
    .pwModalContents {
        span {
            padding-right: 10px;
            font-weight: 500;
        }
        input {
            margin-right: 20px;
        }
    }
`;
const updateBoxDiv = css`
    ul > li {
        display: flex;
        margin-bottom: 25px;
        align-items: center;
        span {
            width: 100px;
            ${Common.textStyle.title5_M14}
            &.fitContentSpan {
                width: fit-content;
                padding-right: 40px;
                input:disabled {
                    background-color: transparent;
                }
            }
        }
        &:nth-of-type(1) span:nth-of-type(3) {
            color: ${Common.colors.gray500};
        }
    }
    li:nth-of-type(4) {
        & > div {
            gap: 0;
        }
    }
    li:last-of-type {
        p {
            ${Common.textStyle.title6_R14}
            color: ${Common.colors.orange}
        }
    }
    select {
        border-bottom: 1.5px solid ${Common.colors.gray200};
        border-radius: 0;
        padding-bottom: 10px;
    }
`;
export default UserUpdate;
