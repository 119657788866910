import styled from "@emotion/styled";
import { Button } from "@src/components";
import React, { useEffect, useRef, useState } from "react";
import { REGION_LIST, ROADPLAN_REGION_LIST, STATE_LAND_REGION_TYPE } from "./regionInfo";

interface Props {
    visible: boolean;
    setVisible: (isVisible: boolean) => void;
    selectRegion: string[];
    setSelectRegion: (regions: string[]) => void;
    type?: string;
}

const RegionSelectBox = ({ visible, setVisible, selectRegion, setSelectRegion, type }: Props) => {
    const [select, setSelect] = useState(selectRegion);
    const regionList = Object.values(type !== "road_plan" ? REGION_LIST : ROADPLAN_REGION_LIST);

    const onRegionClickHandler = (region: string) => {
        if (region === REGION_LIST[0].name && !select.includes(region)) {
            setSelect([REGION_LIST[0].name]);
        } else {
            const regionArr = select.slice();

            if (regionArr.includes(region)) regionArr.splice(regionArr.indexOf(region), 1);
            else regionArr.push(region);

            setSelect(regionArr);
        }
    };

    const onSubmitHandler = () => {
        setSelectRegion(select);
        setVisible(false);
    };

    useEffect(() => {
        // 전국이 존재하지 않지만 모든 지역 클릭시
        if (!select.includes(REGION_LIST[0].name) && select.length === regionList.length - 1) {
            setSelect([REGION_LIST[0].name]);
        } else if (select.includes(REGION_LIST[0].name) && select.length > 1) {
            const regionArr = select.slice();
            regionArr.splice(regionArr.indexOf(REGION_LIST[0].name), 1);
            setSelect(regionArr);
        }
    }, [select]);

    useEffect(() => {
        setSelect(selectRegion);
    }, [visible]);

    return visible ? (
        <Container>
            <div className={"regionBox"}>
                <h3>지역 선택</h3>
                <button className={"exitBtn"} onClick={() => setVisible(false)}>
                    닫기
                </button>
                <div className={"selectBox"}>
                    {regionList.map((el, idx) => {
                        return (
                            <button
                                disabled={el.disable}
                                onClick={() => onRegionClickHandler(el.name)}
                                className={select.includes(el.name) ? "active" : ""}
                                key={idx}
                            >
                                {el.name}
                            </button>
                        );
                    })}
                </div>
                <Button
                    color="gd"
                    handleClick={onSubmitHandler}
                    label="저장"
                    size="small"
                    isDisabled={select.length === 0}
                />
            </div>
        </Container>
    ) : (
        <></>
    );
};

const Container = styled.div`
    position: absolute;
    top: 0;
    width: 485px;
    left: 400px;
    height: 230px;
    border: 1px solid #d1d1d1;
    background: #fff;
    z-index: 2;

    .regionBox {
        padding: 20px;
        & > h3 {
            font-size: 16px;
            margin-bottom: 20px;
        }
        .exitBtn {
            position: absolute;
            top: 0;
            right: 0;
            width: 70px;
            height: 50px;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
        }
        .selectBox {
            & > button {
                width: 65px;
                height: 30px;
                background: #f3f3f3;
                color: #000;
                margin: 0 10px 10px;
                margin-left: 0;
                border-radius: 5px;
                cursor: pointer;
                &:disabled {
                    text-decoration: line-through;
                    cursor: not-allowed;
                }

                &.active {
                    background: #656565;
                    color: #fff;
                }
                &:nth-last-of-type(6n-2) {
                    margin-right: 0;
                }
            }
        }
        .submitBtn {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
`;

export default RegionSelectBox;
