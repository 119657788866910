import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery, useQueryClient } from "react-query";
import { AdminType } from "@src/types/adminType";
import { queryKeys } from "./queryKeys";

const initialAdminDetail = {
    admin_name: "아이유",
    admin_phone: "010-i-u",
    admin_rank: "프로",
    user_no: null,
    comments: null,
    created_by: 100,
    updated_by: 100,
    created_at: "2022-07-14 01:33:20",
    updated_at: "2022-07-14 01:33:20",
};

const fetchData = async () => {
    const response = await axiosInstance.get(axiosPath.admin);
    const { data } = response.data.data;

    if (!response.status || data.length === 0) {
        throw new Error("Problem fetching data");
    }

    return data[0];
};

const useLoginDetail = () => {
    return useQuery<AdminType, Error>(queryKeys.LOGIN_DETAIL, fetchData, {
        initialData: useQueryClient().getQueryData([queryKeys.LOGIN_DETAIL]),
    });
};

export { useLoginDetail };
