import React, { useEffect, useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import ckBoxIcon from "@src/assets/checkboxIcon.png";

interface ICheckbox {
    isDisabled?: boolean;
    isChecked?: boolean;
    label: string;
    defaultValue?: any;
    handleChange: (key: string, checked: boolean, displayName?: string) => void;
}

function Checkbox({
    isDisabled = false,
    isChecked = false,
    label,
    defaultValue,
    handleChange,
}: ICheckbox) {
    const CHECKBOX_ID = "chBox";
    const [checked, setChecked] = useState(isChecked);
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        const displayName = e.currentTarget.innerText;
        if (isDisabled === false) {
            setChecked(!checked);
            handleChange(defaultValue || CHECKBOX_ID, !checked, displayName);
        }
    };

    useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);

    return (
        <div
            css={[checkboxStyle, disabled[isDisabled ? "active" : "inactive"]]}
            onClick={handleClick}
        >
            <input
                type="checkbox"
                checked={checked}
                id={CHECKBOX_ID}
                disabled={isDisabled}
                onChange={() => {}}
                value={defaultValue}
            />
            <label htmlFor="ckBox"></label>
            {label && <span>&nbsp;{label}</span>}
        </div>
    );
}

const checkboxStyle = css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    line-height: 25px;
    align-items: center;
    label {
        display: block;
        width: 18px;
        height: 18px;
        background: url(${ckBoxIcon}) no-repeat;
        background-size: 81px;
    }
    input[type="checkbox"] {
        display: none;
    }
    span {
        font-size: 14px;
    }
`;
const disabled = {
    active: css`
        label {
            background-position: -39px -1px;
        }
        input[type="checkbox"]:checked + label {
            background-position: -60px -1px;
        }
    `,
    inactive: css`
        label {
            background-position: 1px -1px;
        }
        input[type="checkbox"]:checked + label {
            background-position: -19px -1px;
        }
    `,
};
export default Checkbox;
