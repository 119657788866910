export const LocationMediumCategory: { [key: number]: any } = {
    11: {
        서울전체: {
            name: "서울전체",
            code: "999",
        },
        종로구: {
            name: "종로구",
            code: "110",
        },
        중구: {
            name: "중구",
            code: "140",
        },
        용산구: {
            name: "용산구",
            code: "170",
        },
        성동구: {
            name: "성동구",
            code: "200",
        },
        광진구: {
            name: "광진구",
            code: "215",
        },
        동대문구: {
            name: "동대문구",
            code: "230",
        },
        중랑구: {
            name: "중랑구",
            code: "260",
        },
        성북구: {
            name: "성북구",
            code: "290",
        },
        강북구: {
            name: "강북구",
            code: "305",
        },
        도봉구: {
            name: "도봉구",
            code: "320",
        },
        노원구: {
            name: "노원구",
            code: "350",
        },
        은평구: {
            name: "은평구",
            code: "380",
        },
        서대문구: {
            name: "서대문구",
            code: "410",
        },
        마포구: {
            name: "마포구",
            code: "440",
        },
        양천구: {
            name: "양천구",
            code: "470",
        },
        강서구: {
            name: "강서구",
            code: "500",
        },
        구로구: {
            name: "구로구",
            code: "530",
        },
        금천구: {
            name: "금천구",
            code: "545",
        },
        영등포구: {
            name: "영등포구",
            code: "560",
        },
        동작구: {
            name: "동작구",
            code: "590",
        },
        관악구: {
            name: "관악구",
            code: "620",
        },
        서초구: {
            name: "서초구",
            code: "650",
        },
        강남구: {
            name: "강남구",
            code: "680",
        },
        송파구: {
            name: "송파구",
            code: "710",
        },
        강동구: {
            name: "강동구",
            code: "740",
        },
    },
    41: {
        경기전체: {
            name: "경기전체",
            code: "999",
        },
        수원시: {
            name: "수원시",
            code: "11",
        },
        성남시: {
            name: "성남시",
            code: "13",
        },
        의정부시: {
            name: "의정부시",
            code: "150",
        },
        안양시: {
            name: "안양시",
            code: "17",
        },
        부천시: {
            name: "부천시",
            code: "190",
        },
        광명시: {
            name: "광명시",
            code: "210",
        },
        평택시: {
            name: "평택시",
            code: "220",
        },
        동두천시: {
            name: "동두천시",
            code: "250",
        },
        안산시: {
            name: "안산시",
            code: "27",
        },
        고양시: {
            name: "고양시",
            code: "28",
        },
        과천시: {
            name: "과천시",
            code: "290",
        },
        구리시: {
            name: "구리시",
            code: "310",
        },
        남양주시: {
            name: "남양주시",
            code: "360",
        },
        오산시: {
            name: "오산시",
            code: "370",
        },
        시흥시: {
            name: "시흥시",
            code: "390",
        },
        군포시: {
            name: "군포시",
            code: "410",
        },
        의왕시: {
            name: "의왕시",
            code: "430",
        },
        하남시: {
            name: "하남시",
            code: "450",
        },
        용인시: {
            name: "용인시",
            code: "46",
        },
        파주시: {
            name: "파주시",
            code: "480",
        },
        이천시: {
            name: "이천시",
            code: "500",
        },
        안성시: {
            name: "안성시",
            code: "550",
        },
        김포시: {
            name: "김포시",
            code: "570",
        },
        화성시: {
            name: "화성시",
            code: "590",
        },
        광주시: {
            name: "광주시",
            code: "610",
        },
        양주시: {
            name: "양주시",
            code: "630",
        },
        포천시: {
            name: "포천시",
            code: "650",
        },
        여주시: {
            name: "여주시",
            code: "670",
        },
        연천군: {
            name: "연천군",
            code: "800",
        },
        가평군: {
            name: "가평군",
            code: "820",
        },
        양평군: {
            name: "양평군",
            code: "830",
        },
    },
    28: {
        인천전체: {
            name: "인천전체",
            code: "999",
        },
        중구: {
            name: "중구",
            code: "110",
        },
        동구: {
            name: "동구",
            code: "140",
        },
        미추홀구: {
            name: "미추홀구",
            code: "177",
        },
        연수구: {
            name: "연수구",
            code: "185",
        },
        남동구: {
            name: "남동구",
            code: "200",
        },
        부평구: {
            name: "부평구",
            code: "237",
        },
        계양구: {
            name: "계양구",
            code: "245",
        },
        서구: {
            name: "서구",
            code: "260",
        },
        강화군: {
            name: "강화군",
            code: "710",
        },
        옹진군: {
            name: "옹진군",
            code: "720",
        },
    },
    26: {
        부산전체: {
            name: "부산전체",
            code: "999",
        },
        중구: {
            name: "중구",
            code: "110",
        },
        서구: {
            name: "서구",
            code: "140",
        },
        동구: {
            name: "동구",
            code: "170",
        },
        영도구: {
            name: "영도구",
            code: "200",
        },
        부산진구: {
            name: "부산진구",
            code: "230",
        },
        동래구: {
            name: "동래구",
            code: "260",
        },
        남구: {
            name: "남구",
            code: "290",
        },
        북구: {
            name: "북구",
            code: "320",
        },
        해운대구: {
            name: "해운대구",
            code: "350",
        },
        사하구: {
            name: "사하구",
            code: "380",
        },
        금정구: {
            name: "금정구",
            code: "410",
        },
        강서구: {
            name: "강서구",
            code: "440",
        },
        연제구: {
            name: "연제구",
            code: "470",
        },
        수영구: {
            name: "수영구",
            code: "500",
        },
        사상구: {
            name: "사상구",
            code: "530",
        },
        기장군: {
            name: "기장군",
            code: "710",
        },
    },
    30: {
        대전전체: {
            name: "대전전체",
            code: "999",
        },
        동구: {
            name: "동구",
            code: "110",
        },
        중구: {
            name: "중구",
            code: "140",
        },
        서구: {
            name: "서구",
            code: "170",
        },
        유성구: {
            name: "유성구",
            code: "200",
        },
        대덕구: {
            name: "대덕구",
            code: "230",
        },
    },
    27: {
        대구전체: {
            name: "대구전체",
            code: "999",
        },
        중구: {
            name: "중구",
            code: "110",
        },
        동구: {
            name: "동구",
            code: "140",
        },
        서구: {
            name: "서구",
            code: "170",
        },
        남구: {
            name: "남구",
            code: "200",
        },
        북구: {
            name: "북구",
            code: "230",
        },
        수성구: {
            name: "수성구",
            code: "260",
        },
        달서구: {
            name: "달서구",
            code: "290",
        },
        달성군: {
            name: "달성군",
            code: "710",
        },
    },
    31: {
        울산전체: {
            name: "울산전체",
            code: "999",
        },
        중구: {
            name: "중구",
            code: "110",
        },
        남구: {
            name: "남구",
            code: "140",
        },
        동구: {
            name: "동구",
            code: "170",
        },
        북구: {
            name: "북구",
            code: "200",
        },
        울주군: {
            name: "울주군",
            code: "710",
        },
    },
    36: {
        세종전체: {
            name: "세종전체",
            code: "999",
        },
    },
    29: {
        광주전체: {
            name: "광주전체",
            code: "999",
        },
        동구: {
            name: "동구",
            code: "110",
        },
        서구: {
            name: "서구",
            code: "140",
        },
        남구: {
            name: "남구",
            code: "155",
        },
        북구: {
            name: "북구",
            code: "170",
        },
        광산구: {
            name: "광산구",
            code: "200",
        },
    },
    42: {
        강원전체: {
            name: "강원전체",
            code: "999",
        },
        춘천시: {
            name: "춘천시",
            code: "110",
        },
        원주시: {
            name: "원주시",
            code: "130",
        },
        강릉시: {
            name: "강릉시",
            code: "150",
        },
        동해시: {
            name: "동해시",
            code: "170",
        },
        태백시: {
            name: "태백시",
            code: "190",
        },
        속초시: {
            name: "속초시",
            code: "210",
        },
        삼척시: {
            name: "삼척시",
            code: "230",
        },
        홍천군: {
            name: "홍천군",
            code: "720",
        },
        횡성군: {
            name: "횡성군",
            code: "730",
        },
        영월군: {
            name: "영월군",
            code: "750",
        },
        평창군: {
            name: "평창군",
            code: "760",
        },
        정선군: {
            name: "정선군",
            code: "770",
        },
        철원군: {
            name: "철원군",
            code: "780",
        },
        화천군: {
            name: "화천군",
            code: "790",
        },
        양구군: {
            name: "양구군",
            code: "800",
        },
        인제군: {
            name: "인제군",
            code: "810",
        },
        고성군: {
            name: "고성군",
            code: "820",
        },
        양양군: {
            name: "양양군",
            code: "830",
        },
    },
    43: {
        충북전체: {
            name: "충북전체",
            code: "999",
        },
        청주시: {
            name: "청주시",
            code: "11",
        },
        충주시: {
            name: "충주시",
            code: "130",
        },
        제천시: {
            name: "제천시",
            code: "150",
        },
        보은군: {
            name: "보은군",
            code: "720",
        },
        옥천군: {
            name: "옥천군",
            code: "730",
        },
        영동군: {
            name: "영동군",
            code: "740",
        },
        증평군: {
            name: "증평군",
            code: "745",
        },
        진천군: {
            name: "진천군",
            code: "750",
        },
        괴산군: {
            name: "괴산군",
            code: "760",
        },
        음성군: {
            name: "음성군",
            code: "770",
        },
        단양군: {
            name: "단양군",
            code: "800",
        },
    },
    44: {
        충남전체: {
            name: "충남전체",
            code: "999",
        },
        천안시: {
            name: "천안시",
            code: "13",
        },
        공주시: {
            name: "공주시",
            code: "150",
        },
        보령시: {
            name: "보령시",
            code: "180",
        },
        아산시: {
            name: "아산시",
            code: "200",
        },
        서산시: {
            name: "서산시",
            code: "210",
        },
        논산시: {
            name: "논산시",
            code: "230",
        },
        계룡시: {
            name: "계룡시",
            code: "250",
        },
        당진시: {
            name: "당진시",
            code: "270",
        },
        금산군: {
            name: "금산군",
            code: "710",
        },
        부여군: {
            name: "부여군",
            code: "760",
        },
        서천군: {
            name: "서천군",
            code: "770",
        },
        청양군: {
            name: "청양군",
            code: "790",
        },
        홍성군: {
            name: "홍성군",
            code: "800",
        },
        예산군: {
            name: "예산군",
            code: "810",
        },
        태안군: {
            name: "태안군",
            code: "825",
        },
    },
    47: {
        경북전체: {
            name: "경북전체",
            code: "999",
        },
        포항시: {
            name: "포항시",
            code: "11",
        },
        경주시: {
            name: "경주시",
            code: "130",
        },
        김천시: {
            name: "김천시",
            code: "150",
        },
        안동시: {
            name: "안동시",
            code: "170",
        },
        구미시: {
            name: "구미시",
            code: "190",
        },
        영주시: {
            name: "영주시",
            code: "210",
        },
        영천시: {
            name: "영천시",
            code: "230",
        },
        상주시: {
            name: "상주시",
            code: "250",
        },
        문경시: {
            name: "문경시",
            code: "280",
        },
        경산시: {
            name: "경산시",
            code: "290",
        },
        군위군: {
            name: "군위군",
            code: "720",
        },
        의성군: {
            name: "의성군",
            code: "730",
        },
        청송군: {
            name: "청송군",
            code: "750",
        },
        영양군: {
            name: "영양군",
            code: "760",
        },
        영덕군: {
            name: "영덕군",
            code: "770",
        },
        청도군: {
            name: "청도군",
            code: "820",
        },
        고령군: {
            name: "고령군",
            code: "830",
        },
        성주군: {
            name: "성주군",
            code: "840",
        },
        칠곡군: {
            name: "칠곡군",
            code: "850",
        },
        예천군: {
            name: "예천군",
            code: "900",
        },
        봉화군: {
            name: "봉화군",
            code: "920",
        },
        울진군: {
            name: "울진군",
            code: "930",
        },
        울릉군: {
            name: "울릉군",
            code: "940",
        },
    },
    48: {
        경남전체: {
            name: "경남전체",
            code: "999",
        },
        창원시: {
            name: "창원시",
            code: "12",
        },
        진주시: {
            name: "진주시",
            code: "170",
        },
        통영시: {
            name: "통영시",
            code: "220",
        },
        사천시: {
            name: "사천시",
            code: "240",
        },
        김해시: {
            name: "김해시",
            code: "250",
        },
        밀양시: {
            name: "밀양시",
            code: "270",
        },
        거제시: {
            name: "거제시",
            code: "310",
        },
        양산시: {
            name: "양산시",
            code: "330",
        },
        의령군: {
            name: "의령군",
            code: "720",
        },
        함안군: {
            name: "함안군",
            code: "730",
        },
        창녕군: {
            name: "창녕군",
            code: "740",
        },
        고성군: {
            name: "고성군",
            code: "820",
        },
        남해군: {
            name: "남해군",
            code: "840",
        },
        하동군: {
            name: "하동군",
            code: "850",
        },
        산청군: {
            name: "산청군",
            code: "860",
        },
        함양군: {
            name: "함양군",
            code: "870",
        },
        거창군: {
            name: "거창군",
            code: "880",
        },
        합천군: {
            name: "합천군",
            code: "890",
        },
    },
    45: {
        전북전체: {
            name: "전북전체",
            code: "999",
        },
        전주시: {
            name: "전주시",
            code: "11",
        },
        군산시: {
            name: "군산시",
            code: "130",
        },
        익산시: {
            name: "익산시",
            code: "140",
        },
        정읍시: {
            name: "정읍시",
            code: "180",
        },
        남원시: {
            name: "남원시",
            code: "190",
        },
        김제시: {
            name: "김제시",
            code: "210",
        },
        완주군: {
            name: "완주군",
            code: "710",
        },
        진안군: {
            name: "진안군",
            code: "720",
        },
        무주군: {
            name: "무주군",
            code: "730",
        },
        장수군: {
            name: "장수군",
            code: "740",
        },
        임실군: {
            name: "임실군",
            code: "750",
        },
        순창군: {
            name: "순창군",
            code: "770",
        },
        고창군: {
            name: "고창군",
            code: "790",
        },
        부안군: {
            name: "부안군",
            code: "800",
        },
    },
    46: {
        전남전체: {
            name: "전남전체",
            code: "999",
        },
        목포시: {
            name: "목포시",
            code: "110",
        },
        여수시: {
            name: "여수시",
            code: "130",
        },
        순천시: {
            name: "순천시",
            code: "150",
        },
        나주시: {
            name: "나주시",
            code: "170",
        },
        광양시: {
            name: "광양시",
            code: "230",
        },
        담양군: {
            name: "담양군",
            code: "710",
        },
        곡성군: {
            name: "곡성군",
            code: "720",
        },
        구례군: {
            name: "구례군",
            code: "730",
        },
        고흥군: {
            name: "고흥군",
            code: "770",
        },
        보성군: {
            name: "보성군",
            code: "780",
        },
        화순군: {
            name: "화순군",
            code: "790",
        },
        장흥군: {
            name: "장흥군",
            code: "800",
        },
        강진군: {
            name: "강진군",
            code: "810",
        },
        해남군: {
            name: "해남군",
            code: "820",
        },
        영암군: {
            name: "영암군",
            code: "830",
        },
        무안군: {
            name: "무안군",
            code: "840",
        },
        함평군: {
            name: "함평군",
            code: "860",
        },
        영광군: {
            name: "영광군",
            code: "870",
        },
        장성군: {
            name: "장성군",
            code: "880",
        },
        완도군: {
            name: "완도군",
            code: "890",
        },
        진도군: {
            name: "진도군",
            code: "900",
        },
        신안군: {
            name: "신안군",
            code: "910",
        },
    },
    50: {
        제주전체: {
            name: "제주전체",
            code: "999",
        },
        제주시: {
            name: "제주시",
            code: "110",
        },
        서귀포시: {
            name: "서귀포시",
            code: "130",
        },
    },
};
