import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";

import { queryList } from "./queryList";

import {
    BaseDeungiList,
    DeungiListDto,
} from "@src/interfaces/Deungi.interface";

const fetchData = async (params: DeungiListDto) => {
    const paramsAnyType: any = params;
    const urlParams = new URLSearchParams(paramsAnyType);
    const response = await axiosInstance.get(`${axiosPath.deungi}/items`, {
        params: {
            ...params,
        },
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseDeungiList[] = responseData.list;
    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCnt);
    } else {
        queryList.setTotalCount(responseData.totalCnt);
    }

    return queryList.getData(parse);
};

const parse = (originData: BaseDeungiList[]) => {
    const newData = originData.map((data) => {
        const {
            _id,
            createdAtToString,
            ticket_idx,
            name,
            email,
            pin,
            item_type,
            address,
            deungi_type,
            deungi_state,
            crawlingType,
            updatedAtToString,
            user_idx,
            pdf_url,
        } = data;

        return {
            id: _id,
            deungiState: deungi_state,
            crawlingType: crawlingType,
            name: name,
            email: email,
            address: address,
            pin: pin,
            ticketNo: ticket_idx,
            deungiType: deungi_type,
            startAt: updatedAtToString,
            itemType: item_type,
            usedAt: createdAtToString,
            userIdx: user_idx,
            pdfUrl: pdf_url,
        };
    });

    return newData;
};

const getQueryString = (searchParams: DeungiListDto) => {
    let queryParams: any = {};

    Object.keys(searchParams).forEach((value: any) => {
        let name = value as keyof DeungiListDto;
        if (searchParams[name]) {
            queryParams[name] = searchParams[name];
        }
    });
    return queryParams;
};
const useDeungiList = (filters: DeungiListDto, enabled: boolean = true) => {
    const queryParams = getQueryString(filters);

    return useQuery(
        [queryKeys.DEUNGI_LIST, queryParams],
        () => fetchData(queryParams),
        {
            enabled,
        },
    );
};

const useUpdateDeungiState = (deungiState: string, deungiId: string) => {
    const body = {
        deungiState: deungiState,
    };
    const response = axiosInstance.post(
        `${axiosPath.deungi}/state/${deungiId}`,
        body,
    );
    return response;
};

export { useDeungiList, getQueryString, useUpdateDeungiState };
