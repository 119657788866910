import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Constants } from "@src/constants/Constants";
import { Button, ConfirmModal } from "@src/components";
import {
    BaseTemplate,
    TemplateFormProps,
    UpdateTemplateDto,
} from "@src/interfaces/Template.interface";
import {
    useTemplateDetail,
    useUpdateTemplate,
} from "@src/hooks/queries/useTemplateDetail";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "@src/reducers/loading";
import { useNavigate, useParams } from "react-router-dom";
import TemplateForm from "./TemplateForm";
import { useTemplateList } from "@src/hooks/queries/useTemplate";

interface ITemplateUpdate {
    isDetail: boolean;
}

function TemplateUpdate({ isDetail = false }: ITemplateUpdate) {
    const { id = "0" } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalShow, setIsModalShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<string>("");
    const [data, setData] = useState<TemplateFormProps>();
    const {
        isLoading,
        data: initData = null,
        isError,
        refetch: singleRefetch,
    } = useTemplateDetail(id, true);
    const [isUpdate, setIsUpdate] = useState(true);
    const [updateData, setUpdateData] = useState<TemplateFormProps>({
        templateBody: "",
        isEdit: 0,
    });
    useEffect(() => {
        if (!isLoading && !isError && initData) {
            setupData(initData);
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());
        }
    }, [isLoading, isError]);

    useEffect(() => {
        setDisabled(!updateData.isEdit);
    }, [updateData]);

    const setupData = (data: BaseTemplate) => {
        const initData: TemplateFormProps = {
            templateCode: data.template_code,
            templateCategory: data.category,
            templateTitle: data.msg_title,
            templateBody: data.msg_body,
            templateLink: data.msg_click_action,
            templateStatus: data.status.toString(),
            isEdit: 0,
        };
        setData(initData);
        setIsUpdate(initData.templateStatus !== "200");
    };

    const matchInterface: { [key: string]: string } = {
        templateCode: "template_code",
        templateCategory: "category",
        templateTitle: "msg_title",
        templateBody: "msg_body",
        templateLink: "msg_click_action",
    };

    const diffChecker = (updateData: TemplateFormProps) => {
        let obj: UpdateTemplateDto = {};
        if (data) {
            for (let value of Object.keys(updateData)) {
                let v = value as keyof TemplateFormProps;
                if (data[v] !== updateData[v]) {
                    let propertyName = matchInterface[v];
                    if (propertyName) {
                        obj = { ...obj, [propertyName]: updateData[v] };
                    }
                }
            }
        }

        return obj;
    };

    const { refetch: listRefetch } = useTemplateList({ limit: 10, page: 1 });
    const { mutate: updateMutate } = useUpdateTemplate(id);
    const handleSubmit = () => {
        const params = diffChecker(updateData);

        updateMutate(params, {
            onSuccess: () => {
                navigate("/notify/template");
            },
            onError: (error: any) => {
                setPageErrorMessage(error);
            },
            onSettled: () => {
                listRefetch();
                singleRefetch();
            },
        });
    };

    return (
        <>
            {data ? (
                <div css={createDiv}>
                    <TemplateForm
                        title={`${Constants.TEMPLATE} 수정`}
                        handleUpdate={setUpdateData}
                        isUpdate={isUpdate}
                        initData={data}
                        isDetail={isDetail}
                    />
                    {!isDetail && (
                        <div className="buttonBox">
                            <p>{pageErrorMessage}</p>
                            <Button
                                label={"수정"}
                                color={"gd"}
                                size="medium"
                                isDisabled={disabled}
                                handleClick={() => {
                                    setIsModalShow(true);
                                }}
                                deleteBtn={false}
                                defaultValue={""}
                            />
                        </div>
                    )}
                    <ConfirmModal
                        isModalShow={isModalShow}
                        handleOk={handleSubmit}
                        handleCancel={() => {
                            setIsModalShow((prev) => !prev);
                        }}
                        buttonText="수정"
                    >
                        수정하시겠습니까?
                    </ConfirmModal>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
}

const createDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;
export default TemplateUpdate;
