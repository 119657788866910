import React, { useState, useEffect, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input, Title } from "@src/components";
import { validEmailCheck } from "@src/utils/validationCheck";
import { useDebounce } from "@src/hooks/useDebounce";
import { FormUserType } from "../types";
import { ConvertDateTime } from "@src/utils/dateUtil";

interface IUserInfoContainer {
    user: any;
    isUpdate: boolean;
    handlePatchUserData?: Function; // isUpdate가 있을 때
}

const UserInfoContainer = ({
    user,
    isUpdate,
    handlePatchUserData,
}: IUserInfoContainer) => {
    const [inputs, setInputs] = useState<FormUserType>({ ...user });
    useEffect(() => {
        setInputs({
            userName: user?.userName,
            userEmail: user?.userEmail,
            userPhoneNumber: user?.userPhoneNumber,
            userBirthday: user?.userBirthday,
            userGender: user?.userGender,
            userApplyDate: user?.userApplyDate,
        });
    }, [user]);
    const {
        userName,
        userEmail,
        userPhoneNumber,
        userBirthday,
        userGender,
        userApplyDate,
    } = inputs;
    const [errorMessage, setErrorMessage] = useState("");
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    };
    const debouncedEmail = useDebounce(inputs["userEmail"], 500);
    useEffect(() => {
        if (inputs.userEmail) {
            validEmailCheck(inputs.userEmail)
                ? setErrorMessage("")
                : setErrorMessage("이메일 형식이 올바르지 않습니다.");
            if (isUpdate) {
                handlePatchUserData &&
                    inputs.userEmail !== debouncedEmail &&
                    (handlePatchUserData({
                        userEmail: inputs.userEmail,
                        userStatus: user?.userStatus.toString(),
                        edit: true,
                    }),
                    console.log("dd"));
            }
        } else {
            setErrorMessage("");
        }
    }, [inputs.userEmail]);
    return (
        <>
            <Title title="개인 정보" size="small" />
            <ul css={infoBox}>
                <li>
                    <Input
                        value={userName || ""}
                        name="userName"
                        label="이름"
                        placeholder="이름 입력"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>
                <li>
                    <Input
                        value={userBirthday || ""}
                        name="userBirthday"
                        label="생년월일"
                        placeholder="생년월일 입력"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>

                <li>
                    <Input
                        value={userPhoneNumber || ""}
                        name="userPhoneNumber"
                        label="연락처"
                        placeholder="연락처 입력"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>
                <li>
                    <Input
                        value={userGender || ""}
                        name="userGender"
                        label="성별"
                        placeholder="성별 입력"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>
                <li className="emailItem">
                    <Input
                        value={userEmail || ""}
                        name="userEmail"
                        label="이메일"
                        placeholder="이메일  입력"
                        isDisabled={!isUpdate}
                        handleChange={handleChange}
                        errorMessage={errorMessage}
                        size="dynamic"
                    />
                </li>
                <li>
                    <Input
                        // value={userApplyDate || ""}
                        value={
                            userApplyDate ? ConvertDateTime(userApplyDate) : ""
                        }
                        name="userApplyDate"
                        label="가입일"
                        placeholder="가입일 입력"
                        isDisabled={true}
                        handleChange={() => {}}
                        size="dynamic"
                    />
                </li>
            </ul>
        </>
    );
};

const infoBox = css`
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    li {
        width: 45%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        span:nth-of-type(1) {
            ${Common.textStyle.text1_R13}
            display: inline-block;
            width: 100px;
            color: ${Common.colors.gray500};
        }
        span:nth-of-type(2) {
            ${Common.textStyle.title6_R14}
            color: ${Common.colors.black};
        }
        &:nth-of-type(odd) {
            margin-right: 40px;
        }
    }
    input:disabled {
        background-color: ${Common.colors.white};
        color: ${Common.colors.black};
        border-bottom: none;
    }
    .emailItem {
        position: relative;
        p {
            position: absolute;
            left: 102px;
            bottom: -25px;
        }
    }
`;
export default UserInfoContainer;
