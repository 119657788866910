import React, { ChangeEvent, useEffect, useState } from "react";
import "@src/styles/CommonTable.css";
import { Input } from "@src/components";
import { validNumberCheck } from "@src/utils/validationCheck";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { useGetCaseAnalysis } from "@src/hooks/queries/product/useCaseAnalysis";

function CaseAnalysisContainer({ isEdit = false, handleUpdate }: any) {
    const PRODUCT_NO = "20210130088995-1";
    const headerMeta = [
        "기간(개월)",
        "매각 건수(건)",
        "평균 감정가(원)",
        "평균 매각가(원)",
        "매각가율(%)",
        "평균 유찰횟수(회)",
    ];
    const [tableData, setTableData] = useState<any>([]);
    const { isLoading, isError, data } = useGetCaseAnalysis(PRODUCT_NO);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setTableData(data);
        }
    }, [isLoading, isError, data]);

    const handleChange = (updateData: any, index: number) => {
        let newTableData = [...tableData];
        newTableData[index] = updateData;
        setTableData([...newTableData]);
    };

    useEffect(() => {
        if (handleUpdate !== undefined) {
            handleUpdate(tableData);
        }
    }, [tableData]);
    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: any) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isEdit={isEdit}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}
const TableRow = ({ data, isEdit, handleChange }: any) => {
    const [rowData, setRowData] = useState(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const {
        period,
        bidCount,
        estPriceAverage,
        bidPriceAverage,
        bidPriceRate,
        skpCountAverage,
    } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let [validCheckResult, validCheckValue] = validNumberCheck(value);
        if (validCheckResult) {
            setRowData({
                ...rowData,
                [name]: validCheckValue,
            });
        }
    };
    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);
    return (
        <tr key={data.id} style={isEdit ? { borderBottom: "none" } : undefined}>
            <td>
                <Input
                    name="period"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={ConvertEmptyValue(period)}
                />
            </td>
            <td>
                <Input
                    name="bidCount"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isEdit ? bidCount : resultPrice(bidCount)}
                />
            </td>
            <td>
                <Input
                    name="estPriceAverage"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isEdit ? estPriceAverage : resultPrice(estPriceAverage)
                    }
                />
            </td>
            <td>
                <Input
                    name="bidPriceAverage"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isEdit ? bidPriceAverage : resultPrice(bidPriceAverage)
                    }
                />
            </td>
            <td>
                <Input
                    name="bidPriceRate"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isEdit ? bidPriceRate : ConvertEmptyValue(bidPriceRate)
                    }
                />
            </td>
            <td>
                <Input
                    name="skpCountAverage"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isEdit ? skpCountAverage : resultPrice(skpCountAverage)
                    }
                />
            </td>
        </tr>
    );
};

export default CaseAnalysisContainer;
