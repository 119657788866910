import img from "@src/assets/logo_gd.png";
import { ConvertDate } from "@src/utils/dateUtil";

const random = (max) => {
    const arr = [];
    for (let i = 1; i <= max; i++) {
        const order = Math.floor(Math.random() * 5);
        arr.push({
            bannerNo: i,
            bannerImage: img,
            bannerLink: "https://missgoauction.com",
            bannerDate: `${ConvertDate(1655132400000)} ~ ${ConvertDate(
                1655443800000,
            )}`,
            startDate: 1655132400000,
            endDate: 1655443800000,
            bannerClickCount: Math.floor(Math.random() * 100),
            bannerView: order !== 0,
            bannerViewOrder: order,
            viewMore: i,
            index: i,
        });
    }
    return arr;
};

export const BannerSampleData = random(500);
export const dropdownData = [
    {
        key: 0,
        value: "비노출",
    },
    {
        key: 1,
        value: "1",
    },
    {
        key: 2,
        value: "2",
    },
    {
        key: 3,
        value: "3",
    },
    {
        key: 4,
        value: "4",
    },
    {
        key: 5,
        value: "5",
    },
];

const hours = {};
export const hoursData = new Array(24)
    .fill()
    .map((_, i) => (hours[i] = { id: i, key: i, value: i }));

export const minutesData = [
    { key: 0, value: "00" },
    { key: 30, value: "30" },
];
