// @ts-nocheck
import React, { useEffect, useRef } from "react";
// import Chart from "chart.js/auto";
import { Chart } from "chart.js";

import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Common } from "@src/styles/Common";

function PieChart({ data, options }) {
    Chart.register(ChartDataLabels);

    useEffect(() => {
        Chart.defaults.set("plugins.datalabels", {
            display: true,
        });
    }, [data]);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<any>(null);
    const config = {
        type: "pie",
        data: data,
        options: {
            animation: {
                duration: 1,
            },
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        color: Common.colors.black,
                        usePointStyle: true,
                        boxWidth: 6,
                    },

                    position: "bottom",
                },
                datalabels: {
                    anchor: "center",
                    align: "end",
                    font: {
                        size: 20,
                    },
                    formatter: function (value: any, context: any) {
                        const sum = context.dataset.data.reduce(
                            (total: number, dataValue: any) =>
                                total + dataValue,
                            0,
                        );

                        const percentValue = ((value / sum) * 100).toFixed(2);

                        return `${percentValue.slice(0, -3)}%`;
                    },
                },
                responsive: false,
                tooltip: {
                    responsive: false,
                    mode: "index",
                    displayColors: true,
                    titleColor: Common.colors.black,
                    boxWidth: 5,
                    boxHeight: 10,
                    boxPadding: 3,
                    usePointStyle: true,
                    borderColor: Common.colors.black,
                    borderWidth: 1,
                    padding: 6,
                    bodyFont: {
                        size: 15,
                    },
                },
            },
        },
    };
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        if (canvasRef.current) {
            const mergedOptions = Object.assign({}, config.options, options);
            const configWithMergedOptions = Object.assign({}, config, {
                options: mergedOptions,
            });
            chartRef.current = new Chart(
                canvasRef.current,
                configWithMergedOptions,
            );
        }
    }, [data, options]);

    return <canvas ref={canvasRef}></canvas>;
}

export default PieChart;
