// @ts-nocheck
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { Common } from "@src/styles/Common";

function LineChart({ data }) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<any>(null);
    const config = {
        type: "line",
        data,
        options: {
            animation: {
                duration: 0,
            },
            scales: {
                y: {
                    beginAtZero: true,
                },
                x: {
                    grid: {
                        display: false,
                    },
                    type: "time",
                    time: {
                        unit: "day",
                        displayFormats: {
                            day: "MM.dd",
                        },
                        tooltipFormat: "YYY.MM.dd HH:mm",
                    },
                },
            },
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: Common.colors.black,
                        usePointStyle: true,
                        boxWidth: 6,
                    },
                    position: "bottom",
                },
                tooltip: {
                    mode: "index",
                    titleColor: Common.colors.black,
                    bodyColor: Common.colors.black,
                    backgroundColor: Common.colors.white,
                    boxWidth: 8,
                    boxPadding: 3,
                    usePointStyle: true,
                    borderColor: Common.colors.black,
                    borderWidth: 1,
                    padding: 10,
                },
            },
        },
    };

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        if (canvasRef.current) {
            chartRef.current = new Chart(
                canvasRef.current.getContext("2d"),
                config,
            );
        }
    }, [data]);

    return <canvas ref={canvasRef}></canvas>;
}

export default LineChart;
