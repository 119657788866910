import { createSlice } from "@reduxjs/toolkit";
import { LocalStatusType } from "@src/types";

const locationStatusData: LocalStatusType = {
    metroStation:
        "본건 인근에 지하철 3,8호선 '가락시장역'이 소재라고 인근에 버스정류장이 있어 교통사정은 보통임.",
    locationStatus: "오피스텔로 이용중임",
    option: "기타사항 없음",
};

const initialState = locationStatusData;

export const locationStatusSlice = createSlice({
    name: "locationStatus",
    initialState,
    reducers: {
        createLocationStatus: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateLocationStatus: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createLocationStatus, updateLocationStatus } =
    locationStatusSlice.actions;

export default locationStatusSlice.reducer;
