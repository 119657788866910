import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import AdminForm from "./AdminForm";
import { Button, ConfirmModal } from "@src/components";
import { isNotEmpty } from "@src/utils/textUtil";
import { Common } from "@src/styles/Common";
import { useAdminList } from "@src/hooks/queries/useAdmin";
import { useCreateAdmin } from "@src/hooks/queries/useAdminDetail";
import { useNavigate } from "react-router-dom";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useDispatch } from "react-redux";
import { useUserNoSearch } from "@src/hooks/queries/useUser";

function AdminCreate() {
    const initAdmin = {
        adminName: "",
        adminPhone: "",
        adminId: "",
        adminRank: "",
        userNo: undefined,
        password: "",
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [createdAdmin, setCreatedAdmin] = useState({ edit: false });
    const [isDisabled, setIsDisabled] = useState(true);
    const [isModalShow, setIsModalShow] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState("");
    useEffect(() => {
        setCreatedAdmin({ ...initAdmin, edit: false });
    }, []);
    const fullOfCreatedAdmins = ({
        adminName,
        adminPhone,
        adminId,
        adminRank,
        password,
    }: any) => {
        if (
            isNotEmpty(adminName) &&
            isNotEmpty(adminPhone) &&
            isNotEmpty(adminId) &&
            isNotEmpty(adminRank) &&
            isNotEmpty(password)
        ) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };
    useEffect(() => {
        createdAdmin.edit && fullOfCreatedAdmins(createdAdmin);
    }, [createdAdmin]);
    const { mutate: createMutate } = useCreateAdmin();
    const { refetch: listRefetch } = useAdminList({ page: 1, limit: 10 });
    const createParams = (createdData: any) => {
        return {
            admin_name: createdData.adminName,
            admin_phone: createdData.adminPhone,
            admin_rank: createdData.adminRank,
            user_no: createdData?.userNo || null,
            id: createdData.adminId,
            passwd: createdData.password,
        };
    };
    const handleCreate = (createdData: any) => {
        let params = createParams(createdData);
        dispatch(startLoading());
        createMutate(params, {
            onSuccess: () => {
                listRefetch();
                navigate("/admins/list");
            },
            onError: (error: any) => {
                setPageErrorMessage(error.toString());
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };

    return (
        <div css={adminCreateWrap}>
            <AdminForm
                isUpdate={true}
                title={"관리자 등록"}
                initData={createdAdmin}
                handleSubmit={(data: any) => setCreatedAdmin({ ...data })}
            />
            <div css={submitBtnDiv}>
                <p css={pageErrMsgBox}>{pageErrorMessage}</p>
                <Button
                    label={"등록"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={isDisabled}
                    handleClick={() => {
                        setIsModalShow(true);
                    }}
                    deleteBtn={false}
                    defaultValue={"등록"}
                />
            </div>
            <ConfirmModal
                children={"관리자로 등록하시겠습니까?"}
                isModalShow={isModalShow}
                handleOk={() => {
                    handleCreate(createdAdmin);
                    setIsModalShow(false);
                }}
                handleCancel={() => {
                    setIsModalShow(false);
                }}
                buttonText={"등록"}
                disabled={false}
            />
        </div>
    );
}
const adminCreateWrap = css`
    width: 100%;
    padding: 40px;
`;
const submitBtnDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
`;
const pageErrMsgBox = css`
    color: ${Common.colors.error};
`;
export default AdminCreate;
