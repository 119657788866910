import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface IUpDown {
    title: string;
    subTitle?: string;
    mainText: string;
    subText?: string;
    desc?: string;
    upDownCount: number | string | null;
    isUp?: boolean;
    same?: boolean;
    errorMessage?: string;
}
function UpDown({
    title,
    subTitle,
    mainText,
    subText,
    upDownCount,
    isUp,
    same = false,
    errorMessage,
}: IUpDown) {
    return (
        <>
            {subTitle || subText ? (
                <div css={mainWrap}>
                    <div className="mainContainer">
                        <p css={subTitleP}>{title}</p>
                        <div className="mainDescWrap">
                            {!errorMessage && (
                                <p className="rateFont">{mainText}</p>
                            )}
                            <div className="mainUpDownContainer">
                                {!errorMessage ? (
                                    upDownCount ? (
                                        <UpDownBox
                                            upDownCount={upDownCount}
                                            isUp={isUp}
                                            same={same}
                                            errorMessage={errorMessage}
                                        />
                                    ) : null
                                ) : (
                                    <ErrorBox errorMessage={errorMessage} />
                                )}
                            </div>
                        </div>
                        <div className="mainContainer">
                            <p css={subTitleP}>{subTitle}</p>
                            {!errorMessage ? (
                                <p className="rateFont">{subText}</p>
                            ) : (
                                <ErrorBox errorMessage={errorMessage} />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div css={upDownStyle}>
                    <p css={titleP}>{title}</p>
                    <div>
                        {!errorMessage && <p css={mainTextP}>{mainText}</p>}
                        {!errorMessage ? (
                            upDownCount ? (
                                <UpDownBox
                                    upDownCount={upDownCount}
                                    isUp={isUp}
                                    same={same}
                                    errorMessage={errorMessage}
                                />
                            ) : null
                        ) : (
                            <ErrorBox errorMessage={errorMessage} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

function UpDownBox({ upDownCount, isUp, same = false }: any) {
    return (
        <>
            <span css={isUp ? countStyle["up"] : countStyle["down"]}>
                {same ? `` : isUp ? `↑` : `↓`}
                &nbsp;{upDownCount}
            </span>
            {/* <span css={descSpan}>전일대비</span> */}
        </>
    );
}

function ErrorBox({ errorMessage }: any) {
    return (
        <>
            <span css={errorStyle}>{errorMessage}</span>
        </>
    );
}
const upDownStyle = css`
    width: 100%;
    min-width: 200px;
    height: 108px;
    /* height: fit-content; */
    border: 1.5px solid ${Common.colors.gray200};
    border-radius: 8px;
    padding: 20px;
    padding-right: 2px;
    & > div {
        display: flex;
        align-items: center;
    }
    .subUpDownContainer {
        display: flex;
        margin-left: 90px;
        align-items: flex-end;
        justify-content: flex-end;
    }
`;

const mainWrap = css`
    height: 108px;
    border: 1.5px solid ${Common.colors.gray200};
    border-radius: 8px;
    padding: 10px;
    .mainContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .mainDescWrap {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
    .mainUpDownContainer {
        margin-left: 10px;
    }
    .rateFont {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.8px;
        color: ${Common.colors.black};
    }
`;

const titleP = css`
    ${Common.textStyle.title8_R12}
    color: ${Common.colors.gray700};
    margin-bottom: 8px;
`;
const subTitleP = css`
    ${Common.textStyle.title8_R12};
    color: ${Common.colors.gray700};
    font-size: 11px;
`;
const mainTextP = css`
    font-size: 24px;
    font-weight: 500;
    line-height: 165%;
    letter-spacing: -0.8px;
    color: ${Common.colors.black};
    margin-right: 6px;
`;
const countStyle = {
    up: css`
        letter-spacing: -0.8px;
        color: ${Common.colors.error};
    `,
    down: css`
        color: ${Common.colors.success};
    `,
};
const descSpan = css`
    font-size: 11px;
    font-weight: 400;
    line-height: 165%;
    color: ${Common.colors.gray400};
    padding-left: 6px;
`;
const errorStyle = css`
    font-size: 18px;
    font-weight: 500;
    line-height: 165%;
    letter-spacing: -0.8px;
    color: ${Common.colors.black};
`;
export default UpDown;
