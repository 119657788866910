import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, TextBox, ConfirmModal, Title } from "@src/components";
import {
    useGetSupportDetailData,
    useCreateSupportData,
} from "@src/hooks/queries/useSupportDetail";
import { useNavigate, useParams } from "react-router-dom";
import { ConvertPhoneNumber } from "@src/utils/units";
import { ResultSupportType, CreateSupportType } from "../supportTypes";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { initFilters, useSupportData } from "@src/hooks/queries/useSupport";
import arrowLeftIcon from "@src/assets/ico_arrow_left.svg";
import arrowRightIcon from "@src/assets/ico_arrow_right_green.svg";
import arrowRightDisableIcon from "@src/assets/ico_arrow_right_disable.svg";
import arrowLeftDisableIcon from "@src/assets/ico_arrow_left_disable.svg";
import ReplyHistory from "./ReplyHistory";

function WaitingReply() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const SUPPORT_ID = id || "c29b8626-6991-40f2-bbe6-576b01b71527";
    const [showSavePopup, setShowSavePopup] = useState(false);
    const [replyText, setReplyText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [selectedImg, setSelectedImg] = useState<number>(0);
    const [showSelectedImg, setShowSelectedImg] = useState<boolean>(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<any>("");
    const [initData, setInitData] = useState<ResultSupportType>({
        user: {
            user_no: 0,
            id: "",
            uname: "",
            phone: "",
        },
        result: {
            no: 0,
            category: "",
            userQst: "",
            reply: "",
            prevReply: [],
            device: "app",
        },
    });
    const {
        isLoading,
        data,
        isError,
        refetch: singleRefetch,
    } = useGetSupportDetailData(SUPPORT_ID);
    const { refetch: listRefetch } = useSupportData(initFilters);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
        }
    }, [isLoading, isError]);

    const { mutate: createMutate } = useCreateSupportData(SUPPORT_ID);
    const onCreate = () => {
        const params: CreateSupportType = {
            user_no: initData?.user.user_no,
            category: initData?.result.category,
            contents: replyText,
        };
        dispatch(startLoading());
        createMutate(params, {
            onSuccess: () => {
                singleRefetch();
                navigate("/help/support");
            },
            onError: (error: any) => {
                setPageErrorMessage(error);
            },
            onSettled: () => {
                setShowSavePopup((prev) => !prev);
                listRefetch();
                dispatch(endLoading());
            },
        });
    };
    return (
        <div css={WrapperDiv}>
            <Title title="답변 등록" />

            <div css={ContainerDiv}>
                <ul>
                    <li>문의자</li>
                    <li>{initData?.user?.uname}</li>
                    <li>이메일</li>
                    <li>{initData?.user?.id}</li>
                    <li>휴대폰 번호</li>
                    <li>{ConvertPhoneNumber(initData?.user?.phone)}</li>
                    <li>구분</li>
                    <li>{initData?.result?.device?.toUpperCase()}</li>
                </ul>
                <ul>
                    <li>문의 제목</li>
                    <li>{initData?.result?.title}</li>
                </ul>
                <ul>
                    <li>첨부파일</li>
                    <li css={imageBoxStyle}>
                        {initData?.result?.imgurl?.map(
                            (img: any, idx: number) => (
                                <img
                                    src={img?.path}
                                    key={idx.toString()}
                                    width={150}
                                    onClick={() => {
                                        setSelectedImg(idx);
                                        setShowSelectedImg(true);
                                    }}
                                />
                            ),
                        )}
                    </li>
                </ul>
                <ul>
                    <li>문의 사항</li>
                    <li>
                        <div className="userQstDiv">
                            {initData?.result.userQst}
                        </div>
                    </li>
                </ul>
                <ul>
                    <div css={replyContent}>
                        <TextBox
                            handleChange={(value, isError = false) => {
                                setReplyText(value);
                                setDisabled(isError);
                            }}
                            label=""
                            maxLength={1000}
                            placeholder="답변 내용을 입력해 주세요."
                            size="dynamic"
                            isDisabled={false}
                            defaultText={replyText}
                        />
                    </div>
                </ul>
                <div css={notiMessage}>
                    ※ 등록한 답변은 즉시 노출되니 신중하게 작성해 주세요
                </div>
                <ul>
                    <li></li>
                    <li
                        style={{ justifyContent: "flex-end" }}
                        className="buttonBox"
                    >
                        <p>{pageErrorMessage}</p>
                        <Button
                            color="purple"
                            handleClick={() => {
                                setShowSavePopup(true);
                            }}
                            label="답변 등록"
                            size="medium"
                            isDisabled={disabled}
                        />
                    </li>
                </ul>
                <ReplyHistory data={initData.result.prevReply} />

                <ConfirmModal
                    handleOk={() => {
                        onCreate();
                    }}
                    handleCancel={() => setShowSavePopup(!showSavePopup)}
                    isModalShow={showSavePopup}
                    buttonText={"등록"}
                >
                    답변을 등록하시겠습니까?
                </ConfirmModal>
                <ConfirmModal
                    isModalShow={showSelectedImg}
                    buttonText={"닫기"}
                    handleOk={() => {
                        setShowSelectedImg(false);
                        setSelectedImg(0);
                    }}
                    handleCancel={() => {}}
                    disabledCancelBtn={true}
                >
                    <div css={imageModalBoxStyle}>
                        <img
                            src={
                                selectedImg === 0
                                    ? arrowLeftDisableIcon
                                    : arrowLeftIcon
                            }
                            alt=""
                            className={selectedImg !== 0 ? "arrow" : ""}
                            onClick={() => {
                                if (selectedImg !== 0) {
                                    setSelectedImg((pre) => pre - 1);
                                }
                            }}
                        />
                        <img
                            src={initData?.result?.imgurl?.[selectedImg]?.path}
                            alt=""
                        />
                        <img
                            src={
                                selectedImg ===
                                initData?.result?.imgurl?.length - 1
                                    ? arrowRightDisableIcon
                                    : arrowRightIcon
                            }
                            alt=""
                            className={
                                selectedImg !==
                                initData?.result?.imgurl?.length - 1
                                    ? "arrow"
                                    : ""
                            }
                            onClick={() => {
                                if (
                                    selectedImg !==
                                    initData?.result?.imgurl?.length - 1
                                ) {
                                    setSelectedImg((pre) => pre + 1);
                                }
                            }}
                        />
                    </div>
                </ConfirmModal>
            </div>
        </div>
    );
}

const WrapperDiv = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const ContainerDiv = css`
    margin-top: 20px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray200};
    display: flex;
    flex-direction: column;

    ${Common.textStyle.title5_M14}

    & > ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;
        height: fit-content;

        & > li:nth-of-type(odd) {
            min-width: 120px;
        }
        & > li:nth-of-type(even) {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
        }
    }
    & > div:nth-last-of-type(2) {
        button:nth-last-of-type(2) {
            background: ${Common.colors.black};
        }
    }
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
    .userQstDiv {
        line-height: 3;
    }
`;

const replyContent = css`
    width: 100%;
    height: 200px;
    margin-top: 20px;
    textarea {
        width: 100%;
        height: 200px;
    }
`;
const notiMessage = css`
    color: ${Common.colors.orange};
    font-weight: 400;
    font-size: 12px;
    line-height: 180%;
    margin-top: 20px;
`;

const imageBoxStyle = css`
    display: flex;
    gap: 14px;
`;

const imageModalBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 690px;
    img {
        max-width: 600px;
    }
    img:nth-of-type(2) {
        width: 98%;
    }
    & > .arrow {
        cursor: pointer;
    }
`;

export default WaitingReply;
