import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
    Title,
    ToggleButton,
    DropDown,
    Input,
    EditorBox,
    Button,
    ConfirmModal,
} from "@src/components";
import { Common } from "@src/styles/Common";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { FormFAQType, UpdateBoardType } from "./types";
import { arrayToDropdown } from "@src/utils/dropdownUtil";
import {
    useCreateFAQData,
    useFAQDetailData,
    useUpdateFAQData,
} from "@src/hooks/queries/useFAQDetail";
import { useFAQData, initParams } from "@src/hooks/queries/useFAQ";
import {
    isEditorEmpty,
    isNotEmpty,
    ConvertHtmlToString,
} from "@src/utils/textUtil";
import { validChecker } from "@src/utils/validationCheck";

export default function FAQForm({ isUpdate = false }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [pageErrorMessage, setPageErrorMessage] = useState<any>("");
    const [initData, setInitData] = useState<FormFAQType>({
        faqNo: 0,
        faqType: "",
        faqTitle: "",
        faqContent: "",
        view: 1,
        faqDate: "",
        updateDate: "",
        more: 0,
        edit: false,
        no: 0,
    });
    const [updateData, setUpdateData] = useState<FormFAQType>(initData);
    const {
        data,
        isLoading,
        isError,
        refetch: detailRefetch,
    } = useFAQDetailData(Number(id), isUpdate);
    useEffect(() => {
        if (isError) {
            //error
        } else {
            if (!isLoading && data) {
                setInitData({ ...data, edit: false });
            }
        }
    }, [data]);

    const typeList = arrayToDropdown([
        "서비스 이용",
        "미스고 정기구독",
        "전문가 회원",
        "전문가 상담/입찰대행",
        "결제/환불/정산",
    ]);
    const selectedType =
        initData.faqType &&
        Object.entries(typeList).find(
            ([_, i]) => i.key === initData.faqType,
        )?.[1].key;

    useEffect(() => {
        setUpdateData(initData);
    }, [initData]);

    const [edit, setEdit] = useState<boolean>(initData.edit ?? true);
    useEffect(() => {
        if (updateData.edit) {
            if (
                isNotEmpty(updateData.faqTitle) &&
                isEditorEmpty(updateData.faqContent) &&
                isNotEmpty(updateData.faqType)
            ) {
                setPageErrorMessage("");
                setEdit(true);
            } else {
                setEdit(false);
            }
        }
    }, [updateData]);
    const { refetch: listRefetch } = useFAQData(initParams);

    const [isModalShow, setIsModalShow] = useState(false);
    const { mutate: createMutate } = useCreateFAQData();
    const handleCreateSubmit = () => {
        if (isUpdate) {
            setIsModalShow((prev) => !prev);
        } else {
            const { faqTitle, faqContent, view, faqType } = updateData;
            const params: UpdateBoardType = {
                title: faqTitle,
                contents: faqContent,
                is_open: view,
                category: faqType,
            };

            dispatch(startLoading());
            createMutate(params, {
                onSuccess: () => {
                    navigate("/help/faq");
                },
                onError: (error) => {
                    setPageErrorMessage(error);
                },
                onSettled: () => {
                    listRefetch();
                    dispatch(endLoading());
                },
            });
        }
    };

    const { mutate: updateMutate } = useUpdateFAQData(Number(id));
    const handleUpdateSubmit = () => {
        setIsModalShow((prev) => !prev);
        dispatch(startLoading());

        const { faqTitle, faqContent, view, faqType } = updateData;
        const params: UpdateBoardType = {
            title: faqTitle,
            contents: faqContent,
            is_open: view,
            category: faqType,
        };

        updateMutate(params, {
            onSuccess: () => {
                detailRefetch();
                navigate("/help/faq");
            },
            onError: (error) => {
                setPageErrorMessage(error);
            },
            onSettled: () => {
                listRefetch();
                dispatch(endLoading());
            },
        });
    };
    const handleCancel = () => {
        setIsModalShow((prev) => !prev);
    };
    return (
        <div css={formDiv}>
            <Title title={`자주 묻는 질문 ${isUpdate ? "수정" : "등록"}`} />
            <ul>
                <li>
                    <span>노출 여부</span>
                    <ToggleButton
                        name="노출 여부"
                        value={Boolean(updateData?.view || 0)}
                        handleToggle={(_, checked) => {
                            setUpdateData({
                                ...updateData,
                                ...{ view: Number(checked), edit: true },
                            });
                        }}
                    />
                </li>
                <li>
                    <span>분류</span>
                    <DropDown
                        data={typeList}
                        handleChange={(e) => {
                            const { value } = e.target;
                            const currentValue = value.includes("선택")
                                ? ""
                                : value;
                            setUpdateData({
                                ...updateData,
                                ...{ faqType: currentValue, edit: true },
                            });
                        }}
                        defaultValue={selectedType}
                        border
                        placeholder="카테고리 선택"
                    />
                </li>
                <li>
                    <Input
                        label="제목"
                        placeholder="제목 입력"
                        type="text"
                        size="medium"
                        handleChange={(e) => {
                            const { value } = e.target;
                            const { errMsg } = validChecker("spacebar", value);
                            errMsg ||
                                setUpdateData({
                                    ...updateData,
                                    ...{ faqTitle: value, edit: true },
                                });
                            setPageErrorMessage(errMsg);
                        }}
                        isDisabled={false}
                        errorMessage={""}
                        name="title"
                        value={updateData.faqTitle || ""}
                    />
                </li>
            </ul>
            <EditorBox
                handleChange={(value: string) => {
                    if (initData.faqContent !== value) {
                        const { errMsg } = validChecker(
                            "spacebar",
                            ConvertHtmlToString(value),
                        );
                        errMsg ||
                            setUpdateData({
                                ...updateData,
                                ...{ faqContent: value, edit: true },
                            });
                        setPageErrorMessage(errMsg);
                    }
                }}
                defaultText={initData.faqContent ?? ""}
            />
            <div className={"buttonBox"}>
                <p>{pageErrorMessage}</p>
                <Button
                    label={isUpdate ? "수정" : "등록"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={!edit}
                    handleClick={handleCreateSubmit}
                    deleteBtn={false}
                    defaultValue={""}
                />
            </div>
            <ConfirmModal
                children={"수정하시겠습니까?"}
                isModalShow={isModalShow}
                handleOk={handleUpdateSubmit}
                handleCancel={handleCancel}
                buttonText={"수정"}
            />
        </div>
    );
}

const formDiv = css`
    width: 100%;
    padding: 40px;
    & > div:first-of-type {
        margin-bottom: 40px;
    }
    & > ul {
        li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 28px;
            & > span {
                display: block;
                width: 120px;
            }
            select {
                border: none;
                border-radius: 0;
                border-bottom: 1.5px solid ${Common.colors.gray200};
            }
        }
    }
    & > button:last-of-type {
        margin-left: auto;
        margin-top: 28px;
    }
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;
