import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { useNavigate } from "react-router";
import authService from "@src/api/authService";
import { useLoginDetail } from "@src/hooks/queries/useLogin";

function LoginUser() {
    const email = localStorage.getItem("user");
    const navigate = useNavigate();
    const { isLoading, data, isError } = useLoginDetail();
    const [name, setName] = useState<string>("");
    const [rank, setRank] = useState<string>("");

    const forceLogout = () => {
        authService.logout();
        navigate("/login");
    };
    useEffect(() => {
        if (isError) {
            forceLogout();
        } else {
            if (!isLoading) {
                if (data) {
                    setName(data.admin_name);
                    setRank(data.admin_rank);
                } else {
                    forceLogout();
                }
            }
        }
    }, [isError, isLoading]);

    return (
        <>
            <ul css={userList}>
                <li>
                    {name}&nbsp;
                    {rank}
                </li>
                <li>{email}</li>
            </ul>
        </>
    );
}

LoginUser.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
};

const userList = css`
    margin-top: unset;
    li {
        ${Common.textStyle.title6_R14}
        margin-bottom: 20px;
        a.active {
            color: ${Common.colors.focus};
        }
    }
`;

export default LoginUser;
