import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import {
    DeungiDetailList,
    ResultDeungiListProps,
    BaseNestUser,
    BaseUser,
    DetailUserProps,
} from "@src/interfaces/User.interface";
import { ConvertPhoneNumber } from "@src/utils/units";
import { asyncCall } from "@src/api/asyncCall";
import { queryList } from "./queryList";
import { AxiosError } from "axios";
import { IExistingDeunggiRes } from "@src/pages/user/types";

const fetchData = async (userNo: number) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/${userNo}`,
    );
    // const response = await axiosInstance.get(`${axiosPath.users}/${userNo}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    // const responseData = response.data;
    // const originData: BaseUser = responseData.data.data;
    const originData: BaseNestUser = response.data;

    if (response) {
        return nestParse(originData);
    } else {
        return null;
    }
    // const errorCode = responseData.data.errCode;
    // if (errorCode === 0) {
    //     return parse(originData);
    // } else {
    //     return null;
    // }
};
const nestParse = (originData: BaseNestUser): DetailUserProps => {
    const {
        no,
        id,
        status,
        userInfo,
        createdAt,
        agreements,
        favorite,
        subscriptionType,
    } = originData;

    const newAgreements = [];
    newAgreements.push(
        agreements.map((v) => {
            return {
                type: v.type,
                agreement: v.agreement ? true : false,
                date: v.updatedAt,
            };
        }),
    );
    return {
        userNo: no,
        userName: userInfo.uname,
        userEmail: id,
        userPhoneNumber: ConvertPhoneNumber(userInfo.phone),
        userBirthday: userInfo.birthdate?.replace(
            /(\d{4})(\d{2})(\d{2})/,
            "$1.$2.$3",
        ),
        userGender: userInfo.gender === "0" ? "여성" : "남성", //"0": 여성, "1": 남성
        userApplyDate: createdAt,
        userStatus: Number(status),
        favorite: {
            location: favorite?.favoriteRegion || "",
            usage: favorite?.favoriteCategory || "",
        },
        agreements: newAgreements,
        trial: {
            isUsing: false,
            date: null,
        },
        status: Number(status),
        subscriptionStatus: 0,
        productName: subscriptionType.productName,

        // productName: product_name,
        // subExpiredAt: subs_expired_at,
    };
};

const parse = (originData: BaseUser): DetailUserProps => {
    const {
        user_no,
        id,
        status,
        uname,
        phone,
        created_at,
        birthdate,
        gender,
        favorite,
        agreement,
        promotion,
        subscription_status,
        product_name,
        subs_expired_at,
    } = originData;
    const agreements = [];
    agreements.push(
        agreement.map((v) => {
            return {
                type: v.type,
                agreement: v.agreement ? true : false,
                date: v.updated_at,
            };
        }),
    );
    return {
        userNo: user_no,
        userName: uname,
        userEmail: id,
        userPhoneNumber: ConvertPhoneNumber(phone),
        userBirthday: birthdate?.replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3"),
        userGender: gender === "0" ? "여성" : "남성", //"0": 여성, "1": 남성
        userApplyDate: created_at,
        userStatus: status,
        favorite: {
            location: favorite?.favorite_region || "",
            usage: favorite?.favorite_category || "",
        },
        agreements,
        trial: {
            isUsing: promotion[0].created_at === null ? false : true,
            date: promotion[0].created_at,
        },
        status,
        subscriptionStatus: subscription_status,
        productName: product_name,
        subExpiredAt: subs_expired_at,
    };
};

const useRefundPGSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestPgRefund}`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.post(url, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
//회원 상세 조회
const useGetUserDetailData = (userNo: number) => {
    return useQuery<DetailUserProps | null, Error>(
        [queryKeys.USER_DETAIL_DATA, userNo],
        () => fetchData(userNo),
        {
            // initialData: initialUserDetailData,
        },
    );
};

//회원 구독 정보 조회
const useSubscriptionInfo = async (userNo: number | undefined) => {
    const promise = await axiosInstance.get(
        `${axiosPath.users}/${userNo}/subs`,
    );

    return promise;
};

//구독 정보 수정
const useChangeSubscriptionInfo = async (
    userNo: number,
    subsNo: number | null,
    expiredAt: string,
    nextRenewAt: string | undefined,
    comments: string | undefined,
) => {
    const body = {
        expired_at: expiredAt,
        next_renew_at: nextRenewAt,
        comments: comments,
    };
    const result = await axiosInstance.patch(
        `${axiosPath.users}/${userNo}/subs/${subsNo}`,
        body,
    );
};

// 환불예정액 조회
const useCheckRefundPay = async (
    userNo: number | undefined,
    subsNo: number | null | undefined,
    orderCode: string | undefined | null,
) => {
    const body = {
        subs_no: subsNo,
        order_code: orderCode,
        reason: "",
        immediately: 1,
        will: 1,
        wanted_refund: 0,
        comments: "조회",
    };

    const result = await axiosInstance.delete(
        `${axiosPath.users}/${userNo}/subs/${subsNo}`,
        {
            data: body,
        },
    );

    return result;
};

//회원 상태 변경
const useSubscriptionStateEdit = async (
    userNo: number | undefined,
    subsNo: number | null | undefined,
    orderCode: string | undefined | null,
    wantedRefund: number | undefined,
    comments: string,
    immediately: number | undefined,
) => {
    const body = {
        subs_no: subsNo,
        order_code: orderCode,
        reason: "",
        immediately: immediately,
        will: 0,
        wanted_refund: immediately === 1 ? wantedRefund : 0,
        comments: comments,
    };

    if (immediately === 2) {
        const result = await axiosInstance.post(
            `${axiosPath.users}/${userNo}/subs/${subsNo}/cancel-refund`,
            { order_code: orderCode },
        );
        return result;
    } else {
        const result = await axiosInstance.delete(
            `${axiosPath.users}/${userNo}/subs/${subsNo}`,
            {
                data: body,
            },
        );
        return result;
    }
};

//회원 구독 강제 취소
const useSubscriptionForceDelete = async (userNo: number | undefined) => {
    const result = await axiosInstance.delete(
        `${axiosPath.nestUsers}/subs/force/${userNo}`,
    );
    return result;
};

//회원정보 수정
const useUpdateUserData = (userNo: number) => {
    const updateUser = async (params: any) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.users}/${userNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateUser);
};

//임시비밀번호 이메일 발송
const usePostUserPWEmail = (userNo: number) => {
    const postUserPWEmail = async (params: any) => {
        const promise = await axiosInstance.post(
            `${axiosPath.users}/${userNo}/passwd/reset`,
            params,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(postUserPWEmail);
};

//회원 탈퇴처리
const useDeleteUser = (userNo: number) => {
    const deleteUser = async (params: any) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.users}/${userNo}`,
            { data: params },
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(deleteUser);
};

const usePatchTestUserPassword = (userNo: number) => {
    const patchUserPassword = async (password: string) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.nestUsers}/${userNo}/password/test-user`,
            {
                password,
            },
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(patchUserPassword);
};
//등기 열람개수 조회
const useDeungiTicketCnt = async (userNo: number) => {
    const response = await axiosInstance.get(
        `${axiosPath.deungiV2}/count/1?userNo=${userNo}`,
    );
    return response;
};

// 기존 등기 열람 개수
const useFindExistingDeunggiTicketCount = (userNo: number) => {
    return useQuery<any>(["liveDeunggiCount", userNo], async () => {
        const res = await axiosInstance.get(
            `${axiosPath.deungiV2}/count/2?userNo=${userNo}`,
        );
        return res?.data;
    });
};

// 기존 등기 열람권 리스트
const fetchLiveDeunggiList = async (params: any) => {
    // const url = "";
    const newParams: any = { ...params, ticketType: 2 };
    delete newParams?.totalCount;
    const url = `${axiosPath.deungiV2}/tickets?${new URLSearchParams(
        newParams,
    )}`;
    const res = await axiosInstance.get(url);
    return res?.data;
};

const useFindAllExistingDeunggiList = (params: any) => {
    return useInfiniteQuery<IExistingDeunggiRes>({
        queryKey: ["liveDeunggiList", params],
        queryFn: ({ pageParam }) =>
            fetchLiveDeunggiList({
                ...params,
                page: pageParam ? pageParam : 1,
            }),
        getNextPageParam: (_lastPage: any, pages: any) => {
            return params.totalCount > pages?.length * 20 && pages?.length + 1;
        },
        enabled: false,
    });
};

const fetchDeungiListData = async (params: any) => {
    const newParams: any = { ...params, ticketType: 1 };
    const url = `${axiosPath.deungiV2}/tickets?${new URLSearchParams(
        newParams,
    )}`;

    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: DeungiDetailList[] = responseData.list;

    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCnt);
    } else {
        queryList.setTotalCount(responseData.totalCnt);
    }

    return queryList.getData(detailParse);
};

const detailParse = (originData: DeungiDetailList[]) => {
    const newData: DeungiDetailList[] = originData;

    return newData;
};

const useDeungiTicketList = (searchParams: any) => {
    const queryParams: any = searchParams;
    return useQuery<ResultDeungiListProps, Error>(
        [queryKeys.USER_DEUNGI_LIST, new URLSearchParams(queryParams)],
        () => fetchDeungiListData(queryParams),
        {},
    );
};

const useUpdateDeungi = async (ticketNo: number, expiredAt: string) => {
    const body = {
        ticketNo: ticketNo,
        expiredAt: expiredAt,
    };
    const response = await axiosInstance.post(
        `${axiosPath.deungi}/ticket/expired`,
        body,
    );
    return response;
};

const useRefundDeungi = async (
    ticketNo: number,
    refundPrice: number,
    reason: string,
) => {
    const body = {
        ticketNo: ticketNo,
        refundPrice: refundPrice,
        reason: reason,
    };
    const response = await axiosInstance.post(
        `${axiosPath.deungi}/ticket/refund`,
        body,
    );
    return response;
};

// 기존 등기 수정
const useSaverExistingDeunggiExpired = (
    onSuccess: (data: any) => void = () => {},
    onError: (err: any) => void = (err) => console.log("err>>>", err),
) => {
    return useMutation(
        async (data: any) => {
            try {
                const response = await axiosInstance.post(
                    `${axiosPath.deungi}/ticket/expired`,
                    data,
                );
                return response.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

// 기존 등기 환불
const useSaverRefundExistingDeunggi = (
    onSuccess: (data: any) => void = () => {},
    onError: (err: any) => void = (err) => console.log("err>>>", err),
) => {
    return useMutation(
        async (data: any) => {
            try {
                const response = await axiosInstance.post(
                    `${axiosPath.deungi}/ticket/refund`,
                    data,
                );
                return response.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

// 기존 등기 삭제
const useRemoverExistingDeunggi = (
    onSuccess: (data: any) => void = () => {},
    onError: (err: any) => void = (err) => console.log("err>>>", err),
) => {
    return useMutation(
        async (ticketNo: number) => {
            try {
                const response = await axiosInstance.delete(
                    `${axiosPath.deungi}/ticket/${ticketNo}`,
                );
                return response.data;
            } catch (Err) {
                console.log("removeError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCancelDeungi = async (ticketNo: number) => {
    const response = await axiosInstance.delete(
        `${axiosPath.deungi}/ticket/${ticketNo}`,
    );
    return response;
};

const useGetPartnerInfo = async (userNo: number) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestUsers}/${userNo}/payback-partner/connections`,
    );
    return response.data;
};

const useGetPartnerCode = async (userNo: number, code: string) => {
    const response = await axiosInstance.get(`${axiosPath.partner}/code`, {
        params: {
            userNo: userNo,
            code: code,
        },
    });

    return response.data;
};

const usePostPartnerConnections = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (data: any) => {
            try {
                const response = await axiosInstance.post(
                    `${axiosPath.nestUsers}/${data.userNo}/payback-partner/connections`,
                    data.body,
                );
                return response.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
const useUpdatePartnerConnections = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (data: any) => {
            try {
                const response = await axiosInstance.put(
                    `${axiosPath.nestUsers}/${data.userNo}/payback-partner/connections`,
                    data.body,
                );
                return response.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useDeletePartner = async (userNo: number) => {
    const response = await axiosInstance.put(
        `${axiosPath.nestUsers}/${userNo}/payback-partner/connections/cancel`,
    );
    return response.data;
};
export {
    useGetUserDetailData,
    useUpdateUserData,
    usePostUserPWEmail,
    useDeleteUser,
    useSubscriptionInfo,
    useChangeSubscriptionInfo,
    useCheckRefundPay,
    useSubscriptionStateEdit,
    usePatchTestUserPassword,
    useDeungiTicketCnt,
    useDeungiTicketList,
    useUpdateDeungi,
    useRefundDeungi,
    useRefundPGSaver,
    useGetPartnerInfo,
    useGetPartnerCode,
    usePostPartnerConnections,
    useUpdatePartnerConnections,
    useDeletePartner,
    useSubscriptionForceDelete,
    useCancelDeungi,
    useFindExistingDeunggiTicketCount,
    useFindAllExistingDeunggiList,
    useSaverRefundExistingDeunggi,
    useRemoverExistingDeunggi,
    useSaverExistingDeunggiExpired,
};
