import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { EditModal, SearchInput } from "@src/components";

interface ISearchModal {
    title?: string;
    buttonText?: string;
    placeholder?: string;
    isModalShow: boolean;
    isLoading: boolean;
    list: any[];
    headers: any[];
    handleOpenModal: () => void;
    handleSave: (index: number) => void;
    handleSearch: (value?: string) => void;
    errorMessage?: string;
}
function SearchModal({
    title = "",
    buttonText = "입력",
    placeholder = "상호명/대표자명/중개번호('-'포함)/주소 입력",
    isModalShow,
    isLoading = false,
    list = [],
    headers = [],
    handleOpenModal,
    handleSave,
    handleSearch,
    errorMessage = "",
}: ISearchModal) {
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [searchResultList, setSearchResultList] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>();
    const initChange = () => {
        setSearchResultList([]);
        setSelectedItem({});
        setSaveBtnDisabled(true);
    };
    useEffect(() => {
        //모달창이 닫히면 초기화 실행
        isModalShow === false && initChange();
    }, [isModalShow]);
    useEffect(() => {
        setSearchResultList(list);
    }, [list]);
    useEffect(() => {
        if (errorMessage !== "") {
            setSaveBtnDisabled(true);
        }
    }, [errorMessage]);
    const handleSearchBtnClick = (value: string) => {
        handleSearch(value);
    };
    const handleSaveBtnClick = () => {
        handleSave(selectedItem);
    };
    return (
        <EditModal
            isModalShow={isModalShow}
            title={`${title} 검색`}
            buttonText={buttonText}
            handleOpenModal={handleOpenModal}
            size={"fitContent"}
            handleBtnClick={handleSaveBtnClick}
            isDisabled={saveBtnDisabled}
            errorMessage={errorMessage}
        >
            <div css={searchContents}>
                <div className="searchBox">
                    <span>검색어</span>
                    <SearchInput
                        placeholder={placeholder}
                        handleClick={handleSearchBtnClick}
                        isDisabled={false}
                    />
                </div>
                <ul className="searchResultList">
                    {isLoading ? (
                        <div css={loadingBarDiv}>
                            <div></div>
                            <p>로딩중입니다.</p>
                        </div>
                    ) : searchResultList?.length === 0 ? (
                        <li className="noData">데이터 없음</li>
                    ) : (
                        searchResultList?.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    className={"subItems"}
                                    onClick={() => {
                                        setSelectedItem(
                                            searchResultList[index],
                                        );
                                        setSaveBtnDisabled(false);
                                    }}
                                >
                                    <input
                                        type={"radio"}
                                        id={`item${index}`}
                                        name="item"
                                    />
                                    <label htmlFor={`item${index}`}>
                                        <ul className="subItem">
                                            {Object.values(item).map(
                                                (v: any, i) => {
                                                    return (
                                                        headers[i] && (
                                                            <li key={i}>
                                                                <div className="header">
                                                                    {headers[i]}
                                                                </div>
                                                                <span className="text">
                                                                    {v}
                                                                </span>
                                                            </li>
                                                        )
                                                    );
                                                },
                                            )}
                                        </ul>
                                    </label>
                                </li>
                            );
                        })
                    )}
                </ul>
            </div>
        </EditModal>
    );
}

export default SearchModal;

const searchContents = css`
    width: 700px;
    .searchBox {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            width: 80px;
            ${Common.textStyle.body3_R14}
        }
    }
    .searchResultList {
        height: 100%;
        min-height: 320px;
        max-height: 400px;
        overflow: scroll;
        .noData {
            margin-top: 20px;
            height: 400px;
            text-align: center;
            padding-top: 25%;
            ${Common.textStyle.body3_R14}
            background-color: rgba(0,0,0,0.1);
            display: block;
        }
        .subItems {
            border-bottom: 1px solid ${Common.colors.gray100};
            &:first-of-type {
                margin-top: 10px;
            }
            input {
                display: none;
            }
        }
        input[type="radio"]:checked + label {
            display: block;
            width: 100%;
        }
        input[type="radio"]:checked + label > ul {
            background-color: rgba(154, 73, 242, 0.15);
        }
        .subItem {
            padding-top: 20px;
            padding-bottom: 20px;
            cursor: pointer;
            div {
                margin-bottom: 15px;
                span:first-of-type {
                    text-indent: 10px;
                    display: inline-block;
                    width: 40%;
                    color: ${Common.colors.gray600};
                    ${Common.textStyle.title6_R14}
                }
                span:last-of-type {
                    display: inline-block;
                    width: 60%;
                    ${Common.textStyle.title5_M14}
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            li {
                padding-left: 20px;
                margin-bottom: 10px;
                display: flex;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .header {
                margin-right: 80px;
                color: ${Common.colors.gray600};
                ${Common.textStyle.title6_R14}
                min-width:100px;
            }
            .text {
                ${Common.textStyle.title5_M14}
            }
        }
    }
`;
const loadingBarDiv = css`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    div {
        width: 20px;
        height: 20px;
        border: 4px solid ${Common.colors.white};
        border-top-color: ${Common.colors.purple500};
        border-radius: 50%;
        animation: spin 1s infinite;
        transform-origin: center;
        transform: rotate(0deg);
        transition: rotate 1s;
    }
    @keyframes spin {
        from {
            transform-origin: center;
            transform: rotate(0deg);
        }
        to {
            transform-origin: center;
            transform: rotate(360deg);
        }
    }
    p {
        ${Common.textStyle.title2_M16}
        color: ${Common.colors.white};
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: 65px;
        transform: translate(-50%, -50%);
    }
`;
