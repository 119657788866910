import React, { MouseEvent } from "react";

import { css } from "@emotion/react";
import EditIcon from "@src/assets/editIcon.png";
import TrashIcon from "@src/assets/trashIcon.png";
import ConverterIcon from "@src/assets/converterIcon.png";
import plusIcon from "@src/assets/plus.png";
import CopyIcon from "@src/assets/copy.png";
import ListIcon from "@src/assets/ListIcon.png";
import restoreIcon from "@src/assets/restore.png";

interface IIconButton {
    type: string;
    color?: "black" | "gray" | "orange";
    handleClick: (e: MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
}

function IconButton({
    type = "edit",
    color = "black",
    handleClick,
    isDisabled = false,
}: IIconButton) {
    const isArrowType = type === "arrow";
    const arrowButton = (
        <button type="button" css={iconButtonStyle} onClick={handleClick}>
            &gt;
        </button>
    );

    const imgButton = (type: string) => {
        let text = "수정하기";
        let addStyle = [editButtonStyle, colors[color]];

        if (type === "trash") {
            text = "삭제하기";
            addStyle = [trashButtonStyle];
        }

        if (type === "converter") {
            text = "면적변환하기";
            addStyle = [converterButtonStyle];
        }

        if (type === "plus") {
            text = "";
            addStyle = [plusButtonStyle];
        }

        if (type === "copy") {
            text = "복제하기";
            addStyle = [copyButtonStyle];
        }
        if (type === "list") {
            text = "";
            addStyle = [ListButtonStyle];
        }
        if (type === "restore") {
            text = "";
            addStyle = [restoreButtonStyle];
        }

        return (
            <button
                type="button"
                onClick={handleClick}
                css={[iconButtonStyle, addStyle]}
                disabled={isDisabled}
            >
                {text}
            </button>
        );
    };

    return <>{isArrowType ? arrowButton : imgButton(type)}</>;
}

const iconButtonStyle = css`
    display: block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
`;

const trashButtonStyle = css`
    font-size: 0;
    text-indent: -9999px;
    background: url(${TrashIcon}) no-repeat;
    background-size: cover;
`;
const ListButtonStyle = css`
    font-size: 0;
    text-indent: -9999px;
    width: 79px;
    height: 32px;
    background: url(${ListIcon}) no-repeat;
    background-size: cover;
`;
const editButtonStyle = css`
    font-size: 0;
    text-indent: -9999px;
    background: url(${EditIcon}) no-repeat;
    background-size: 93px;
`;
const copyButtonStyle = css`
    font-size: 0;
    text-indent: -9999px;
    background: url(${CopyIcon}) no-repeat;
    width: 39px;
    cursor: pointer;
`;
const converterButtonStyle = css`
    font-size: 0;
    text-indent: -9999px;
    width: 48px;
    background: url(${ConverterIcon}) no-repeat;
    background-size: cover;
`;

const restoreButtonStyle = css`
    font-size: 0;
    text-indent: -9999px;
    width: 24px;
    background: url(${restoreIcon}) no-repeat;
    background-size: cover;
`;

const colors: { [index: string]: any } = {
    black: css`
        background-position: -4px -4px;
    `,
    gray: css`
        background-position: -35px -4px;
    `,
    orange: css`
        background-position: -66px -4px;
    `,
};

const plusButtonStyle = css`
    background: url(${plusIcon}) no-repeat;
    background-size: 24px 24px;
`;

export default IconButton;
