import React, { Fragment, useEffect, useState, ChangeEvent } from "react";
import { DropDown } from "@src/components";

interface IMultiDropDown {
    firstData: any;
    secondData: any;
    handleChange: (
        first: { key: string; displayName: string },
        second: { key: string; displayName: string },
    ) => void;
    defaultValue: {
        first: { key: string; displayName: string };
        second: { key: string; displayName: string };
    };
    clear?: boolean;
}

const MultiDropDown = ({
    firstData,
    secondData,
    defaultValue,
    handleChange,
    clear = false,
}: IMultiDropDown) => {
    const [initValue, setInitValue] = useState(defaultValue);
    const [selectedFirstDisplayName, setSelectedFirstDisplayName] = useState(
        defaultValue.first.displayName,
    );
    const [selectedFirstValue, setSelectedFirstValue] = useState(
        defaultValue.first.key,
    );
    const [updateList, setUpdateList] = useState<any>([{ key: "", value: "" }]);

    const createUsageSmallList = (list: any) => {
        return Object.entries(list).map((value, index) => {
            const { name, code }: any = value[1];
            return {
                id: index,
                key: code,
                value: name,
            };
        });
    };

    const resetSmallList = () => {
        const filter = createUsageSmallList(secondData[defaultValue.first.key]);
        setUpdateList(filter);

        return { filter };
    };

    useEffect(() => {
        resetSmallList();
    }, []);

    useEffect(() => {
        if (clear) {
            const { filter } = resetSmallList();
            const second = { key: filter[0].value, displayName: filter[0].key };
            setInitValue(defaultValue);
            setSelectedFirstDisplayName(initValue.first.displayName);
            setSelectedFirstValue(initValue.first.key);

            handleChange(initValue.first, second);
        }
    }, [clear]);

    const onChange = (e: any, text: string, value: string, id: string) => {
        let firstDisplayName = "";
        let firstValue = "";
        let secondText = "";
        let secondValue = "";

        if (id === "first") {
            firstDisplayName = text;
            firstValue = value;

            setSelectedFirstDisplayName(firstDisplayName);
            setSelectedFirstValue(firstValue);

            const filter = createUsageSmallList(secondData[firstValue]);
            setUpdateList(filter);

            secondText = filter[0].value;
            secondValue = filter[0].key;
        }

        if (id === "second") {
            firstDisplayName = selectedFirstDisplayName;
            firstValue = selectedFirstValue;
            secondText = text;
            secondValue = value;
        }

        const first = { key: firstValue, displayName: firstDisplayName };
        const second = { key: secondText, displayName: secondValue };

        handleChange(first, second);
    };

    return (
        <Fragment key={"DropDown" + Number(clear)}>
            <DropDown
                data={firstData}
                handleChange={(
                    e: ChangeEvent<HTMLSelectElement>,
                    text?: string,
                    value?: string,
                ) => text && value && onChange(e, text, value, "first")}
                defaultValue={initValue.first.key}
            />
            &nbsp;&nbsp;
            <Fragment key={updateList.length}>
                <DropDown
                    data={updateList}
                    handleChange={(
                        e: ChangeEvent<HTMLSelectElement>,
                        text?: string,
                        value?: string,
                    ) => text && value && onChange(e, text, value, "second")}
                    defaultValue={
                        updateList[0].key !== ""
                            ? updateList[0].key
                            : initValue.second.key
                    }
                />
            </Fragment>
        </Fragment>
    );
};
export default MultiDropDown;
