import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import { css } from "@emotion/react";
import { RangeCalendar, DropDown, Checkbox, Input } from "@src/components";
import { Common } from "@src/styles/Common";
import { UserFilterType } from "./types";
import { ConvertDate } from "@src/utils/dateUtil";
import { initFilters } from "@src/hooks/queries/useUser";
import { useLocation, useSearchParams } from "react-router-dom";
import { dateParse } from "@src/utils/dateUtil";
import { set, sub } from "date-fns";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useBannerList, useGetFilterBannerList } from "@src/hooks/queries/useBanner";
import { getBannerStatRequestDto, initBannerStateRequestDto } from "../expert/types";

interface IUserFilter {
    handleSubmit: (data: any) => void;
    handleSearchClick: () => void;
    dropList: {
        id: number;
        key: string;
        value: string;
    }[];
    clear?: boolean;
}

function UserBannerClikcFilter({
    handleSubmit,
    handleSearchClick,
    dropList,
    clear,
}: IUserFilter) {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const parseQuery = useMemo(
        () => Object.fromEntries(searchParams),
        [location.search],
    );
    const initPartnerChecked = {
        noInput: false,
        input: false,
    };

    const [searchType, setSearchType] = useState<string>("name"); //검색 카테고리
    const [searchKeyword, setSearchKeyword] = useState<string>(""); //검색 키워드
    const [filters, setFilters] = useState<getBannerStatRequestDto>(initBannerStateRequestDto);
    const [subTypeArr, setSubTypeArr] = useState<number[]>([]);

    const [partnerChecked, setPartnerChecked] =
        useState<any>(initPartnerChecked);
    const [selectedDate, setSelectedDate] = useState<number | null>(0);
    const dateLabel = [
        //날짜 라디오 버튼
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];
    const subType = [0, 1, 2, 3, 4];
    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const [bannerImg, setBannerImg] = useState("");

    const [filterBanner, setFilterBanner] = useState<any[]>([]);

    const { data: bannerData } = useGetFilterBannerList({ value: "pcHomeWide" }, true);



    useEffect(() => {
        if (bannerData) {
            setFilterBanner(bannerData?.list);
            setFilters({
                ...filters,
                bannerNo: bannerData?.list[0].no
            })
            setBannerImg(bannerData?.list[0].imgurl)
        }
    }, [bannerData])

    const renderDate = (item: number) => {
        if (item == 0) {
            setFilters({
                ...filters,
                startDate: "",
                endDate: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                startDate: before7day,
                endDate: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                startDate: before1Month,
                endDate: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                startDate: before6Month,
                endDate: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                startDate: before12Month,
                endDate: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                startDate: TODAY,
                endDate: TODAY,
            });
        }
        setSelectedDate(item);
    };

    useEffect(() => {
        const { subType, startDate, endDate } = parseQuery;

        if (parseQuery) {
            setFilters({
                ...filters,
                startDate: startDate ? startDate.toString() : null,
                endDate: endDate ? endDate.toString() : null,
                // userSubTypes: querySubChecked,
                searchType: searchType,
                searchText: searchKeyword,
            });
            // setSubChecked(Object.assign(subsChecked, querySubCheckedObj));
        }
    }, [parseQuery]);

    const handleDropChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setFilters({
            ...filters,
            searchType: e.target.value,
            searchText: "",
        });

        setSearchKeyword(""); //검색 카테고리 변경시 이전 카테고리의 키워드 초기화
        setSearchType(e.target.value);
    };
    const handleBannerNoChange = (e: any) => {
        setFilters({
            ...filters,
            bannerNo: Number(e)
        });

        setBannerImg(bannerData.list.filter((data: any) => data.no === Number(e))[0]?.imgurl)
    }
    const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value);

        setFilters({
            ...filters,
            searchText: e.target.value,
        });
    };
    const removeEmptyObj = (update: UserFilterType) => {
        return Object.fromEntries(
            Object.entries(update).filter(([_, value]) => value !== ""),
        );
    };

    const handleSubTypeChange = (idx: number) => {
        if (subTypeArr.includes(idx)) {
            setSubTypeArr(subTypeArr.filter((data) => data !== idx));
        } else {
            setSubTypeArr([...subTypeArr, idx]);
        }
    }

    useEffect(() => {
        setFilters({
            ...filters,
            userSubTypes: subTypeArr
        })
    }, [subTypeArr])

    useEffect(() => {
        handleSubmit(removeEmptyObj(filters));
    }, [filters]);

    useEffect(() => {
        //전체 해제 버튼 클릭시
        if (clear) {
            setFilters(initBannerStateRequestDto);
            setSearchType("name");
            setSearchKeyword("");
            setSubTypeArr([]);
            setPartnerChecked({
                noInput: false,
                input: false,
            });
        }
    }, [clear]);

    useEffect(() => {
        if (selectedDate == 0 && (filters.startDate !== undefined || filters.endDate !== undefined)) {
            setSelectedDate(-1);
        } else if (selectedDate == 2 && (filters.startDate !== before7day || filters.endDate !== TODAY)) {
            setSelectedDate(-1)
        } else if (selectedDate == 3 && (filters.startDate !== before1Month || filters.endDate !== TODAY)) {
            setSelectedDate(-1)
        } else if (selectedDate == 4 && (filters.startDate !== before6Month || filters.endDate !== TODAY)) {
            setSelectedDate(-1)
        } else if (selectedDate == 5 && (filters.startDate !== before12Month || filters.endDate !== TODAY)) {
            setSelectedDate(-1)
        }
    }, [filters.startDate, filters.endDate])

    return (
        <div css={FilterDiv}>
            <ul>
                <li>
                    <span className="subTitle" style={{ whiteSpace: "nowrap" }}>배너 선택</span>
                    <DropDown
                        data={filterBanner}
                        handleBannerChange={handleBannerNoChange}
                        handleChange={(e) => { }} />
                </li>
                <li>
                    <span className="subTitle" style={{ whiteSpace: "nowrap" }}>배너 클릭 횟수 <br /> 조회 기간</span>
                    <div>
                        {dateLabel.map((item) => {
                            return (
                                <div key={item.id}>
                                    <button
                                        value={item.value}
                                        className={
                                            "datebtn" +
                                            (item.key == selectedDate
                                                ? " active"
                                                : "")
                                        }
                                        onClick={() => renderDate(item.key)}
                                    >
                                        {item.value}
                                    </button>
                                </div>
                            );
                        })}
                        <RangeCalendar
                            setRangeDate={{
                                startDate:
                                    filters.startDate !== null &&
                                        filters.startDate !== undefined
                                        ? dateParse(filters.startDate)
                                        : null,
                                endDate:
                                    filters.endDate !== null &&
                                        filters.endDate !== undefined
                                        ? dateParse(filters.endDate)
                                        : null,
                            }}
                            getUserRangeDate={(date, isError) => {
                                const { startDate: start, endDate: end } = date;
                                setFilters({
                                    ...filters,
                                    startDate: start ? ConvertDate(start) : "",
                                    endDate: end ? ConvertDate(end) : "",
                                });
                            }}
                            verticalAlign={false}
                            isDisabled={false}
                            timePicker={false}
                            clear={clear}
                        />
                    </div>
                </li>

                <li>
                    <span className="subTitle">구독상태</span>
                    <div className="chkBoxDiv">
                        {subType.map((check, idx) => (
                            <Checkbox
                                key={check.toString()}
                                isDisabled={false}
                                isChecked={subTypeArr.filter((data) => data === check + 1).length > 0}
                                label={
                                    idx === 0
                                        ? "유료 구독 "
                                        : idx === 1
                                            ? "무료 구독"
                                            : idx == 2
                                                ? "경매 상품"
                                                : idx === 3
                                                    ? "지도 상품"
                                                    : '미구독'
                                }
                                defaultValue={check}
                                handleChange={() => {
                                    handleSubTypeChange(idx + 1);
                                }}
                            />
                        ))}
                        {/* <Checkbox
                            isDisabled={false}
                            isChecked={subChecked["paid"]}
                            label="정기구독-유료"
                            defaultValue={"paid"}
                            handleChange={(n, v) => {
                                const { setCheckedListSub, subType } =
                                    handleSubStatusChange(n, v);
                                setFilters({
                                    ...filters,
                                    subType: subType.join(),
                                });
                                setSubChecked(setCheckedListSub);
                            }}
                        />
                        <Checkbox
                            isDisabled={false}
                            isChecked={subChecked["none"]}
                            label="정기구독-미구독"
                            defaultValue={"none"}
                            handleChange={(n, v) => {
                                const { setCheckedListSub, subType } =
                                    handleSubStatusChange(n, v);
                                setFilters({
                                    ...filters,
                                    subType: subType.join(),
                                });
                                setSubChecked(setCheckedListSub);
                            }}
                        /> */}
                    </div>
                </li>
                <li>
                    <span className="subTitle">검색</span>
                    <div>
                        <DropDown
                            handleChange={handleDropChange}
                            data={dropList}
                            defaultValue={searchType}
                        />
                        <Input
                            label=""
                            value={searchKeyword}
                            name="searchInput"
                            type="text"
                            size="medium"
                            handleChange={handleKeywordChange}
                            onKeyDown={(e: any) => {
                                if (e.key === "Enter") {
                                    handleSearchClick();
                                }
                            }}
                            isDisabled={false}
                            errorMessage=""
                            placeholder="이름이나 연락처, 이메일, 파트너 닉네임을 입력하세요"
                        />
                    </div>
                </li>
            </ul>
            <div className="imgContainer">
                {
                    bannerImg !== "" &&
                    <img src={bannerImg} alt="" />
                }
            </div>
        </div>
    );
}
const FilterDiv = css`
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};

    .imgContainer {
        & > img {
            width: 550px;
        }
    }

    & > ul {
        width: 60%;
    }
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .datebtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .datebtn.active {
        font-weight: 700;
    }
`;
export default UserBannerClikcFilter;
