import React from "react";
import Router from "./Router";
import "./App.css";
import { BrowserRouter } from "react-router-dom";

function App() {
    const url = new URL(process.env.PUBLIC_URL || "http://localhost");
    const basePath = url.pathname;

    return basePath !== "/" ? (
        <BrowserRouter basename={basePath}>
            <Router />
        </BrowserRouter>
    ) : (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    );
}

export default App;
