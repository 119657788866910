export const Age = (number) => {
    return `${number}세`;
};

export const ConvertPrice = (price) => {
    return Number(price).toLocaleString("ko-KR");
};

export const ConvertPhoneNumber = (number) => {
    let result = "";
    if (!isNaN(number)) {
        if (number.length === 11) {
            result = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (number.length === 8) {
            result = number.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
            if (number.indexOf("02") === 0) {
                result = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
            } else {
                result = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
            }
        }
    } else {
        result = "-";
    }
    return result;
};
