import React, { ChangeEvent, useEffect, useState } from "react";
import "@src/styles/CommonTable.css";
import { Calendar, Input } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { SkipBidHistoryProps } from "@src/interfaces/product/SkipBidHistoryContainer.interface";
import {
    useGetSkipBidHistory,
    useUpdateSkipBidHistory,
} from "@src/hooks/queries/product/useSkipBidHistory";
import { useDispatch } from "react-redux";
import { setSuccess, setError } from "@src/reducers/productUpdateResult";

interface ISkipBidHistoryContainer {
    isEdit: boolean;
    handleUpdate?: Function;
    productNo: string;
    isUpdating: boolean;
}
function SkipBidHistoryContainer({
    isEdit = false,
    productNo,
    isUpdating = false,
}: ISkipBidHistoryContainer) {
    const dispatch = useDispatch();
    const headerMeta = ["구분", "매각기일", "최저 매각가(원)", "결과"];
    const [tableData, setTableData] = useState<any>();
    const PRODUCT_NO = productNo;
    const { isLoading, isError, data, refetch } =
        useGetSkipBidHistory(PRODUCT_NO);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setTableData(data);
        }
    }, [data, isLoading, isError]);

    const handleChange = (updateData: SkipBidHistoryProps) => {
        let newTableData = [...tableData];
        const index = newTableData.findIndex((v) => v.id === updateData.id);
        newTableData[index] = updateData;
        setTableData(newTableData);
    };
    const updateParams = (updateData: SkipBidHistoryProps[]) => {
        return updateData?.map((data) => {
            return {
                stage: data.round,
                due_date: data.bidDate,
                min_price: data.minPrice
                    ? resultPrice(data.minPrice) + "원"
                    : "",
                result: data.bidResult,
                return: data.returnText,
            };
        });
    };
    const { mutate } = useUpdateSkipBidHistory(productNo);
    const updateMutate = (params: any) => {
        mutate(params, {
            onSuccess: () => {
                dispatch(setSuccess());
                refetch();
            },
            onError: (error: any) => {
                dispatch(setError({ errMsg: error.toString() }));
            },
        });
    };
    useEffect(() => {
        if (isUpdating) {
            let params = updateParams(tableData);
            updateMutate(params);
        }
    }, [isUpdating]);
    return (
        <div style={{ minWidth: "1000px" }}>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data: any, index: number) => {
                            return (
                                <TableRow
                                    key={`${index}_${index + 1}`}
                                    data={data}
                                    isEdit={isEdit}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}
const TableRow = ({ data, isEdit, handleChange }: any) => {
    const [rowData, setRowData] = useState<SkipBidHistoryProps>(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const { round, bidDate, minPrice, bidResult, returnText } = rowData;
    const isMergeColumn = returnText !== "";
    const onChangeDate = (value: number) => {
        setRowData((prev: any) => ({
            ...prev,
            bidDate: ConvertDate(value, "yyyy.MM.dd"),
        }));
    };

    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRowData({
            ...rowData,
            [name]: value,
        });
    };
    useEffect(() => {
        handleChange(rowData);
    }, [rowData]);
    return (
        <>
            <tr
                key={data.id + data.bidDate}
                style={isEdit ? { borderBottom: "none" } : undefined}
            >
                <td>
                    <Input
                        name="round"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={true}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={round}
                    />
                </td>
                <td>
                    <Calendar
                        setDate={bidDate !== null ? Date.parse(bidDate) : 0}
                        getUserDate={(value: any) => {
                            onChangeDate(value);
                        }}
                        isDisabled={!isEdit}
                    />
                </td>
                <td>
                    <Input
                        name="minPrice"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={!isEdit}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={isEdit ? minPrice : resultPrice(minPrice)}
                    />
                </td>
                <td>
                    <Input
                        name="bidResult"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={!isEdit}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={
                            isEdit ? bidResult : ConvertEmptyValue(bidResult)
                        }
                    />
                </td>
            </tr>
            {isMergeColumn && (
                <tr
                    key={data.id}
                    style={isEdit ? { borderBottom: "none" } : undefined}
                >
                    <td colSpan={4}>
                        <Input
                            name="returnText"
                            errorMessage={""}
                            handleChange={onChangeField}
                            isDisabled={!isEdit}
                            label=""
                            placeholder=""
                            size="dynamic"
                            type="text"
                            value={returnText}
                        />
                    </td>
                </tr>
            )}
        </>
    );
};

export default SkipBidHistoryContainer;
