/**
 * 필터 조건(객체)을 넣으면 쿼리스트링으로 변환하는 함수
 */
function makeQueryString(obj) {
    return Object.entries(obj)
        .filter(([_, v]) => v !== "" && v !== null && v !== undefined)
        .map((e) => e.join("="))
        .join("&");
}

export { makeQueryString };
