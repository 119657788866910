export const firstPage = "/home";
export const pages = [
    { link: "/home", title: "홈" },
    { link: "/monitor", title: "서버 현황" },
    // { link: "/op/list", title: "관리자 관리" }, 2022-05-10 권한처리가 후순위가 되면서 관리처리 하지 않음
    { link: "/admins/list", title: "관리자 관리" },
    {
        link: "/business/viewsCount",
        title: "영업 관리",
        subLinks: [
            { link: "/business/viewsCount", title: "물건열람횟수 조회" },
            { link: "/business/lotViewsCount", title: "필지상세 열람횟수 조회" },
            { link: "/business/bannerClickCount", title: "배너 클릭 회원 조회" },
        ],
    },
    {
        link: "/users/list",
        title: "회원 관리",
    },

    {
        link: "/experts/home",
        title: "전문가 관리",
        subLinks: [
            { link: "/experts/home", title: "전문가 관리" },
            { link: "/experts/user/list", title: "전문가 회원 관리" },
            { link: "/experts/advertisement/list", title: "전문가 광고 관리" },
            { link: "/experts/inquiry/list", title: "문의 관리" },
            // { link: "/experts/user/list", title: "상품 관리" },
            // { link: "/commodity/provision", title: "상품 지급 관리" },
        ],
    },
    {
        link: "/commodity/list",
        title: "상품 관리",
        subLinks: [
            { link: "/commodity/list", title: "상품 관리" },
            { link: "/commodity/provision", title: "상품 지급 관리" },
        ],
    },
    {
        link: "/deungi/list",
        title: "등기 및 대장 관리",
        subLinks: [
            { link: "/deungi/list", title: "등기 열람 관리" },
            { link: "/deungi/notice", title: "점검 일정 관리" },
        ],
    },
    {
        link: "/deungiChange/list",
        title: "등기변동알림 관리",
        subLinks: [{ link: "/deungiChange/list", title: "등기변동알림 관리" }],
    },
    {
        link: "/payment/list",
        title: "결제 관리",
        subLinks: [
            { link: "/payment/list", title: "결제 내역" },
            { link: "/payment/subscribe", title: "정기구독 결제 관리" },
            { link: "/payment/expert", title: "전문가 결제 관리" },
        ],
    },
    {
        link: "/revenue/list",
        title: "매출 관리",
        subLinks: [{ link: "/revenue/list", title: "매출 내역" }],
    },
    {
        link: "/coupon/list",
        title: "쿠폰 관리",
    },
    { link: "/products/list", title: "매물 관리" },
    { link: "/banners/list", title: "배너 관리" },
    { link: "/campus/management/lectures", title: "캠퍼스 강의 관리" },
    { link: "/news/list", title: "뉴스 관리" },
    {
        link: "/notify/sms",
        title: "알림 관리",
        subLinks: [
            { link: "/notify/sms", title: "SMS 관리" },
            { link: "/notify/push", title: "PUSH 관리" },
        ],
    },
    {
        link: "/help/support",
        title: "문의 관리",
        subLinks: [
            { link: "/help/support", title: "1:1 문의" },
            { link: "/help/faq", title: "자주 묻는 질문 (FAQ)" },
            { link: "/help/notice", title: "공지사항" },
        ],
    },
    {
        link: "/partner/list",
        title: "파트너 관리",
        subLinks: [
            { link: "/partner/list?page=1&limit=10&searchColumn=userInfo.uname", title: "파트너 관리" },
            { link: "/partner/settlement", title: "파트너 정산관리" },
            { link: "/partner/benefits", title: "파트너 혜택관리" },
        ],
    },
    { link: "/logout", title: "로그아웃" },
];
