import styled from "@emotion/styled";
import { Button, Checkbox, RangeCalendar } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { dateParse } from "@src/utils/dateUtil";
import { makeQueryString } from "@src/utils/queryStringUtil";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import {
    getQueryString,
    useCommodityList,
} from "@src/hooks/queries/useCommodity";
import { endLoading, startLoading } from "@src/reducers/loading";
import { getQueryData } from "@src/utils/useQueryClient";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import { useDispatch } from "react-redux";

export interface CommodityFilterType {
    startDate?: string | null;
    endDate?: string | null;
    page?: number;
    limit?: number;
    saleTypes?: string | null;
    q?: string;
}

interface ICommodityFilterProps {
    clear?: boolean;
    handleSubmit: (data: any) => void;
    setClear: Dispatch<SetStateAction<boolean>>;
    option: CommodityFilterType;
    setList: any;
    setTotalCount: Dispatch<SetStateAction<number>>;
    setParams: Dispatch<SetStateAction<CommodityFilterType>>;
    params: CommodityFilterType;
    searchParams: URLSearchParams;
    setSearchParams: (
        nextInit: URLSearchParamsInit,
        navigateOptions?:
            | {
                  replace?: boolean | undefined;
                  state?: any;
              }
            | undefined,
    ) => void;
    copyQuery: CommodityFilterType;
    handleClearClick: () => void;
    isChangeUrl: boolean;
}

const CommodityFilter = ({
    clear,
    handleSubmit,
    setClear,
    option,
    setList,
    setTotalCount,
    setParams,
    params,
    searchParams,
    setSearchParams,
    copyQuery,
    handleClearClick,
    isChangeUrl,
}: ICommodityFilterProps) => {
    const dispatch = useDispatch();
    const [reasonValue, setReasonValue] = useState<string>("");

    // const [searchParams, setSearchParams] = useSearchParams();
    // const copyQuery: CommodityFilterType = Object.fromEntries(searchParams);
    const newQuery = getQueryString(copyQuery);
    const queryData: { result: any[]; total: number } = getQueryData([
        queryKeys.COMMODITY_LIST,
        newQuery,
    ]);
    const initFilters: CommodityFilterType = {
        startDate: "",
        endDate: "",
        page: 1,
        limit: 10,
        saleTypes: "",
        q: "",
    };
    const initChecked = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
    };
    const [filters, setFilters] = useState<CommodityFilterType>(initFilters);
    // const [params, setParams] = useState<CommodityFilterType>(initFilters);
    const [isChecked, setIsChecked] = useState(initChecked);

    const handleCheckBoxChange = (name: string, checked: boolean) => {
        let setCheckedList = {
            ...isChecked,
            [name]: checked,
        };

        const statusType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedList, statusType };
    };
    const updateURL = (value: CommodityFilterType) => {
        const queryString = makeQueryString(value);
        setSearchParams(queryString);
    };

    const handleSearchClick = () => {
        setParams(option);
        setClear(false);
        updateURL(option);
    };

    const {
        isLoading,
        data = { result: [], total: 0 },
        isError,
        refetch,
    } = useCommodityList(params, true);

    useEffect(() => {
        refetch();
    }, []);
    // const [list, setList] = useState<any>(data.result);
    useEffect(() => {
        if (queryData) {
            setList(queryData.result);
            setTotalCount(queryData.total);
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setList(data.result);
                if (params.page === 1) {
                    setTotalCount(data.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [isLoading, data.result, isError, queryData]);

    const removeEmptyObj = (update: CommodityFilterType) => {
        return Object.fromEntries(
            Object.entries(update).filter(([_, value]) => value !== ""),
        );
    };
    useEffect(() => {
        handleSubmit(removeEmptyObj(filters));
    }, [filters]);

    useEffect(() => {
        //전체 해제 버튼 클릭시
        if (clear) {
            setFilters(initFilters);
            setReasonValue("");
            setIsChecked({
                0: false,
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false,
                8: false,
            });
        }
    }, [clear]);

    return (
        <>
            <StyledCommodityFilter>
                <StyledFilterBox>
                    <div className="itemRow">
                        <span>등록일시</span>
                        <RangeCalendar
                            setRangeDate={{
                                startDate:
                                    filters.startDate !== null &&
                                    filters.startDate !== undefined
                                        ? dateParse(filters.startDate)
                                        : null,
                                endDate:
                                    filters.endDate !== null &&
                                    filters.endDate !== undefined
                                        ? dateParse(filters.endDate)
                                        : null,
                            }}
                            getUserRangeDate={(date, isError) => {
                                const { startDate: start, endDate: end } = date;
                                setFilters({
                                    ...filters,
                                    startDate: start ? ConvertDate(start) : "",
                                    endDate: end ? ConvertDate(end) : "",
                                });
                            }}
                            verticalAlign={false}
                            isDisabled={false}
                            timePicker={false}
                            clear={clear}
                        />
                    </div>
                    <div className="itemRow">
                        <span>판매형태</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[0]}
                                label="일반판매 미노출"
                                defaultValue={"0"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[1]}
                                label="일반판매 노출"
                                defaultValue={"1"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[2]}
                                label="파트너스 제휴 판매 노출"
                                defaultValue={"2"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[3]}
                                label="직원용"
                                defaultValue={"3"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[4]}
                                label="무료 쿠폰용"
                                defaultValue={"4"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[5]}
                                label="유료 쿠폰용"
                                defaultValue={"5"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[6]}
                                label="AOS노출용"
                                defaultValue={"6"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[7]}
                                label="IOS노출용"
                                defaultValue={"7"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isChecked[8]}
                                label="WEB노출용"
                                defaultValue={"8"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(n, v);
                                    setFilters({
                                        ...filters,
                                        saleTypes: statusType.join(),
                                    });
                                    setIsChecked(setCheckedList);
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>검색</span>
                        <StyledReasonWrapper>
                            <input
                                type="text"
                                placeholder="상품코드나 상품명, 구독이름, 상품설명을  입력해주새요 "
                                onChange={(e: any) => {
                                    setReasonValue(e.target.value);
                                    setFilters({
                                        ...filters,
                                        q: e.target.value,
                                    });
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        handleSearchClick();
                                    }
                                }}
                                value={reasonValue}
                            />
                        </StyledReasonWrapper>
                    </div>
                </StyledFilterBox>
                <StyledSearchButton>
                    <Button
                        color="gray"
                        handleClick={() => {
                            handleClearClick();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            handleSearchClick();
                        }}
                        label="검색"
                        size="medium"
                    />
                </StyledSearchButton>
            </StyledCommodityFilter>
        </>
    );
};

export default CommodityFilter;

const StyledCommodityFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    margin-bottom: 32px;
`;

const StyledFilterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: fit-content;
    background-color: #f4f5f7;
    border-radius: 10px;
    padding: 10px 24px 24px 24px;
    margin-top: 20px;
    & > .itemRow {
        display: flex;
        gap: 16px;
        align-items: center;
        box-sizing: border-box;
        & > span {
            width: 63px;
            font-weight: 400;
            font-size: 13px;
            color: #222222;
            letter-spacing: -0.4px;
        }
        & > .checkBoxDiv {
            display: flex;
            gap: 12px;
            align-items: center;
        }
    }
`;

const StyledReasonWrapper = styled.div`
    width: 648px;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledSearchButton = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
`;
