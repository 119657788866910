import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { DropDown, Input, Title, TextBox, Button } from "@src/components";
import {
    ddlTemplateCategory,
    ddlTemplateStatus,
} from "@src/constants/TemplateStatus";
import { TemplateFormProps } from "@src/interfaces/Template.interface";
import { validChecker } from "@src/utils/validationCheck";
import { isEmpty, isNotEmpty } from "@src/utils/textUtil";
import { Common } from "@src/styles/Common";
import { useDuplicateTemplate } from "@src/hooks/queries/useTemplateDetail";

interface ITemplateForm {
    title: string;
    isUpdate: boolean;
    isDetail: boolean;
    handleUpdate: Function;
    initData: TemplateFormProps;
}

function TemplateForm({
    title,
    isUpdate,
    isDetail,
    handleUpdate,
    initData,
}: ITemplateForm) {
    const DUPLICATION_SUCCESS = "사용할 수 있는 템플릿 코드입니다.";
    const pushCategory = ddlTemplateCategory;
    const pushStatus = ddlTemplateStatus;
    const [titleErrorMessage, setTitleErrorMessage] = useState("");
    const [linkErrorMessage, setLinkErrorMessage] = useState("");
    const [templateCodeErrorMessage, setTemplateCodeErrorMessage] =
        useState("");
    const [templateCodeSuccessMessage, setTemplateCodeSuccessMessage] =
        useState("");
    const [bodyErrorMessage, setBodyErrorMessage] = useState("");
    const [updateData, setUpdateData] = useState<TemplateFormProps>(initData);
    const updateFormData = (name: string, value: string | number) => {
        setUpdateData({
            ...updateData,
            [name]: value,
        });
    };

    const editChecker = () => {
        const emptyChecker =
            isNotEmpty(updateData?.templateCode || "") &&
            isNotEmpty(updateData?.templateTitle || "") &&
            isNotEmpty(updateData?.templateBody || "") &&
            isNotEmpty(updateData?.templateLink || "");

        const errorChecker =
            isEmpty(bodyErrorMessage) &&
            isEmpty(titleErrorMessage) &&
            isEmpty(linkErrorMessage) &&
            isUpdate
                ? true
                : templateCodeSuccessMessage === DUPLICATION_SUCCESS;

        return emptyChecker && errorChecker;
    };
    useEffect(() => {
        const isEdit = editChecker();
        handleUpdate({ ...updateData, isEdit });
    }, [updateData, templateCodeSuccessMessage, templateCodeErrorMessage]);

    const [templatePlaceHolder, setTemplatePlaceHolder] = useState(
        "영문(TSVC), 숫자만 사용하여 입력해주세요",
    );
    const { mutate: duplicateMutate } = useDuplicateTemplate();
    const handleDuplicate = () => {
        const templateCode = updateData?.templateCode || "";
        if (templateCode) {
            duplicateMutate(templateCode, {
                onSuccess: () => {
                    setTemplateCodeErrorMessage("");
                    setTemplateCodeSuccessMessage(DUPLICATION_SUCCESS);
                },
                onError: (error: any) => {
                    setTemplateCodeErrorMessage(error || "");
                    setTemplateCodeSuccessMessage("");
                },
            });
        }
    };

    return (
        <div css={formDiv}>
            <Title title={title} />
            <ul>
                <li>
                    <Title title={"템플릿 유형"} size={"small"} />
                    <DropDown
                        data={pushCategory}
                        handleChange={(e) => {
                            const { value } = e.target;
                            const categoryCode = Number(value);
                            updateFormData("templateCategory", categoryCode);
                            let text = "";
                            switch (categoryCode) {
                                case 0: {
                                    text = "TSVC";
                                    break;
                                }
                                case 10: {
                                    text = "TSVO";
                                    break;
                                }
                                case 100: {
                                    text = "TRC0";
                                    break;
                                }
                                case 1000: {
                                    text = "TEVT";
                                    break;
                                }
                            }
                            setTemplatePlaceHolder(
                                `영문(${text}), 숫자만 사용하여 입력해주세요`,
                            );
                        }}
                        border={isDetail ? false : isUpdate ? false : true}
                        placeholder={""}
                        isDisabled={isDetail || isUpdate}
                        defaultValue={(
                            updateData?.templateCategory || ""
                        ).toString()}
                    />
                </li>

                <li>
                    <Title title={"템플릿 이름"} size={"small"} />
                    <div style={{ width: "320px" }}>
                        <Input
                            placeholder="템플릿 이름을 입력해주세요"
                            type="text"
                            size="dynamic"
                            handleChange={(e) => {
                                const text = e.target.value;
                                updateFormData("templateTitle", text);
                                const { result, errMsg } = validChecker(
                                    "spacebar",
                                    text,
                                );
                                if (text.length) {
                                    setTitleErrorMessage(result ? errMsg : "");
                                } else {
                                    setTitleErrorMessage("");
                                }
                            }}
                            errorMessage={titleErrorMessage}
                            isDisabled={isDetail}
                            value={updateData.templateTitle || ""}
                        />
                    </div>
                </li>
                <li>
                    <Title title={"템플릿 코드"} size={"small"} />
                    <div style={{ width: "320px" }}>
                        <Input
                            placeholder={templatePlaceHolder}
                            type="text"
                            size="dynamic"
                            handleChange={(e) => {
                                const text = e.target.value;
                                updateFormData("templateCode", text);
                                const { result, errMsg } = validChecker(
                                    "code",
                                    text,
                                );

                                setTemplateCodeErrorMessage(
                                    result ? errMsg : "",
                                );
                                setTemplateCodeSuccessMessage("");
                            }}
                            isDisabled={isDetail || isUpdate}
                            value={updateData.templateCode || ""}
                        />
                    </div>
                    {!isUpdate && (
                        <div
                            style={{
                                paddingLeft: "20px",
                                paddingBottom: "25px",
                            }}
                        >
                            <Button
                                label={"중복검사"}
                                color={"gd"}
                                size={"xSmall"}
                                isDisabled={false}
                                handleClick={handleDuplicate}
                                deleteBtn={false}
                            />
                        </div>
                    )}
                    {templateCodeErrorMessage && (
                        <p>{templateCodeErrorMessage}</p>
                    )}
                    {templateCodeSuccessMessage && (
                        <p style={{ color: Common.colors.success }}>
                            {templateCodeSuccessMessage}
                        </p>
                    )}
                </li>
                <li>
                    <Title title={"템플릿 내용"} size={"small"} />
                    <TextBox
                        label={""}
                        placeholder={"템플릿 내용을 입력해주세요 (100자 이내)"}
                        size={"big"}
                        maxLength={100}
                        handleChange={(value, isError) => {
                            updateFormData("templateBody", value);
                            setBodyErrorMessage(isError ? "textbox error" : "");
                        }}
                        isDisabled={isDetail}
                        defaultText={updateData.templateBody || ""}
                    />
                </li>
                <li>
                    <Title title={"링크"} size={"small"} />
                    <div style={{ width: "320px" }}>
                        <Input
                            placeholder="msgaapp://url 링크"
                            type="text"
                            size="dynamic"
                            handleChange={(e) => {
                                const text = e.target.value;
                                updateFormData("templateLink", text);
                                const { result, errMsg } = validChecker(
                                    "spacebar",
                                    text,
                                );

                                if (text.length) {
                                    setLinkErrorMessage(result ? errMsg : "");
                                } else {
                                    setLinkErrorMessage("");
                                }
                            }}
                            errorMessage={linkErrorMessage}
                            isDisabled={isDetail}
                            value={updateData.templateLink || ""}
                        />
                    </div>
                </li>
                <li>
                    <Title title={"상태"} size={"small"} />
                    <DropDown
                        data={pushStatus}
                        handleChange={() => {}}
                        border={false}
                        placeholder={""}
                        isDisabled={true}
                        defaultValue={updateData.templateStatus}
                    />
                </li>
            </ul>
        </div>
    );
}

const formDiv = css`
    padding: 40px;
    li {
        display: flex;
        align-items: center;

        & > div:first-of-type {
            width: 100px;
            font-weight: 500;
            line-height: 46px;
        }
    }
    & > ul > li {
        padding-top: 30px;
    }
    input:disabled {
        background: transparent;
    }
    textarea {
        width: 100%;
    }

    & > ul:first-of-type {
        select:disabled {
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }
    p {
        ${Common.textStyle.body4_R12}
        text-align: left;
        padding-left: 12px;
        color: ${Common.colors.error};
        margin-bottom: 0;
    }
`;

export default TemplateForm;
