import { css } from "@emotion/react";
import { Button, Title } from "@src/components";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

interface Props {}

const CampusHome: React.FC<Props> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <div css={rootStyle}>
            <Title
                title={
                    pathname.includes("campus/management/list")
                        ? "캠퍼스 강의 관리"
                        : pathname.includes("campus/management/create")
                        ? "캠퍼스 강의 등록"
                        : ""
                }
            />
            {pathname.includes("campus/management/lectures") && (
                <div css={btnStyle}>
                    <Button
                        color="purple"
                        handleClick={(e) =>
                            navigate("/campus/management/create")
                        }
                        label="강의 등록"
                        size="medium"
                    />
                </div>
            )}
            <Outlet />
        </div>
    );
};

export default CampusHome;

const rootStyle = css`
    padding: 40px;
    width: calc(100vw - 235px);
`;

const btnStyle = css`
    display: flex;
    justify-content: flex-end;
    padding: 40px 0;
`;
