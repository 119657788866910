import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { Title, Table, IconButton } from "@src/components";
import { useDispatch } from "react-redux";
import { ConvertPrice } from "@src/utils/units";
import { ConvertDate } from "@src/utils/dateUtil";
import ProductFilter from "./productFilter";
import { createProductViewCategory } from "@src/reducers/ProductViewCategory";
import { ProductFilterType } from "@src/types/product";
import { ProductFilterCondition } from "./ProductFilterCondition";
import { useProductList } from "@src/hooks/queries/useProduct";
import { BaseProductListType } from "@src/pages/product/types";

function ProductList() {
    const { isLoading, data: ProductList, isError } = useProductList();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const defaultViewCategory = ["STANDARD_INFORMATION", "COMMENT"];
    const [data, setData] = useState<any>(ProductList);
    useEffect(() => {
        if (!isLoading && !isError && ProductList) {
            setData(ProductList);
        }
    }, [isLoading, ProductList, isError]);
    const handleClick = (idx: string, bidType: number) => {
        dispatch(createProductViewCategory(defaultViewCategory));

        if (bidType) {
            navigate(`/products/public/${idx}`);
        } else {
            navigate(`/products/auction/${idx}`);
        }
    };
    const FullAddress = (address: {
        addr: string;
        city: string;
        dst: string;
        prov: string;
        strt: string;
        zip: string;
    }) => {
        const { addr, city, dst, prov, strt, zip } = address;
        return `${prov} ${city} ${dst} ${strt} ${addr} ${zip}`;
    };
    const ConvertType = (bidType: number) => {
        return bidType ? "공매" : "경매";
    };
    const columns = [
        {
            Header: "타입",
            accessor: "bidType",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "50px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {ConvertType(value)}
                </div>
            ),
        },
        {
            Header: "매물 번호",
            accessor: "itemId",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{ width: "100px", cursor: "pointer" }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "매물 용도",
            accessor: "gubun",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "80px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "이미지",
            accessor: "imgUrl",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div style={{ width: "80px", cursor: "pointer" }}>
                    <img
                        src={value}
                        width={72}
                        height={72}
                        onClick={() =>
                            handleClick(values.keyId, values.bidType)
                        }
                    />
                </div>
            ),
        },
        {
            Header: "소재지",
            accessor: "address",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "200px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {FullAddress(value)}
                </div>
            ),
        },
        {
            Header: "매각 기일",
            accessor: "bidDate",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "112px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {ConvertDate(value)}
                </div>
            ),
        },
        {
            Header: "보증금(원)",
            accessor: "deposit",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "112px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "최저가(원)",
            accessor: "bpLow",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "112px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {ConvertPrice(value)}
                </div>
            ),
        },
        {
            Header: "노출 여부",
            accessor: "view",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{ width: "80px", cursor: "pointer" }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {value ? "O" : "X"}
                </div>
            ),
        },
        {
            Header: "관심등록",
            accessor: "interestCnt",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{ width: "80px", cursor: "pointer" }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "조회수",
            accessor: "favorate",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{ width: "80px", cursor: "pointer" }}
                    onClick={() => handleClick(values.keyId, values.bidType)}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "더보기",
            accessor: "keyId",
            Cell: ({ cell: { value }, row: { values } }: any) => (
                <div
                    style={{
                        width: "80px",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                    }}
                >
                    <IconButton
                        type="arrow"
                        handleClick={() =>
                            handleClick(values.keyId, values.bidType)
                        }
                    />
                </div>
            ),
        },
    ];

    const filterData = (
        data: BaseProductListType[],
        params: ProductFilterType,
    ) => {
        const filterData = data.filter((v) => {
            const g = new ProductFilterCondition(v, params);
            return (
                g.conditionType() &&
                g.conditionNo() &&
                g.conditionStatus() &&
                g.conditionBidRangeDate() &&
                g.conditionExpectPrice() &&
                g.conditionMinBidPrice() &&
                g.conditionUsedBuilding() &&
                g.conditionDisposeType()
            );
        });

        return filterData;
    };

    const handleSubmit = (params: ProductFilterType) => {
        const result = filterData(ProductList || [], params);
        setData(result);
    };

    return (
        <div css={listStyle}>
            <Title title="매물 관리" />
            <ProductFilter handleSubmit={handleSubmit} />
            <div css={tableDiv}>
                <Table
                    initialState={{ pageIndex: 0, pageSize: 10 }}
                    columns={columns}
                    data={data}
                    FixedHeight={900}
                    showTotalCount={true}
                ></Table>
            </div>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        button:nth-of-type(1) {
            margin-right: 15px;
        }
    }
`;
export default ProductList;
