import styled from "@emotion/styled";
import { EditModal, ViewMoreTable } from "@src/components";
import { useGetChargeAdTicketList } from "@src/hooks/queries/useTicket";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserAdTicketModalColumns } from "./UserAdTicketModalColumns";

interface UserAdTicketModalProps {
    totalCount?: number;
    closeModal: () => void;
    isModalShow: boolean;
    setIsShowDeleteAdTicketModal: (isShow: boolean) => void;
    setSeletedDeleteAdTicketNo: (ticketNo: number) => void;
    dataList?: any;
    setIsShowAdTicketRefundModal: (isShow: boolean) => void;
    setSeletedAdTickNo: (no: number) => void;
    setIsShowAdTicketEditModal: (isShow: boolean) => void;
}

const UserAdTicketModal = ({
    totalCount,
    closeModal,
    isModalShow,
    setIsShowDeleteAdTicketModal,
    setSeletedDeleteAdTicketNo,
    dataList,
    setIsShowAdTicketRefundModal,
    setSeletedAdTickNo,
    setIsShowAdTicketEditModal,
}: UserAdTicketModalProps) => {
    const columns = getUserAdTicketModalColumns(
        setIsShowDeleteAdTicketModal,
        setSeletedDeleteAdTicketNo,
        setIsShowAdTicketRefundModal,
        closeModal,
        setSeletedAdTickNo,
        setIsShowAdTicketEditModal,
    );

    return (
        <StyledModalWrap>
            <EditModal
                isModalShow={isModalShow}
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={closeModal}
                size="big"
                title="매물광고권 정보"
                deleteButton={true}
            >
                <StyledContents>
                    <div className="ticketCnt">
                        총 잔여<span>{totalCount}장</span>
                    </div>
                    <div className="subTitle">충전내역</div>

                    <ViewMoreTable
                        FixedHeight={300}
                        columns={columns}
                        data={dataList ?? []}
                        handleViewMore={function noRefCheck() {}}
                        isRefetching={true}
                    />
                </StyledContents>
            </EditModal>
        </StyledModalWrap>
    );
};

export default UserAdTicketModal;

const StyledModalWrap = styled.div`
    p {
        margin-bottom: 8px;
    }
    & > div {
        & > .modal {
            width: 1300px;
        }
    }
`;

const StyledContents = styled.div`
    & > .ticketCnt {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        & > span {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #9a49f2;
        }
        margin-bottom: 24px;
    }
    & > .subTitle {
        font-weight: 700;
        font-size: 14px;
        color: #222222;
        margin-bottom: 9px;
    }
    & > div {
        & > button {
            display: none;
        }
    }
`;
