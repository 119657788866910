import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

function Label({ text, color }: any) {
    return <div css={[labelDiv, colors[color]]}>{text}</div>;
}

const labelDiv = css`
    ${Common.textStyle.text1_R13}
    min-width: 80px;
    width: 100%;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 60px;
    white-space: nowrap;
`;
const colors: { [index: string]: any } = {
    purple: css`
        background-color: ${Common.colors.purple500};
        color: ${Common.colors.white};
    `,
    orange: css`
        background-color: ${Common.colors.orange};
        color: ${Common.colors.white};
    `,
    lightGray: css`
        background-color: ${Common.colors.gray300};
        color: ${Common.colors.black};
    `,
    gray: css`
        background-color: ${Common.colors.gray500};
        color: ${Common.colors.white};
    `,
    black: css`
        background-color: ${Common.colors.black};
        color: ${Common.colors.white};
    `,
    pink: css`
        background-color: #fdb0ff;
        color: ${Common.colors.white};
    `,
    purpleBorder: css`
        background-color: ${Common.colors.white};
        color: ${Common.colors.purple500};
        border: 1px solid ${Common.colors.purple500};
    `,
    purple200: css`
        background-color: ${Common.colors.purple200};
        color: ${Common.colors.white};
    `,
    purple300: css`
        background-color: ${Common.colors.purple300};
        color: ${Common.colors.white};
    `,
    purple400: css`
        background-color: ${Common.colors.purple400};
        color: ${Common.colors.white};
    `,
    purple000: css`
        background-color: #e3caff;
        color: ${Common.colors.white};
    `,
    yellow: css`
        background-color: #fac710;
        color: ${Common.colors.white};
    `,
    red: css`
        background-color: #ff0000;
        color: ${Common.colors.white};
    `,
    orangeBorder: css`
        background-color: ${Common.colors.white};
        border: 1px solid ${Common.colors.orange};
        color: ${Common.colors.orange};
    `,
    redBorder: css`
        background-color: ${Common.colors.white};
        color: ${Common.colors.error};
        border: 1px solid ${Common.colors.error};
    `,
    purple1: css`
        background-color: ${Common.colors.purple1};
        color: ${Common.colors.white};
    `,
    refund: css`
        background-color: #ff6287;
        color: ${Common.colors.white};
    `,
    allRefund: css`
        background-color: #ff003d;
        color: ${Common.colors.white};
    `,
    expired: css`
        background-color: #535353;
        color: ${Common.colors.white};
    `,
    complete: css`
        background-color: #f5eb00;
        color: ${Common.colors.white};
    `,
    request: css`
        background-color: #ff752f;
        color: ${Common.colors.white};
    `,
    reject: css`
        background-color: #ff0000;
        color: ${Common.colors.white};
    `,
    cancel: css`
        background-color: #ff7d7d;
        color: ${Common.colors.white};
    `,
    blue: css`
        background-color: #3776ed;
        color: white;
    `,
    lightBlue: css`
        background-color: #03a9f4;
        color: white;
    `,
};
export default Label;
