import { css } from "@emotion/react";
import { Calendar, ConfirmModal, EditModal, Title } from "@src/components";
import {
    useCampusExpireUpdate,
    useUserCampusDetail,
} from "@src/hooks/queries/useUserCampus";
import { ConvertDateTime } from "@src/utils/dateUtil";
import React, { useEffect, useState } from "react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    info: any;
}

const UserSugangDetailModal: React.FC<Props> = ({
    open,
    onCloseView,
    info,
}) => {
    const [param, setParam] = useState<any>({
        no: "",
        expiredAt: "",
    });
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);

    const { data, refetch } = useUserCampusDetail(info?.registerNo);
    const expireUpdate = useCampusExpireUpdate(() => {
        setIsShowConfirmModal(false);
        onCloseView();
    });

    const onClickUpdate = () => {
        expireUpdate.mutate(param);
    };

    useEffect(() => {
        if (open && info?.registerNo) {
            setParam({
                ...param,
                expiredAt: info?.expiredAt,
                no: info?.registerNo,
            });
            refetch();
        }
    }, [open, info]);
    return (
        <>
            <EditModal
                isModalShow={open}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowConfirmModal(true);
                }}
                handleOpenModal={onCloseView}
                size="big"
                title="수강강의 정보 수정"
                checkCloseModal={true}
                styles={{ backgroundColor: "#ECEFF1 !important" }}
            >
                <div css={{ height: "380px" }}>
                    <Title title="수강 정보" />
                    <div css={tableRootStyle}>
                        <table>
                            <colgroup>
                                <col width="30%" />
                                <col width="35%" />
                                <col width="35%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>수강일시</th>
                                    <th>강의명</th>
                                    <th>수강기한</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {data?.createdAt
                                            ? ConvertDateTime(
                                                  new Date(data?.createdAt),
                                              )
                                            : "-"}
                                    </td>
                                    <td>{data?.title ?? "-"}</td>
                                    <td>
                                        <Calendar
                                            setDate={new Date(
                                                data?.expiredAt,
                                            ).getTime()}
                                            minDate={Date.now()}
                                            getUserDate={(date) =>
                                                setParam({
                                                    ...param,
                                                    expiredAt: new Date(
                                                        date,
                                                    ).toISOString(),
                                                })
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => setIsShowConfirmModal(false)}
                handleOk={onClickUpdate}
            >
                저장하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default UserSugangDetailModal;

const tableRootStyle = css`
    width: 100%;
    table {
        width: 88%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 10px;
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
        }
    }
`;
