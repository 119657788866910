import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { addDay, dateFormat } from "@src/utils/dateUtil";
import { ko } from "date-fns/locale";

export type DateRangeCalendarType = {
    startDate: Date;
    endDate: Date;
    key: "selection";
};
interface IDateRangeCalendar {
    setDate?: DateRangeCalendarType;
    getUserDate: (range: DateRangeCalendarType) => void;
}

const DateRangeCalendar = ({
    setDate = {
        startDate: new Date(),
        endDate: addDay(new Date(), 1),
        key: "selection",
    },
    getUserDate,
}: IDateRangeCalendar) => {
    const [isOpen, setIsOpen] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [range, selectionRange] = useState([setDate]);

    useEffect(() => {
        selectionRange([setDate]);
        getUserDate(setDate);
    }, []);

    useEffect(() => {
        if (
            dateFormat(setDate.startDate) !== dateFormat(range[0].startDate) ||
            dateFormat(setDate.endDate) !== dateFormat(range[0].endDate)
        ) {
            selectionRange([setDate]);
            getUserDate(setDate);
        }
    }, [setDate]);

    const handleSelect = (ranges: any) => {
        if (clickCount === 1) {
            setClickCount(0);
            setTimeout(() => {
                setIsOpen(false);
                getUserDate(ranges.selection);
            }, 500);
        } else {
            setClickCount(clickCount + 1);
        }
        selectionRange([ranges.selection]);
    };

    const displayDate = `${dateFormat(range[0].startDate)} ~ ${dateFormat(
        range[0].endDate,
    )}`;

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div>
                <input
                    css={RangeInputStyle}
                    type="button"
                    value={displayDate}
                    onClick={onToggle}
                />
            </div>
            <div>
                {isOpen && (
                    <DateRangePicker
                        onChange={handleSelect}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        months={2}
                        direction="horizontal"
                        staticRanges={[]}
                        inputRanges={[]}
                        css={RangeCalendarStyle}
                        locale={ko}
                    />
                )}
            </div>
        </>
    );
};

const RangeInputStyle = css`
    width: 200px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #d4d4d8;
    border-color: ${Common.colors.gray300};
    background-color: ${Common.colors.white};
    cursor: pointer;
`;

const RangeCalendarStyle = css`
    .rdrDateDisplayWrapper {
        display: none;
    }
    .rdrYearPicker {
        display: none;
    }
    .rdrMonthPicker {
        display: none;
    }
    .rdrDefinedRangesWrapper {
        display: none;
    }
    .rdrCalendarWrapper {
        position: absolute;
        z-index: 9999;
        border-radius: 4px;
        border: 1px solid #d4d4d8;
        border-color: ${Common.colors.gray300};
    }
`;

export default DateRangeCalendar;
