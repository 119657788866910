import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface ViewMoreTableProps {
    columns: column[];
    handleViewMore: () => void;
    data: any[];
    FixedHeight?: number;
    isRefetching?: boolean;
}
interface column {
    Header: string;
    accessor: string;
    Cell?: any;
}
const ViewMoreTable = ({
    columns,
    handleViewMore,
    data,
    FixedHeight,
    isRefetching = false,
}: ViewMoreTableProps) => {
    const [dataList, setDataList] = useState<any[]>(data);
    useEffect(() => {
        if (isRefetching) {
            setDataList(data);
        } else {
            setDataList([...dataList, ...data]);
        }
    }, [data]);
    return (
        <div
            css={ViewMoreTableWrap}
            style={{ maxHeight: FixedHeight ? `${FixedHeight}px` : "auto" }}
        >
            <div style={{ height: "100%" }}>
                <table css={tableStyle}>
                    <thead css={theadStyle}>
                        <tr>
                            {columns.length !== 0 &&
                                columns.map((col: column, index: number) => {
                                    return (
                                        <th css={thStyle} key={index}>
                                            {col.Header}
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    <tbody>
                        {dataList.length !== 0 ? (
                            dataList.map((d: any, index: number) => {
                                return (
                                    <tr css={trStyle} key={index}>
                                        {columns.length !== 0 &&
                                            columns.map(
                                                (
                                                    col: column,
                                                    index: number,
                                                ) => {
                                                    return (
                                                        <td
                                                            css={tdStyle}
                                                            key={index}
                                                        >
                                                            {col.Cell({
                                                                cell: {
                                                                    value: d[
                                                                        col
                                                                            .accessor
                                                                    ],
                                                                },
                                                                row: {
                                                                    values: {
                                                                        ...d,
                                                                    },
                                                                },
                                                            })}
                                                        </td>
                                                    );
                                                },
                                            )}
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td
                                    colSpan={columns.length}
                                    css={tdStyle}
                                    style={{
                                        textAlign: "center",
                                        height: FixedHeight
                                            ? `${FixedHeight}px`
                                            : "300px",
                                        backgroundColor: Common.colors.gray100,
                                        opacity: "0.6",
                                    }}
                                >
                                    데이터 없음
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {data?.length !== 0 && (
                <button
                    css={viewMoreButton}
                    className="viewMoreButton"
                    onClick={() => {
                        handleViewMore();
                    }}
                >
                    {isRefetching ? (
                        <div css={loadingBarDiv}>
                            <div></div>
                        </div>
                    ) : (
                        "더보기"
                    )}
                </button>
            )}
        </div>
    );
};
const ViewMoreTableWrap = css`
    height: 100%;
    overflow: auto;
    table {
        max-height: 300px;
        overflow: scroll;
    }
`;
const viewMoreButton = css`
    display: block;
    width: 100%;
    height: 40px;
    background-color: ${Common.colors.gray100};
`;
const tableStyle = css`
    width: 100%;
    border: 1px solid ${Common.colors.gray100};
`;
const theadStyle = css`
    border-top: 1px solid ${Common.colors.gray100};
    border-bottom: 1px solid ${Common.colors.gray100};
    height: 40px;
    width: 100%;
    text-align: center;
    position: sticky;
    left: 0;
    top: -1px;
    z-index: 2;
    background-color: ${Common.colors.white};
`;

const thStyle = css`
    border: 1px solid ${Common.colors.gray100};
    ${Common.textStyle.text1_R13}
    vertical-align: middle;
    display: table-cell;
`;

const trStyle = css`
    height: 40px;
    border-bottom: 1px solid ${Common.colors.gray100};
    &:hover {
        background-color: ${Common.colors.gray100};
    }
`;

const tdStyle = css`
    ${Common.textStyle.text1_R13}
    vertical-align: middle;
    border: 1px solid ${Common.colors.gray100};
    display: table-cell;
    & > div {
        margin: 0 auto;
        white-space: wrap;
        word-break: keep-all;
    }
`;
const loadingBarDiv = css`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    div {
        width: 20px;
        height: 20px;
        border: 4px solid ${Common.colors.white};
        border-top-color: ${Common.colors.purple500};
        border-radius: 50%;
        animation: spin 1s infinite;
        transform-origin: center;
        transform: rotate(0deg);
        transition: rotate 1s;
    }
    @keyframes spin {
        from {
            transform-origin: center;
            transform: rotate(0deg);
        }
        to {
            transform-origin: center;
            transform: rotate(360deg);
        }
    }
    p {
        ${Common.textStyle.title2_M16}
        color: ${Common.colors.white};
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: 65px;
        transform: translate(-50%, -50%);
    }
`;
export default ViewMoreTable;
