import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Constants } from "@src/constants/Constants";
import { Button, ConfirmModal } from "@src/components";
import {
    BaseSituation,
    SituationFormProps,
    UpdateSituationDto,
} from "@src/interfaces/Situation.interface";
import {
    useSituationDetail,
    useUpdateSituation,
} from "@src/hooks/queries/useSituationDetail";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "@src/reducers/loading";
import { useNavigate, useParams } from "react-router-dom";
import SituationForm from "./SituationForm";
import { useSituationList } from "@src/hooks/queries/useSituation";

interface ISituationUpdate {
    isDetail: boolean;
}

function SituationUpdate({ isDetail = false }: ISituationUpdate) {
    const { id = "0" } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalShow, setIsModalShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<string>("");
    const [data, setData] = useState<SituationFormProps>();
    const {
        isLoading,
        data: initData = null,
        isError,
        refetch: singleRefetch,
    } = useSituationDetail(id, true);
    const [updateData, setUpdateData] = useState<SituationFormProps>({
        situationStatus: 200,
        isFormError: false,
    });
    useEffect(() => {
        if (!isLoading && !isError && initData) {
            setupData(initData);
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());
        }
    }, [isLoading, isError]);

    useEffect(() => {
        setDisabled(!updateData.isFormError);
    }, [updateData]);

    const setupData = (data: BaseSituation) => {
        const initData: SituationFormProps = {
            situationCode: data.situation_code,
            situationComments: data.comments,
            situationReservationTime: data.reservation_time,
            situationDelaySec: data?.delay_sec || "",
            situationStatus: Number(data.status),
            templateCode: data.template_code,
            isFormError: false,
        };
        setData(initData);
    };

    const matchInterface: { [key: string]: string } = {
        templateCode: "template_code",
        situationComments: "comments",
        situationReservationTime: "reservation_time",
        situationDelaySec: "delay_sec",
        situationStatus: "status",
    };

    const diffChecker = (updateData: SituationFormProps) => {
        let obj: UpdateSituationDto = {};
        if (data) {
            for (let value of Object.keys(updateData)) {
                let v = value as keyof SituationFormProps;
                if (data[v] !== updateData[v]) {
                    let propertyName = matchInterface[v];
                    if (propertyName) {
                        obj = { ...obj, [propertyName]: updateData[v] };
                    }
                }
            }
        }

        return obj;
    };

    const { refetch: listRefetch } = useSituationList({ limit: 10, page: 1 });
    const { mutate: updateMutate } = useUpdateSituation(id);
    const handleSubmit = () => {
        const params = diffChecker(updateData);
        updateMutate(params, {
            onSuccess: () => {
                navigate("/notify/Situation");
            },
            onError: (error: any) => {
                setPageErrorMessage(error);
            },
            onSettled: () => {
                listRefetch();
                singleRefetch();
            },
        });
    };

    return (
        <>
            {data ? (
                <div css={createDiv}>
                    <SituationForm
                        title={`${Constants.SITUATION} 수정`}
                        handleUpdate={setUpdateData}
                        isUpdate={true}
                        initData={data}
                        isDetail={isDetail}
                    />
                    {!isDetail && (
                        <div className="buttonBox">
                            <p>{pageErrorMessage}</p>
                            <Button
                                label={"수정"}
                                color={"gd"}
                                size="medium"
                                isDisabled={disabled}
                                handleClick={() => {
                                    setIsModalShow(true);
                                }}
                                deleteBtn={false}
                                defaultValue={""}
                            />
                        </div>
                    )}
                    <ConfirmModal
                        isModalShow={isModalShow}
                        handleOk={handleSubmit}
                        handleCancel={() => {
                            setIsModalShow((prev) => !prev);
                        }}
                        buttonText="수정"
                    >
                        수정하시겠습니까?
                    </ConfirmModal>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
}

const createDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .buttonBox {
        width: 100%;
        padding-top: 28px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;
        p {
            color: ${Common.colors.error};
        }
    }
`;
export default SituationUpdate;
