import React from "react";
import { IconButton, Label } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { UserStatus } from "@src/constants/UserStatus";

export const getColumns = () => {
    return [
        {
            Header: "이름",
            accessor: "userName",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "연락처",
            accessor: "userPhoneNumber",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "120px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "이메일",
            accessor: "userEmail",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "180px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "생년월일",
            accessor: "userBirthday",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100px", textAlign: "center" }}>
                    {ConvertDate(value)}
                </div>
            ),
        },
        {
            Header: "성별",
            accessor: "userGender",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "가입 일시",
            accessor: "userApplyDate",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100px" }}>{ConvertDate(value)}</div>
            ),
        },
        {
            Header: "상태",
            accessor: "userStatus",
            Cell: ({ cell: { value } }: any) => {
                const [text, color] = UserStatus[value];
                return (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Label color={color} text={text} />
                    </div>
                );
            },
        },
    ];
};
