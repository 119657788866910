import styled from "@emotion/styled";
import { EditModal, Title, ViewMoreTable } from "@src/components";
import React from "react";
import { getUserAdTicketEditModalColumns } from "./UserAdTicketEditModalColumns";

interface UserAdTicketEditModalProps {
    setAdTicketEditTime: (time: string) => void;
    setIsShowAdTicketEditModal: (isShow: boolean) => void;
    isShowAdTicketEditModal: boolean;
    AdTicketDetailData: any;
    setIsShowAdTicketEditConfirmModal: (isShow: boolean) => void;
}

const UserAdTicketEditModal = ({
    setAdTicketEditTime,
    setIsShowAdTicketEditModal,
    isShowAdTicketEditModal,
    AdTicketDetailData,
    setIsShowAdTicketEditConfirmModal,
}: UserAdTicketEditModalProps) => {
    const getColumns = getUserAdTicketEditModalColumns(setAdTicketEditTime);

    return (
        <>
            <EditModal
                isModalShow={isShowAdTicketEditModal}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowAdTicketEditModal(false);
                    setIsShowAdTicketEditConfirmModal(true);
                }}
                handleOpenModal={() => {
                    setIsShowAdTicketEditModal(false);
                }}
                size="big"
                title="등기열람권 정보"
            >
                <>
                    <StyledEditContents>
                        <Title title="충전내역" />
                        {AdTicketDetailData && (
                            <ViewMoreTable
                                FixedHeight={100}
                                columns={getColumns}
                                data={[AdTicketDetailData]}
                                handleViewMore={function noRefCheck() {}}
                                isRefetching={true}
                            />
                        )}
                    </StyledEditContents>
                </>
            </EditModal>
        </>
    );
};

export default UserAdTicketEditModal;

const StyledEditContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
        & > button {
            display: none;
        }
    }
`;
