import { Button, IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { useCancelDeungi } from "@src/hooks/queries/useUserDetail";
import { DeungiDetailList } from "@src/interfaces/User.interface";
import React, { Dispatch, SetStateAction } from "react";

// interface getUserProductListColumnsProps {
//     setIsDeungiEditModalShow: ;
// }

export const getUserAdTicketModalColumns = (
    setIsShowDeleteAdTicketModal: (isShow: boolean) => void,
    setSeletedDeleteAdTicketNo: (ticketNo: number) => void,
    setIsShowAdTicketRefundModal: (isShow: boolean) => void,
    closeModal: () => void,
    setSeletedAdTickNo: (no: number) => void,
    setIsShowAdTicketEditModal: (isShow: boolean) => void,
    // setIsProductModalShow: Dispatch<SetStateAction<boolean>>,
    // setDeungiTicket: Dispatch<SetStateAction<DeungiDetailList>>,
    // setIsDeungiRefundModalShow: Dispatch<SetStateAction<boolean>>,
    // setDeungiRefundPrice: Dispatch<SetStateAction<string>>,
    // setReason: Dispatch<SetStateAction<string>>,
) => [
    {
        Header: "광고권 번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "50px",

                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "충전일시",
        accessor: "startedAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "80px",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "충전정보",
        accessor: "productName",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "156px",
                    textAlign: "center",
                }}
            >
                {value ? value + " 충전" : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "충전 수량",
        accessor: "serveQuantity",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "50px",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "사용 수량",
        accessor: "usedQuantity",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "50px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "결제액",
        accessor: "paidPrice",
        Cell: ({ cell: { value }, row }: any) => {
            const userNo = row.values.userNo;
            return (
                <div
                    style={{
                        width: "70px",
                        textAlign: "center",
                    }}
                >
                    {value.toLocaleString()}
                </div>
            );
        },
    },
    {
        Header: "결제번호",
        accessor: "paidNo",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "80px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "결제수단",
        accessor: "paidCardName",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "50px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "환불 수량",
        accessor: "refundQuantity",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "70px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {row.values.status !== 109 ? value : 0}
                </div>
            );
        },
    },
    {
        Header: "환불액",
        accessor: "refundPrice",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "70px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value.toLocaleString()}
                </div>
            );
        },
    },
    {
        Header: "취소 수량",
        accessor: "refundQuantity",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "70px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {row.values.status === 109 ? value : 0}
                </div>
            );
        },
    },
    {
        Header: "유효기간",
        accessor: "expiredAt",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "80px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "상태",
        accessor: "state",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "85px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value === "정상" && (
                        <Label text={value} color={"purple1"}></Label>
                    )}
                    {value === "부분환불" && (
                        <Label text={value} color={"refund"}></Label>
                    )}
                    {value === "전체환불" && (
                        <Label text={value} color={"allRefund"}></Label>
                    )}
                    {value === "기간만료" && (
                        <Label text={value} color={"expired"}></Label>
                    )}
                    {value === "관리자취소" && (
                        <Label text={"삭제"} color={"request"}></Label>
                    )}
                </div>
            );
        },
    },
    {
        Header: "환불",
        accessor: "status",
        Cell: ({ cell: { value }, row }: any) => {
            const paidCardName = row.values.paidCardName;
            const disable = row.values.totalPrice === row.values.paidPrice;
            const paidNo = row.values.paidNo;
            const status = row.values.status;
            const no = row.values.no;

            return (
                <div
                    style={{
                        width: "65px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {disable &&
                    paidNo > 0 &&
                    paidCardName !== "App Store" &&
                    paidCardName !== "Google Play" &&
                    status === 200 ? (
                        <Button
                            label="환불하기"
                            color="purple"
                            size="small"
                            handleClick={() => {
                                // setIsDeungiRefundModalShow(true);
                                // setIsProductModalShow(false);
                                // setDeungiTicket(row.values);
                                // setDeungiRefundPrice("");
                                // setReason("");
                                setIsShowAdTicketRefundModal(true);
                                setSeletedAdTickNo(no);
                                closeModal();
                            }}
                            styles={{
                                width: "100%",
                                height: "30px",
                                fontSize: "13px",
                                fontWeight: 400,
                                margin: 0,
                            }}
                        />
                    ) : (
                        <Button
                            label="환불하기"
                            color="purple"
                            size="small"
                            handleClick={() => {}}
                            styles={{
                                width: "100%",
                                height: "30px",
                                fontSize: "13px",
                                fontWeight: 400,
                                margin: 0,
                            }}
                            isDisabled={true}
                        />
                    )}
                </div>
            );
        },
    },
    {
        Header: "삭제",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            const status = row.values.status;

            return (
                <div
                    style={{
                        width: "65px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {status === 200 ? (
                        <Button
                            label="삭제하기"
                            color="purple"
                            size="small"
                            // handleClick={async () => {
                            //     if (
                            //         confirm(
                            //             "정말 해당 등기열람권을 취소하시겠습니까?",
                            //         )
                            //     ) {
                            //         await useCancelDeungi(row.values.no);
                            //         alert("취소 처리되었습니다.");
                            //         location.reload();
                            //     }
                            // }}
                            handleClick={() => {
                                setSeletedDeleteAdTicketNo(value);
                                setIsShowDeleteAdTicketModal(true);
                            }}
                            styles={{
                                width: "100%",
                                height: "30px",
                                fontSize: "13px",
                                fontWeight: 400,
                                margin: 0,
                            }}
                        />
                    ) : (
                        <Button
                            label="삭제하기"
                            color="purple"
                            size="small"
                            handleClick={() => {}}
                            styles={{
                                width: "100%",
                                height: "30px",
                                fontSize: "13px",
                                fontWeight: 400,
                                margin: 0,
                            }}
                            isDisabled={true}
                        />
                    )}
                </div>
            );
        },
    },
    {
        Header: "수정",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "65px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Button
                        label="수정"
                        color="purple"
                        size="small"
                        handleClick={() => {
                            // setIsDeungiEditModalShow(true);
                            // setIsProductModalShow(false);
                            // setDeungiTicket(row.values);
                            setIsShowAdTicketEditModal(true);
                            setSeletedAdTickNo(value);
                            closeModal();
                        }}
                        styles={{
                            width: "100%",
                            height: "30px",

                            fontWeight: 400,
                            margin: 0,
                        }}
                    />
                </div>
            );
        },
    },
];
