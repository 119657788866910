import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import "@src/styles/CommonTable.css";
import { Input, Title } from "@src/components";
import { documentHistoryProps } from "@src/interfaces/product/DocumentDeliveryHistoryContainer.interface";

interface IDocumentDeliveryHistoryContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
    initData: any;
}
function DocumentDeliveryHistoryContainer({
    isUpdate = false,
    handleUpdate,
    initData,
}: IDocumentDeliveryHistoryContainer) {
    const [tableData, setTableData] = useState<any>();
    const [delTableData, setDelTableData] = useState<any>();

    useEffect(() => {
        let doc = initData.doc;
        let del = initData.delivery;
        setTableData(doc);
        setDelTableData(del);
    }, [initData]);

    const headerMeta = {
        doc: ["접수일", "접수내역", "결과"],
        del: ["송달일", "송달내역", "송달결과"],
    };

    const handleChange = (updateData: documentHistoryProps, id: string) => {
        let newTableData = [...tableData];
        const index = newTableData.findIndex((v) => v.id === id);
        newTableData[index] = updateData;
        setTableData(newTableData);
    };

    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);
    return (
        <div style={{ minWidth: "1000px" }} css={docTableWrap}>
            <Title title="문건처리내역" size="small" />
            {tableData && (
                <div>
                    <table className="commonTable">
                        <thead>
                            <tr>
                                {headerMeta.doc.map((headerText, index) => (
                                    <th key={index}>{headerText}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((data: any, index: number) => {
                                return (
                                    <TableRow
                                        key={`${index}_${index + 1}`}
                                        data={data}
                                        isUpdate={isUpdate}
                                        handleChange={handleChange}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            <Title title="송달내역" size="small" />
            {delTableData && (
                <div>
                    <table className="commonTable">
                        <thead>
                            <tr>
                                {headerMeta.del.map((headerText, index) => (
                                    <th key={index}>{headerText}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {delTableData.map((data: any, index: number) => {
                                return (
                                    <TableRow
                                        key={`${index}_${index + 1}`}
                                        data={data}
                                        isUpdate={isUpdate}
                                        handleChange={handleChange}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}
const TableRow = ({ data, isEdit, handleChange }: any) => {
    const [rowData, setRowData] = useState<documentHistoryProps>(data);

    const { do_date: date, content: contents, result } = rowData;

    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newRowData = { ...rowData };
        let key: keyof documentHistoryProps;

        for (key in newRowData) {
            if (key === name) {
                newRowData[name] = value;
            }
        }
    };
    useEffect(() => {
        handleChange(rowData);
    }, [rowData]);
    return (
        <>
            <tr
                key={data.id + data.bidDate}
                style={isEdit ? { borderBottom: "none" } : undefined}
            >
                <td>
                    <Input
                        name="date"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={true}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={date}
                    />
                </td>
                <td>
                    <Input
                        name="contents"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={true}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={contents}
                    />
                </td>
                <td>
                    <Input
                        name="result"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={true}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={result}
                    />
                </td>
            </tr>
        </>
    );
};

const docTableWrap = css`
    & > div:nth-of-type(odd) {
        margin: 20px 0 20px;
    }
    & > div:nth-of-type(even) {
        max-height: 300px;
        overflow-y: scroll;
        position: relative;
        thead {
            position: sticky;
            left: 0;
            top: 0;
        }
    }
    & > div:nth-of-type(3) {
        margin-top: 40px;
    }
    tr td:nth-of-type(2) input {
        text-align: left;
    }
`;
export default DocumentDeliveryHistoryContainer;
