import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import ImageUploading, { ImageType } from "react-images-uploading";
import axios, { AxiosRequestConfig, Method } from "axios";
import { getImageURLToS3, uploadImageToS3 } from "@src/api/uploadImage";
import { noImage } from "@src/constants/Images";

interface IImageListView {
    allFiles: Array<ImageType>;
    isDisabled: boolean;
    handleChange: (image: any) => void;
    maxCount?: number; // 파일 갯수 제한
}

const ImageListView = ({
    allFiles = [],
    isDisabled = false,
    handleChange = (image: any) => {},
    maxCount = 10,
}: IImageListView) => {
    const maxFileSize = 5242880;
    const dataURLKey = "data_url";
    const [images, setImages] = useState<ImageType[]>(allFiles);
    const [thumbs, setThumbs] = useState<string[]>([]);

    useEffect(() => {
        setImages(allFiles);
        if (maxCount !== 1) {
            let newItem: any[] = [];
            allFiles.map((v) => newItem.push(v.data_url));
            setThumbs([...newItem]);
        }
    }, [allFiles]);

    const fileUpload = (files: any, index: number) => {
        const { file } = files[index];
        const makeAxiosRequestConfig = (
            method: Method,
            path: string,
            data?: { [key: string]: any },
            params?: any,
        ) => {
            let headers: AxiosRequestConfig["headers"] = {};
            let formData: FormData | undefined = undefined;

            if (data) {
                (headers["content-type"] = "image/png"),
                    (formData = new FormData());
                formData.append("file", file);
            }

            return {
                method,
                url: path,
                data: formData,
                params,
                headers,
            };
        };

        axios(makeAxiosRequestConfig("POST", "/upload", file))
            .then((response) => {
                if (response.data.success) {
                    alert("파일이 저장되었습니다.");
                } else {
                    alert("파일을 저장하는데 실패했습니다.");
                }
            })
            .catch(() => console.log("로컬 서버를 확인해주세요."));

        setImages(files);
    };

    const s3Upload = (files: any, index: number) => {
        const { file } = files[index];
        let type = file.type;
        type = type.replace("image/", "");

        getImageURLToS3(file).then((response) => {
            setThumbs([...thumbs, response.thumb]);
            uploadImageToS3(response).then((result) => {
                result && setImages(files);
            });
        });
    };

    const onChange = (
        imageList: Array<ImageType>,
        addUpdateIndex?: number[],
    ) => {
        if (addUpdateIndex) {
            s3Upload(imageList, addUpdateIndex[0]);
        }
    };
    useEffect(() => {
        handleChange(thumbs);
    }, [thumbs]);

    const onClick = (url: string) => {
        //이미지 클릭시 새 창 활성화
        let win = window.open("", "_black");
        let img = document.createElement("img");
        img.src = url;
        img.style.width = "80%";
        win?.document.body.appendChild(img);
    };
    const onErrorImg = (e: any) => {
        e.target.src = noImage;
    };

    const onChangeThumbs = (index: number) => {
        const copy = [...thumbs];
        copy.splice(index, 1);
        setThumbs([...copy]);
    };
    const onChangeImages = (index: number) => {
        const copy = [...images];
        copy.splice(index, 1);
        setImages([...copy]);
    };
    return (
        <div>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxFileSize}
                dataURLKey={dataURLKey}
            >
                {({ imageList, onImageUpload, dragProps }) => (
                    <div css={wrapperStyle}>
                        {imageList.length !== 0
                            ? imageList.map((image, index) => {
                                  let url = image["data_url"];
                                  return (
                                      <div
                                          key={index}
                                          style={{
                                              position: "relative",
                                              width: "96px",
                                              height: "74px",
                                              minWidth: "96px",
                                              border: "1px solid #ccc",
                                              overflow: "hidden",
                                          }}
                                      >
                                          <div
                                              className="dim"
                                              onClick={() => onClick(url)}
                                          >
                                              <img
                                                  src={image["data_url"]}
                                                  alt=""
                                                  style={{
                                                      width: "100%",
                                                      height: "74px",
                                                      display: "block",
                                                  }}
                                                  onError={onErrorImg}
                                              />
                                          </div>
                                          {!isDisabled && (
                                              <div
                                                  style={{
                                                      position: "absolute",
                                                      top: 0,
                                                      right: 0,
                                                      cursor: "pointer",
                                                      width: "25px",
                                                      height: "25px",
                                                      textAlign: "center",
                                                      background:
                                                          Common.colors.gray500,
                                                  }}
                                                  onClick={() => {
                                                      onChangeThumbs(index);
                                                      onChangeImages(index);
                                                  }}
                                              >
                                                  <span
                                                      style={{
                                                          fontSize: "20px",
                                                          lineHeight: "25px",
                                                          color: Common.colors
                                                              .white,
                                                          position: "absolute",
                                                          textAlign: "center",
                                                          width: "25px",
                                                          minWidth: "25px",
                                                          left: 0,
                                                      }}
                                                  >
                                                      X
                                                  </span>
                                              </div>
                                          )}
                                      </div>
                                  );
                              })
                            : isDisabled && (
                                  <img
                                      src={noImage}
                                      alt=""
                                      style={{
                                          margin: "0 auto",
                                          width: "96px",
                                          height: "106px",
                                          display: "block",
                                      }}
                                      onError={onErrorImg}
                                  />
                              )}
                        {!isDisabled && maxCount > images.length && (
                            <button
                                style={{
                                    width: "96px",
                                    height: "74px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: Common.colors.gray400,
                                    color: Common.colors.white,
                                    fontSize: "90px",
                                    borderRadius: 0,
                                    cursor: "pointer",
                                }}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                +
                            </button>
                        )}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: row;
    overflow: scroll;
    max-width: 1070px;
    .dim {
        width: 100%;
        height: 100%;
    }
`;

export default ImageListView;
