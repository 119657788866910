import { css } from "@emotion/react";
import { Calendar, EditModal, Input, RangeCalendar } from "@src/components";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from "react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    eventInfo: IEvent;
    setEventInfo: Dispatch<SetStateAction<IEvent>>;
}

interface IEvent {
    eventTitle: string;
    eventStartAt: string;
    eventExpiredAt: string;
}

const CampusCountDownModal: React.FC<Props> = ({
    open,
    onCloseView,
    eventInfo,
    setEventInfo,
}) => {
    const [title, setTitle] = useState<string>("");
    const [startDate, setStartDate] = useState<any>(Date.now());
    const [endDate, setEndDate] = useState<any>(Date.now());

    const onChangeTitle = useCallback((evt: any) => {
        setTitle(evt.target.value);
    }, []);

    useEffect(() => {
        if (eventInfo.eventTitle && open) {
            setTitle(eventInfo.eventTitle);
            setStartDate(new Date(eventInfo.eventStartAt).getTime());
            setEndDate(new Date(eventInfo.eventExpiredAt).getTime());
        }
    }, [open]);

    return (
        <>
            <EditModal
                buttonText="저장"
                handleBtnClick={() => {
                    setEventInfo({
                        eventTitle: title,
                        eventStartAt: new Date(startDate).toISOString(),
                        eventExpiredAt: new Date(endDate).toISOString(),
                    });
                }}
                handleOpenModal={onCloseView}
                size="big"
                title="카운트다운 관리"
                isModalShow={open}
                isDisabled={
                    !(
                        title &&
                        startDate &&
                        endDate &&
                        new Date(startDate) < new Date(endDate)
                    )
                }
            >
                <div css={tableRootStyle}>
                    <table>
                        <colgroup>
                            <col width="40%" />
                            <col width="30%" />
                            <col width="30%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <span>*</span>문구
                                </th>
                                <th>
                                    <span>*</span>시작일시
                                </th>
                                <th>
                                    <span>*</span>종료일시
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Input
                                        errorMessage=""
                                        handleChange={(evt) =>
                                            onChangeTitle(evt)
                                        }
                                        label=""
                                        onKeyDown={() => {}}
                                        placeholder="문구를 입력하세요"
                                        size="default"
                                        type="text"
                                        value={title}
                                    />
                                </td>
                                <td>
                                    <Calendar
                                        getUserDate={(date: number) => {
                                            setStartDate(
                                                new Date(date).toISOString(),
                                            );
                                        }}
                                        setDate={
                                            startDate
                                                ? new Date(startDate).getTime()
                                                : Date.now()
                                        }
                                        timePicker={true}
                                        timeInterval={1}
                                        minDate={Date.now()}
                                    />
                                </td>
                                <td>
                                    <Calendar
                                        getUserDate={(date: number) => {
                                            setEndDate(
                                                new Date(date).toISOString(),
                                            );
                                        }}
                                        setDate={
                                            endDate
                                                ? new Date(endDate).getTime()
                                                : Date.now()
                                        }
                                        timePicker={true}
                                        timeInterval={1}
                                        minDate={new Date(
                                            startDate ?? 0,
                                        ).getTime()}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </EditModal>
        </>
    );
};

export default CampusCountDownModal;

const tableRootStyle = css`
    width: 100%;
    height: 17vh;
    min-height: 340px;
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 10px;
            span {
                color: #ff0000;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 12px 10px;
            text-align: center;
        }
    }
`;
