import React, { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Label } from "@src/components";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { userExpertDto } from "./types";
import { ConvertPhoneNumber } from "@src/utils/units";

export const getExpertInquiryColumns = () => {
    return [
        {
            Header: "번호",
            accessor: "no",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "구분",
            accessor: "bidType",
            Cell: ({ cell: { value }, row }: any) => {
                const id = row.original.itemSubId;

                return (
                    <div
                        style={{ textAlign: "center", padding: "15px 0" }}
                        onClick={() => {}}
                    >
                        {value === 1 ? "경매" : "공매"}
                        <br />
                        {id}
                    </div>
                );
            },
        },
        {
            Header: "용도",
            accessor: "useDetail",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },

        {
            Header: "소재지",
            accessor: "address",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        // minWidth: "400px",
                        // width: "400px",
                        maxWidth: "80%",
                        whiteSpace: "pre-wrap",
                        margin: "0 auto",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "문의 회원",
            accessor: "inquiryUserName",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "전문가 회원",
            accessor: "expertUserName",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "문의 유형",
            accessor: "type",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {value === "call" ? "전화문의" : "문자문의"}
                </div>
            ),
        },
        {
            Header: "문의 일시",
            accessor: "createdAt",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {ConvertDateTime(value)}
                </div>
            ),
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <>
                        <IconButton
                            type="arrow"
                            handleClick={() => {
                                // navigate(`/users/detail/${value}`);
                            }}
                        />
                    </>
                </div>
            ),
        },
    ];
};
