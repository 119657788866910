import React, { useState, useEffect, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { EditModal, Input } from "@src/components";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { PASSWORD_DELAY } from "@src/constants/Delay";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";

interface IPasswordForm {
    getDisabled: Function;
    getPassword: Function;
    setParentErrorMessage: Function;
}

function PasswordForm({
    getDisabled,
    getPassword,
    setParentErrorMessage,
}: IPasswordForm) {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [passwords, setPasswords] = useState({
        password: "",
        passwordCheck: "",
        passwordOld: "",
    });
    const { password, passwordCheck, passwordOld } = passwords;
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPasswords({
            ...passwords,
            [name]: value,
        });
        setParentErrorMessage("");
    };
    const success = () => {
        getDisabled(false);
        setErrorMessage("");
        getPassword(password, passwordOld);
    };
    const fail = () => {
        getDisabled(true);
        setErrorMessage("비밀번호가 일치하지 않습니다");
        getPassword("");
    };
    useEffect(() => {
        if (password !== "" && passwordCheck !== "") {
            password === passwordCheck ? success() : fail();
        } else {
            setErrorMessage("");
        }
    }, [passwords]);

    return (
        <div css={InputBoxStyle} className="passwordModal">
            <Input
                type="password"
                name="passwordOld"
                label="현재 비밀번호"
                placeholder="현재 비밀번호 입력"
                handleChange={handleChange}
                isDisabled={false}
                size="medium"
            />
            <Input
                type="password"
                name="password"
                label="새 비밀번호"
                placeholder="새 비밀번호 입력"
                handleChange={handleChange}
                isDisabled={false}
                size="medium"
            />
            <Input
                type="password"
                name="passwordCheck"
                errorMessage={errorMessage}
                label="비밀번호 확인"
                placeholder="새 비밀번호 변경"
                handleChange={handleChange}
                isDisabled={false}
                size="medium"
            />
        </div>
    );
}
function PasswordModal({
    isModalShow,
    handleOpenModal,
    title = "비밀번호 추가",
    buttonText,
}: any) {
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(true);
    const getDisabled = (value: any) => setIsDisabled(value);
    const [password, setPassword] = useState({});
    const [errorMessage, setErrorMessage] = useState<string>("");
    const getPassword = (passwdNew: string, passwdOld: string) => {
        setPassword({ passwdOld, passwdNew });
    };
    const changePassword = (params: {
        passwdOld: string;
        passwdNew: string;
    }) => {
        return axiosInstance.patch(axiosPath.passwd, params);
    };
    const { mutate } = useMutation(changePassword);
    const patchPassword = (password: any) => {
        dispatch(startLoading());
        const { passwdOld, passwdNew } = password;
        const params = { passwdOld, passwdNew };
        try {
            mutate(params, {
                onSuccess: ({ data }: any) => {
                    if (data.data.errCode !== 0) {
                        setErrorMessage(
                            "비밀번호는 영문, 숫자, 특수문자를 포함한 8자 이상입니다. \r\n 다시 시도해주세요.",
                        );
                        dispatch(endLoading());
                    } else {
                        handleOpenModal(false);
                        setTimeout(() => {
                            dispatch(endLoading());
                        }, PASSWORD_DELAY);
                    }
                },
                onError: (error: any) => {
                    console.log(error);
                    dispatch(endLoading());
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isModalShow) {
            // 초기화
            setErrorMessage("");
        }
    }, [isModalShow]);

    return (
        <>
            <EditModal
                buttonText={buttonText}
                handleBtnClick={() => patchPassword(password)}
                handleOpenModal={handleOpenModal}
                isModalShow={isModalShow}
                size="fitContent"
                title={title}
                isDisabled={isDisabled}
                errorMessage={errorMessage}
            >
                <>
                    <PasswordForm
                        getDisabled={getDisabled}
                        getPassword={getPassword}
                        setParentErrorMessage={setErrorMessage}
                    />
                </>
            </EditModal>
        </>
    );
}

const InputBoxStyle = css`
    width: 700px;
    span {
        display: inline-block;
        width: 120px;
    }
    p:nth-of-type(2) {
        padding-top: 5px;
        padding-left: 130px;
    }
`;

export default PasswordModal;
