import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface IToggleButton {
    name: string;
    value: boolean;
    isDisabled?: boolean;
    handleToggle: (name: string, checked: boolean) => void;
}

function ToggleButton({
    name,
    handleToggle,
    value = false,
    isDisabled,
}: IToggleButton) {
    const [isToggle, setIsToggle] = useState(false);
    useEffect(() => {
        // value &&
        setIsToggle(value);
    }, [value]);

    const handleClick = () => {
        if (!isDisabled) {
            setIsToggle(!isToggle);
            handleToggle(name, !isToggle);
        }
    };
    return (
        <>
            <div
                css={[
                    ToggleStyle,
                    toggleMove[isToggle ? "active" : "inactive"],
                    isDisabled &&
                        css`
                            pointer-events: none;
                        `,
                ]}
                onClick={handleClick}
            >
                <div></div>
            </div>
        </>
    );
}

const ToggleStyle = css`
    width: 48px;
    height: 24px;
    background: ${Common.colors.purple600};
    border-radius: 15px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    div {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: ${Common.colors.white};
    }
`;
const toggleMove = {
    active: css`
        justify-content: flex-end;
    `,
    inactive: css`
        justify-content: flex-start;
        background: ${Common.colors.gray200};
    `,
};
export default ToggleButton;
