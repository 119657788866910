import { useEffect } from "react";

export const useEventListener = (handler) => {
    useEffect(() => {
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [handler]);
};
