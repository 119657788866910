import React, { useEffect, useState } from "react";
import PushNavigator from "@src/pages/notify/containers/PushNavigator";
import { css } from "@emotion/react";
import { Button, ConfirmModal, PageTable, SearchInput } from "@src/components";
import { useNavigate } from "react-router-dom";
import { Constants } from "@src/constants/Constants";
import { getColumns } from "./SituationListColumns";
import { BaseTemplate } from "@src/interfaces/Template.interface";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "@src/reducers/loading";
import { useDeleteTemplate } from "@src/hooks/queries/useTemplateDetail";
import { useSituationList } from "@src/hooks/queries/useSituation";
import { previousDay } from "date-fns";

function SituationList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [deleteNo, setDeleteNo] = useState("");
    const [isDelete, setIsDelete] = useState<{
        isModalShow: boolean;
        deleteNo: number;
    }>({
        isModalShow: false,
        deleteNo: -1,
    });
    const columns: any = getColumns({ navigate, setDeleteNo, setIsDelete });
    const [params, setParams] = useState({ page: 1, limit: 10 });
    const [totalCount, setTotalCount] = useState(0);
    const {
        isLoading,
        data: templateList = { result: [], total: 0 },
        isError,
        refetch: listRefetch,
    } = useSituationList(params);
    const [data, setData] = useState<BaseTemplate[]>([]);

    useEffect(() => {
        if (!isLoading && !isError) {
            setData(templateList.result);
            if (params.page === 1) {
                setTotalCount(templateList.total);
            }
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());
        }
    }, [isLoading, isError, templateList.result]);

    const { mutate: deleteMutate } = useDeleteTemplate();
    const onDelete = (deleteNo: string) => {
        deleteMutate(deleteNo, {
            onSuccess: () => {
                listRefetch();
            },
            onError: (error: any) => {
                console.log(error);
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });

        setIsDelete({ ...isDelete, ...{ isModalShow: false } });
    };

    return (
        <div css={listStyle}>
            <PushNavigator />
            <div css={searchDiv}>
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/notify/situation/create");
                    }}
                    label={`${Constants.SITUATION} 등록`}
                    size="medium"
                />
            </div>
            <div
                style={{
                    width: "400px",
                    marginLeft: "auto",
                    marginTop: "10px",
                }}
            >
                <SearchInput
                    placeholder="발생 상황을 입력하세요"
                    handleClick={(searchItem: string) => {
                        setParams((prevState: any) => {
                            return {
                                ...prevState,
                                ...{ q: searchItem, page: 1 },
                            };
                        });
                    }}
                />
            </div>
            <div css={tableDiv}>
                <PageTable
                    data={data}
                    columns={columns}
                    FixedHeight={600}
                    showTotalCount={true}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParams({ page, limit: 10 });
                    }}
                    forcePage={params.page === 1}
                />
            </div>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() =>
                    setIsDelete({ ...isDelete, ...{ isModalShow: false } })
                }
                handleOk={() => onDelete(deleteNo)}
                isModalShow={isDelete.isModalShow}
            >
                <>정말 삭제하시겠습니까?</>
            </ConfirmModal>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const searchDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
`;

const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        padding: 8px 0;
        button:nth-of-type(2) {
            margin-left: 15px;
        }
        &:nth-of-type(2) {
            width: 600px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
    }
`;
export default SituationList;
