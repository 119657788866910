import { createSlice } from "@reduxjs/toolkit";
import { OccupancyRelationType } from "@src/types";
const occupancyRelationData: Array<OccupancyRelationType> = [
    {
        id: 0,
        ocpRelation: "사업자등록자",
        name: "도**",
        contractDate: "2020.01.01",
        moveInDate: "2020.01.21",
        fixedDate: "2020.01.22",
        deposit: "미상",
        rentPrice: "미상",
        rentPart: "미상",
    },
    {
        id: 1,
        ocpRelation: "사업자등록자",
        name: "김민수",
        contractDate: "2020.01.01",
        moveInDate: "2020.01.21",
        fixedDate: "2020.01.22",
        deposit: "50000000",
        rentPrice: "미상",
        rentPart: "미상",
    },
    {
        id: 2,
        ocpRelation: "사업자등록자",
        name: "도**",
        contractDate: "2020.01.01",
        moveInDate: "2020.01.21",
        fixedDate: "",
        deposit: "58000000",
        rentPrice: "8000000000",
        rentPart: "미상",
    },
];

const initialState = occupancyRelationData;

export const occupancyRelationSlice = createSlice({
    name: "occupancyRelation",
    initialState,
    reducers: {
        createOccupancyRelation: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateOccupancyRelation: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createOccupancyRelation, updateOccupancyRelation } =
    occupancyRelationSlice.actions;

export default occupancyRelationSlice.reducer;
