import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, Checkbox, DropDown, RangeCalendar } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";

interface DeungiChangeListFilterProps {
    filter: any;
    setFilter: any;
    refetch: any;
}

const DeungiChangeFilter = ({
    filter,
    setFilter,
    refetch,
}: DeungiChangeListFilterProps) => {
    const handleCheckBoxChange = (
        name: string,
        checked: boolean,
        checkList: any,
    ) => {
        let setCheckedList = {
            ...checkList,
            [name]: checked,
        };

        const statusType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedList, statusType };
    };

    const initDeungiState = {
        변동확인전: false,
        변동없음: false,
        변동있음: false,
        소유자불일치: false,
        기간만료: false,
        확인실패: false,
        관리자삭제: false,
    };

    const [isDeungiChecked, setIsDeungiChecked] = useState(initDeungiState);
    const [deungiState, setDeungiState] = useState<string[]>([]);
    const [today, setToday] = useState<any>(new Date().getTime());
    const [startDay, setStartDay] = useState<any>();
    const [endDay, setEndDay] = useState<any>();
    const [searchType, setSearchType] = useState<string>("userName");
    const [searchText, setSearchText] = useState<string>("");

    const date = new Date();

    const year = date.getFullYear(); // 년
    const month = date.getMonth(); // 월
    const day = date.getDate(); // 일

    useEffect(() => {
        setSearchText("");
    }, [searchType]);

    const handleSearch = () => {
        setFilter({
            page: 1,
            limit: 10,
            changeState: deungiState,
            searchType: searchType,
            regStartDate: startDay,
            regEndDate: endDay,
            searchValue: searchText,
        });
        refetch;
    };
    const handleClear = () => {
        setIsDeungiChecked(initDeungiState);
        setDeungiState([]);
        setStartDay(null);
        setEndDay(null);
        setSearchType("userName");
        setSearchText("");
    };
    return (
        <>
            <StyledCommodityFilter>
                <StyledFilterBox>
                    <div className="itemRow">
                        <span>등록일시</span>
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay("");
                                setEndDay("");
                            }}
                            label="전체"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(ConvertDate(today));
                                setEndDay(null);
                            }}
                            label="오늘"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year,
                                            month,
                                            day - 7,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="7일"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year,
                                            month - 1,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="1개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year,
                                            month - 6,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="6개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year - 1,
                                            month,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="12개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <RangeCalendar
                            getUserRangeDate={(date) => {
                                const { startDate: start, endDate: end } = date;
                                setStartDay(start ? ConvertDate(start) : "");
                                setEndDay(end ? ConvertDate(end) : "");
                            }}
                            setRangeDate={{
                                startDate: startDay,
                                endDate: endDay,
                            }}
                            isDisabled={false}
                            timePicker={false}
                        />
                    </div>
                    <div className="itemRow">
                        <span>열람상태</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["변동확인전"]}
                                label="확인중(변동 확인 전)"
                                defaultValue={"변동확인전"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setIsDeungiChecked(setCheckedList);
                                    setDeungiState(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["변동없음"]}
                                label="확인중(확인된 변동없음)"
                                defaultValue={"변동없음"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setIsDeungiChecked(setCheckedList);
                                    setDeungiState(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["변동있음"]}
                                label="확인중(변동있음)"
                                defaultValue={"변동있음"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["소유자불일치"]}
                                label="확인중(소유자불일치)"
                                defaultValue={"소유자불일치"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["기간만료"]}
                                label="알림종료"
                                defaultValue={"기간만료"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["확인실패"]}
                                label="확인 불가"
                                defaultValue={"확인실패"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["관리자삭제"]}
                                label="관리자 삭제"
                                defaultValue={"관리자삭제"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                        </div>
                    </div>

                    <div className="itemRow">
                        <span>검색</span>
                        <div className="dropDown">
                            <DropDown
                                border
                                data={[
                                    {
                                        id: 1,
                                        key: "userName",
                                        value: "이름",
                                    },
                                    {
                                        id: 2,
                                        key: "useEmail",
                                        value: "이메일",
                                    },
                                    {
                                        id: 3,
                                        key: "address",
                                        value: "소재지",
                                    },
                                    {
                                        id: 4,
                                        key: "pin",
                                        value: "부동산고유번호",
                                    },
                                ]}
                                defaultValue=""
                                handleChange={(e, text, value) => {
                                    value && setSearchType(value);
                                }}
                            />
                        </div>
                        <StyledReasonWrapper>
                            <input
                                type="text"
                                placeholder="이름이나 이메일, 소재지를 입력해주세요"
                                onChange={(e: any) => {
                                    setSearchText(e.target.value);
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={searchText}
                            />
                        </StyledReasonWrapper>
                    </div>
                </StyledFilterBox>
                <StyledSearchButton>
                    <Button
                        color="gray"
                        handleClick={() => {
                            handleClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            handleSearch();
                        }}
                        label="검색"
                        size="medium"
                    />
                </StyledSearchButton>
            </StyledCommodityFilter>
        </>
    );
};

export default DeungiChangeFilter;

const StyledCommodityFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    margin-bottom: 32px;
`;

const StyledFilterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: fit-content;
    background-color: #f4f5f7;
    border-radius: 10px;
    padding: 10px 24px 24px 24px;
    margin-top: 20px;
    & > .itemRow {
        display: flex;
        gap: 16px;
        align-items: center;
        box-sizing: border-box;
        & > span {
            width: 63px;
            font-weight: 400;
            font-size: 13px;
            color: #222222;
            letter-spacing: -0.4px;
        }
        & > .checkBoxDiv {
            display: flex;
            gap: 12px;
            align-items: center;
        }
        & > .dropDown {
            height: 40px;
            & > div {
                height: 100%;
                & > label {
                    & > select {
                        height: 100%;
                    }
                }
            }
        }
    }
`;

const StyledReasonWrapper = styled.div`
    width: 648px;
    height: 40px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledSearchButton = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
`;
