import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

export interface IDropDownData {
    key: string;
    value: string;
}

interface IDropDown {
    data: IDropDownData | any;
    handleChange: (
        e: ChangeEvent<HTMLSelectElement>,
        text?: string,
        value?: string,
    ) => void;
    defaultValue?: string | undefined;
    handleBannerChange?: (e: any) => void;
    border?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    ref?: any;
    label?: string;
}

const DropDown = ({
    data,
    handleChange,
    defaultValue = "",
    handleBannerChange,
    border = true,
    placeholder,
    isDisabled = false,
    ref,
    label,
}: IDropDown) => {
    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { options, value } = e.target;
        const text = options[options.selectedIndex].innerText;
        handleChange(e, text, value);
        handleBannerChange && handleBannerChange(data?.[Number(value) - 1]?.no);
    };
    return (
        <div key={defaultValue}>
            <label htmlFor={label}>
                <select
                    ref={ref}
                    onChange={onChange}
                    css={border ? dropDownSelect : [dropDownSelect, borderNone]}
                    defaultValue={defaultValue}
                    disabled={isDisabled}
                    required
                >
                    {placeholder && (
                        <option value={undefined}>{placeholder}</option>
                    )}
                    {data.map(
                        (
                            item: { key: string; value: string, no: number },
                            index: number,
                        ) => {
                            return (
                                <option key={index} value={item.key}>
                                    {item.value ? item.value : index + 1}
                                </option>
                            );
                        },
                    )}
                </select>
            </label>
        </div>
    );
};

const dropDownSelect = css`
    ${Common.textStyle.body3_R14}
    color: ${Common.colors.gray500};
    padding: 6px 20px;
    padding-left: 10px;
    border: 1.5px solid ${Common.colors.gray200};
    border-radius: 8px;
    outline: none;
    &:disabled {
        -webkit-appearance: none;
        -moz-appearance: none;
        color: ${Common.colors.black};
    }
`;
const borderNone = css`
    border: none;
`;
export default DropDown;
