import { useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import {
    ICoupon,
    ICouponDetailList,
    ICouponDetailListDto,
    ICouponList,
    ICouponListDto,
} from "@src/interfaces/Coupon.interface";
import { queryList } from "./queryList";
import { ResponseMissgoApi } from "@src/interfaces/MissgoApi";
import { AxiosError } from "axios";

const fetchData = async (params: any) => {
    const url = `${axiosPath.coupon}/getList?${new URLSearchParams(params)}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: ICouponList[] = responseData.data.list;

    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 &&
            queryList.setTotalCount(responseData.data.totalCnt);
    } else {
        queryList.setTotalCount(responseData.data.totalCnt);
    }

    return queryList.getData(parse);
};

const parse = (originData: ICouponList[]) => {
    const couponType = fetchCouponType();

    const newData: ICouponList[] = originData.map((item) => {
        const newDesc = couponType;
        return {
            ...item,
            count_status: `${item.used_count ?? 0} / ${item.issued_count}`,
            // code_type_desc: newDesc,
        };
    });
    return newData;
};

const useCouponListFetchData = (searchParams: ICouponListDto) => {
    const initialData: any = [];
    const queryParams: any = searchParams;
    return useQuery<ResponseMissgoApi<ICouponList[]>, Error>(
        [queryKeys.COUPON_LIST, new URLSearchParams(queryParams)],
        () => fetchData(queryParams),
        { initialData, enabled: false },
    );
};

const fetchDetailData = async (id: string, params: any) => {
    const url = `${axiosPath.coupon}/getIssuedList/${id}?${new URLSearchParams(
        params,
    )}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: ICouponDetailList[] = responseData.data.list;

    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 &&
            queryList.setTotalCount(responseData.data.totalCnt);
    } else {
        queryList.setTotalCount(responseData.data.totalCnt);
    }

    return queryList.getData(detailParse);
};

const detailParse = (originData: ICouponDetailList[]) => {
    const couponType = fetchCouponType();

    const newData: ICouponDetailList[] = originData;
    return newData;
};

const useCouponDetailListFetchData = (
    id: string,
    searchParams: ICouponDetailListDto,
) => {
    const initialData: any = [];
    const queryParams: any = searchParams;
    return useQuery<ResponseMissgoApi<ICouponDetailList[]>, Error>(
        [queryKeys.COUPON_DETAIL_LIST, new URLSearchParams(queryParams)],
        () => fetchDetailData(id, queryParams),
        { initialData, enabled: false },
    );
};

const fetchCouponData = async (id: string) => {
    const url = `${axiosPath.coupon}/getCoupon/${id}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: ICoupon = responseData.data.data;

    return originData;
};

const useCouponFetchData = (id: string) => {
    const initialData: any = [];
    return useQuery<ICoupon, Error>(
        [queryKeys.COUPON_DATA, id],
        () => fetchCouponData(id),
        { initialData, enabled: false },
    );
};

// /product/products
const fetchProduct = async () => {
    const url = `${axiosPath.product}/products/coupon`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData.data.list;
    const total = responseData.data.totalCnt;

    queryList.setDataList(originData);

    // return queryList.getData(parse);
    return { result: originData, total };
};

const useProductListFetchData = () => {
    const initialData: any = { result: [], total: 0 };
    return useQuery<ResponseMissgoApi<any[]>, Error>(
        [queryKeys.PRODUCT_LIST],
        () => fetchProduct(),
        { initialData },
    );
};

const useSaveCoupon = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.coupon}/create`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.post(url, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useUpdateCoupon = (
    id: string,
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.coupon}/edit/${id}`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.patch(url, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const fetchCouponType = async () => {
    const url = `${axiosPath.coupon}/getCouponTypeList`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;

    const originData = responseData.data.list;

    return originData;
};

const useCouponTypeFetchData = () => {
    const initialData: any = [];
    return useQuery<any[], Error>(
        [queryKeys.COUPON_TYPE],
        () => fetchCouponType(),
        { initialData },
    );
};

export {
    useCouponListFetchData,
    useProductListFetchData,
    useCouponTypeFetchData,
    useSaveCoupon,
    useUpdateCoupon,
    useCouponDetailListFetchData,
    useCouponFetchData,
};
