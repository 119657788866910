import styled from "@emotion/styled";
import { EditModal, Title, ViewMoreTable } from "@src/components";
import React, { useEffect } from "react";
import { getUserRoadExpansionEditModalColumns } from "./UserRoadExpansionEditModalColumns";

interface UserRoadExpansionEditModalProps {
    setRoadExpansionEditTime: (time: string) => void;
    setIsShowRoadExpansionEditModal: (isShow: boolean) => void;
    isShowRoadExpansionEditModal: boolean;
    roadExpansionItem: any;
    setIsShowRoadExpansionEditConfirmModal: (isShow: boolean) => void;
}

const UserRoadExpansionEditModal = ({
    setRoadExpansionEditTime,
    setIsShowRoadExpansionEditModal,
    isShowRoadExpansionEditModal,
    roadExpansionItem,
    setIsShowRoadExpansionEditConfirmModal,
}: UserRoadExpansionEditModalProps) => {
    const getColumns = getUserRoadExpansionEditModalColumns(setRoadExpansionEditTime);
    return (
        <>
            <EditModal
                isModalShow={isShowRoadExpansionEditModal}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowRoadExpansionEditConfirmModal(true);
                    setIsShowRoadExpansionEditModal(false);
                }}
                handleOpenModal={() => {
                    setIsShowRoadExpansionEditModal(false);
                }}
                size="big"
                title="이용권 정보 수정"
            >
                <>
                    <StyledEditContents>
                        <Title title="충전내역" />

                        <ViewMoreTable
                            FixedHeight={100}
                            columns={getColumns}
                            data={[roadExpansionItem]}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </StyledEditContents>
                </>
            </EditModal>
        </>
    );
};

export default UserRoadExpansionEditModal;

const StyledEditContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
        & > button {
            display: none;
        }
    }
`;
