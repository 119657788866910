import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

export interface IUserStateLandData {
    no?: number;
    isSubs: boolean;
    productName: string;
    isActive: boolean;
    expiredAt?: string;
    firstUsedAt?: string;
}

export interface IStateLandItem {
    no: number;
    orderNo: number;
    subsNo: number;
    firstUsedAt: string;
    createdAt: string;
    expiredAt: string;
    refundedAt: string;
    productName: string;
    paidNo: number;
    paidPrice: number;
    cardName: string;
    refundPrice: number;
    stateToString: string;
    isUsed: boolean;
    canRefund: boolean;
    canDelete: boolean;
}

export interface IStateLandItems {
    list: IStateLandItem[];
    totalCount: number;
}

const fetchUserStateLandData = async (userNo: number) => {
    const response = await axiosInstance.get(
        `${axiosPath.stateLand}/${userNo}`,
    );
    return response.data;
};

export const useGetUserStateLandData = (
    userNo: number,
    enabled: boolean = false,
) => {
    return useQuery<IUserStateLandData>(
        ["getUserStateLandData", userNo],
        () => fetchUserStateLandData(userNo),
        { enabled },
    );
};

const fetchStateLandItems = async (params: {
    limit?: number;
    page?: number;
    userNo: number;
}) => {
    const response = await axiosInstance.get(
        `${axiosPath.stateLand}/items/${params.userNo}`,
        { params: { page: params.page, limit: params.limit } },
    );
    return response.data;
};

export const useGetStateLandItems = (
    params: { limit?: number; page?: number; userNo: number },
    enabled: boolean = false,
) => {
    return useQuery<IStateLandItems>(
        ["getStateLandItems", params],
        () => fetchStateLandItems(params),
        { enabled },
    );
};

export const useEditStateLandExpiredAt = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: { stateLandNo: number; expiredAt: string }) => {
            try {
                const body = {
                    stateLandNo: params.stateLandNo,
                    expiredAt: params.expiredAt,
                };
                const response = await axiosInstance.post(
                    `${axiosPath.stateLand}/expired`,
                    body,
                );
                console.log(response.data);
                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};

export const useDeleteStateLand = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (stateLandNo: number) => {
            try {
                const response = await axiosInstance.delete(
                    `${axiosPath.stateLand}/${stateLandNo}`,
                );

                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};
