import styled from "@emotion/styled";
import { EditModal, ViewMoreTable } from "@src/components";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { getUserStateLandColumns } from "./UserStateLandColumns";

interface UserStateLandInfoModalProps {
    isModalShow: boolean;
    closeModal: () => void;
    setIsShowRefundModal: (isShow: boolean) => void;
    dataList?: any;
    totalCount?: number;
    setIsShowStateLandEditModal: (isShow: boolean) => void;
    setStateLandItem: Dispatch<SetStateAction<any>>;
    setIsShowStateLandDeleteConfirmModal: Dispatch<SetStateAction<boolean>>;
}

const UserStateLandInfoModal = ({
    isModalShow,
    closeModal,
    setIsShowRefundModal,
    dataList,
    totalCount,
    setIsShowStateLandEditModal,
    setStateLandItem,
    setIsShowStateLandDeleteConfirmModal,
}: UserStateLandInfoModalProps) => {
    const columns = getUserStateLandColumns(
        setIsShowRefundModal,
        closeModal,
        setIsShowStateLandEditModal,
        setStateLandItem,
        setIsShowStateLandDeleteConfirmModal,
    );

    return (
        <>
            <StyledModalWrap>
                <EditModal
                    isModalShow={isModalShow}
                    buttonText=""
                    handleBtnClick={() => {}}
                    handleOpenModal={closeModal}
                    size="big"
                    title="국유지 필터 상품 정보"
                    deleteButton={true}
                >
                    <StyledContents>
                        <ViewMoreTable
                            FixedHeight={300}
                            columns={columns}
                            data={dataList ?? []}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </StyledContents>
                </EditModal>
            </StyledModalWrap>
        </>
    );
};
export default UserStateLandInfoModal;

const StyledModalWrap = styled.div`
    p {
        margin-bottom: 8px;
    }
    & > div {
        & > .modal {
            max-width: 99vw;
            width: 1300px;
        }
    }
`;

const StyledContents = styled.div`
    & > .ticketCnt {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        & > span {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #9a49f2;
        }
        margin-bottom: 24px;
    }
    & > .subTitle {
        font-weight: 700;
        font-size: 14px;
        color: #222222;
        margin-bottom: 9px;
    }
    & > div {
        & > button {
            display: none;
        }
    }
`;
