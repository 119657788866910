import styled from "@emotion/styled";
import { Button, EditModal, ViewMoreTable } from "@src/components";
import {
    useGetConnectionList,
    useGetSusList,
} from "@src/hooks/queries/usePartner";
import { ConvertPrice } from "@src/utils/units";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPayBackSubsListColumns } from "./PayBackSubsListColumns";
import { getPayBackUserListColumns } from "./PayBackUserListColumns";

interface PayBackInfoBoxProps {
    detailData: any;
}

const PayBackInfoBox = ({ detailData }: PayBackInfoBoxProps) => {
    const { no } = useParams();
    const navigate = useNavigate();
    const [modalState, setModalState] = useState<{
        isShow: boolean;
        type: string;
    }>({
        isShow: false,
        type: "userList",
    });

    const connectionColumns = getPayBackUserListColumns(navigate);
    const subsColumns = getPayBackSubsListColumns(navigate);

    const { data: connectionData, refetch: refetchConnection } =
        useGetConnectionList(Number(no));

    const { data, refetch } = useGetSusList(Number(no));

    useEffect(() => {
        no && refetchConnection();
    }, [no]);

    useEffect(() => {
        no && refetch();
    }, [no]);

    return (
        <>
            <StyledContentsBox>
                <div className="titleDiv">
                    <span>페이백 정보</span>
                    <div style={{ display: "flex", gap: "13px" }}>
                        <Button
                            label="회원 리스트"
                            color="whiteOp"
                            size="small"
                            handleClick={(e) => {
                                setModalState({
                                    isShow: true,
                                    type: "userList",
                                });
                            }}
                            styles={{
                                boxSizing: "border-box",
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "21px",
                                width: "86px",
                                height: "24px",
                            }}
                        />
                        <Button
                            label="회원 현황"
                            color="whiteOp"
                            size="small"
                            handleClick={(e) => {
                                setModalState({
                                    isShow: true,
                                    type: "subsList",
                                });
                            }}
                            styles={{
                                boxSizing: "border-box",
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "21px",
                                width: "86px",
                                height: "24px",
                            }}
                        />
                        {/* <Button
                            label="비율 수정"
                            color="whiteOp"
                            size="small"
                            handleClick={(e) => {
                                const { userNo } = location.state as any;
                                // navigate(`/partner/update/${userNo}`, {
                                //     state: { userNo: userNo, type: "update" },
                                // });
                            }}
                            styles={{
                                boxSizing: "border-box",
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "21px",
                                width: "86px",
                                height: "24px",
                            }}
                        /> */}
                    </div>
                </div>
                <div className="contents">
                    <div className="titleContents">
                        <div className="nickname">{detailData.name} 님</div>
                        <div className="userCountDiv">
                            <div className="row">
                                <span>코드를 입력한 회원</span>
                                <span>{detailData.connectionCount}명</span>
                            </div>
                            <div className="row">
                                <span>구독 결제완료(페이백 대상)</span>
                                <span>{detailData.paybackCount}명</span>
                            </div>
                        </div>
                        <div className="paybackRatio">
                            <span className="title">적용 페이백 비율</span>
                            <span className="text">
                                {detailData.paybackRate}%
                            </span>
                        </div>
                    </div>
                    <div className="salesDiv">
                        <div className="salesTitle">
                            <div className="title">
                                {detailData.calc.month} 정산
                            </div>
                            <span>
                                정산기간 : {detailData.calc.startDate} ~{" "}
                                {detailData.calc.endDate}
                            </span>
                            <span>
                                정산예정일 : {detailData.calc.expectedDate}
                            </span>
                        </div>
                        <div className="boxWrap">
                            <div className="paymentAmount box">
                                <div className="title">결제액</div>
                                <div className="money">
                                    {ConvertPrice(detailData.calc.totalAmount)}
                                </div>
                            </div>
                            <div className="cancel box">
                                <div className="title">결제취소액</div>
                                <div className="money">
                                    {ConvertPrice(
                                        detailData.calc.canceledAmount,
                                    )}
                                </div>
                            </div>
                            <div className="netPayment box">
                                <div className="title">순결제액</div>
                                <div className="money">
                                    {ConvertPrice(detailData.calc.amount)}
                                </div>
                            </div>
                            <div className="descText">
                                ※ 현재 시점까지 집계된 금액이 노출됩니다
                            </div>
                        </div>
                    </div>
                </div>
            </StyledContentsBox>
            <EditModal
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={() => {
                    setModalState({
                        ...modalState,
                        isShow: false,
                    });
                }}
                size="big"
                title={
                    modalState.type === "userList"
                        ? "회원 리스트"
                        : "구독 결제완료(페이백 대상) 현황"
                }
                isModalShow={modalState.isShow}
                dimClose={true}
                checkCloseModal={true}
                deleteButton={true}
            >
                <StyledUserListModal>
                    {modalState.type === "userList" && (
                        <>
                            <div className="countList">
                                <div className="countItem">
                                    누적:{" "}
                                    <span>
                                        {ConvertPrice(
                                            connectionData?.result.total ?? 0,
                                        )}
                                    </span>
                                    명
                                </div>
                                <div className="countItem">
                                    페이백 대상:{" "}
                                    <span>
                                        {ConvertPrice(
                                            connectionData?.result
                                                .paybackCount ?? 0,
                                        )}
                                    </span>
                                    명
                                </div>
                                <div className="countItem">
                                    파트너 종료:{" "}
                                    <span>
                                        {ConvertPrice(
                                            connectionData?.result
                                                .expiredPartnerCount ?? 0,
                                        )}
                                    </span>
                                    명
                                </div>
                                <div className="countItem">
                                    파트너 해지:{" "}
                                    <span>
                                        {ConvertPrice(
                                            connectionData?.result
                                                .canceledPartnerCount ?? 0,
                                        )}
                                    </span>
                                    명
                                </div>
                            </div>

                            <ViewMoreTable
                                FixedHeight={300}
                                columns={connectionColumns}
                                data={connectionData?.result.list ?? []}
                                handleViewMore={function noRefCheck() {}}
                                isRefetching={true}
                            />
                        </>
                    )}
                    {modalState.type === "subsList" && (
                        <ViewMoreTable
                            FixedHeight={300}
                            columns={subsColumns}
                            data={data?.result ?? []}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    )}
                </StyledUserListModal>
            </EditModal>
        </>
    );
};

export default PayBackInfoBox;

const StyledContentsBox = styled.div`
    width: 1000px;
    height: fit-content;
    .titleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 13px;
        height: 43px;
        width: 100%;
        background-color: #fbf4fe;
        border: 1px solid #d8d8d8;
        & > span {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
        }
    }
    .contents {
        display: flex;
        flex-direction: column;
        height: fit-content;
        border-top: none;
        border: 1px solid #d8d8d8;
        padding: 38px 85px 18px 85px;
        gap: 41px;

        .titleContents {
            display: flex;
            justify-content: space-between;
            .nickname {
                font-size: 40px;
                font-weight: 700;
                line-height: 48px;
                /* margin-right: 160px; */
            }
            .userCountDiv {
                display: flex;
                flex-direction: column;
                gap: 11px;
                .row {
                    width: 300px;
                    display: flex;
                    justify-content: space-between;
                    & > span {
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
            }
            .paybackRatio {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 11px;
                & > span {
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
        .salesDiv {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin: auto;
            .salesTitle {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .title {
                    font-size: 24px;
                    font-weight: 700;
                }
                & > span {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
        .boxWrap {
            display: flex;
            gap: 28px;
            position: relative;
            padding-bottom: 30px;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 167px;
                height: 88px;
                font-size: 20px;
                font-weight: 700;
                gap: 20px;
            }
            .paymentAmount {
                background-color: #f1f3ff;
            }
            .cancel {
                background-color: #fff1f1;
            }
            .netPayment {
                background-color: #f1fff3;
            }
            .descText {
                position: absolute;
                bottom: 10px;
                right: 0;
                font-size: 10px;
            }
        }
    }
`;

const StyledUserListModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    .countList {
        display: flex;
        gap: 15px;
        align-items: center;
        .countItem {
            & > span {
                font-weight: 700;
            }
        }
    }
    & > div {
        & > .viewMoreButton {
            display: none;
        }
    }
`;
