import React, { useEffect, useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { PieChart } from "@src/components";
import { initTimeTerm, SalesStatType, useHomeItemSaleStatsData } from "@src/hooks/queries/useHomeChart";
import { dateFormat } from "@src/utils/dateUtil";
import { ISalesStat } from "@src/pages/user/types";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ChartHeader, { changeDateType } from "./ChartHeader";
import { ProductType, productTypeList } from "../Home";

enum SortTypeVal {
    name = 1,
    subsGroupName = 2,
    saleType = 3,
    salesCount = 4,
    salesAmount = 5,
}

function ItemSaleStatsChart() {
    const tableColumns = [
        { value: 10, name: "상품명" },
        { value: 20, name: "구독이름" },
        { value: 30, name: "판매형태" },
        { value: 40, name: "결제건수" },
        { value: 50, name: "상품가격" },
    ];
    const [productType, setProductType] = useState<ProductType>("subscription");
    const [options, setOptions] = useState({});

    const [filterSaleChart, setFilterSaleChart] = useState<SalesStatType>({
        startDate: dateFormat(initTimeTerm.day.startDate),
        endDate: dateFormat(initTimeTerm.day.endDate),
    });

    const parseHomeUserSalesChartAndCreateOption = (data: any) => {
        return {
            labels: data.map((v: any) => v.name),
            datasets: [
                {
                    type: "pie",
                    label: "상품",
                    data: data.map((v: any) => v.salesCount),
                    backgroundColor: data.map((v: any) => v.nameColorBg),
                    color: data.map((v: any) => v.nameColor),
                },
            ],
        };
    };

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        const dataType = e.currentTarget.getAttribute("product-type") as ProductType;
        setProductType(dataType);
    };

    const {
        isLoading: saleStatLoading,
        data: saleStatData,
        isError: saleStatError,
    } = useHomeItemSaleStatsData(filterSaleChart, productType);

    const [homeSalesChartData, setHomeSalesChartData] = useState<any>([]);
    const [sortedSaleStatData, setSortedSaleStatData] = useState<ISalesStat[]>([]);
    const [sortedSumData, setSortedSumData] = useState<any[]>([]);

    useEffect(() => {
        if (!saleStatLoading && !saleStatError && saleStatData) {
            const newSaleData = parseHomeUserSalesChartAndCreateOption(saleStatData);
            setHomeSalesChartData(newSaleData);
            setSortedSaleStatData(saleStatData);
        }
    }, [saleStatData]);

    const sumSaleCount = saleStatData
        ?.map((item: any) => item.salesCount)
        .reduce((acc: any, value: any) => acc + value, 0);

    const sumSaleAmount = saleStatData
        ?.map((item: any) => item.salesAmount)
        .reduce((acc: any, value: any) => acc + value, 0);

    const getDateStatsSalesChart = ({ range }: changeDateType) => {
        const { startDate, endDate } = range;

        setFilterSaleChart({
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        });
    };
    const [sortType, setSortType] = useState<number>(0); // 0 기본 10 상품명 오름 11 상품명 내림 20 구독이름 오름 21 구독이름 내림 30 판매형태 오름 31 판매형태 내림 ...

    const onClickSortStatData = (key: string, sortWay: number) => {
        if (saleStatData) {
            const newSortedStatData = saleStatData?.toSorted((q: any, w: any) =>
                q[key] > w[key] ? sortWay : q[key] < w[key] ? -sortWay : 0,
            );
            setSortedSaleStatData(newSortedStatData);
            if (/(name|subsGroupName|saleType)/.test(key)) {
                const reducer = (q: any, val: any, idx: number) => {
                    const currentValue = val[key];
                    if (idx === 0) {
                        q = [val];
                    } else {
                        if (q[q.length - 1][key] === currentValue) {
                            q[q.length - 1] = {
                                ...q[q.length - 1],
                                salesCount: q[q.length - 1].salesCount + val.salesCount,
                                salesAmount: q[q.length - 1].salesAmount + val.salesAmount,
                            };
                        } else {
                            q = [...q, val];
                        }
                    }
                    return q;
                };
                const sumValue = newSortedStatData.reduce(reducer, []);
                setSortedSumData(sumValue);
            } else {
                setSortedSumData([]);
            }
        }
    };

    useEffect(() => {
        if (sortType && saleStatData) {
            switch (sortType) {
                case 10:
                    onClickSortStatData("name", 1);
                    break;
                case 11:
                    onClickSortStatData("name", -1);
                    break;
                case 20:
                    onClickSortStatData("subsGroupName", 1);
                    break;
                case 21:
                    onClickSortStatData("subsGroupName", -1);
                    break;
                case 30:
                    onClickSortStatData("saleType", 1);
                    break;
                case 31:
                    onClickSortStatData("saleType", -1);
                    break;
                case 40:
                    onClickSortStatData("salesCount", 1);
                    break;
                case 41:
                    onClickSortStatData("salesCount", -1);
                    break;
                case 50:
                    onClickSortStatData("salesAmount", 1);
                    break;
                case 51:
                    onClickSortStatData("salesAmount", -1);
                    break;
                default:
                    saleStatData;
            }
        }
    }, [sortType]);

    return (
        <div>
            <h1 style={{ marginBottom: "7px" }}>상품별 매출현황</h1>
            <div css={subChartDiv}>
                <div css={chartHeaderBoxWrap}>
                    {productTypeList.map((type) => (
                        <div
                            key={type.value}
                            css={productType === type.value ? [chartHeaderBox, selected] : chartHeaderBox}
                            product-type={type.value}
                            onClick={handleClick}
                        >
                            {type.name}
                        </div>
                    ))}
                </div>
                <div css={rootBoxStyle}>
                    <div className="mainChartBox">
                        <ChartHeader
                            getChangeDate={getDateStatsSalesChart}
                            defaultBasisType="month"
                            type="productType"
                        />
                        <div css={chartBoxStyle}>
                            <PieChart data={homeSalesChartData} options={options} />
                        </div>
                    </div>
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    {tableColumns.map((column) => (
                                        <th key={column.name}>
                                            {column.name}
                                            {sortType === column.value ? (
                                                <ArrowUpwardRoundedIcon
                                                    className="sorted"
                                                    onClick={() => setSortType(column.value + 1)}
                                                />
                                            ) : sortType === column.value + 1 ? (
                                                <ArrowDownwardRoundedIcon
                                                    className="sorted"
                                                    onClick={() => setSortType(column.value)}
                                                />
                                            ) : (
                                                <SwapVertRoundedIcon
                                                    className="sortButton"
                                                    onClick={() => setSortType(column.value)}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedSaleStatData?.map((v, idx: number) => (
                                    <tr key={idx.toString()}>
                                        <td className="productName">
                                            <ChartColor nameColorBg={v.nameColorBg} />
                                            {v.name}
                                        </td>
                                        <td>{v?.subsGroupName}</td>
                                        <td>{v?.saleType}</td>
                                        <td>{v.salesCount} 건</td>
                                        <td>{v.salesAmount.toLocaleString()}원</td>
                                    </tr>
                                ))}
                                <tr className="totalAmount">
                                    <td colSpan={3}>총 구독 매출</td>
                                    <td>{sumSaleCount} 건</td>
                                    <td>{Number(sumSaleAmount).toLocaleString()}원</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const subChartDiv = css`
    width: 1220px;
    height: fit-content;
    min-height: 400px;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1.5px solid ${Common.colors.gray200};
    & h1 {
        ${Common.textStyle.title1_B16}
        color: ${Common.colors.black};
    }

    .typeBtn {
        height: 24px;
    }
    .mainChartBox {
        min-width: 350px;
        height: 200px;
        canvas {
            width: 300px !important;
            height: 300px !important;
        }
    }
    .statsGridBox {
        display: flex;
        width: auto;
        flex-direction: row;
        padding: 0px;
        margin-bottom: 0px;
        height: 40px;
        .navTitle1 {
            display: flex;
            justify-content: center;
            align-items: center;
            ${Common.textStyle.text1_R13}
            color: ${Common.colors.black};
            margin-bottom: 12px;
            width: 370px;
            height: 40px;
            border: 1px solid ${Common.colors.gray200};
            text-align: center;
        }
        .navTitle2 {
            display: flex;
            justify-content: center;
            align-items: center;
            ${Common.textStyle.text1_R13}
            color: ${Common.colors.black};
            margin-bottom: 12px;
            width: 70px;
            height: 40px;
            border: 1px solid ${Common.colors.gray200};
            justify-content: center;
            padding: 0 6px;
        }
        .navTitle3 {
            display: flex;
            justify-content: center;
            align-items: center;
            ${Common.textStyle.text1_R13}
            color: ${Common.colors.black};
            margin-bottom: 12px;
            width: 90px;
            height: 40px;
            border: 1px solid ${Common.colors.gray200};
            padding: 0 6px;
        }
        .navTitle4 {
            display: flex;
            ${Common.textStyle.text1_R13}
            color: ${Common.colors.black};
            margin-bottom: 12px;
            width: 80px;
            height: 40px;
            border: 1px solid ${Common.colors.gray200};
            justify-content: center;
            align-items: center;
            padding: 0 6px;
        }
        .navTitle5 {
            display: flex;
            ${Common.textStyle.text1_R13}
            color: ${Common.colors.black};
            margin-bottom: 12px;
            width: 150px;
            height: 40px;
            border: 1px solid ${Common.colors.gray200};
            justify-content: center;
            align-items: center;
            padding: 0 6px;
        }
    }
`;

const ChartColor = ({ nameColorBg }: { nameColorBg: string }) => {
    const chartColor = css`
        display: flex;
        margin-bottom: 12px;
        width: 15px !important;
        height: 15px;
        margin: 8px 5px;
        justify-content: center;
        align-items: center;
        align-content: center;
        justify-items: center;
        border-radius: 3px;
        background-color: ${nameColorBg};
    `;
    return <div css={chartColor} />;
};

const chartHeaderBoxWrap = css`
    display: flex;
    flex-direction: row;
    gap: 2px;
    cursor: pointer;
`;
const chartHeaderBox = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px 0px;
    height: 24px;
    background: ${Common.colors.white};
    border: 1px solid ${Common.colors.gray300};
    border-radius: 4px;
    font-size: 12px;
    line-height: 180%;
`;
const selected = css`
    border: 1px solid ${Common.colors.purple500};
    color: ${Common.colors.purple500};
`;

const rootBoxStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const chartBoxStyle = css`
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const tableRootStyle = css`
    width: 100%;
    table {
        width: 100%;
        thead {
            tr {
                background-color: #ffffff;
                position: sticky;
                top: 0;
            }
            th {
                padding: 10px;
            }
            .sortButton {
                margin-bottom: -5px;
                margin-left: 5px;
                font-size: 20px;
                cursor: pointer;
            }
            .sorted {
                margin-bottom: -5px;
                margin-left: 5px;
                font-size: 20px;
                cursor: pointer;
                color: ${Common.colors.blue};
            }
        }
        tbody {
            vertical-align: middle;
            border-bottom: 1px solid #b5b5b5;
        }
        tr {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 5px 10px;
            text-align: center;
            white-space: nowrap;
            word-break: keep-all;
            text-overflow: ellipsis;
            cursor: pointer;
        }
        .productName {
            display: flex;
            align-items: center;
        }
        .totalAmount {
            td {
                padding: 10px;
                color: #ff0000;
            }
        }
    }
`;

export default ItemSaleStatsChart;
