import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { css } from "@emotion/react";
import { Checkbox, Input, RangeCalendar } from "@src/components";
import { Common } from "@src/styles/Common";
import { ConvertDateLine, dateParseLine } from "@src/utils/dateUtil";
import { IViewsCountFilter } from "@src/hooks/queries/useViewsCount";

interface ViewsCountFilterProps {
    filters: IViewsCountFilter;
    setFilters: Dispatch<SetStateAction<IViewsCountFilter>>;
}

const UserViewsCountFilters = ({
    filters,
    setFilters,
}: ViewsCountFilterProps) => {
    const TODAY = ConvertDateLine(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDateLine(
        new Date(year, month, day - 7).getTime(),
    );
    const before1Month = ConvertDateLine(
        new Date(year, month - 1, day).getTime(),
    );
    const before6Month = ConvertDateLine(
        new Date(year, month - 6, day).getTime(),
    );
    const before12Month = ConvertDateLine(
        new Date(year - 1, month, day).getTime(),
    );
    const dateLabel = [
        { id: 1, startDate: TODAY, value: "오늘" },
        { id: 2, startDate: before7day, value: "7일" },
        { id: 3, startDate: before1Month, value: "1개월" },
        { id: 4, startDate: before6Month, value: "6개월" },
        { id: 5, startDate: before12Month, value: "12개월" },
    ];
    const renderDate = (item: number, start: string, end: string) => {
        if (item === 1) {
            setFilters({
                ...filters,
                [start]: TODAY,
                [end]: TODAY,
            });
        } else if (item === 2) {
            setFilters({
                ...filters,
                [start]: before7day,
                [end]: TODAY,
            });
        } else if (item === 3) {
            setFilters({
                ...filters,
                [start]: before1Month,
                [end]: TODAY,
            });
        } else if (item === 4) {
            setFilters({
                ...filters,
                [start]: before6Month,
                [end]: TODAY,
            });
        } else if (item === 5) {
            setFilters({
                ...filters,
                [start]: before12Month,
                [end]: TODAY,
            });
        } else {
            setFilters({
                ...filters,
                [start]: before1Month,
                [end]: TODAY,
            });
        }
    };

    const viewsCountLabel = [
        {
            id: 0,
            viewCountMin: 0,
            viewCountMax: 10,
            isChecked: false,
        },
        {
            id: 1,
            viewCountMin: 11,
            viewCountMax: 30,
            isChecked: false,
        },
        {
            id: 2,
            viewCountMin: 31,
            viewCountMax: 50,
            isChecked: false,
        },
        {
            id: 3,
            viewCountMin: 51,
            viewCountMax: 100,
            isChecked: false,
        },
    ];

    const [checkedBox, setCheckBox] = useState<boolean>(false);
    const [clickedBox, setClickedBox] = useState<boolean>(false);

    return (
        <>
            <div css={filterWrap}>
                <ul>
                    <li>
                        <span className="subTitle">
                            열람횟수 <br /> 조회기간
                        </span>
                        <div>
                            {dateLabel.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <button
                                            value={item.value}
                                            className={
                                                "dateBtn" +
                                                (filters?.startDate ===
                                                item?.startDate
                                                    ? " active"
                                                    : "")
                                            }
                                            onClick={() =>
                                                renderDate(
                                                    item.id,
                                                    "startDate",
                                                    "endDate",
                                                )
                                            }
                                        >
                                            {item.value}
                                        </button>
                                    </div>
                                );
                            })}
                            <RangeCalendar
                                setRangeDate={{
                                    startDate:
                                        filters.startDate !== null &&
                                        filters.startDate !== undefined
                                            ? dateParseLine(filters.startDate)
                                            : null,
                                    endDate:
                                        filters.endDate !== null &&
                                        filters.endDate !== undefined
                                            ? dateParseLine(filters.endDate)
                                            : null,
                                }}
                                getUserRangeDate={(date, isError) => {
                                    const { startDate: start, endDate: end } =
                                        date;

                                    setFilters({
                                        ...filters,
                                        startDate: start
                                            ? ConvertDateLine(start)
                                            : undefined,
                                        endDate: end
                                            ? ConvertDateLine(end)
                                            : undefined,
                                    });
                                }}
                                verticalAlign={false}
                                isDisabled={false}
                                timePicker={false}
                            />
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">물건열람횟수</span>
                        <div>
                            {viewsCountLabel.map((view, idx) => (
                                <Checkbox
                                    key={view.id}
                                    isDisabled={false}
                                    isChecked={
                                        !checkedBox &&
                                        !!(
                                            filters?.viewCountMin ===
                                            view?.viewCountMin
                                        ) &&
                                        !!(
                                            filters?.viewCountMax ===
                                            view?.viewCountMax
                                        )
                                    }
                                    label={`${view.viewCountMin}~${view?.viewCountMax}`}
                                    handleChange={() => {
                                        setFilters({
                                            ...filters,
                                            viewCountMin: view?.viewCountMin,
                                            viewCountMax: view?.viewCountMax,
                                        });
                                        setCheckBox(false);
                                        setClickedBox(false);
                                    }}
                                />
                            ))}
                            <Checkbox
                                isDisabled={false}
                                isChecked={checkedBox || clickedBox}
                                label={"직접입력"}
                                handleChange={() => {
                                    setCheckBox(true);
                                    setFilters({
                                        ...filters,
                                        viewCountMin: undefined,
                                        viewCountMax: undefined,
                                    });
                                }}
                            />
                            <Input
                                placeholder={"숫자입력"}
                                size={"small"}
                                handleChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        viewCountMin: Number(e.target.value),
                                    });
                                    setClickedBox(true);
                                }}
                                value={filters?.viewCountMin ?? ""}
                                type={"number"}
                            />
                            ~
                            <Input
                                placeholder={"숫자입력"}
                                size={"small"}
                                handleChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        viewCountMax: Number(e.target.value),
                                    });
                                    setClickedBox(true);
                                }}
                                value={filters?.viewCountMax ?? ""}
                                type={"number"}
                            />
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">검색결과갯수</span>
                        <div>
                            <Input
                                placeholder={"숫자입력"}
                                size={"small"}
                                handleChange={(e) => {
                                    if (
                                        !isNaN(Number(e.target.value)) &&
                                        Number(e.target.value) <= 500
                                    ) {
                                        setFilters({
                                            ...filters,
                                            getCount: Number(e.target.value),
                                        });
                                    } else {
                                        setFilters({
                                            ...filters,
                                            getCount: 500,
                                        });
                                    }
                                }}
                                value={filters?.getCount ?? ""}
                                type={"number"}
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default UserViewsCountFilters;

const filterWrap = css`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
        white-space: pre-wrap;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .dateBtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .dateBtn.active {
        font-weight: 700;
    }
`;
