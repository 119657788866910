import { css } from "@emotion/react";
import {
    Button,
    Calendar,
    ConfirmModal,
    DropDown,
    TextBox,
    ToggleButton,
} from "@src/components";
import { deungiDropDown } from "@src/constants/Deungi";
import {
    useDeungiNoticeSaver,
    useDeungiNoticeUpdate,
} from "@src/hooks/queries/useDeungiNotice";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface Props {}

interface IParam {
    isOpen: boolean;
    openAt: string;
    closeAt: string;
    title: string;
    category: string;
}

interface PropsState {
    closeAt: string;
    closeAtToString: string;
    isOpen: boolean;
    no: number;
    openAt: string;
    openAtToString: string;
    title: string;
    category: string;
}

const initialValue: IParam = {
    isOpen: true,
    openAt: new Date().toISOString(),
    closeAt: new Date().toISOString(),
    title: "",
    category: "deungi",
};

const DeungiNoticeForm: React.FC<Props> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const getId = useParams();
    const currentTypeString = location.pathname.includes("create")
        ? "등록하시겠습니까?"
        : "수정하시겠습니까?";
    const checkCreate = location.pathname.includes("create");

    const [param, setParam] = useState<IParam>(initialValue);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const noticeCreate = useDeungiNoticeSaver(
        () => {
            navigate(-1);
        },
        (error) => {
            console.log("err>>>", error);
            setOpenDialog(false);
        },
    );
    const noticeUpdate = useDeungiNoticeUpdate(
        () => {
            navigate(-1);
        },
        (error) => {
            console.log("err>>>", error);
            setOpenDialog(false);
        },
    );

    const onSubmitNoticeUpdate = () => {
        const currentId = getId.id;
        const newParam = { ...param, id: currentId };
        noticeUpdate.mutate(newParam);
    };

    const onClickSubmit = () => {
        checkCreate ? noticeCreate.mutate(param) : onSubmitNoticeUpdate();
    };

    useEffect(() => {
        if (location.state) {
            const { closeAt, openAt, title, isOpen, category } =
                location.state as PropsState;
            setParam({ closeAt, openAt, title, isOpen, category });
        }
    }, []);

    return (
        <div css={rootStyle}>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>노출 여부</p>
                <ToggleButton
                    name="open"
                    value={param.isOpen}
                    handleToggle={(name: string, checked: boolean) =>
                        setParam({ ...param, isOpen: checked })
                    }
                />
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>점검 대상</p>
                <div className="dropDownDiv">
                    <DropDown
                        data={deungiDropDown}
                        defaultValue={param.category}
                        border={false}
                        handleChange={(e: any) => {
                            setParam({ ...param, category: e.target.value });
                        }}
                    />
                </div>
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>점검 시작일시
                </p>
                <Calendar
                    getUserDate={(date: number) => {
                        setParam({
                            ...param,
                            openAt: new Date(date).toISOString(),
                        });
                    }}
                    setDate={new Date(param.openAt).getTime() ?? Date.now()}
                    timePicker={true}
                    timeInterval={1}
                />
                <p css={boxContentStyle}>부터 노출</p>
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>점검 종료일시
                </p>
                <Calendar
                    getUserDate={(date: number) => {
                        setParam({
                            ...param,
                            closeAt: new Date(date).toISOString(),
                        });
                    }}
                    setDate={new Date(param.closeAt).getTime() ?? Date.now()}
                    timePicker={true}
                    timeInterval={1}
                />
                <p css={boxContentStyle}>까지</p>
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>설명
                </p>
                <TextBox
                    handleChange={(value: string) => {
                        setParam({ ...param, title: value });
                    }}
                    maxLength={1000}
                    placeholder="설명을 입력해주세요"
                    size="small"
                    isDisabled={false}
                    defaultText={param.title}
                />
            </div>
            <div css={btnStyle}>
                <Button
                    color="purple"
                    handleClick={(e) => {
                        setOpenDialog(true);
                    }}
                    label={checkCreate ? "등록" : "수정"}
                    size="medium"
                    isDisabled={
                        ![param.title, param.closeAt, param.openAt].every(
                            (val: any) => val,
                        )
                    }
                />
            </div>
            <ConfirmModal
                isModalShow={openDialog}
                buttonText="확인"
                handleCancel={() => {
                    setOpenDialog(false);
                }}
                handleOk={onClickSubmit}
            >
                {currentTypeString}
            </ConfirmModal>
        </div>
    );
};

export default DeungiNoticeForm;

const rootStyle = css`
    padding: 40px;
    width: calc(100vw - 235px);
`;

const boxStyle = css`
    display: flex;
    align-items: center;
    margin: 10px 0 30px;
`;

const boxTitleStyle = css`
    font-size: 1rem;
    font-weight: 500;
    width: 10rem;
    span {
        color: #ff0000;
    }
`;

const boxContentStyle = css`
    font-size: 1rem;
    font-weight: 500;
    margin-left: 10px;
    color: #bebebe;
`;

const btnStyle = css`
    display: flex;
    justify-content: flex-end;
    padding: 40px 100px;
`;
