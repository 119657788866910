import React from "react";
import { css } from "@emotion/react";
import { IconButton, Label } from "@src/components";
import {
    SituationStatus,
    SituationStatusColor,
} from "@src/constants/SituationStatus";

export const getColumns = ({ navigate }: any) => {
    return [
        {
            Header: "상황 코드",
            accessor: "situation_code",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "120px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "발생 상황",
            accessor: "comments",
            Cell: ({ cell: { value } }: any) => <div css={maxLow}>{value}</div>,
        },
        {
            Header: "예약 시간",
            accessor: "reservation_time",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "지연 시간",
            accessor: "delay_sec",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "템플릿 코드",
            accessor: "template_code",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "120px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "상태",
            accessor: "status",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "120px" }}>
                    <Label
                        color={SituationStatusColor[value]}
                        text={SituationStatus[value]}
                    />
                </div>
            ),
        },
        {
            Header: "관리",
            accessor: "add",
            Cell: ({ row }: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "120px",
                    }}
                >
                    <IconButton
                        type="edit"
                        handleClick={() => {
                            navigate(
                                `/notify/situation/update/${row.original.no}`,
                            );
                        }}
                    />
                </div>
            ),
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ row }: any) => {
                return (
                    <div
                        style={{
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="arrow"
                            handleClick={() => {
                                navigate(
                                    `/notify/situation/detail/${row.original.no}`,
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];
};

export const searchItemTitle = ["템플릿 코드", "템플릿 이름"];

const maxLow = css`
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: -10px;
    text-align: left;
`;
