import styled from "@emotion/styled";
import { Button, EditModal, Label } from "@src/components";
import { DetailUserProps } from "@src/interfaces/User.interface";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSubscriptionInfo } from "@src/hooks/queries/useUserDetail";
import { subscriptionInfoType } from "./UserDetail";

interface UserSubscriptionInfoModalProps {
    isModalShow: boolean;
    setIsSubscriptionInfoModalShow: Dispatch<SetStateAction<boolean>>;
    setIsSubscriptionEditModalShow: Dispatch<SetStateAction<boolean>>;
    setIsSubscriptionStateEditModalShow: Dispatch<SetStateAction<boolean>>;
    user?: DetailUserProps;
    subscriptionInfo?: subscriptionInfoType;
}

const UserSubscriptionInfoModal = ({
    isModalShow,
    setIsSubscriptionInfoModalShow,
    setIsSubscriptionEditModalShow,
    setIsSubscriptionStateEditModalShow,
    user,
    subscriptionInfo,
}: UserSubscriptionInfoModalProps) => {
    return (
        <EditModal
            isModalShow={isModalShow}
            buttonText=""
            handleBtnClick={function noRefCheck() {}}
            handleOpenModal={() => setIsSubscriptionInfoModalShow(false)}
            size="big"
            title="구독 정보"
            deleteButton={true}
        >
            <>
                <StyleModalContents>
                    <StyledModalWrapper>
                        <StyledModalItem>
                            <span>구독이름</span>
                            <div className="subText">
                                {subscriptionInfo?.subs_group_name}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>결제일</span>
                            <div className="subText">
                                {subscriptionInfo?.last_paid_at}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>결제액</span>
                            <div className="subText">
                                {subscriptionInfo?.paid_price?.toLocaleString(
                                    "ko-KR",
                                )}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>환불액</span>
                            <div className="subText">
                                {subscriptionInfo?.refund_price?.toLocaleString(
                                    "ko-KR",
                                )}
                            </div>
                        </StyledModalItem>
                    </StyledModalWrapper>
                    <StyledModalWrapper>
                        <StyledModalItem>
                            <span>상품명</span>
                            <div className="subText">
                                {subscriptionInfo?.product_name}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>상태</span>
                            <div style={{ width: 80, height: 26 }}>
                                {subscriptionInfo?.cancel_reason !==
                                "(해지예약)" ? (
                                    <Label text="정상" color="purple" />
                                ) : (
                                    <Label text="해지 예약" color="gray" />
                                )}
                            </div>
                            <Button
                                color="gd"
                                handleClick={() => {
                                    // setIsSubscriptionEditModalShow(true);
                                    setIsSubscriptionInfoModalShow(false);
                                    setIsSubscriptionStateEditModalShow(true);
                                }}
                                label="상태 변경"
                                size="small"
                                styles={{ width: 89, height: 23 }}
                            />
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>만료일</span>
                            <div className="subText">
                                {subscriptionInfo?.expired_at}
                            </div>
                            <Button
                                color="gd"
                                handleClick={() => {
                                    setIsSubscriptionEditModalShow(true);
                                    setIsSubscriptionInfoModalShow(false);
                                }}
                                label="수정"
                                size="small"
                                styles={{ width: 89, height: 23 }}
                            />
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>다음 결제일</span>

                            <StyledNextPaidText
                                value={
                                    subscriptionInfo?.cancel_reason !==
                                    "(해지예약)"
                                }
                            >
                                {subscriptionInfo?.next_renew_at
                                    ? subscriptionInfo?.next_renew_at
                                    : "-"}
                            </StyledNextPaidText>
                        </StyledModalItem>
                    </StyledModalWrapper>
                </StyleModalContents>
            </>
        </EditModal>
    );
};

export default UserSubscriptionInfoModal;
const StyledModalItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 28px;
    & > span {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        width: 88px;
        height: 20px;
        display: flex;
        align-items: center;
    }
    & > .subText {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
`;

const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;
`;

const StyleModalContents = styled.div`
    display: flex;
    gap: 35px;
    width: 100%;
`;

const StyledNextPaidText = styled.div<{ value: boolean }>`
    font-size: 14px;
    font-weight: 400;

    color: ${(props) => (props.value ? "#222222;" : "#D9D9D9 ")};
`;
