/**
 * 국공유지 지역별 PNU
 */
export enum STATE_LAND_REGION_TYPE {
    ALL = 0, // 전국
    SEOUL = 11, // 서울
    GYEONGGI = 41, // 경기
    INCHEON = 28, // 인천
    BUSAN = 26, // 부산
    DAEJEON = 30, // 대전
    DAEGU = 27, // 대구
    ULSAN = 31, // 울산
    SEJONG = 36, // 세종
    GWANGJU = 29, // 광주
    GANGWON = 51, // 강원
    CHUNGCHEONBUK = 43, // 충청북도
    CHUNGCHEONNAM = 44, // 충청남도
    GYEONGSANGBUK = 47, // 경상북도
    GYEONGSANGNAM = 48, // 경상남도
    JEOLLABUK = 45, // 전라북도
    JEOLLANAM = 46, // 전라남도
    JEJU = 50, // 제주
}

/**
 * 국공유지 지역 묶음 리스트
 */

export const ROADPLAN_REGION_LIST = [
    {
        name: "전국",
        value: null,
        disable: true,
    },
    {
        name: "서울",
        value: [STATE_LAND_REGION_TYPE.SEOUL],
        disable: true,
    },
    {
        name: "경기",
        value: [STATE_LAND_REGION_TYPE.GYEONGGI],
        disable: true,
    },
    {
        name: "인천",
        value: [STATE_LAND_REGION_TYPE.INCHEON],
        disable: true,
    },
    {
        name: "제주",
        value: [STATE_LAND_REGION_TYPE.JEJU],
        disable: false,
    },
    {
        name: "대전",
        value: [STATE_LAND_REGION_TYPE.DAEJEON],
        disable: true,
    },
    {
        name: "충청",
        value: [
            STATE_LAND_REGION_TYPE.CHUNGCHEONBUK,
            STATE_LAND_REGION_TYPE.CHUNGCHEONNAM,
            STATE_LAND_REGION_TYPE.SEJONG,
        ],
        disable: true,
    },
    {
        name: "광주",
        value: [STATE_LAND_REGION_TYPE.GWANGJU],
        disable: true,
    },
    {
        name: "전라",
        value: [STATE_LAND_REGION_TYPE.JEOLLABUK, STATE_LAND_REGION_TYPE.JEOLLANAM],
        disable: true,
    },
    {
        name: "강원",
        disable: true,
        value: [STATE_LAND_REGION_TYPE.GANGWON],
    },
    {
        name: "대구",
        value: [STATE_LAND_REGION_TYPE.DAEGU],
        disable: true,
    },
    {
        name: "경북",
        value: [STATE_LAND_REGION_TYPE.GYEONGSANGBUK],
        disable: true,
    },
    {
        name: "경남",
        value: [STATE_LAND_REGION_TYPE.GYEONGSANGNAM],
        disable: true,
    },
    {
        name: "부산",
        value: [STATE_LAND_REGION_TYPE.BUSAN],
        disable: true,
    },
    {
        name: "울산",
        value: [STATE_LAND_REGION_TYPE.ULSAN],
        disable: true,
    },
];
export const REGION_LIST = [
    {
        name: "전국",
        value: null,
        disable: false,
    },
    {
        name: "서울",
        value: [STATE_LAND_REGION_TYPE.SEOUL],
        disable: false,
    },
    {
        name: "경기",
        value: [STATE_LAND_REGION_TYPE.GYEONGGI],
        disable: false,
    },
    {
        name: "인천",
        value: [STATE_LAND_REGION_TYPE.INCHEON],
        disable: false,
    },
    {
        name: "제주",
        value: [STATE_LAND_REGION_TYPE.JEJU],
        disable: false,
    },
    {
        name: "대전",
        value: [STATE_LAND_REGION_TYPE.DAEJEON],
        disable: false,
    },
    {
        name: "충청",
        value: [
            STATE_LAND_REGION_TYPE.CHUNGCHEONBUK,
            STATE_LAND_REGION_TYPE.CHUNGCHEONNAM,
            STATE_LAND_REGION_TYPE.SEJONG,
        ],
        disable: false,
    },
    {
        name: "광주",
        value: [STATE_LAND_REGION_TYPE.GWANGJU],
        disable: false,
    },
    {
        name: "전라",
        value: [STATE_LAND_REGION_TYPE.JEOLLABUK, STATE_LAND_REGION_TYPE.JEOLLANAM],
        disable: false,
    },
    {
        name: "강원",
        disable: false,
        value: [STATE_LAND_REGION_TYPE.GANGWON],
    },
    {
        name: "대구",
        value: [STATE_LAND_REGION_TYPE.DAEGU],
        disable: false,
    },
    {
        name: "경북",
        value: [STATE_LAND_REGION_TYPE.GYEONGSANGBUK],
        disable: false,
    },
    {
        name: "경남",
        value: [STATE_LAND_REGION_TYPE.GYEONGSANGNAM],
        disable: false,
    },
    {
        name: "부산",
        value: [STATE_LAND_REGION_TYPE.BUSAN],
        disable: false,
    },
    {
        name: "울산",
        value: [STATE_LAND_REGION_TYPE.ULSAN],
        disable: false,
    },
];
