import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input } from "@src/components";
import { useSelector } from "react-redux";
import { ConvertPhoneNumber, ConvertPrice } from "@src/utils/units";
import {
    validEmptyValueCheck,
    validNumberCheck,
} from "@src/utils/validationCheck";
import { INVALID_VALUE } from "@src/constants/InvalidValue";

function BuildingInformationContainer({ isUpdate = false, handleUpdate }: any) {
    const [buildingInfo, setBuildingInfo] = useState<any>({});
    let defaultInfo = useSelector((state: any) => state.buildingInfo);
    useEffect(() => {
        let newDefaultInfo = { ...defaultInfo };
        for (let value in newDefaultInfo) {
            if (validEmptyValueCheck(newDefaultInfo[value])) {
                newDefaultInfo[value] = INVALID_VALUE;
                setBuildingInfo({ ...newDefaultInfo });
            } else {
                setBuildingInfo({ ...newDefaultInfo });
            }
        }
    }, [defaultInfo]);
    const {
        constructionCompany,
        totalNumberOfBuildings,
        totalNumberOfHouseholds,
        highestFloor,
        lowestFloor,
        totalParkingNumber,
        managementOfficeNumber,
        heatingSystem,
        heatingFuel,
        areaType,
    }: any = buildingInfo;

    useEffect(() => {
        setBuildingInfo({
            constructionCompany: defaultInfo.constructionCompany,
            totalNumberOfBuildings: defaultInfo.totalNumberOfBuildings,
            totalNumberOfHouseholds: defaultInfo.totalNumberOfHouseholds,
            highestFloor: defaultInfo.highestFloor,
            lowestFloor: defaultInfo.lowestFloor,
            totalParkingNumber: defaultInfo.totalParkingNumber,
            managementOfficeNumber: defaultInfo.managementOfficeNumber,
            heatingSystem: defaultInfo.heatingSystem,
            heatingFuel: defaultInfo.heatingFuel,
            areaType: defaultInfo.areaType,
        });
    }, [defaultInfo]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setBuildingInfo((buildingInfo: any) => ({
            ...buildingInfo,
            [name]: value.replace(INVALID_VALUE, ""),
        }));
    };
    const onNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let [validCheckResult, validCheckValue] = validNumberCheck(value);
        if (validCheckResult) {
            setBuildingInfo({
                ...buildingInfo,
                [name]: validCheckValue,
            });
        }
    };
    const convertNumber = (value: any) => {
        let result;
        if (value === INVALID_VALUE || validEmptyValueCheck(value)) {
            result = 0;
        } else {
            result = ConvertPrice(+value);
        }
        return result;
    };
    useEffect(() => {
        if (handleUpdate !== undefined) handleUpdate(buildingInfo);
    }, [buildingInfo]);
    return (
        <ul css={buildingInfoUl}>
            <li>
                <Input
                    value={constructionCompany || ""}
                    name="constructionCompany"
                    label="건설사"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={
                        isUpdate
                            ? totalNumberOfBuildings || ""
                            : convertNumber(totalNumberOfBuildings) || ""
                    }
                    name="totalNumberOfBuildings"
                    label="총 동수(개동)"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onNumberChange}
                />
            </li>
            <li>
                <Input
                    value={
                        isUpdate
                            ? totalNumberOfHouseholds || ""
                            : convertNumber(totalNumberOfHouseholds) || ""
                    }
                    name="totalNumberOfHouseholds"
                    label="총 세대수(세대)"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onNumberChange}
                />
            </li>
            <li>
                <Input
                    value={
                        isUpdate
                            ? highestFloor || ""
                            : convertNumber(highestFloor) || ""
                    }
                    name="highestFloor"
                    label="최고층(층)"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onNumberChange}
                />
            </li>
            <li>
                <Input
                    value={
                        isUpdate
                            ? lowestFloor || ""
                            : convertNumber(lowestFloor) || ""
                    }
                    name="lowestFloor"
                    label="최저층(층)"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onNumberChange}
                />
            </li>
            <li>
                <Input
                    value={
                        isUpdate
                            ? totalParkingNumber || ""
                            : convertNumber(totalParkingNumber) || ""
                    }
                    name="totalParkingNumber"
                    label="총 주차대수(대)"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onNumberChange}
                />
            </li>
            <li>
                <Input
                    value={
                        isUpdate
                            ? managementOfficeNumber || ""
                            : ConvertPhoneNumber(
                                  defaultInfo.managementOfficeNumber,
                              ) || ""
                    }
                    name="managementOfficeNumber"
                    label="관리소"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={heatingSystem || ""}
                    name="heatingSystem"
                    label="난방방식"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={heatingFuel || ""}
                    name="heatingFuel"
                    label="난방연료"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={areaType || ""}
                    name="areaType"
                    label="면적유형"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
        </ul>
    );
}

const buildingInfoUl = css`
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    input {
        max-width: 300px;
    }
    input:disabled {
        background-color: transparent;
        border-bottom: none;
        color: ${Common.colors.black};
    }
    ${Common.textStyle.text1_R13}
    span {
        display: inline-block;
        width: 120px;
        color: ${Common.colors.gray500};
    }
    li {
        width: 30%;
        display: flex;
        align-items: center;
        margin-right: 40px;
    }
`;

export default BuildingInformationContainer;
