import styled from "@emotion/styled";
import { Button, Checkbox, DropDown, RangeCalendar } from "@src/components";
import { DeungiListDto } from "@src/interfaces/Deungi.interface";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ConvertDate } from "@src/utils/dateUtil";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";

interface DeungiListFilterProps {
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<any | unknown>>;
    // filter: DeungiListDto;
    setFilter: Dispatch<SetStateAction<DeungiListDto>>;
    initParams: any;
}

const DeungiListFilter = ({
    refetch,
    // filter,
    setFilter,
    initParams,
}: DeungiListFilterProps) => {
    const handleCheckBoxChange = (
        name: string,
        checked: boolean,
        checkList: any,
    ) => {
        let setCheckedList = {
            ...checkList,
            [name]: checked,
        };

        const statusType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedList, statusType };
    };

    const initCrawlingChecked = {
        신건크롤링: false,
        열람권사용: false,
        기존열람권사용: false,
    };
    const initDeungiState = {
        대기중: false,
        결제진행: false,
        결제완료: false,
        열람진행: false,
        재열람: false,
        열람완료: false,
        결제실패: false,
        열람실패: false,
        재열람실패: false,
        열람취소: false,
    };

    const [isDeungiChecked, setIsDeungiChecked] = useState(initDeungiState);
    const [deungiState, setDeungiState] = useState<string[]>([]);
    const [isCrawlingChecked, setIsCrawlingChecked] =
        useState(initCrawlingChecked);
    const [crawlingType, setCrawlingType] = useState<string[]>([]);
    const [today, setToday] = useState<any>(new Date().getTime());
    const [startDay, setStartDay] = useState<any>();
    const [endDay, setEndDay] = useState<any>();
    const [searchType, setSearchType] = useState<string>("name");
    const [searchText, setSearchText] = useState<string>("");
    const [textFilter, setTextFilter] = useState<any>();

    const date = new Date();

    const year = date.getFullYear(); // 년
    const month = date.getMonth(); // 월
    const day = date.getDate(); // 일

    useEffect(() => {
        setTextFilter({
            [searchType]:
                searchType === "ticketNo" ? Number(searchText) : searchText,
        });
    }, [searchType, searchText]);

    useEffect(() => {
        setSearchText("");
    }, [searchType]);

    const handleSearch = () => {
        setFilter({
            page: 1,
            deungiState: deungiState,
            crawlingType: crawlingType,
            startAt: startDay,
            endAt: endDay,
            ...textFilter,
        });
    };
    const handleClear = () => {
        setIsDeungiChecked(initDeungiState);
        setDeungiState([]);
        setIsCrawlingChecked(initCrawlingChecked);
        setCrawlingType([]);
        setStartDay(null);
        setEndDay(null);
        setSearchType("name");
        setSearchText("");
        setTextFilter(null);
    };
    return (
        <>
            <StyledCommodityFilter>
                <StyledFilterBox>
                    <div className="itemRow">
                        <span>열람일시</span>
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay("");
                                setEndDay("");
                            }}
                            label="전체"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(ConvertDate(today));
                                setEndDay(null);
                            }}
                            label="오늘"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year,
                                            month,
                                            day - 7,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="7일"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year,
                                            month - 1,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="1개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year,
                                            month - 6,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="6개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <Button
                            color="white"
                            handleClick={() => {
                                setStartDay(
                                    ConvertDate(
                                        new Date(
                                            year - 1,
                                            month,
                                            day,
                                        ).getTime(),
                                    ),
                                );
                                setEndDay(ConvertDate(today));
                            }}
                            label="12개월"
                            size="medium"
                            styles={{
                                width: "50px",
                                minWidth: "50px",
                                height: "30px",
                            }}
                        />
                        <RangeCalendar
                            getUserRangeDate={(date) => {
                                const { startDate: start, endDate: end } = date;
                                setStartDay(start ? ConvertDate(start) : "");
                                setEndDay(end ? ConvertDate(end) : "");
                            }}
                            setRangeDate={{
                                startDate: startDay,
                                endDate: endDay,
                            }}
                            isDisabled={false}
                            timePicker={false}
                        />
                    </div>
                    <div className="itemRow">
                        <span>열람상태</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["대기중"]}
                                label="대기중"
                                defaultValue={"대기중"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["결제진행"]}
                                label="결제 진행"
                                defaultValue={"결제진행"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setIsDeungiChecked(setCheckedList);
                                    setDeungiState(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["결제완료"]}
                                label="결제 완료"
                                defaultValue={"결제완료"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["열람진행"]}
                                label="열람 진행"
                                defaultValue={"열람진행"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["재열람"]}
                                label="재열람"
                                defaultValue={"재열람"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["열람완료"]}
                                label="열람 완료"
                                defaultValue={"열람완료"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["결제실패"]}
                                label="결제 실패"
                                defaultValue={"결제실패"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );
                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["열람실패"]}
                                label="열람 실패"
                                defaultValue={"열람실패"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );

                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["재열람실패"]}
                                label="재열람 실패"
                                defaultValue={"재열람실패"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );

                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isDeungiChecked["열람취소"]}
                                label="열람 취소"
                                defaultValue={"열람취소"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isDeungiChecked,
                                        );

                                    setDeungiState(statusType);
                                    setIsDeungiChecked(setCheckedList);
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>종류</span>
                        <div className="checkBoxDiv">
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCrawlingChecked["신건크롤링"]}
                                label="신건 크롤링"
                                defaultValue={"신건크롤링"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCrawlingChecked,
                                        );
                                    setIsCrawlingChecked(setCheckedList);
                                    setCrawlingType(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCrawlingChecked["열람권사용"]}
                                label="실시간 등기열람권 사용"
                                defaultValue={"열람권사용"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCrawlingChecked,
                                        );
                                    setIsCrawlingChecked(setCheckedList);
                                    setCrawlingType(statusType);
                                }}
                            />
                            <Checkbox
                                isDisabled={false}
                                isChecked={isCrawlingChecked["기존열람권사용"]}
                                label="기존 등기열람권 사용"
                                defaultValue={"기존열람권사용"}
                                handleChange={(n, v) => {
                                    const { setCheckedList, statusType } =
                                        handleCheckBoxChange(
                                            n,
                                            v,
                                            isCrawlingChecked,
                                        );
                                    setIsCrawlingChecked(setCheckedList);
                                    setCrawlingType(statusType);
                                }}
                            />
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>검색</span>
                        <div className="dropDown">
                            <DropDown
                                border
                                data={[
                                    {
                                        id: 1,
                                        key: "name",
                                        value: "이름",
                                    },
                                    {
                                        id: 2,
                                        key: "email",
                                        value: "이메일",
                                    },
                                    {
                                        id: 3,
                                        key: "address",
                                        value: "소재지",
                                    },
                                    {
                                        id: 4,
                                        key: "pin",
                                        value: "부동산고유번호",
                                    },
                                    {
                                        id: 5,
                                        key: "ticketNo",
                                        value: "열람권 번호",
                                    },
                                ]}
                                defaultValue=""
                                handleChange={(e, text, value) => {
                                    value && setSearchType(value);
                                }}
                            />
                        </div>
                        <StyledReasonWrapper>
                            <input
                                type="text"
                                placeholder="상품코드나 상품명, 구독이름, 상품설명을  입력해주새요 "
                                onChange={(e: any) => {
                                    setSearchText(e.target.value);
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={searchText}
                            />
                        </StyledReasonWrapper>
                    </div>
                </StyledFilterBox>
                <StyledSearchButton>
                    <Button
                        color="gray"
                        handleClick={() => {
                            handleClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            handleSearch();
                        }}
                        label="검색"
                        size="medium"
                    />
                </StyledSearchButton>
            </StyledCommodityFilter>
        </>
    );
};

export default DeungiListFilter;

const StyledCommodityFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    margin-bottom: 32px;
`;

const StyledFilterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: fit-content;
    background-color: #f4f5f7;
    border-radius: 10px;
    padding: 10px 24px 24px 24px;
    margin-top: 20px;
    & > .itemRow {
        display: flex;
        gap: 16px;
        align-items: center;
        box-sizing: border-box;
        & > span {
            width: 63px;
            font-weight: 400;
            font-size: 13px;
            color: #222222;
            letter-spacing: -0.4px;
        }
        & > .checkBoxDiv {
            display: flex;
            gap: 12px;
            align-items: center;
        }
        & > .dropDown {
            height: 40px;
            & > div {
                height: 100%;
                & > label {
                    & > select {
                        height: 100%;
                    }
                }
            }
        }
    }
`;

const StyledReasonWrapper = styled.div`
    width: 648px;
    height: 40px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledSearchButton = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
`;
