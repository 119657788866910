import React, { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Label } from "@src/components";
import { UserStatus, ExpertStatus, SubscriptionStatus } from "@src/constants/UserStatus";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { useDeleteProvision } from "@src/hooks/queries/useCommodityProvision";
import { label } from "@src/components/panel/Label.stories";
import { useDeleteProvisionUser, useGetProvisionUserList } from "@src/hooks/queries/useProvisionUser";
import { ProvisionUser } from "@src/interfaces/Commodity.interface";
export const getUserColumns = (
    navigate: any,
    setProvisionUserList: Dispatch<SetStateAction<ProvisionUser[]>>,
    setDeleteUserData: Dispatch<SetStateAction<{ no: number; issueNo: number }>>,
    setIsDeleteUserShow: Dispatch<SetStateAction<boolean>>,
    isVeiwModal: boolean,
) => [
    {
        Header: "번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "100%",
                    minWidth: "50px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "회원 번호",
        accessor: "userNo",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ minWidth: "80px", width: "100%", textAlign: "center" }}>{value ? value : INVALID_VALUE}</div>
        ),
    },
    {
        Header: "이름",
        accessor: "uname",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "80px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "입력 정보",
        accessor: "toGo",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        minWidth: "180px",
                        width: "100%",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "상태",
        accessor: "status",
        Cell: ({ cell: { value }, row }: any) => {
            const userNo = row.values.userNo;
            const comments = row.values.comments;
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "80%",
                        textAlign: "center",
                    }}
                >
                    {userNo ? (
                        <>
                            {value === 200 && <Label text="지급대기" color="purpleBorder" />}
                            {value === 202 && comments !== "이미 구독중인 상품이 있습니다." && (
                                <Label text="지급실패" color="orange" />
                            )}
                            {value === 202 && comments === "이미 구독중인 상품이 있습니다." && (
                                <Label text="기구독" color="purpleBorder" />
                            )}
                            {value === 300 && <Label text="지급완료" color="purple" />}
                            {value === 400 && <Label text="지급취소" color="expired" />}
                        </>
                    ) : (
                        <Label text="찾을 수 없음" color="lightGray" />
                    )}
                </div>
            );
        },
    },
    {
        Header: "삭제",
        accessor: "delete",
        Cell: ({ cell: { value }, row }: any) => {
            const issueNo = row.values.issueNo;
            const userNo = row.values.userNo;
            const status = row.values.status;
            const comments = row.values.comments;
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        type="trash"
                        handleClick={() => {
                            setDeleteUserData({
                                no: value,
                                issueNo: issueNo,
                            });
                            setIsDeleteUserShow(true);
                        }}
                        isDisabled={
                            !(
                                !userNo ||
                                (status !== 202 && status !== 300) ||
                                comments === "이미 구독중인 상품이 있습니다."
                            ) || isVeiwModal
                        }
                    />
                </div>
            );
        },
    },
];
