import styled from "@emotion/styled";
import { Button, Checkbox, DropDown, RangeCalendar } from "@src/components";
import { DeungiListDto } from "@src/interfaces/Deungi.interface";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ConvertDate } from "@src/utils/dateUtil";

import { PartnerListDto } from "@src/interfaces/Partner.interface";
import { useLocation } from "react-router-dom";

interface DeungiListFilterProps {
    filter: PartnerListDto;
    setFilter: Dispatch<SetStateAction<PartnerListDto>>;
}

const PartnerListFilter = ({ filter, setFilter }: DeungiListFilterProps) => {
    const handleCheckBoxChange = (name: string, checked: boolean, checkList: any) => {
        let setCheckedList = {
            ...checkList,
            [name]: checked,
        };

        const statusType = Object.entries(setCheckedList)
            .filter(([_, checked]) => checked === true)
            .map(([name, _]) => name);
        return { setCheckedList, statusType };
    };

    const initPartnerState: any = {
        request: false,
        complete: false,
        정산대기: false,
        정산완료: false,
        cancel: false,
        reject: false,
    };
    const initUserState: any = {
        0: false,
        1: false,
        101: false,
        102: false,
        200: false,
    };
    const initPartnerStatusState = {
        0: false,
        1: false,
    };
    const initSubscriptionStatusState = {
        free: false,
        paid: false,
        none: false,
    };
    const initParams = {
        page: 1,
        limit: 10,
        searchColumn: "userInfo.uname",
    };

    const [isUserChecked, setIsUserChecked] = useState<any>(initUserState);
    const [isPartnerState, setIsPartnerState] = useState<any>(initPartnerState);
    const [isPartnerStatusState, setIsPartnerStatusState] = useState(initPartnerStatusState);
    const [isSubscriptionStatusState, setIsSubscriptionStatusState] = useState(initSubscriptionStatusState);
    const [userState, setUserState] = useState<string[]>([]);
    const [isPartnerChecked, setIsPartnerChecked] = useState<string[]>([]);
    const [today, setToday] = useState<any>(new Date().getTime());
    const [startDay, setStartDay] = useState<any>();
    const [endDay, setEndDay] = useState<any>();
    const [searchType, setSearchType] = useState<string>("userInfo.uname");
    const [searchText, setSearchText] = useState<string>("");
    const [textFilter, setTextFilter] = useState<any>();

    const date = new Date();

    const year = date.getFullYear(); // 년
    const month = date.getMonth(); // 월
    const day = date.getDate(); // 일

    useEffect(() => {
        setSearchText("");
    }, [searchType]);

    const handleSearch = () => {
        console.log("1234");
        const newUserState = userState.map((item) => Number(item));
        const newPartnerType = Object.entries(isPartnerStatusState).filter(([_, checked]) => checked === true);
        const newSubscriptionStatues = Object.entries(isSubscriptionStatusState).filter(
            ([_, checked]) => checked === true,
        );
        setFilter({
            page: 1,
            limit: 10,
            userStatuses: newUserState.length > 0 ? newUserState : undefined,
            partnerStatuses: isPartnerChecked.length > 0 ? isPartnerChecked : undefined,
            requestStartedAt: startDay || undefined,
            requestEndedAt: endDay || undefined,
            searchColumn: searchType,
            searchValue: searchText,
            partnerType: newPartnerType.length > 0 ? newPartnerType.map(([name, _]) => name) : undefined,
            subscriptionStatuses:
                newSubscriptionStatues.length > 0 ? newSubscriptionStatues.map(([name, _]) => name) : undefined,
        });
    };
    const handleClear = () => {
        setIsUserChecked(initUserState);
        setUserState([]);
        setIsPartnerState(initPartnerState);
        setIsPartnerChecked([]);
        setStartDay(null);
        setEndDay(null);
        setSearchType("userInfo.uname");
        setSearchText("");
        setTextFilter(null);
        setFilter(initParams);
    };

    const dateButtonList = [
        {
            label: "전체",
            func: () => {
                setStartDay("");
                setEndDay("");
            },
            style: {
                width: "50px",
                minWidth: "50px",
                height: "30px",
            },
        },
        {
            label: "오늘",
            func: () => {
                setStartDay(ConvertDate(today));
                setEndDay(null);
            },
            style: {
                width: "50px",
                minWidth: "50px",
                height: "30px",
            },
        },
        {
            label: "7일",
            func: () => {
                setStartDay(ConvertDate(new Date(year, month, day - 7).getTime()));
                setEndDay(ConvertDate(today));
            },
            style: {
                width: "50px",
                minWidth: "50px",
                height: "30px",
            },
        },
        {
            label: "1개월",
            func: () => {
                setStartDay(ConvertDate(new Date(year, month - 1, day).getTime()));
                setEndDay(ConvertDate(today));
            },
            style: {
                width: "50px",
                minWidth: "50px",
                height: "30px",
            },
        },
        {
            label: "6개월",
            func: () => {
                setStartDay(ConvertDate(new Date(year, month - 6, day).getTime()));
                setEndDay(ConvertDate(today));
            },
            style: {
                width: "50px",
                minWidth: "50px",
                height: "30px",
            },
        },
        {
            label: "12개월",
            func: () => {
                setStartDay(ConvertDate(new Date(year - 1, month, day).getTime()));
                setEndDay(ConvertDate(today));
            },
            style: {
                width: "50px",
                minWidth: "50px",
                height: "30px",
            },
        },
    ];

    const userStatusFunc = (n: string, v: boolean) => {
        const { setCheckedList, statusType } = handleCheckBoxChange(n, v, isUserChecked);
        setUserState(statusType);
        setIsUserChecked(setCheckedList);
    };

    const partnerStatusFuc = (n: string, v: boolean) => {
        const { setCheckedList, statusType } = handleCheckBoxChange(n, v, isPartnerState);
        setIsPartnerState(setCheckedList);
        setIsPartnerChecked(statusType);
    };

    const partnerTypeFunc = (n: string, v: boolean) => {
        const { setCheckedList } = handleCheckBoxChange(n, v, isPartnerStatusState);
        setIsPartnerStatusState(setCheckedList);
    };

    const subscriptionStatusFunc = (n: string, v: boolean) => {
        const { setCheckedList } = handleCheckBoxChange(n, v, isSubscriptionStatusState);
        setIsSubscriptionStatusState(setCheckedList);
    };

    const userStatusList = [
        {
            label: "정상",
            value: "200",
        },
        {
            label: "정지",
            value: "102",
        },
        {
            label: "휴먼",
            value: "101",
        },
        {
            label: "탈퇴",
            value: "0",
        },
        {
            label: "탈퇴(가입제한)",
            value: "1",
        },
    ];

    const partnerStatusList = [
        {
            label: "파트너 신청",
            value: "request",
        },
        {
            label: "승인완료",
            value: "complete",
        },
        {
            label: "정산 대기",
            value: "정산대기",
        },
        {
            label: "정산 완료",
            value: "정산완료",
        },
        {
            label: "파트너 취소",
            value: "cancel",
        },
        {
            label: "신청 반려",
            value: "reject",
        },
    ];

    const partnerTypeList: {
        label: string;
        value: "0" | "1";
    }[] = [
        {
            label: "일반회원",
            value: "0",
        },
        {
            label: "임직원",
            value: "1",
        },
    ];

    const subscriptionStatusList: {
        label: string;
        value: "free" | "paid" | "none";
    }[] = [
        {
            label: "구독중-무료",
            value: "free",
        },
        {
            label: "구독중-유료",
            value: "paid",
        },
        {
            label: "미구독",
            value: "none",
        },
    ];

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        const searchColumn = queryParams.get("searchColumn");
        const requestStartedAt = queryParams.get("requestStartedAt");
        const requestEndedAt = queryParams.get("requestEndedAt");

        const searchValue = queryParams.get("searchValue");
        const userStatuses = queryParams.getAll("userStatuses");
        const partnerStatuses = queryParams.getAll("partnerStatuses");
        const subscriptionStatuses = queryParams.getAll("subscriptionStatuses");
        const partnerType = queryParams.getAll("partnerType");

        if (requestStartedAt) setStartDay(requestStartedAt);
        if (requestEndedAt) setEndDay(requestEndedAt);
        if (searchColumn) setSearchType(searchColumn);
        if (searchValue) setSearchText(searchValue);
        if (userStatuses && userStatuses.length > 0) {
            userStatuses.forEach((key) => {
                userStatusFunc(key.toString(), true);
            });
        }
        if (partnerStatuses && partnerStatuses.length > 0) {
            partnerStatuses.forEach((key) => {
                partnerStatusFuc(key, true);
            });
        }
        if (partnerType && partnerType.length > 0) {
            partnerType.forEach((key) => {
                partnerTypeFunc(key, true);
            });
        }
        if (subscriptionStatuses && subscriptionStatuses.length > 0) {
            subscriptionStatuses.forEach((key) => {
                subscriptionStatusFunc(key, true);
            });
        }
    }, []);

    return (
        <>
            <StyledPartnerFilter>
                <StyledFilterBox>
                    <div className="itemRow">
                        <span>신청일시</span>
                        {dateButtonList?.map((date) => (
                            <Button
                                key={date.label}
                                color="white"
                                handleClick={() => date.func()}
                                label={date.label}
                                size="medium"
                                styles={date.style}
                            />
                        ))}
                        <RangeCalendar
                            getUserRangeDate={(date) => {
                                const { startDate: start, endDate: end } = date;
                                setStartDay(start ? ConvertDate(start) : "");
                                setEndDay(end ? ConvertDate(end) : "");
                            }}
                            setRangeDate={{
                                startDate: startDay,
                                endDate: endDay,
                            }}
                            isDisabled={false}
                            timePicker={false}
                        />
                    </div>
                    <div className="itemRow">
                        <span>회원상태</span>
                        <div className="checkBoxDiv">
                            {userStatusList.map((status) => (
                                <Checkbox
                                    key={status.label}
                                    isDisabled={false}
                                    isChecked={isUserChecked[status.value]}
                                    label={status.label}
                                    defaultValue={status.value}
                                    handleChange={(n, v) => userStatusFunc(n, v)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>파트너상태</span>
                        <div className="checkBoxDiv">
                            {partnerStatusList.map((type) => (
                                <Checkbox
                                    key={type.label}
                                    isDisabled={false}
                                    isChecked={isPartnerState[type.value]}
                                    label={type.label}
                                    defaultValue={type.value}
                                    handleChange={(n, v) => partnerStatusFuc(n, v)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>파트너타입</span>
                        <div className="checkBoxDiv">
                            {partnerTypeList.map((type) => (
                                <Checkbox
                                    key={type.label}
                                    isDisabled={false}
                                    isChecked={isPartnerStatusState[type.value]}
                                    label={type.label}
                                    defaultValue={type.value}
                                    handleChange={(n, v) => partnerTypeFunc(n, v)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>구독상태</span>
                        <div className="checkBoxDiv">
                            {subscriptionStatusList.map((type) => (
                                <Checkbox
                                    key={type.label}
                                    isDisabled={false}
                                    isChecked={isSubscriptionStatusState[type.value]}
                                    label={type.label}
                                    defaultValue={type.value}
                                    handleChange={(n, v) => subscriptionStatusFunc(n, v)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="itemRow">
                        <span>검색</span>
                        <div className="dropDown">
                            <DropDown
                                border
                                data={[
                                    {
                                        id: 1,
                                        key: "userInfo.name",
                                        value: "이름",
                                    },
                                    {
                                        id: 2,
                                        key: "user.id",
                                        value: "이메일",
                                    },
                                    {
                                        id: 3,
                                        key: "userInfo.phone",
                                        value: "연락처",
                                    },
                                    {
                                        id: 4,
                                        key: "userPaybackPartner.name",
                                        value: "닉네임",
                                    },
                                ]}
                                defaultValue=""
                                handleChange={(e, text, value) => {
                                    value && setSearchType(value);
                                }}
                            />
                        </div>
                        <StyledReasonWrapper>
                            <input
                                type="text"
                                placeholder="이름이나 이메일, 연락처, 닉네임을  입력해주세요  "
                                onChange={(e: any) => {
                                    setSearchText(e.target.value);
                                }}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                                value={searchText}
                            />
                        </StyledReasonWrapper>
                    </div>
                </StyledFilterBox>
                <StyledSearchButton>
                    <Button
                        color="gray"
                        handleClick={() => {
                            handleClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            handleSearch();
                        }}
                        label="검색"
                        size="medium"
                    />
                </StyledSearchButton>
            </StyledPartnerFilter>
        </>
    );
};

export default PartnerListFilter;

const StyledPartnerFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 23px;
    width: 100%;
    margin-bottom: 32px;
`;

const StyledFilterBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: fit-content;
    background-color: #f4f5f7;
    border-radius: 10px;
    padding: 10px 24px 24px 24px;
    margin-top: 20px;
    & > .itemRow {
        display: flex;
        gap: 16px;
        align-items: center;
        box-sizing: border-box;
        & > span {
            width: 63px;
            font-weight: 400;
            font-size: 13px;
            color: #222222;
            letter-spacing: -0.4px;
        }
        & > .checkBoxDiv {
            display: flex;
            gap: 12px;
            align-items: center;
        }
        & > .dropDown {
            height: 40px;
            & > div {
                height: 100%;
                & > label {
                    & > select {
                        height: 100%;
                    }
                }
            }
        }
    }
`;

const StyledReasonWrapper = styled.div`
    width: 648px;
    height: 40px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledSearchButton = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: flex-end;
`;
