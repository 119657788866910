import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import {
    BaseUser,
    ListUserProps,
    FilterUserProps,
    ReadUserListDto,
} from "@src/interfaces/User.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import { queryList } from "./queryList";
import { CommodityFilterType } from "@src/pages/commodity/CommodityFilter";
import {
    BaseCommodity,
    CommodityList,
    ReadCommodityListDto,
} from "@src/interfaces/Commodity.interface";
import { CommodityDataType } from "@src/pages/commodity/CommodityCreateContents";

const fetchData = async (params: CommodityFilterType) => {
    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");
    const url = `${axiosPath.commodity}/products?${queryString}`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseCommodity[] = responseData.data.list;
    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 &&
            queryList.setTotalCount(responseData.data.totalCnt);
    } else {
        queryList.setTotalCount(responseData.data.totalCnt);
    }

    return queryList.getData(parse);
};

const parse = (originData: BaseCommodity[]) => {
    const newData: CommodityList[] = [];

    originData.forEach((item, index) => {
        const {
            no,
            code,
            subs_group_name,
            name,
            description,
            created_at,
            updated_at,
            sale_type,
            status,
        } = item;

        newData.push({
            no: no,
            code: code,
            subsGroupName: subs_group_name,
            name: name,
            description: description,
            createdAt: created_at,
            updatedAt: updated_at,
            saleType: sale_type,
            status: status,
            viewMore: no,
        });
    });
    return newData;
};

const changeFormat = (date: string) => {
    return date.replaceAll(".", "-");
};
///
const getQueryString = (searchParams: CommodityFilterType) => {
    let queryParams: any = {};
    const {
        startDate,
        endDate,

        limit,
        saleTypes,
        page,
        q,
    } = searchParams;

    const createParams: ReadCommodityListDto = {
        sale_types: saleTypes,
        "created_dt[start]": !startDate ? undefined : changeFormat(startDate),
        "created_dt[end]": !endDate
            ? undefined
            : changeFormat(`${endDate} ${"23:59:59"}`),

        limit: Number(limit),
        page: Number(page),
        q,
    };

    Object.keys(createParams).forEach((value: any) => {
        let name = value as keyof ReadCommodityListDto;
        if (createParams[name]) {
            queryParams[name] = createParams[name];
        }
    });
    return queryParams;
};
const useCommodityList = (
    filters: CommodityFilterType,
    enabled: boolean = true,
) => {
    const queryParams = getQueryString(filters);

    return useQuery(
        [queryKeys.COMMODITY_LIST, queryParams],
        () => fetchData(queryParams),
        {
            enabled,
        },
    );
};
const TODAY = ConvertDate(new Date().getTime());

const initFilters: CommodityFilterType = {
    startDate: "",
    endDate: "",
    page: 1,
    limit: 10,
    saleTypes: "",
    q: "",
};
const dropList = [
    {
        id: 1,
        key: "userName",
        value: "이름",
    },
    {
        id: 2,
        key: "userPhoneNumber",
        value: "연락처",
    },
    {
        id: 3,
        key: "userEmail",
        value: "이메일",
    },
];
// const usePushReceivingUserList = ({ limit, to_go }: FilterUserProps) => {
//     const queryParams = { limit, to_go };
//     return useQuery(
//         [queryKeys.PUSH_RECEIVING_USER_LIST, queryParams],
//         () => fetchData(queryParams),
//         {
//             // initialData: initData,
//         },
//     );
// };

const useUserNoSearch = (params: {
    q?: string;
    enabled: boolean;
}): {
    parseData: any[];
    isLoading: boolean;
    refetch: () => void;
    isRefetching: boolean;
} => {
    const {
        isLoading,
        data = { result: [], total: 0 },
        refetch,
        isRefetching,
    } = useCommodityList(params, params.enabled);

    const originData = data.result;
    let parseData: any[] = [];
    originData.forEach((item: any) => {
        const { userName, userEmail, userPhoneNumber, userNo } = item;
        parseData.push({
            userName,
            userEmail,
            userPhoneNumber,
            userNo,
        });
    });
    return { parseData, isLoading, refetch, isRefetching };
};

const useGetCommodityCode = async (no: string) => {
    const url = `${axiosPath.nestProducts}/${no}`;
    const response = await axiosInstance.get(url);
    return response;
};

const useGetCheckCommodityCode = async (code: string) => {
    const url = `${axiosPath.nestProducts}/${code}/is-duplicated`;
    const response = await axiosInstance.get(url);
    return response;
};

const useGetConnectedProducts = async () => {
    const url = `${axiosPath.commodity}/products/connected`;
    const response = await axiosInstance.get(url);

    return response;
};

const useTryCreateProduct = async (data: CommodityDataType) => {
    const url = `${axiosPath.nestProducts}`;
    const response = await axiosInstance.post(url, data);

    return response;
};

const useTryUpdateProduct = async (
    data: CommodityDataType,
    id: string | undefined,
) => {
    const body = {
        ...data,
        comments: "수정",
    };
    const url = `${axiosPath.nestProducts}/${id}`;

    const response = await axiosInstance.put(url, body);

    return response;
};

const useGetSaleCount = (no: string | undefined) => {
    const queryKey = ["saleCount", no];

    return useQuery(
        queryKey,
        async () => {
            const url = `${axiosPath.nestProducts}/${no}/sale-count`;
            const response = await axiosInstance.get(url);
            return response;
        },
        {
            enabled: Boolean(no),
            onSuccess: (data) => {
                return data;
            },
        },
    );
};

export {
    useCommodityList,
    initFilters,
    // usePushReceivingUserList,
    getQueryString,
    dropList,
    useUserNoSearch,
    useGetCommodityCode,
    useGetConnectedProducts,
    useTryCreateProduct,
    useTryUpdateProduct,
    useGetSaleCount,
    useGetCheckCommodityCode,
};
