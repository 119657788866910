import React, { useEffect, useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { PieChart } from "@src/components";
import { useHomeSubsOs } from "@src/hooks/queries/useHomeChart";

function SubsOsPercentChart() {
    const [subsOsData, setSubsOsData] = useState<any>([]);
    const options = {
        animation: {
            duration: 20,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                displayColors: true,
                usePointStyle: true,
                boxWidth: 5,
                boxHeight: 10,
                boxPadding: 3,
                bodyFont: {
                    size: 20,
                },
            },
            datalabels: {
                display: true,
                formatter: function (value: string) {
                    return `${value}%`;
                },
                font: {
                    size: 25,
                },
            },
        },
    };
    const subsOsColor = ["#12CDD4", "#FF752F", "#9D9FA5"];

    const parseHomeUserOsChartAndCreateOption = (data: any) => {
        const labels = Object.keys(data);
        const OsValues = Object.values(data).map((value) => value);
        return {
            labels,
            datasets: [
                {
                    type: "pie",
                    label: "OS",
                    data: OsValues,
                    backgroundColor: subsOsColor,
                    color: "#ffffff",
                },
            ],
        };
    };

    const {
        isLoading: subsOsLoading,
        data: subsOs,
        isError: subsOsError,
    } = useHomeSubsOs();

    useEffect(() => {
        if (!subsOsLoading && !subsOsError) {
            const newOsData = parseHomeUserOsChartAndCreateOption(subsOs);
            setSubsOsData(newOsData);
        }
    }, [subsOsLoading]);

    return (
        <div css={subChartDiv}>
            <p css={titleP}>구독 회원별 OS비율</p>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div className="mainChartBox">
                    <div style={{ marginLeft: "20px" }}>
                        <PieChart data={subsOsData} options={options} />
                    </div>
                </div>
            </div>
            <div css={subContainer}>
                <div css={subTitle}>
                    <ChartColor nameColorBg={"#12CDD4"} />
                    <p>AOS</p>
                </div>
                <div css={subTitle}>
                    <ChartColor nameColorBg={"#FF752F"} />
                    <p>iOS</p>
                </div>
                <div css={subTitle}>
                    <ChartColor nameColorBg={"#9D9FA5"} />
                    <p>기타</p>
                </div>
            </div>
        </div>
    );
}

const subChartDiv = css`
    height: fit-content;
    width: 365px;
    padding: 16px;
    border-radius: 8px;
    border: 1.5px solid ${Common.colors.gray200};
    position: relative;
    .mainChartBox {
        width: 325px;
        height: 170px;
        canvas {
            margin-left: 20px;
            width: 175px !important;
            height: 175px !important;
        }
    }
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
`;

const ChartColor = ({ nameColorBg }: { nameColorBg: string }) => {
    const chartColor = css`
        width: 15px !important;
        height: 15px;
        margin: 8px 5px;
        border-radius: 3px;
        background-color: ${nameColorBg};
    `;
    return <div css={chartColor} />;
};

const subTitle = css`
    display: flex;
    width: 50px;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    ${Common.textStyle.title7_M12}
`;

const subContainer = css`
    display: flex;
    position: absolute;
    flex-direction: column;
    right: 5%;
    top: 7%;
    /* bottom: 15%; */
`;

export default SubsOsPercentChart;
