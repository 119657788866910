import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";
import {
    BaseBoardType,
    BoardTypeResult,
    FormFAQType,
} from "@src/pages/help/types";
import { queryKeys } from "./queryKeys";

const random = (max: number) => {
    const arr: Array<FormFAQType> = [];
    for (let i = 1; i <= max; i++) {
        arr.push({
            faqNo: i,
            faqType: Math.floor(Math.random() * 2) ? "서비스 이용" : "결제",
            faqTitle: "미스고 서비스 이용 방법",
            faqContent:
                "<p>미스고는 회원님의 소중한 정보보호를 항상 안전하게 관리하고 있습니다. 아래 계정보호 TIP을 활용하여 미스고계정을 더욱 안전하게 지켜주세요. 미스고는 회원님의 소중한 정보보호를 항상 안전하게 관리하고 있습니다. 아래 계정보호 TIP을 활용하여 미스고계정을 더욱 안전하게 지켜주세요. 미스고는 회원님의 소중한 정보보호를 항상 안전하게 관리하고 있습니다. 아래 계정보호 TIP을 활용하여 미스고계정을 더욱 안전하게 지켜주세요.</p>",
            view: Math.floor(Math.random() * 2),
            faqDate: "2022.02.28 12:24:22",
            updateDate: "2022.03.04 12:24:22",
            more: i,
            edit: false,
            no: i,
        });
    }
    return arr;
};

const initialFAQData = random(500);

interface ParametersType {
    q?: string | undefined;
    page?: number | undefined;
    limit?: number | undefined;
}

const fetchData = async (params: ParametersType) => {
    const response = await axiosInstance.get(`${axiosPath.board}/faq`, {
        params,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData = responseData.data.list;

    return originData.length
        ? {
              result: parse(originData),
              total: responseData.data.totalCnt,
          }
        : { result: [], total: 0 };
};

const parse = (originData: BaseBoardType[]) => {
    const newData: FormFAQType[] = [];
    originData.forEach((item: BaseBoardType, index: number) => {
        const {
            no,
            category,
            title,
            contents,
            is_open,
            created_at,
            updated_at,
        } = item;

        newData.push({
            no: index,
            faqType: category,
            faqTitle: title,
            faqContent: contents,
            view: is_open,
            faqDate: created_at,
            updateDate: updated_at,
            more: 1,
            edit: true,
            faqNo: no,
        });
    });

    return newData;
};

const useFAQData = (params: ParametersType) => {
    return useQuery<BoardTypeResult, Error>(
        [queryKeys.FAQ_LIST, { ...params }],
        () => fetchData(params),
        {
            // initialData: initialFAQData,
        },
    );
};

const initParams = {
    page: 1,
    limit: 10,
};
export { useFAQData, initParams };
