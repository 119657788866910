import styled from "@emotion/styled";
import {
    Button,
    Calendar,
    ConfirmModal,
    EditModal,
    Input,
    Title,
} from "@src/components";
import {
    useGetPartnerCode,
    useGetPartnerInfo,
    usePostPartnerConnections,
    useUpdatePartnerConnections,
} from "@src/hooks/queries/useUserDetail";
import { PartnerInfoProps } from "@src/interfaces/User.interface";
import { getYear, set } from "date-fns";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export interface detailInfoDto {
    name: string;
    code: string;
    uname: string;
    connection: {
        expiredAt: any;
    };
}

interface UserPartnerAddModalProps {
    isPartnerModal: { isShowModal: boolean; type: string };
    setIsPartnerModal: Dispatch<
        SetStateAction<{ isShowModal: boolean; type: string }>
    >;
    partnerInfo: any;
    setPartnerInfo: Dispatch<SetStateAction<PartnerInfoProps>>;
}

const UserPartnerAddModal = ({
    isPartnerModal,
    setIsPartnerModal,
    partnerInfo,
    setPartnerInfo,
}: UserPartnerAddModalProps) => {
    const initalDetailInfo = {
        name: partnerInfo.userPaybackPartner?.name ?? "",
        code: partnerInfo?.userPaybackPartner?.code ?? "",
        uname: partnerInfo?.userPaybackPartner?.user?.info?.uname ?? "",
        connection: {
            expiredAt: partnerInfo?.expiredAt ?? "",
        },
    };
    const { id } = useParams();
    const userNo = Number(id);
    const [code, setCode] = useState<string>(
        partnerInfo.userPaybackPartner?.code ?? "",
    );
    const [detailInfo, setDetailInfo] =
        useState<detailInfoDto>(initalDetailInfo);
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    useEffect(() => {
        setCode(partnerInfo.userPaybackPartner?.code ?? "");
        setDetailInfo({
            name: partnerInfo.userPaybackPartner?.name ?? "",
            code: partnerInfo?.userPaybackPartner?.code ?? "",
            uname: partnerInfo?.userPaybackPartner?.user?.info?.uname ?? "",
            connection: {
                expiredAt: partnerInfo?.expiredAt ?? "",
            },
        });
    }, [partnerInfo]);
    const tryPostPartnerBenefits = async () => {
        postPartnerConntions.mutate({
            userNo: userNo,
            body: {
                code: code,
                connection: { expiredAt: detailInfo?.connection.expiredAt },
            },
        });
    };

    const postPartnerConntions = usePostPartnerConnections(() => {
        useGetPartnerInfo(userNo).then((res) => {
            setPartnerInfo(res);
            setIsModalShow(false);
        });
    });

    const tryUpdatePartnerBenefits = async () => {
        updatePartnerConntions.mutate({
            userNo: userNo,
            body: {
                code: code,
                connection: { expiredAt: detailInfo?.connection.expiredAt },
            },
        });
    };

    const updatePartnerConntions = useUpdatePartnerConnections(() => {
        useGetPartnerInfo(userNo).then((res) => {
            setPartnerInfo(res);
            setIsModalShow(false);
        });
    });

    return (
        <>
            <EditModal
                isModalShow={isPartnerModal.isShowModal}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsModalShow(true);
                }}
                handleOpenModal={() => {
                    setIsPartnerModal({
                        ...isPartnerModal,
                        isShowModal: false,
                    });
                }}
                size="big"
                title={
                    isPartnerModal.type === "create"
                        ? "파트너 입력"
                        : "파트너 입력 수정"
                }
                deleteButton={false}
                styles={{ width: "fit-content" }}
            >
                <StyledContents>
                    <div className="inputDiv">
                        <Input
                            placeholder="파트너 코드를 입력해주세요"
                            size="medium"
                            handleChange={(e) => {
                                setCode(e.target.value);
                            }}
                            label="파트너코드"
                            value={code}
                            required={true}
                        />
                        <Button
                            label="확인"
                            color="gd"
                            size="small"
                            handleClick={() => {
                                if (code && userNo) {
                                    useGetPartnerCode(userNo, code).then(
                                        (res) => {
                                            const time = set(new Date(), {
                                                year: getYear(new Date()) + 1,
                                                hours: 23,
                                                minutes: 59,
                                                seconds: 59,
                                            });

                                            setDetailInfo({
                                                ...res,
                                                uname: res?.user?.info?.uname,
                                                connection: {
                                                    expiredAt: new Date(
                                                        time,
                                                    ).toISOString(),
                                                },
                                            });
                                        },
                                    );
                                }
                            }}
                            styles={{ width: 64, height: 40 }}
                        />
                    </div>
                    {detailInfo.connection.expiredAt ? (
                        <div className="partnerInfo">
                            <div className="itemRow">
                                <span className="name">닉네임</span>
                                <span className="text">
                                    {detailInfo?.name ?? ""}
                                </span>
                            </div>
                            <div className="itemRow">
                                <span className="name">이름</span>
                                <span className="text">
                                    {detailInfo?.uname ?? ""}
                                </span>
                            </div>
                            <div className="itemRow">
                                <span className="name">유효기간</span>
                                <span className="text">
                                    <Calendar
                                        getUserDate={(date: number) => {
                                            setDetailInfo({
                                                ...detailInfo,
                                                connection: {
                                                    expiredAt: new Date(
                                                        date,
                                                    ).toISOString(),
                                                },
                                            });
                                        }}
                                        setDate={
                                            detailInfo.connection?.expiredAt
                                        }
                                    />
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className="noData">입력된 파트너가 없습니다</div>
                    )}
                </StyledContents>
            </EditModal>
            <ConfirmModal
                isModalShow={isModalShow}
                buttonText="확인"
                handleCancel={() => {
                    setIsModalShow(false);
                }}
                handleOk={() => {
                    isPartnerModal.type === "create"
                        ? tryPostPartnerBenefits()
                        : tryUpdatePartnerBenefits();
                }}
            >
                정말 파트너를 저장 하시겠습니까??
            </ConfirmModal>
        </>
    );
};

export default UserPartnerAddModal;

const StyledContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    .noData {
        width: 433px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f4f4;
        color: #222222;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
    }
    .inputDiv {
        display: flex;
        /* gap: 16px; */
        align-items: center;
        & > input {
            margin-right: 16px;
            border-radius: 8px;
            border: 1px solid #e3e3e6;
            margin-left: 16px;
            width: 242px;
        }
    }
    .partnerInfo {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .itemRow {
            display: flex;
            align-items: center;
            gap: 30px;

            .name {
                color: #9d9fa5;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                width: 68px;
            }
            .text {
                color: #222222;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
            }
        }
    }
`;
