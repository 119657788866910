import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { PageTable, Title, Button, ConfirmModal } from "@src/components";
import { useFAQData, initParams } from "@src/hooks/queries/useFAQ";
import { useNavigate } from "react-router-dom";
import { FormFAQType } from "@src/pages/help/types";
import { useDeleteFAQData } from "@src/hooks/queries/useFAQDetail";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { getColumns } from "./FAQListColumns";

function FAQList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    interface DeleteType {
        isModalShow: boolean;
        deleteNo: number;
    }
    const [isDelete, setIsDelete] = useState<DeleteType>({
        isModalShow: false,
        deleteNo: -1,
    });
    const [data, setData] = useState<FormFAQType[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [params, setParams] = useState(initParams);
    const {
        isLoading,
        data: faqData = { result: [], total: 0 },
        isError,
        refetch: listRefetch,
    } = useFAQData(params.page !== 0 ? params : {});

    useEffect(() => {
        if (isError) {
            // error
        } else {
            if (!isLoading && faqData) {
                setData(faqData.result);
                if (params.page === 1) {
                    setTotalCount(faqData.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [faqData.result]);
    const columns = getColumns({ navigate, setIsDelete });
    const { mutate } = useDeleteFAQData();

    const onDelete = (id: number) => {
        setIsDelete({ ...isDelete, ...{ isModalShow: false } });
        mutate(id, {
            onSuccess: () => {
                console.log("삭제되었습니다.");
            },
            onError: (error) => {
                console.log(error);
            },
            onSettled: () => listRefetch(),
        });
    };
    return (
        <div css={listStyle}>
            <Title title="자주 묻는 질문 (FAQ)" />
            <div css={searchDiv}>
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/help/faq/create");
                    }}
                    label="신규 등록"
                    size="medium"
                />
            </div>
            <div css={tableDiv}>
                <PageTable
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                        hiddenColumns: ["faqNo"],
                    }}
                    columns={columns}
                    data={data}
                    FixedHeight={700}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParams({ page, limit: 10 });
                    }}
                    showTotalCount={true}
                    forcePage={params.page === 1}
                ></PageTable>
            </div>
            <ConfirmModal
                buttonText="삭제"
                handleCancel={() =>
                    setIsDelete({ ...isDelete, ...{ isModalShow: false } })
                }
                handleOk={() => onDelete(isDelete.deleteNo)}
                isModalShow={isDelete.isModalShow}
            >
                <>정말 삭제하시겠습니까?</>
            </ConfirmModal>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const searchDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        padding: 10px 0;
        button:nth-of-type(1) {
            margin-right: 15px;
        }
    }
    .faqTitle {
        text-align: left;
    }
    .faqContent {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-align: left;
    }
`;

export default FAQList;
