import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import LoadingPortal from "./LoadingPortal";

function LoadingBar() {
    return (
        <LoadingPortal>
            <div css={loadingBarDiv}>
                <div></div>
                <p>로딩중입니다.</p>
            </div>
        </LoadingPortal>
    );
}

const loadingBarDiv = css`
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.4);
    div {
        width: 40px;
        height: 40px;
        border: 8px solid ${Common.colors.white};
        border-top-color: ${Common.colors.purple500};
        border-radius: 50%;
        animation: spin 1s infinite;
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center;
        transform: rotate(0deg) translate(-50%, -50%);
        transition: rotate 1s;
    }
    @keyframes spin {
        from {
            transform-origin: center;
            transform: rotate(0deg);
        }
        to {
            transform-origin: center;
            transform: rotate(360deg);
        }
    }
    p {
        ${Common.textStyle.title2_M16}
        color: ${Common.colors.white};
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: 65px;
        transform: translate(-26%, -50%);
    }
`;

export default LoadingBar;
