import React from "react";
import { IconButton } from "@src/components";

export const getColumns = ({ navigate, setIsDelete }: any) => {
    return [
        {
            Header: "배너이미지",
            accessor: "bannerImage",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "200px", textAlign: "center" }}>
                    <img src={value} width={156} height={74} />
                </div>
            ),
        },
        {
            Header: "연결 링크",
            accessor: "bannerLink",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "left",
                        width: "500px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                    }}
                >
                    <a
                        href={value}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            color: "blue",
                            textAlign: "left",
                            width: "100px",
                        }}
                    >
                        {value}
                    </a>
                </div>
            ),
        },
        {
            Header: "게시 기간",
            accessor: "bannerDate",
            Cell: ({ row }: any) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        {row.original.startDate} ~ {row.original.endDate}
                    </div>
                );
            },
        },
        {
            Header: "노출 여부",
            accessor: "bannerView",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {value === 0 && "미노출"}
                    {value === 1 && "노출"}
                    {value === 2 && "테스터 노출"}
                </div>
            ),
        },
        {
            Header: "배너번호",
            accessor: "bannerNo",
        },
        {
            Header: "노출 순서",
            accessor: "order",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100%", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "관리",
            accessor: "viewControl",
            Cell: ({ row }: any) => (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        type="trash"
                        handleClick={() => {
                            setIsDelete({
                                ...{
                                    deleteNo: row.original.bannerNo,
                                    isModalShow: true,
                                },
                            });
                        }}
                    />
                    <IconButton
                        type="edit"
                        handleClick={() => {
                            navigate(
                                `/banners/update/${row.original.bannerNo}`,
                            );
                        }}
                    />
                </div>
            ),
        },
    ];
};
