import React from "react";
import { IconButton, Label } from "@src/components";
import {
    UserStatus,
    ExpertStatus,
    SubscriptionStatus,
} from "@src/constants/UserStatus";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTime } from "@src/utils/dateUtil";

export const getColumns = (navigate: any) => [
    {
        Header: "번호",
        accessor: "userNo",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ width: "100%", minWidth: "80px", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "이름",
        accessor: "username",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ minWidth: "80px", width: "100%", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "연락처",
        accessor: "phone",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "120px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {!value
                    ? INVALID_VALUE
                    : value.length == 11
                        ? value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        : value}
            </div>
        ),
    },
    {
        Header: "이메일",
        accessor: "userId",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "200px",
                    width: "100%",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "배너 클릭 횟수",
        accessor: "bannerClickCount",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "50px",
                    width: "100%",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "생년월일",
        accessor: "birth",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "100px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {/* {value ? value : INVALID_VALUE} */}
                {!value
                    ? INVALID_VALUE
                    : value.length == 8
                        ? value.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1.$2.$3`)
                        : value}
            </div>
        ),
    },
    {
        Header: "성별",
        accessor: "gender",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ minWidth: "50px", width: "100%", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "가입 일시",
        accessor: "joinedAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "180px",
                    fontSize: "13px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {ConvertDateTime(value ?? 0)}
            </div>
        ),
    },
    {
        Header: "상태",
        accessor: "status",
        Cell: ({ cell: { value } }: any) => {
            const [text, color] = UserStatus[value == "정상" ? 200 : 0];
            return (
                <div
                    style={{
                        minWidth: "100px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <Label color={color} text={text} />
                </div>
            );
        },
    },
    {
        Header: "정기구독 여부",
        accessor: "subsInfo",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value}
                </div>
            );
        },
    },
    {
        Header: "관리",
        accessor: "viewControl",
        Cell: ({ row }: any) => {
            const status = row.original.status;
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    {
                        status === "정상" && <IconButton
                            type="edit"
                            handleClick={() => {
                                navigate(
                                    `/users/update/${row.original.userNo}`,
                                );
                            }}
                        />
                    }
                </div>
            );
        },
    },
    {
        Header: "더보기",
        accessor: "viewMore",
        Cell: ({ cell: { value }, row: { values } }: any) => {
            const status = values.status;
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    {status === "정상" &&
                        <>
                            <IconButton
                                type="arrow"
                                handleClick={() => {
                                    navigate(`/users/detail/${values.userNo}`);
                                }}
                            />
                        </>
                    }
                </div>
            );
        },
    },
];
