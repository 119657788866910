import React, { useEffect, useState } from "react";
import { Title, PageTable, SearchInput, DropDown } from "@src/components";
import { css } from "@emotion/react";
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { ICouponDetailListDto } from "@src/interfaces/Coupon.interface";
import { useCouponDetailListFetchData } from "@src/hooks/queries/useCoupon";
import { couponDetailColumns } from "./CouponDetailColumns";

interface Props {}

interface PropsState {
    usedCount: number;
    issuedCount: number;
}

const CouponDetail: React.FC<Props> = () => {
    const navigate = useNavigate();
    const columns = couponDetailColumns(navigate);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [countStatus, setCountStatus] = useState<string>("");
    const [selectWord, setSelectWord] = useState<string>("email");
    const [param, setParam] = useState<ICouponDetailListDto>({});
    const { id } = useParams();
    const { data, refetch } = useCouponDetailListFetchData(id ?? "", param);
    useEffect(() => {
        if (location.state) {
            const { usedCount, issuedCount } = location.state as PropsState;
            setCountStatus(`사용 ${usedCount ?? 0} / 발행 ${issuedCount ?? 0}`);
        } else {
            const newParam = Object.fromEntries(searchParams);
            setCountStatus(
                `사용 ${
                    newParam.usedCount === "null" ? 0 : newParam.usedCount
                } / 발행 ${
                    newParam.issuedCount === "null" ? 0 : newParam.issuedCount
                }`,
            );
        }
    }, []);

    useEffect(() => {
        refetch();
    }, [param]);

    return (
        <div css={rootStyle}>
            <Title title="쿠폰 사용내역 조회" />
            <div css={searchRootStyle}>
                <p>{countStatus}</p>
                <div css={searchBoxStyle}>
                    <DropDown
                        border
                        data={[
                            {
                                key: "email",
                                value: "이메일",
                            },
                            {
                                key: "code",
                                value: "쿠폰코드",
                            },
                        ]}
                        handleChange={(evt) => {
                            setSelectWord(evt.target.value);
                        }}
                    />
                    <SearchInput
                        handleClick={(t: string) => {
                            setParam({
                                ...param,
                                q: t,
                                search_type: selectWord,
                                page: 1,
                            });
                        }}
                        placeholder={
                            selectWord === "email"
                                ? "이메일로 검색해 주세요."
                                : "쿠폰코드로 검색해 주세요."
                        }
                    />
                </div>
            </div>
            <div>
                <div css={sortBoxStyle}>
                    <DropDown
                        border
                        data={[
                            {
                                key: "",
                                value: "등록일 순",
                            },
                            {
                                key: "rest_day",
                                value: "잔여일 순",
                            },
                        ]}
                        handleChange={(evt) => {
                            setParam({
                                ...param,
                                sort: evt.target.value,
                                page: 1,
                            });
                        }}
                    />
                </div>
                <PageTable
                    columns={columns}
                    data={data?.result ?? []}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    FixedHeight={500}
                    showTotalCount={true}
                    totalCount={data?.total ?? 0}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParam({ ...param, page });
                    }}
                    forcePageNumber={param.page ?? 1 - 1}
                />
            </div>
        </div>
    );
};

const rootStyle = css`
    padding: 40px;
    width: 100%;
`;

const searchRootStyle = css`
    padding: 7rem 0 2rem;
    p {
        margin: 1rem;
    }
`;

const sortBoxStyle = css`
    display: flex;
    justify-content: flex-end;
    select {
        border: 1px solid #d9d9d9;
        color: #121212;
    }
`;

const searchBoxStyle = css`
    display: inline-flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    input {
        border: none;
        width: 22rem;
    }
    select {
        border: none;
        width: 9rem;
    }
    button {
        background-color: #424242;
    }
`;

export default CouponDetail;
