export const NormalState = [
    {
        id: 1,
        key: "normal",
        value: "정상",
    },
    {
        id: 2,
        key: "cancelReservation",
        value: "해지예약",
    },
    {
        id: 3,
        key: "cancel",
        value: "즉시해지",
    },
];

export const CancelState = [
    {
        id: 2,
        key: "cancelReservation",
        value: "해지예약",
    },
    {
        id: 1,
        key: "normal",
        value: "정상",
    },
    {
        id: 3,
        key: "cancel",
        value: "즉시해지",
    },
];

export const NoReservation = [
    {
        id: 1,
        key: "normal",
        value: "정상",
    },
    {
        id: 2,
        key: "cancel",
        value: "즉시해지",
    },
];
