import { Button, IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { useUpdateDeungiState } from "@src/hooks/queries/useDeungi";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { UserStatus } from "@src/constants/UserStatus";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { PartnerLabelState } from "@src/constants/PartnerStatus";

export const getColumns = (navigate: any) => [
    {
        id: "1",
        Header: "파트너 번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => <div>{value ? value : INVALID_VALUE}</div>,
    },
    {
        id: "2",
        Header: "유형",
        accessor: "partnerType",
        Cell: ({ cell: { value } }: any) => <div>{value ? value : INVALID_VALUE}</div>,
    },
    {
        id: "3",
        Header: "이름",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            const name = row.original.user.info.uname;
            const id = row.original.user.no;
            return (
                <div
                    onClick={() => {
                        navigate(`/users/detail/${id}`);
                    }}
                >
                    {name ? name : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        id: "4",
        Header: "닉네임",
        accessor: "name",
        Cell: ({ cell: { value } }: any) => {
            return <div>{value ? value : INVALID_VALUE}</div>;
        },
    },
    {
        id: "5",
        Header: "이메일",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            const email = row.original.user.id;
            return <div>{email ? email : INVALID_VALUE}</div>;
        },
    },
    {
        id: "6",
        Header: "연락처",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            const phone = row.original.user.info.phone;
            return (
                <div>
                    {!phone
                        ? INVALID_VALUE
                        : phone.length == 11
                        ? phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        : phone}
                </div>
            );
        },
    },
    {
        id: "7",
        Header: "구독여부",
        accessor: "subscription",
        Cell: ({ cell: { value }, row }: any) => {
            const subscription = row.original.subscription;
            const subscriptionType = row.original.subscriptionType;
            const subscriptionTypeText =
                subscriptionType === "paid" ? "구독중 - 유료" : subscriptionType === "free" ? "구독중 - 무료" : "";
            return (
                <div>
                    {!!subscriptionTypeText && (
                        <>
                            {subscriptionTypeText}
                            <br />
                        </>
                    )}
                    {subscription === "미구독" ? subscription : `(${subscription})`}
                </div>
            );
        },
    },
    {
        id: "8",
        Header: "회원상태",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            const status = row.original.user.status;
            const [text, color] = UserStatus[status];
            return (
                <div>
                    <Label color={color} text={text} />
                </div>
            );
        },
    },
    {
        id: "9",
        Header: "신청일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => <div>{ConvertDateTime(value ?? 0)}</div>,
    },
    {
        id: "10",
        Header: "파트너 상태",
        accessor: "status",
        Cell: ({ cell: { value, row } }: any) => {
            const [text, color] = PartnerLabelState[value];
            return (
                <div>
                    <Label color={color} text={text} />
                </div>
            );
        },
    },
    {
        id: "11",
        Header: "관리",
        accessor: "no",
        Cell: ({ cell: { value, row } }: any) => {
            const no = row.original.no;
            return (
                <div>
                    <IconButton
                        type="arrow"
                        handleClick={() => {
                            navigate(`/partner/detail/${no}`, {
                                state: {
                                    no: no,
                                },
                            });
                        }}
                    />
                </div>
            );
        },
    },
];
