import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import CompositionTable from "./CompositionTable";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
interface ITable {
    columns: any;
    data: any;
    initialState?: {
        pageIndex: number;
        pageSize: number;
        hiddenColumns?: string[];
    };
    FixedHeight?: number;
    showCheckbox?: boolean;
    showTotalCount?: boolean;
    totalCount: number;
    handlePageChange?: (index: number, pageSize: number) => void;
    onSelectedRowsChange?: (selectedRow: any) => void;
    selectedRows?: any;
    stateReducer?: any;
    showNumber?: boolean;
    forcePage?: boolean;
    selectionClear?: boolean;
    forcePageNumber?: number;
    showPagination?: boolean;
    showUserCount?: number;
    showClickCount?: number;
}

function PageTable({
    columns,
    data,
    showCheckbox,
    initialState = { pageIndex: 0, pageSize: 5 },
    FixedHeight = 250,
    showTotalCount = false,
    totalCount,
    handlePageChange,
    onSelectedRowsChange,
    selectedRows = {},
    showNumber = false,
    forcePage = false,
    selectionClear = false,
    forcePageNumber,
    showPagination = true,
    showUserCount,
    showClickCount,
}: ITable) {
    const pageCount = Math.ceil(totalCount / initialState.pageSize);
    const hiddenColumns = initialState.hiddenColumns
        ? [...initialState.hiddenColumns, showNumber ? "" : "pageNo"]
        : [showNumber ? "" : "pageNo"];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        selectedFlatRows,
        dispatch,
        state: { selectedRowIds },
        toggleAllRowsSelected,
    }: any = useTable(
        {
            columns,
            data,
            initialState: {
                ...initialState,
                selectedRowIds: selectedRows,
                hiddenColumns,
            },
            stateReducer: (newState, action, prevState) => {
                switch (action.type) {
                    case "changePageNumber":
                        return { ...newState, pageNumber: action.payload };
                    default:
                        return newState;
                }
            },
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "pageNo",
                    Header: "번호",
                    Cell: ({ state, row }: any) => {
                        const { pageNumber = 0 } = state;
                        const page = pageNumber == 0 ? 1 : pageNumber;
                        const currentPage = (page - 1) * initialState.pageSize;
                        const pageNo = page ? totalCount - (currentPage + (row.index + 1)) + 1 : 1;

                        return <div style={{ width: "60px", textAlign: "center" }}>{pageNo}</div>;
                    },
                },
                ...columns,
            ]);

            if (showCheckbox) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",
                        Header: ({ getToggleAllPageRowsSelectedProps }: any) => {
                            return (
                                <div>
                                    <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                                </div>
                            );
                        },
                        Cell: ({ row }: any) => {
                            return (
                                <div
                                    style={{
                                        width: "60px",
                                        textAlign: "center",
                                    }}
                                >
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                                </div>
                            );
                        },
                    },
                    ...columns,
                ]);
            }
        },
    );

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(initialState.pageIndex);
    const onPageChange = ({ selected }: any) => {
        setCurrentPageNumber(selected);
        handlePageChange && handlePageChange(selected, initialState.pageSize);
        deselectAllRows();
        dispatch({ type: "changePageNumber", payload: selected + 1 });
    };
    useEffect(() => {
        if (onSelectedRowsChange !== undefined) {
            onSelectedRowsChange(selectedFlatRows);
        }
    }, [selectedFlatRows]);

    useEffect(() => {
        if (forcePage) {
            setCurrentPageNumber(0); // 검색 후 첫 페이지로 강제 업데이트
        }
        if (forcePageNumber) {
            setCurrentPageNumber(forcePageNumber - 1);
        }
    }, [forcePage, forcePageNumber]);

    const deselectAllRows = useCallback(() => {
        Object.keys(selectedRowIds).forEach((key, index) => {
            selectedRowIds[key] = false;
        });
        toggleAllRowsSelected(false);
    }, [selectedRowIds, toggleAllRowsSelected]);

    useEffect(() => {
        if (selectionClear) {
            deselectAllRows(); // 체크박스 모두 해제
        }
    }, [selectionClear]);

    useEffect(() => {
        if (totalCount && currentPageNumber) {
            if (currentPageNumber > pageCount) {
                handlePageChange && handlePageChange(pageCount - 1, initialState.pageSize); // 삭제로 페이지가 사라지면, api 파라미터 강제 업데이트
            }
        }
    }, [totalCount]);

    return (
        <>
            <CompositionTable
                columns={columns}
                data={data}
                FixedHeight={FixedHeight}
                showTotalCount={showTotalCount}
                totalCount={totalCount}
                onPageChange={onPageChange}
                pageCount={pageCount}
                forcePageNumber={currentPageNumber}
                getTableBodyProps={getTableBodyProps}
                getTableProps={getTableProps}
                page={page}
                prepareRow={prepareRow}
                headerGroups={headerGroups}
                showUserCount={showUserCount}
                showClickCount={showClickCount}
            />
        </>
    );
}

export default React.memo(PageTable);
