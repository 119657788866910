const validEmailCheck = (input: any) => {
    //정규식 설명
    //숫자 (0~9) or 알파벳 (a~z, A~Z) 으로 시작하며 중간에 -_. 문자가 있을 수 있으며 그 후 숫자 (0~9) or 알파벳 (a~z, A~Z)이 올 수도 있고 연달아 올 수도 있고 없을 수도 있다.
    //@ 는 반드시 존재하며 . 도 반드시 존재하고 a~z, A~Z 의 문자가 2,3개 존재하고 i = 대소문자 구분 안한다.

    // let pattern =
    //     /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    // const result = input.match(pattern) != null;
    // if (result) {
    //     console.log("올바른 이메일 주소를 입력하세요");
    // }

    return input.includes("@");
};

const validNumberCheck = (input: any) => {
    const regex = /[^-.0-9]/;
    const reValue = input.replace(/[^-.0-9]/g, "");
    const result = (reValue: any) => regex.test(reValue);
    return [result, reValue];
};

const validMaxLengthCheck = (input: any, MAX_LENGTH: any) => {
    return input.length > MAX_LENGTH;
};

const validEmptyValueCheck = (input: any) => {
    return input === undefined || input === null || input === "";
};

const validURLCheck = (url: any) => {
    const regex = /(http[s]?:\/\/)?[^\s(["<,>]*\.[^\s[",><]*/;

    return !regex.test(url);
};

export const validSpacebarValueCheck = (value: any) => {
    const test = value.trim();
    return test === "";
};
export const SPACEBAR_ERROR_MESSAGE = "유효하지 않은 값입니다.";

/**
 * 유효성 검사 후 boolean, errMsg 반환하는 함수
 */
const validChecker = (
    check: "spacebar" | "maxLength" | "url" | "code",
    value: any,
    maxLength?: any,
) => {
    let result = false;
    let errMsg = "";
    switch (check) {
        case "spacebar": {
            result = validSpacebarValueCheck(value);
            if (result) {
                value === ""
                    ? (errMsg = "")
                    : (errMsg = SPACEBAR_ERROR_MESSAGE);
            }
            break;
        }
        case "maxLength": {
            result = validMaxLengthCheck(value, maxLength);
            if (result) {
                errMsg = `${maxLength}자를 넘을 수 없습니다.`;
            }
            break;
        }
        case "url": {
            result = validURLCheck(value);
            if (result) {
                errMsg = "URL주소를 다시 확인해주세요.";
            }
            break;
        }
        case "code": {
            const specialChar = /_\w+/;
            const numberOrAlphabet = /[a-zA-Z0-9]/;
            result = specialChar.test(value) || !numberOrAlphabet.test(value);
            if (result) {
                errMsg = "코드를 다시 확인해주세요.";
            }
            break;
        }
    }
    return { result, errMsg };
};
export {
    validEmailCheck,
    validNumberCheck,
    validMaxLengthCheck,
    validEmptyValueCheck,
    validURLCheck,
    validChecker,
};
