import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { Title, Button, PageTable, EditModal, Input } from "@src/components";
import {
    useUserList,
    initFilters,
    getQueryString,
    dropList,
} from "@src/hooks/queries/useUser";
import UserFilter from "./UserFilter";
import { UserFilterType } from "./types";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "@src/reducers/loading";
import { getColumns } from "./UserBannerClickColumn";
import { getQueryData } from "@src/utils/useQueryClient";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import { makeQueryString } from "@src/utils/queryStringUtil";
import UserBannerClikcFilter from "./UserBannerClickFilter";
import { getBannerStatRequestDto, initBannerStateRequestDto } from "../expert/types";
import { useGetBannerClickCount, useGetBannerExcelDownloadLog, useGetBannerExcelFile, useGetFilterBannerList } from "@src/hooks/queries/useBanner";
import { ConvertDateTime } from "@src/utils/dateUtil";
import useInfiniteScroll from "react-infinite-scroll-hook";

function UserBannerClickCount() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = getColumns(navigate);

    const [searchParams, setSearchParams] = useSearchParams();
    const copyQuery: UserFilterType = Object.fromEntries(searchParams);
    const newQuery = getQueryString(copyQuery);
    const queryData: { result: any[]; total: number; cursor: string } =
        getQueryData([queryKeys.USER_LIST, newQuery]);

    const [filters, setFilters] = useState<getBannerStatRequestDto>({
        ...initBannerStateRequestDto,
    });


    const {
        data: bannerExcelDownloadLog,
        refetch: refetchBannerExcelDownloadLog,
        hasNextPage,
        fetchNextPage,
    } = useGetBannerExcelDownloadLog({ page: 1, limit: 10 });

    const [isOpenReasonModal, setIsOpenReasonModal] = useState<boolean>(false);
    const [isOpenDownloadListModal, setIsOpenDownloadListModal] =
        useState<boolean>(false);

    const [option, setOption] = useState<UserFilterType>(initFilters);
    const [reason, setReason] = useState<string>("");
    const [params, setParams] = useState<getBannerStatRequestDto>(initBannerStateRequestDto);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [viewMoreRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: () => {
            fetchNextPage();
        },
        disabled: false,
        delayInMs: 100,
    });


    const {
        isLoading,
        data = { result: [], total: 0, cursor: "" },
        isError,
    } = useUserList(params, true);

    const { data: banenrData, refetch: refetchBannerData } = useGetBannerClickCount(params, true);
    const { data: bannerListData } = useGetFilterBannerList({ value: "pcHomeWide" }, true);

    const [list, setList] = useState<any>(data.result);

    useEffect(() => {
        refetchBannerData()
    }, [params]);

    useEffect(() => {
        if (bannerListData && bannerListData?.list.length > 0) {
            setFilters({
                ...filters,
                bannerNo: bannerListData.list[0].no
            })
            setParams({
                ...params,
                bannerNo: bannerListData.list[0].no
            })
        }
    }, [bannerListData])

    /**
     * 필터 검색 조건이 변경되면 실행하는 함수
     */
    const handleSubmit = (data: getBannerStatRequestDto) => {
        setFilters(data)
        setClear(false);
    };
    /**
     * 필터 검색 버튼을 클릭하면 실행되는 함수
    */
    const handleSearchClick = () => {
        if (filters.searchText === "") {
            delete filters.searchText;
        }
        setParams(filters);
    };

    const [clear, setClear] = useState(false);
    /**
     * 필터 전체 해제 버튼을 클릭하면 실행되는 함수
     */
    const handleClearClick = () => {
        setOption(initFilters);
        setClear(true);
    };




    return (
        <div css={listStyle}>
            <Title title="배너 클릭 회원 조회" />
            <UserBannerClikcFilter
                handleSubmit={handleSubmit}
                handleSearchClick={handleSearchClick}
                dropList={dropList}
                clear={clear}
            />
            <div className="filterBtnBox" css={filterBtnBox}>
                <Button
                    color="gray"
                    handleClick={handleClearClick}
                    label="전체 해제"
                    size="medium"
                />
                <Button
                    color="purple"
                    handleClick={handleSearchClick}
                    label="검색"
                    size="medium"
                    isDisabled={option.isError}
                />
            </div>
            <div css={btnBox}>
                {/* <Button
                    color="white"
                    handleClick={() => {
                        setIsOpenReasonModal(true);
                    }}
                    label="엑셀 다운로드"
                    size="medium"
                />
                <Button
                    color="purple"
                    handleClick={() => {
                        setIsOpenDownloadListModal(true);
                        refetchBannerExcelDownloadLog();
                    }}
                    label="내역"
                    size="small"
                /> */}
            </div>
            {
                banenrData?.list &&
                <div css={tableDiv}>
                    <PageTable
                        columns={columns}
                        data={banenrData?.list}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        showUserCount={banenrData?.totalUserCount}
                        showClickCount={banenrData?.totalClickCount}
                        FixedHeight={500}
                        showTotalCount={false}
                        totalCount={1}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            const limit = 10;
                            // setParams(() => {
                            //     let newParams = {
                            //         ...copyQuery,
                            //         page,
                            //         limit,
                            //         cursor: page === 1 ? "" : cursor,
                            //     };
                            //     return newParams;
                            // });
                        }}
                    // forcePageNumber={params?.page}
                    ></PageTable>
                </div>
            }
            <EditModal
                isModalShow={isOpenReasonModal}
                title={"엑셀 다운로드"}
                handleOpenModal={() => {
                    setIsOpenReasonModal(false);
                }}
                size={"fitContent"}
                handleBtnClick={() => {
                    useGetBannerExcelFile(filters, reason);
                    setReason("")
                }}
                isDisabled={!reason}
            >
                <div>
                    <Input
                        placeholder={"사유를 입력해주세요"}
                        handleChange={(e) => {
                            setReason(e.target.value);
                        }}
                        size={"medium"}
                        value={reason}
                        label="사유"
                    />
                </div>
            </EditModal>

            <EditModal
                isModalShow={isOpenDownloadListModal}
                title={"엑셀 다운로드 내역"}
                handleOpenModal={() => {
                    setIsOpenDownloadListModal(false);
                }}
                size={"fitContent"}
                handleBtnClick={() => { }}
                deleteButton
            >
                <div css={itemModalWrap}>
                    <table css={tableCss}>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>다운로드 일시</th>
                                <th>관리자 이름</th>
                                <th>사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bannerExcelDownloadLog?.pages.map((page: any) =>
                                page.list.map((item: any, index: number) => (
                                    <tr key={String([item, index])}>
                                        <td>{item?.no}</td>
                                        <td>
                                            {ConvertDateTime(
                                                item?.downloadDate,
                                            )}
                                        </td>
                                        <td>{item?.adminName}</td>
                                        <td>{item?.reason}</td>
                                    </tr>
                                )),
                            )}
                            <tr>
                                <td
                                    ref={viewMoreRef}
                                    css={scrollDiv}
                                    colSpan={8}
                                ></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </EditModal>
        </div >
    );
}
const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const btnBox = css`
    display: flex;
    gap: 14px;
`
const searchDiv = css`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 43px;
    width: 100%;
    height: 80vh;
`;
const filterBtnBox = css`
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
`;

const tableCss = css`
    width: 100%;
    & > thead {
        background-color: #f3f3f3;
        & > tr {
            display: table;
            width: 100%;
            & > th {
                width: 25%;
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
    & > tbody {
        display: block;
        max-height: 500px;
        overflow-y: scroll;
        & > tr {
            display: table;
            width: 100%;
            & > td {
                width: 25%;
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
`;

const scrollDiv = css`
    padding: 40px;
`;

const itemModalWrap = css`
    width: 1000px;
`;


export default UserBannerClickCount;
