import styled from "@emotion/styled";
import { CommodityDataType } from "@src/pages/commodity/CommodityCreateContents";
import React, { Dispatch, SetStateAction } from "react";

interface EssentialInputProps {
    label: string;
    essential: boolean;
    placeholder: string;
    value?: any;
    style?: React.CSSProperties | undefined;
    color?: boolean;
    data: CommodityDataType;
    onChange: Dispatch<SetStateAction<CommodityDataType>>;
    name: string;
    isDisabled?: boolean;
    number?: boolean;
}

const EssentialInput = ({
    label,
    essential,
    placeholder,
    style,
    color,
    value,
    data,
    onChange,
    name,
    isDisabled,
    number = false,
}: EssentialInputProps) => {
    return (
        <>
            <StyledInputWrapper color={value}>
                {essential && <span>*</span>}
                {label}
                {color && <div className="colorPalette"></div>}
                <input
                    type={number ? "number" : "text"}
                    placeholder={placeholder}
                    style={style}
                    value={value}
                    onChange={(e: any) => {
                        number
                            ? onChange({
                                  ...data,
                                  [name]: Number(e.target.value),
                              })
                            : onChange({ ...data, [name]: e.target.value });
                    }}
                    disabled={isDisabled}
                />
            </StyledInputWrapper>
        </>
    );
};

export default EssentialInput;

const StyledInputWrapper = styled.div<{ color: string }>`
    font-weight: 400;
    font-size: 14px;
    position: relative;
    color: #222222;
    & > span {
        font-weight: 400;
        font-size: 14px;
        color: #f45b5b;
    }
    & > .colorPalette {
        width: 22px;
        height: 22px;
        border: 1px solid black;
        position: absolute;
        top: 20%;
        right: 300px;
        background-color: ${(props) => props.color};
    }

    & > input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 328px;
        height: 42px;
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
    & > input:disabled {
        background: none;
    }
`;
