import { css } from "@emotion/react";
import { Checkbox, DropDown, Input, RangeCalendar } from "@src/components";
import { Common } from "@src/styles/Common";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import React, { useEffect, useState } from "react";

interface ExpertAdvertisementListFilterProps {
    filters: any;
    setFilters: any;
    advertisementStatus: any;
    setAdvertisementStatus: (text: string, value: boolean) => void;
    advertisementType: any;
    setAdvertisementType: (text: string, value: boolean) => void;
    advertisementManager: any;
    setAdvertisementManager: (text: string, value: boolean) => void;
}

const ExpertAdvertisementListFilter = ({
    filters,
    setFilters,
    advertisementStatus,
    setAdvertisementStatus,
    advertisementType,
    setAdvertisementType,
    advertisementManager,
    setAdvertisementManager,
}: ExpertAdvertisementListFilterProps) => {
    const dropList: {
        id: number;
        key: string;
        value: string;
    }[] = [
        { id: 0, key: "userName", value: "이름" },
        { id: 1, key: "userPhone", value: "휴대폰번호" },
        { id: 2, key: "officeName", value: "사무소명" },
        { id: 3, key: "itemSubId", value: "사건번호" },
        { id: 4, key: "itemAddress", value: "소재지" },
        { id: 5, key: "bannerNo", value: "광고번호" },
        { id: 5, key: "managerName", value: "담당 관리자" },
    ];

    const dateLabel = [
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];
    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const [selectedDate, setSelectedDate] = useState<number | null>(0);

    const renderDate = (item: number) => {
        if (item == 0) {
            setFilters({
                ...filters,
                searchStartDate: "",
                searchEndDate: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                searchStartDate: before7day,
                searchEndDate: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                searchStartDate: before1Month,
                searchEndDate: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                searchStartDate: before6Month,
                searchEndDate: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                searchStartDate: before12Month,
                searchEndDate: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                searchStartDate: TODAY,
                searchEndDate: TODAY,
            });
        }
        setSelectedDate(item);
    };

    useEffect(() => {
        const newList = Object.entries(advertisementStatus)
            .filter(([name, value]) => value)
            .map(([name, _]) => name);

        setFilters({
            ...filters,
            bannerStatusTypes: newList,
        });
    }, [advertisementStatus]);
    useEffect(() => {
        const newList = Object.entries(advertisementType)
            .filter(([name, value]) => value)
            .map(([name, _]) => name);
        setFilters({
            ...filters,
            bannerTypes: newList,
        });
    }, [advertisementType]);
    useEffect(() => {
        const newList = Object.entries(advertisementManager)
            .filter(([name, value]) => value)
            .map(([name, _]) => name);
        setFilters({
            ...filters,
            managerTypes: newList,
        });
    }, [advertisementManager]);

    return (
        <>
            <div css={filterWrap}>
                <ul>
                    <li>
                        <span css={subTitle}>광고시작 일시</span>
                        <div>
                            {dateLabel.map((item) => (
                                <div key={item.id}>
                                    <button
                                        value={item.value}
                                        css={dateButton(
                                            item.key == selectedDate,
                                        )}
                                        onClick={() => renderDate(item.key)}
                                    >
                                        {item.value}
                                    </button>
                                </div>
                            ))}
                            <RangeCalendar
                                setRangeDate={{
                                    startDate:
                                        filters.searchStartDate !== null &&
                                        filters.searchStartDate !== undefined
                                            ? dateParse(filters.searchStartDate)
                                            : null,

                                    endDate:
                                        filters.searchEndDate !== null &&
                                        filters.searchEndDate !== undefined
                                            ? dateParse(filters.searchEndDate)
                                            : null,
                                }}
                                getUserRangeDate={(date, isError) => {
                                    const { startDate: start, endDate: end } =
                                        date;
                                    setFilters({
                                        ...filters,
                                        searchStartDate: start
                                            ? ConvertDate(start)
                                            : "",
                                        searchEndDate: end
                                            ? ConvertDate(end)
                                            : "",
                                    });
                                }}
                                verticalAlign={false}
                                isDisabled={false}
                                timePicker={false}
                                clear={false}
                            />
                        </div>
                    </li>

                    <li>
                        <span css={subTitle}>광고분류</span>
                        <div css={checkBoxWrap}>
                            {Object.keys(advertisementType).map(
                                (check, idx) => (
                                    <Checkbox
                                        key={check}
                                        isDisabled={false}
                                        isChecked={advertisementType[check]}
                                        label={
                                            idx === 0 ? "매물광고" : "검색광고"
                                        }
                                        defaultValue={check}
                                        handleChange={(n, v) => {
                                            setAdvertisementType(n, v);
                                        }}
                                    />
                                ),
                            )}
                        </div>
                    </li>
                    <li>
                        <span css={subTitle}>광고상태</span>
                        <div css={checkBoxWrap}>
                            {Object.keys(advertisementStatus).map(
                                (check, idx) => (
                                    <Checkbox
                                        key={check}
                                        isDisabled={false}
                                        isChecked={advertisementStatus[check]}
                                        label={
                                            idx === 0
                                                ? "광고중"
                                                : idx === 1
                                                ? "광고 종료"
                                                : "관리자 삭제"
                                        }
                                        defaultValue={check}
                                        handleChange={(n, v) => {
                                            setAdvertisementStatus(n, v);
                                        }}
                                    />
                                ),
                            )}
                        </div>
                    </li>
                    <li>
                        <span css={subTitle}>담당관리자</span>
                        <div css={checkBoxWrap}>
                            {Object.keys(advertisementManager).map(
                                (check, idx) => (
                                    <Checkbox
                                        key={check}
                                        isDisabled={false}
                                        isChecked={advertisementManager[check]}
                                        label={idx === 0 ? "공통관리" : "있음"}
                                        defaultValue={check}
                                        handleChange={(n, v) => {
                                            setAdvertisementManager(n, v);
                                        }}
                                    />
                                ),
                            )}
                        </div>
                    </li>
                    <li>
                        <span css={subTitle}>검색</span>
                        <div>
                            <DropDown
                                handleChange={(e, text, value) => {
                                    value &&
                                        setFilters({
                                            ...filters,
                                            searchType: value,
                                        });
                                }}
                                data={dropList}
                                defaultValue={filters.searchType}
                            />
                            <Input
                                label=""
                                value={filters.searchValue ?? ""}
                                name="searchInput"
                                type="text"
                                size="medium"
                                handleChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        searchValue: e.target.value,
                                    });
                                }}
                                isDisabled={false}
                                errorMessage=""
                                placeholder="이름, 휴대폰번호, 사무소명, 사건번호, 광고번호, 소재지를 입력하세요"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default ExpertAdvertisementListFilter;

const filterWrap = css`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
`;

const subTitle = css`
    ${Common.textStyle.title6_R14}
    color: ${Common.colors.black};
    display: inline-block;
    width: 100px;
`;

const checkBoxWrap = css`
    display: flex;
    & > div {
        margin-right: 16px;
    }
`;
const dateButton = (isActive: boolean) => css`
    padding: 10px;
    margin: 5px;
    background-color: white;
    border: 1px solid gray;
    cursor: pointer;
    border-radius: 8px;
    ${isActive && { fontWeight: 700 }}
`;
