import { css } from "@emotion/react";
import { Button, ConfirmModal, IconButton, Input } from "@src/components";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CampusCountDownModal from "./CampusCountDownModal";
import { ConvertDateTimeNoSecond } from "@src/utils/dateUtil";
import FileUploadView from "@src/components/FileUploadView";
import { useCampusSaver, useCampusUpdate } from "@src/hooks/queries/useCampus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import { ContentState } from "draft-js";

interface Props {}

interface IEvent {
    eventTitle: string;
    eventStartAt: string;
    eventExpiredAt: string;
}

const CampusBasicForm: React.FC<Props> = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const checkCreate = location.pathname.includes("create");
    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty(),
    );
    const [isShowConfirmModal, setIsShowConfirmModal] = useState<number>(0);
    const [title, setTitle] = useState<string>("");
    const [order, setOrder] = useState<string>("1000");
    const [hashTag, setHashTag] = useState<string>("");
    const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
    const [sampleVideoUrl, setSampleVideoUrl] = useState<string>("");
    const [introImgUrl, setIntroImgUrl] = useState<string>("");
    const [contents, setContents] = useState<any>();
    const [eventInfo, setEventInfo] = useState<IEvent>({
        eventTitle: "",
        eventStartAt: "",
        eventExpiredAt: "",
    });
    const [showCheck, setShowCheck] = useState<any>();
    const [openCountdownDialog, setOpenCountdownDialog] =
        useState<boolean>(false);

    const rgbToHex = (r: any, g: any, b: any) =>
        "#" +
        [r, g, b]
            .map((x) => {
                const hex = Number(x).toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            })
            .join("");

    const updateEditorState = (state: any) => {
        setEditorState(state);
        const newData: any = convertToRaw(editorState.getCurrentContent());
        const newBlocks = convertToRaw(
            editorState.getCurrentContent(),
        ).blocks.map((item) => {
            const newInline = item.inlineStyleRanges.map((data: any) => {
                if (
                    data.style.includes("color-rgb") &&
                    !data.style.includes("bgcolor-rgb")
                ) {
                    const rgb = data.style.slice(7);
                    const rgbArray: any = rgb.match(/\d+/g);
                    const hex: any = rgbArray
                        ? rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2])
                        : data.style;

                    return { ...data, style: "color-" + hex };
                } else if (data.style.includes("bgcolor-rgb")) {
                    const rgb = data.style.slice(7);
                    const rgbArray: any = rgb.match(/\d+/g);
                    const hex: any = rgbArray
                        ? rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2])
                        : data.style;

                    return { ...data, style: "bgcolor-" + hex };
                } else {
                    return { ...data };
                }
            });
            if (newInline) {
                return { ...item, inlineStyleRanges: newInline };
            } else {
                return { ...item };
            }
        });
        newData.blocks = newBlocks;
        const html = draftjsToHtml(newData);

        if (
            convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
            convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
        ) {
            setContents("");
            return;
        }
        setContents(html);
    };

    const campusSaver = useCampusSaver(() => {
        setIsShowConfirmModal(0);
        navigate(-1);
    });

    const campusUpdate = useCampusUpdate(() => {
        setIsShowConfirmModal(0);
        navigate(-1);
    });

    const onClickSaveCampus = () => {
        const sendOrder = Number(order);
        const param = eventInfo.eventTitle
            ? {
                  title,
                  thumbnailUrl,
                  hashTag,
                  introImgUrl,
                  sampleVideoUrl,
                  contents,
                  order: sendOrder,
                  ...eventInfo,
              }
            : {
                  title,
                  thumbnailUrl,
                  hashTag,
                  introImgUrl,
                  sampleVideoUrl,
                  contents,
                  order: sendOrder,
              };
        campusSaver.mutate(param);
    };

    const onClickUpdateCampus = () => {
        const sendOrder = Number(order);
        const param = {
            title,
            thumbnailUrl,
            hashTag,
            introImgUrl,
            sampleVideoUrl,
            contents,
            order: sendOrder,
            ...eventInfo,
            ...showCheck,
            no: id,
        };
        campusUpdate.mutate(param);
    };

    const onClickDeleteEvent = () => {
        setIsShowConfirmModal(0);
        setEventInfo({ eventTitle: "", eventStartAt: "", eventExpiredAt: "" });
    };

    useEffect(() => {
        if (location.state) {
            const { param } = location.state as any;
            setTitle(param.title);
            setOrder(param.order);
            setThumbnailUrl(param?.thumbnailUrl ?? "");
            setHashTag(param?.hashTag ?? "");
            setIntroImgUrl(param?.introImgUrl ?? "");
            setSampleVideoUrl(param?.sampleVideoUrl ?? "");
            setContents(param?.contents ?? "");
            setEventInfo({
                eventExpiredAt: param?.eventExpiredAt,
                eventStartAt: param?.eventStartAt,
                eventTitle: param?.eventTitle ?? "",
            });
            setShowCheck({
                showUserCnt: param.showUserCnt,
                showReview: param.showReview,
            });
            const blocksFromHtml = htmlToDraft(param?.contents ?? "");
            if (blocksFromHtml) {
                const { contentBlocks, entityMap } = blocksFromHtml;

                const contentState =
                    ContentState.createFromBlockArray(contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
    }, []);

    return (
        <>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>강의명
                </p>
                <Input
                    errorMessage=""
                    handleChange={(evt) => setTitle(evt.target.value)}
                    label=""
                    onKeyDown={() => {}}
                    placeholder="강의명을 입력해주세요"
                    size="medium"
                    type="text"
                    value={title}
                />
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>강의노출 순서
                </p>
                <Input
                    errorMessage=""
                    handleChange={(evt) =>
                        !isNaN(Number(evt.target.value)) &&
                        Number(evt.target.value) <= 1000 &&
                        0 <= Number(evt.target.value) &&
                        setOrder(evt.target.value)
                    }
                    label=""
                    onKeyDown={() => {}}
                    placeholder="1~1000 사이 숫자를 입력해주세요"
                    size="medium"
                    type="text"
                    value={order}
                />
            </div>
            <div css={boxStyle} style={{ alignItems: "flex-start" }}>
                <p css={boxTitleStyle}>
                    <span>*</span>리스트용 썸네일
                </p>
                <div>
                    {thumbnailUrl ? (
                        <div css={imgBoxStyle}>
                            <img
                                src={thumbnailUrl}
                                alt=""
                                style={{ width: "7rem" }}
                            />
                        </div>
                    ) : (
                        <FileUploadView
                            bucket="missgo-campus"
                            onChangeFile={(url: any) =>
                                setThumbnailUrl(url.path)
                            }
                        />
                    )}
                    <div style={{ display: "flex", marginTop: "1rem" }}>
                        <Input
                            placeholder="주소를 입력해주세요."
                            value={thumbnailUrl}
                            size="medium"
                            handleChange={(e) => {
                                const { value } = e.target;
                                setThumbnailUrl(value);
                            }}
                            name={"bannderImageInput"}
                            // errorMessage={errorMessage.linkError}
                        />
                        <Button
                            label="복사"
                            color="purple"
                            size="small"
                            isDisabled={false}
                            handleClick={
                                () => {}
                                // handleCopyClipBoard(updateData.bannerImage)
                            }
                            styles={copyButton}
                        />
                    </div>
                </div>
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>맛보기 영상
                </p>
                <Input
                    errorMessage=""
                    handleChange={(evt) => setSampleVideoUrl(evt.target.value)}
                    label=""
                    onKeyDown={() => {}}
                    placeholder="맛보기 영상URL를 입력해주세요"
                    size="medium"
                    type="text"
                    value={sampleVideoUrl}
                />
            </div>
            <div css={boxStyle} style={{ alignItems: "flex-start" }}>
                <p css={boxTitleStyle}>카운트다운</p>
                <div css={tableRootStyle}>
                    <table>
                        <colgroup>
                            <col width="30%" />
                            <col width="30%" />
                            <col width="30%" />
                            <col width="10%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>문구</th>
                                <th>시작일시</th>
                                <th>종료일시</th>
                                <th>관리</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventInfo.eventTitle ? (
                                <tr css={whiteTrStyle}>
                                    <td>{eventInfo.eventTitle}</td>
                                    <td>
                                        {ConvertDateTimeNoSecond(
                                            new Date(
                                                eventInfo?.eventStartAt ?? 0,
                                            ),
                                        )}
                                    </td>
                                    <td>
                                        {ConvertDateTimeNoSecond(
                                            new Date(
                                                eventInfo?.eventExpiredAt ?? 0,
                                            ),
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                minWidth: "80px",
                                                width: "100%",
                                            }}
                                        >
                                            <IconButton
                                                type="trash"
                                                handleClick={() => {
                                                    setIsShowConfirmModal(2);
                                                }}
                                            />
                                            <IconButton
                                                type="edit"
                                                handleClick={() => {
                                                    setOpenCountdownDialog(
                                                        true,
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ) : (
                                <tr css={grayTrStyle}>
                                    <td colSpan={4}>
                                        <Button
                                            color="purple"
                                            size="small"
                                            label="카운트다운 등록"
                                            isDisabled={false}
                                            handleClick={() => {
                                                setOpenCountdownDialog(true);
                                            }}
                                            styles={{ display: "inline-block" }}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>해시태그</p>
                <Input
                    errorMessage=""
                    handleChange={(evt) => setHashTag(evt.target.value)}
                    label=""
                    onKeyDown={() => {}}
                    placeholder="입력 시 “#문구” 형식으로 입력하고,  2개 이상 입력 시 띄어쓰기로 구분해주세요  ex) #강아지 #고양이 #귀여운_강아지"
                    size="big"
                    type="text"
                    value={hashTag}
                />
            </div>
            <div css={boxStyle}>
                <p css={boxTitleStyle}>
                    <span>*</span>요약정보
                </p>
                <Editor
                    placeholder="강의 요약정보를 등록해주세요"
                    editorState={editorState}
                    onEditorStateChange={updateEditorState}
                    localization={{ locale: "ko" }}
                    editorStyle={{
                        height: "200px",
                        width: "100%",
                        border: "3px solid lightgray",
                        padding: "20px",
                    }}
                />
            </div>
            <div css={boxStyle} style={{ alignItems: "flex-start" }}>
                <p css={boxTitleStyle}>
                    <span>*</span>강의소개
                </p>
                <div>
                    {introImgUrl ? (
                        <div css={imgBoxStyle}>
                            <img
                                src={introImgUrl}
                                alt=""
                                style={{ width: "7rem" }}
                            />
                        </div>
                    ) : (
                        <FileUploadView
                            bucket="missgo-campus"
                            onChangeFile={(url: any) =>
                                setIntroImgUrl(url.path)
                            }
                        />
                    )}
                    <div style={{ display: "flex", marginTop: "1rem" }}>
                        <Input
                            placeholder="주소를 입력해주세요."
                            value={introImgUrl}
                            size="medium"
                            handleChange={(e) => {
                                const { value } = e.target;
                                setIntroImgUrl(value);
                            }}
                            name={"bannderImageInput"}
                            // errorMessage={errorMessage.linkError}
                        />
                        <Button
                            label="복사"
                            color="purple"
                            size="small"
                            isDisabled={false}
                            handleClick={() => {}}
                            styles={copyButton}
                        />
                    </div>
                </div>
            </div>
            <div css={btnRootStyle}>
                <Button
                    label={checkCreate ? "저장" : "수정"}
                    color="purple"
                    size="medium"
                    isDisabled={false}
                    handleClick={() => setIsShowConfirmModal(1)}
                    styles={copyButton}
                />
            </div>
            <CampusCountDownModal
                open={openCountdownDialog}
                onCloseView={() => setOpenCountdownDialog(false)}
                eventInfo={eventInfo}
                setEventInfo={setEventInfo}
            />
            <ConfirmModal
                isModalShow={isShowConfirmModal !== 0}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowConfirmModal(0);
                }}
                handleOk={() => {
                    isShowConfirmModal === 1
                        ? id
                            ? onClickUpdateCampus()
                            : onClickSaveCampus()
                        : onClickDeleteEvent();
                }}
            >
                <>
                    {isShowConfirmModal === 1
                        ? "정말 저장하시겠습니까?"
                        : "삭제하시겠습니까?"}
                </>
            </ConfirmModal>
        </>
    );
};

export default CampusBasicForm;

const boxStyle = css`
    display: flex;
    align-items: center;
    margin: 20px 0 40px;
`;

const boxTitleStyle = css`
    font-size: 1rem;
    font-weight: 500;
    width: 10rem;
    span {
        color: #ff0000;
    }
`;

const btnRootStyle = css`
    display: flex;
    justify-content: flex-end;
`;

const copyButton = css`
    margin-left: 20px;
`;

const tableRootStyle = css`
    width: 100%;
    table {
        width: 88%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 10px;
        }
        td {
            padding: 15px 10px;
            text-align: center;
        }
    }
`;

const grayTrStyle = css`
    background-color: #eeeeee;
    border-bottom: 1px solid #b5b5b5;
`;

const whiteTrStyle = css`
    background-color: #ffffff;
    border-bottom: 1px solid #b5b5b5;
`;

const imgBoxStyle = css`
    width: 7rem;
    height: 7rem;
    overflow-y: scroll;
    .scroll {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`;
