import { arrayToDropdown } from "@src/utils/dropdownUtil";
const checkBoxList: any[] = [
    { key: "counterForce", value: "대항력 유무" },
    { key: "rightOfLien", value: "유치권" },
    { key: "rightOfTombBase", value: "분묘기지권" },
    { key: "shareSale", value: "지분매각" },
    { key: "landBid", value: "토지만입찰" },
    { key: "unregisteredOfLandRight", value: "대지권미등기" },
    { key: "needOfLandCertification", value: "농취증필요" },
    { key: "statutorySuperficies", value: "법정지상권" },
    { key: "violationBuilding", value: "위반건축물" },
    { key: "buildingBid", value: "건물만입찰" },
    { key: "separateRegisteredLand", value: "토지별도등기" },
];
const progressStatus = ["신건", "진행중", "낙찰", "유찰", "매각예정", "기타"];
const ddlProgressStatus = arrayToDropdown(progressStatus);

export { checkBoxList, ddlProgressStatus };
