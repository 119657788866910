import styled from "@emotion/styled";
import { EditModal, Title, ViewMoreTable } from "@src/components";
import React, { useEffect } from "react";
import { getUserRoadExpansionEditModalColumns } from "./UserRoadExpansionEditModalColumns";

interface UserGoStopEditModalProps {
    setEditTime: (time: string) => void;
    setIsShowEditModal: (isShow: boolean) => void;
    isShowEditModal: boolean;
    item: any;
    setIsShowEditConfirmModal: (isShow: boolean) => void;
}

const UserGoStopEditModal = ({
    setEditTime,
    setIsShowEditModal,
    isShowEditModal,
    item,
    setIsShowEditConfirmModal,
}: UserGoStopEditModalProps) => {
    const getColumns = getUserRoadExpansionEditModalColumns(setEditTime);
    return (
        <>
            <EditModal
                isModalShow={isShowEditModal}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowEditConfirmModal(true);
                    setIsShowEditModal(false);
                }}
                handleOpenModal={() => {
                    setIsShowEditModal(false);
                }}
                size="big"
                title="이용권 정보 수정"
            >
                <>
                    <StyledEditContents>
                        <Title title="충전내역" />

                        <ViewMoreTable
                            FixedHeight={100}
                            columns={getColumns}
                            data={[item]}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </StyledEditContents>
                </>
            </EditModal>
        </>
    );
};

export default UserGoStopEditModal;

const StyledEditContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
        & > button {
            display: none;
        }
    }
`;
