import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { saleStatusSampleData } from "@src/data/AuctionData";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.itemsAuctionSaleItem}/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData.data.data;
    return parse(originData);
};

const parse = (originData: any) => {
    const { table, eval_summ, eval_note, eval_url } = originData;
    let list: any[] = [];
    table.map((cell: any) =>
        list.push({
            type: cell.type,
            location: cell.address,
            no: cell.num,
            detail: cell.detail,
        }),
    );
    return {
        list,
        options: eval_note,
        appraisal: eval_summ,
        documentUrl: eval_url,
    };
};
const useGetSalesStatus = (productNo: any) => {
    return useQuery([queryKeys, productNo], () => fetchData(productNo), {
        // initialData: saleStatusSampleData,
    });
};
export { useGetSalesStatus };
