import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import "@src/styles/CommonTable.css";
import { Calendar, Input } from "@src/components";
import { useSelector } from "react-redux";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { ConvertDate } from "@src/utils/dateUtil";
import { OccupancyRelationType } from "@src/types";
import { dateParse } from "@src/utils/dateUtil";

interface IOccupancyRelation {
    isUpdate: boolean;
    handleUpdate?: Function;
}
function OccupancyRelationContainer({
    isUpdate = false,
    handleUpdate,
}: IOccupancyRelation) {
    const headerMeta = [
        "점유관계",
        "성명",
        "계약일자",
        "전입일자",
        "확정일자",
        "보증금(원)",
        "차임(원)",
        "임차부분",
    ];
    const defaultData = useSelector((state: any) => state.occupancyRelation);
    const [tableData, setTableData] =
        useState<OccupancyRelationType[]>(defaultData);
    useEffect(() => {
        setTableData([...defaultData]);
    }, [defaultData]);

    const handleChange = (updateData: OccupancyRelationType, index: number) => {
        let newTableData = [...tableData];
        newTableData[index] = updateData;
        setTableData([...newTableData]);
    };

    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);
    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: any) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isUpdate={isUpdate}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

const TableRow = ({ data, isUpdate, handleChange }: any) => {
    const [rowData, setRowData] = useState<OccupancyRelationType>(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const {
        id,
        ocpRelation,
        name,
        contractDate,
        moveInDate,
        fixedDate,
        deposit,
        rentPrice,
        rentPart,
    } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRowData({
            ...rowData,
            [name]: value,
        });
    };
    const onChangeDate = (name: string, value: number) => {
        setRowData((prev) => ({
            ...prev,
            [name]: ConvertDate(value, "yyyy.MM.dd"),
        }));
    };
    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);

    return (
        <tr key={id} style={isUpdate ? { borderBottom: "none" } : undefined}>
            <td>
                <Input
                    name="ocpRelation"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate ? ocpRelation : ConvertEmptyValue(ocpRelation)
                    }
                />
            </td>
            <td>
                <Input
                    name="name"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? name : ConvertEmptyValue(name)}
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(contractDate) || 1577804400000}
                    getUserDate={(value) => onChangeDate("contractDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(moveInDate) || 1577804400000}
                    getUserDate={(value) => onChangeDate("moveInDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(fixedDate) || 1577804400000}
                    getUserDate={(value) => onChangeDate("fixedDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
            <td>
                <Input
                    name="deposit"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? deposit : resultPrice(deposit)}
                />
            </td>
            <td>
                <Input
                    name="rentPrice"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rentPrice : resultPrice(rentPrice)}
                />
            </td>
            <td>
                <Input
                    name="rentPart"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rentPart : ConvertEmptyValue(rentPart)}
                />
            </td>
        </tr>
    );
};
const calendarItem = css`
    & input {
        height: 47px;
    }
`;
export default OccupancyRelationContainer;
