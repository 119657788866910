import styled from "@emotion/styled";
import { ConfirmModal, Title } from "@src/components";
import { useGetPartnerDetail, useUpdatePartner } from "@src/hooks/queries/usePartner";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PartnerDetailBox from "./PartnerDetailBox";
import PayBackInfoBox from "./PayBackInfoBox";
import backArrow from "@src/assets/backArrow.png";

const PartnerDetail = () => {
    const { no } = useParams();
    const navigate = useNavigate();
    const [isShowCompleteModal, setIsShowCompleteModal] = useState<boolean>(false);

    const [detailData, setDetailData] = useState<any>();

    const tryGetDetailData = async () => {
        const response = await useGetPartnerDetail(no);
        setDetailData(response);
    };
    const tryPutPartner = () => {
        const newDetailData = { ...detailData };
        delete newDetailData.no;
        delete newDetailData.createdAt;
        newDetailData.userNo = detailData.user.no;
        delete newDetailData.user;
        no &&
            putPartner.mutate({
                data: { ...newDetailData, status: "complete" },
                no: Number(no),
            });
    };

    const putPartner = useUpdatePartner(() => {
        tryGetDetailData();
        setIsShowCompleteModal(false);
    });

    useEffect(() => {
        tryGetDetailData();
    }, []);

    return (
        <>
            <StyledWrap>
                <div className="backButton" onClick={() => navigate(-1)}>
                    <img src={backArrow} alt="" width={20} />
                </div>
                <Title title="파트너 상세" />
                {detailData && (
                    <>
                        <PartnerDetailBox setIsShowCompleteModal={setIsShowCompleteModal} detailData={detailData} />
                        {detailData.status !== "request" && detailData.status !== "reject" && (
                            <PayBackInfoBox detailData={detailData} />
                        )}
                    </>
                )}
                <ConfirmModal
                    children={"정말 승인 하시겠습니까?"}
                    isModalShow={isShowCompleteModal}
                    handleOk={() => {
                        tryPutPartner();
                    }}
                    handleCancel={() => {
                        setIsShowCompleteModal(false);
                    }}
                    buttonText={"확인"}
                />
            </StyledWrap>
        </>
    );
};

export default PartnerDetail;

const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 15px;

    .backButton {
        width: 40px;
        height: 40px;
    }
`;
