import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import {
    BasePushListType,
    BasePushType,
    PushCategoryType,
    PushListType,
    PushType,
    ReceivePushListType,
} from "@src/pages/notify/types";
import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { ResponseMissgoApi } from "@src/interfaces/MissgoApi";

const useGetPushCategories = () => {
    const getCategories = async () => {
        const response = await axiosInstance.get(
            `${axiosPath.postbox}/categories`,
        );
        return response.data.data.list as PushCategoryType[];
    };

    return useQuery<PushCategoryType[], Error>(
        [queryKeys.PUSH_CATEGORY_LIST],
        () => getCategories(),
    );
};

const fetchData = async (params: ReceivePushListType, listNo: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestNotifications}/push-groups/${listNo}/receivers`,
        {
            params,
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData = responseData.list;

    return originData.length
        ? {
              result: parse(originData),
              total: responseData.totalCount,
          }
        : { result: [], total: 0 };
};

const parse = (originData: ReceivePushListType[]) => {
    const newData = originData.map((data: any, idx: any) => ({
        listNo: idx,
        createdAt: data?.createdAt ?? "",
        id: data?.id ?? "",
        name: data?.name ?? "",
        gender: data?.gender ?? "",
        isOpened: data?.isOpened,
        phone: data?.phone ?? "",
        status: data?.status,
        birthdate: data?.birthdate ?? "",
    }));

    return newData;
};

const useGetPushReceivers = (params: ReceivePushListType, listNo: string) => {
    return useQuery<any, Error>(
        [queryKeys.PUSH_RECEIVERS, { ...params, listNo }],
        () => fetchData(params, listNo),
    );
};
export { useGetPushCategories, useGetPushReceivers };
