import { ConfirmModal, EditModal, ViewMoreTable } from "@src/components";
import React, { useEffect, useState } from "react";
import { UserSugangListColumns } from "./UserSugangListColumns";
import {
    useCampusDelete,
    useUserCampusList,
} from "@src/hooks/queries/useUserCampus";
import { css } from "@emotion/react";
import UserSugangRefundModal from "./UserSugangRefundModal";
import UserSugangDetailModal from "./UserSugangDetailModal";

interface Props {
    open: boolean;
    onCloseView: () => void;
    id?: string;
}

const UserSugangInfoModal: React.FC<Props> = ({ open, onCloseView, id }) => {
    const [openRefundModal, setOpenRefundModal] = useState<boolean>(false);
    const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
    const [selectedLecture, setSelectedLecture] = useState<any>();
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [registerNo, setRegisterNo] = useState<number>(0);

    const columns = UserSugangListColumns(
        () => setOpenRefundModal(true),
        () => setOpenUpdateModal(true),
        (val: any) => setSelectedLecture(val),
        () => setOpenDeleteModal(true),
        (registerNum: number) => setRegisterNo(registerNum),
    );

    const { data, refetch } = useUserCampusList(id ?? "", {
        page: 1,
        limit: 999,
    });

    const tryDeleteCampusDelete = useCampusDelete(
        () => {
            setOpenDeleteModal(false), refetch();
        },
        () => setOpenDeleteModal(false),
    );

    useEffect(() => {
        id && !openRefundModal && !openUpdateModal && refetch();
    }, [id, openRefundModal, openUpdateModal]);

    return (
        <>
            <EditModal
                handleBtnClick={function noRefCheck() {}}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="수강강의 정보"
                isModalShow={open}
                dimClose={true}
                deleteButton={true}
            >
                <div css={rootStyle}>
                    <p>강의 목록</p>
                    <ViewMoreTable
                        FixedHeight={300}
                        columns={columns}
                        data={data?.result ?? []}
                        handleViewMore={function noRefCheck() {}}
                        isRefetching={true}
                    />
                </div>
            </EditModal>
            <UserSugangRefundModal
                open={openRefundModal}
                onCloseView={() => {
                    setOpenRefundModal(false);
                    setSelectedLecture(null);
                }}
                info={selectedLecture}
            />
            <UserSugangDetailModal
                open={openUpdateModal}
                onCloseView={() => {
                    setOpenUpdateModal(false);
                    setSelectedLecture(null);
                }}
                info={selectedLecture}
            />
            <ConfirmModal
                isModalShow={openDeleteModal}
                buttonText="확인"
                handleCancel={() => setOpenDeleteModal(false)}
                handleOk={() => {
                    // useCampusDelete(registerNo);
                    tryDeleteCampusDelete.mutate(registerNo);
                }}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default UserSugangInfoModal;

const rootStyle = css`
    width: 80vw;
    & > div {
        & > button {
            display: none;
        }
    }
`;
