export const PartnerLabelState: { [key: string]: any } = {
    complete: ["승인 완료", "complete"],
    request: ["파트너 신청", "request"],
    reject: ["신청 반려", "reject"],
    cancel: ["파트너 취소", "cancel"],
};

export const PartnerDropDown = [
    {
        id: 1,
        key: "request",
        value: "파트너 신청",
    },
    {
        id: 2,
        key: "complete",
        value: "승인 완료",
    },
];

export const UpdatePartnerDropDown = [
    {
        id: 1,
        key: "request",
        value: "파트너 신청",
    },
    {
        id: 2,
        key: "complete",
        value: "승인 완료",
    },
    {
        id: 3,
        key: "reject",
        value: "신청반려",
    },
    {
        id: 4,
        key: "cancel",
        value: "파트너 취소",
    },
    {
        id: 5,
        key: "complete",
        value: "정산완료",
    },
    {
        id: 6,
        key: "complete",
        value: "정산대기",
    },
];
