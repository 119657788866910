import { Constants } from "./Constants";

export const RESIGNED_USER = "탈퇴";
export const RESIGNED_WAITING_USER = "탈퇴(가입제한)";
export const DORMANCY_USER = "휴면";
export const SUSPENDED_USER = "정지";
export const NORMAL_USER = "정상";

export const UserStatus: { [key: number]: any } = {
    0: [RESIGNED_USER, "black"],
    1: [RESIGNED_WAITING_USER, "black"],
    100: [SUSPENDED_USER, "lightGray"],
    101: [DORMANCY_USER, "gray"],
    102: [SUSPENDED_USER, "lightGray"],
    200: [NORMAL_USER, "purple"],
};

export const ExpertStatus = [
    { code: 100, text: Constants.CERTIFICATION_APPLICATION },
    { code: 101, text: Constants.CERTIFICATED_DENIED },
    { code: 102, text: Constants.CERTIFICATED_STOP },
    { code: 200, text: Constants.CERTIFICATED },
];

export const SubscriptionStatus = [
    { code: 0, text: "미구독" },
    { code: 100, text: "만료" },
    { code: 101, text: "해지" },
    { code: 102, text: "정지" },
    { code: 200, text: "구독중" },
];

export const ConnectionUserStatus: { [key: string]: any } = {
    파트너: ["파트너", "pink"],
    "파트너 종료": ["파트너 종료", "gray"],
    "파트너 해지": ["파트너 해지", "black"],
    "페이백 대상": ["페이백 대상", "purple"],
};

export const SettlementStatus: { [key: string]: any } = {
    정산대기: ["정산 대기", "lightBlue"],
    정산완료: ["정산 완료", "blue"],
};
