import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { ViewMoreTable, Title, Button, IconButton } from "@src/components";
import { useNavigate } from "react-router-dom";
import { useUserRecentViewHistory } from "@src/hooks/queries/useUserRecentViewHistory";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";

interface RecentViewListProps {
    userNo: number;
}
function RecentViewListContainer({ userNo }: RecentViewListProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSearch, setIsSearch] = useState(false);
    const [params, setParams] = useState<{ user_no: number; next_it?: string }>(
        { user_no: userNo },
    );
    const [listData, setListData] = useState<any[]>([]);
    const columns = [
        {
            Header: "열람 일시",
            accessor: "date",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "매물번호",
            accessor: "productNo",
            Cell: ({ cell: { value } }: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>;
            },
        },
        {
            Header: "관심매물 여부",
            accessor: "favorite",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        {value ? "O" : "X"}
                    </div>
                );
            },
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ cell: { value }, row: { values } }: any) => {
                return (
                    <div style={{ margin: "0 auto", width: "fit-content" }}>
                        <IconButton
                            type={"arrow"}
                            color={"black"}
                            handleClick={() => {}}
                            isDisabled={false}
                        />
                    </div>
                );
            },
        },
    ];
    const setInit = () => {
        setListData([]);
        setParams({ user_no: userNo });
    };
    const getUserRecentViewHistory = () => {
        dispatch(startLoading());
        setInit();
        setIsSearch(true);
    };
    const { isLoading, data, isError, refetch, isRefetching } =
        useUserRecentViewHistory(params, isSearch);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setListData(data);
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        }
        setIsSearch(false);
    }, [isLoading, isError, data]);
    useEffect(() => {
        if (!isRefetching) {
            dispatch(endLoading());
        }
    }, [isRefetching]);
    useEffect(() => {
        if (data) {
            let lastIndex = data.length - 1;
            setParams({
                ...params,
                next_it: data[lastIndex]?.nextItem,
            });
        }
    }, [data]);

    const handleViewMore = () => {
        refetch();
    };
    return (
        <div css={RecentViewListWrap}>
            <div>
                <Title title="최근 열람내역" size={"small"} />
                <Button
                    label={"조회"}
                    color={"gd"}
                    size={"medium"}
                    isDisabled={false}
                    handleClick={getUserRecentViewHistory}
                    deleteBtn={false}
                    defaultValue={"조회하기"}
                />
            </div>
            <ViewMoreTable
                columns={columns}
                handleViewMore={handleViewMore}
                data={listData}
                isRefetching={isRefetching}
                FixedHeight={500}
            />
        </div>
    );
}
const RecentViewListWrap = css`
    width: 100%;
    min-width: 980px;
    margin-top: 70px;
    & > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
export default RecentViewListContainer;
