import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { TextBox } from "@src/components";
import { useSelector } from "react-redux";
import { LocalStatusType } from "@src/types";

interface ILocationStatusContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
}

function LocationStatusContainer({
    isUpdate = false,
    handleUpdate,
}: ILocationStatusContainer) {
    const defaultData = useSelector((state: any) => state.locationStatus);
    const [locationData, setLocationData] =
        useState<LocalStatusType>(defaultData);

    useEffect(() => {
        handleUpdate && handleUpdate(locationData);
    }, [locationData]);

    useEffect(() => {
        setLocationData({ ...defaultData });
    }, [defaultData]);

    const onChangeField = (name: string, value: string) => {
        const obj = { [name]: value };
        setLocationData({ ...locationData, ...obj });
    };

    const { metroStation, locationStatus, option } = locationData;
    return (
        <div css={locationInfoDiv}>
            <ul>
                <li className="subTitle">위치 및 부근 현황</li>
                <li>
                    <ul>
                        <li>
                            <TextBox
                                defaultText={metroStation || ""}
                                label=""
                                placeholder=""
                                size="dynamic"
                                isDisabled={!isUpdate}
                                handleChange={(value) =>
                                    onChangeField("metroStation", value)
                                }
                            />
                        </li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li className="subTitle">이용 현황</li>
                <li>
                    <ul>
                        <li>
                            <TextBox
                                defaultText={locationStatus || ""}
                                label=""
                                placeholder=""
                                size="dynamic"
                                isDisabled={!isUpdate}
                                handleChange={(value) =>
                                    onChangeField("locationStatus", value)
                                }
                            />
                        </li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li className="subTitle">기타 사항</li>
                <li>
                    <ul>
                        <li>
                            <TextBox
                                defaultText={option || ""}
                                label=""
                                placeholder=""
                                size="dynamic"
                                isDisabled={!isUpdate}
                                handleChange={(value) =>
                                    onChangeField("option", value)
                                }
                            />
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}

LocationStatusContainer.propTypes = {};
const locationInfoDiv = css`
    color: ${Common.colors.gray500};
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > ul {
        display: flex;
        .subTitle {
            width: 140px;
            ${Common.textStyle.text1_R13}
            padding-top: 12px;
        }
        li:nth-of-type(even) {
            width: 100%;
        }
    }
    input {
        width: 400px;
    }
    input:disabled {
        background-color: transparent;
        border-bottom: none;
        color: #222;
    }
    input:disabled + p {
        display: none;
    }
    input[name="officeName"] {
        font-weight: bold;
    }
`;
export default LocationStatusContainer;
