import axios from "axios";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "./axiosConfig";

interface uploadImageProps {
    url: string;
    file: File;
    fields: { [key: string]: string };
    thumb: string;
}

const getImageURLToS3 = (file: File): Promise<uploadImageProps> => {
    let fileType = file.type;
    fileType = fileType.replace("image/", "");
    return new Promise((resolve, rejects) => {
        axios
            .get(`${axiosPath.newsImage}/${fileType}`)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    const { result, thumb } = data.data;
                    const { url, fields } = result;
                    resolve({ url, file, fields, thumb });
                } else {
                    rejects("URL을 가져오는데 실패했습니다.");
                }
            })
            .catch((error) => {
                rejects(error);
            });
    });
};

const uploadImageToS3 = async ({
    url,
    file,
    fields,
    thumb,
}: uploadImageProps): Promise<string> => {
    const formData = new FormData();
    Object.keys(fields).forEach((key: string) => {
        formData.append(key, fields[key]);
    });
    formData.append("Content-Type", file.type);
    formData.append("file", file);

    const presignedUrl = url.replace("https://s3.amazonaws.com", "");
    return new Promise((resolve, rejects) => {
        axios
            .post(presignedUrl, formData)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    resolve(thumb);
                } else {
                    rejects("파일을 저장하는데 실패했습니다.");
                }
            })
            .catch((error) => {
                rejects(error);
            });
    });
};

const uploadFile = async (bucket: string, data: any) => {
    const formData = new FormData();
    // formData.append(
    //     "data",
    //     new Blob([JSON.stringify({ fileType: data.type })], {
    //         type: "application/json",
    //     }),
    // );
    data.map((d: any) => formData.append("file", d));
    return new Promise((resolve, rejects) => {
        axiosInstance
            .post(`${axiosPath.nestUpload}/${bucket}`, formData)
            .then((res: any) => {
                const { eTag, bucket, path, size } = res;
                resolve(res);
            })
            .catch((err) => {
                console.log("err>>", err);
                rejects(err);
            });
    });
};

export { getImageURLToS3, uploadImageToS3, uploadFile };
