import { css } from "@emotion/react";
import { ConfirmModal, EditModal } from "@src/components";
import {
    useCampusCouponSaver,
    useCampusCoupons,
} from "@src/hooks/queries/useCampus";
import React, { useEffect, useState } from "react";

interface Props {
    onCloseView: () => void;
    open: boolean;
    id?: string;
}
const CampusCouponListModal: React.FC<Props> = ({ onCloseView, open, id }) => {
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);
    const { data, refetch } = useCampusCoupons({ limit: 999 }, false);
    // saleType [상품 타입] 0: NOT_DISPLAYED, 1: DISPLAYED, 2: PARTNER_DISPLAYED, 3: EMPLOYEE,
    // 4: FREE_COUPON, 5: PAID_COUPON, 6: AOS, 7: IOS, 8: PG

    const saverCoupon = useCampusCouponSaver(
        () => {
            setIsShowConfirmModal(false);
            setSelectedProduct(null);
            onCloseView();
        },
        () => setIsShowConfirmModal(false),
    );

    const onClickSave = () => {
        const param = { no: id, couponNo: selectedProduct?.no };
        saverCoupon.mutate(param);
    };

    useEffect(() => {
        open && refetch();
        return () => setSelectedProduct(null);
    }, [open]);

    return (
        <>
            <EditModal
                buttonText="연결"
                handleBtnClick={() =>
                    selectedProduct
                        ? setIsShowConfirmModal(true)
                        : onCloseView()
                }
                handleOpenModal={onCloseView}
                size="fitContent"
                title="쿠폰상품 조회"
                isModalShow={open}
                checkCloseModal={true}
            >
                <div css={tableRootStyle}>
                    <table>
                        <colgroup>
                            <col width="150px" />
                            <col width="550px" />
                        </colgroup>
                        <tbody>
                            {data?.list?.map((d: any, idx: number) => (
                                <tr
                                    css={
                                        selectedProduct?.no === d.no
                                            ? selectedStyle
                                            : ""
                                    }
                                    key={idx.toString()}
                                    aria-hidden
                                    onClick={() => setSelectedProduct(d)}
                                >
                                    <td className="title">
                                        <p>상품명</p>
                                        <p>상품코드</p>
                                    </td>
                                    <td>
                                        <p>{d.name}</p>
                                        <p>{d.productCode}</p>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => setIsShowConfirmModal(false)}
                handleOk={() => {
                    onClickSave();
                }}
            >
                연결하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default CampusCouponListModal;

const tableRootStyle = css`
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    table {
        width: 100%;
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        .title {
            p {
                line-height: 300%;
                font-weight: 600;
                font-size: 1rem;
            }
        }
        tr {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            p {
                line-height: 300%;
                font-size: 1rem;
            }
            padding: 15px 10px;
            text-align: center;
            white-space: nowrap;
            word-break: keep-all;
            text-overflow: ellipsis;
            cursor: pointer;
        }
    }
`;

const selectedStyle = css`
    background-color: #e8f5e9;
`;
