import { Button, IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { useUpdateDeungiState } from "@src/hooks/queries/useDeungi";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

export const getColumns = (
    setCancelIsModalShow: Dispatch<SetStateAction<boolean>>,
    setDeungiId: Dispatch<SetStateAction<string>>,
    setIsReopenModalShow: Dispatch<SetStateAction<boolean>>,
) => [
    {
        Header: "열람일시",
        accessor: "startAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "fit-content",
                    padding: "0 4px",
                    minWidth: "120px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "열람권 사용일시",
        accessor: "usedAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "fit-content",
                    padding: "0 4px",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "열람권번호",
        accessor: "ticketNo",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value
                        ? value === -1
                            ? "스탠다드 무료열람"
                            : value
                        : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "이름",
        accessor: "name",
        Cell: ({ cell: { value }, row }: any) => {
            const navigate = useNavigate();

            const userNo = row.original.userIdx;
            return (
                <div
                    style={{
                        minWidth: "100px",
                        width: "100%",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {value ? (
                        <div
                            onClick={() => {
                                navigate(`/users/detail/${userNo}`);
                            }}
                        >
                            {value}
                        </div>
                    ) : (
                        INVALID_VALUE
                    )}
                </div>
            );
        },
    },
    {
        Header: "이메일",
        accessor: "email",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        // minWidth: "50px",
                        width: "fit-content",
                        padding: "0 4px",
                        textAlign: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "부동산 고유번호",
        accessor: "pin",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        minWidth: "50px",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "구분",
        accessor: "itemType",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "100%",
                    minWidth: "50px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "소재지",
        accessor: "address",
        Cell: ({ cell: { value, row } }: any) => {
            const url = row.original.pdfUrl;
            const deungiState = row.original.deungiState;
            return (
                <div
                    style={{
                        width: "100%",
                        minWidth: "50px",
                        height: "50px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {deungiState === "열람완료" ? (
                        <a href={url} target="_blank" rel="noreferrer">
                            {value ? value : INVALID_VALUE}
                        </a>
                    ) : (
                        <div>{value ? value : INVALID_VALUE}</div>
                    )}
                </div>
            );
        },
    },
    {
        Header: "등기상태",
        accessor: "deungiType",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    width: "100%",
                    minWidth: "50px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "열람상태",
        accessor: "deungiState",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        width: "100%",
                        minWidth: "50px",
                        height: "50px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {value === "열람완료" && (
                        <Label text={value} color="purple" />
                    )}
                    {value === "결제실패" && (
                        <Label text={value} color="yellow" />
                    )}
                    {(value === "열람실패" || value === "재열람실패") && (
                        <Label text={value} color="orange" />
                    )}
                    {value === "열람취소" && <Label text={value} color="red" />}
                    {(value === "대기중" ||
                        value === "결제진행" ||
                        value === "결제완료" ||
                        value === "열람진행" ||
                        value === "재열람") && (
                        <Label text={value} color="purple000" />
                    )}
                </div>
            );
        },
    },
    {
        Header: "관리",
        accessor: "edit",
        Cell: ({ cell: { value, row } }: any) => {
            const deungiState = row.original.deungiState;
            const deungiId = row.original.id;

            return (
                <div
                    style={{
                        width: "100%",
                        minWidth: "50px",
                        height: "50px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {deungiState === "열람취소" ? (
                        <Button
                            color="purple"
                            handleClick={() => {
                                setDeungiId(deungiId);
                                setIsReopenModalShow(true);
                            }}
                            label="재열람"
                            size="small"
                        />
                    ) : (
                        <Button
                            color="purple"
                            handleClick={() => {
                                setDeungiId(deungiId);
                                setCancelIsModalShow(true);
                            }}
                            label="열람 취소"
                            size="small"
                        />
                    )}
                </div>
            );
        },
    },
];
