import React, { ChangeEvent, KeyboardEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface IInput {
    label?: string;
    placeholder: string;
    type?: "text" | "number" | "password";
    size: "small" | "medium" | "big" | "dynamic" | "default";
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    isDisabled?: boolean;
    errorMessage?: string;
    name?: string;
    value?: string | number;
    required?: boolean;
    min?: number;
    max?: number;
    maxLength?: number;
    border?: boolean;
    color?: "success" | "error";
    onKeyDown?: (evt: KeyboardEvent<HTMLInputElement>) => void;
    readonly?: boolean;
}

function Input({
    label,
    placeholder,
    type = "text",
    size,
    handleChange,
    isDisabled = false,
    errorMessage,
    name,
    value,
    required = false,
    border = false,
    color,
    onKeyDown,
    readonly,
    ...rest
}: IInput) {
    const styles = () => {
        const arr = [
            inputStyle,
            sizes[size],
            disabledStyle,
            border ? borderInputFocusStyle : {},
            border
                ? {
                      border: `1.5px solid ${Common.colors.gray200}`,
                      borderBottomColor: `${Common.colors.gray200}`,
                      borderRadius: "8px",
                  }
                : {
                      border: "none",
                      borderBottom: `1.5px solid ${Common.colors.gray200}`,
                  },
            color
                ? color === "success"
                    ? { color: `${Common.colors.success}` }
                    : { color: `${Common.colors.error}` }
                : "",
        ];
        return errorMsg ? arr.concat(errorStyle) : arr;
    };
    let errorMsg = errorMessage;

    return (
        <>
            {required && <strong css={requiredStyle}>*</strong>}
            {label && <span css={labelStyle}>{label}</span>}
            <input
                type={type}
                name={name}
                css={styles}
                placeholder={placeholder}
                onChange={handleChange}
                disabled={isDisabled}
                value={value}
                {...rest}
                onKeyDown={onKeyDown ?? undefined}
                readOnly={readonly}
            />
            {!!errorMessage && <p>{errorMsg}</p>}
        </>
    );
}

const inputStyle = css`
    width: 328px;
    height: 48px;
    outline: none;
    background-color: ${Common.colors.white};
    white-space: nowrap;
    transition: all 0.4s;
    text-indent: 12px;
    ${Common.textStyle.title6_R14}

    &:focus {
        border-bottom-color: ${Common.colors.purple500};
    }
    & + p {
        height: 22px;
    }
`;

const borderInputFocusStyle = css`
    &:focus {
        border-color: ${Common.colors.purple500} !important;
    }
`;

const sizes: { [index: string]: any } = {
    small: css`
        width: 136px;
    `,
    medium: css`
        width: 550px;
    `,
    big: css`
        width: 800px;
    `,
    dynamic: css`
        display: inline-block;
        width: 100%;
    `,
    default: css`
        width: 328px;
    `,
};

const errorStyle = css`
    border-bottom-color: ${Common.colors.error};
    & + p {
        ${Common.textStyle.body4_R12}
        width: 328px;
        text-align: left;
        padding-left: 12px;
        color: ${Common.colors.error};
        margin-bottom: 0;
        padding-left: 86px;
    }
`;
const disabledStyle = css`
    &:disabled {
        border-radius: 4px;
        background-color: ${Common.colors.gray200};
        color: ${Common.colors.gray700};
    }
`;

const requiredStyle = css`
    padding-right: 5px;
    color: ${Common.colors.error};
`;

const labelStyle = css`
    display: inline-block;
    vertical-align: bottom;
    line-height: 42px;
    ${Common.textStyle.title5_M14}
`;
export default Input;
