import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

const fetchUserGoStopData = async (userNo: number, gostopType: number) => {
    const response = await axiosInstance.get(`${axiosPath.goStop}/${userNo}`, {
        params: { gostopType: gostopType },
    });
    return response.data;
};

const fetchGoStopItems = async (params: { limit?: number; page?: number; userNo: number; gostopType: number }) => {
    const response = await axiosInstance.get(`${axiosPath.goStop}/payment/${params.userNo}`, {
        params: { page: params.page, limit: params.limit, gostopType: params.gostopType },
    });
    return response.data;
};

export const useGetUserGoStopData = (userNo: number, enabled: boolean = false) => {
    return useQuery(["getUserGoStopData", userNo], () => fetchUserGoStopData(userNo, 1), {
        enabled,
    });
};

export const useGetUserStoreGoStopData = (userNo: number, enabled: boolean = false) => {
    return useQuery(["getUserStoreGoStopData", userNo], () => fetchUserGoStopData(userNo, 2), {
        enabled,
    });
};

export const useGetGoStopItems = (
    params: { limit?: number; page?: number; userNo: number; gostopType: number },
    enabled: boolean = false,
) => {
    return useQuery(["getGoStopItems", params], () => fetchGoStopItems(params), { enabled });
};

export const useEditGoStopExpiredAt = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: { gostopNo: number; expiredAt: string }) => {
            try {
                const body = {
                    expiredAt: params.expiredAt,
                };
                const response = await axiosInstance.put(`${axiosPath.goStop}/${params.gostopNo}`, body);
                console.log(response.data);
                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};

export const useDeleteGoStop = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (gostopNo: number) => {
            try {
                const response = await axiosInstance.delete(`${axiosPath.goStop}/${gostopNo}`);

                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};
