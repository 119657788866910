import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Calendar, DropDown, ImageListView, Input } from "@src/components";
import { useGetMainInformation } from "@src/hooks/queries/product/useMainInformation";
import { objectToDropdown } from "@src/utils/dropdownUtil";
import { Common } from "@src/styles/Common";
import { ConvertPrice } from "@src/utils/units";
import { dateParse } from "@src/utils/dateUtil";

interface IMainInformationContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
}
function MainInformationContainer({
    isUpdate = false,
    handleUpdate,
}: IMainInformationContainer) {
    const PRODUCT_NO = "20210130101452-1";
    const { isLoading, isError, data } = useGetMainInformation(PRODUCT_NO);
    const [initData, setInitData] = useState<any>();
    const [updateData, setUpdateData] = useState<any>();

    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
            setUpdateData(data);
        }
    }, [data]);

    useEffect(() => {
        handleUpdate && handleUpdate(updateData);
    }, [updateData]);

    const test = objectToDropdown({
        100: "주거용",
        101: "토지",
        102: "산업용",
    });
    return (
        <div>
            <ul css={MainContainerUl}>
                <li>
                    <Input
                        label={"매물번호"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={true}
                        errorMessage={""}
                        name=""
                        value={updateData?.productName || ""}
                        border={isUpdate}
                    />
                </li>
                <li>
                    <Input
                        label={"타입"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={true}
                        errorMessage={""}
                        name=""
                        value={updateData?.bidType || ""}
                        border={isUpdate}
                    />
                </li>
                <li>
                    <Input
                        label={"담당법원"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={updateData?.assign || ""}
                        border={isUpdate}
                    />
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li>
                    <Input
                        label={"매각가(원)"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={
                            !isUpdate
                                ? ConvertPrice(updateData?.expectPrice || "")
                                : updateData?.expectPrice || ""
                        }
                        border={isUpdate}
                    />
                </li>
                <li>
                    <span>매각일</span>
                    <Calendar
                        setDate={dateParse(updateData?.expectDueDate) || null}
                        getUserDate={(value) => console.log(value)}
                        isDisabled={!isUpdate}
                    />
                </li>
                <li>
                    <span>매각결정기일</span>
                    <Calendar
                        setDate={dateParse(updateData?.expectDueDate) || null}
                        getUserDate={(value) => console.log(value)}
                        isDisabled={!isUpdate}
                    />
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li>
                    <span>이미지</span>
                    <ImageListView
                        allFiles={updateData?.allFiles || [{ data_url: "" }]}
                        isDisabled={!isUpdate}
                        handleChange={() => {}}
                        maxCount={14}
                    />
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li style={{ width: "800px" }}>
                    <Input
                        label={"소재지"}
                        placeholder="-"
                        type="text"
                        size="dynamic"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={updateData?.location || ""}
                        border={isUpdate}
                    />
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li>
                    <Input
                        label={"도로명"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={updateData?.roadLocation || ""}
                        border={isUpdate}
                    />
                </li>
                <li>
                    <span>참고사항</span>
                    <Input
                        label="유찰"
                        placeholder="-"
                        type="number"
                        size="small"
                        handleChange={() => {}}
                        isDisabled={!isUpdate}
                        errorMessage={""}
                        name=""
                        value={updateData?.skpCnt || ""}
                        border={isUpdate}
                    />
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li>
                    <span>용도</span>
                    <DropDown
                        data={test}
                        handleChange={() => {}}
                        defaultValue={"주거용"}
                        border={isUpdate}
                        placeholder={""}
                        isDisabled={!isUpdate}
                    />
                </li>
            </ul>
        </div>
    );
}

const MainContainerUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    justify-content: flex-start;
    li {
        display: flex;
        align-items: center;
        width: 30%;
        height: 70px;
        & > span {
            width: 100px;
            min-width: 100px;
            display: inline-block;
            vertical-align: bottom;
            line-height: 60px;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
        & > span:nth-of-type(2) {
            text-align: right;
            width: fit-content;
            min-width: 30px !important;
        }
        & > input:disabled {
            background-color: transparent;
        }
    }
    li:first-of-type {
        min-width: 400px;
    }
`;

export default MainInformationContainer;
