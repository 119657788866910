import React, { useEffect, useState } from "react";
import { Button, EditModal, Input, PageTable, Title } from "@src/components";
import { css } from "@emotion/react";
import PaymentListFilter from "./PaymentListFilter";
import {
    initFilters,
    IPaymentListFilter,
    useGetExcelDownloadLog,
    useGetExcelFile,
    useGetPaymentList,
} from "@src/hooks/queries/usePaymentList";
import { getColumns } from "./PaymentListColumns";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { useNavigate } from "react-router-dom";

function PaymentList() {
    const navigate = useNavigate();
    const [filters, setFilters] = useState<IPaymentListFilter>(initFilters);
    const columns = getColumns(navigate);
    const [params, setParams] = useState<IPaymentListFilter>(initFilters);

    const [reason, setReason] = useState<string>("");
    const [isOpenReasonModal, setIsOpenReasonModal] = useState<boolean>(false);
    const [isOpenDownloadListModal, setIsOpenDownloadListModal] =
        useState<boolean>(false);

    const {
        data: excelDownloadLog,
        refetch: refetchExcelDownloadLog,
        hasNextPage,
        fetchNextPage,
    } = useGetExcelDownloadLog({ page: 1, limit: 10 });

    const dropList = [
        {
            id: 0,
            key: "userName",
            value: "이름",
        },
        {
            id: 1,
            key: "userEmail",
            value: "이메일",
        },
        {
            id: 2,
            key: "productCode",
            value: "상품코드",
        },
        {
            id: 3,
            key: "productName",
            value: "상품명",
        },
        {
            id: 4,
            key: "subsName",
            value: "구독이름",
        },
        {
            id: 5,
            key: "partner",
            value: "파트너",
        },
        {
            id: 6,
            key: "uid",
            value: "UID",
        },
    ];

    const { data } = useGetPaymentList(params);

    const handleSearchClick = () => {
        setFilters({ ...filters, page: 1 });
        setParams({ ...filters, page: 1 });
    };
    const handleClearClick = () => {
        setFilters(initFilters);
        setParams(initFilters);
    };

    useEffect(() => {
        setParams({ ...params, page: filters.page });
    }, [filters.page]);

    const [viewMoreRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: () => {
            fetchNextPage();
        },
        disabled: false,
        delayInMs: 100,
    });

    return (
        <div css={listStyle}>
            <Title title="결제내역" />
            <PaymentListFilter
                dropList={dropList}
                filters={filters}
                setFilters={setFilters}
            />
            <div className="filterBtnBox" css={filterBtnBox}>
                <div css={buttonWrapStyle}>
                    <Button
                        color="white"
                        handleClick={() => {
                            setIsOpenReasonModal(true);
                        }}
                        label="엑셀 다운로드"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            setIsOpenDownloadListModal(true);
                            refetchExcelDownloadLog();
                        }}
                        label="내역"
                        size="small"
                    />
                </div>
                <div css={buttonWrapStyle}>
                    <Button
                        color="gray"
                        handleClick={handleClearClick}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={handleSearchClick}
                        label="검색"
                        size="medium"
                    />
                </div>
            </div>
            <div css={tableDiv}>
                <div css={countDivStyle}>
                    <span>총 {data?.totalCount}건</span>
                    <span>총 결제액: {data?.totalPaymentAmount}원</span>
                    <span>환불액: {data?.totalRefundAmount}원</span>
                    <span>순 결제액: {data?.totalPaidAmount}원</span>
                </div>
                <PageTable
                    columns={columns}
                    data={data?.list ?? []}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    FixedHeight={500}
                    showTotalCount={true}
                    totalCount={data?.totalCount ?? 0}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setFilters({ ...filters, page: page ?? 1 });
                    }}
                    forcePageNumber={filters?.page}
                ></PageTable>
            </div>
            <EditModal
                isModalShow={isOpenReasonModal}
                title={"엑셀 다운로드"}
                handleOpenModal={() => {
                    setIsOpenReasonModal(false);
                }}
                size={"fitContent"}
                handleBtnClick={() => {
                    useGetExcelFile(filters, reason);
                }}
                isDisabled={!reason}
            >
                <div>
                    <Input
                        placeholder={"사유를 입력해주세요"}
                        handleChange={(e) => {
                            setReason(e.target.value);
                        }}
                        size={"medium"}
                        value={reason}
                        label="사유"
                    />
                </div>
            </EditModal>

            <EditModal
                isModalShow={isOpenDownloadListModal}
                title={"엑셀 다운로드 내역"}
                handleOpenModal={() => {
                    setIsOpenDownloadListModal(false);
                }}
                size={"fitContent"}
                handleBtnClick={() => {}}
                deleteButton
            >
                <div css={itemModalWrap}>
                    <table css={tableCss}>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>다운로드 일시</th>
                                <th>관리자 이름</th>
                                <th>사유</th>
                            </tr>
                        </thead>
                        <tbody>
                            {excelDownloadLog?.pages.map((page: any) =>
                                page.list.map((item: any, index: number) => (
                                    <tr key={String([item, index])}>
                                        <td>{item?.no}</td>
                                        <td>
                                            {ConvertDateTime(
                                                item?.downloadDate,
                                            )}
                                        </td>
                                        <td>{item?.adminName}</td>
                                        <td>{item?.reason}</td>
                                    </tr>
                                )),
                            )}
                            <tr>
                                <td
                                    ref={viewMoreRef}
                                    css={scrollDiv}
                                    colSpan={8}
                                ></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </EditModal>
        </div>
    );
}

export default PaymentList;

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const filterBtnBox = css`
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
`;
const buttonWrapStyle = css`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const tableDiv = css`
    margin-top: 43px;
    width: 100%;
    height: 80vh;
    & > div {
        & > div:nth-of-type(1) {
            display: none;
        }
    }
`;

const countDivStyle = css`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    & > span {
        padding-right: 10px;
        border-right: 1px solid black;
    }
    & > span:last-of-type {
        border-right: none;
    }
`;
const tableCss = css`
    width: 100%;
    & > thead {
        background-color: #f3f3f3;
        & > tr {
            display: table;
            width: 100%;
            & > th {
                width: 25%;
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
    & > tbody {
        display: block;
        max-height: 500px;
        overflow-y: scroll;
        & > tr {
            display: table;
            width: 100%;
            & > td {
                width: 25%;
                text-align: center;
                height: 40px;
                vertical-align: middle;
            }
        }
    }
`;

const scrollDiv = css`
    padding: 40px;
`;

const itemModalWrap = css`
    width: 1000px;
`;
