import React from "react";
import {
    ExpectedReturnContainer,
    CommentContainer,
    PublicInformationContainer,
    BuildingInformationContainer,
    SurroundInformationContainer,
    BidHistoryContainer,
    LocationStatusContainer,
    SeizeInformationContainer,
    SaleCaseContainer,
    RegisteredInfoPublicContainer,
    RentInfoPublicContainer,
    OccupancyRelationContainer,
    DistributionOrBondContainer,
} from "./containers";
import { updateExpectedReturn } from "@src/reducers/expectedReturn";
import { updateComment } from "@src/reducers/comment";
import { updateBuildingInfo } from "@src/reducers/buildingInformation";
import { updateStandardInfo } from "@src/reducers/standardInformation";
import { updateDetailInfoPublic } from "@src/reducers/publicInformation";
import { updateBidHistory } from "@src/reducers/bidHistory";
import { updateLocationStatus } from "@src/reducers/locationStatus";
import { updateSaleCase } from "@src/reducers/saleCase";
import { updateRegisteredInfoPublic } from "@src/reducers/registeredInfoPublic";
import { updateRentInfoPublic } from "@src/reducers/rentInfoPublic";
import { updateOccupancyRelation } from "@src/reducers/occupancyRelation";
import { updateDistributionOrBond } from "@src/reducers/distributionOrBond";
import { updateSurroundInfo } from "@src/reducers/surroundInformation";
import { updateSeizeInfo } from "@src/reducers/seizeInfo";

export const ProductPublicContainers: { [key: string]: any } = {
    STANDARD_INFORMATION: {
        component: ExpectedReturnContainer, // 기본정보
        updateAction: updateStandardInfo,
    },
    EXPECTED_RETURN: {
        component: ExpectedReturnContainer, // 예상 수익률
        updateAction: updateExpectedReturn,
    },
    COMMENT: {
        component: CommentContainer, // 미스고 코멘트
        updateAction: updateComment,
    },
    DETAIL_INFORMATION: {
        component: PublicInformationContainer, // 세부 정보
        updateAction: updateDetailInfoPublic,
    },
    BUILDING_INFORMATION: {
        component: BuildingInformationContainer, // 건물 정보
        updateAction: updateBuildingInfo,
    },
    BID_HISTORY: {
        component: BidHistoryContainer, // 입찰 이력
        updateAction: updateBidHistory,
    },
    LOCATION_STATUS: {
        component: LocationStatusContainer, // 위치 및 현황
        updateAction: updateLocationStatus,
    },
    SURROUND_INFORMATION: {
        component: SurroundInformationContainer, // 주변 정보
        updateAction: updateSurroundInfo,
    },
    SEIZE_INFORMATION: {
        component: SeizeInformationContainer, //압류 정보
        updateAction: updateSeizeInfo,
    },
    SALE_CASE: {
        component: SaleCaseContainer, // 인근 매각 사례
        updateAction: updateSaleCase,
    },
    REGISTERED_INFO_PUBLIC: {
        component: RegisteredInfoPublicContainer, // 등기 사항 증명서 주요 정보
        updateAction: updateRegisteredInfoPublic,
    },
    RENT_INFO_PUBLIC: {
        component: RentInfoPublicContainer, // 임대차 정보
        updateAction: updateRentInfoPublic,
    },
    OCCUPANCY_RELATION: {
        component: OccupancyRelationContainer, // 점유 관계
        updateAction: updateOccupancyRelation,
    },
    DISTRIBUTION_OR_BOND: {
        component: DistributionOrBondContainer, // 배분 요구 및 채권 신고
        updateAction: updateDistributionOrBond,
    },
};

export const renderComponent = (name: string, options?: object) => {
    return React.createElement(
        ProductPublicContainers[name].component,
        options,
    );
};
