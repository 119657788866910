import React, { useEffect, useState } from "react";
import NewsForm from "./NewsForm";
import { css } from "@emotion/react";
import { Button, Title } from "@src/components";
import { useNavigate } from "react-router-dom";
import { isNotNULL, isNotEmpty } from "@src/utils/textUtil";
import { FormNewsProps, CreateNewsDto } from "@src/interfaces/News.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import { useNewsData } from "@src/hooks/queries/useNews";
import { useCreateNews } from "@src/hooks/queries/useNewsDetail";
import { Common } from "@src/styles/Common";

function NewsCreate() {
    const navigate = useNavigate();
    const [updateData, setUpdateData] = useState<FormNewsProps>();
    const [disabled, setDisabled] = useState(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<any>();
    const { refetch } = useNewsData({});

    const initData: FormNewsProps = {
        imageFileCount: undefined,
        newsLink: "",
        startDate: new Date().setHours(0, 0, 0),
        mediaCompany: "",
        newsTitle: "",
        view: false,
        edit: false,
        allFiles: [],
        newsImage: "",
        keyword: "부동산 정책",
        portal: "",
        endDate: new Date().setHours(0, 0, 0),
        keywordCode: 1,
    };
    const changeValue = ({
        imageFileCount,
        newsLink,
        startDate,
        mediaCompany,
        newsTitle,
        view,
        edit,
        keywordCode,
        portal,
        endDate,
    }: any) => {
        if (
            isNotNULL(imageFileCount) &&
            isNotEmpty(newsLink) &&
            isNotNULL(startDate) &&
            isNotNULL(endDate) &&
            isNotEmpty(mediaCompany) &&
            isNotEmpty(newsTitle) &&
            isNotNULL(view) &&
            isNotNULL(keywordCode) &&
            isNotEmpty(portal)
        ) {
            // 버튼 활성화
            return edit ? false : true;
        } else {
            return true;
        }
    };

    useEffect(() => {
        const isChange = changeValue({ ...updateData });
        setDisabled(isChange);
        setPageErrorMessage("");
    }, [updateData]);
    const { mutate: createMutate } = useCreateNews();

    const handleSubmit = () => {
        if (updateData) {
            const params: CreateNewsDto = {
                title: updateData.newsTitle,
                url: updateData.newsLink,
                press: updateData.mediaCompany,
                date: updateData.startDate
                    ? ConvertDate(updateData.startDate, "yyyy.MM.dd")
                    : "",
                thumb: updateData.newsImage,
                is_show: updateData.view ? 1 : 0,
                keyword_code: updateData.keywordCode,
                portal: updateData.portal,
                close_at: updateData.endDate
                    ? ConvertDate(updateData.endDate, "yyyy.MM.dd")
                    : "",
            };

            createMutate(params, {
                onSuccess: () => {
                    refetch();
                    navigate("/news/list");
                    setPageErrorMessage("");
                },
                onError: (error) => {
                    setPageErrorMessage(error);
                },
            });
        }
    };
    useEffect(() => {
        if (pageErrorMessage !== "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [pageErrorMessage]);
    return (
        <div css={layoutDiv}>
            <Title title="뉴스 등록" />
            <NewsForm
                initData={initData}
                handleUpdate={setUpdateData}
                isUpdate={false}
            />
            <div css={btnDiv}>
                <p css={errorStyle}>{pageErrorMessage}</p>
                <Button
                    color="gd"
                    handleClick={handleSubmit}
                    label="등록"
                    size="medium"
                    isDisabled={disabled}
                />
            </div>
        </div>
    );
}

const layoutDiv = css`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
`;

const btnDiv = css`
    display: flex;
    justify-content: flex-end;
    button:disabled {
        cursor: none;
    }
`;
const errorStyle = css`
    padding-right: 15px;
    color: ${Common.colors.error};
    ${Common.textStyle.title6_R14}
    line-height: 38px;
`;
export default NewsCreate;
