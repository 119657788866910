import { createSlice } from "@reduxjs/toolkit";

const LandRegisInfoData = [
    {
        key: "LandRegis1",
        id: 0,
        receptionDate: "2016.06.11 (갑4)",
        rightType: "소유권이전청구권 가등기",
        note: "말소기준등기 2020타경112352",
        rightHolder: "파산자 김성훈의 파산관재인 임창기",
        bondPrice: 16000000,
        extinctionOrNot: "소멸",
    },
    {
        key: "LandRegis2",
        id: 1,
        receptionDate: "2016.06.12 (갑4)",
        rightType: "근저당권 설정",
        note: "2020타경112352",
        rightHolder: "주식회사재네트대부",
        bondPrice: 161120,
        extinctionOrNot: "인수",
    },
];

const initialState = LandRegisInfoData;

export const landRegisInfoSlice = createSlice({
    name: "landRegisInfo",
    initialState,
    reducers: {
        createLandRegisInfo: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateLandRegisInfo: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createLandRegisInfo, updateLandRegisInfo } =
    landRegisInfoSlice.actions;

export default landRegisInfoSlice.reducer;
