import React, { useEffect, useState, KeyboardEvent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { ko } from "date-fns/locale";
import DatePicker from "react-datepicker";
import calendarIcon from "@src/assets/calenderIcon.png";
import { getTime } from "date-fns";

type RangeTime = {
    startDate: EpochTimeStamp | null;
    endDate: EpochTimeStamp | null;
};
interface IRangeCalendar {
    setRangeDate?: RangeTime;
    getUserRangeDate?: (range: RangeTime, isError?: boolean) => void;
    verticalAlign?: boolean;
    isDisabled?: boolean;
    timePicker?: boolean;
    clear?: boolean;
    isDateDisabled?: number; // 1: start 2: end
}

function RangeCalendar({
    getUserRangeDate,
    verticalAlign = false,
    setRangeDate = {
        startDate: new Date().getTime(),
        endDate: new Date().getTime(),
    },
    isDisabled = false,
    timePicker = false,
    clear = false,
    isDateDisabled,
}: IRangeCalendar) {
    const { startDate: initStart = null, endDate: initEnd = null } =
        setRangeDate;
    const [startDate, setStartDate] =
        useState<EpochTimeStamp | null>(initStart);
    const [endDate, setEndDate] = useState<EpochTimeStamp | null>(initEnd);

    useEffect(() => {
        if (clear) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [clear]);

    useEffect(() => {
        if (startDate !== initStart || endDate !== initEnd) {
            setStartDate(initStart);
            setEndDate(initEnd);
        }
    }, [setRangeDate]);

    useEffect(() => {
        isDateDisabled === 1
            ? setStartDate(null)
            : isDateDisabled === 2 && setEndDate(null);
    }, [isDateDisabled]);

    useEffect(() => {
        getUserRangeDate && getUserRangeDate({ startDate, endDate }, isError);
    }, [startDate, endDate]);
    const isError = (startDate && endDate && startDate > endDate) || false;
    const setDate = (date: EpochTimeStamp | null) => {
        return date ? new Date(date) : null;
    };
    return (
        <>
            <div
                css={RangeCalendarStyle}
                className={(verticalAlign && "vertical") || ""}
            >
                <DatePicker
                    css={
                        isDisabled
                            ? [CalenderStyle, DisabledStyle]
                            : CalenderStyle
                    }
                    selected={setDate(startDate)}
                    locale={ko}
                    onChange={(date) => {
                        date && setStartDate(new Date(date).getTime());
                        // date && setStartDate(getTime(date));
                    }}
                    selectsStart
                    startDate={setDate(startDate)}
                    endDate={setDate(endDate)}
                    dateFormat={timePicker ? "yyyy.MM.dd HH:mm" : "yyyy.MM.dd"}
                    placeholderText="날짜 선택"
                    readOnly={isDisabled || isDateDisabled === 1}
                    showTimeSelect={timePicker}
                    timeIntervals={30}
                    onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                        e.preventDefault();
                    }}
                />
                {/* <span>~</span> */}
                <DatePicker
                    css={
                        isDisabled
                            ? [CalenderStyle, DisabledStyle]
                            : isError
                            ? [CalenderStyle, ErrorStyle]
                            : CalenderStyle
                    }
                    selected={setDate(endDate)}
                    locale={ko}
                    onChange={(date) => {
                        date && setEndDate(new Date(date).getTime());
                    }}
                    selectsEnd
                    startDate={setDate(startDate)}
                    endDate={setDate(endDate)}
                    minDate={setDate(startDate)}
                    dateFormat={timePicker ? "yyyy.MM.dd HH:mm" : "yyyy.MM.dd"}
                    placeholderText="날짜 선택"
                    readOnly={isDisabled || isDateDisabled === 2}
                    showTimeSelect={timePicker}
                    timeIntervals={30}
                    onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                        e.preventDefault();
                    }}
                />
            </div>
            {isError && (
                <div
                    style={{
                        color: Common.colors.error,
                        fontSize: "12px",
                        lineHeight: "6px",
                    }}
                >
                    <p>종료날짜는 시작날짜보다 작을 수 없습니다. </p>
                </div>
            )}
        </>
    );
}

const RangeCalendarStyle = css`
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        padding: 0 14px;
        line-height: 49px;
        ${Common.textStyle.title3_R16}
    }
    input {
        text-align: left !important;
    }
    &.vertical {
        flex-direction: column;
        gap: 20px;
        & > span {
            display: none;
        }
    }
    .react-datepicker__input-container {
        display: block !important;
        line-height: 60px;
        caret-color: transparent;
    }
    .react-datepicker__header {
        /* width: 260px !important; */
        font-size: 1em !important;
        line-height: 1.9em !important;
    }
    .react-datepicker__month {
        font-size: 1em !important;
        min-height: 165px !important;
        height: fit-content;
    }

    .react-datepicker__navigation-icon {
        top: 5px;
        position: absolute;
    }
`;
const CalenderStyle = css`
    min-width: 160px;
    min-height: 47px;
    border: none;
    outline: none;
    border-bottom: 1.5px solid ${Common.colors.gray200};
    text-indent: 12px;
    cursor: pointer;
    ${Common.textStyle.title6_R14}
    background: url(${calendarIcon}) no-repeat;
    background-position: right 8px center;
    background-size: 24px 24px;
    box-sizing: border-box;
`;

const DisabledStyle = css`
    background: none;
    border-bottom: none;
`;

const ErrorStyle = css`
    color: #f45b5b;
`;
export default RangeCalendar;
