import { add, format, parse, getTime } from "date-fns";

export const getHourFromTimestamp = (timestamp: EpochTimeStamp) => {
    const time = String(timestamp).padEnd(13, "00000");
    const result = new Date(Number(time)).getHours();
    return result;
};

export const getMinuteFromTimestamp = (timestamp: EpochTimeStamp) => {
    const time = String(timestamp).padEnd(13, "00000");

    return new Date(Number(time)).getMinutes();
};

export const addDay = (date: Date, days: number) => {
    return add(date, { days });
};

export const addHour = (date: Date, hours: number) => {
    return add(date, { hours });
};

export const addMinute = (date: Date, minutes: number) => {
    return add(date, { minutes });
};

export const dateFormat = (date: Date, formats = "yyyy-MM-dd") => {
    return `${format(date, formats)}`;
};

export const noYearFormat = (date: Date, formats = "MM.dd") => {
    return `${format(date, formats)}`;
};

export const dateSimpleFormat = (date: Date, formats = "yy.MM.dd") => {
    return `${format(date, formats)}`;
};

export const dateParse = (
    dateString: string,
    formats = "yyyy.MM.dd",
): EpochTimeStamp => {
    return getTime(parse(dateString, formats, new Date()));
};
export const dateParseLine = (
    dateString: string,
    formats = "yyyy-MM-dd",
): EpochTimeStamp => {
    return getTime(parse(dateString, formats, new Date()));
};
export const ConvertDateLine = (
    dateTime: EpochTimeStamp,
    displayFormat = "yyyy-MM-dd",
): string => {
    const date = new Date(dateTime);
    return format(date, displayFormat);
};

export const ConvertDate = (
    dateTime: EpochTimeStamp,
    displayFormat = "yyyy.MM.dd",
): string => {
    const date = new Date(dateTime);
    return format(date, displayFormat);
};

export const ConvertDateTime = (
    dateTime: any,
    displayFormat = "yyyy.MM.dd HH:mm:ss",
): string => {
    const date = new Date(dateTime);
    return format(date, displayFormat);
};

export const ConvertDateTimeNoSecond = (
    dateTime: any,
    displayFormat = "yyyy.MM.dd HH:mm",
): string => {
    const date = new Date(dateTime);
    return format(date, displayFormat);
};
