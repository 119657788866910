import { createSlice } from "@reduxjs/toolkit";
import { DateHistoryStateType } from "@src/pages/product/types";

const sampleData: Array<DateHistoryStateType> = [
    {
        id: "0",
        round: "1",
        bidDate: "2020.08.21",
        minPrice: "125000000",
        bidResult: "유찰",
        returnText: "",
    },
    {
        id: "1",
        round: "2",
        bidDate: "2020.10.28",
        minPrice: "100000000",
        bidResult: "유찰",
        returnText: "",
    },
    {
        id: "2",
        round: "3",
        bidDate: "2020.12.02",
        minPrice: "80000000",
        bidResult: "유찰",
        returnText: "",
    },
    {
        id: "3",
        round: "0",
        bidDate: null,
        minPrice: "93000000",
        bidResult: "미납",
        returnText: "매각결정기일 : 2020.12.12 \n 매각 93,000,000원 미납",
    },
    {
        id: "4",
        round: "4",
        bidDate: "2020.03.16",
        minPrice: "80000000",
        bidResult: "유찰",
        returnText: "",
    },
    {
        id: "5",
        round: "5",
        bidDate: "2020.04.05",
        minPrice: "64000000",
        bidResult: "유찰",
        returnText: "",
    },
    {
        id: "6",
        round: "6",
        bidDate: "2020.05.21",
        minPrice: "51000000",
        bidResult: "유찰",
        returnText: "",
    },
];

const initialState = sampleData;

export const dateHistorySlice = createSlice({
    name: "dateHistory",
    initialState,
    reducers: {
        createDateHistory: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateDateHistory: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createDateHistory, updateDateHistory } =
    dateHistorySlice.actions;

export default dateHistorySlice.reducer;
