import styled from "@emotion/styled";
import { Button, ConfirmModal, DropDown, EditModal, RangeCalendar, Title } from "@src/components";
import EssentialInput from "@src/components/input/EssentialInput";
import {
    useGetConnectedProducts,
    useTryCreateProduct,
    useGetCheckCommodityCode,
} from "@src/hooks/queries/useCommodity";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RelatedProduct from "./RelatedProduct";
import {
    isEventDropDown,
    includeProductTermDropDown,
    purchaseRestrictionsDropDown,
    recommendDropDown,
    renewTermDropDown,
    saleTypeDropDown,
    serveDurationTypeDropDown,
    subsCategoryDropDown,
    subsDropDown,
    monthlyInstallmentPlanEventDropDown,
} from "@src/constants/Commodity";
import { useGetProducts } from "@src/hooks/queries/useCommodityProvision";
import { ProductType } from "./provision/ProvisionForm";
import RegionSelectBox from "./components/RegionSelectBox";
import { REGION_LIST, STATE_LAND_REGION_TYPE } from "./components/regionInfo";

export interface CommodityDataType {
    no: number | null | undefined;
    type?: string; // 종류. [subscription:구독, deungi_ticket:등기열람권]
    code: string; // 상품코드. [영문숫자 임의 생성]
    name: string; // 상품명. 구독이름과 함께 앱에 노출됨. eg. 월구독
    subsGroup: string | undefined; // 구독종류. [subs_basic_01:일반구독]
    subsGroupName: string; // 구독이름. 상품명과 함께 앱에 노출됨. eg. 정기구독
    renewTerm: string | undefined; // 갱신주기. [monthly, yearly, none]
    description: string; // 상품설명.
    nameColor: string; // 상품명 색상.
    nameColorBg: string; // 상품명 배경색상.
    duplRestrict: number | null | undefined; // 구매제한. [0:무제한, 1:CI, 2:user_no, 3:phone, 4:email]
    saleType: number | null; // 판매형태. [0:미노출(특수경로진입), 1:일반노출, 2:제휴, 3:직원]
    serveDuration: number | undefined; // 제공기간. 숫자
    serveDurationType: string | undefined; // 제공기간단위. [day, month, year]
    serveQuantity: number | null; // 제공개수. 구독일 경우 1
    price: number | null; // 가격(정가) - 부가세 포함가격.
    priceIncludedVat: number | null; // 가격(정가)에 포함된 부가세.
    priceDiscountDisplay1: string; // 할인표시 정보 1. 현재 표시되는곳 없음.
    priceDiscountDisplay2: string; // 할인표시 정보 2. 문자열 그대로 표시됨. eg. -71,500원, 무료체험, 등등
    term1Duration: number | null; // 할인구간1 - 기간(적용횟수).
    term1DiscountedPrice: number | null; // 할인구간1 - 가격(할인가) - 부가세 포함가격.
    term1IncludedVat: number | null; // 할인구간1 - 가격(할인가)에 포함된 부가세.
    term2Duration: number | null; // 할인구간2 - 기간(적용횟수).
    term2DiscountedPrice: number | null; // 할인구간2 - 가격(할인가) - 부가세 포함가격.
    term2IncludedVat: number | null; // 할인구간2 - 가격(할인가)에 포함된 부가세.
    firstPayDelayDay: number | null; // 지연결제일 (예, 3일무료).
    includeProductCode: string; // 연관상품 - 상품코드.
    includeProductIssueTerm: string | undefined; // 연관상품 - 지급주기. [once:한번만지급, month:매월, year:매녕]
    status: number | null | undefined; // 판매상태. [200:정상판매중, 100:판매중지]
    comments: string;
    displayDescription: string;
    displayOrder: number | null;
    recommend: number;
    isEvent: boolean; // 이벤트 여부
    showStartAt: Date | null; // 노출 기간 시작일
    showEndAt: Date | null; // 노출 기간 종료일
    subsCategory: number; // 구독 카테고리 0: 일반, 1: 베이직, 2: 스탠다드, 3: 프리미엄
    includeProductList: any; // [연관 상품 json] { productNo: term, … }
    extraData: any; // 상품 관련 추가 데이터
}

export interface ConnectedProductType {
    no: number;
    code?: string;
    name: string;
    price_discount_display1: string;
    price_discount_display2: string;
    description: string;
    created_at: string;
    updated_at: string;
}

interface CommodityCreateContentsProps {
    kindValue: string;
    saleStatus?: number;
}

const CommodityCreateContents = ({ kindValue, saleStatus }: CommodityCreateContentsProps) => {
    const initialState = {
        no: null,
        type: kindValue, // 종류. [subscription:구독, deungi_ticket:등기열람권]
        code: "", // 상품코드. [영문숫자 임의 생성]
        name: "", // 상품명. 구독이름과 함께 앱에 노출됨. eg. 월구독
        subsGroup: "", // 구독종류. [subs_basic_01:일반구독]
        subsGroupName: "", // 구독이름. 상품명과 함께 앱에 노출됨. eg. 정기구독
        renewTerm: "", // 갱신주기. [monthly, yearly, none]
        description: "", // 상품설명.
        nameColor: "", // 상품명 색상.
        nameColorBg: "", // 상품명 배경색상.
        duplRestrict: null, // 구매제한. [0:무제한, 1:CI, 2:user_no, 3:phone, 4:email]
        saleType: null, // 판매형태. [0:미노출(특수경로진입), 1:일반노출, 2:제휴, 3:직원]
        serveDuration: undefined, // 제공기간. 숫자
        serveDurationType: "", // 제공기간단위. [day, month, year]
        serveQuantity: null, // 제공개수. 구독일 경우 1
        price: null, // 가격(정가) - 부가세 포함가격.
        priceIncludedVat: null, // 가격(정가)에 포함된 부가세.
        priceDiscountDisplay1: "", // 할인표시 정보 1. 현재 표시되는곳 없음.
        priceDiscountDisplay2: "", // 할인표시 정보 2. 문자열 그대로 표시됨. eg. -71,500원, 무료체험, 등등
        term1Duration: null, // 할인구간1 - 기간(적용횟수).
        term1DiscountedPrice: null, // 할인구간1 - 가격(할인가) - 부가세 포함가격.
        term1IncludedVat: null, // 할인구간1 - 가격(할인가)에 포함된 부가세.
        term2Duration: null, // 할인구간2 - 기간(적용횟수).
        term2DiscountedPrice: null, // 할인구간2 - 가격(할인가) - 부가세 포함가격.
        term2IncludedVat: null, // 할인구간2 - 가격(할인가)에 포함된 부가세.
        firstPayDelayDay: null, // 지연결제일 (예, 3일무료).
        includeProductCode: "", // 연관상품 - 상품코드.
        includeProductIssueTerm: "", // 연관상품 - 지급주기. [once:한번만지급, month:매월, year:매녕]
        status: 200, // 판매상태. [200:정상판매중, 100:판매중지]
        comments: "",
        displayDescription: "",
        displayOrder: 0,
        recommend: 1, //추천여부
        isEvent: false, // 이벤트 여부
        showStartAt: null, // 노출 기간 시작일
        showEndAt: null, // 노출 기간 종료일
        subsCategory: 0, // 구독 카테고리 0: 일반, 1: 베이직, 2: , 3: 프리미엄
        includeProductList: [],
        extraData: null,
    };

    // const connectedProductInitialState: ConnectedProductType = {
    //     no: 0,
    //     code: "",
    //     name: "",
    //     price_discount_display1: "",
    //     price_discount_display2: "",
    //     description: "",
    //     created_at: "",
    //     updated_at: "",
    // };
    const productInitialState: ProductType = {
        no: 0,
        type: "",
        code: "",
        name: "",
        saleType: 0,
        price: 0,
        discountedPrice: 0,
        subs_group: "",
        subs_group_name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    };

    const [commodityData, setCommodityData] = useState<CommodityDataType>(initialState);

    const [listPrice, setListPrice] = useState<string>();
    const [discountPrice, setDiscountPrice] = useState<string>();
    const [secondDiscountPrice, setSecondDiscountPrice] = useState<string>();
    const [isDuplicateCheck, setIsDuplicateCheck] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState("");
    const [productList, setProductList] = useState<ProductType[]>([productInitialState]);
    const [selectedItem, setSelectedItem] = useState<any>({
        no: 0,
        code: "",
        name: "",
        price_discount_display1: "",
        price_discount_display2: "",
        description: "",
        created_at: "",
        updated_at: "",
    });
    const navigate = useNavigate();
    const [errMessage, setErrMessage] = useState<string>("");
    const [isCheckModalShow, setIsCheckModalShow] = useState<boolean>(false);
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
    // 국공유지 지역 설정 상태 관리
    const [isRegionVisible, setIsRegionVisible] = useState<boolean>(false);
    const [selectRegion, setSelectRegion] = useState<string[]>([]);

    const {
        type,
        code,
        name,
        subsGroup,
        subsGroupName,
        renewTerm,
        description,
        nameColor,
        nameColorBg,
        duplRestrict,
        saleType,
        serveDuration,
        serveDurationType,
        serveQuantity,
        price,
        priceIncludedVat,
        priceDiscountDisplay1,
        priceDiscountDisplay2,
        term1Duration,
        term1IncludedVat,
        term1DiscountedPrice,
        term2DiscountedPrice,
        term2Duration,
        term2IncludedVat,
        firstPayDelayDay,
        status,
        displayOrder,
        displayDescription,
        recommend,
        isEvent,
        showStartAt,
        showEndAt,
        subsCategory,
        includeProductList,
        extraData,
    } = commodityData;

    const trySetPrice = (value: string | undefined, name1: string, name2: string) => {
        const numValue = Number(value);
        const vat = parseInt(String(numValue * (1 / 11)));
        setCommodityData({
            ...commodityData,
            [name1]: vat,
            [name2]: numValue,
        });
    };

    // const trySetPrice = (value: string | undefined, name1: string, name2: string) => {
    //     const numValue = Number(value);
    //     const vat = parseInt(String(numValue * 0.1));
    //     setCommodityData({
    //         ...commodityData,
    //         [name1]: vat,
    //         [name2]: numValue + vat,
    //     });
    // };

    useEffect(() => {
        if (saleType === 4) {
            setCommodityData({
                ...commodityData,
                term1IncludedVat: 0,
                term1DiscountedPrice: 0,
            });
        }
    }, [saleType]);

    useEffect(() => {
        if (
            kindValue === "deungi_ticket" ||
            kindValue === "campus_registration" ||
            kindValue === "exist_deungi_ticket" ||
            kindValue === "items_ads_ticket" ||
            kindValue === "region_ads_ticket" ||
            kindValue === "state_land_filter" ||
            kindValue === "road_plan" ||
            kindValue === "premium_filter" ||
            kindValue === "Retail_profit_rate"
        ) {
            setCommodityData({
                ...commodityData,
                subsGroupName: "",
                subsCategory: 0,
                renewTerm: "",
                subsGroup: "",
            });
        } else if (kindValue === "subscription" && (commodityData.saleType === 4 || commodityData.saleType === 5)) {
            setCommodityData({
                ...commodityData,
                renewTerm: "none",
            });
        }

        // 국공유지 지역 초기화
        setSelectRegion([]);
        setCommodityData({
            ...commodityData,
            type: kindValue,
            extraData: null,
        });
    }, [kindValue]);

    useEffect(() => {
        setCommodityData({
            ...commodityData,
            status: saleStatus,
        });
    }, [saleStatus]);

    const handleCheckDuplicate = async () => {
        const response = await useGetCheckCommodityCode(commodityData?.code);
        setIsDuplicateCheck(!response.data);

        !response.data ? alert("사용가능한 상품코드입니다.") : alert("이미 존재하는 상품코드입니다.");
    };

    const handleSubmit = () => {
        if (isDuplicateCheck) {
            useTryCreateProduct(commodityData).then((res) => {
                navigate("/commodity/list", { state: true });
            });
        } else {
            setErrMessage("중복검사를 해주세요.");
        }
    };

    const handleGetConnectProducts = async (title: string) => {
        // const response = await useGetConnectedProducts();
        // setConnectedProductList(response.data.data.list);

        const response = await useGetProducts();
        setProductList(response.data);
        setModalTitle(title);
    };

    const regTypeEngNum = /^[a-z|A-Z|0-9|]+$/;
    const regTypeNum = /^[0-9]+$/;

    const onChange = (e: any, setValue: Dispatch<SetStateAction<string | undefined>>) => {
        const { value } = e.target;
        const removeCommaPrice = value.replaceAll(",", "");
        if (isNaN(removeCommaPrice)) {
            return;
        }
        setValue(removeCommaPrice);
    };

    const rgbToHex = (r: any, g: any, b: any) => {
        return (
            "#" +
            [r, g, b]
                .map((x) => {
                    const hex = Number(x).toString(16);
                    return hex.length === 1 ? "0" + hex : hex;
                })
                .join("")
        );
    };

    const updateEditorState = (state: any) => {
        setEditorState(state);
        const newData: any = convertToRaw(editorState.getCurrentContent());
        const newBlocks = convertToRaw(editorState.getCurrentContent()).blocks.map((item) => {
            const newInline = item.inlineStyleRanges.map((data: any) => {
                if (data.style.includes("color-rgb") && !data.style.includes("bgcolor-rgb")) {
                    const rgb = data.style.slice(7);
                    const rgbArray: any = rgb.match(/\d+/g);
                    const hex: any = rgbArray ? rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2]) : data.style;

                    return { ...data, style: "color-" + hex };
                } else if (data.style.includes("bgcolor-rgb")) {
                    const rgb = data.style.slice(7);
                    const rgbArray: any = rgb.match(/\d+/g);
                    const hex: any = rgbArray ? rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2]) : data.style;

                    return { ...data, style: "bgcolor-" + hex };
                } else {
                    return { ...data };
                }
            });
            if (newInline) {
                return { ...item, inlineStyleRanges: newInline };
            } else {
                return { ...item };
            }
        });
        newData.blocks = newBlocks;
        const html = draftjsToHtml(newData);

        if (
            convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
            convertToRaw(editorState.getCurrentContent()).blocks[0].text === ""
        ) {
            setCommodityData({
                ...commodityData,
                displayDescription: "",
            });
            return;
        }
        setCommodityData({
            ...commodityData,
            displayDescription: html,
        });
    };

    // 국공유지 지역 관련 핸들러
    const onRegionRemoveHandler = (region: string) => {
        const regionArr = selectRegion.slice();
        regionArr.splice(regionArr.indexOf(region), 1);
        setSelectRegion(regionArr);
    };

    // 국공유지 지역 선택시 지역 정보 입력
    useEffect(() => {
        const pnusList = Object.values(REGION_LIST)
            .filter((el) => selectRegion.includes(el.name))
            .reduce((acc: number[], cur) => acc.concat(cur.value || 0), []);

        if (!pnusList.includes(STATE_LAND_REGION_TYPE.ALL)) {
            if (pnusList && pnusList.length > 0) {
                setCommodityData({
                    ...commodityData,
                    extraData: {
                        regions: pnusList,
                    },
                });
            }
        } else {
            setCommodityData({
                ...commodityData,
                extraData: null,
            });
        }
    }, [selectRegion]);

    return (
        <>
            <StyledCommodityCreateContents>
                <StyledSaleType style={{ marginBottom: "15px" }}>
                    <text>
                        <span>*</span>노출기간
                    </text>
                    <label htmlFor="noShowRadio">
                        <input
                            type="radio"
                            id="noShowRadio"
                            name="show"
                            checked={showStartAt === null}
                            onChange={(e) => {
                                setCommodityData({
                                    ...commodityData,
                                    showStartAt: null,
                                    showEndAt: null,
                                });
                            }}
                        />
                        <span>미설정</span>
                    </label>
                    <label htmlFor="showRadio">
                        <input
                            type="radio"
                            name="show"
                            id="showRadio"
                            checked={!!showStartAt}
                            onChange={(e) => {
                                setCommodityData({
                                    ...commodityData,
                                    showStartAt: new Date(),
                                    showEndAt: new Date(),
                                });
                            }}
                        />
                        <span>설정</span>
                    </label>
                </StyledSaleType>
                {showStartAt !== null && (
                    <RangeCalendar
                        setRangeDate={{
                            startDate: showStartAt !== null ? new Date(showStartAt).getTime() : null,
                            endDate: showEndAt !== null ? new Date(showEndAt).getTime() : null,
                        }}
                        getUserRangeDate={({ startDate, endDate }) => {
                            setCommodityData({
                                ...commodityData,
                                showStartAt: startDate !== null && !isNaN(startDate) ? new Date(startDate) : null,
                                showEndAt: endDate !== null && !isNaN(endDate) ? new Date(endDate) : null,
                            });
                        }}
                        timePicker={true}
                    />
                )}
                {kindValue === "state_land_filter" && (
                    <StyledItemLow>
                        <StyledButtonInput>
                            <span>
                                <span className="essential">*</span>
                                지역선택
                            </span>
                            <div className="regionBtns">
                                {selectRegion &&
                                    selectRegion.map((el, idx) => {
                                        return (
                                            <label key={idx}>
                                                {el}
                                                <button onClick={() => onRegionRemoveHandler(el)}>X</button>
                                            </label>
                                        );
                                    })}
                                {selectRegion.length === 0 && <span>지역을 선택해주세요</span>}
                            </div>
                            <Button
                                color="gd"
                                handleClick={() => {
                                    setIsRegionVisible(true);
                                }}
                                label="선택"
                                size="small"
                            />
                        </StyledButtonInput>
                        <RegionSelectBox
                            visible={isRegionVisible}
                            setVisible={setIsRegionVisible}
                            selectRegion={selectRegion}
                            setSelectRegion={setSelectRegion}
                        />
                    </StyledItemLow>
                )}
                {kindValue === "road_plan" && (
                    <StyledItemLow>
                        <StyledButtonInput>
                            <span>
                                <span className="essential">*</span>
                                지역선택
                            </span>
                            <div className="regionBtns">
                                {selectRegion &&
                                    selectRegion.map((el, idx) => {
                                        return (
                                            <label key={idx}>
                                                {el}
                                                <button onClick={() => onRegionRemoveHandler(el)}>X</button>
                                            </label>
                                        );
                                    })}
                                {selectRegion.length === 0 && <span>지역을 선택해주세요</span>}
                            </div>
                            <Button
                                color="gd"
                                handleClick={() => {
                                    setIsRegionVisible(true);
                                }}
                                label="선택"
                                size="small"
                            />
                        </StyledButtonInput>
                        <RegionSelectBox
                            type="road_plan"
                            visible={isRegionVisible}
                            setVisible={setIsRegionVisible}
                            selectRegion={selectRegion}
                            setSelectRegion={setSelectRegion}
                        />
                    </StyledItemLow>
                )}
                <StyledDropDown>
                    <span>
                        <span className="essential">*</span>판매형태
                    </span>
                    <div className="dropDownDiv">
                        <DropDown
                            data={saleTypeDropDown}
                            defaultValue=""
                            placeholder="항목을 선택해주세요"
                            handleChange={(e, text, value) => {
                                if (Number(value) === 4) {
                                    alert("무료 쿠폰용은 할인금액이 0원입니다");
                                    setCommodityData({
                                        ...commodityData,
                                        priceDiscountDisplay2: "0",
                                        saleType: Number(value),
                                        renewTerm: "none",
                                    });
                                } else if (Number(value) === 5 && commodityData.type === "subscription") {
                                    setCommodityData({
                                        ...commodityData,
                                        saleType: Number(value),
                                        renewTerm: "none",
                                    });
                                } else {
                                    setCommodityData({
                                        ...commodityData,
                                        saleType: Number(value),
                                    });
                                }
                            }}
                            label=""
                        />
                    </div>
                </StyledDropDown>
                <StyledItemLow>
                    <StyledButtonInput>
                        <span>
                            <span className="essential">*</span>
                            상품코드
                        </span>
                        <input
                            type="text"
                            value={commodityData.code}
                            placeholder="영문, 숫자만 사용하여 입력해주세요"
                            onChange={(e) => {
                                setCommodityData({
                                    ...commodityData,
                                    code: e.target.value,
                                });
                                setIsDuplicateCheck(false);
                            }}
                        />
                        <Button
                            color="gd"
                            handleClick={() => {
                                if (regTypeEngNum.test(code)) {
                                    code.length > 0 && handleCheckDuplicate();
                                } else {
                                    alert("영어, 숫자만 입력해주세요");
                                }
                            }}
                            label="중복검사"
                            size="small"
                        />
                    </StyledButtonInput>
                    <EssentialInput
                        label="상품명"
                        essential={true}
                        placeholder="이름을 입력해주세요"
                        data={commodityData}
                        value={name}
                        name={"name"}
                        onChange={setCommodityData}
                    />
                </StyledItemLow>
                {kindValue === "subscription" && (
                    <StyledItemLow>
                        <StyledDropDown>
                            <span>
                                <span className="essential">*</span>구독종류
                            </span>
                            <div className="dropDownDivSubscription">
                                <DropDown
                                    data={subsDropDown}
                                    defaultValue=""
                                    placeholder="항목을 선택해주세요"
                                    handleChange={(e, text, value) => {
                                        setCommodityData({
                                            ...commodityData,
                                            subsGroup: value,
                                        });
                                    }}
                                    label=""
                                />
                            </div>
                        </StyledDropDown>
                        <StyledDropDown>
                            <span>
                                <span className="essential">*</span>갱신주기
                            </span>
                            <div className="dropDownDivSubscription">
                                <DropDown
                                    data={renewTermDropDown}
                                    defaultValue={commodityData.renewTerm}
                                    placeholder="항목을 선택해주세요"
                                    handleChange={(e, text, value) => {
                                        setCommodityData({
                                            ...commodityData,
                                            renewTerm: value,
                                        });
                                    }}
                                    label=""
                                    isDisabled={
                                        Number(commodityData.saleType) === 5 || Number(commodityData.saleType) === 4
                                    }
                                />
                            </div>
                        </StyledDropDown>
                    </StyledItemLow>
                )}
                <StyledItemLow>
                    {kindValue === "subscription" && (
                        <>
                            <StyledDropDown>
                                <span>
                                    <span className="essential">*</span>
                                    분류 카테고리
                                </span>
                                <div className="dropDownDiv">
                                    <DropDown
                                        data={subsCategoryDropDown}
                                        defaultValue={subsCategory.toString()}
                                        handleChange={(e, text, value) => {
                                            setCommodityData({
                                                ...commodityData,
                                                subsCategory: Number(value),
                                                subsGroupName:
                                                    Number(value) > 0
                                                        ? subsCategoryDropDown[Number(value)].value
                                                        : subsGroupName,
                                            });
                                        }}
                                        label=""
                                    />
                                </div>
                            </StyledDropDown>
                            <EssentialInput
                                label="구독이름"
                                data={commodityData}
                                name={"subsGroupName"}
                                onChange={setCommodityData}
                                essential={true}
                                value={subsGroupName}
                                placeholder="이름입력"
                                style={{ width: 320 }}
                                isDisabled={subsCategory > 0}
                            />
                        </>
                    )}
                </StyledItemLow>
                <StyledItemLow>
                    <EditorDiv>
                        <span>
                            <span className="essential">*</span>상품설명{<br />}
                            (노출용)
                        </span>
                        <Editor
                            placeholder="상품을 등록해주세요"
                            editorState={editorState}
                            onEditorStateChange={updateEditorState}
                            localization={{ locale: "ko" }}
                            editorStyle={{
                                height: "400px",
                                width: "100%",
                                border: "3px solid lightgray",
                                padding: "20px",
                            }}
                        />
                    </EditorDiv>
                </StyledItemLow>
                <StyledItemLow>
                    <StyledReasonWrapper>
                        <span>상품설명{<br />}(관리용)</span>
                        <input
                            type="text"
                            placeholder="상품설명을 입력해주세요"
                            value={description}
                            onChange={(e) => {
                                setCommodityData({
                                    ...commodityData,
                                    description: e.target.value,
                                });
                            }}
                        />
                    </StyledReasonWrapper>
                </StyledItemLow>
                <StyledItemLow>
                    <EssentialInput
                        label="상품 노출순서"
                        essential={false}
                        name={"displayOrder"}
                        onChange={setCommodityData}
                        value={displayOrder ?? ""}
                        data={commodityData}
                        placeholder="1~1000이하 숫자로 입력해주세요"
                        number={true}
                    />
                    <StyledDropDown>
                        <span>
                            <span className="essential">*</span>추천 여부
                        </span>
                        <DropDown
                            data={recommendDropDown}
                            defaultValue="추천"
                            handleChange={(e, text, value) => {
                                setCommodityData({
                                    ...commodityData,
                                    recommend: Number(value),
                                });
                            }}
                            label=""
                        />
                    </StyledDropDown>
                </StyledItemLow>
                <StyledItemLow>
                    <EssentialInput
                        label="상품문구"
                        data={commodityData}
                        essential={true}
                        value={priceDiscountDisplay1}
                        name={"priceDiscountDisplay1"}
                        onChange={setCommodityData}
                        placeholder="상품문구를 입력해주세요"
                    />
                    <EssentialInput
                        label="할인금액"
                        essential={true}
                        name={"priceDiscountDisplay2"}
                        onChange={setCommodityData}
                        value={priceDiscountDisplay2}
                        data={commodityData}
                        placeholder="할인금액을 입력해주세요(숫자, 문자열 모두 가능)"
                        isDisabled={Number(commodityData.saleType) === 4}
                    />
                </StyledItemLow>
                <StyledItemLow>
                    <EssentialInput
                        label="상품명 색상"
                        essential={true}
                        data={commodityData}
                        value={nameColor}
                        name={"nameColor"}
                        onChange={setCommodityData}
                        color={true}
                        placeholder="색상코드를 입력해주세요(예 #FFFFFF)"
                        style={{ paddingLeft: 35 }}
                    />

                    <EssentialInput
                        label={"상품명 배경 색상"}
                        value={nameColorBg}
                        essential={true}
                        data={commodityData}
                        name={"nameColorBg"}
                        onChange={setCommodityData}
                        color={true}
                        placeholder="색상코드를 입력해주세요(예 #FFFFFF)"
                        style={{ paddingLeft: 35 }}
                    />
                </StyledItemLow>
                <StyledItemLow>
                    <StyledDropDown>
                        <span>
                            <span className="essential">*</span>구매제한
                        </span>
                        <div className="dropDownDiv">
                            <DropDown
                                data={purchaseRestrictionsDropDown}
                                defaultValue=""
                                placeholder="항목을 선택해주세요"
                                handleChange={(e, text, value) => {
                                    setCommodityData({
                                        ...commodityData,
                                        duplRestrict: Number(value),
                                    });
                                }}
                                label=""
                            />
                        </div>
                    </StyledDropDown>
                    <StyledDropDown>
                        <span>
                            <span className="essential">*</span>이벤트 여부
                        </span>
                        <div className="dropDownDiv">
                            <DropDown
                                data={isEventDropDown}
                                defaultValue=""
                                handleChange={(e, text, value) => {
                                    setCommodityData({
                                        ...commodityData,
                                        isEvent: Boolean(Number(value)),
                                    });
                                }}
                                label=""
                            />
                        </div>
                    </StyledDropDown>
                    {/* <StyledDropDown>
                        <span>
                            <span className="essential">*</span>판매형태
                        </span>
                        <div className="dropDownDiv">
                            <DropDown
                                data={saleTypeDropDown}
                                defaultValue=""
                                placeholder="항목을 선택해주세요"
                                handleChange={(e, text, value) => {
                                    if (Number(value) === 4) {
                                        alert(
                                            "무료 쿠폰용은 할인금액이 0원입니다",
                                        );
                                        setCommodityData({
                                            ...commodityData,
                                            price_discount_display2: "0",
                                            sale_type: Number(value),
                                            renew_term: "none",
                                        });
                                    } else if (
                                        Number(value) === 5 &&
                                        commodityData.type === "subscription"
                                    ) {
                                        setCommodityData({
                                            ...commodityData,
                                            sale_type: Number(value),
                                            renew_term: "none",
                                        });
                                    } else {
                                        setCommodityData({
                                            ...commodityData,
                                            sale_type: Number(value),
                                        });
                                    }
                                }}
                                label=""
                            />
                        </div>
                    </StyledDropDown> */}
                </StyledItemLow>
                <StyledItemLow>
                    <div className="provisionPeriod">
                        <EssentialInput
                            label="제공기간"
                            value={serveDuration ?? ""}
                            essential={true}
                            name={"serveDuration"}
                            onChange={setCommodityData}
                            data={commodityData}
                            placeholder="숫자를 입력해주세요"
                            style={{ width: 200 }}
                        />
                        <div className="dropDownDiv">
                            <DropDown
                                data={serveDurationTypeDropDown}
                                defaultValue=""
                                placeholder="기간단위"
                                handleChange={(e, text, value) => {
                                    setCommodityData({
                                        ...commodityData,
                                        serveDurationType: value,
                                    });
                                }}
                                label=""
                            />
                        </div>
                    </div>
                    <EssentialInput
                        label="제공개수"
                        value={serveQuantity ?? ""}
                        data={commodityData}
                        name={"serveQuantity"}
                        onChange={setCommodityData}
                        essential={true}
                        placeholder="숫자를 입력해주세요"
                    />
                </StyledItemLow>
                {kindValue === "subscription" && (
                    <StyledItemLow>
                        <StyledEventWrapper>
                            <StyledDropDown>
                                <span>
                                    <span className="essential">*</span>이벤트 할부 개월
                                </span>
                                <DropDown
                                    data={monthlyInstallmentPlanEventDropDown}
                                    defaultValue="할부없음"
                                    handleChange={(e, text, value) => {
                                        setCommodityData({
                                            ...commodityData,
                                            extraData: {
                                                ...commodityData.extraData,
                                                installment: Number(value),
                                            },
                                        });
                                    }}
                                />
                            </StyledDropDown>
                            <div className="description">할부개월 선택에 따라 이벤트 기간 상품 동일 지급</div>
                        </StyledEventWrapper>
                        <StyledEventWrapper>
                            <div className="flex">
                                <span>
                                    <span className="essential">*</span>이벤트 할부 개월 미해당
                                </span>
                                <div
                                    className="productAddBtn"
                                    onClick={() => {
                                        handleGetConnectProducts("상품 조회");
                                    }}
                                >
                                    상품 조회
                                </div>
                                <span>{commodityData.extraData?.originProductCode ?? ""}</span>
                            </div>
                            <div className="description">이벤트 할부개월이 아닐 시 검색된 상품으로 지급</div>
                        </StyledEventWrapper>
                    </StyledItemLow>
                )}
                <StyledItemLow>
                    <StyledPriceWrapper>
                        <span>
                            <span className="essential">*</span>가격(정가)
                            {<br />}※부가세 포함
                        </span>
                        <input
                            type="text"
                            value={listPrice ? Number(listPrice).toLocaleString() : ""}
                            placeholder="숫자를 입력해주세요"
                            onChange={(e) => {
                                const newVal = e.target.value.replaceAll(",", "");
                                if (isNaN(Number(newVal))) {
                                    return;
                                }
                                setListPrice(newVal);
                            }}
                            onBlur={(e) => trySetPrice(listPrice, "priceIncludedVat", "price")}
                        />
                    </StyledPriceWrapper>
                    <StyledPriceWrapper>
                        <span>부가세</span>
                        <StyledPrice>
                            {commodityData?.priceIncludedVat
                                ? Number(commodityData?.priceIncludedVat).toLocaleString()
                                : ""}
                        </StyledPrice>
                    </StyledPriceWrapper>
                    <StyledPriceWrapper>
                        <span>
                            가격(정가)
                            {<br />}※부가세 미포함
                        </span>
                        <StyledPrice>
                            {commodityData?.price
                                ? (Number(listPrice) - Number(commodityData?.priceIncludedVat)).toLocaleString()
                                : ""}
                        </StyledPrice>
                    </StyledPriceWrapper>
                </StyledItemLow>
                <Title title="할인정보" size="small" />
                <StyledItemLow>
                    할인구간1
                    <EssentialInput
                        label="기간(적용횟수)"
                        data={commodityData}
                        value={term1Duration ?? ""}
                        name={"term1Duration"}
                        onChange={setCommodityData}
                        essential={true}
                        placeholder="숫자를 입력해주세요"
                        style={{ width: 180 }}
                    />
                </StyledItemLow>
                <StyledItemLow>
                    <StyledPriceWrapper>
                        <span>
                            <span className="essential">*</span>가격(할인가)
                            {<br />}※부가세 포함
                        </span>
                        <input
                            type="text"
                            value={saleType === 4 ? 0 : discountPrice ? Number(discountPrice).toLocaleString() : ""}
                            onChange={(e) => {
                                onChange(e, setDiscountPrice);
                            }}
                            onBlur={() => {
                                trySetPrice(discountPrice, "term1IncludedVat", "term1DiscountedPrice");
                            }}
                        />
                    </StyledPriceWrapper>
                    <StyledPriceWrapper>
                        <span>부가세</span>
                        <StyledPrice>
                            {commodityData?.term1IncludedVat
                                ? Number(commodityData?.term1IncludedVat).toLocaleString()
                                : ""}
                        </StyledPrice>
                    </StyledPriceWrapper>
                    <StyledPriceWrapper>
                        <span>
                            가격(할인가)
                            {<br />}※부가세 미포함
                        </span>
                        <StyledPrice>
                            {commodityData?.term1DiscountedPrice
                                ? (
                                      Number(commodityData?.term1DiscountedPrice) -
                                      Number(commodityData?.term1IncludedVat)
                                  ).toLocaleString()
                                : ""}
                        </StyledPrice>
                    </StyledPriceWrapper>
                </StyledItemLow>
                <StyledItemLow>
                    할인구간2
                    <EssentialInput
                        label="기간(적용횟수)"
                        value={term2Duration ?? ""}
                        data={commodityData}
                        essential={false}
                        name={"term2Duration"}
                        onChange={setCommodityData}
                        placeholder="숫자를 입력해주세요"
                        style={{ width: 180 }}
                    />
                </StyledItemLow>
                <StyledItemLow>
                    <StyledPriceWrapper>
                        <span>
                            가격(할인가)
                            {<br />}※부가세 포함
                        </span>
                        <input
                            type="text"
                            value={secondDiscountPrice ? Number(secondDiscountPrice).toLocaleString() : ""}
                            onChange={(e) => {
                                onChange(e, setSecondDiscountPrice);
                            }}
                            onBlur={() => {
                                trySetPrice(secondDiscountPrice, "term2IncludedVat", "term2DiscountedPrice");
                            }}
                        />
                    </StyledPriceWrapper>
                    <StyledPriceWrapper>
                        <span>부가세</span>
                        <StyledPrice>
                            {commodityData?.term2IncludedVat
                                ? Number(commodityData?.term2IncludedVat).toLocaleString()
                                : ""}
                        </StyledPrice>
                    </StyledPriceWrapper>
                    <StyledPriceWrapper>
                        <span>
                            가격(할인가)
                            {<br />}※부가세 미포함
                        </span>
                        <StyledPrice>
                            {commodityData?.term2DiscountedPrice
                                ? (
                                      Number(commodityData?.term2DiscountedPrice) -
                                      Number(commodityData?.term2IncludedVat)
                                  ).toLocaleString()
                                : ""}
                        </StyledPrice>
                    </StyledPriceWrapper>
                </StyledItemLow>
                <StyledItemLow>첫결제 지연일</StyledItemLow>
                <StyledItemLow>
                    <EssentialInput
                        label="지연일수"
                        data={commodityData}
                        value={firstPayDelayDay ?? ""}
                        name={"firstPayDelayDay"}
                        onChange={setCommodityData}
                        essential={false}
                        placeholder="숫자를 입력해주세요"
                        style={{ width: 180 }}
                    />
                    일
                </StyledItemLow>
                <>
                    <Title title="연관상품" size="small" />
                    <StyledItemLow>
                        <IncludeProductListBox>
                            <table>
                                <thead>
                                    <tr>
                                        <th>번호</th>
                                        <th>상품명</th>
                                        <th>상품코드</th>
                                        <th>지급주기</th>
                                        <th>삭제</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {includeProductList &&
                                        Object.keys(includeProductList).length > 0 &&
                                        Object.keys(includeProductList).map((el, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{includeProductList[idx].no}</td>
                                                    <td>{includeProductList[idx].name}</td>
                                                    <td>{includeProductList[idx].code}</td>
                                                    <td>
                                                        <DropDown
                                                            data={
                                                                type === "subscription"
                                                                    ? includeProductTermDropDown
                                                                    : [includeProductTermDropDown[0]]
                                                            }
                                                            defaultValue={includeProductList[el].term}
                                                            handleChange={(e, text, value) => {
                                                                includeProductList[idx].term = value;

                                                                setCommodityData((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        includeProductList,
                                                                    };
                                                                });
                                                            }}
                                                            label=""
                                                        />
                                                    </td>
                                                    <td>
                                                        <label
                                                            onClick={() => {
                                                                includeProductList.splice(idx, 1);

                                                                setCommodityData((prev) => {
                                                                    return {
                                                                        ...prev,
                                                                        includeProductList: includeProductList,
                                                                    };
                                                                });
                                                            }}
                                                        >
                                                            삭제
                                                        </label>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    <tr>
                                        <td colSpan={5}>
                                            <div className={"productAddBtn"}>
                                                <span
                                                    onClick={() => {
                                                        handleGetConnectProducts("연관 상품 조회");
                                                    }}
                                                >
                                                    상품 조회
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </IncludeProductListBox>
                    </StyledItemLow>
                </>
            </StyledCommodityCreateContents>

            <StyledButtonDiv errMessage={errMessage}>
                <Button
                    color="gd"
                    // handleClick={handleSubmit}
                    handleClick={() => {
                        setIsCheckModalShow(true);
                    }}
                    label="등록"
                    size="medium"
                    isDisabled={
                        !(
                            type &&
                            status &&
                            code &&
                            name &&
                            priceDiscountDisplay1 &&
                            priceDiscountDisplay2 &&
                            nameColor &&
                            nameColorBg &&
                            saleType !== null &&
                            saleType !== undefined &&
                            duplRestrict !== null &&
                            duplRestrict !== undefined &&
                            serveDuration &&
                            serveDuration >= 1 &&
                            serveDurationType &&
                            serveQuantity &&
                            serveQuantity >= 1 &&
                            price !== null &&
                            price >= 0 &&
                            priceIncludedVat !== null &&
                            priceIncludedVat >= 0 &&
                            term1Duration !== null &&
                            term1Duration >= 0 &&
                            term1DiscountedPrice !== null &&
                            term1DiscountedPrice >= 0 &&
                            term1IncludedVat !== null &&
                            term1IncludedVat >= 0 &&
                            displayDescription !== "" &&
                            ((type === "subscription" && subsGroup && renewTerm && subsGroupName) ||
                                type === "deungi_ticket" ||
                                type === "campus_registration" ||
                                type === "exist_deungi_ticket" ||
                                type === "items_ads_ticket" ||
                                type === "region_ads_ticket" ||
                                type === "premium_filter" ||
                                type === "Retail_profit_rate" ||
                                // 국공유지 지역 설정 체크
                                (type === "state_land_filter" && selectRegion.length > 0) ||
                                (type === "road_plan" && selectRegion.length > 0))
                        )
                    }
                />
                <p>{errMessage}</p>
            </StyledButtonDiv>
            <EditModal
                buttonText="OK"
                handleBtnClick={() => {
                    if (modalTitle === "연관 상품 조회") {
                        includeProductList.push({
                            no: selectedItem.no,
                            name: selectedItem.name,
                            code: selectedItem.code,
                            term: "once",
                        });
                        setCommodityData((prev) => {
                            return {
                                ...prev,
                                includeProductList: includeProductList
                                    .filter(
                                        (el1: any, idx: number) =>
                                            includeProductList.findIndex((el2: any) => el1.no === el2.no) === idx,
                                    )
                                    .sort((el1: any, el2: any) => el1.no - el2.no),
                            };
                        });
                        return;
                    }

                    if (modalTitle === "상품 조회") {
                        setCommodityData((prev) => {
                            return {
                                ...prev,
                                extraData: {
                                    ...prev.extraData,
                                    originProductCode: selectedItem.code,
                                },
                            };
                        });
                        return;
                    }
                }}
                handleOpenModal={() => {
                    setModalTitle("");
                }}
                size="big"
                title={modalTitle}
                isModalShow={!!modalTitle}
                isDisabled={!selectedItem.code}
            >
                <div>
                    <RelatedProduct
                        ProductList={productList}
                        setSelectedItem={setSelectedItem}
                        selectedItem={selectedItem}
                    />
                </div>
            </EditModal>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() => {
                    setIsCheckModalShow(false);
                }}
                handleOk={() => {
                    handleSubmit();
                    setIsCheckModalShow(false);
                }}
                isModalShow={isCheckModalShow}
            >
                등록하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default CommodityCreateContents;

const IncludeProductListBox = styled.div`
    position: relative;
    width: 100%;

    table {
        width: 800px;

        & > thead {
            & > tr {
                line-height: 2.2;
                border-bottom: 1px solid #999;
                text-align: left;

                & > th:nth-of-type(1),
                & > th:nth-of-type(3),
                & > th:nth-of-type(4),
                & > th:nth-of-type(5) {
                    width: 100px;
                    text-align: center;
                }
            }
        }
        & > tbody {
            & > tr {
                line-height: 3;
                text-align: left;

                & > td:nth-of-type(1),
                & > td:nth-of-type(3),
                & > td:nth-of-type(4),
                & > td:nth-of-type(5) {
                    text-align: center;
                }
                & > td > label {
                    border: 1px solid #9a49f2;
                    border-radius: 8px;
                    font-size: 12px;
                    color: #9a49f2;
                    padding: 4px;
                    cursor: pointer;
                }

                .productAddBtn {
                    width: 100%;
                    text-align: center;
                    line-height: 3;
                    background: #eee;

                    & > span {
                        border: 1px solid #9a49f2;
                        border-radius: 8px;
                        font-size: 12px;
                        color: #9a49f2;
                        padding: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;

const StyledCommodityCreateContents = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 30px;
    padding-bottom: 40px;
`;

const StyledItemLow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 48px;
    font-weight: 400;
    font-size: 15px;
    color: #222222;

    & > .provisionPeriod {
        display: flex;
        gap: 24px;
        align-items: center;
    }
    & > .dropDown {
        & > div {
            & > label {
                & > select {
                    font-weight: 400;
                    font-size: 14px;
                    width: 96px;
                }
            }
        }
    }
`;

const StyledInputWrapper = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    & > span {
        font-weight: 400;
        font-size: 14px;
        color: #f45b5b;
    }
    & > input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 328px;
        height: 42px;
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
`;

const StyledReasonWrapper = styled.div`
    bottom: 20px;
    width: 100%;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        line-height: 18px;

        .essential {
            color: #f45b5b;
        }
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const EditorDiv = styled.div`
    width: 100%;

    display: flex;
    gap: 16px;
    align-items: flex-start;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        line-height: 18px;

        .essential {
            color: #f45b5b;
        }
    }
`;

const StyledSaleType = styled.div`
    display: flex;
    margin-top: 25px;
    margin-bottom: 35px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #222222;

    & > text {
        width: 70px;
        & > span {
            color: #f45b5b;
        }
    }
    & > label {
        display: flex;
        margin-left: 54px;
        gap: 4px;
        align-items: center;
        & > input {
            outline: none;
            color: black;
            border: 1px solid black !important;
            border-radius: 100px;
        }
    }
`;

const StyledPriceWrapper = styled.div`
    bottom: 20px;
    width: fit-content;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: fit-content;
        line-height: 18px;

        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 180px;
        height: 42px;
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
`;

const StyledDropDown = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 14px;

        line-height: 18px;
        color: #222222;
        .essential {
            color: #f45b5b;
        }
    }

    & > .dropDownDivSubscription {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid #b4b4b9;
                    border-radius: 0;
                    width: 180px;
                }
            }
        }
    }

    & > .dropDownDiv {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: none;
                    border-bottom: 1px solid #b4b4b9;
                    border-radius: 0;
                    width: 328px;
                }
            }
        }
    }
`;

const StyledPrice = styled.div`
    margin-left: 16px;
    border: none;
    box-sizing: border-box;
    border-bottom: 1px solid #e3e3e6;
    border-radius: 0px;
    padding: 13px;
    outline: none;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    width: 180px;
    height: 42px;
`;

const StyledButtonInput = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    position: relative;

    & > span {
        font-weight: 400;
        font-size: 14px;

        line-height: 18px;
        color: #222222;
        .essential {
            color: #f45b5b;
        }
    }
    & > div,
    input {
        margin-left: 16px;
        border: none;
        box-sizing: border-box;
        border-bottom: 1px solid #e3e3e6;
        border-radius: 0px;
        padding: 13px;
        outline: none;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        width: 328px;
        height: 42px;

        &.regionBtns {
            user-select: none;

            & > label {
                margin-right: 10px;
                border: 1px solid #ccc;
                border-radius: 3px;
                padding: 2px 5px;

                & > button {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
            & > span {
                color: #999;
            }
        }
    }
    & > input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #9d9fa5;
    }
    & > button {
        position: absolute;
        right: 10px;
    }
`;

const StyledButtonDiv = styled.div<{ errMessage: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    right: 500px;
    & > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 180%;

        text-align: left;
        color: #f45b5b;
        display: ${(props) => (props.errMessage ? "block" : "none")};
    }
`;

const StyledEventWrapper = styled.div`
    & .flex {
        display: flex;
        gap: 16px;
        align-items: center;
        span {
            font-weight: 400;
            font-size: 14px;

            line-height: 18px;
            color: #222222;
            .essential {
                color: #f45b5b;
            }
        }

        & .productAddBtn {
            border: 1px solid #9a49f2;
            border-radius: 8px;
            color: #9a49f2;
            padding: 4px 18px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 170%;
        }
    }

    & .description {
        margin-left: 8px;

        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #f45b5b;
    }
`;
