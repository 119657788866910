import React from "react";
import { IconButton } from "@src/components";
import { ConvertHtmlToString } from "@src/utils/textUtil";

export const getColumns = ({ navigate, setDeleteNo, setIsModalShow }: any) => {
    return [
        {
            Header: "구분",
            accessor: "category",
            Cell: ({ cell: { value } }: any) => (
                <div className="category" style={{ width: "200px", textAlign: "center" }}>
                    {value === "app" && "전체"}
                    {value === "app-home" && "PC/APP-홈"}
                    {value === "homepage" && "PC-랜딩"}
                </div>
            ),
        },
        {
            Header: "제목",
            accessor: "title",
            Cell: ({ cell: { value } }: any) => (
                <div
                    className="title"
                    style={{
                        width: "250px",
                        whiteSpace: "pre-wrap",
                        textAlign: "center",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "본문",
            accessor: "contents",
            Cell: ({ cell: { value } }: any) => (
                <div
                    className="contents"
                    style={{
                        width: "350px",
                        textAlign: "justify",
                        whiteSpace: "normal",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {ConvertHtmlToString(value)}
                </div>
            ),
        },
        {
            Header: "노출",
            accessor: "isOpen",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>{value ? "O" : "X"}</div>
            ),
        },
        {
            Header: "노출 순서",
            accessor: "displayOrder",
            Cell: ({ cell: { value } }: any) => <div style={{ width: "80px", textAlign: "center" }}>{value}</div>,
        },
        {
            Header: "등록 일시",
            accessor: "createdAt",
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            Header: "최종 수정 일시",
            accessor: "updatedAt",
            Cell: ({ cell: { value } }: any) => <div style={{ textAlign: "center" }}>{value}</div>,
        },
        {
            Header: "관리",
            accessor: "viewControl",
            Cell: ({ row: { values } }: any) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="trash"
                            handleClick={() => {
                                setDeleteNo(values.viewControl);
                                setIsModalShow((prev: any) => !prev);
                            }}
                        />
                        <IconButton
                            type="edit"
                            handleClick={() => {
                                navigate(`/help/notice/update/${values.viewControl}`, { state: { value: values } });
                            }}
                        />
                    </div>
                );
            },
        },
    ];
};
