import React from "react";
import { css } from "@emotion/react";
import { EditModal } from "@src/components";
import { ISettlementPaymentResDTO } from "@src/hooks/queries/usePartnerSettlement";

interface Props {
    open: boolean;
    onCloseView: () => void;
    paymentList?: ISettlementPaymentResDTO;
    paymentListParams: any;
}

const columnList = [
    "결제번호",
    "결제일시",
    "결제 고객회원",
    "구매정보",
    "결제액",
    `정산 전\n결제 취소액`,
    `정산 후\n결제 취소액`,
    "순결제액",
];

const SettlementPaymenyListModal: React.FC<Props> = ({ open, onCloseView, paymentList, paymentListParams }) => {
    return (
        <div css={rootBoxStyle}>
            <EditModal
                isModalShow={open}
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={onCloseView}
                size="big"
                title="내역 리스트"
            >
                <div>
                    <p>
                        정산 대상 결제 내역 <span>닉네임 {paymentListParams?.nickname}</span>
                    </p>
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    {columnList.map((column) => (
                                        <th key={column}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {paymentList?.list?.map((pay) => (
                                    <tr key={pay?.paymentNo}>
                                        <td>{pay?.paymentNo}</td>
                                        <td>{pay?.paymentDate}</td>
                                        <td>{pay?.userName}</td>
                                        <td>{pay?.productName}</td>
                                        <td>{pay?.paymentPrice}</td>
                                        <td>{pay?.cancelAmountBefore}</td>
                                        <td>{pay?.cancelAmountAfter}</td>
                                        <td>{pay?.paymentAmount}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>총계</td>
                                    <td>
                                        {paymentListParams?.paidYear}년 {paymentListParams?.paidMonth}월 정산
                                    </td>
                                    <td />
                                    <td />
                                    <td>{paymentList?.monthlyPrice}</td>
                                    <td>{paymentList?.monthlyCancelBefore}</td>
                                    <td>{paymentList?.monthlyCancelAfter}</td>
                                    <td>{paymentList?.monthlyAmount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p css={subTitleTextStyle}>정산 요약</p>
                    <div css={summaryBoxStyle}>
                        <div className="info">
                            <span className="bold">정산 기준금액 (순결제액): </span>
                            <span className="ml4">{paymentList?.monthlyAmount} </span>
                            <span className="bold ml4"> 정산등급(비율): </span>
                            <span className="ml4">{paymentList?.level}</span>
                        </div>
                        <p>
                            총 정산 지급(예정)액: <span className="bold">{paymentList?.monthlyPayouts}원</span>
                        </p>
                    </div>
                </div>
            </EditModal>
        </div>
    );
};

export default SettlementPaymenyListModal;

const rootBoxStyle = css`
    & > div > div:first-of-type {
        width: 1080px;
    }
    .submitBtn {
        display: none;
    }
`;

const tableRootStyle = css`
    width: 100%;
    max-width: 1200px;
    margin-top: 2rem;
    height: 500px;
    overflow: scroll;
    max-height: fit-content;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            background-color: #eeeeee;
            position: sticky;
            top: 0px;
        }
        th {
            padding: 10px 5px;
            span {
                color: #ff0000;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 5px;
            text-align: center;
            border-bottom: 1px solid #b5b5b5;
        }
    }
`;

const subTitleTextStyle = css`
    font-size: 20px;
    font-weight: 700;
    line-height: 170%;
`;

const summaryBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .info {
        display: flex;
        align-items: center;
    }
    .bold {
        font-weight: 700;
    }
    .ml4 {
        margin-left: 4px;
    }
`;
