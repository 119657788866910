import React, { MouseEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import DateRangeCalendar, {
    DateRangeCalendarType,
} from "@src/components/calendar/DateRangeCalendar";
import {
    initTime,
    initTimeTerm,
    paymentInitTime,
    basicInitTime,
} from "@src/hooks/queries/useHomeChart";

type BasisType = "daily" | "weekly" | "monthly" | "day" | "week" | "month";

export type changeDateType = {
    range: DateRangeCalendarType;
    type: BasisType;
};
interface IChartHeader {
    getChangeDate: ({ range, type }: changeDateType) => void;
    defaultBasisType: BasisType;
    type?: "productType";
    paymentType?: string;
}
function ChartHeader({
    getChangeDate,
    defaultBasisType,
    type,
    paymentType,
}: IChartHeader) {
    const [basis, setBasis] = useState<BasisType>(defaultBasisType);
    const [date, setDate] = useState<DateRangeCalendarType>();

    useEffect(() => {
        if (type === "productType" && paymentType !== "paymentStatus") {
            setDate(initTimeTerm.month);
        } else if (type !== "productType" && paymentType !== "paymentStatus") {
            setDate(basicInitTime.month);
        } else if (paymentType === "paymentStatus") {
            setDate(paymentInitTime.month);
        }
    }, [paymentType]);

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        const dataBasis = e.currentTarget.getAttribute(
            "data-basis",
        ) as BasisType;
        setBasis(dataBasis);

        if (type !== "productType") {
            date && getChangeDate({ range: date, type: dataBasis });
        }
        if (type === "productType" && paymentType !== "paymentStatus") {
            // 날짜 초기값 설정
            switch (dataBasis) {
                case "day":
                    setDate(initTimeTerm.day);
                    break;
                case "week":
                    setDate(initTimeTerm.week);
                    break;
                case "month":
                    setDate(initTimeTerm.month);
                    break;
                default:
                    setDate(initTimeTerm.month);
            }
        } else if (paymentType === "paymentStatus") {
            switch (dataBasis) {
                case "daily":
                    setDate(paymentInitTime.day);
                    break;
                case "weekly":
                    setDate(paymentInitTime.week);
                    break;
                case "monthly":
                    setDate(paymentInitTime.month);
                    break;
                default:
                    setDate(paymentInitTime.month);
            }
        }
    };
    return (
        <div css={chartHeaderWrap}>
            <div css={chartContentWrap}>
                <DateRangeCalendar
                    getUserDate={(range: DateRangeCalendarType) => {
                        setDate(range);
                        getChangeDate({ range, type: basis });
                    }}
                    setDate={date}
                />
            </div>
            <div css={chartHeaderBoxWrap}>
                {type === "productType" ? (
                    <>
                        <div
                            css={
                                basis === "day"
                                    ? [chartHeaderBox, selected]
                                    : chartHeaderBox
                            }
                            data-basis="day"
                            onClick={handleClick}
                        >
                            일별
                        </div>
                        <div
                            css={
                                basis === "week"
                                    ? [chartHeaderBox, selected]
                                    : chartHeaderBox
                            }
                            data-basis="week"
                            onClick={handleClick}
                        >
                            주별
                        </div>
                        <div
                            css={
                                basis === "month"
                                    ? [chartHeaderBox, selected]
                                    : chartHeaderBox
                            }
                            data-basis="month"
                            onClick={handleClick}
                        >
                            월별
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            css={
                                basis === "daily"
                                    ? [chartHeaderBox, selected]
                                    : chartHeaderBox
                            }
                            data-basis="daily"
                            onClick={handleClick}
                        >
                            일
                        </div>
                        <div
                            css={
                                basis === "weekly"
                                    ? [chartHeaderBox, selected]
                                    : chartHeaderBox
                            }
                            data-basis="weekly"
                            onClick={handleClick}
                        >
                            주
                        </div>
                        <div
                            css={
                                basis === "monthly"
                                    ? [chartHeaderBox, selected]
                                    : chartHeaderBox
                            }
                            data-basis="monthly"
                            onClick={handleClick}
                        >
                            월
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

const chartHeaderWrap = css`
    width: 100%;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
const chartContentWrap = css`
    display: flex;
    flex-direction: column;
`;
const chartHeaderBoxWrap = css`
    display: flex;
    flex-direction: row;
    gap: 2px;
    cursor: pointer;
`;
const chartHeaderBox = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px 0px;
    /* width: 24px; */
    height: 24px;
    background: ${Common.colors.white};
    border: 1px solid ${Common.colors.gray300};
    border-radius: 4px;
    font-size: 12px;
    line-height: 180%;
`;
const selected = css`
    border: 1px solid ${Common.colors.purple500};
    color: ${Common.colors.purple500};
`;
export default ChartHeader;
