import { Button, IconButton, Label } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTime } from "@src/utils/dateUtil";
import React, { Dispatch, SetStateAction } from "react";

export const getUserStateLandColumns = (
    setIsShowRefundModal: (isShow: boolean) => void,
    closeModal: () => void,
    setIsShowStateLandEditModal: (isShow: boolean) => void,
    setStateLandItem: Dispatch<SetStateAction<any>>,
    setIsShowStateLandDeleteConfirmModal: Dispatch<SetStateAction<boolean>>,
) => [
    {
        Header: "번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "결제일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    textAlign: "center",
                }}
            >
                {value ? ConvertDateTime(value) : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품명",
        accessor: "productName",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "사용여부",
        accessor: "isUsed",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    textAlign: "center",
                }}
            >
                {value ? "O" : "X"}
            </div>
        ),
    },
    {
        Header: "첫 사용일시",
        accessor: "firstUsedAt",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value ? ConvertDateTime(value) : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "결제액",
        accessor: "paidPrice",
        Cell: ({ cell: { value }, row }: any) => {
            const userNo = row.values.userNo;
            return (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value.toLocaleString()}
                </div>
            );
        },
    },
    {
        Header: "결제번호",
        accessor: "paidNo",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "결제수단",
        accessor: "cardName",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value ? value : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "환불액",
        accessor: "refundPrice",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value.toLocaleString()}
                </div>
            );
        },
    },
    {
        Header: "이용기한",
        accessor: "expiredAt",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    {value ? ConvertDateTime(value) : INVALID_VALUE}
                </div>
            );
        },
    },
    {
        Header: "상태",
        accessor: "stateToString",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 8px",
                    }}
                >
                    {value === "이용중" && (
                        <Label text={value} color={"purple1"}></Label>
                    )}
                    {value === "부분환불" && (
                        <Label text={value} color={"refund"}></Label>
                    )}
                    {value === "전체환불" && (
                        <Label text={value} color={"allRefund"}></Label>
                    )}
                    {value === "기간만료" && (
                        <Label text={value} color={"expired"}></Label>
                    )}
                    {value === "관리자 삭제" && (
                        <Label text={value} color={"black"}></Label>
                    )}
                </div>
            );
        },
    },
    {
        Header: "환불",
        accessor: "canRefund",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {value ? (
                        <Button
                            label="환불하기"
                            color="purple"
                            size="small"
                            handleClick={() => {
                                console.log("!!!!", row?.values);
                                setIsShowRefundModal(true);
                                setStateLandItem(row?.values);
                                closeModal();
                            }}
                            styles={{
                                width: "80%",
                                height: "30px",
                                fontSize: "13px",
                                fontWeight: 400,
                                margin: 0,
                            }}
                        />
                    ) : (
                        <Button
                            label="환불하기"
                            color="purple"
                            size="small"
                            handleClick={() => {}}
                            styles={{
                                width: "80%",
                                height: "30px",
                                fontSize: "13px",
                                fontWeight: 400,
                                margin: 0,
                            }}
                            isDisabled={true}
                        />
                    )}
                </div>
            );
        },
    },
    {
        Header: "수정",
        accessor: "no",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        label="수정"
                        color="purple"
                        size="small"
                        handleClick={() => {
                            setIsShowStateLandEditModal(true);
                            setStateLandItem(row?.values);
                            closeModal();
                        }}
                        styles={{
                            width: "80%",
                            height: "30px",
                            fontWeight: 400,
                            margin: 0,
                        }}
                    />
                </div>
            );
        },
    },

    {
        Header: "삭제",
        accessor: "canDelete",
        Cell: ({ cell: { value }, row }: any) => {
            return (
                <div
                    style={{
                        width: "65px",
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {value ? (
                        <IconButton
                            type="trash"
                            handleClick={() => {
                                setStateLandItem(row?.values);
                                closeModal();
                                setIsShowStateLandDeleteConfirmModal(true);
                            }}
                        />
                    ) : (
                        <IconButton
                            type="trash"
                            handleClick={() => {}}
                            isDisabled
                        />
                    )}
                </div>
            );
        },
    },
];
