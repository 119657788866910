import React, { useEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import {
    Title,
    Table,
    SearchInput,
    PageTable,
    Button,
    IconButton,
} from "@src/components";
import { Constants } from "@src/constants/Constants";
import {
    useAdminList,
    usePushReceivingAdminList,
} from "@src/hooks/queries/useAdmin";
import { Common } from "@src/styles/Common";
import { AdminFilterType, AdminListType } from "@src/types/adminType";
import { getColumns } from "./ReceiverTesterColumns";

interface IReceiverTester {
    receiverUserNo?: string[]; // 관리자번호
    handleUpdate: Function;
    isDetail?: Boolean;
}
const ReceiverTester = ({
    receiverUserNo = [],
    handleUpdate,
    isDetail = false,
}: IReceiverTester) => {
    const MAX_LIMIT_CHECKBOX_SELECTIONS = 10;
    const PAGE_SIZE = 5;
    const [params, setParams] = useState<AdminFilterType>({
        page: 1,
        limit: PAGE_SIZE,
        userjoinedonly: 1,
    });
    const {
        isLoading,
        data: adminData = { result: [], total: 0 },
        isError,
    } = useAdminList(params);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [data, setData] = useState<AdminListType[]>([]);
    useEffect(() => {
        if (!isLoading && !isError && adminData) {
            setData(adminData.result);
            if (params.page === 1) {
                setTotalCount(adminData.total);
            }
        }
    }, [adminData]);

    const [receiver, setReceiver] = useState<AdminListType[]>([]);
    const {
        isError: isSelectedAdminLoading,
        data: selectedAdminData = { result: [], total: 0 },
        isError: isSelectedAdminError,
    } = usePushReceivingAdminList({
        to_go: `,${receiverUserNo.join(",")},`,
        limit: 100,
        page: 1,
    });
    useEffect(() => {
        if (
            !isSelectedAdminLoading &&
            !isSelectedAdminError &&
            selectedAdminData.result.length
        ) {
            setReceiver(selectedAdminData.result);
        }
    }, [selectedAdminData.result]);

    const [updateReceiver, setUpdateReceiver] = useState<AdminListType[]>([]);
    const onSelectedRowsChange = (rows: any) => {
        const mappedRows = rows.map((r: any) => r.original);
        const existUserNo = receiver.map((r: any) => r.userNo);
        const filterRows = mappedRows.filter(
            (v: any) => !existUserNo.includes(v.userNo),
        );

        setUpdateReceiver([...receiver, ...filterRows]);
    };

    const columns = getColumns();
    const [selectionClear, setSelectionClear] = useState(false);
    const ListTable = () => {
        return useMemo(
            () => (
                <PageTable
                    columns={columns}
                    data={data}
                    initialState={{
                        pageIndex: 0,
                        pageSize: PAGE_SIZE,
                    }}
                    showCheckbox={true}
                    showNumber={false}
                    onSelectedRowsChange={onSelectedRowsChange}
                    selectedRows={{}}
                    FixedHeight={300}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParams((prevState: any) => {
                            return { ...prevState, page, limit: PAGE_SIZE };
                        });
                    }}
                    forcePage={params.page === 1}
                    selectionClear={selectionClear}
                />
            ),
            [data, params, selectionClear, receiver],
        );
    };

    const SelectionTable = () => {
        const UpdateTableColumns = [
            ...columns,
            {
                Header: "관리",
                accessor: "viewControl",
                Cell: ({ row }: any) => (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="trash"
                            handleClick={() => {
                                const filter = receiver.filter(
                                    (v) => v !== row.original,
                                );
                                handleUpdate(filter);
                                setReceiver(filter);
                            }}
                        />
                    </div>
                ),
            },
        ];
        return (
            <Table
                columns={isDetail ? columns : UpdateTableColumns}
                data={receiver}
                FixedHeight={200}
                showTotalCount={false}
                showPagination={false}
                initialState={{
                    pageIndex: 0,
                    pageSize: MAX_LIMIT_CHECKBOX_SELECTIONS,
                }}
                showNumber={false}
            />
        );
    };

    const handleAddClick = () => {
        setReceiver(updateReceiver);
        handleUpdate(updateReceiver);
        setSelectionClear(true);
        setTimeout(() => {
            setSelectionClear(false);
        }, 10);
    };

    return (
        <div css={Wrapper}>
            <div css={CountDiv}>
                <Title title={Constants.TESTER} size="small" />
                &nbsp;&nbsp;<span>({receiver.length}건)</span>
            </div>
            <div css={ReceiverBoxDiv}>{SelectionTable()}</div>
            {isDetail == false && (
                <div css={TesterBoxDiv}>
                    <Title title="관리자 목록" size="small" />
                    <p className="info">
                        테스터로 지정되지 않은 관리자는 표시되지 않습니다.
                    </p>
                    <div css={SearchDiv}>
                        <SearchInput
                            handleClick={(value: string) => {
                                setParams((prevState: any) => {
                                    return { ...prevState, q: value };
                                });
                            }}
                            placeholder="이름이나 연락처, 이메일을 검색하세요"
                            isDisabled={false}
                        />
                        <span>&nbsp;</span>
                        <Button
                            label={"추가"}
                            color="orange"
                            size="medium"
                            handleClick={handleAddClick}
                        />
                    </div>
                    <div>{ListTable()}</div>
                </div>
            )}
        </div>
    );
};

const Wrapper = css`
    width: 1280px;
    height: 700px;
`;
const ReceiverBoxDiv = css`
    display: flex;
    flex-direction: column;
    min-height: 200px;
    overflow: auto;
`;
const TesterBoxDiv = css`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 920px;
    position: relative;
    .info {
        color: ${Common.colors.black};
        ${Common.textStyle.body4_R12}
    }
    .caution {
        color: ${Common.colors.error};
        ${Common.textStyle.body4_R12}
        margin-top: 5px;
    }
`;
const SearchDiv = css`
    position: absolute;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    & + div {
        padding-top: 50px;
    }
`;
const CountDiv = css`
    ${Common.textStyle.body3_R14}
    display: flex;
    margin: 15px 0;
    line-height: 20px;
`;
export default ReceiverTester;
