const AUCTION = "경매";
const PUBLIC_AUCTION = "공매";
export const ALL = "전체";

// 타입
export const PRODUCT_TYPE = { ALL, AUCTION, PUBLIC_AUCTION };
// 물건상태
export const PRODUCT_STATUS = [
    "전체",
    "매각예정",
    "준비중",
    "신건",
    "진행중",
    "낙찰",
    "유찰",
    "기타",
];
// 물건상태
export const PRODUCT_STATUS_SHORT = ["신건", "진행중", "낙찰", "유찰"];
// 처분방식
export const DISPOSE_TYPE = ["전체", "매각", "임대"];
// 자산구분
export const PROPERTY_TYPE = ["전체", "캠코", "이용기관"];
// 정렬방식
export const LIST_BY_ORDER = [
    "최신순(기본)",
    "감정가",
    "최저입찰가",
    "매각기일",
    "유찰횟수",
];
// 감정가&입찰가
export const VALUE_MIN_PRICE = [
    "1백만",
    "2백만",
    "3백만",
    "4백만",
    "5백만",
    "6백만",
    "7백만",
    "8백만",
    "9백만",
    "1천만",
    "2천만",
    "3천만",
    "4천만",
    "5천만",
    "6천만",
    "7천만",
    "8천만",
    "9천만",
    "1억",
    "2억",
    "3억",
    "4억",
    "5억",
    "6억",
    "7억",
    "8억",
    "9억",
    "10억",
    "20억",
    "30억",
    "40억",
    "50억",
    "60억",
    "70억",
    "80억",
    "90억",
    "100억",
];
export const VALUE_MAX_PRICE = [
    "1천만",
    "2천만",
    "3천만",
    "4천만",
    "5천만",
    "6천만",
    "7천만",
    "8천만",
    "9천만",
    "1억",
    "2억",
    "3억",
    "4억",
    "5억",
    "6억",
    "7억",
    "8억",
    "9억",
    "10억",
    "20억",
    "30억",
    "40억",
    "50억",
    "60억",
    "70억",
    "80억",
    "90억",
    "100억",
    "200억",
    "400억",
    "600억",
    "800억",
    "1000억",
];
// 감정가&입찰가
export const VALUE_MIN_PRICE_NEW = [
    { key: 1000000, value: "1백만" },
    { key: 2000000, value: "2백만" },
    { key: 3000000, value: "3백만" },
    { key: 4000000, value: "4백만" },
    { key: 5000000, value: "5백만" },
    { key: 6000000, value: "6백만" },
    { key: 7000000, value: "7백만" },
    { key: 8000000, value: "8백만" },
    { key: 9000000, value: "9백만" },
    { key: 10000000, value: "1천만" },
    { key: 20000000, value: "2천만" },
    { key: 30000000, value: "3천만" },
    { key: 40000000, value: "4천만" },
    { key: 50000000, value: "5천만" },
    { key: 60000000, value: "6천만" },
    { key: 70000000, value: "7천만" },
    { key: 80000000, value: "8천만" },
    { key: 90000000, value: "9천만" },
    { key: 100000000, value: "1억" },
    { key: 200000000, value: "2억" },
    { key: 300000000, value: "3억" },
    { key: 400000000, value: "4억" },
    { key: 500000000, value: "5억" },
    { key: 600000000, value: "6억" },
    { key: 700000000, value: "7억" },
    { key: 800000000, value: "8억" },
    { key: 900000000, value: "9억" },
    { key: 1000000000, value: "10억" },
    { key: 2000000000, value: "20억" },
    { key: 3000000000, value: "30억" },
    { key: 4000000000, value: "40억" },
    { key: 5000000000, value: "50억" },
    { key: 6000000000, value: "60억" },
    { key: 7000000000, value: "70억" },
    { key: 8000000000, value: "80억" },
    { key: 9000000000, value: "90억" },
    { key: 10000000000, value: "100억" },
];
export const VALUE_MAX_PRICE_NEW = [
    { key: 10000000, value: "1천만" },
    { key: 20000000, value: "2천만" },
    { key: 30000000, value: "3천만" },
    { key: 40000000, value: "4천만" },
    { key: 50000000, value: "5천만" },
    { key: 60000000, value: "6천만" },
    { key: 70000000, value: "7천만" },
    { key: 80000000, value: "8천만" },
    { key: 90000000, value: "9천만" },
    { key: 100000000, value: "1억" },
    { key: 200000000, value: "2억" },
    { key: 300000000, value: "3억" },
    { key: 400000000, value: "4억" },
    { key: 500000000, value: "5억" },
    { key: 600000000, value: "6억" },
    { key: 700000000, value: "7억" },
    { key: 800000000, value: "8억" },
    { key: 900000000, value: "9억" },
    { key: 1000000000, value: "10억" },
    { key: 2000000000, value: "20억" },
    { key: 3000000000, value: "30억" },
    { key: 4000000000, value: "40억" },
    { key: 5000000000, value: "50억" },
    { key: 6000000000, value: "60억" },
    { key: 7000000000, value: "70억" },
    { key: 8000000000, value: "80억" },
    { key: 9000000000, value: "90억" },
    { key: 10000000000, value: "100억" },
    { key: 20000000000, value: "200억" },
    { key: 40000000000, value: "400억" },
    { key: 60000000000, value: "600억" },
    { key: 80000000000, value: "800억" },
    { key: 100000000000, value: "1000억" },
];
