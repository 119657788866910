import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { CounterBoxType } from "@src/types/homeTypes";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";

const initialHomeCountData = {
    visitorCnt: 5155,
    supportCnt: 55,
    expertsCertCnt: 555,
    expertsAdCnt: 1621,
    subsCnt: 512,
    subsPer: 22,
};
interface IOriginData {
    visitorToday: string;
    visitorDiff: string;
    subscriptionToday: string;
    paidSubsAll: string;
    paidTodayPerJoin: string;
    paidTodayPerYesterday: string;
    paidPerAllUser: string;
    deungiUserToday: string;
    useDeungiToday: string;
    deungiUserCount: string;
    useDeungiCount: string;
    inquiryUnresolved: string;
}

const fetchData = async () => {
    const response = await axiosInstance.get(axiosPath.homeSummary);
    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    return responseData;
};

const useHomeCountData = () => {
    return useQuery<any, Error>(queryKeys.HOME_COUNT, () => fetchData(), {
        // initialData: initialHomeCountData,
    });
};

export { useHomeCountData };
