import { useQuery } from "react-query";
import {
    NoticeType,
    BaseNoticeType,
    NoticeTypeResult,
} from "@src/types/NoticeType";
import { queryKeys } from "./queryKeys";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";

// const random = (max: number) => {
//     const arr: Array<NoticeType> = [];
//     for (let i = 1; i <= max; i++) {
//         arr.push({
//             boardNo: i,
//             noticeNo: i,
//             noticeTitle: "당신의 경매비서 미스고가 오픈했어요",
//             noticeContent:
//                 "<p>미스고는 회원님의 소중한 정보보호를 항상 안전하게 관리하고 있습니다. 아래 계정보호 TIP을 활용하여 미스고계정을 더욱 안전하게 지켜주세요. 미스고는 회원님의 소중한 정보보호를 항상 안전하게 관리하고 있습니다. 아래 계정보호 TIP을 활용하여 미스고계정을 더욱 안전하게 지켜주세요. 미스고는 회원님의 소중한 정보보호를 항상 안전하게 관리하고 있습니다. 아래 계정보호 TIP을 활용하여 미스고계정을 더욱 안전하게 지켜주세요.</p>",
//             view: Math.floor(Math.random() * 2) ? true : false,
//             noticeDate: "2022.02.28 12:24:22",
//             updateDate: "2022.03.04 12:24:22",
//             more: i,
//             edit: false,
//         });
//     }
//     return arr;
// };

// const initialNoticeData = random(500);
interface ParametersType {
    q?: string | undefined;
    page?: number | undefined;
    limit?: number;
}
const fetchData = async (params: ParametersType) => {
    const response = await axiosInstance.get(`${axiosPath.boardNotices}`, {
        params,
    });

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;

    const originData: BaseNoticeType[] = responseData.list;

    return originData.length
        ? {
              result: parse(originData),
              total: responseData.totalCnt,
          }
        : { result: [], total: 0 };
};
const parse = (originData: BaseNoticeType[]) => {
    const newData: NoticeType[] = originData.map((item: BaseNoticeType) => {
        const {
            no,
            title,
            contents,
            isOpen,
            createdAt,
            updatedAt,
            category,
            displayOrder,
        } = item;
        return {
            title: title,
            contents: contents,
            isOpen: isOpen,
            createdAt: createdAt,
            updatedAt: updatedAt,
            category: category,
            displayOrder: displayOrder,
            edit: updatedAt ? true : false,
            viewControl: no,
        };
    });

    return newData;
};
const useNoticeFetchData = (params: ParametersType) => {
    return useQuery<NoticeTypeResult, Error>(
        [queryKeys.NOTICE_DATA, { ...params }],
        () => fetchData(params),
        {
            // initialData: initialNoticeData,
            cacheTime: 0, // react query 기본 캐싱 (5min)
        },
    );
};
const initParams = {
    page: 1,
    limit: 10,
};
export { useNoticeFetchData, initParams };
