import { createSlice } from "@reduxjs/toolkit";

const expectedReturnData = [
    {
        id: 0,
        type: "price",
        title: "예상 낙찰가(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 1,
        type: "price",
        title: "예상 매도가(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 2,
        type: "price",
        title: "예상 대출금(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 3,
        type: "price",
        title: "예상 비용(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 4,
        type: "price",
        title: "예상 취득세(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 5,
        type: "price",
        title: "예상 양도소득세(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 6,
        type: "price",
        title: "예상 중계수수료(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 7,
        type: "price",
        title: "예상 수익(원)",
        none: "79999000",
        one: "99999000",
        many: "99999000",
    },
    {
        id: 8,
        type: "rate",
        title: "예상 수익률(%)",
        none: "15",
        one: "10",
        many: "5",
    },
];

const initialState = expectedReturnData;

export const expectedReturnSlice = createSlice({
    name: "expectedReturn",
    initialState,
    reducers: {
        createExpectedReturn: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateExpectedReturn: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createExpectedReturn, updateExpectedReturn } =
    expectedReturnSlice.actions;

export default expectedReturnSlice.reducer;
