import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { PageTable, Title, Button, ConfirmModal } from "@src/components";
import { useNavigate } from "react-router-dom";
import { useNewsData } from "@src/hooks/queries/useNews";
import { useDeleteNews } from "@src/hooks/queries/useNewsDetail";
import { ListNewsProps } from "@src/interfaces/News.interface";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { getColumns } from "./NewsListColumns";

function NewsList() {
    const [isModalShow, setIsModalShow] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState<ListNewsProps[]>([]);
    const [params, setParams] = useState({ page: 1 });
    const {
        isLoading,
        data: newsData = { result: [], total: 0 },
        isError,
        refetch,
    } = useNewsData(params.page !== 0 ? params : {});
    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoading && !isError && newsData) {
            setData(newsData.result);
            if (params.page === 1) {
                setTotalCount(newsData.total);
            }
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        } else {
            dispatch(startLoading());
        }
    }, [newsData.result]);
    const { mutate: deleteMutate } = useDeleteNews();
    const onDelete = (articleId: string) => {
        deleteMutate(articleId, {
            onSuccess: () => {
                console.log("삭제되었습니다.");
            },
            onError: (error: any) => {
                console.log(error);
            },
            onSettled: () => refetch(),
        });
    };
    const [deleteNo, setDeleteNo] = useState("");
    const columns = getColumns({ navigate, setDeleteNo, setIsModalShow });
    return (
        <div css={listStyle}>
            <Title title="뉴스 관리" />
            <div css={searchDiv}>
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/news/create");
                    }}
                    label="뉴스 등록"
                    size="medium"
                />
            </div>
            <div css={tableDiv}>
                {data.length !== 0 && (
                    <PageTable
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                            hiddenColumns: ["articleId"],
                        }}
                        columns={columns}
                        data={data}
                        FixedHeight={900}
                        totalCount={totalCount}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParams({ page });
                        }}
                        showTotalCount={true}
                        forcePage={params.page === 1}
                    ></PageTable>
                )}
            </div>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() => setIsModalShow((prev) => !prev)}
                handleOk={() => {
                    onDelete(deleteNo);
                    setIsModalShow((prev) => !prev);
                }}
                isModalShow={isModalShow}
            >
                <>정말 삭제하시겠습니까?</>
            </ConfirmModal>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const searchDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        button:nth-of-type(1) {
            margin-right: 15px;
        }
    }
`;

export default NewsList;
