import { arrayToDropdown } from "@src/utils/dropdownUtil";
export const WAITING_REPLY = "미답변";
export const COMPLETE_REPLY = "답변";

export const SupportStatus: { [key: number]: any } = {
    0: [WAITING_REPLY, "purple"],
    1: [COMPLETE_REPLY, "gray"],
};

//검색 카테고리 드롭다운 리스트
export const searchStatusList = [
    { id: 0, value: "등록 번호", key: "conversation_id" },
    { id: 1, value: "문의자", key: "user_name" },
    { id: 2, value: "문의 내용", key: "inquiry_contents" },
    { id: 3, value: "답변자", key: "admin_name" },
    { id: 4, value: "문의 제목", key: "title" },
];

//답변 상태 드롭다운 리스트
export const ansStatusList = arrayToDropdown(["전체", "답변", "미답변"]);

//* inquiry type
export const inquiryTypeList = arrayToDropdown(["전체", "APP", "PC"]);

export const CATEGORY_LIST = {
    SERVICE_QST: "서비스 이용",
    SUBSCRIBE_QST: "미스고 정기구독",
    EXPERT_QST: "전문가 회원",
    EXPERT_SERVICE_QST: "전문가 상담/입찰대행",
    PAYMENT_QST: "결제/환불/정산",
};
