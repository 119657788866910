import React from "react";
import { IconButton } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";

export const couponColumns = (navigate: any) => [
    {
        Header: "쿠폰 고유번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "쿠폰명",
        accessor: "name",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품 코드",
        accessor: "product_code",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "진행 상태",
        accessor: "status",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "쿠폰 형태",
        accessor: "code_type",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "사용/발행",
        accessor: "count_status",
        Cell: ({ cell: { value } }: any) => (
            <div style={{ textAlign: "center" }}>
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "발행 일시",
        accessor: "created_at",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    fontSize: "13px",
                    textAlign: "center",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "최종 수정일시",
        accessor: "updated_at",
        Cell: ({ row }: any) => {
            return (
                <div
                    style={{
                        fontSize: "13px",
                        textAlign: "center",
                    }}
                >
                    {row.original.updated_at
                        ? row.original.updated_at
                        : row.original.created_at}
                </div>
            );
        },
    },
    {
        Header: "관리",
        accessor: "viewControl",
        Cell: ({ row }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <IconButton
                    type="edit"
                    handleClick={() => {
                        navigate(`/coupon/update/${row.original.no}`, {
                            state: {
                                info: row.original,
                            },
                        });
                    }}
                />
            </div>
        ),
    },
    {
        Header: "사용내역",
        accessor: "viewMore",
        Cell: ({ row }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <IconButton
                    type="arrow"
                    handleClick={() => {
                        const option =
                            "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1600,height=1200,top=250,left=250";
                        const checkDev =
                            window.location.host === "dev.missgoauction.com";
                        const newUrl = checkDev
                            ? `${window.location.origin}/admin/coupon/detail/${row.original.no}?usedCount=${row.original.used_count}&issuedCount=${row.original.issued_count}`
                            : `${window.location.origin}/coupon/detail/${row.original.no}?usedCount=${row.original.used_count}&issuedCount=${row.original.issued_count}`;
                        window.open(
                            `${newUrl}?usedCount=${row.original.used_count}&issuedCount=${row.original.issued_count}`,
                            "detail",
                            option,
                        );
                    }}
                />
            </div>
        ),
    },
];
