import styled from "@emotion/styled";
import { Button, ConfirmModal, PageTable, Title } from "@src/components";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import { usePartnerList, useUpdateSelectedPartnerToCancel } from "@src/hooks/queries/usePartner";
import { getQueryString } from "@src/hooks/queries/useUser";
import { PartnerListDto } from "@src/interfaces/Partner.interface";
import { getQueryData } from "@src/utils/useQueryClient";
import { endLoading, startLoading } from "@src/reducers/loading";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getColumns } from "./PartnerListColumns";
import PartnerListFilter from "./PartnerListFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { useQueryClient } from "react-query";
import usePartnerFilter from "@src/hooks/usePartnerFilter";

function serializeFilter(filter: PartnerListDto): string {
    let params = new URLSearchParams();
    for (let key in filter) {
        if (Array.isArray(filter[key])) {
            if (filter[key].length > 0) {
                params.append(key, filter[key].toString());
            }
        } else if (filter[key]) {
            params.append(key, filter[key].toString());
        }
    }
    return params.toString();
}

const Partner = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const pagesInitialState = {
        pageIndex: 0,
        pageSize: 10,
    };
    const initParams = {
        page: 1,
        limit: 10,
        searchColumn: "userInfo.uname",
    };

    const queryClient = useQueryClient();

    const [filter, setFilter] = usePartnerFilter(initParams);
    const [partnerList, setPartnerList] = useState<any>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    const newQuery = getQueryString(filter);

    const queryData: { result: any[]; total: number } = getQueryData([queryKeys.PARTNER_LIST, newQuery]);

    const { isLoading, data = { result: [], total: 0 }, isError, refetch } = usePartnerList(filter);

    const onSelectedRowsChange = useCallback(
        (selectedFlatRows: any) => {
            const selectedRowsData = selectedFlatRows.map((row: any) => row.original);
            setSelectedRows(selectedRowsData);
        },
        [setSelectedRows],
    );

    const mutateSelectedPartnerToCancel = useUpdateSelectedPartnerToCancel(() => {
        console.log(filter);
        queryClient.invalidateQueries([queryKeys.PARTNER_LIST, filter]);
        setIsOpenConfirmModal(false);
    });

    const handleDeleteClick = () => {
        setIsOpenConfirmModal(true);
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (queryData) {
            setPartnerList(queryData.result);
            setTotalCount(queryData.total);
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setPartnerList(data.result);
                if (filter.page === 1) {
                    setTotalCount(data.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [isLoading, isError, data.result]);

    useEffect(() => {
        const newQueryParams = serializeFilter(filter);
        navigate(location.pathname + "?" + newQueryParams);
    }, [filter]);

    const handlePageChange = useCallback(
        (index: number) => {
            const page = index + 1;
            const limit = 10;
            setFilter({
                ...filter,
                page: page,
                limit: limit,
            });
        },
        [filter],
    );

    const columns = useMemo(() => getColumns(navigate), []);
    const partnerData = useMemo(() => partnerList ?? [], [partnerList]);
    const totalItemCount = useMemo(() => totalCount, [totalCount]);

    return (
        <>
            <StyledWrap>
                <Title title="파트너 관리" />
                <div className="btnDiv">
                    <Button
                        color="gd"
                        handleClick={() => {
                            navigate("/partner/register", {
                                state: { type: "create" },
                            });
                        }}
                        label="파트너 등록"
                        size="medium"
                    />
                </div>
                <PartnerListFilter filter={filter} setFilter={setFilter} />
                <div className="delete_button">
                    <Button
                        color="gd"
                        handleClick={handleDeleteClick}
                        label="선택 회원 삭제"
                        size="medium"
                        isDisabled={selectedRows.length === 0}
                    />
                </div>
                <div css={tableBoxStyle}>
                    <PageTable
                        FixedHeight={700}
                        columns={columns}
                        data={partnerData}
                        handlePageChange={handlePageChange}
                        initialState={pagesInitialState}
                        totalCount={totalItemCount}
                        showCheckbox={true}
                        onSelectedRowsChange={onSelectedRowsChange}
                    />
                </div>
            </StyledWrap>
            <ConfirmModal
                isModalShow={isOpenConfirmModal}
                handleCancel={() => {
                    setIsOpenConfirmModal(false);
                }}
                handleOk={() => {
                    mutateSelectedPartnerToCancel.mutate(selectedRows);
                }}
            >
                <>
                    <p
                        style={{
                            fontSize: "22px",
                            fontWeight: "400",
                        }}
                    >
                        파트너 회원에서 삭제하시겠습니까?
                    </p>
                    <p
                        style={{
                            fontSize: "16px",
                            fontWeight: "400",
                        }}
                    >
                        파트너 삭제 시 지급된 구독권도 함께 취소됩니다.
                    </p>
                </>
            </ConfirmModal>
        </>
    );
};

export default Partner;

const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .btnDiv {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    .delete_button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
`;

const tableBoxStyle = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        padding: 8px 0;

        button:nth-of-type(2) {
            margin-left: 15px;
        }
        &:nth-of-type(1) {
            width: 50px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(2) {
            width: 100px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(3) {
            width: 100px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(4) {
            width: 200px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(5) {
            width: 250px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(6) {
            width: 160px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(7) {
            width: 220px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:nth-of-type(8) {
            width: 120px;
            & > div {
                margin: unset;
                width: 100%;
            }
        }
        &:last-of-type {
            width: 120px;
            & > div {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }
    }
`;
