import styled from "@emotion/styled";
import { Button, DropDown, PageTable, Title } from "@src/components";
import { useGetExpertList } from "@src/hooks/queries/useExpert";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExpertUserFilter from "./ExpertUserFilter";
import { getColumns } from "./ExpertUserListColumns";
import { initUserExpertListDto, userExpertListDto } from "./types";

const ExpertUserList = () => {
    const navigate = useNavigate();
    const columns = getColumns(navigate);
    const [filters, setFilters] = useState<userExpertListDto>(
        initUserExpertListDto,
    );
    const [params, setParams] = useState<userExpertListDto>(
        initUserExpertListDto,
    );
    const initExpertChecked = {
        noneCounselingCategoryTrue: false,
        noneCounselingCategoryFalse: false,
        scrivener: false,
        agent: false,
    };
    const [expertChecked, setExpertChecked] = useState<any>(initExpertChecked);
    const initCounselingChecked = {
        noneCounselingCategory: false,
        agent: false,
        claim: false,
        item: false,
    };
    const [counselingChecked, setCounselingChecked] = useState<any>(
        initCounselingChecked,
    );

    const { data, refetch } = useGetExpertList(params);

    const trySubsmit = () => {
        if (filters.searchValue === "") {
            delete filters.searchValue;
        }
        setParams(filters);
    };

    const tryClear = () => {
        setFilters(initUserExpertListDto);
        setParams(initUserExpertListDto);
        setExpertChecked(initExpertChecked);
        setCounselingChecked(initCounselingChecked);
    };

    useEffect(() => {
        refetch();
    }, [params]);

    const sortDropDownList: {
        id: number;
        key: string;
        value: string;
    }[] = [{ id: 0, key: "userExpert.createdAt", value: "등록일 최신순" }];

    return (
        <>
            <StyledExpertUserList>
                <Title title="전문가 회원 관리" />
                <ExpertUserFilter
                    filters={filters}
                    setFilters={setFilters}
                    expertChecked={expertChecked}
                    setExpertChecked={setExpertChecked}
                    counselingChecked={counselingChecked}
                    setCounselingChecked={setCounselingChecked}
                />
                <div className="filterBtnBox">
                    <Button
                        color="gray"
                        handleClick={() => {
                            tryClear();
                        }}
                        label="전체 해제"
                        size="medium"
                    />
                    <Button
                        color="purple"
                        handleClick={() => {
                            trySubsmit();
                        }}
                        label="검색"
                        size="medium"
                        isDisabled={false}
                    />
                </div>
                <div className="tableDiv">
                    <div className="sortDiv">
                        <DropDown
                            handleChange={(e, text, value) => {
                                value &&
                                    setFilters({
                                        ...filters,
                                        sort: value,
                                    });
                            }}
                            data={sortDropDownList}
                            defaultValue={""}
                        />
                    </div>
                    <PageTable
                        columns={columns}
                        data={data?.result ?? []}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        FixedHeight={500}
                        showTotalCount={true}
                        totalCount={data?.total ?? 0}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParams({
                                ...params,
                                page,
                            });
                            // setParams(() => {
                            //     let newParams = {
                            //         ...copyQuery,
                            //         page,
                            //         limit,
                            //         cursor: page === 1 ? "" : cursor,
                            //     };
                            //     updateURL(newParams);
                            //     return newParams;
                            // });
                        }}
                    // forcePageNumber={copyQuery?.page || params.page}
                    ></PageTable>
                </div>
            </StyledExpertUserList>
        </>
    );
};

export default ExpertUserList;

const StyledExpertUserList = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .filterBtnBox {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 10px;
    }
    .tableDiv {
        margin-top: 43px;
        width: 100%;
        height: 80vh;
        .sortDiv {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
