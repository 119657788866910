import React, { useState, useEffect, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, Calendar, Input, EditModal } from "@src/components";
import { useGetCaseInformation } from "@src/hooks/queries/product/useCaseInformation";
import { CaseInformationProps } from "@src/interfaces/product/CaseInformationContainer.interface";
import { dateParse } from "@src/utils/dateUtil";
import DocumentDeliveryHistoryContainer from "./DocumentDeliveryHistoryContainer";
interface ICaseInformation {
    isUpdating: boolean;
    isEdit: boolean;
}

const CaseInformationContainer = ({
    isUpdating = false,
    isEdit = false,
}: ICaseInformation) => {
    const PRODUCT_NO = "20120130001910-1";
    const [initData, setInitData] = useState<CaseInformationProps>({
        statedDate: null,
        caseTitle: "",
        owner: "",
        debtor: "",
        creditor: "",
        relCase: "",
        docAndDel: {},
    });
    const [isModalShow, setIsModalShow] = useState(false);
    const [updateData, setUpdateData] =
        useState<CaseInformationProps>(initData);
    const { isLoading, isError, data } = useGetCaseInformation(PRODUCT_NO);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
        }
    }, [isLoading, isError, data]);
    useEffect(() => {
        setUpdateData(initData);
    }, [initData]);
    const {
        statedDate,
        caseTitle,
        owner,
        debtor,
        creditor,
        relCase,
        docAndDel,
    } = updateData;
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateData({
            ...updateData,
            [name]: value,
        });
    };

    return (
        <div>
            <ul css={CaseInformationUl}>
                <li>
                    <span>경매개시결정일</span>
                    <Calendar
                        getUserDate={() => {}}
                        setDate={statedDate ? dateParse(statedDate) : null}
                        isDisabled={!isEdit}
                        timePicker={false}
                    />
                </li>
                <li>
                    <Input
                        label={"소유자"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="owner"
                        value={owner}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"사건명"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="caseTitle"
                        value={caseTitle}
                        border={isEdit}
                    />
                </li>

                <li>
                    <Input
                        label={"채무자"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="debtor"
                        value={debtor}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"관련사건"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="relCase"
                        value={relCase}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"채권자"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="creditor"
                        value={creditor}
                        border={isEdit}
                    />
                </li>
                {!isEdit && (
                    <li>
                        <Button
                            color="purple"
                            defaultValue="BTN_PURPLE"
                            handleClick={() => setIsModalShow(!isModalShow)}
                            label="문건/송달내역 보기"
                            size="xSmall"
                        />
                    </li>
                )}
            </ul>
            <EditModal
                isModalShow={isModalShow}
                title={"문건송달내역"}
                buttonText={"확인"}
                handleOpenModal={() => setIsModalShow(!isModalShow)}
                size={"fitContent"}
                handleBtnClick={() => setIsModalShow(!isModalShow)}
                isDisabled={false}
                errorMessage={"수정시 개발팀에 문의해주세요."}
            >
                <DocumentDeliveryHistoryContainer
                    isUpdate={false}
                    initData={docAndDel}
                />
            </EditModal>
        </div>
    );
};
const CaseInformationUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 15px;
    justify-content: flex-start;
    li {
        display: flex;
        align-items: center;
        width: 40%;
        & > span {
            width: 100px;
            display: inline-block;
            vertical-align: bottom;
            line-height: 60px;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
        & > button {
            margin-left: 0 !important;
        }
    }
    li:first-of-type {
        min-width: 400px;
    }
    input:disabled {
        background-color: #fff;
        color: ${Common.colors.black};
    }
`;
export default CaseInformationContainer;
