import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ConfirmModal, PageTable, Title } from "@src/components";
import {
    getQueryString,
    useDeungiChangeList,
    useDeungiChangeRemover,
    useDeungiChangeRestore,
    useGetLogList,
} from "@src/hooks/queries/useDeungiChange";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import { getQueryData } from "@src/utils/useQueryClient";
import { endLoading, startLoading } from "@src/reducers/loading";
import { useDispatch } from "react-redux";
import { getColumns } from "./DeungiChangeListColumns";
import DeungiChangeListFilter from "./DeungiChangeFilter";
import DeungiChangeAlarmLogModal from "./DeungiChangeAlarmLogModal";
import { DeungiChangeListDto } from "@src/interfaces/DeungiChange.interface";

const DeungiChangeList = () => {
    const dispatch = useDispatch();

    const pagesInitialState = {
        pageIndex: 0,
        pageSize: 10,
    };
    const initParams = {
        page: 1,
        limit: 10,
    };

    const [filter, setFilter] = useState<DeungiChangeListDto>(initParams);
    const [deungiChangeList, setDeungiChangeList] = useState<any>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [openAlarmLogList, setOpenAlarmLogList] = useState<boolean>(false);
    const [openDelDialog, setOpenDelDialog] = useState<boolean>(false);
    const [openReDialog, setOpenReDialog] = useState<boolean>(false);
    const [logId, setLogId] = useState<string>("");
    const [delId, setDelId] = useState<string>("");
    const newQuery = getQueryString(filter);

    const queryData: { result: any[]; total: number } = getQueryData([
        queryKeys.PARTNER_LIST,
        newQuery,
    ]);

    const {
        isLoading,
        data = { result: [], total: 0 },
        isError,
        refetch,
    } = useDeungiChangeList(filter);

    const { data: logData, refetch: refetchRog } = useGetLogList({
        page: 1,
        limit: 999,
        id: logId ?? "",
    });

    const columns = getColumns({
        setLogId,
        setOpenAlarmLogList,
        refetchRog,
        setDelId,
        setOpenDelDialog,
        setOpenReDialog,
    });

    const deleteDeungiChange = useDeungiChangeRemover(
        () => {
            setOpenDelDialog(false);
            setDelId("");
            refetch();
        },
        (err) => {
            console.error(err);
            setOpenDelDialog(false);
            setDelId("");
        },
    );

    const onSubmitDeleteDeungiChange = () => {
        const param = delId;
        deleteDeungiChange.mutate(param);
    };

    const restoreDeungiChange = useDeungiChangeRestore(
        () => {
            setOpenReDialog(false);
            setDelId("");
            refetch();
        },
        () => {
            setOpenReDialog(false);
            setDelId("");
        },
    );

    const onSubmitRestoreDeungiChange = () => {
        const param = delId;
        restoreDeungiChange.mutate(param);
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (queryData) {
            setDeungiChangeList(queryData?.result);
            setTotalCount(queryData?.total);
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setDeungiChangeList(data?.result);
                if (filter.page === 1) {
                    setTotalCount(data?.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [isLoading, isError, data?.result]);

    useEffect(() => {
        if (logId) refetchRog();
    }, [logId]);

    return (
        <>
            <StyledWrap>
                <Title title="등기변동 알림" />
                <DeungiChangeListFilter
                    filter={filter}
                    setFilter={setFilter}
                    refetch={refetch}
                />
                <TotalCountBox>
                    총 {totalCount.toLocaleString()}건
                </TotalCountBox>
                <div css={tableDiv}>
                    <PageTable
                        FixedHeight={700}
                        columns={columns}
                        data={deungiChangeList ?? []}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            const limit = 10;
                            setFilter({
                                ...filter,
                                page: page,
                                limit: limit,
                            });
                        }}
                        initialState={pagesInitialState}
                        totalCount={totalCount}
                    />
                </div>
                {openAlarmLogList && (
                    <DeungiChangeAlarmLogModal
                        open={openAlarmLogList}
                        data={logData}
                        onCloseView={() => setOpenAlarmLogList(false)}
                    />
                )}
                <ConfirmModal
                    isModalShow={openDelDialog}
                    buttonText="삭제"
                    handleCancel={() => {
                        setDelId("");
                        setOpenDelDialog(false);
                    }}
                    handleOk={() => {
                        onSubmitDeleteDeungiChange();
                    }}
                >
                    정말 삭제하시겠습니까?
                </ConfirmModal>
                <ConfirmModal
                    isModalShow={openReDialog}
                    buttonText="확인"
                    handleCancel={() => {
                        setDelId("");
                        setOpenReDialog(false);
                    }}
                    handleOk={() => {
                        onSubmitRestoreDeungiChange();
                    }}
                >
                    복구하시겠습니까?
                </ConfirmModal>
            </StyledWrap>
        </>
    );
};

export default DeungiChangeList;

const StyledWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    .btnDiv {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
`;

const TotalCountBox = styled.div`
    margin: 12px;
    font-size: 14px;
    line-height: 17px;
`;

const tableDiv = css`
    width: 100%;
    height: 80vh;
    table {
        th:first-of-type {
            width: 4%;
        }
        th:nth-of-type(2) {
            width: 8%;
        }
        th:nth-of-type(3) {
            width: 4%;
        }
        th:nth-of-type(4) {
            width: 6%;
        }
        th:nth-of-type(5) {
            width: 4%;
        }
        th:nth-of-type(6) {
            width: 4%;
        }
        th:nth-of-type(7) {
            width: 10%;
        }
        th:nth-of-type(8) {
            width: 4%;
        }
        th:nth-of-type(9) {
            width: 4%;
        }
        th:nth-of-type(10) {
            width: 4%;
        }
        th:nth-of-type(11) {
            width: 4%;
        }
        th:nth-of-type(12) {
            width: 4%;
        }
        th:nth-of-type(13) {
            width: 3%;
        }
    }
    td {
        padding: 10px 0;
    }
`;
