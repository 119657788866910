import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, ConfirmModal, TextBox, Title } from "@src/components";
import {
    useGetSupportDetailData,
    useUpdateSupportData,
    useDeleteSupportData,
} from "@src/hooks/queries/useSupportDetail";
import { ConvertPhoneNumber } from "@src/utils/units";
import { ResultSupportType, UpdateSupportType } from "../supportTypes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { startLoading, endLoading } from "@src/reducers/loading";
import ReplyHistory from "./ReplyHistory";
import { useParams } from "react-router-dom";
import { initFilters, useSupportData } from "@src/hooks/queries/useSupport";
import arrowLeftIcon from "@src/assets/ico_arrow_left.svg";
import arrowRightIcon from "@src/assets/ico_arrow_right_green.svg";
import arrowRightDisableIcon from "@src/assets/ico_arrow_right_disable.svg";
import arrowLeftDisableIcon from "@src/assets/ico_arrow_left_disable.svg";

function ReplyDetail() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const SUPPORT_ID = id ?? "48d55dfa-46fd-4044-b28c-6b908155ae8e";
    const [showSavePopup, setShowSavePopup] = useState(false);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedImg, setSelectedImg] = useState<number>(0);
    const [showSelectedImg, setShowSelectedImg] = useState<boolean>(false);
    const [replyText, setReplyText] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [initData, setInitData] = useState<ResultSupportType>({
        user: {
            user_no: 0,
            id: "",
            uname: "",
            phone: "",
        },
        result: {
            no: 0,
            category: "",
            userQst: "",
            reply: "",
            prevReply: [],
            device: "",
        },
    });
    const {
        isLoading,
        data,
        isError,
        refetch: singleRefetch,
    } = useGetSupportDetailData(SUPPORT_ID);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            // console.log("data", data);
            setInitData(data);
        }
    }, [isLoading, isError, data]);
    const { mutate: updateMutate } = useUpdateSupportData(
        SUPPORT_ID,
        initData.result.no,
    );
    const { refetch: listRefetch } = useSupportData(initFilters);
    const onUpdate = () => {
        const params: UpdateSupportType = {
            user_no: initData?.user.user_no,
            category: initData?.result.category,
            contents: replyText,
        };
        dispatch(startLoading());
        updateMutate(params, {
            onSuccess: () => {
                listRefetch();
                singleRefetch();
            },
            onError: (error: any) => {
                console.log(error);
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };
    const { mutate: deleteMutate } = useDeleteSupportData(initData?.result.no);
    const onDelete = () => {
        dispatch(startLoading());
        deleteMutate(SUPPORT_ID, {
            onSuccess: () => {
                listRefetch();
                singleRefetch();
                navigate("/help/support");
            },
            onError: (error: any) => {
                console.log(error);
            },
            onSettled: () => {
                dispatch(endLoading());
            },
        });
    };

    useEffect(() => {
        singleRefetch();
    }, []);

    return (
        <div css={WrapperDiv}>
            <Title title="문의 상세" />
            <div css={ContainerDiv}>
                <ul>
                    <li>문의자</li>
                    <li>{initData?.user?.uname}</li>
                    <li>이메일</li>
                    <li>{initData?.user?.id}</li>
                    <li>휴대폰 번호</li>
                    <li>{ConvertPhoneNumber(initData?.user?.phone)}</li>
                    <li>구분</li>
                    <li>{initData?.result?.device?.toUpperCase()}</li>
                </ul>
                <ul>
                    <li>문의 제목</li>
                    <li>{initData?.result?.title}</li>
                </ul>
                <ul>
                    <li>첨부파일</li>
                    <li css={imageBoxStyle}>
                        {initData?.result?.imgurl?.map(
                            (img: any, idx: number) => (
                                <img
                                    src={img?.path}
                                    key={idx.toString()}
                                    width={150}
                                    onClick={() => {
                                        setSelectedImg(idx);
                                        setShowSelectedImg(true);
                                    }}
                                />
                            ),
                        )}
                    </li>
                </ul>
                <ul>
                    <li>문의 사항</li>
                    <li>
                        <div className="userQstDiv">
                            {initData?.result.userQst}
                        </div>
                    </li>
                </ul>
                <ul>
                    <li>문의 답변</li>
                </ul>
                <ul>
                    <li>
                        <div css={replyContent}>{initData?.result?.reply}</div>
                    </li>
                </ul>
                <ul className="buttons">
                    <li>
                        <Button
                            color="black"
                            handleClick={() => {
                                setShowDeletePopup(true);
                            }}
                            label="답변 삭제"
                            size="medium"
                        />
                    </li>
                    <li>
                        <Button
                            color="gd"
                            handleClick={() => {
                                setShowSavePopup(!showSavePopup);
                            }}
                            label="답변 수정"
                            size="medium"
                        />
                    </li>
                </ul>
                <ReplyHistory data={initData.result.prevReply} />
                <ConfirmModal
                    isModalShow={showSavePopup}
                    buttonText={"답변 수정"}
                    handleOk={() => {
                        setShowSavePopup(!showSavePopup);
                        onUpdate();
                    }}
                    handleCancel={() => setShowSavePopup(!showSavePopup)}
                    disabled={disabled}
                >
                    <div css={modifyContent}>
                        <Title title="답변 수정" />
                        <div>문의사항 : {initData?.result.userQst}</div>
                        <div className="reply">{initData?.result.reply}</div>
                        <TextBox
                            handleChange={(value, isError = false) => {
                                setReplyText(value);
                                setDisabled(isError);
                            }}
                            label=""
                            maxLength={1000}
                            placeholder="답변 내용을 입력해 주세요."
                            size="dynamic"
                            isDisabled={false}
                            defaultText={replyText}
                        />
                        <p css={notiMessage}>
                            ※ 수정한 답변은 즉시 노출되니 신중하게 작성해 주세요
                        </p>
                    </div>
                </ConfirmModal>
                <ConfirmModal
                    handleOk={() => {
                        onDelete();
                        setShowDeletePopup(!showDeletePopup);
                    }}
                    handleCancel={() => setShowDeletePopup(!showDeletePopup)}
                    isModalShow={showDeletePopup}
                    buttonText={"삭제"}
                >
                    답변을 삭제하겠습니까? 사용자 문의가 미답변 상태로
                    돌아갑니다.
                </ConfirmModal>
                <ConfirmModal
                    isModalShow={showSelectedImg}
                    buttonText={"닫기"}
                    handleOk={() => {
                        setShowSelectedImg(false);
                        setSelectedImg(0);
                    }}
                    handleCancel={() => {}}
                    disabledCancelBtn={true}
                >
                    <div css={imageModalBoxStyle}>
                        <img
                            src={
                                selectedImg === 0
                                    ? arrowLeftDisableIcon
                                    : arrowLeftIcon
                            }
                            alt=""
                            className={selectedImg !== 0 ? "arrow" : "default"}
                            onClick={() => {
                                if (selectedImg !== 0) {
                                    setSelectedImg((pre) => pre - 1);
                                }
                            }}
                        />
                        <img
                            src={initData?.result?.imgurl?.[selectedImg]?.path}
                            alt=""
                            width={100}
                        />
                        <img
                            src={
                                selectedImg ===
                                initData?.result?.imgurl?.length - 1
                                    ? arrowRightDisableIcon
                                    : arrowRightIcon
                            }
                            alt=""
                            className={
                                selectedImg !==
                                initData?.result?.imgurl?.length - 1
                                    ? "arrow"
                                    : "default"
                            }
                            onClick={() => {
                                if (
                                    selectedImg !==
                                    initData?.result?.imgurl?.length - 1
                                ) {
                                    setSelectedImg((pre) => pre + 1);
                                }
                            }}
                        />
                    </div>
                </ConfirmModal>
            </div>
        </div>
    );
}

const WrapperDiv = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const ContainerDiv = css`
    margin-top: 20px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray200};
    display: flex;
    flex-direction: column;
    ${Common.textStyle.title5_M14}

    & > ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;
        height: fit-content;

        & > li:nth-of-type(odd) {
            min-width: 120px;
        }
        & > li:nth-of-type(even) {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
        }
        &.buttons {
            flex-direction: row-reverse;
            li:nth-of-type(even) {
                margin-right: 10px;
                display: block;
                flex: unset;
                button {
                    display: block;
                }
            }
        }
    }
    .userQstDiv {
        line-height: 3;
    }
`;

const replyContent = css`
    color: ${Common.colors.purple500};
    ${Common.textStyle.text1_R13}
    line-height: 160%;
    white-space: pre-wrap;
`;

const modifyContent = css`
    height: fit-content;
    min-height: 350px;
    white-space: pre-wrap;
    textarea {
        height: 200px;
    }
    div {
        padding: 10px 0;
    }
    .reply {
        color: ${Common.colors.purple500};
        font-size: 13px;
    }
`;

const replyLogUl = css`
    width: 100%;
    & > li {
        width: 100%;
    }
`;
const replyLogContent = css`
    margin-top: 20px;
    padding-top: 20px;
    color: ${Common.colors.gray700};
    ${Common.textStyle.text1_R13}
    text-decoration-line: line-through;
    line-height: 160%;
    white-space: pre-wrap;
    width: 100%;
    border-top: 1px solid #fff;
`;
const notiMessage = css`
    color: ${Common.colors.orange};
    font-size: 13px;
    margin-bottom: 70px;
`;

const imageBoxStyle = css`
    display: flex;
    gap: 14px;
`;

const imageModalBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 690px;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    img:nth-of-type(2) {
        width: 98%;
    }
    & > .arrow {
        cursor: pointer;
    }
    & > .default {
        width: 30px;
    }
`;

export default ReplyDetail;
