import React, { ChangeEvent, useEffect, useState } from "react";
import "@src/styles/CommonTable.css";
import { css } from "@emotion/react";
import { useGetSalesStatus } from "@src/hooks/queries/product/useSalesStatus";
import {
    Title,
    EditModal,
    IconButton,
    Input,
    TextBox,
    Button,
} from "@src/components";
import { Common } from "@src/styles/Common";

interface ISaleStatusContainer {
    isEdit: boolean;
    handleUpdate?: Function;
}
function SaleStatusContainer({
    isEdit = false,
    handleUpdate,
}: ISaleStatusContainer) {
    const PRODUCT_NO = "20140130009118-3";
    const HEADER_META = isEdit
        ? ["구분", "소재지"]
        : ["구분", "소재지", "번호", "상세정보"];
    const [tableData, setTableData] = useState<any>();
    const [isPopupOpen, setPopupOpen] = useState({ isShow: false, data: "" });
    const { isLoading, isError, data } = useGetSalesStatus(PRODUCT_NO);
    const [updateData, setUpdateData] = useState<any>();
    const [detailTitle, setDetailTitle] = useState("");
    const [documentUrl, setDocumentUrl] = useState("");
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setTableData(data.list);
            setUpdateData({ options: data.options, appraisal: data.appraisal });
            setDetailTitle(data.list?.[0]?.type ?? "");
            setDocumentUrl(data.documentUrl);
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);

    const handleChange = (updateData: any, id: string) => {
        let newTableData = [...tableData];
        const index = newTableData.findIndex((v) => v.id === id);
        newTableData[index] = updateData;
        setTableData(newTableData);
    };
    return (
        <>
            <div>
                {tableData && (
                    <table className="commonTable">
                        <thead>
                            <tr>
                                {HEADER_META.map((headerText, index) => (
                                    <th key={index}>{headerText}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData &&
                                tableData.map((data: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={`${index}_${index + 1}`}
                                            data={data}
                                            isEdit={isEdit}
                                            handleChange={handleChange}
                                            setPopupOpen={setPopupOpen}
                                        />
                                    );
                                })}
                        </tbody>
                    </table>
                )}
                <div style={{ margin: "10px" }}>
                    <ul css={MainContainerUl}>
                        <li>
                            <span>물건참고사항</span>
                            <TextBox
                                label={""}
                                placeholder="-"
                                handleChange={() => {}}
                                isDisabled={!isEdit}
                                defaultText={updateData?.options || ""}
                                size={"small"}
                            />
                        </li>
                        <li>
                            <Button
                                color="purple"
                                defaultValue="BTN_PURPLE"
                                handleClick={function noRefCheck() {
                                    window.open(documentUrl);
                                }}
                                label="감정평가서원본"
                                size="xSmall"
                            />
                        </li>
                    </ul>
                    <ul css={MainContainerUl}>
                        <li>
                            <span>요약</span>
                            {isEdit ? (
                                <TextBox
                                    label={""}
                                    placeholder="-"
                                    handleChange={() => {}}
                                    isDisabled={!isEdit}
                                    defaultText={updateData?.appraisal || ""}
                                    size={"small"}
                                />
                            ) : (
                                <div css={appraisalDiv}>
                                    {updateData?.appraisal || ""}
                                </div>
                            )}
                        </li>
                    </ul>
                </div>
            </div>

            <EditModal
                isModalShow={isPopupOpen.isShow}
                buttonText="저장"
                handleBtnClick={() => {}}
                handleOpenModal={() => {
                    setPopupOpen((prev) => {
                        return {
                            isShow: !prev.isShow,
                            data: prev.data,
                        };
                    });
                }}
                size={"medium"}
                title={"매각물건 상세정보"}
            >
                <>
                    <Title title={detailTitle} size="small" />
                    <Title title="참고" size="small" />
                    <TextBox
                        handleChange={() => {}}
                        isDisabled={false}
                        label=""
                        placeholder=""
                        size="dynamic"
                        defaultText={isPopupOpen.data}
                    />
                </>
            </EditModal>
        </>
    );
}

interface ITableRow {
    data: any;
    isEdit: boolean;
    handleChange: Function;
    setPopupOpen: any;
}
const TableRow = ({ data, isEdit, handleChange, setPopupOpen }: ITableRow) => {
    const [rowData, setRowData] = useState(data);
    const { id, type, location, detail, no } = rowData;

    useEffect(() => {
        handleChange(rowData);
    }, [rowData]);

    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newRowData = { ...rowData };
        let key: keyof any;

        for (key in newRowData) {
            if (key === name) {
                newRowData[name] = value;
            }
        }
    };
    return (
        <tr
            key={data.id + data.bidDate}
            style={isEdit ? { borderBottom: "none" } : undefined}
        >
            <td style={{ width: "180px" }}>
                <Input
                    name="type"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={type}
                />
            </td>
            <td style={{ width: "600px" }}>
                <Input
                    name="location"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={location}
                />
            </td>
            {!isEdit && (
                <td>
                    <Input
                        name="no"
                        errorMessage={""}
                        handleChange={onChangeField}
                        isDisabled={true}
                        label=""
                        placeholder=""
                        size="dynamic"
                        type="text"
                        value={no}
                    />
                </td>
            )}
            {!isEdit && (
                <td css={BtnEditColumn}>
                    <IconButton
                        type="edit"
                        color="orange"
                        handleClick={() =>
                            setPopupOpen({
                                isShow: true,
                                data: detail,
                            })
                        }
                    />
                </td>
            )}
        </tr>
    );
};
const MainContainerUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    justify-content: flex-start;
    li {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        & > span {
            width: 100px;
            min-width: 100px;
            display: inline-block;
            vertical-align: bottom;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
        & > button {
            margin-left: 0 !important;
        }
    }
`;

const BtnEditColumn = css`
    display: flex;
    justify-content: center;
    & > button {
        margin: 0 !important;
    }
`;
const appraisalDiv = css`
    ${Common.textStyle.body3_R14}
    white-space: pre-line;
    line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;
export default SaleStatusContainer;
