export const STANDARD_INFORMATION = "기본 정보";
export const EXPECTED_RETURN = "예상 수익률";
export const COMMENT = "미스고 코멘트";
export const DETAIL_INFORMATION = "세부 정보";
export const DATE_HISTORY = "기일 내역";
export const AUCTION_PRODUCT = "매각 물건";
export const LESSEE = "임차인";
export const REGISTERED_INFORMATION = "등기 정보";
export const BUILDING_INFORMATION = "건물 정보";
export const REAL_PRICE = "실거래가";
export const SURROUND_INFORMATION = "주변 정보";
export const CASE_ANALYSIS = "매각 사례 분석";
export const BID_HISTORY = "입찰 이력";
export const LOCATION_STATUS = "위치 및 현황";
export const SEIZE_INFORMATION = "압류 정보";
export const SALE_CASE = "인근 매각 사례";
export const REGISTERED_INFO_PUBLIC = "등기 사항 증명서 주요 정보";
export const RENT_INFO_PUBLIC = "임대차 정보";
export const OCCUPANCY_RELATION = "점유 관계";
export const DISTRIBUTION_OR_BOND = "배분 요구 및 채권 신고";
export const BUILDING_REGIS_INFO = "건물 등기 정보";
export const LAND_REGIS_INFO = "토지 등기 정보";
export const SUMMARY_INFO = "간략정보";
export const FAIL_BID_HISTORY = "유찰내역";

interface IExpectedReturnTitles {
    [key: string]: string;
}
export const expectedReturnTitles: IExpectedReturnTitles = {
    sale: "예상 매도가(원)",
    bid_sucs: "예상 낙찰가(원)",
    loan: "예상 대출금(원)",
    acq_tax: "예상 취득세(원)",
    brkg: "예상 중개수수료(원)",
    ti_tax: "예상 양도소득세(원)",
    total: "예상 비용(원)",
    income: "예상 수익(원)",
    invstmnt: "예상 실투자금(원)",
    er_rate: "예상 수익률(%)",
};
