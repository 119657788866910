import React, { useEffect, useState, ChangeEvent } from "react";
import { css } from "@emotion/react";
import {
    Input,
    ImageListView,
    Calendar,
    ToggleButton,
    DropDown,
} from "@src/components";
import { Common } from "@src/styles/Common";
import { validChecker } from "@src/utils/validationCheck";
import {
    FormNewsProps,
    FormErrorMessage,
} from "@src/interfaces/News.interface";
import { ddlNewsCategory } from "@src/constants/NewsCategory";
import { dateParse } from "@src/utils/dateUtil";

interface INewsForm {
    initData: FormNewsProps;
    handleUpdate(data: FormNewsProps): void;
    isUpdate: boolean;
}

function NewsForm({ initData, handleUpdate, isUpdate = false }: INewsForm) {
    const MAX_LENGTH = 1000;
    const [updateData, setUpdateData] = useState<FormNewsProps>(initData);
    const [errorMessage, setErrorMessage] = useState<FormErrorMessage>({});
    useEffect(() => {
        handleUpdate(updateData);
    }, [updateData]);

    useEffect(() => {
        if (isUpdate) {
            setUpdateData(initData);
        }
    }, [isUpdate, initData]);
    const diffChecker = (key: string, value: any): boolean => {
        let k = key as keyof FormNewsProps;
        return initData[k] !== value;
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        const active = diffChecker(name, value);
        const { result: spacebarResult, errMsg: spacebarMessage } =
            validChecker("spacebar", value);
        const { result: maxLengthResult, errMsg: maxLengthMessage } =
            validChecker("maxLength", value, MAX_LENGTH);
        const { result: urlResult, errMsg: urlMessage } = validChecker(
            "url",
            value,
        );
        setUpdateData({
            ...updateData,
            ...{ [name]: value, edit: active },
        });
        if (maxLengthResult) {
            value = value.slice(0, MAX_LENGTH);
            setErrorMessage({
                ...errorMessage,
                [name]: maxLengthMessage,
            });
        } else if (name === "newsLink" && urlResult) {
            setErrorMessage({
                ...errorMessage,
                [name]: urlMessage,
            });
        } else if (spacebarResult) {
            setErrorMessage({
                ...errorMessage,
                [name]: spacebarMessage,
            });
        } else {
            setErrorMessage({
                ...errorMessage,
                [name]: "",
            });
        }
    };
    useEffect(() => {
        //에러메세지가 한 개라도 있으면 수정 버튼 비활성화
        if (
            errorMessage.newsLink ||
            errorMessage.mediaCompany ||
            errorMessage.newsTitle
        ) {
            setUpdateData({
                ...updateData,
                edit: false,
            });
        }
    }, [errorMessage]);
    const [allFiles, setAllFiles] = useState<any>([
        { data_url: updateData.newsImage },
    ]);
    useEffect(() => {
        updateData.newsImage === ""
            ? setAllFiles([])
            : setAllFiles([{ data_url: updateData.newsImage }]);
    }, [updateData.newsImage]);
    return (
        <div css={formDiv}>
            <ul>
                <li>
                    <span>뉴스 이미지</span>
                    <ImageListView
                        allFiles={allFiles}
                        handleChange={(data: string[]) => {
                            setUpdateData({
                                ...updateData,
                                ...{
                                    newsImage: data?.[0] ?? "",
                                    edit: true,
                                },
                            });
                        }}
                        isDisabled={false}
                        maxCount={1}
                    />
                </li>
                <li>
                    <span>뉴스 링크</span>
                    <Input
                        errorMessage={errorMessage.newsLink}
                        placeholder="URL 입력"
                        value={updateData.newsLink || ""}
                        size="medium"
                        name="newsLink"
                        handleChange={(e) => handleChange(e)}
                    />
                </li>
                <li>
                    <span>뉴스 제목</span>
                    <Input
                        placeholder="제목 입력"
                        value={updateData.newsTitle || ""}
                        size="medium"
                        name="newsTitle"
                        handleChange={(e) => handleChange(e)}
                        errorMessage={errorMessage.newsTitle}
                    />
                </li>
                <li>
                    <span>언론사</span>
                    <Input
                        placeholder="언론사 입력 (중앙일보 등)"
                        value={updateData.mediaCompany || ""}
                        size="default"
                        name="mediaCompany"
                        handleChange={(e) => handleChange(e)}
                        errorMessage={errorMessage.mediaCompany}
                    />
                </li>
                <li>
                    <span>뉴스 출처</span>
                    <Input
                        placeholder="뉴스 출처 입력 (네이버, 다음 등)"
                        value={updateData.portal || ""}
                        size="medium"
                        name="portal"
                        handleChange={(e) => handleChange(e)}
                        errorMessage={errorMessage.portal}
                    />
                </li>
                <li>
                    <span>뉴스 카테고리</span>
                    <DropDown
                        data={ddlNewsCategory}
                        handleChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            const { value } = e.target;
                            const active = diffChecker("keyword", value);
                            setUpdateData({
                                ...updateData,
                                ...{ keywordCode: Number(value), edit: active },
                            });
                        }}
                        defaultValue={(
                            updateData?.keywordCode || ""
                        ).toString()}
                    />
                </li>
                <li>
                    <span>뉴스 포털 등록일</span>
                    <ul css={calendarWrapperDiv}>
                        <li>
                            <Calendar
                                getUserDate={(date: number) => {
                                    setUpdateData({
                                        ...updateData,
                                        ...{ startDate: date, edit: true },
                                    });
                                    setErrorMessage({
                                        ...errorMessage,
                                        notInputType: "",
                                    });
                                }}
                                setDate={
                                    updateData.startDate
                                        ? updateData.startDate
                                        : +new Date()
                                }
                                isDisabled={false}
                                timePicker={false}
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <span>게시 만료일</span>
                    <ul css={calendarWrapperDiv}>
                        <li>
                            <Calendar
                                getUserDate={(date: number) => {
                                    setUpdateData({
                                        ...updateData,
                                        ...{ endDate: date, edit: true },
                                    });
                                    setErrorMessage({
                                        ...errorMessage,
                                        notInputType: "",
                                    });
                                }}
                                setDate={
                                    updateData.endDate
                                        ? updateData.endDate
                                        : +new Date()
                                }
                                isDisabled={false}
                                timePicker={false}
                            />
                            <span>까지</span>
                        </li>
                    </ul>
                </li>
                <li>
                    <span>노출 여부</span>
                    <ToggleButton
                        name="view"
                        value={updateData.view}
                        handleToggle={(name, checked) => {
                            setUpdateData({
                                ...updateData,
                                ...{ view: checked, edit: true },
                            });
                            setErrorMessage({
                                ...errorMessage,
                                notInputType: "",
                            });
                        }}
                    />
                </li>
            </ul>
        </div>
    );
}

const formDiv = css`
    padding-top: 40px;

    & > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        li {
            width: 100%;
            ${Common.textStyle.title6_R14}
            margin-bottom: 27px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            position: relative;
            p {
                position: absolute;
                left: 120px;
                top: 50px;
            }
        }
        span {
            display: inline-block;
            width: 120px;
            min-width: 120px;
        }
    }
`;

const calendarWrapperDiv = css`
    display: flex;
    flex-direction: row;
    li {
        margin-bottom: 0 !important;
    }
`;

export default NewsForm;
