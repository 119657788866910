import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { pages } from "@src/constants/Menu";

interface ITitle {
    title?: string;
    size?: "small" | "big";
    required?: boolean;
}

function Title({ title = "", size = "big", required = false }: ITitle) {
    const activeIndex = title
        ? title
        : JSON.parse(sessionStorage.getItem("activeIndex") || "");
    const currentIndex = activeIndex == null ? 1 : activeIndex;

    return (
        <div css={sizes[size]}>
            {required && <strong css={requiredStyle}>*</strong>}
            {title !== "" ? title : pages[currentIndex].title}
        </div>
    );
}

const sizes: { [index: string]: any } = {
    big: css`
        ${Common.textStyle.title2_M16}
    `,
    small: css`
        ${Common.textStyle.title5_M14}
    `,
};

const requiredStyle = css`
    padding-right: 5px;
    color: ${Common.colors.error};
`;
export default Title;
