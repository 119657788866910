import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { sampleData } from "@src/data/CaseInfoContainerSampleData";
import {
    BaseExpectedReturn,
    BaseSubExpectedReturn,
} from "@src/interfaces/product/ExpectedReturnContainer.interface";
import { expectedReturnTitles } from "@src/constants/ProductAuctionTitles";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.itemsExpectedReturn}/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData.data.result;
    return parse(originData);
};

const parse = (originData: BaseExpectedReturn | null): any => {
    let result;
    if (originData !== null) {
        let parsed: any[] = [];
        let categories = Object.keys(originData);

        categories.map((key: string, index) => {
            let flatten = { ...originData[key], ...originData[key].cost };
            delete flatten.cost;
            let category = Object.keys(flatten);

            if (index === 0) {
                category.map((item, index) => {
                    let i = item as keyof BaseSubExpectedReturn;
                    let c = item as keyof BaseSubExpectedReturn["cost"];
                    parsed.push({
                        id: index,
                        name: item,
                        title: expectedReturnTitles[item],
                        type: item !== "er_rate" ? "price" : "rate",
                        none: originData.none[i] ?? originData.none.cost?.[c],
                        one: originData.one[i] ?? originData.one.cost?.[c],
                        many: originData.many[i] ?? originData.many.cost?.[c],
                    });
                });
            }
        });
        result = parsed;
    } else {
        result = null;
    }
    return result;
};
const useGetExpectedReturn = (productNo: any) => {
    return useQuery(
        [queryKeys.EXPECTED_RETURN, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};
export { useGetExpectedReturn };
