import { AxiosResponse } from "axios";
import { ErrorCode } from "@src/constants/ErrorCode";

export function asyncCall(
    promise: AxiosResponse<any, any>,
    type: "Common" | "User" | "Template" | "Situation" | "News",
) {
    if (promise?.data) {
        const result = new Promise((resolve, reject) => {
            const { data, status } = promise.data;
            const errorCode = data.errCode;
            if (errorCode) {
                const message = ErrorCode[type][errorCode];
                reject(message || errorCode);
            } else {
                if (status === 200 || errorCode === 0) {
                    resolve(data);
                } else {
                    reject(data);
                }
            }
        });
        return result;
    }
    return promise;
}
