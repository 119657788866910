// @ts-nocheck
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import { Common } from "@src/styles/Common";

function ServerChart({ data, max = 0 }) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartRef = useRef<any>(null);
    let delayed;
    const config = {
        data: data,
        options: {
            scales: {
                x: {
                    type: "time",
                    time: {
                        displayFormats: { hour: "HH:mm" },
                        tooltipFormat: "YYY.MM.dd HH:mm",
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 24,
                    },
                },
                y: {
                    border: {
                        display: false,
                    },
                    grid: {
                        lineWidth: 2,
                        color: function (context: any) {
                            if (context.tick.value === 70) {
                                return "#FFB84C";
                            } else if (context.tick.value === 90) {
                                return "#EB455F";
                            }
                        },
                    },
                },
            },
            responsive: true,
            // animation: false,
            animation: {
                onComplete: () => {
                    delayed = true;
                },
                delay: (context) => {
                    let delay = 0;
                    if (
                        context.type === "data" &&
                        context.mode === "default" &&
                        !delayed
                    ) {
                        delay = context.dataIndex * 2 + context.datasetIndex;
                    }
                    return delay;
                },
            },
            aspectRatio: 2,
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        color: Common.colors.black,
                        usePointStyle: true,
                        boxWidth: 6,
                    },
                    position: "bottom",
                },
                tooltip: {
                    mode: "index",
                    titleColor: Common.colors.black,
                    bodyColor: Common.colors.black,
                    backgroundColor: Common.colors.white,
                    boxWidth: 8,
                    boxPadding: 3,
                    usePointStyle: true,
                    borderColor: Common.colors.black,
                    borderWidth: 1,
                    padding: 10,
                },
            },
        },
    };

    if (max) config.options.scales.y.max = max;

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        if (canvasRef.current) {
            chartRef.current = new Chart(
                canvasRef.current.getContext("2d"),
                config,
            );
        }
    }, [data]);

    return <canvas ref={canvasRef}></canvas>;
}

export default ServerChart;
