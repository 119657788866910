import React, { useState, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, DropDown, Input, RangeCalendar } from "@src/components";
import { arrayToDropdown } from "@src/utils/dropdownUtil";
import {
    DISPOSE_TYPE,
    LIST_BY_ORDER,
    PRODUCT_STATUS,
    PRODUCT_TYPE,
    PROPERTY_TYPE,
    VALUE_MAX_PRICE_NEW,
    VALUE_MIN_PRICE_NEW,
    ALL,
} from "@src/constants/ProductCatalog";
import { ProductFilterType } from "@src/types/product";
import FilterLocation from "@src/pages/product/productFilter/FilterLocation";
import FilterUsage from "@src/pages/product/productFilter/FilterUsage";
import { dateParse } from "@src/utils/dateUtil";

interface IProductFilter {
    handleSubmit: (value: any) => void;
}

function ProductFilter({ handleSubmit }: IProductFilter) {
    const [filters, setFilters] = useState<ProductFilterType>();
    const [locations, setLocations] = useState<string[]>([]);
    const [buildings, setBuildings] = useState<string[]>([]);
    const showDispose = filters?.productType !== PRODUCT_TYPE.AUCTION;

    const defaultDate: [startDate: number | null, endDate: number | null] = [
        null,
        null,
    ];

    const [clear, setClear] = useState(false);
    const handleClear = () => {
        setFilters({});
        setClear(true);
    };

    const updateField = (formData: ProductFilterType) => {
        const key = Object.keys(formData)[0];
        const value = Object.values(formData)[0];

        if (value === ALL) {
            setFilters((current: any) => {
                const copy = { ...current };
                delete copy[key];
                return copy;
            });
        } else {
            setFilters((current) => ({ ...current, ...formData }));
        }
    };

    const PLACEHOLDER_MAX = "최대 금액";
    const PLACEHOLDER_MIN = "최소 금액";
    const [isErrorValuePrice, setIsErrorValuePrice] = useState(false);
    const [isErrorBidPrice, setIsErrorBidPrice] = useState(false);
    const [isErrorRangeCalendar, setIsErrorRangeCalendar] = useState(false);
    interface ChangeValueProps {
        fieldName: string;
        minValue: number;
        maxValue: number;
        currentValue: number;
    }
    const changeValuePrice = ({
        fieldName,
        minValue,
        maxValue,
        currentValue,
    }: ChangeValueProps) => {
        if (minValue > maxValue) {
            setIsErrorValuePrice(true);
        } else {
            setIsErrorValuePrice(false);
        }
        updateField({
            [fieldName]: currentValue,
        });
    };
    const changeBidPrice = ({
        fieldName,
        minValue,
        maxValue,
        currentValue,
    }: ChangeValueProps) => {
        if (minValue > maxValue) {
            setIsErrorBidPrice(true);
        } else {
            setIsErrorBidPrice(false);
        }
        updateField({
            [fieldName]: currentValue,
        });
    };
    return (
        <div css={ProductSearchDiv}>
            <div css={ContainerDiv}>
                <ul>
                    <li>타입</li>
                    <li css={WideDropDown}>
                        <DropDown
                            data={arrayToDropdown([
                                PRODUCT_TYPE.ALL,
                                PRODUCT_TYPE.AUCTION,
                                PRODUCT_TYPE.PUBLIC_AUCTION,
                            ])}
                            handleChange={(e) => {
                                const result = e.target.value;
                                updateField({
                                    productType:
                                        result === undefined ? ALL : result,
                                });
                            }}
                            defaultValue={filters?.productType || ALL}
                        />
                    </li>
                    <li>매물번호</li>
                    <li>
                        <Input
                            placeholder="매물번호를 입력하세요."
                            size="dynamic"
                            handleChange={(
                                e: ChangeEvent<HTMLInputElement>,
                            ) => {
                                updateField({
                                    productId: e.target.value,
                                });
                            }}
                            value={filters?.productId || ""}
                        />
                    </li>
                </ul>
                <ul>
                    <li>물건상태</li>
                    <li css={WideDropDown}>
                        <DropDown
                            data={arrayToDropdown(PRODUCT_STATUS)}
                            handleChange={(e) => {
                                updateField({
                                    productStatus: e.target.value,
                                });
                            }}
                            defaultValue={filters?.productStatus || ALL}
                        />
                    </li>
                    <li>매각기일</li>
                    <li
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <RangeCalendar
                            setRangeDate={{
                                startDate: defaultDate[0],
                                endDate: defaultDate[1],
                            }}
                            getUserRangeDate={(
                                { startDate: START_DATE, endDate: END_DATE },
                                isError,
                            ) => {
                                setIsErrorRangeCalendar(isError || false);
                                updateField({
                                    startDate: START_DATE ?? undefined,
                                    endDate: END_DATE ?? undefined,
                                });
                            }}
                            clear={clear}
                        />
                    </li>
                </ul>
                <ul>
                    <li>감정가</li>
                    <li
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                lineHeight: "60px",
                                height: "60px",
                            }}
                        >
                            <DropDown
                                placeholder={PLACEHOLDER_MIN}
                                data={VALUE_MIN_PRICE_NEW}
                                handleChange={(e) => {
                                    const currentValue = e.target.value;
                                    if (currentValue !== PLACEHOLDER_MIN) {
                                        changeValuePrice({
                                            fieldName: "valueMinPrice",
                                            minValue: Number(currentValue),
                                            maxValue: Number(
                                                filters?.valueMaxPrice || "0",
                                            ),
                                            currentValue: Number(currentValue),
                                        });
                                    } else {
                                        updateField({
                                            valueMinPrice: ALL,
                                        });
                                        setIsErrorValuePrice(false);
                                    }
                                }}
                                defaultValue={
                                    filters?.valueMinPrice || undefined
                                }
                            />
                            <span style={{ padding: "10px" }}>~</span>
                            <DropDown
                                placeholder={PLACEHOLDER_MAX}
                                data={VALUE_MAX_PRICE_NEW}
                                handleChange={(e) => {
                                    const currentValue = e.target.value;
                                    if (currentValue !== PLACEHOLDER_MAX) {
                                        changeValuePrice({
                                            fieldName: "valueMaxPrice",
                                            minValue: Number(
                                                filters?.valueMinPrice || "0",
                                            ),
                                            maxValue: Number(currentValue),
                                            currentValue: Number(currentValue),
                                        });
                                    } else {
                                        updateField({
                                            valueMaxPrice: ALL,
                                        });
                                        setIsErrorValuePrice(false);
                                    }
                                }}
                                defaultValue={
                                    filters?.valueMaxPrice || undefined
                                }
                            />
                        </div>
                        {isErrorValuePrice && (
                            <div
                                style={{
                                    color: Common.colors.error,
                                    fontSize: "12px",
                                    lineHeight: "0",
                                }}
                            >
                                <p>
                                    감정가 최대금액은 최소금액보다 작을 수
                                    없습니다.
                                </p>
                            </div>
                        )}
                    </li>
                    <li>최저입찰가</li>
                    <li
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                lineHeight: "60px",
                                height: "60px",
                            }}
                        >
                            <DropDown
                                placeholder={PLACEHOLDER_MIN}
                                data={VALUE_MIN_PRICE_NEW}
                                handleChange={(e) => {
                                    const currentValue = e.target.value;
                                    if (currentValue !== PLACEHOLDER_MAX) {
                                        changeBidPrice({
                                            fieldName: "bidMinPrice",
                                            minValue: Number(currentValue),
                                            maxValue: Number(
                                                filters?.bidMaxPrice || "0",
                                            ),
                                            currentValue: Number(currentValue),
                                        });
                                    } else {
                                        updateField({
                                            bidMinPrice: ALL,
                                        });
                                        setIsErrorBidPrice(false);
                                    }
                                }}
                                defaultValue={filters?.bidMinPrice || undefined}
                            />
                            <span style={{ padding: "10px" }}>~</span>
                            <DropDown
                                placeholder={PLACEHOLDER_MAX}
                                data={VALUE_MAX_PRICE_NEW}
                                handleChange={(e) => {
                                    const currentValue = e.target.value;
                                    if (currentValue !== PLACEHOLDER_MAX) {
                                        changeBidPrice({
                                            fieldName: "bidMaxPrice",
                                            minValue: Number(
                                                filters?.bidMinPrice || "0",
                                            ),
                                            maxValue: Number(currentValue),
                                            currentValue: Number(currentValue),
                                        });
                                    } else {
                                        updateField({
                                            bidMaxPrice: ALL,
                                        });
                                        setIsErrorBidPrice(false);
                                    }
                                }}
                                defaultValue={filters?.bidMaxPrice || undefined}
                            />
                        </div>
                        {isErrorBidPrice && (
                            <div
                                style={{
                                    color: Common.colors.error,
                                    fontSize: "12px",
                                    lineHeight: "0",
                                }}
                            >
                                <p>
                                    최저입찰가 최대금액은 최소금액보다 작을 수
                                    없습니다.
                                </p>
                            </div>
                        )}
                    </li>
                </ul>
                <FilterLocation
                    clear={clear}
                    setClear={setClear}
                    setPropsLocations={setLocations}
                />

                <FilterUsage
                    clear={clear}
                    setClear={setClear}
                    setPropsBuildings={setBuildings}
                />
                {showDispose && (
                    <ul>
                        <li>처분방식(공)</li>
                        <li css={WideDropDown}>
                            <DropDown
                                data={arrayToDropdown(DISPOSE_TYPE)}
                                handleChange={(e) => {
                                    updateField({
                                        disposeType: e.target.value,
                                    });
                                }}
                                defaultValue={filters?.disposeType || ALL}
                            />
                        </li>
                        <li>자산구분(공)</li>
                        <li css={WideDropDown}>
                            <DropDown
                                data={arrayToDropdown(PROPERTY_TYPE)}
                                handleChange={(e) => {
                                    updateField({
                                        propertyType: e.target.value,
                                    });
                                }}
                                defaultValue={filters?.propertyType || ALL}
                            />
                        </li>
                    </ul>
                )}
                <ul>
                    <li>정렬방식</li>
                    <li css={WideDropDown}>
                        <DropDown
                            data={arrayToDropdown(LIST_BY_ORDER)}
                            handleChange={(e) => {
                                updateField({
                                    listByOrder: e.target.value,
                                });
                            }}
                            defaultValue={filters?.listByOrder || undefined}
                        />
                    </li>
                </ul>
            </div>
            <div>
                <Button
                    color="gray"
                    handleClick={handleClear}
                    label="전체 해제"
                    size="medium"
                />
                <Button
                    color="purple"
                    handleClick={() => {
                        const params = {
                            ...filters,
                            ...{ locations, buildings },
                        };
                        if (
                            !isErrorValuePrice &&
                            !isErrorBidPrice &&
                            !isErrorRangeCalendar
                        ) {
                            handleSubmit(params);
                        }
                    }}
                    label="검색"
                    size="medium"
                />
            </div>
        </div>
    );
}

ProductFilter.propTypes = {};

const ProductSearchDiv = css`
    display: flex;
    flex-direction: column;

    height: fit-content;
    min-height: fit-content;

    margin-top: 30px;
    & > div:nth-of-type(2) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
        padding-top: 10px;
    }
`;

const ContainerDiv = css`
    display: flex;
    flex-direction: column;
    height: fit-content;
    background-color: ${Common.colors.gray100};
    padding: 30px;
    border-radius: 10px;
    ${Common.textStyle.title6_R14}
    & > ul {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;

        & > li:nth-of-type(odd) {
            width: 120px;
        }
        & > li:nth-of-type(even) {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
        }
        & > li > div:nth-of-type(1) {
            .react-datepicker__month > * {
                line-height: normal !important;
            }
        }
        & > li > div {
            select {
                background-color: transparent;
                border-radius: 0;
                border-bottom: 1px solid ${Common.colors.gray200};
                border-color: transparent;
                border-bottom-color: ${Common.colors.gray200};
                width: 150px;
            }
            & > div > select {
                background-color: transparent;
                border-radius: 0;
                border-bottom: 1px solid ${Common.colors.gray200};
                border-color: transparent;
                border-bottom-color: ${Common.colors.gray200};
                width: 150px;
            }
        }
        & > li > input {
            background: transparent;
            width: 400px;
            border-bottom-color: ${Common.colors.gray200};
        }
        & > li > input::-webkit-outer-spin-button,
        & > li > input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        & > li > span {
            color: ${Common.colors.gray500};
            margin-left: 10px;
        }
        & > li > div > span {
            color: ${Common.colors.gray500};
            margin-left: 10px;
            line-height: 40px;
        }
    }
`;

const WideDropDown = css`
    & > div {
        & > select {
            width: 220px;
        }
    }
`;

export default ProductFilter;
