import { createSlice } from "@reduxjs/toolkit";
import { BidHistoryStateType } from "@src/types";

const bidHistoryData: Array<BidHistoryStateType> = [
    {
        id: "039",
        text: "050/001",
        limit: "일시불(30일)",
        bidStartDate: "2022.01.03 10:00:00",
        bidEndDate: "2022.01.05 17:00:00",
        openDate: "2021.12.30",
        saleDate: "2022.01.03 10:00:00",
        minPrice: "380250000",
    },
    {
        id: "040",
        text: "050/001",
        limit: "일시불(30일)",
        bidStartDate: "2022.01.03 10:00:00",
        bidEndDate: "2022.01.05 17:00:00",
        openDate: "2021.12.30",
        saleDate: "2022.01.10 10:00:00",
        minPrice: "350250000",
    },
    {
        id: "041",
        text: "050/001",
        limit: "일시불(30일)",
        bidStartDate: "2022.01.03 10:00:00",
        bidEndDate: "2022.01.05 17:00:00",
        openDate: "2021.12.30",
        saleDate: "2022.01.17 10:00:00",
        minPrice: "280000000",
    },
    {
        id: "042",
        text: "050/001",
        limit: "일시불(30일)",
        bidStartDate: "2022.01.03 10:00:00",
        bidEndDate: "2022.01.05 17:00:00",
        openDate: "2021.12.30",
        saleDate: "2022.01.03 10:00:00",
        minPrice: "220000000",
    },
    {
        id: "043",
        text: "050/001",
        limit: "일시불(30일)",
        bidStartDate: "2022.01.03 10:00:00",
        bidEndDate: "2022.01.05 17:00:00",
        openDate: "2021.12.30",
        saleDate: "2022.01.03 10:00:00",
        minPrice: "350000000",
    },
];

const initialState = bidHistoryData;

export const bidHistorySlice = createSlice({
    name: "bidHistory",
    initialState,
    reducers: {
        createBidHistory: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateBidHistory: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createBidHistory, updateBidHistory } = bidHistorySlice.actions;

export default bidHistorySlice.reducer;
