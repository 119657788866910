import styled from "@emotion/styled";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { EditModal, ViewMoreTable } from "@src/components";
import { useDeungiTicketList } from "@src/hooks/queries/useUserDetail";
import { DeungiDetailList } from "@src/interfaces/User.interface";
import { AnyARecord } from "dns";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getUserProductListColumns } from "./UserProductListColumns";

interface UserProductInfoModalProps {
    isModalShow: boolean;
    setIsProductModalShow: Dispatch<SetStateAction<boolean>>;
    deungiTicketCnt: number;
    userNo: number;
    setIsDeungiEditModalShow: Dispatch<SetStateAction<boolean>>;
    setDeungiTicket: Dispatch<SetStateAction<DeungiDetailList>>;
    deungiRefetch: boolean;
    setIsDeungiRefundModalShow: Dispatch<SetStateAction<boolean>>;
    setDeungiRefundPrice: Dispatch<SetStateAction<string>>;
    setReason: Dispatch<SetStateAction<string>>;
}

const UserProductInfoModal = ({
    isModalShow,
    setIsProductModalShow,
    deungiTicketCnt,
    userNo,
    setIsDeungiEditModalShow,
    setDeungiTicket,
    deungiRefetch,
    setIsDeungiRefundModalShow,
    setDeungiRefundPrice,
    setReason,
}: UserProductInfoModalProps) => {
    const [deungiInfo, setDeungiInfo] = useState<any[]>([]);
    const [params, setParams] = useState<any>({
        userNo: userNo,
        limit: 9999999999,
        page: 1,
        type: 0,
    });
    const { isLoading, data, isError, refetch } = useDeungiTicketList(params);
    useEffect(() => {
        refetch();
    }, [deungiRefetch]);
    let columns = getUserProductListColumns(
        setIsDeungiEditModalShow,
        setIsProductModalShow,
        setDeungiTicket,
        setIsDeungiRefundModalShow,
        setDeungiRefundPrice,
        setReason,
        refetch,
    );
    useEffect(() => {
        if (!isLoading && !isError && data && userNo) {
            setDeungiInfo(data.result);
            columns = getUserProductListColumns(
                setIsDeungiEditModalShow,
                setIsProductModalShow,
                setDeungiTicket,
                setIsDeungiRefundModalShow,
                setDeungiRefundPrice,
                setReason,
                refetch,
            );
        }
    }, [isLoading, isError, data, userNo]);

    return (
        <StyledModalWrap>
            <EditModal
                isModalShow={isModalShow}
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={() => {
                    setIsProductModalShow(false);
                }}
                size="big"
                title="실시간 등기열람권 정보"
                deleteButton={true}
            >
                <StyledContents>
                    <div className="ticketCnt">
                        총 잔여<span>{deungiTicketCnt}장</span>
                    </div>
                    <div className="subTitle">충전내역</div>

                    <ViewMoreTable
                        FixedHeight={300}
                        columns={columns}
                        data={deungiInfo}
                        handleViewMore={function noRefCheck() {}}
                        isRefetching={true}
                    />
                </StyledContents>
            </EditModal>
        </StyledModalWrap>
    );
};

export default UserProductInfoModal;

const StyledModalWrap = styled.div`
    p {
        margin-bottom: 8px;
    }
    & > div {
        & > .modal {
            width: 1300px;
        }
    }
`;

const StyledContents = styled.div`
    & > .ticketCnt {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        & > span {
            margin-left: 8px;
            font-size: 14px;
            font-weight: 400;
            color: #9a49f2;
        }
        margin-bottom: 24px;
    }
    & > .subTitle {
        font-weight: 700;
        font-size: 14px;
        color: #222222;
        margin-bottom: 9px;
    }
    & > div {
        & > button {
            display: none;
        }
    }
    /* & > .viewTable {
        & > div {
            & > div {
                width: 1300px;
            }
        }
    } */
`;
