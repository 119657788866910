import React, { ChangeEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";
import "@src/styles/CommonTable.css";
import { Calendar, Input } from "@src/components";
import { useSelector } from "react-redux";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { RentInfoPublicType } from "@src/types";
import { ConvertDate } from "@src/utils/dateUtil";
import { dateParse } from "@src/utils/dateUtil";

interface IRentInfoPublic {
    isUpdate: boolean;
    handleUpdate?: Function;
}
function RentInfoPublicContainer({
    isUpdate = false,
    handleUpdate,
}: IRentInfoPublic) {
    const headerMeta = [
        "임대차내용",
        "성명",
        "보증금(원)",
        "차임(월세)(원)",
        "환산보증금(원)",
        "확정일",
        "전입일",
    ];
    const defaultData = useSelector((state: any) => state.rentInfoPublic);
    const [tableData, setTableData] =
        useState<RentInfoPublicType[]>(defaultData);
    useEffect(() => {
        setTableData(defaultData);
    }, [defaultData]);

    const handleChange = (updateData: RentInfoPublicType, index: number) => {
        let newTableData = [...tableData];
        newTableData[index] = updateData;
        setTableData([...newTableData]);
    };

    useEffect(() => {
        if (handleUpdate !== undefined) {
            handleUpdate(tableData);
        }
    }, [tableData]);
    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: any) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isUpdate={isUpdate}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

const TableRow = ({ data, isUpdate, handleChange }: any) => {
    const [rowData, setRowData] = useState<RentInfoPublicType>(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const {
        id,
        rentType,
        name,
        deposit,
        rentPrice,
        conversionDeposit,
        fixedDate,
        moveInDate,
    } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRowData({
            ...rowData,
            [name]: value,
        });
    };

    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);
    const onChangeDate = (name: string, value: number) => {
        setRowData((prev) => ({
            ...prev,
            [name]: ConvertDate(value, "yyyy.MM.dd"),
        }));
    };
    return (
        <tr key={id} style={isUpdate ? { borderBottom: "none" } : undefined}>
            <td>
                <Input
                    name="rentType"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rentType : ConvertEmptyValue(rentType)}
                />
            </td>
            <td>
                <Input
                    name="name"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? name : ConvertEmptyValue(name)}
                />
            </td>
            <td>
                <Input
                    name="deposit"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? deposit : resultPrice(deposit)}
                />
            </td>
            <td>
                <Input
                    name="rentPrice"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? rentPrice : resultPrice(rentPrice)}
                />
            </td>
            <td>
                <Input
                    name="conversionDeposit"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? conversionDeposit
                            : resultPrice(conversionDeposit)
                    }
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(moveInDate)}
                    getUserDate={(value) => onChangeDate("moveInDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
            <td css={calendarItem}>
                <Calendar
                    setDate={dateParse(fixedDate)}
                    getUserDate={(value) => onChangeDate("fixedDate", value)}
                    isDisabled={!isUpdate}
                />
            </td>
        </tr>
    );
};

RentInfoPublicContainer.propTypes = {
    isUpdate: PropTypes.bool.isRequired,
    handleUpdate: PropTypes.func,
};
const calendarItem = css`
    & input {
        height: 47px;
    }
`;
export default RentInfoPublicContainer;
