import { Constants } from "./Constants";
const {
    REGISTER,
    TEST_PREV,
    TEST_ING,
    TEST_FAIL,
    TEST_SUCCESS,
    PUSH_APPLY,
    PUSH_ING,
    PUSH_FAIL,
    PUSH_SUCCESS,
} = Constants;

export enum PushCodeType {
    Register = 100,
    TestReady = 200,
    Testing = 201,
    TestFailed = 202,
    TestSuccess = 300,
    PushReady = 400,
    Pushing = 401,
    PushFailed = 402,
    PushSuccess = 500,
}
interface PushStatusType {
    code: number;
    value: string;
    color: string;
}

export const PushCodeAndColors: PushStatusType[] = [
    {
        code: PushCodeType.Register,
        value: REGISTER,
        color: "purpleBorder",
    },
    {
        code: PushCodeType.TestReady,
        value: TEST_PREV,
        color: "pink",
    },
    {
        code: PushCodeType.Testing,
        value: TEST_ING,
        color: "pink",
    },
    {
        code: PushCodeType.TestFailed,
        value: TEST_FAIL,
        color: "pink",
    },
    {
        code: PushCodeType.TestSuccess,
        value: TEST_SUCCESS,
        color: "pink",
    },
    {
        code: PushCodeType.PushReady,
        value: PUSH_APPLY,
        color: "purple200",
    },
    {
        code: PushCodeType.Pushing,
        value: PUSH_ING,
        color: "purple300",
    },
    {
        code: PushCodeType.PushFailed,
        value: PUSH_FAIL,
        color: "orange",
    },
    {
        code: PushCodeType.PushSuccess,
        value: PUSH_SUCCESS,
        color: "purple",
    },
];

export const PushCategoryType: { [key: string]: string } = {
    "0": "서비스",
    "10": "관심매물",
    "100": "매물추천",
    "1000": "혜택이벤트",
};
export const getPushCategoryType = (categoryCode: string) => {
    return PushCategoryType[categoryCode] || PushCategoryType[0];
};

export const PushToType: { [key: string]: string } = {
    user_no: "개별전송",
    topic: "개별전송",
    group: "상황전송",
};
export const getPushToType = (categoryCode: string) => {
    return PushToType[categoryCode] || PushToType[0];
};
