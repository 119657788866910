import React, { ChangeEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Calendar, Input, TextBox } from "@src/components";
import { useSelector } from "react-redux";
import { ConvertDate } from "@src/utils/dateUtil";
import { PublicDetailStateType } from "@src/types";
import { ConvertEmptyValue } from "@src/utils/textUtil";
import { dateParse } from "@src/utils/dateUtil";
interface IPublicInformationContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
    handleSubmitDisabled?: Function;
}

function PublicInformationContainer({
    isUpdate,
    handleUpdate,
    handleSubmitDisabled,
}: IPublicInformationContainer) {
    const defaultInfo = useSelector((state: any) => state.publicInformation);
    const [detailInfo, setDetailInfo] =
        useState<PublicDetailStateType>(defaultInfo);
    const getUserDate = (name: string, data: any) => {
        setDetailInfo((prev: any) => ({
            ...prev,
            [name]: new Date(data).toString(),
        }));
    };

    useEffect(() => {
        setDetailInfo({
            propertyType: defaultInfo.propertyType,
            agency: defaultInfo.agency,
            disposeWay: defaultInfo.disposeWay,
            executiveAgency: defaultInfo.executiveAgency,
            bidWay: defaultInfo.bidWay,
            assignee: defaultInfo.assignee,
            initDate: defaultInfo.initDate,
            duty: defaultInfo.duty,
            requestDate: defaultInfo.requestDate,
            conditional: defaultInfo.conditional,
        });
    }, [defaultInfo]);

    const {
        propertyType,
        agency,
        disposeWay,
        executiveAgency,
        bidWay,
        assignee,
        initDate,
        duty,
        requestDate,
        conditional,
    } = detailInfo;

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDetailInfo((prev: any) => ({
            ...prev,
            [name]: value,
        }));
    };
    useEffect(() => {
        let copyDetailInfo = { ...detailInfo };
        copyDetailInfo["initDate"] = ConvertDate(dateParse(initDate));
        copyDetailInfo["requestDate"] = ConvertDate(dateParse(requestDate));
        handleUpdate && handleUpdate(copyDetailInfo);
    }, [detailInfo]);

    return (
        <ul css={detailInfoUl}>
            <li>
                <Input
                    value={ConvertEmptyValue(propertyType)}
                    name="propertyType"
                    label="자산 구분"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={ConvertEmptyValue(agency)}
                    name="agency"
                    label="위임 기관"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={ConvertEmptyValue(disposeWay)}
                    name="disposeWay"
                    label="처분 방식"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={executiveAgency || ""}
                    name="executiveAgency"
                    label="진행 기관"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={bidWay || ""}
                    name="bidWay"
                    label="입찰 방식"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li>
                <Input
                    value={assignee || ""}
                    name="assignee"
                    label="담당자"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li className="openDateItem">
                <span>최초 공고 일자</span>
                <Calendar
                    setDate={
                        initDate !== undefined
                            ? dateParse(initDate)
                            : dateParse(defaultInfo.initDate)
                    }
                    getUserDate={(value) => getUserDate("initDate", value)}
                    isDisabled={!isUpdate}
                />
            </li>
            <li>
                <Input
                    value={duty || ""}
                    name="duty"
                    label="명도 책임"
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    handleChange={onChange}
                />
            </li>
            <li className="openDateItem">
                <span>배분요구종기</span>
                <Calendar
                    setDate={
                        requestDate !== undefined
                            ? dateParse(requestDate)
                            : dateParse(defaultInfo.requestDate)
                    }
                    getUserDate={(value) => getUserDate("requestDate", value)}
                    isDisabled={!isUpdate}
                />
            </li>
            <li></li>
            <div css={ConditionDiv}>
                <span className="subTitle">부대 조건</span>
                <TextBox
                    defaultText={conditional || ""}
                    label=""
                    placeholder=""
                    size="dynamic"
                    isDisabled={!isUpdate}
                    maxLength={10000}
                    handleChange={(value, isError) => {
                        setDetailInfo((prev: any) => ({
                            ...prev,
                            conditional: value,
                        }));
                        handleSubmitDisabled && handleSubmitDisabled(isError);
                    }}
                />
            </div>
        </ul>
    );
}

PublicInformationContainer.propTypes = {
    isUpdate: PropTypes.bool.isRequired,
    handleUpdate: PropTypes.func,
};
const detailInfoUl = css`
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    input {
        max-width: 300px;
    }
    input:disabled {
        background-color: transparent;
        border-bottom: none;
        color: ${Common.colors.black};
    }
    ${Common.textStyle.text1_R13}
    span {
        display: inline-block;
        width: 100px;
        color: ${Common.colors.gray500};
    }
    li {
        width: 50%;
        display: flex;
        align-items: center;
    }
    .openDateItem {
        & > div {
            max-width: 300px;
        }
        input {
            border-bottom-width: 1.5px;
            width: 300px;
        }
        input:read-only {
            cursor: default;
        }
    }
`;

const ConditionDiv = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px 0;

    .subTitle {
        width: 110px;
        ${Common.textStyle.text1_R13}
        padding-top: 12px;
        display: inline-block;
    }
`;

export default PublicInformationContainer;
