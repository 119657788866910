import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const productViewCategorySlice = createSlice({
    name: "productViewCategory",
    initialState,
    reducers: {
        createProductViewCategory: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateProductViewCategory: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createProductViewCategory, updateProductViewCategory } =
    productViewCategorySlice.actions;

export default productViewCategorySlice.reducer;
