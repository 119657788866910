import { validEmptyValueCheck } from "@src/utils/validationCheck";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertPrice } from "./units";

export const ConvertEmptyValue = (value: string | null | undefined): string => {
    let result = "";
    if (validEmptyValueCheck(value)) {
        result = INVALID_VALUE;
    } else {
        value && (result = value);
    }
    return result;
};

export const isNotNULL = (value: string | null) => {
    return value !== null && value !== "";
};

export const isNotEmpty = (value: string | null) => {
    return value !== "";
};

export const isEmpty = (value: string) => {
    return value.length === 0;
};

export const resultPrice = (price?: string | null) => {
    let result = ConvertEmptyValue(price);
    if (result !== INVALID_VALUE) {
        return isNaN(+result) ? result : ConvertPrice(+result);
    }
    return result;
};

export const ConvertHtmlToString = (value: string) => {
    let result = value.replace(/<[^>]+>/g, "").replaceAll("&nbsp;", " ");
    return result;
};

export const deleteCommaPrice = (value: string) => {
    let result = value.replace(/,/g, "");
    return result;
};

export const isEditorEmpty = (value: any) => {
    //에디터는 빈값일때
    //<p><img class=\"ProseMirror-separator\" alt=\"\"><br class=\"ProseMirror-trailingBreak\"></p>
    //태그가 들어가서 텍스트로 변환 후 빈값체크
    return ConvertHtmlToString(value) !== "";
};
