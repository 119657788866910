import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import {
    BaseUser,
    ListUserProps,
    FilterUserProps,
    ReadUserListDto,
} from "@src/interfaces/User.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import { queryList } from "./queryList";
import { CommodityFilterType } from "@src/pages/commodity/CommodityFilter";
import {
    BaseCommodity,
    BasePayment,
    BaseProvisionUser,
    CommodityList,
    CreateProvisionParams,
    PaymentList,
    ProvisionUser,
    ReadCommodityListDto,
    ReadPaymentListDto,
} from "@src/interfaces/Commodity.interface";
import { CommodityDataType } from "@src/pages/commodity/CommodityCreateContents";
import { ProductDataType } from "@src/pages/commodity/provision/ProvisionUpdate";

const useGetProvisionUserList = async (no: number) => {
    const url = `${axiosPath.nestFreeIssue}/${no}/users`;
    const response = await axiosInstance.get(url);
    const data: BaseProvisionUser[] = response.data;
    const newData: ProvisionUser[] = data.map((item) => {
        return {
            no: item.no,
            status: item.status,
            toGo: item.toGo,
            userNo: item.userNo,
            uname: item.uname,
            comments: item.comments,
            delete: item.no,
            issueNo: no,
        };
    });

    return newData;
};

const usePushProvisionUser = async (issueNo: number, toGo: string[]) => {
    const url = `${axiosPath.nestFreeIssue}/${issueNo}/users`;
    const response = await axiosInstance.post(url, { toGoes: toGo });

    return response;
};

const useProvisionSendPush = async (issueNo: number, willSendPush: boolean) => {
    const url = `${axiosPath.nestFreeIssueV1}/${issueNo}/issue`;
    const response = await axiosInstance.post(url, {
        willSendPush: willSendPush,
    });
    return response;
};

const useGiveFreeForUser = async (issueNo: number) => {
    // let toGoList: string[] = [];
    // waitingForPayment.forEach((item) => {
    //     toGoList.push(item.toGo);
    // });
    // const toGo = toGoList.join();

    const url = `${axiosPath.nestFreeIssue}/${issueNo}/issue`;
    const response = await axiosInstance.post(url);

    return response;
};
const useDeleteProvisionUser = async (no: number, issueNo: number) => {
    const url = `${axiosPath.nestFreeIssue}/${issueNo}/users/${no}`;
    const response = await axiosInstance.delete(url);

    return response;
};

export {
    useGetProvisionUserList,
    usePushProvisionUser,
    useGiveFreeForUser,
    useDeleteProvisionUser,
    useProvisionSendPush,
};
