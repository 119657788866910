import React, { useEffect, useState } from "react";
import { IExistingDeunggi } from "./types";
import { Calendar, ConfirmModal, EditModal, Title } from "@src/components";
import { css } from "@emotion/react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    selectedDeunggi?: IExistingDeunggi | null;
    onSubmitDeunggiEdit: (expired: string) => void;
}

const columns = ["충전일시", "충전정보", "사용 수량", "유효 기간"];

const UserExistingDeunggiUpdateModal: React.FC<Props> = ({
    open,
    onCloseView,
    selectedDeunggi,
    onSubmitDeunggiEdit,
}) => {
    const [editDate, setEditDate] = useState<any>(selectedDeunggi?.expiredAt);
    const [openCheckModal, setOpenCheckModal] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            const newDate = new Date(selectedDeunggi?.expiredAt ?? 0).getTime();
            setEditDate(newDate);
        }
        return () => {
            setOpenCheckModal(false);
        };
    }, [open]);

    return (
        <>
            <EditModal
                isModalShow={open}
                buttonText="저장"
                handleBtnClick={() => setOpenCheckModal(true)}
                handleOpenModal={() => onCloseView()}
                size="big"
                checkCloseModal={true}
                title="기존 등기열람권 수정"
            >
                <>
                    <Title title="충전내역" />
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedDeunggi?.startedAt}</td>
                                    <td>{selectedDeunggi?.productName}</td>
                                    <td>{selectedDeunggi?.usedQuantity}</td>
                                    <td>
                                        <Calendar
                                            getUserDate={(date) => {
                                                setEditDate(
                                                    new Date(date)
                                                        .toISOString()
                                                        .slice(0, 10),
                                                );
                                            }}
                                            setDate={editDate}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            </EditModal>
            <ConfirmModal
                isModalShow={openCheckModal}
                buttonText="확인"
                handleCancel={() => setOpenCheckModal(false)}
                handleOk={() => onSubmitDeunggiEdit(editDate)}
            >
                수정하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default UserExistingDeunggiUpdateModal;

const tableRootStyle = css`
    width: 100%;
    height: 270px;
    table {
        width: 100%;
        vertical-align: middle;
        thead {
            th {
                padding: 10px 5px;
                white-space: nowrap;
                font-size: 14px;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        tr {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            font-size: 13px;
            vertical-align: middle;
            padding: 10px 5px;
            text-align: center;
            /* white-space: nowrap;
            word-break: keep-all; */
            text-overflow: ellipsis;
        }
    }
`;
