import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const selectUserSlice = createSlice({
    name: "selectUser",
    initialState,
    reducers: {
        setSelectUser: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setSelectUser } = selectUserSlice.actions;

export default selectUserSlice.reducer;
