import { css } from "@emotion/react";
import {
    ConfirmModal,
    DropDown,
    EditModal,
    IconButton,
    Input,
    TextBox,
} from "@src/components";
import { useCampusAdminReviewSaver } from "@src/hooks/queries/useCampus";
import { IReview } from "@src/interfaces/Campus.interface";
import React, { useEffect, useState } from "react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    id?: string;
    adminReviews?: IReview[];
}

const SCORE_LIST = Array(5)
    .fill(0)
    .map((_v, i) => ({ key: i + 1, value: `${i + 1}점` }))
    .reverse();

const initialReview: IReview = {
    showReview: true,
    order: "1",
    name: "",
    score: 5,
    contents: "",
};

const LectureScoreManagementModal: React.FC<Props> = ({
    open,
    onCloseView,
    id,
    adminReviews,
}) => {
    const [isShowConfirmModal, setIsShowConfirmModal] =
        useState<boolean>(false);
    const [reviews, setReviews] = useState<IReview[]>([initialReview]);

    const onChangeReviews = (type: string, index: number, val: any) => {
        const newReviews = [...reviews].map((review: any, idx) => ({
            ...review,
            [type]: idx === index ? val : review[type],
        }));
        setReviews(newReviews);
    };

    const reviewSaver = useCampusAdminReviewSaver(
        () => {
            setIsShowConfirmModal(false);
            onCloseView();
        },
        () => {
            setIsShowConfirmModal(false);
        },
    );

    const onSaveReview = () => {
        const newReviews = [...reviews].map((r) => ({
            ...r,
            no: undefined,
            order: Number(r.order),
        }));
        const param = { reviews: newReviews, no: id };
        reviewSaver.mutate(param);
    };

    useEffect(() => {
        adminReviews?.length && setReviews(adminReviews);
    }, [adminReviews]);

    useEffect(() => {
        !open && setReviews([initialReview]);
    }, [open]);

    return (
        <>
            <EditModal
                buttonText="저장"
                handleBtnClick={() => setIsShowConfirmModal(true)}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="강의상세 노출 수강평 관리"
                isModalShow={open}
                checkCloseModal={true}
            >
                <>
                    <div css={tableRootStyle}>
                        <table>
                            <colgroup>
                                <col width="10%" />
                                <col width="10%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="40%" />
                                <col width="10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        <span>* </span>노출여부
                                    </th>
                                    <th>
                                        <span>* </span>노출순서
                                    </th>
                                    <th>
                                        <span>* </span>이름
                                    </th>
                                    <th>
                                        <span>* </span>평점
                                    </th>
                                    <th>
                                        <span>* </span>수강평
                                    </th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviews.map((review, i) => (
                                    <tr key={i.toString()}>
                                        <td>
                                            <DropDown
                                                border
                                                data={[
                                                    {
                                                        key: true,
                                                        value: "노출",
                                                    },
                                                    {
                                                        key: false,
                                                        value: "미노출",
                                                    },
                                                ]}
                                                defaultValue={review.showReview.toString()}
                                                handleChange={(
                                                    _e,
                                                    _test,
                                                    val,
                                                ) => {
                                                    onChangeReviews(
                                                        "showReview",
                                                        i,
                                                        val === "true",
                                                    );
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                handleChange={(evt) => {
                                                    const newVal =
                                                        evt.target.value;
                                                    if (isNaN(Number(newVal))) {
                                                        return;
                                                    }
                                                    onChangeReviews(
                                                        "order",
                                                        i,
                                                        newVal,
                                                    );
                                                }}
                                                label=""
                                                placeholder="노출순서를 입력해주세요"
                                                size="dynamic"
                                                type="text"
                                                value={review.order}
                                                border={true}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                handleChange={(evt) => {
                                                    onChangeReviews(
                                                        "name",
                                                        i,
                                                        evt.target.value,
                                                    );
                                                }}
                                                label=""
                                                placeholder="이름을 입력해주세요"
                                                size="dynamic"
                                                type="text"
                                                value={review.name}
                                                border={true}
                                            />
                                        </td>
                                        <td>
                                            <DropDown
                                                border
                                                data={SCORE_LIST}
                                                defaultValue={review.score.toString()}
                                                handleChange={(
                                                    _e,
                                                    _test,
                                                    val,
                                                ) => {
                                                    onChangeReviews(
                                                        "score",
                                                        i,
                                                        val,
                                                    );
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <TextBox
                                                handleChange={(
                                                    value: string,
                                                ) => {
                                                    onChangeReviews(
                                                        "contents",
                                                        i,
                                                        value,
                                                    );
                                                }}
                                                maxLength={200}
                                                placeholder="수강평을 입력해주세요"
                                                size="dynamic"
                                                isDisabled={false}
                                                defaultText={review.contents}
                                            />
                                        </td>
                                        <td css={deleteBtnStyle}>
                                            <div>
                                                <IconButton
                                                    type="trash"
                                                    handleClick={(_e) => {
                                                        setReviews(
                                                            reviews.filter(
                                                                (
                                                                    _item,
                                                                    index,
                                                                ) =>
                                                                    index !== i,
                                                            ),
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={6} css={addBtnStyle}>
                                        <button
                                            onClick={() => {
                                                setReviews([
                                                    ...reviews,
                                                    initialReview,
                                                ]);
                                            }}
                                        >
                                            <div>
                                                <p>+</p>
                                                <p>수강평 추가</p>
                                            </div>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            </EditModal>
            <ConfirmModal
                isModalShow={isShowConfirmModal}
                buttonText="확인"
                handleCancel={() => setIsShowConfirmModal(false)}
                handleOk={() => onSaveReview()}
            >
                저장하시겠습니까?
            </ConfirmModal>
        </>
    );
};

export default LectureScoreManagementModal;

const tableRootStyle = css`
    width: 80vw;
    max-width: 1200px;
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 1.2rem;
            span:first-of-type {
                color: #ff0000;
            }
            span {
                font-weight: 400;
                font-size: 0.85rem;
                color: #b5b5b5;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
            vertical-align: middle !important;
        }
    }
`;

const deleteBtnStyle = css`
    vertical-align: middle !important;
    div {
        justify-content: center;
        display: flex;
        align-items: center;
    }
`;

const addBtnStyle = css`
    padding: 10px !important;
    button {
        cursor: pointer;
        border: 1px solid #757575;
        border-radius: 24px;
        padding: 0.3rem 0.6rem;
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #757575;
            p:first-of-type {
                padding-right: 0.5rem;
                font-weight: 700;
                font-size: 1.5rem;
            }
            p {
                font-size: 1rem;
            }
        }
    }
`;
