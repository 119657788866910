import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSuccess: false,
    isError: false,
    errMsg: "",
};

export const productUpdateResultSlice = createSlice({
    name: "productUpdateResult",
    initialState,
    reducers: {
        setSuccess: (state) => {
            state.isSuccess = true;
        },
        setError: (state, action) => {
            state.isError = true;
            state.errMsg = action.payload.errMsg;
        },
        setInit: (state) => {
            state.isSuccess = initialState.isSuccess;
            state.isError = initialState.isError;
            state.isSuccess = initialState.isSuccess;
        },
    },
});

export const { setSuccess, setError, setInit } =
    productUpdateResultSlice.actions;

export default productUpdateResultSlice.reducer;
