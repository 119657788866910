import React from "react";
import { IconButton } from "@src/components";
import { ConvertHtmlToString } from "@src/utils/textUtil";

export const getColumns = ({ navigate, setIsDelete }: any) => {
    return [
        {
            Header: "분류",
            accessor: "faqType",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "제목",
            accessor: "faqTitle",
            Cell: ({ cell: { value } }: any) => (
                <div
                    className="faqTitle"
                    style={{
                        width: "80%",
                        textAlign: "left",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "normal",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "본문",
            accessor: "faqContent",
            Cell: ({ cell: { value } }: any) => (
                <div
                    className="faqContent"
                    style={{
                        width: "80%",
                        whiteSpace: "pre-wrap",
                        textAlign: "left",
                    }}
                >
                    {ConvertHtmlToString(value)}
                </div>
            ),
        },
        {
            Header: "노출",
            accessor: "view",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value === 1 ? "O" : "X"}
                </div>
            ),
        },
        {
            Header: "등록 일시",
            accessor: "faqDate",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "최종 수정 일시",
            accessor: "updateDate",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "번호",
            accessor: "faqNo",
        },
        {
            Header: "관리",
            accessor: "viewControl",
            Cell: ({ row: { values } }: any) => (
                <div
                    style={{
                        width: "100px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <IconButton
                        type="trash"
                        handleClick={() => {
                            setIsDelete({
                                deleteNo: values.faqNo,
                                isModalShow: true,
                            });
                        }}
                    />
                    <IconButton
                        type="edit"
                        handleClick={() => {
                            navigate(`/help/faq/update/${values.faqNo}`);
                        }}
                    />
                </div>
            ),
        },
    ];
};
