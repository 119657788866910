import { createSlice } from "@reduxjs/toolkit";

const RegisteredInformationData = [
    {
        key: "regis1",
        id: 0,
        receptionDate: "2016.06.09 (갑4)",
        rightType: "소유권이전청구권 가등기",
        note: "말소기준등기 2020타경112352",
        rightHolder: "파산자 김성훈의 파산관재인 임창기",
        bondPrice: "16000000",
        extinctionOrNot: "소멸",
    },
    {
        key: "regis2",
        id: 1,
        receptionDate: "2016.06.10 (갑4)",
        rightType: "근저당권 설정",
        note: "2020타경112352",
        rightHolder: "주식회사재네트대부",
        bondPrice: "161120",
        extinctionOrNot: "인수",
    },
];

const initialState = RegisteredInformationData;

export const registeredInformationSlice = createSlice({
    name: "registeredInfo",
    initialState,
    reducers: {
        createRegisteredInfo: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateRegisteredInfo: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createRegisteredInfo, updateRegisteredInfo } =
    registeredInformationSlice.actions;

export default registeredInformationSlice.reducer;
