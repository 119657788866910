import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";
import { queryList } from "./queryList";

const fetchSettlement = async (params: ISettlementDTO) => {
    const res = await axiosInstance.get(axiosPath.settlementPath, { params });
    if (!res?.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = res.data;
    const originData: ISettlement[] = responseData.list;
    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCount);
    } else {
        queryList.setTotalCount(responseData.totalCount);
    }
    const newData = queryList.getData(() => {
        return originData;
    });
    return newData;
};

export const useFindAllSettlement = (params: ISettlementDTO) => {
    return useQuery([params, "findAllsettlement"], () => fetchSettlement({ ...params, limit: 10 }), {
        enabled: false,
        keepPreviousData: true,
    });
};

export const useFindPaymentList = (params: IPaymentListDTO) => {
    return useQuery<ISettlementPaymentResDTO>(
        [params, "findpaymenyList"],
        async () => {
            const newParam = { ...params };
            newParam.paidYear = Number(`20${params.paidYear}`);
            try {
                const res = await axiosInstance.get(axiosPath.paymentListPath, { params: newParam });
                return res?.data;
            } catch (error) {
                console.log("err>>>", error);
                throw error;
            }
        },
        {
            enabled: false,
        },
    );
};

export interface ISettlement {
    no: number;
    userNo: number;
    settlementMonth: string;
    partnerNickname: string;
    name: string;
    phone: string;
    targetCount: number;
    level: string;
    factor: number;
    cancelAmountBefore: string;
    cancelAmountAfter: string;
    paymentAmount: string;
    monthlyPayouts: string;
    settlementDate: string;
    settlementStatus: string;
    paidYear: number;
    paidMonth: number;
}

export interface ISettlementResDTO {
    totalCount: number;
    page: number;
    limit: number;
    cursor: string;
    list: ISettlement[];
}

export interface ISettlementDTO {
    page: number;
    cursor?: string;
    limit?: number;
    partnerNo?: number;
    searchType?: "userName" | "userEmail" | "userNo" | "nickname";
    searchValue?: string;
    startDate?: string;
    endDate?: string;
    level?: string[];
    status?: "정산대기" | "정산완료";
    existCancel?: "취소액없음" | "취소액있음";
}

export interface IPaymentListDTO {
    page: number;
    cursor?: string;
    limit?: number;
    partnerNo?: number;
    paidYear: number;
    paidMonth: number;
}

export interface ISettlementPayment {
    paymentNo: number;
    paymentDate: number;
    userNo: string;
    userName: string;
    productName: string;
    paymentPrice: string;
    cancelAmountBefore: string;
    cancelAmountAfter: string;
    paymentAmount: number;
}

export interface ISettlementPaymentResDTO {
    totalCount: number;
    page: number;
    limit: number;
    cursor: string;
    settlementMonth: string;
    partnerNickname: string;
    targetCount: number;
    level: string;
    factor: number;
    monthlyPrice: string;
    monthlyCancelBefore: string;
    monthlyCancelAfter: string;
    monthlyAmount: number;
    monthlyPayouts: string;
    list: ISettlementPayment[];
}
