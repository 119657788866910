import React, { useState, useEffect, ChangeEvent, KeyboardEvent } from "react";
import Logo from "@src/assets/logo_gd.png";
import authService from "@src/api/authService";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Input, Button } from "@src/components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEventListener } from "@src/hooks/useEventListener";
import { useDebounce } from "@src/hooks/useDebounce";
import { firstPage } from "@src/constants/Menu";
import { endLoading, startLoading } from "@src/reducers/loading";
import { AppDispatch } from "@src/store";

function Login() {
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const navigate = useNavigate();
    const [emailError, setEmailError] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const debouncedState = useDebounce(email, 500);
    const isLogin = authService.isUserLogin();

    useEffect(() => {
        if (isLogin) {
            navigate(firstPage);
        }
    });

    useEffect(() => {
        dispatch(endLoading());
    }, []);

    useEffect(() => {
        if (debouncedState) {
            debouncedState.includes("@")
                ? setEmailError("")
                : setEmailError("이메일 형식이 올바르지 않습니다.");
        } else {
            setEmailError("");
        }

        errorMessage !== "" && setErrorMessage("");
        if (password !== "") {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [debouncedState]);

    const emailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
    };

    const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const current = e.target.value;
        setPassword(current);
        errorMessage !== null && setErrorMessage("");

        if (email !== "") {
            if (current === "") {
                setButtonDisabled(true);
            } else {
                setButtonDisabled(false);
            }
        }
    };

    const handleClick = () => {
        if (buttonDisabled === false) {
            const username = email;
            dispatch(startLoading());
            authService
                .executeService(username, password)
                .then(({ status, data }) => {
                    if (status === 200) {
                        authService.registerLoginSuccess(
                            username,
                            data.data.accessToken,
                        );
                        navigate(firstPage);
                    }
                })
                .catch(({ response }) => {
                    const { message } = response.data;
                    setErrorMessage(message);
                })
                .finally(() => dispatch(endLoading()));
        }
    };

    const afterKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>) => {
        if (key === "Enter") {
            handleClick();
        }
    };

    useEventListener(afterKeyPress);

    return (
        <div css={loginWrap}>
            <div css={loginDiv}>
                <img src={Logo} alt="미스고" />
                <div css={emptyDivByEmail} />
                <Input
                    value={email}
                    placeholder="이메일"
                    isDisabled={false}
                    handleChange={emailChange}
                    errorMessage={emailError}
                    size="default"
                />
                <div css={emptyDivByPwd} />
                <Input
                    value={password}
                    type="password"
                    placeholder="비밀번호"
                    handleChange={passwordChange}
                    isDisabled={false}
                    errorMessage={errorMessage}
                    size="default"
                />
                <Button
                    label="로그인"
                    size="big"
                    color="gd"
                    handleClick={handleClick}
                    isDisabled={buttonDisabled}
                />
                <div css={infoDiv}>
                    ※ 로그인이 되지 않는다면 최고 관리자에게 별도 요청해 주세요
                    <br /> 최고 관리자 : info@missgoauction.com
                </div>
            </div>
        </div>
    );
}

const loginWrap = css`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Common.colors.gray100};
`;

const loginDiv = css`
    width: 640px;
    height: 436px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${Common.colors.white};
    border-radius: 16px;

    button {
        margin-top: 28px;
    }
    Input:nth-of-type(2) {
        border-bottom-color: ${Common.colors.gray200};
    }
    Input p {
        padding-top: 8px;
    }
    p:nth-of-type(1) {
        height: 0;
    }
    p:nth-of-type(2) {
        padding-top: 8px;
    }
`;

const emptyDivByEmail = css`
    height: 36px;
`;

const emptyDivByPwd = css`
    height: 28px;
`;

const infoDiv = css`
    color: #9d9fa5;
    font-size: 12px;
    width: 309px;
    height: 44px;
    margin: 32px 0;
    text-align: center;
    ${Common.textStyle.body4_R12}
    white-space: nowrap;
`;

export default Login;
