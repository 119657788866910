import React from "react";
import { IconButton, Label } from "@src/components";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { ConvertPhoneNumber } from "@src/utils/units";

export const getColumns = (navigate: any) => {
    return [
        {
            Header: "번호",
            accessor: "no",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "구분",
            accessor: "types",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "200px", textAlign: "center" }}>
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "이름",
            accessor: "name",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "100px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },

        {
            Header: "휴대폰번호",
            accessor: "phone",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "150px", textAlign: "center" }}>
                    {ConvertPhoneNumber(value)}
                </div>
            ),
        },
        {
            Header: "사무소명",
            accessor: "officeName",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        width: "200px",
                        textAlign: "center",
                        maxWidth: "250px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "등록일시",
            accessor: "createdAt",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "200px", textAlign: "center" }}>
                        {value === null ? "-" : ConvertDateTime(value)}
                    </div>
                );
            },
        },
        {
            Header: "광고매물",
            accessor: "adItemCount",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            textAlign: "center",
                        }}
                    >
                        {value}건
                    </div>
                );
            },
        },
        {
            Header: "문의건수",
            accessor: "inquiryCount",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {value}건
                    </div>
                );
            },
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ row }: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "80px",
                    }}
                >
                    <IconButton
                        type="arrow"
                        handleClick={() => {
                            console.log("row.expertUserNo", row?.original);
                            navigate(
                                `/experts/user/detail/${row?.original?.expertUserNo}`,
                                {
                                    state: {
                                        name: row?.original?.name,
                                        type: row?.original?.types,
                                        expertNo: row?.original?.no,
                                    },
                                },
                            );
                        }}
                    />
                </div>
            ),
        },
    ];
};
