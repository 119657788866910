import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import { Title, Button, PageTable } from "@src/components";
import {
    useUserList,
    initFilters,
    getQueryString,
    dropList,
} from "@src/hooks/queries/useUser";
import UserFilter from "./UserFilter";
import { UserFilterType } from "./types";
import { useDispatch } from "react-redux";
import { endLoading, startLoading } from "@src/reducers/loading";
import { getColumns } from "./UserListColumns";
import { getQueryData } from "@src/utils/useQueryClient";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import { makeQueryString } from "@src/utils/queryStringUtil";

function UserList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = getColumns(navigate);

    const [searchParams, setSearchParams] = useSearchParams();
    const copyQuery: UserFilterType = Object.fromEntries(searchParams);
    const newQuery = getQueryString(copyQuery);
    const queryData: { result: any[]; total: number; cursor: string } =
        getQueryData([queryKeys.USER_LIST, newQuery]);

    const [option, setOption] = useState<UserFilterType>(initFilters);
    const [params, setParams] = useState<UserFilterType>(initFilters);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [cursor, setCursor] = useState<string>("");

    const {
        isLoading,
        data = { result: [], total: 0, cursor: "" },
        isError,
    } = useUserList(params, true);

    const [list, setList] = useState<any>(data.result);

    useEffect(() => {
        if (queryData) {
            setList(queryData.result);
            setTotalCount(queryData.total);
            if (params.page === 1) {
                setCursor(queryData.cursor);
            }
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setList(data.result);
                if (params.page === 1) {
                    setCursor(data.cursor);
                    setTotalCount(data.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [isLoading, data, isError, queryData]);
    const updateURL = (value: UserFilterType) => {
        const queryString = makeQueryString(value);
        setSearchParams(queryString);
    };
    /**
     * 필터 검색 조건이 변경되면 실행하는 함수
     */
    const handleSubmit = (data: UserFilterType) => {
        setOption(data);
        setClear(false);
    };
    /**
     * 필터 검색 버튼을 클릭하면 실행되는 함수
     */
    const handleSearchClick = () => {
        setParams(option);
        setClear(false);
        updateURL(option);
    };

    const [clear, setClear] = useState(false);
    /**
     * 필터 전체 해제 버튼을 클릭하면 실행되는 함수
     */
    const handleClearClick = () => {
        setOption(initFilters);
        setParams(initFilters);
        updateURL(initFilters);
        setClear(true);
    };

    console.log(
        "copyQuery?.page || params.page",
        copyQuery?.page || params.page,
        copyQuery?.page,
        params.page,
    );
    return (
        <div css={listStyle}>
            <Title title="회원 관리" />
            <UserFilter
                handleSubmit={handleSubmit}
                handleSearchClick={handleSearchClick}
                dropList={dropList}
                clear={clear}
            />
            <div className="filterBtnBox" css={filterBtnBox}>
                <Button
                    color="gray"
                    handleClick={handleClearClick}
                    label="전체 해제"
                    size="medium"
                />
                <Button
                    color="purple"
                    handleClick={handleSearchClick}
                    label="검색"
                    size="medium"
                    isDisabled={option.isError}
                />
            </div>

            <div css={tableDiv}>
                <PageTable
                    columns={columns}
                    data={list}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    FixedHeight={500}
                    showTotalCount={true}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        const limit = 10;
                        setParams(() => {
                            let newParams = {
                                ...copyQuery,
                                page,
                                limit,
                                cursor: page === 1 ? "" : cursor,
                            };
                            updateURL(newParams);
                            return newParams;
                        });
                    }}
                    forcePageNumber={params?.page}
                ></PageTable>
            </div>
        </div>
    );
}
const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const searchDiv = css`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 43px;
    width: 100%;
    height: 80vh;
`;
const filterBtnBox = css`
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
`;

export default UserList;
