import { useQuery, useMutation } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { sampleData } from "@src/data/SkipBidContainerSampleData";
import {
    SkipBidHistoryProps,
    BaseSkipBidHistory,
} from "@src/interfaces/product/SkipBidHistoryContainer.interface";
import { asyncCall } from "@src/api/asyncCall";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.itemsAuction}/skipBid/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseSkipBidHistory[] = responseData.data.list;
    return parse(originData)[0];
};

const parse = (originData: BaseSkipBidHistory[]) => {
    const newData = [];
    newData.push(
        originData.map((item: BaseSkipBidHistory, index: number) => {
            const {
                stage,
                due_date,
                min_price,
                result,
                return: returnText,
            } = item;
            return {
                id: index,
                round: stage,
                bidDate: due_date,
                minPrice: min_price?.replace("원", "").replaceAll(",", ""),
                bidResult: result,
                returnText: returnText ?? "",
            };
        }),
    );
    return newData;
};
const useGetSkipBidHistory = (productNo: any) => {
    return useQuery(
        [queryKeys.SKIP_BID_HISTORY, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};

const useUpdateSkipBidHistory = (productNo: any) => {
    const updateSkipBidHistory = async (params: SkipBidHistoryProps[]) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.itemsAuctionSkipBid}/${productNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(updateSkipBidHistory);
};
export { useGetSkipBidHistory, useUpdateSkipBidHistory };
