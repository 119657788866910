import React, { useState, useEffect } from "react";
import NewsForm from "./NewsForm";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Button, Title, ConfirmModal } from "@src/components";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { FormNewsProps, UpdateNewsDto } from "@src/interfaces/News.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import {
    useNewsDetailData,
    useUpdateNews,
} from "@src/hooks/queries/useNewsDetail";
import { useNewsData, initParams } from "@src/hooks/queries/useNews";
import { queryKeys } from "@src/hooks/queries/queryKeys";

function NewsUpdate() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [pageErrorMessage, setPageErrorMessage] = useState<any>();
    const { isLoading, data, isError } = useNewsDetailData(id ?? "0");
    const [initData, setInitData] = useState<any>({});
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
        }
    }, [isLoading, isError]);
    const queryClient = useQueryClient();
    const [updateData, setUpdateData] = useState<FormNewsProps>({
        allFiles: [],
        edit: false,
        mediaCompany: "",
        newsLink: "",
        newsTitle: "",
        startDate: +new Date(),
        view: false,
        newsImage: "",
        keyword: "경매",
        portal: "",
        endDate: new Date().setHours(0, 0, 0),
        keywordCode: 1,
    });
    const [disabled, setDisabled] = useState(false);
    const isNotNULL = (value: string | null) => {
        return value !== null && value !== "";
    };
    const isNotEmpty = (value: string | null) => {
        return value !== "";
    };
    const changeValue = ({
        imageFileCount,
        newsLink,
        startDate,
        mediaCompany,
        newsTitle,
        view,
        edit,
        keywordCode,
        portal,
    }: any) => {
        if (
            isNotNULL(imageFileCount) &&
            isNotEmpty(newsLink) &&
            isNotNULL(startDate) &&
            isNotEmpty(mediaCompany) &&
            isNotEmpty(newsTitle) &&
            isNotNULL(view) &&
            isNotNULL(keywordCode) &&
            isNotEmpty(portal)
        ) {
            // 버튼 활성화
            return edit ? false : true;
        } else {
            // 버튼 비활성화
            return true;
        }
    };
    useEffect(() => {
        const isChange = changeValue({ ...updateData });
        setDisabled(isChange);
        setPageErrorMessage("");
    }, [updateData]);
    const { refetch } = useNewsData(initParams);
    const [isModalShow, setIsModalShow] = useState(false);
    const { mutate: updateMutate } = useUpdateNews(id);

    const handleOk = () => {
        setIsModalShow((prev) => !prev);

        let newData = {
            allFiles: [],
            edit: false,
            mediaCompany: "",
            newsLink: "",
            newsTitle: "",
            startDate: 0,
            view: false,
            newsImage: "",
            keywordCode: updateData.keywordCode,
            portal: "",
            endDate: 0,
        };
        // 변경값이 있는지 비교

        for (let value of Object.keys(updateData)) {
            let v = value as keyof FormNewsProps;
            if (initData[v] !== updateData[v]) {
                newData = { ...newData, [v]: updateData[v] };
            }
        }
        // 업데이트 파라미터 생성
        const params: UpdateNewsDto = {
            is_show: updateData.view ? 1 : 0, // boolean 이므로 비교하지 말고 무조건 업데이트 해준다.
            date: updateData.startDate
                ? ConvertDate(updateData.startDate, "yyyy.MM.dd")
                : "",
            close_at: updateData.endDate
                ? ConvertDate(updateData.endDate, "yyyy.MM.dd")
                : "",
            ...(newData.newsTitle && { title: newData.newsTitle }),
            ...(newData.newsLink && { url: newData.newsLink }),
            ...(newData.mediaCompany && { press: newData.mediaCompany }),
            ...(newData.newsImage && { thumb: newData.newsImage }),
            ...(newData.portal && { portal: newData.portal }),
            ...(newData.keywordCode && { keyword_code: newData.keywordCode }),
        };
        updateMutate(params, {
            onSuccess: () => {
                refetch();
                queryClient.invalidateQueries([queryKeys.NEWS_DETAIL_DATA, id]);
                navigate(`/news/list`);
                setPageErrorMessage("");
            },
            onError: (error) => {
                setPageErrorMessage(error);
            },
        });
    };
    useEffect(() => {
        if (pageErrorMessage !== "") {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [pageErrorMessage]);
    return (
        <div css={layoutDiv}>
            <Title title="뉴스 수정" />
            <NewsForm
                initData={initData}
                handleUpdate={setUpdateData}
                isUpdate={true}
            />
            <div css={btnDiv}>
                <p css={errorStyle}>{pageErrorMessage}</p>
                <Button
                    color="gd"
                    handleClick={() => setIsModalShow(true)}
                    label="수정"
                    size="medium"
                    isDisabled={disabled}
                />
            </div>
            <ConfirmModal
                isModalShow={isModalShow}
                handleOk={handleOk}
                handleCancel={() => {
                    setIsModalShow((prev) => !prev);
                }}
                buttonText="수정"
            >
                뉴스를 수정하시겠습니까?
            </ConfirmModal>
        </div>
    );
}

const layoutDiv = css`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
`;

const btnDiv = css`
    display: flex;
    justify-content: flex-end;
`;
const errorStyle = css`
    padding-right: 15px;
    color: ${Common.colors.error};
    ${Common.textStyle.title6_R14}
    line-height: 38px;
`;
export default NewsUpdate;
