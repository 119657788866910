import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useGetBasicInformation } from "@src/hooks/queries/product/useBasicInformation";
import { Common } from "@src/styles/Common";
import { Input } from "@src/components";
import { ConvertPrice } from "@src/utils/units";

interface IBasicContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
}
function BasicContainer({ isUpdate = false, handleUpdate }: IBasicContainer) {
    const PRODUCT_NO = "20210130101452-1";
    const { isLoading, isError, data } = useGetBasicInformation(PRODUCT_NO);
    const [updateData, setUpdateData] = useState<any>();

    useEffect(() => {
        if (!isLoading && !isError && data) {
            setUpdateData(data);
        }
    }, [data]);

    useEffect(() => {
        handleUpdate && handleUpdate(updateData);
    }, [updateData]);
    return (
        <div>
            <ul css={MainContainerUl}>
                <li>
                    <Input
                        label={"입찰일"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={updateData?.bidDate || ""}
                        border={isUpdate}
                    />
                </li>
                <li>
                    <span>URL</span>
                    {isUpdate ? (
                        <Input
                            placeholder="-"
                            type="text"
                            size="default"
                            handleChange={() => {}}
                            isDisabled={false}
                            errorMessage={""}
                            name=""
                            value={updateData?.URL || ""}
                            border={isUpdate}
                        />
                    ) : (
                        <a
                            href={updateData?.URL || ""}
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.courtauction.go.kr
                        </a>
                    )}
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li>
                    <Input
                        label={"입찰장소"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={updateData?.bidLocation || ""}
                        border={isUpdate}
                    />
                </li>
            </ul>
            <ul css={MainContainerUl}>
                <li>
                    <Input
                        label={"감정가(원)"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={
                            !isUpdate
                                ? ConvertPrice(updateData?.bidPrice || "")
                                : updateData?.bidPrice || ""
                        }
                        border={isUpdate}
                    />
                </li>
                <li>
                    <Input
                        label={"최저가(원)"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={
                            !isUpdate
                                ? ConvertPrice(updateData?.miniPrice || "")
                                : updateData?.miniPrice || ""
                        }
                        border={isUpdate}
                    />
                </li>
                <li>
                    <Input
                        label={"보증금(원)"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={() => {}}
                        isDisabled={false}
                        errorMessage={""}
                        name=""
                        value={
                            !isUpdate
                                ? ConvertPrice(updateData?.depositPrice || "")
                                : updateData?.depositPrice || ""
                        }
                        border={isUpdate}
                    />
                </li>
            </ul>
        </div>
    );
}
const MainContainerUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    justify-content: flex-start;
    li {
        display: flex;
        align-items: center;
        width: 30%;
        height: 70px;
        & > span {
            width: 100px;
            min-width: 100px;
            display: inline-block;
            vertical-align: bottom;
            line-height: 60px;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
        & > a {
            color: blue;
            font-size: 14px;
        }
    }
    li:first-of-type {
        min-width: 400px;
    }
`;
export default BasicContainer;
