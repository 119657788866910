export const storePathValue = () => {
    const storage = sessionStorage;
    if (!storage) {
        return;
    }

    const previousUrl = storage.getItem("CURRENT_URL");
    storage.setItem("PREVIOUS_URL", previousUrl || "");
    storage.setItem("CURRENT_URL", window.location.pathname);
};
