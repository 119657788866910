import styled from "@emotion/styled";
import { Checkbox, EditModal, Input, Title } from "@src/components";
import { IStateLandItem } from "@src/hooks/queries/useStateLand";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { ConvertPrice } from "@src/utils/units";
import React, { Dispatch, SetStateAction } from "react";

interface UserStateLandRefundModalProps {
    isShowStateLandRefundModal: boolean;
    setIsShowModal: (isShow: boolean) => void;
    stateLandItem: IStateLandItem;
    setRefundStateLandState: Dispatch<
        SetStateAction<{
            userNo: number;
            orderNo: number;
            amount: number;
            comments: string;
        }>
    >;
    refundStateLandState: {
        userNo: number;
        orderNo: number;
        amount: number;
        comments: string;
    };
    setIsShowStateLandRefundConfirmModal: Dispatch<SetStateAction<boolean>>;
}

const UserStateLandRefundModal = ({
    isShowStateLandRefundModal,
    setIsShowModal,
    stateLandItem,
    setRefundStateLandState,
    refundStateLandState,
    setIsShowStateLandRefundConfirmModal,
}: UserStateLandRefundModalProps) => {
    return (
        <>
            <EditModal
                isModalShow={isShowStateLandRefundModal}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowStateLandRefundConfirmModal(true);
                    setIsShowModal(false);
                }}
                handleOpenModal={() => {
                    setIsShowModal(false);
                }}
                size="big"
                title="등기열람권 환불"
            >
                <StyledWrap>
                    <StyledContens>
                        <div className="row">
                            <div>
                                상품명 <span>{stateLandItem?.productName}</span>
                            </div>
                            <div>
                                결제액 <span>{stateLandItem?.paidPrice && ConvertPrice(stateLandItem?.paidPrice)}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div>
                                사용 여부
                                <span>{stateLandItem?.isUsed ? "O" : "X"}</span>
                            </div>
                            <div>
                                첫 사용일시
                                <span>{stateLandItem?.firstUsedAt && ConvertDateTime(stateLandItem?.firstUsedAt)}</span>
                            </div>
                        </div>
                    </StyledContens>
                    <StyledRefundInfo>
                        <Title title="환불정보" />
                        <div className="row">
                            <div>
                                <span className="star">*</span>환불예정액{" "}
                                <Input
                                    errorMessage=""
                                    handleChange={(e) => {
                                        const onlyNumber = e.target.value.replace(/[^0-9]/g, "");
                                        setRefundStateLandState({
                                            ...refundStateLandState,
                                            amount: Number(onlyNumber),
                                        });
                                    }}
                                    onKeyDown={function noRefCheck() {}}
                                    placeholder="금액 입력"
                                    size="medium"
                                    type="text"
                                    value={refundStateLandState.amount.toLocaleString() ?? ""}
                                />
                                <Checkbox
                                    defaultValue=""
                                    handleChange={(key, check) => {
                                        check &&
                                            stateLandItem?.paidPrice &&
                                            setRefundStateLandState({
                                                ...refundStateLandState,
                                                amount: stateLandItem?.paidPrice,
                                            });
                                    }}
                                    label="전액환불"
                                />
                            </div>
                        </div>
                        <StyledReasonWrapper>
                            <span>
                                <span className="essential">*</span>사유
                            </span>
                            <input
                                type="text"
                                placeholder="사유를 작성해 주세요"
                                onChange={(e: any) => {
                                    setRefundStateLandState({
                                        ...refundStateLandState,
                                        comments: e.target.value,
                                    });
                                }}
                                value={refundStateLandState?.comments ?? ""}
                            />
                        </StyledReasonWrapper>
                    </StyledRefundInfo>
                </StyledWrap>
            </EditModal>
        </>
    );
};

export default UserStateLandRefundModal;

const StyledWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
`;

const StyledContens = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    & > .row {
        display: flex;
        & > div {
            width: 50%;
            & > span {
                margin-left: 15%;
            }
        }
    }
`;

const StyledRefundInfo = styled.div`
    margin-bottom: 25px;
    & > .row {
        display: flex;
        margin-top: 15px;
        & > div {
            width: 50%;
            display: flex;
            align-items: center;
            & > .star {
                color: #f45b5b;
                margin-left: 0;
            }
            & > span {
                margin-left: 15%;
            }

            & > input {
                margin-left: 20px;
                width: 50%;
                margin-right: 10px;
            }
        }
    }
`;

const StyledReasonWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    width: 648px;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 296px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;
