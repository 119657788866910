import React, { ChangeEvent, KeyboardEvent, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import searchIcon from "@src/assets/search.png";

interface ISearchInput {
    placeholder: string;
    handleClick: (searchItem: string) => void;
    isDisabled?: boolean;
}

function SearchInput({
    placeholder,
    handleClick,
    isDisabled = false,
}: ISearchInput) {
    const [searchItem, setSearchItem] = useState("");
    const onClick = () => {
        handleClick(searchItem);
    };
    const handleKeyUP = (e: KeyboardEvent<HTMLInputElement>) =>
        e.key === "Enter" && onClick();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchItem(e.target.value);
    };
    return (
        <div css={searchStyle}>
            <input
                css={searchInput}
                value={searchItem}
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                onKeyUp={handleKeyUP}
                disabled={isDisabled}
            />
            {/* <Button
                color="gd"
                handleClick={onClick}
                label="검색"
                size="medium"
                isDisabled={isDisabled}
            /> */}
            <button css={searchButton} onClick={onClick} disabled={isDisabled}>
                검색
            </button>
        </div>
    );
}

const searchStyle = css`
    width: 100%;
    display: flex;
    align-items: center;
`;
const searchInput = css`
    width: calc(100% - 36px);
    min-width: 320px;
    height: 36px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid ${Common.colors.purple500};
    border-radius: 0px;
    &::placeholder {
        color: ${Common.colors.gray500};
    }
    outline: none;
`;
const searchButton = css`
    display: block;
    width: 36px;
    height: 36px;
    line-height: 1;
    font-size: 0;
    text-indent: -9999px;
    background: ${Common.colors.purple500} url(${searchIcon}) no-repeat center;
    background-size: 22px 22px;
`;
export default SearchInput;
