import { useQuery, useMutation } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { sampleData } from "@src/data/SummaryInfoSampleData";
import {
    SummaryInformationContainerProps,
    BaseSummaryInformationContainer,
} from "@src/interfaces/product/SummaryInformationContainter.interface";
import { asyncCall } from "@src/api/asyncCall";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.itemsAuction}/summaryInfo/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData = responseData.data.data;
    return parse(originData);
};

const parse = (
    originData: BaseSummaryInformationContainer,
): SummaryInformationContainerProps => {
    const {
        is_agg_bd,
        land_area,
        buid_area,
        status,
        types,
        options: {
            counter_force,
            r_lien,
            r_tomb,
            shr_sale,
            r_surface,
            cert_farm,
            violation,
            only_land,
            only_buid,
            land_unreg,
            land_sep_reg,
        },
    } = originData;
    return {
        isAggBid: is_agg_bd,
        stockScale: land_area?.square.split ?? undefined,
        stockScalePyeong: land_area?.pyong.split ?? undefined,
        landScale: land_area?.square.all ?? undefined,
        landScalePyeong: land_area?.pyong.all ?? undefined,
        buildingScale: buid_area?.square.all ?? undefined,
        buildingScalePyeong: buid_area?.pyong.all ?? undefined,
        buildingStockScale: buid_area?.square.split ?? undefined,
        buildingStockScalePyeong: buid_area?.pyong.split ?? undefined,
        bidType: types ?? "",
        status: status ?? "",
        specialNote: {
            counterForce: counter_force,
            rightOfLien: r_lien,
            rightOfTombBase: r_tomb,
            shareSale: shr_sale,
            landBid: only_land,
            unregisteredOfLandRight: land_unreg,
            needOfLandCertification: cert_farm,
            statutorySuperficies: r_surface,
            violationBuilding: violation,
            buildingBid: only_buid,
            separateRegisteredLand: land_sep_reg,
        },
    };
};
const useGetSummaryInformation = (productNo: any) => {
    return useQuery(
        [queryKeys.SUMMARY_INFORMATION, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};
const useUpdateSummaryInformation = (productNo: any) => {
    const updateSummaryInformation = async (
        params: SummaryInformationContainerProps,
    ) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.itemsAuctionSummaryInfo}/${productNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(updateSummaryInformation);
};
export { useGetSummaryInformation, useUpdateSummaryInformation };
