import styled from "@emotion/styled";
import { EditModal, Title, ViewMoreTable } from "@src/components";
import React, { useEffect } from "react";
import { getUserStateLandEditModalColumns } from "./UserStateLandEditModalColumns";

interface UserStateLandEditModalProps {
    setStateLandEditTime: (time: string) => void;
    setIsShowStateLandEditModal: (isShow: boolean) => void;
    isShowStateLandEditModal: boolean;
    stateLandItem: any;
    setIsShowStateLandEditConfirmModal: (isShow: boolean) => void;
}

const UserStateLandEditModal = ({
    setStateLandEditTime,
    setIsShowStateLandEditModal,
    isShowStateLandEditModal,
    stateLandItem,
    setIsShowStateLandEditConfirmModal,
}: UserStateLandEditModalProps) => {
    const getColumns = getUserStateLandEditModalColumns(setStateLandEditTime);
    return (
        <>
            <EditModal
                isModalShow={isShowStateLandEditModal}
                buttonText="저장"
                handleBtnClick={() => {
                    setIsShowStateLandEditConfirmModal(true);
                    setIsShowStateLandEditModal(false);
                }}
                handleOpenModal={() => {
                    setIsShowStateLandEditModal(false);
                }}
                size="big"
                title="이용권 정보 수정"
            >
                <>
                    <StyledEditContents>
                        <Title title="충전내역" />

                        <ViewMoreTable
                            FixedHeight={100}
                            columns={getColumns}
                            data={[stateLandItem]}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </StyledEditContents>
                </>
            </EditModal>
        </>
    );
};

export default UserStateLandEditModal;

const StyledEditContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    & > div {
        & > button {
            display: none;
        }
    }
`;
