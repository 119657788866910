import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

interface IReplyHistory {
    data: string[];
}
export default function ReplyHistory({ data }: IReplyHistory) {
    return (
        <ul css={replyLogUl}>
            <li>
                <div>
                    {data?.map((v: string, index: number) => {
                        return (
                            <div css={replyLogContent} key={index}>
                                {v}
                            </div>
                        );
                    })}
                </div>
            </li>
        </ul>
    );
}

const replyLogUl = css`
    width: 100%;
    & > li {
        width: 100%;
    }
`;
const replyLogContent = css`
    margin-top: 20px;
    padding-top: 20px;
    color: ${Common.colors.gray700};
    ${Common.textStyle.text1_R13}
    text-decoration-line: line-through;
    line-height: 160%;
    white-space: pre-wrap;
    width: 100%;
    border-top: 1px solid #fff;
`;
