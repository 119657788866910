export const deungiDropDown = [
    {
        id: 1,
        key: "deungi",
        value: "등기소",
    },
    {
        id: 2,
        key: "buildingRegister",
        value: "세움터",
    },
];
