import { axiosInstance } from "@src/api/axiosConfig";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { queryList } from "./queryList";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { ICampusDetail, ILectures } from "@src/interfaces/Campus.interface";

const fetchData = async (params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/classes`,
        {
            params: {
                ...params,
            },
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: ILectures = response.data;
    queryList.setDataList(responseData.list);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCnt);
    } else {
        queryList.setTotalCount(responseData.totalCnt);
    }

    return queryList.getData(() => {
        return responseData.list;
    });
};

const fetchOne = async (no: number) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData: ICampusDetail = response.data;

    return responseData;
};

const fetchProduct = async (param: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/products`,
        { params: { ...param } },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;

    return responseData;
};

const fetchResources = async (no: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}/resources`,
        { params: { page: 1, limit: 999 } },
    );
    const responseData = response.data;
    return responseData;
};

const fetchCurriculums = async (no: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}/curriculums`,
        { params: { page: 1, limit: 999 } },
    );
    const responseData = response.data;
    return responseData;
};

const fetchReviews = async (no: string, param: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}/reviews`,
        { params: { ...param } },
    );
    const responseData = response.data;
    return responseData;
};

const fetchAdminReviews = async (no: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}/admin/reviews`,
        { params: { page: 1, limit: 999 } },
    );
    const responseData = response.data;
    return responseData;
};

const fetchSugangUsers = async (no: string, params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}/users`,
        { params },
    );
    const responseData = response.data;
    return responseData;
};

const fetchSugangUsersReviews = async (no: string, params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/class/${no}/reviews`,
        { params },
    );
    const responseData = response.data;
    return responseData;
};

const fetchUsersCoupons = async (no: string, params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestCampus}/${no}/coupons`,
        { params },
    );
    const responseData = response.data;
    return responseData;
};

const fetchCampusCouponList = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.nestCoupon}/items`, {
        params,
    });
    const responseData = response.data;
    return responseData;
};

const useVimeoInfo = async (url: any) => {
    const response = await axiosInstance.get(`${axiosPath.nestCampus}/vimeo`, {
        params: url,
    });
    const responseData = response.data;
    return responseData;
};

const useSugangUsers = (no: string) => {
    return useInfiniteQuery({
        queryKey: [`${queryKeys.CAMPUS}/${no}/sugangUsers`],
        queryFn: ({ pageParam }) => fetchSugangUsers(no, { page: pageParam }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage =
                pages[0].totalCnt > pages.length * 10
                    ? pages.length + 1
                    : undefined;
            return newPage;
        },
    });
};

const useSugangUsersReviews = (no: string) => {
    return useInfiniteQuery({
        queryKey: [`${queryKeys.CAMPUS}/${no}/reviews`],
        queryFn: ({ pageParam }) =>
            fetchSugangUsersReviews(no, { page: pageParam }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage =
                pages[0].totalCnt > pages.length * 10
                    ? pages.length + 1
                    : undefined;
            return newPage;
        },
    });
};

const useUsersCouponList = (no: string) => {
    return useInfiniteQuery({
        queryKey: [`${queryKeys.CAMPUS}/coupon/${no}`],
        queryFn: ({ pageParam }) => fetchUsersCoupons(no, { page: pageParam }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage =
                pages[0].totalCnt > pages.length * 10
                    ? pages.length + 1
                    : undefined;
            return newPage;
        },
    });
};

const useCampusCoupons = (filters: any, enabled: boolean = true) => {
    return useQuery(
        [`${queryKeys.COUPON_LIST}/campus`, filters],
        () => fetchCampusCouponList(filters),
        {
            enabled,
        },
    );
};

const useCampusProducts = (filters: any, enabled: boolean = true) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/products`, filters],
        () => fetchProduct(filters),
        {
            enabled,
        },
    );
};
const useCampusResources = (no: string, enabled: boolean = true) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/${no}/resources`],
        () => fetchResources(no),
        {
            enabled,
        },
    );
};
const useCampusCurriculums = (no: string, enabled: boolean = false) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/${no}/curriculums`],
        () => fetchCurriculums(no),
        {
            enabled,
        },
    );
};
const useCampusReviews = (
    no: string,
    filters: any,
    enabled: boolean = false,
) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/${no}/reviews`, filters],
        () => fetchReviews(no, filters),
        {
            enabled,
        },
    );
};
const useCampusAdminReviews = (no: string, enabled: boolean = false) => {
    return useQuery(
        [`${queryKeys.CAMPUS}/${no}/adminReviews`],
        () => fetchAdminReviews(no),
        {
            enabled,
        },
    );
};

const useCampusList = (filters: any, enabled: boolean = true) => {
    return useQuery([queryKeys.CAMPUS, filters], () => fetchData(filters), {
        enabled,
    });
};

const useCampusOne = (filters: any, enabled: boolean = false) => {
    return useQuery([queryKeys.CAMPUS, filters], () => fetchOne(filters), {
        enabled,
    });
};

const useCampusResourceSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.post(
                    `${url}/${no}/resource`,
                    data,
                );
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusCurriculumSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.post(
                    `${url}/${no}/curriculum`,
                    data,
                );
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusAdminReviewSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.post(
                    `${url}/${no}/admin/review`,
                    data,
                );
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusReviewUpdate = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.patch(
                    `${url}/${no}/review`,
                    data,
                );
                return res.data;
            } catch (Err) {
                console.log("patchError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.post(url, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusUpdate = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.patch(`${url}/${no}`, data);
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusConnectToProduct = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/product`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.post(url, data);
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusProductRemover = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/product`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.delete(`${url}/${data}`);
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};
const useCampusRemover = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}/class`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.delete(`${url}/${data}`);
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusCouponRemover = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}`;
    return useMutation(
        async (data: any) => {
            try {
                const res = await axiosInstance.delete(`${url}/${data}/coupon`);
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const useCampusCouponSaver = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.nestCampus}`;
    return useMutation(
        async (data: any) => {
            const no = data.no;
            delete data.no;
            try {
                const res = await axiosInstance.post(
                    `${url}/${no}/coupon`,
                    data,
                );
                return res.data;
            } catch (Err) {
                console.log("saveError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export {
    useCampusList,
    useCampusRemover,
    useCampusOne,
    useCampusSaver,
    useCampusUpdate,
    useCampusAdminReviews,
    useCampusCurriculums,
    useCampusResources,
    useCampusReviews,
    useCampusResourceSaver,
    useCampusCurriculumSaver,
    useCampusAdminReviewSaver,
    useCampusConnectToProduct,
    useCampusReviewUpdate,
    useCampusProducts,
    useSugangUsers,
    useVimeoInfo,
    useSugangUsersReviews,
    useCampusProductRemover,
    useCampusCoupons,
    useCampusCouponSaver,
    useUsersCouponList,
    useCampusCouponRemover,
};
