import React, { useState } from "react";
import { CampusListColumns } from "./CampusListColumns";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmModal, PageTable } from "@src/components";
import { useCampusList, useCampusRemover } from "@src/hooks/queries/useCampus";
import { css } from "@emotion/react";

interface Props {}

const test = [
    {
        no: 11,
        name: "test lecture",
        title: "connect",
        isOpen: 100,
        score: 2,
    },
];

const CampusList: React.FC<Props> = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [param, setParam] = useState<any>({ page: 1, limit: 10 });
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [deletedId, setDeletedId] = useState<number>(0);

    const columns = CampusListColumns(navigate, setDeletedId, setOpenDialog);

    const { data, refetch } = useCampusList(param);

    const deleteCampus = useCampusRemover(
        () => {
            setOpenDialog(false);
            setDeletedId(0);
            refetch();
        },
        () => {
            setOpenDialog(false);
            setDeletedId(0);
        },
    );
    const onSubmitDeleteCampus = () => {
        const param = deletedId;
        deleteCampus.mutate(param);
    };
    return (
        <div css={rootStyle}>
            <PageTable
                columns={columns}
                data={data?.result ?? [...test]}
                initialState={{
                    pageIndex: 0,
                    pageSize: 10,
                }}
                FixedHeight={500}
                showTotalCount={true}
                totalCount={data?.total ?? 0}
                handlePageChange={(index: number) => {
                    const page = index + 1;
                    setParam({ ...param, page });
                }}
                forcePageNumber={param.page}
            />
            <ConfirmModal
                isModalShow={openDialog}
                buttonText="삭제"
                handleCancel={() => {
                    setDeletedId(0);
                    setOpenDialog(false);
                }}
                handleOk={onSubmitDeleteCampus}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
        </div>
    );
};

export default CampusList;

const rootStyle = css`
    padding: 4rem 0;
`;
