import { useMutation, useQuery } from "react-query";
import {
    CreateReplyType,
    ResultSupportType,
    InitSupportType,
    BaseSupportType,
    UpdateSupportType,
    CreateSupportType,
} from "@src/pages/help/supportTypes";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { asyncCall } from "@src/api/asyncCall";

// const random = (max: number): CreateReplyType => {
//     return {
//         userName: "은하수",
//         userPhoneNumber: "010-1234-5678",
//         userEmail: "abc@naver.com",
//         userContents: "결제에서 단계가 안 넘어가요",
//         reply: "안녕하세요 당신의 경매비서 미스고 입니다.\n문의하신 내용 확인 결과 추가적인 문의 사항이 있으실 경우 언제든지 다시 문의 부탁드립니다.\n2022.02.28 15:31:56 김철수 사원",
//         prevReply: [
//             "안녕하세요 당신의 경매비서 미스고 입니다.\n문의하신 내용 확인 결과 추가적인 문의 사항이 있으실 경우 언제든지 다시 문의 부탁드립니다.\n2022.02.28 15:31:56 김철수 사원",
//             "안녕하세요 당신의 경매비서 미스고 입니다.\n문의하신 내용 확인 결과 추가적인 문의 사항이 있으실 경우 언제든지 다시 문의 부탁드립니다.\n2022.02.28 15:31:56 김철수 사원",
//         ],
//     };
// };

// const initialSupportDetailData = random(500);

const fetchData = async (id: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.inquirySingle}/${id}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const userData = response.data.data.user;
    const originData: BaseSupportType[] = responseData.data.data;
    return { user: userData, result: parse(originData) };
};
const parse = (originData: BaseSupportType[]): InitSupportType => {
    const userContents = originData[0];
    const userQst = userContents.contents;
    const lastReply = originData[1];
    let replyText = "";
    let replyAT = "";
    let replyBY = "";
    if (lastReply) {
        replyText = lastReply.contents;
        replyBY = lastReply.created_admin;
        replyAT = lastReply.updated_at || "";
    }
    const newData: InitSupportType = {
        ...userContents,
        no: lastReply?.no || 0,
        userQst: userQst,
        reply: lastReply ? `${replyText} \n ${replyAT} ${replyBY} 답변` : "",
        prevReply: [],
        device: "APP",
    };
    originData.forEach((item: any, i: number) => {
        const { direction, contents, deleted_at, updated_at, created_admin } =
            item;
        if (i > 1 && direction !== 0) {
            if (deleted_at) {
                newData.prevReply.push(
                    `${contents} \n ${deleted_at} ${created_admin} 삭제`,
                );
            } else {
                newData.prevReply.push(
                    `${contents} \n ${updated_at} ${created_admin} 답변`,
                );
            }
        } else if (i === 1) {
            deleted_at &&
                newData.prevReply.unshift(
                    `${contents} \n ${deleted_at} ${created_admin} 삭제`,
                );
        }
    });
    return newData;
};
const useGetSupportDetailData = (supportId: string) => {
    return useQuery<ResultSupportType, Error>(
        [queryKeys.SUPPORT_REPLY_DATA, supportId],
        () => fetchData(supportId),
        {
            // initialData: initialSupportDetailData,
        },
    );
};

const useUpdateSupportData = (supportId: string, replyNo: number) => {
    const updateSupport = async (params: UpdateSupportType) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.inquirySingle}/${supportId}/${replyNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(updateSupport);
};

const useDeleteSupportData = (replyNo: number) => {
    const deleteSupport = async (supportId: string) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.inquirySingle}/${supportId}/${replyNo}`,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(deleteSupport);
};

const useCreateSupportData = (supportId: string) => {
    const createSupport = async (params: CreateSupportType) => {
        const promise = await axiosInstance.post(
            `${axiosPath.inquirySingle}/${supportId}`,
            params,
        );
        return asyncCall(promise, "Common");
    };
    return useMutation(createSupport);
};
export {
    useGetSupportDetailData,
    useUpdateSupportData,
    useDeleteSupportData,
    useCreateSupportData,
};
