import React from "react";
import { Title } from "@src/components";

function PaymentSubscribe() {
    return (
        <div>
            <Title />
        </div>
    );
}

export default PaymentSubscribe;
