import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Input, PageTable } from "@src/components";
import { useGetAgentList, useGetSggList, useGetSidoList } from "@src/hooks/queries/useExpert";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./SearchAgentColumns";
import { AgentParamsDto, userExpertDto } from "./types";

interface SearchAgentProps {
    expertData: userExpertDto;
    setExpertData: Dispatch<SetStateAction<userExpertDto>>;
    setIsShowOfficeModal: Dispatch<SetStateAction<boolean>>;
}

const SearchAgent = ({ expertData, setExpertData, setIsShowOfficeModal }: SearchAgentProps) => {
    const columns = getColumns(expertData, setExpertData, setIsShowOfficeModal);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedSido, setSelectedSido] = useState<{
        code: string;
        name: string;
    }>({ code: "", name: "" });
    const [selectedSgg, setSelectedSgg] = useState<{
        code: string;
        name: string;
    }>({ code: "", name: "" });

    const [agentSearchParams, setAgentSearchParams] = useState<AgentParamsDto>({
        limit: 10,
        sggCode: "",
    });

    const { data, refetch } = useGetSggList(selectedSido.code);
    const { data: agentList, refetch: refetchAgentList } = useGetAgentList(agentSearchParams, true);

    const { data: sidoList, refetch: refetchSidList } = useGetSidoList();

    useEffect(() => {
        refetchSidList();
    }, []);

    useEffect(() => {
        if (selectedSido.code) {
            refetch();
        }
    }, [selectedSido]);

    useEffect(() => {
        setAgentSearchParams({
            ...agentSearchParams,
            sggCode: selectedSgg.code,
        });
    }, [selectedSgg]);

    return (
        <>
            <StyledSearchAgent>
                <StyledSearch>
                    <div className="AreaDiv">
                        <div className="title">
                            지역검색<span>시도/시군구는 필수선택 입니다.</span>
                        </div>
                        <div className="ButtonDiv">
                            <div
                                className="button"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                            >
                                {!isOpen && selectedSido.name ? selectedSido.name : "시도"}
                            </div>
                            <div className="button">{!isOpen && selectedSgg.name ? selectedSgg.name : "시군구"}</div>
                        </div>
                        {sidoList?.result && isOpen && (
                            <div className="addressArea">
                                <div className="sidoList">
                                    {sidoList?.result.map((sido: any, index: number) => (
                                        <StyledSidoItem
                                            key={index}
                                            className="sidoItem"
                                            onClick={() => setSelectedSido(sido)}
                                            selected={selectedSido.code === sido.code}
                                        >
                                            {sido.name}
                                        </StyledSidoItem>
                                    ))}
                                </div>
                                <div className="sggList">
                                    {data?.result.length > 0 && (
                                        <>
                                            {data?.result.map((sgg: any, index: number) => (
                                                <StyledSggItem
                                                    key={index}
                                                    className="sggItem"
                                                    onClick={() => {
                                                        setSelectedSgg(sgg);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    {sgg.name}
                                                </StyledSggItem>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <Input
                        placeholder="상호명/성명/등록번호(‘-’포함)"
                        value={agentSearchParams?.name ?? ""}
                        size={"medium"}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setAgentSearchParams({
                                ...agentSearchParams,
                                name: value,
                            });
                        }}
                    />
                    <Button
                        color="gd"
                        handleClick={() => refetchAgentList()}
                        label="조회"
                        size="medium"
                        isDisabled={!selectedSgg.code}
                    />
                </StyledSearch>
                <PageTable
                    columns={columns}
                    data={agentList?.result.list ?? []}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    FixedHeight={500}
                    showTotalCount={true}
                    totalCount={agentList?.result.totalCount ?? 0}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setAgentSearchParams({
                            ...agentSearchParams,
                            page,
                        });
                    }}
                    forcePageNumber={agentSearchParams.page ?? 0}
                ></PageTable>
            </StyledSearchAgent>
        </>
    );
};

export default SearchAgent;

const StyledSearchAgent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    .compostionTable {
        td {
            & > div {
                white-space: pre-wrap !important;
            }
        }
    }
`;

const StyledSearch = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-end;
    height: 48px;
    width: 100%;
    .AreaDiv {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;

        .title {
            font-size: 16px;
            & > span {
                margin-left: 10px;
                color: ${Common.colors.error};
                font-size: 10px;
            }
        }
        .ButtonDiv {
            display: flex;
            width: 240px;
            height: 48px;
            align-items: center;
            box-sizing: border-box;
            .button {
                width: 50%;
                height: 100%;
                border: 1px solid black;
                padding: 8px 10px;
                font-size: 18px;
                display: flex;
                align-items: center;
            }
        }
        .addressArea {
            position: absolute;
            top: 75px;
            width: 240px;
            z-index: 10;
            height: 408px;
            overflow-y: scroll;
            display: flex;
            .sidoList,
            .sggList {
                width: 50%;
                height: 408px;
                overflow-y: scroll;
                background-color: white;
            }
        }
    }
    & > input {
        border: 1px solid #d9d9d9;
    }
    & > button {
        height: 100%;
    }
`;

const StyledSidoItem = styled.div<{ selected: boolean }>`
    width: 100%;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    background-color: ${({ selected }) => (selected ? "white" : "#cee4fc")};
    height: 48px;
    ${({ selected }) => {
        if (selected) {
            return css`
                color: blue;
            `;
        }
    }}
`;

const StyledSggItem = styled.div`
    width: 100%;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    background-color: white;
    height: 48px;
    :hover {
        background-color: #58589b;
    }
`;
