import { createSlice } from "@reduxjs/toolkit";

const name = "bannerView";
const initialState = 0;

export const bannerViewSlice = createSlice({
    name,
    initialState,
    reducers: {
        setBannerView: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setBannerView } = bannerViewSlice.actions;

export default bannerViewSlice.reducer;
