interface IErrorCode {
    [key: string]: string;
}

const ErrorCommon: IErrorCode = {
    100: "매개변수값이 올바르지 않습니다.",
    101: "상태값이 올바르지 않습니다.",
    999: "서버에서 알 수 없는 오류입니다.",
    20999: "서버에서 알 수 없는 오류입니다.",
    "-1": "서버에서 알 수 없는 오류입니다",
};

const ErrorCodeUser: IErrorCode = {
    20100: "지정한 계정이 이미 있습니다.",
    20110: "지정한 계정(CI)이 이미 있습니다.",
    20200: "계정을 찾을 수 없습니다.",
    20300: "삭제되었습니다.",
    20301: "삭제 보류중입니다.",
    20401: "지정한 계정이 비활성화되어 있습니다.",
    20402: "중단되었습니다.",
    20500: "오래된 정보입니다.",
    20999: "서버에서 알 수 없는 오류입니다.",
};

const ErrorCodeTemplate: IErrorCode = {
    0: "사용할 수 있는 템플릿 코드입니다.",
    20100: "템플릿 코드가 이미 있습니다.",
};

const ErrorCodeSituation: IErrorCode = {
    0: "사용할 수 있는 상황 코드입니다.",
    20100: "상황 코드가 이미 있습니다.",
};

const ErrorCodeNews: IErrorCode = {
    "-1": "서버에서 알 수 없는 오류입니다",
    100: "매개변수값이 올바르지 않습니다.",
    101: "상태값이 올바르지 않습니다.",
    999: "서버에서 알 수 없는 오류입니다.",
    20999: "서버에서 알 수 없는 오류입니다.",
    20100: "이미 등록된 기사 url 입니다.",
};

export const ErrorCode = {
    Common: ErrorCommon,
    User: ErrorCodeUser,
    Template: ErrorCodeTemplate,
    Situation: ErrorCodeSituation,
    News: ErrorCodeNews,
};
