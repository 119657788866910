import { useMutation, useQuery } from "react-query";
import {
    BasePushType,
    PushListType,
    BasePushListType,
} from "@src/pages/notify/types";
import { queryKeys } from "./queryKeys";
import { Constants } from "@src/constants/Constants";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { ResponseMissgoApi } from "@src/interfaces/MissgoApi";
import { asyncCall } from "@src/api/asyncCall";
import { NumberCapabilityList } from "aws-sdk/clients/sns";

interface ParametersType {
    q?: string | undefined;
    page?: number | undefined;
    limit?: number | undefined;
}
interface ReceiversParametersType {
    postboxQueueCommonNo?: NumberCapabilityList | undefined;
    page?: number | undefined;
    limit?: number | undefined;
}

const fetchData = async (params: ParametersType) => {
    const response = await axiosInstance.get(
        `${axiosPath.nestNotificationsV1}/push-groups`,
        {
            params,
        },
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData = responseData.list;

    return originData.length
        ? {
              result: parse(originData),
              total: responseData.totalCount,
          }
        : { result: [], total: 0 };
};
// const fetchReceiversData = async (params: ReceiversParametersType) => {
//     const response = await axiosInstance.get(
//         `${axiosPath.nestNotifications}/push-groups/${params}/receivers`,
//     );

//     if (!response.status) {
//         throw new Error("Problem fetching data");
//     }

//     const responseData = response.data;
//     const originData = responseData.list;

//     return originData.length
//         ? {
//               result: parse(originData),
//               total: responseData.totalCount,
//           }
//         : { result: [], total: 0 };
// };

const parse = (originData: BasePushListType[]) => {
    const newData = originData.map((data: any, idx: any) => ({
        listNo: idx,
        pushNo: data?.no,
        sendAt: data?.reservationAt ?? "",
        category: data?.category.toString(),
        title: data?.msgTitle,
        contents: data?.msgBody,
        link: data?.msgClickAction ?? "",
        status: data?.status.toString(),
        readCount: data?.stat?.readCount,
        readRate: data?.stat?.readRate ?? 0,
        messageCount: data?.stat?.messageCount,
        toType: data?.toType,
        more: data?.no,
        edit: false,
        total: 0,
    }));

    return newData;
};

const usePushList = (params: ParametersType) => {
    return useQuery<ResponseMissgoApi<PushListType>, Error>(
        [queryKeys.PUSH_LIST, { ...params }],
        () => fetchData(params),
        {
            // initialData: initialPushData,
        },
    );
};

// const useReceiversPushList = (params: ReceiversParametersType) => {
//     return useQuery<ResponseMissgoApi<ReceiversParametersType>, Error>(
//         [queryKeys.PUSH_RECEIVERS, { ...params }],
//         () => fetchReceiversData(params),
//         {
//             // initialData: initialPushData,
//         },
//     );
// };

export interface IPushMessageParameters {
    type: "test" | "live" | "cancel";
    reservation_at?: string;
}
const usePushMessage = (pushNo: string) => {
    const testPushData = async (params: IPushMessageParameters) => {
        if (params.type === "live") {
            return asyncCall(await pushLive(pushNo, params), "Common");
        } else if (params.type === "cancel") {
            return asyncCall(await pushCancel(pushNo), "Common");
        } else if (params.type === "test") {
            return asyncCall(await pushTest(pushNo), "Common");
        }
    };

    return useMutation(testPushData);
};

const pushTest = (pushNo: string) => {
    return axiosInstance.post(`${axiosPath.postbox}/${pushNo}/status/test`);
};

const pushLive = (pushNo: string, params: IPushMessageParameters) => {
    return axiosInstance.post(`${axiosPath.postbox}/${pushNo}/status/live`, {
        reservation_at: params.reservation_at,
    });
};

const pushCancel = (pushNo: string) => {
    return axiosInstance.post(`${axiosPath.postbox}/${pushNo}/status/cancel`);
};

export { usePushList, usePushMessage };
