import React, { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Label } from "@src/components";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { userExpertDto } from "./types";
import { ConvertPhoneNumber } from "@src/utils/units";

export const getHistoryColumns = (
    // expertData: userExpertDto,
    setIsShowChangeHistoryModal: Dispatch<SetStateAction<boolean>>,
    changeHistoryParams: any,
    setUserExpertInfoHistoryNo: Dispatch<SetStateAction<any>>,
    setIsHistoryDetailModalShow: Dispatch<SetStateAction<boolean>>,
) => {
    return [
        {
            Header: "수정일시",
            accessor: "createdAt",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {ConvertDateTime(value)}
                </div>
            ),
        },
        {
            Header: "수정내용",
            accessor: "summaries",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ textAlign: "center", padding: "15px 0" }}>
                        {value.length
                            ? value.map((item: string, idx: number) => (
                                  <div key={idx}>{item}</div>
                              ))
                            : "-"}
                    </div>
                );
            },
        },
        {
            Header: "작성자",
            accessor: "creatorName",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },

        {
            Header: "이전이력과 비교하기",
            accessor: "no",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        color="purple"
                        size="small"
                        label="상세보기"
                        isDisabled={false}
                        handleClick={() => {
                            // setIsShowUserInfoModal(true);
                            setIsShowChangeHistoryModal(false);
                            setUserExpertInfoHistoryNo(value);
                            setIsHistoryDetailModalShow(true);
                        }}
                        styles={{ width: 100, height: 35 }}
                    />
                </div>
            ),
        },
    ];
};
