import React, { ChangeEvent, useCallback, useState } from "react";
import { Input } from "@src/components";

function TestPage() {
    const [count, setCount] = useState(0);

    const decrement = useCallback(() => {
        setCount(count - 1);
    }, [count]);
    const increment = useCallback(() => {
        setCount(count + 1);
    }, [count]);

    const [value, setValue] = useState("");
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <div>
            <div>TestPage</div>
            <button onClick={decrement}>-</button>
            <button onClick={increment}>+</button>
            <div>{count}</div>
            <div>
                <Input
                    placeholder="텍스트를 입력해주세요."
                    value={value}
                    handleChange={onChange}
                    size="medium"
                />
            </div>
        </div>
    );
}
export default TestPage;
