import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
};

export const loadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        endLoading: (state) => {
            state.isLoading = false;
        },
    },
});

export const { startLoading, endLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
