import styled from "@emotion/styled";
import { Button, DropDown, EditModal, Input, PageTable, Title } from "@src/components";
import React, { Fragment, useEffect, useRef, useState } from "react";
import userNoImg from "../../assets/userNoImg.png";
import photo from "../../assets/photo.png";
import addPhotoImg from "../../assets/addPhotoImg.png";
import Cancel from "../../assets/Cancel.png";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { initUserExpertState, userExpertDto } from "./types";
import {
    useGetChangeHistory,
    useGetChangeHistoryDetails,
    useGetUserExpert,
    usePostExperts,
    usePutExpertInfoFile,
    usePutExpertProfile,
    useUpdateExperts,
    useUserNotExpertList,
} from "@src/hooks/queries/useExpert";
import { getColumns } from "./NotExpertUserListColumns";
import { ConvertPhoneNumber } from "@src/utils/units";
import { getHistoryColumns } from "./ChangeHistoryListColumns";
import { ConvertDateTime } from "@src/utils/dateUtil";
import backArrow from "../../assets/backArrow.png";
import SearchAgent from "./SearchAgent";
import SearchOffice from "./SearchOffice";

const ExpertForm = () => {
    const dropDownList = [
        {
            value: "직접입력",
        },
        {
            value: "경매 전과정(법원동행,명도비별도)",
        },
        {
            value: "매물 및 권리분석(보고서)",
        },
        {
            value: "명도",
        },
        {
            value: "대리입찰",
        },
    ];

    const userDropDown = [
        { value: "이름", key: "userInfo.uname" },
        { value: "이메일", key: "user.id" },
        { value: "핸드폰번호", key: "userInfo.phone" },
    ];
    const [selectedValue, setSelectedValue] = useState<string>("직접입력");
    const [selectedInputValue, setSelectedInputValue] = useState<string>("");
    const [selectedFee, setSelectedFee] = useState<string>("");
    const [isShowUserInfoModal, setIsShowUserInfoModal] = useState<boolean>(false);
    const [isShowChangeHistoryModal, setIsShowChangeHistoryModal] = useState<boolean>(false);
    const [isShowOfficeModal, setIsShowOfficeModal] = useState<boolean>(false);

    const profileRef = useRef<any>(null);
    const infoFileRef = useRef<any>(null);

    const { no } = useParams();
    const navigate = useNavigate();
    const [expertData, setExpertData] = useState<userExpertDto>(initUserExpertState);
    const [expertName, setExpertName] = useState<string>("");

    const [prevExpertData, setPrevExpertData] = useState<userExpertDto>(initUserExpertState);

    const columns = getColumns(expertData, setExpertData, setIsShowUserInfoModal, setExpertName);

    const tryPutExpertProfile = usePutExpertProfile(
        (res) => {
            setExpertData({ ...expertData, profile: res });
        },
        () => {
            console.log("res", "error");
        },
    );

    const changeProfile = (e: any) => {
        const bodyData = {
            file: e.target.files[0],
            userNo: expertData.userNo ?? 0,
        };
        tryPutExpertProfile.mutate(bodyData);
    };

    const tryPutExpertInfofile = usePutExpertInfoFile(
        (res) => {
            setExpertData({
                ...expertData,
                info: {
                    ...expertData.info,
                    file: {
                        url: res,
                    },
                },
            });
        },
        () => {
            console.log("res", "error");
        },
    );

    const changeInfoFile = (e: any) => {
        const bodyData = {
            file: e.target.files[0],
            userNo: expertData.userNo ?? 0,
        };
        tryPutExpertInfofile.mutate(bodyData);
    };

    const tryPostExperts = async () => {
        const newData = { ...expertData };
        const sendData = {
            ...newData,
            info: {
                ...newData.info,
                phone: newData.info.phone.replaceAll("-", ""),
            },
        };
        if (!expertData?.info.counselingCategories?.some((category) => category === "agent")) {
            delete sendData.info.counselingFees;
        }

        if (no) {
            updateExperts.mutate({ userExpertNo: no, body: sendData });
        } else {
            postExperts.mutate(sendData);
        }
    };

    const postExperts = usePostExperts(() => {
        navigate("/experts/home");
    });

    const updateExperts = useUpdateExperts(() => {
        navigate("/experts/user/list");
    });

    const { data: userExpertData, refetch: userExpertDataRefetch } = useGetUserExpert(no ?? "0");
    useEffect(() => {
        if (no) {
            userExpertDataRefetch();
        }
    }, [no]);
    const changeExpertData = () => {
        if (userExpertData) {
            const { no: userNo } = userExpertData.user;
            const infoData: any = userExpertData?.infos?.filter((info) => info.type === expertData.info.type);
            if (infoData?.length) {
                const {
                    no,
                    phone,
                    type,
                    officeInfo,
                    counselingCategories,
                    counselingFees,
                    career,
                    qualification,
                    introduction,
                    file,
                } = infoData[0];

                if (userNo) {
                    let newData: any = {
                        userNo: userNo !== 0 ? userNo : expertData?.userNo,
                        agentNo: userExpertData?.agentNo,
                        profile: userExpertData.profile,
                        info: {
                            type: type,
                            phone: phone,
                            officeInfo: {
                                ...officeInfo,
                            },
                            counselingCategories: counselingCategories,
                            counselingFees: counselingFees,
                            career: career,
                            qualification: qualification,
                            introduction: introduction,
                        },
                    };
                    if (file) {
                        setExpertData({
                            ...newData,
                            info: {
                                ...newData.info,
                                file: {
                                    url: file,
                                },
                            },
                        });
                        setPrevExpertData({
                            ...newData,
                            info: {
                                ...newData.info,
                                file: {
                                    url: file,
                                },
                            },
                        });
                    } else {
                        setExpertData(newData);
                        setPrevExpertData(newData);
                    }
                }
            }
            setChangeHistoryParams({
                ...changeHistoryParams,
                userExpertNo: userExpertData?.no,
                userExpertInfoNo: infoData?.length ? infoData[0].no ?? 0 : 0,
            });
        }
    };
    useEffect(() => {
        changeExpertData();
        setExpertName(userExpertData?.user.info?.name ?? "");
    }, [userExpertData]);

    useEffect(() => {
        changeExpertData();
    }, [expertData?.info?.type]);

    const trySubmitDisabled = () => {
        if (no) {
            return JSON.stringify(prevExpertData) === JSON.stringify(expertData) &&
                expertData?.info.counselingCategories?.some((category) => category === "agent")
                ? !(expertData.info.phone && expertData.info.officeInfo && expertData.info.counselingFees?.length)
                : !(expertData.info.phone && expertData.info.officeInfo.name);
        } else {
            return expertData?.info.counselingCategories?.some((category) => category === "agent")
                ? !(expertData.info.phone && expertData.info.officeInfo && expertData.info.counselingFees?.length)
                : !(expertData.info.phone && expertData.info.officeInfo.name);
        }
    };

    //* userSearchModal
    const [notExpertListParams, setNotExpertListParams] = useState<any>({
        page: 1,
        limit: 10,
        searchType: "userInfo.uname",
    });

    const { data, refetch } = useUserNotExpertList(notExpertListParams);

    useEffect(() => {
        refetch();
    }, [notExpertListParams?.page]);
    const testInfoData: any = userExpertData?.infos?.filter((info) => info?.type === expertData?.info?.type);
    const [changeHistoryParams, setChangeHistoryParams] = useState<any>({
        userExpertNo: userExpertData?.no,
        userExpertInfoNo: testInfoData?.length ? testInfoData[0].no ?? 0 : 0,
        page: 1,
        limit: 10,
    });

    const { data: historyData, refetch: historyRefetch } = useGetChangeHistory(changeHistoryParams);
    useEffect(() => {
        changeHistoryParams.userExpertNo && historyRefetch();
    }, [changeHistoryParams]);

    const [userExpertInfoHistoryNo, setUserExpertInfoHistoryNo] = useState<any>(0);
    const [isHistoryDetailModalShow, setIsHistoryDetailModalShow] = useState<boolean>(false);
    const historyColumns = getHistoryColumns(
        setIsShowChangeHistoryModal,
        changeHistoryParams,
        setUserExpertInfoHistoryNo,
        setIsHistoryDetailModalShow,
    );

    const { data: historyDetailData, refetch: historyDetailRefetch } = useGetChangeHistoryDetails({
        userExpertNo: changeHistoryParams?.userExpertNo,
        userExpertInfoNo: changeHistoryParams?.userExpertInfoNo,
        userExpertInfoHistoryNo: userExpertInfoHistoryNo,
    });

    useEffect(() => {
        if (userExpertInfoHistoryNo !== 0) {
            historyDetailRefetch();
        }
    }, [userExpertInfoHistoryNo]);

    const phoneNumRex = /^(010|011|016|018|019)/;

    return (
        <>
            <StyledExpertFormWrap>
                <Title title="전문가 회원 등록" />
                <div className="contents">
                    <div className="userNumContents">
                        <Button
                            color="purple"
                            size="small"
                            label="회원정보 가져오기"
                            isDisabled={false}
                            handleClick={() => {
                                setIsShowUserInfoModal(true);
                            }}
                            styles={{ width: 157, height: 35 }}
                        />
                        {expertData?.userNo !== 0 && <div className="userNo">회원번호 : {expertData?.userNo}</div>}
                        {no && (
                            <div className="changeHistory">
                                <Button
                                    color="purple"
                                    size="small"
                                    label="수정이력"
                                    isDisabled={false}
                                    handleClick={() => {
                                        setIsShowChangeHistoryModal(true);
                                    }}
                                    styles={{ width: 157, height: 35 }}
                                />
                            </div>
                        )}
                    </div>
                    <>
                        <div className="userInfo">
                            <div className="userImgWrap">
                                <input
                                    type="file"
                                    id="fileUpload"
                                    style={{ display: "none" }}
                                    ref={profileRef}
                                    onChange={(e) => {
                                        changeProfile(e);
                                    }}
                                />
                                <img
                                    src={expertData.profile ? expertData.profile : userNoImg}
                                    alt=""
                                    className="userImg"
                                    onClick={() => {
                                        profileRef.current.click();
                                    }}
                                />
                                <img src={photo} alt="" className="photo" />
                                <div className="buttonDiv">
                                    <Button
                                        color={expertData.info.type === "agent" ? "purple" : "gray"}
                                        size="small"
                                        label="공인중개사"
                                        isDisabled={false}
                                        handleClick={() => {
                                            if (expertData?.info?.type !== "agent") {
                                                setExpertData({
                                                    ...initUserExpertState,
                                                    userNo: expertData.userNo,
                                                    profile: expertData.profile,
                                                    info: {
                                                        ...initUserExpertState.info,
                                                        type: "agent",
                                                    },
                                                });
                                            }
                                        }}
                                        styles={{ width: 153, height: 42 }}
                                    />
                                    {/* <Button
                                        color={expertData.info.type === "scrivener" ? "purple" : "gray"}
                                        size="small"
                                        label="법무사"
                                        isDisabled={false}
                                        handleClick={() => {
                                            if (expertData?.info?.type !== "scrivener") {
                                                setExpertData({
                                                    ...initUserExpertState,
                                                    userNo: expertData.userNo,
                                                    profile: expertData.profile,
                                                    info: {
                                                        ...initUserExpertState.info,
                                                        type: "scrivener",
                                                    },
                                                });
                                            }
                                        }}
                                        styles={{ width: 153, height: 42 }}
                                    /> */}
                                </div>
                            </div>
                            <div className="userTextDiv">
                                <Input
                                    handleChange={(evt) => {}}
                                    label="이름"
                                    placeholder="미스고"
                                    size="dynamic"
                                    type="text"
                                    value={expertName ?? ""}
                                    border={true}
                                    isDisabled={true}
                                />
                                <div className="requiredInputDiv">
                                    <div className="requiredInputTitle">
                                        휴대폰번호
                                        <span className="required">*</span>
                                        <span className="addMessage">
                                            고객과 소통하는 번호를 입력해 주세요 (문자/전화문의는 작성하신 번호로
                                            연결됩니다)
                                        </span>
                                    </div>
                                    <Input
                                        handleChange={(evt) => {
                                            setExpertData({
                                                ...expertData,
                                                info: {
                                                    ...expertData.info,
                                                    phone: evt.target.value,
                                                },
                                            });
                                        }}
                                        label=""
                                        placeholder="010-0000-0000"
                                        size="dynamic"
                                        type="text"
                                        value={ConvertPhoneNumber(expertData?.info?.phone?.replaceAll("-", ""))}
                                        border={true}
                                    />
                                    {expertData.info.phone && !phoneNumRex.test(expertData.info.phone.slice(0, 3)) && (
                                        <span className="errorMessage">소통가능한 핸드폰 번호를 입력해 주세요</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="officeInfo">
                            <div className="searchInfoWrap">
                                <div style={{ display: "flex" }}>
                                    <Title title="중개사무소(중개법인) 정보" />
                                    <span className="required">*</span>
                                </div>
                                <Button
                                    color="purple"
                                    size="small"
                                    label="조회하기"
                                    isDisabled={false}
                                    handleClick={() => {
                                        setIsShowOfficeModal(true);
                                    }}
                                    styles={{ width: 360, height: 42 }}
                                />
                                {expertData.info.officeInfo.name && (
                                    <div className="officeBox">
                                        <div className="officeRow">
                                            <span className="name">사무소명</span>
                                            <span className="text">{expertData.info.officeInfo.name}</span>
                                        </div>
                                        {expertData.info.type === "agent" && (
                                            <div className="officeRow">
                                                <span className="name">중개등록번호</span>
                                                <span className="text">
                                                    {expertData.info.officeInfo.registrationNumber}
                                                </span>
                                            </div>
                                        )}
                                        <div className="officeRow">
                                            <span className="name">대표</span>
                                            <span className="text">{expertData.info.officeInfo.ceo}</span>
                                        </div>
                                        <div className="officeRow">
                                            <span className="name">전화번호</span>
                                            <span className="text">{expertData.info.officeInfo.phone}</span>
                                        </div>
                                        <div className="officeRow">
                                            <span className="name">소재지</span>
                                            <span className="text">{expertData.info.officeInfo.address}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {expertData.info.type === "agent" && (
                                <div className="officePhotoWrap">
                                    <Title title="매수신청대리인으로 등록되어 있으신가요?" />
                                    <input
                                        type="file"
                                        id="infoFileUpload"
                                        style={{ display: "none" }}
                                        ref={infoFileRef}
                                        onChange={(e) => {
                                            changeInfoFile(e);
                                        }}
                                    />
                                    <p>
                                        매수신청대리인은 경매상담 및 대리입찰이 가능합니다. 아래 서류를 첨부해 주세요.
                                        <br />
                                        1. 매수신청대리인 등록증
                                    </p>
                                    <div className="imgList">
                                        <img
                                            src={addPhotoImg}
                                            alt=""
                                            onClick={() => {
                                                infoFileRef.current.click();
                                            }}
                                        />
                                        <div className="fileCount">
                                            {expertData?.info?.file?.url ? "(1/1)" : "(0/1)"}
                                        </div>
                                        {expertData?.info?.file?.url && <img src={expertData.info.file.url} alt="" />}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="counselingField">
                            <div className="titleWrap">
                                <Title title="상담분야 (중복선택 가능)" />
                                {expertData.info.type === "agent" && (
                                    <p>매수신청대리인 등록증을 첨부하면 경매 대리인 업무도 선택 가능합니다</p>
                                )}
                            </div>
                            <div className="buttonDiv">
                                {expertData.info.type === "agent" && (
                                    <div className="buttonRow">
                                        <div className="name">공인중개사</div>
                                        <Button
                                            color={
                                                // expertData.info.counselingCategories
                                                //     ?.join()
                                                //     .includes("item")
                                                expertData?.info.counselingCategories?.some(
                                                    (category) => category === "item",
                                                )
                                                    ? "purple"
                                                    : "gray"
                                            }
                                            size="small"
                                            label="매물상담"
                                            isDisabled={false}
                                            handleClick={() => {
                                                setExpertData({
                                                    ...expertData,
                                                    info: {
                                                        ...expertData.info,
                                                        counselingCategories:
                                                            expertData?.info.counselingCategories?.some(
                                                                (category) => category === "item",
                                                            )
                                                                ? expertData.info.counselingCategories.filter(
                                                                      (text) => text !== "item",
                                                                  )
                                                                : expertData.info.counselingCategories?.concat("item"),
                                                    },
                                                });
                                            }}
                                            styles={{
                                                width: 113,
                                                height: 42,
                                            }}
                                        />
                                    </div>
                                )}

                                <div className="buttonRow">
                                    <div className="name">경매 대리인</div>
                                    <Button
                                        color={
                                            expertData?.info.counselingCategories?.some(
                                                (category) => category === "claim",
                                            )
                                                ? "purple"
                                                : "gray"
                                        }
                                        size="small"
                                        label="경공매상담"
                                        isDisabled={!expertData.info.file?.url && expertData.info.type === "agent"}
                                        handleClick={() => {
                                            setExpertData({
                                                ...expertData,
                                                info: {
                                                    ...expertData.info,
                                                    counselingCategories: expertData?.info.counselingCategories?.some(
                                                        (category) => category === "claim",
                                                    )
                                                        ? expertData.info.counselingCategories.filter(
                                                              (text) => text !== "claim",
                                                          )
                                                        : expertData.info.counselingCategories?.concat("claim"),
                                                },
                                            });
                                        }}
                                        styles={{ width: 113, height: 42 }}
                                    />
                                    <Button
                                        color={
                                            expertData?.info.counselingCategories?.some(
                                                (category) => category === "agent",
                                            )
                                                ? "purple"
                                                : "gray"
                                        }
                                        size="small"
                                        label="경매 매수신청대리"
                                        isDisabled={!expertData.info.file?.url && expertData.info.type === "agent"}
                                        handleClick={() => {
                                            setExpertData({
                                                ...expertData,
                                                info: {
                                                    ...expertData.info,
                                                    counselingCategories: expertData?.info.counselingCategories?.some(
                                                        (category) => category === "agent",
                                                    )
                                                        ? expertData.info.counselingCategories.filter(
                                                              (text) => text !== "agent",
                                                          )
                                                        : expertData.info.counselingCategories?.concat("agent"),
                                                },
                                            });
                                        }}
                                        styles={{ width: 125, height: 42 }}
                                    />
                                </div>
                            </div>
                        </div>
                        {expertData?.info.counselingCategories?.some((category) => category === "agent") && (
                            <div className="consultationFee">
                                <div style={{ display: "flex" }}>
                                    <Title title="상담내용 및 수수료" />
                                    <span className="required">*</span>
                                </div>

                                <div className="contentsWrap">
                                    <div className="inputWrap">
                                        <div className="inputRow">
                                            <span className="name">상담내용</span>
                                            <div className="inputContents">
                                                <DropDown
                                                    data={dropDownList}
                                                    defaultValue={selectedValue}
                                                    handleChange={(e, text, value) => {
                                                        if (text) {
                                                            setSelectedValue(text);
                                                        }
                                                    }}
                                                    label=""
                                                />
                                                {selectedValue === "직접입력" && (
                                                    <input
                                                        type="text"
                                                        className="textInput"
                                                        onChange={(e) => {
                                                            setSelectedInputValue(e.target.value);
                                                        }}
                                                        value={selectedInputValue}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="inputRow">
                                            <span className="name">수수료</span>
                                            <input
                                                type="text"
                                                className="textInput"
                                                onChange={(e) => {
                                                    setSelectedFee(e.target.value);
                                                }}
                                                value={selectedFee}
                                            />
                                        </div>
                                        <Button
                                            color="purple"
                                            size="small"
                                            label="확인"
                                            isDisabled={false}
                                            handleClick={() => {
                                                if (expertData.info.counselingFees) {
                                                    setExpertData({
                                                        ...expertData,
                                                        info: {
                                                            ...expertData.info,
                                                            counselingFees: [
                                                                ...expertData.info.counselingFees,
                                                                {
                                                                    content:
                                                                        selectedValue !== "직접입력"
                                                                            ? selectedValue
                                                                            : selectedInputValue,
                                                                    fee: selectedFee,
                                                                },
                                                            ],
                                                        },
                                                    });
                                                    setSelectedValue("직접입력");
                                                    setSelectedInputValue("");
                                                    setSelectedFee("");
                                                } else {
                                                    setExpertData({
                                                        ...expertData,
                                                        info: {
                                                            ...expertData.info,
                                                            counselingFees: [
                                                                {
                                                                    content:
                                                                        selectedValue !== "직접입력"
                                                                            ? selectedValue
                                                                            : selectedInputValue,
                                                                    fee: selectedFee,
                                                                },
                                                            ],
                                                        },
                                                    });
                                                    setSelectedValue("직접입력");
                                                    setSelectedInputValue("");
                                                    setSelectedFee("");
                                                }
                                            }}
                                            styles={{
                                                width: 92,
                                                height: 42,
                                                position: "absolute",
                                                right: 0,
                                                bottom: -50,
                                            }}
                                        />
                                    </div>
                                    {expertData?.info?.counselingFees?.length !== 0 && (
                                        <div className="tableArea">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>상담내용</th>
                                                        <th>수수료</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {expertData?.info?.counselingFees?.map((data, index) => (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>{data?.content}</td>
                                                                <td>{data?.fee}</td>
                                                                <td>
                                                                    <img
                                                                        src={Cancel}
                                                                        alt=""
                                                                        onClick={() => {
                                                                            const newList =
                                                                                expertData?.info?.counselingFees?.filter(
                                                                                    (item, idx) => idx !== index,
                                                                                );
                                                                            setExpertData({
                                                                                ...expertData,
                                                                                info: {
                                                                                    ...expertData.info,
                                                                                    counselingFees: newList,
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="textBox">
                            <div className="textItem">
                                <Title title="경력" />
                                <textarea
                                    placeholder="경력을 입력해주시면 회원분들의 선택에 도움이 됩니다(최대200자)"
                                    onChange={(e) => {
                                        setExpertData({
                                            ...expertData,
                                            info: {
                                                ...expertData.info,
                                                career: e.target.value,
                                            },
                                        });
                                    }}
                                    value={expertData.info.career ?? ""}
                                />
                            </div>
                            <div className="textItem">
                                <Title title="자격" />
                                <textarea
                                    placeholder="공인중개사 외의 다른 자격 사항이 있으시면 기재해 주세요(최대200자)"
                                    onChange={(e) => {
                                        setExpertData({
                                            ...expertData,
                                            info: {
                                                ...expertData.info,
                                                qualification: e.target.value,
                                            },
                                        });
                                    }}
                                    value={expertData.info.qualification ?? ""}
                                />
                            </div>
                            <div className="textItem">
                                <Title title="소개" />
                                <textarea
                                    className="introduce"
                                    placeholder="전문가님을 기다리는 회원분들께 소개의 글을 작성해 주세요(최대500자)"
                                    onChange={(e) => {
                                        setExpertData({
                                            ...expertData,
                                            info: {
                                                ...expertData.info,
                                                introduction: e.target.value,
                                            },
                                        });
                                    }}
                                    value={expertData.info.introduction ?? ""}
                                />
                            </div>
                        </div>
                        <Button
                            color="purple"
                            size="small"
                            label={no ? "수정하기" : "등록하기"}
                            isDisabled={trySubmitDisabled()}
                            handleClick={() => {
                                tryPostExperts();
                            }}
                            styles={{
                                width: 153,
                                height: 42,
                                marginLeft: 751,
                                marginTop: 40,
                            }}
                        />
                    </>
                    {/* )} */}
                </div>
            </StyledExpertFormWrap>
            <EditModal
                buttonText="저장"
                handleBtnClick={() => {}}
                handleOpenModal={() => {
                    setIsShowUserInfoModal(false);
                }}
                size="fitContent"
                title="회원정보 가져오기"
                isModalShow={isShowUserInfoModal}
                dimClose={true}
                checkCloseModal={true}
            >
                <StyledUserInfoWrap>
                    <div className="searchDiv">
                        <DropDown
                            data={userDropDown}
                            defaultValue=""
                            handleChange={(e, text, value) => {
                                if (value) {
                                    setNotExpertListParams({
                                        ...notExpertListParams,
                                        searchType: value,
                                    });
                                }
                            }}
                            label=""
                        />
                        <Input
                            handleChange={(evt) => {
                                setNotExpertListParams({
                                    ...notExpertListParams,
                                    searchValue: evt.target.value,
                                });
                            }}
                            label=""
                            placeholder="성명, 이메일주소, 핸드폰번호를 입력하세요"
                            size="big"
                            type="text"
                            value={notExpertListParams.searchValue}
                            border={true}
                        />
                        <Button
                            color="purple"
                            size="small"
                            label="검색"
                            isDisabled={false}
                            handleClick={() => {
                                refetch();
                            }}
                            styles={{
                                width: 153,
                                height: 48,
                            }}
                        />
                    </div>
                    <PageTable
                        columns={columns}
                        data={data?.result ?? []}
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                        }}
                        FixedHeight={500}
                        showTotalCount={true}
                        totalCount={data?.total ?? 0}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setNotExpertListParams({
                                ...notExpertListParams,
                                page,
                            });
                        }}
                    ></PageTable>
                </StyledUserInfoWrap>
            </EditModal>
            <EditModal
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={() => {
                    setIsShowChangeHistoryModal(false);
                }}
                size="big"
                title="수정 이력"
                isModalShow={isShowChangeHistoryModal}
                dimClose={true}
                checkCloseModal={true}
                deleteButton={true}
            >
                <PageTable
                    columns={historyColumns}
                    data={historyData?.list ?? []}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    FixedHeight={500}
                    showTotalCount={false}
                    totalCount={historyData?.totalCount ?? 0}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setChangeHistoryParams({
                            ...changeHistoryParams,
                            page,
                            cursor: page === 1 ? "" : historyData.cursor,
                        });
                    }}
                    forcePageNumber={notExpertListParams.page ?? 0}
                ></PageTable>
            </EditModal>
            <EditModal
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={() => {
                    setIsHistoryDetailModalShow(false);
                }}
                size="fitContent"
                title=""
                isModalShow={isHistoryDetailModalShow}
                dimClose={true}
                checkCloseModal={true}
                deleteButton={true}
            >
                <StyledHistoryDetailContents>
                    <div
                        className="backButton"
                        onClick={() => {
                            setIsHistoryDetailModalShow(false);
                            setIsShowChangeHistoryModal(true);
                        }}
                    >
                        <img src={backArrow} alt="" />
                    </div>
                    <div className="itemRow">
                        <div className="name">작성자</div>
                        <div className="text">{historyDetailData?.creatorName}</div>
                    </div>
                    <div className="itemRow">
                        <div className="name">수정 일시</div>
                        <div className="text">{ConvertDateTime(historyDetailData?.createdAt ?? 0)}</div>
                    </div>
                    <div className="contentsBox">
                        <div className="name">수정내용</div>
                        <div className="contentsList">
                            {historyDetailData?.edits.map((edit: any) => (
                                <>
                                    <div className="contentsItem">
                                        <div className="title">{edit.name} 수정</div>
                                        {edit?.name === "전화번호" && (
                                            <div className="contentsLine">
                                                <div className="prevBox">{ConvertPhoneNumber(edit?.prev)}</div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="currentBox">{ConvertPhoneNumber(edit.curr)}</div>
                                            </div>
                                        )}
                                        {edit.name === "사무실" && (
                                            <div className="contentsLine">
                                                <div className="officeTab">
                                                    <div className="officeLine">
                                                        <div className="officeName">사무소명</div>
                                                        <div className="officeText">{edit?.prev?.name}</div>
                                                    </div>
                                                    {expertData.info.type === "agent" && (
                                                        <div className="officeLine">
                                                            <div className="officeName">중개등록번호</div>
                                                            <div className="officeText">{edit?.prev?.name}</div>
                                                        </div>
                                                    )}
                                                    <div className="officeLine">
                                                        <div className="officeName">대표</div>
                                                        <div className="officeText">{edit?.prev?.ceo}</div>
                                                    </div>
                                                    <div className="officeLine">
                                                        <div className="officeName">전화번호</div>
                                                        <div className="officeText">{edit?.prev?.phone}</div>
                                                    </div>

                                                    <div className="officeLine">
                                                        <div className="officeName">소재지</div>
                                                        <div className="officeText">{edit?.prev?.address}</div>
                                                    </div>
                                                </div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="officeTab">
                                                    <div className="officeLine">
                                                        <div className="officeName">사무소명</div>
                                                        <div className="officeText">{edit?.curr?.name}</div>
                                                    </div>
                                                    {expertData.info.type === "agent" && (
                                                        <div className="officeLine">
                                                            <div className="officeName">중개등록번호</div>
                                                            <div className="officeText">{edit?.curr?.name}</div>
                                                        </div>
                                                    )}
                                                    <div className="officeLine">
                                                        <div className="officeName">대표</div>
                                                        <div className="officeText">{edit?.curr?.ceo}</div>
                                                    </div>
                                                    <div className="officeLine">
                                                        <div className="officeName">전화번호</div>
                                                        <div className="officeText">{edit?.curr?.phone}</div>
                                                    </div>

                                                    <div className="officeLine">
                                                        <div className="officeName">소재지</div>
                                                        <div className="officeText">{edit?.curr?.address}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {edit?.name === "상담분야" && (
                                            <div className="contentsLine">
                                                <div className="prevBox">
                                                    {edit?.prev?.length &&
                                                        edit?.prev?.map((item: any) => {
                                                            switch (item) {
                                                                case "item":
                                                                    return (
                                                                        <div>
                                                                            매물상담
                                                                            <br />
                                                                        </div>
                                                                    );
                                                                case "claim":
                                                                    return (
                                                                        <div>
                                                                            경공매 권리상담
                                                                            <br />
                                                                        </div>
                                                                    );
                                                                case "agent":
                                                                    return (
                                                                        <div>
                                                                            경매 매수신청대리
                                                                            <br />
                                                                        </div>
                                                                    );
                                                            }
                                                        })}
                                                </div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="currentBox">
                                                    {edit?.curr?.length &&
                                                        edit?.curr?.map((item: any) => {
                                                            switch (item) {
                                                                case "item":
                                                                    return (
                                                                        <div>
                                                                            매물상담
                                                                            <br />
                                                                        </div>
                                                                    );
                                                                case "claim":
                                                                    return (
                                                                        <div>
                                                                            경공매 권리상담
                                                                            <br />
                                                                        </div>
                                                                    );
                                                                case "agent":
                                                                    return (
                                                                        <div>
                                                                            경매 매수신청대리
                                                                            <br />
                                                                        </div>
                                                                    );
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        )}
                                        {edit.name === "상담료" && (
                                            <div className="contentsLine">
                                                <div className="tableArea">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>상담내용</th>
                                                                <th>수수료</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {edit?.prev?.length &&
                                                                edit?.prev.map((data: any, index: number) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td>{data?.content}</td>
                                                                            <td>{data?.fee}</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="tableArea">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>상담내용</th>
                                                                <th>수수료</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {edit?.curr?.length &&
                                                                edit?.curr?.map((data: any, index: number) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td>{data?.content}</td>
                                                                            <td>{data?.fee}</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )}
                                        {edit.name === "경력" && (
                                            <div className="contentsLine">
                                                <div className="prevBox">{edit?.prev}</div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="currentBox">{edit.curr}</div>
                                            </div>
                                        )}
                                        {edit.name === "자격" && (
                                            <div className="contentsLine">
                                                <div className="prevBox">{edit?.prev}</div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="currentBox">{edit.curr}</div>
                                            </div>
                                        )}
                                        {edit.name === "소개" && (
                                            <div className="contentsLine">
                                                <div className="prevBox">{edit?.prev}</div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="currentBox">{edit.curr}</div>
                                            </div>
                                        )}
                                        {edit.name === "파일" && (
                                            <div className="contentsLine">
                                                <div className="prevBox">
                                                    {edit?.prev && <img src={edit?.prev} alt="프로필 이미지" />}
                                                </div>
                                                <div className="arrow">{`->`}</div>
                                                <div className="currentBox">
                                                    {edit?.curr && <img src={edit?.curr} alt="프로필 이미지" />}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </StyledHistoryDetailContents>
            </EditModal>
            <EditModal
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={() => setIsShowOfficeModal(false)}
                size="big"
                title={expertData.info.type === "agent" ? "중개사무소 조회" : "법무사 조회"}
                isModalShow={isShowOfficeModal}
                dimClose={true}
                checkCloseModal={true}
                deleteButton={true}
            >
                <>
                    {expertData.info.type === "agent" ? (
                        <SearchAgent
                            expertData={expertData}
                            setExpertData={setExpertData}
                            setIsShowOfficeModal={setIsShowOfficeModal}
                        />
                    ) : (
                        <SearchOffice
                            expertData={expertData}
                            setExpertData={setExpertData}
                            setIsShowOfficeModal={setIsShowOfficeModal}
                        />
                    )}
                </>
            </EditModal>
        </>
    );
};

export default ExpertForm;

const StyledExpertFormWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 20px;
    box-sizing: border-box;
    .contents {
        display: flex;
        flex-direction: column;
        .userNumContents {
            display: flex;
            gap: 20px;
            .userNo {
                display: flex;
                align-items: center;
            }
            .changeHistory {
                position: absolute;
                top: 80px;
                right: 40px;
            }
        }
        .userInfo {
            display: flex;
            gap: 40px;
            margin-top: 57px;
            .userImgWrap {
                display: flex;
                flex-direction: column;
                gap: 32px;
                align-items: center;
                position: relative;
                .userImg {
                    width: 110px;
                    height: 110px;
                    border-radius: 55px;
                }
                .photo {
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    left: 181px;
                    bottom: 74px;
                }
                .buttonDiv {
                    display: flex;
                    gap: 10px;
                }
            }
            .userTextDiv {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                & > input {
                    width: 360px;
                    height: 42px;
                    background-color: #fff;
                }
                .requiredInputDiv {
                    display: flex;
                    flex-direction: column;
                    gap: 9px;
                    & > input {
                        width: 360px;
                        height: 42px;
                    }
                    .requiredInputTitle {
                        display: flex;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 140%;
                        .required {
                            line-height: 22px;
                            font-size: 30px;
                            font-weight: 700;
                            display: flex;
                            align-items: flex-start;
                            color: #ff0000;
                        }
                        .addMessage {
                            font-size: 10px;
                            font-weight: 400;
                            line-height: 12px;
                            color: #ff0000;
                            padding-left: 13px;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .errorMessage {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 12px;
                        color: #ff0000;
                    }
                }
            }
        }
        .officeInfo {
            display: flex;
            gap: 108px;
            margin-top: 70px;
            .searchInfoWrap {
                display: flex;
                flex-direction: column;
                gap: 16px;
                .required {
                    line-height: 22px;
                    font-size: 30px;
                    font-weight: 700;
                    display: flex;
                    align-items: flex-start;
                    color: #ff0000;
                    padding-left: 5px;
                }
                .officeBox {
                    width: 360px;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    justify-content: center;
                    flex-direction: column;
                    border-top: 1px solid black;
                    border-bottom: 1px solid black;
                    .officeRow {
                        display: flex;
                        width: 100%;

                        gap: 30px;
                        .name {
                            width: 100px;
                            min-width: 100px;
                        }
                    }
                }
            }
            .officePhotoWrap {
                display: flex;
                flex-direction: column;
                gap: 10px;
                & > p {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                }
                .imgList {
                    display: flex;
                    gap: 10px;
                    position: relative;
                    .fileCount {
                        position: absolute;
                        bottom: 10px;
                        left: 24px;
                    }
                    & > img {
                        width: 85px;
                        height: 85px;
                    }
                }
            }
        }
        .counselingField {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 80px;
            .titleWrap {
                display: flex;
                flex-direction: column;
                gap: 6px;
                & > p {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                }
            }
            .buttonDiv {
                display: flex;
                flex-direction: column;
                gap: 14px;
                .buttonRow {
                    display: flex;
                    gap: 14px;
                    align-items: center;
                }
            }
        }
        .consultationFee {
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-top: 80px;
            .required {
                line-height: 22px;
                font-size: 30px;
                font-weight: 700;
                display: flex;
                align-items: flex-start;
                color: #ff0000;
                padding-left: 5px;
            }
            .contentsWrap {
                display: flex;
                gap: 20px;
                .inputWrap {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    position: relative;
                    gap: 8px;
                    .inputRow {
                        display: flex;
                        gap: 18px;
                        .name {
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 22px;
                            width: 80px;
                            color: #8d8d8d;
                            :first-of-type {
                                padding-top: 19px;
                            }
                        }
                        .inputContents {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                        & > .inputContents {
                            & > div {
                                & > label {
                                    & > select {
                                        color: #222222;
                                        font-weight: 400;
                                        font-size: 14px;
                                        border: 1px solid #b4b4b9;
                                        border-radius: 10px;
                                        width: 328px;
                                        height: 56px;
                                    }
                                }
                            }
                        }
                        .textInput {
                            width: 328px;
                            height: 56px;
                            background-color: #f0f0f0;
                            border-radius: 10px;
                            border: none;
                        }
                    }
                }
                .tableArea {
                    & > table {
                        width: 500px;
                        border: 2px solid #f0f0f0;
                        border-collapse: collapse;
                        & > thead {
                            width: 500px;
                            height: 60px;
                            & > tr {
                                width: 100%;
                                height: 100%;
                                background-color: #d9d9d9;
                                & > th {
                                    text-align: center;
                                    vertical-align: middle;
                                    :nth-of-type(1) {
                                        width: 200px !important;
                                    }
                                    :nth-of-type(2) {
                                        width: 250px !important;
                                    }
                                }
                            }
                        }
                        & > tbody {
                            height: fit-content;
                            & > tr {
                                width: 500px;
                                height: 60px;
                                & > td {
                                    text-align: center;
                                    vertical-align: middle;
                                    padding: 14px;
                                    & > img {
                                        width: 26px;
                                        height: 26px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .textBox {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 100px;
            .textItem {
                display: flex;
                flex-direction: column;
                & > textarea {
                    width: 908px;
                    height: 116px;
                    background-color: #f0f0f0;
                    border-radius: 10px;
                    border: none;
                    padding: 14px 18px;
                    resize: none;
                }
                .introduce {
                    height: 245px;
                }
            }
        }
    }
`;

const StyledUserInfoWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    .searchDiv {
        display: flex;
        gap: 14px;
    }
    & > .searchDiv {
        & > div {
            & > label {
                & > select {
                    color: #222222;
                    font-weight: 400;
                    font-size: 14px;
                    border: 1px solid #b4b4b9;
                    border-radius: 10px;
                    height: 48px;
                }
            }
        }
    }
`;

const StyledHistoryDetailContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    .backButton {
        width: 30px;
        & > img {
            width: 100%;
        }
        margin-bottom: 10px;
    }
    .itemRow {
        display: flex;
        gap: 20px;
        align-items: center;
        .name {
            color: #959595;
            white-space: nowrap;
            width: 80px;
        }
    }
    .contentsBox {
        display: flex;
        gap: 20px;
        .name {
            color: #959595;
            white-space: nowrap;
            width: 80px;
        }
        .contentsList {
            display: flex;
            flex-direction: column;
            gap: 25px;
            .contentsItem {
                display: flex;
                flex-direction: column;
                gap: 15px;
                .title {
                    font-size: 18px;
                    font-weight: 700;
                }
                .contentsLine {
                    display: flex;
                    gap: 20px;
                    align-items: center;
                    .prevBox {
                        min-width: 300px;
                        width: 50%;
                        min-height: 100px;
                        height: fit-content;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        background-color: #ededed;
                    }
                    .arrow {
                        font-size: 30px;
                        font-weight: 400;
                        color: #000000;
                        white-space: nowrap;
                    }
                    .currentBox {
                        min-width: 300px;
                        width: 50%;
                        min-height: 100px;
                        height: fit-content;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        background-color: #ededed;
                        & > img {
                            width: 110px;
                            height: 110px;
                            border-radius: 55px;
                        }
                    }
                    .tableArea {
                        & > table {
                            width: 500px;
                            border: 2px solid #f0f0f0;
                            border-collapse: collapse;
                            & > thead {
                                width: 500px;
                                height: 60px;
                                & > tr {
                                    width: 100%;
                                    height: 100%;
                                    background-color: #d9d9d9;
                                    & > th {
                                        text-align: center;
                                        vertical-align: middle;
                                        :nth-of-type(1) {
                                            width: 200px !important;
                                        }
                                        :nth-of-type(2) {
                                            width: 250px !important;
                                        }
                                    }
                                }
                            }
                            & > tbody {
                                height: fit-content;
                                & > tr {
                                    width: 500px;
                                    height: 60px;
                                    & > td {
                                        text-align: center;
                                        vertical-align: middle;
                                        padding: 14px;
                                        & > img {
                                            width: 26px;
                                            height: 26px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .officeTab {
                        display: flex;
                        flex-direction: column;
                        min-width: 183px;
                        width: 50%;
                        min-height: 100px;
                        height: fit-content;
                        padding: 30px 40px;
                        border-top: 1px solid black;
                        border-bottom: 1px solid black;
                        gap: 20px;
                        .officeLine {
                            display: flex;
                            gap: 30px;
                            align-items: center;
                            .officeName {
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
`;
