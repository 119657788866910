import { css } from "@emotion/react";
import {
    Button,
    Calendar,
    ConfirmModal,
    DropDown,
    EditModal,
    Input,
    RangeCalendar,
    Title,
    ViewMoreTable,
} from "@src/components";
import React, { useEffect, useState } from "react";
import userNoImg from "@src/assets/userNoImg.png";
import phone from "@src/assets/blackPhone.svg";
import message from "@src/assets/blackMessage.svg";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getConnectionAdminColumns } from "./ExpertConnectionAdminColumns";
import {
    useFindExpertDetail,
    useFindExpertDetailCount,
    useSaverExpertManager,
    useSaverExpertManagerRemove,
} from "@src/hooks/queries/useExpert";
import { useAdminList } from "@src/hooks/queries/useAdmin";
import {
    useCreatedExpertBanner,
    useGetSearchItem,
} from "@src/hooks/queries/Advertisement";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { format } from "date-fns";

interface IStateProps {
    name: string;
    type: string;
    expertNo: number;
}

const ExpertUserDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<{
        startDate?: string;
        endDate?: string;
    }>({ startDate: "", endDate: "" });
    const { no } = useParams();
    const dateLabel = [
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];
    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const [selectedDate, setSelectedDate] = useState<number | null>(0);

    const { data: expertDetail, refetch: refetchExpertDetail } =
        useFindExpertDetail(no ?? "");

    const { data: expertDetailCount, refetch: refetchExpertDetailCount } =
        useFindExpertDetailCount({ ...filters, no: no ?? "" });

    const { data: adminList, refetch: refetchAdminList } = useAdminList({
        page: 1,
        limit: 9999999,
    });
    const [changeAdInfoState, setChangeAdInfoState] = useState<any>();
    const [confirmModalState, setConfirmModalState] = useState<{
        isShowModal: boolean;
        modalText: string;
        modalType: number;
        bannerNo: number;
        expertUserNo: number;
    }>({
        isShowModal: false,
        modalText: "",
        modalType: 0,
        bannerNo: 0,
        expertUserNo: 0,
    });

    const [isShowGetItemInfoModal, setIsShowGetItemInfoModal] =
        useState<boolean>(false);
    const saveExpertManager = useSaverExpertManager(
        () => {
            refetchExpertDetail();
            setIsShowConnectionAdminModal({
                isShow: false,
                isModify: false,
            });
        },
        (err) => {
            setIsShowConnectionAdminModal({
                isShow: false,
                isModify: false,
            });
            console.log("err>>", err);
        },
    );

    const removeExpertManager = useSaverExpertManagerRemove(
        () => {
            refetchExpertDetail();
        },
        (err) => {
            console.log("err>>", err);
        },
    );

    const onSubmitSaveExpertMangaer = (val: number, isModify?: boolean) => {
        const params = {
            expertUserNo: no ?? "",
            managerAdminNo: val,
            is_modify: isModify,
        };
        saveExpertManager.mutate(params);
    };

    const onSubmitRemoveExpertMangaer = () => {
        setIsShowCancelModal(false);
        const params = {
            expertUserNo: no ?? "",
        };
        removeExpertManager.mutate(params);
    };
    const bidTypeDropDownList: {
        id: number;
        key: string;
        value: string;
    }[] = [
        {
            id: 0,
            key: "0",
            value: "전체",
        },
        {
            id: 1,
            key: "1",
            value: "경매",
        },
        {
            id: 2,
            key: "2",
            value: "공매",
        },
    ];

    const renderDate = (item: number) => {
        if (item == 0) {
            setFilters({
                ...filters,
                startDate: "",
                endDate: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                startDate: before7day,
                endDate: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                startDate: before1Month,
                endDate: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                startDate: before6Month,
                endDate: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                startDate: before12Month,
                endDate: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                startDate: TODAY,
                endDate: TODAY,
            });
        }
        setSelectedDate(item);
    };

    const [isShowConnectionAdminModal, setIsShowConnectionAdminModal] =
        useState<{ isShow: boolean; isModify: boolean }>({
            isShow: false,
            isModify: false,
        });
    const columns = getConnectionAdminColumns(
        onSubmitSaveExpertMangaer,
        isShowConnectionAdminModal.isModify,
    );
    const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);
    const [isShowInfoEditModal, setIsShowInfoEditModal] =
        useState<boolean>(false);
    const [searchItemState, setSearchItemState] = useState<any>({
        cursor: {
            sortType: 0,
        },
        limit: 10,
        bidType: 0,
        searchText: "",
    });

    const buttonList = [
        { name: "광고등록", func: () => setIsShowInfoEditModal(true) },
        {
            name: "전문가 정보",
            func: () => navigate(`/experts/update/${expertDetail?.expertNo}`),
        },
        {
            name: "회원 정보",
            func: () => navigate(`/users/detail/${no}`),
        },
    ];

    const [userInfo, setUserInfo] = useState<IStateProps>({
        name: "",
        type: "",
        expertNo: 0,
    });

    useEffect(() => {
        if (location.state) {
            const { name, type, expertNo } = location.state as IStateProps;
            setUserInfo({
                name,
                type,
                expertNo,
            });
        }
    }, []);

    useEffect(() => {
        refetchExpertDetail();
    }, [no]);

    useEffect(() => {
        refetchExpertDetailCount();
    }, [filters]);
    const {
        data: searchItem,
        refetch: refetchSearchItem,
        hasNextPage: searchItemHasNextPage,
        fetchNextPage: searchItemfetchNextPage,
    } = useGetSearchItem(searchItemState);

    const [itemRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(searchItemHasNextPage),
        onLoadMore: () => {
            searchItemfetchNextPage();
        },
        disabled: false,
        delayInMs: 100,
    });

    const createExpertBanner = useCreatedExpertBanner(
        () => {
            refetchExpertDetail();
            refetchExpertDetailCount();
            setIsShowInfoEditModal(false);
            setSearchItemState({
                cursor: {
                    sortType: 0,
                },
                limit: 10,
                bidType: 0,
                searchText: "",
            });
            setChangeAdInfoState({});
        },
        () => {
            setSearchItemState({
                cursor: {
                    sortType: 0,
                },
                limit: 10,
                bidType: 0,
                searchText: "",
            });
            setChangeAdInfoState({});
            setIsShowInfoEditModal(false);
        },
    );

    const onClickCreateExpertBanner = () => {
        createExpertBanner.mutate({
            userNo: no,
            itemId: changeAdInfoState.itemId,
            startDate: changeAdInfoState.createdAt,
            endDate: changeAdInfoState.expiredAt,
        });
    };

    return (
        <>
            <div css={detailWrap}>
                <Title title="전문가 회원 정보 상세" />
                <div css={buttonWrap}>
                    <span>no. {no}</span>
                    <div css={buttons}>
                        {buttonList.map((button) => (
                            <Button
                                color="gd"
                                handleClick={button.func}
                                label={button.name}
                                size="medium"
                                key={button.name}
                            />
                        ))}
                    </div>
                </div>
                <div css={contentsWrap}>
                    <div css={detailInfoWrap}>
                        <img
                            css={profile}
                            src={expertDetail?.image ?? userNoImg}
                            alt="userNoImg"
                        />
                        <div css={detailTextWrap}>
                            <div css={detailTitle}>
                                <p>{expertDetail?.officeName}</p>
                                <p>
                                    {userInfo.name} {userInfo.type}
                                </p>
                            </div>
                            <div css={infoTextBoxStyle}>
                                <p css={infoText}>
                                    회원 아이디 : {expertDetail?.userId}
                                </p>
                                <p css={infoText}>
                                    전문가 회원 등록일 :{" "}
                                    {ConvertDate(
                                        new Date(
                                            expertDetail?.joinedAt ?? 0,
                                        ).getTime(),
                                    )}
                                </p>
                                {expertDetail?.managerName ? (
                                    <p css={infoText}>
                                        담당 관리자 :{" "}
                                        {expertDetail?.managerName}{" "}
                                        <Button
                                            color="gd"
                                            handleClick={() =>
                                                setIsShowConnectionAdminModal({
                                                    isShow: true,
                                                    isModify: true,
                                                })
                                            }
                                            label="수정"
                                            size="small"
                                        />
                                        <Button
                                            color="gd"
                                            handleClick={() =>
                                                setIsShowCancelModal(true)
                                            }
                                            label="해지"
                                            size="small"
                                        />
                                    </p>
                                ) : (
                                    <p css={infoText}>
                                        담당 관리자 :{" "}
                                        {expertDetail?.managerType}{" "}
                                        <Button
                                            color="gd"
                                            handleClick={() => {
                                                refetchAdminList();
                                                setIsShowConnectionAdminModal({
                                                    isShow: true,
                                                    isModify: false,
                                                });
                                            }}
                                            label="등록"
                                            size="small"
                                        />
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div css={countingWrap}>
                        <div css={dateTitleWrap}>
                            <div css={countTitle}>광고 및 문의 현황</div>
                            <div css={dateWrap}>
                                {dateLabel.map((item) => (
                                    <div key={item.id}>
                                        <button
                                            value={item.value}
                                            css={dateButton(
                                                item.key == selectedDate,
                                            )}
                                            onClick={() => renderDate(item.key)}
                                        >
                                            {item.value}
                                        </button>
                                    </div>
                                ))}
                                <RangeCalendar
                                    setRangeDate={{
                                        startDate:
                                            filters.startDate !== null &&
                                            filters.startDate !== undefined
                                                ? dateParse(filters.startDate)
                                                : null,

                                        endDate:
                                            filters.endDate !== null &&
                                            filters.endDate !== undefined
                                                ? dateParse(filters.endDate)
                                                : null,
                                    }}
                                    getUserRangeDate={(date, isError) => {
                                        const {
                                            startDate: start,
                                            endDate: end,
                                        } = date;
                                        setFilters({
                                            ...filters,
                                            startDate: start
                                                ? ConvertDate(start)
                                                : "",
                                            endDate: end
                                                ? ConvertDate(end)
                                                : "",
                                        });
                                    }}
                                    verticalAlign={false}
                                    isDisabled={false}
                                    timePicker={false}
                                    clear={false}
                                />
                            </div>
                        </div>
                        <div css={countingBoxWrap}>
                            <div css={countingBoxStyle}>
                                <p className="title">매물광고</p>
                                <p className="valueText">
                                    {expertDetailCount?.bannerCount?.toLocaleString()}
                                    <span>건</span>
                                </p>
                            </div>
                            <div css={countingBoxStyle}>
                                <p className="title">
                                    <img src={phone} alt="phone" /> 전화문의
                                </p>
                                <p className="valueText">
                                    {expertDetailCount?.callCount?.toLocaleString()}
                                    <span>건</span>
                                </p>
                            </div>
                            <div css={countingBoxStyle}>
                                <p className="title">
                                    <img src={message} alt="message" />
                                    문자문의
                                </p>
                                <p className="valueText">
                                    {expertDetailCount?.messageCount?.toLocaleString()}
                                    <span>건</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <EditModal
                    buttonText="저장"
                    handleBtnClick={() => {}}
                    handleOpenModal={() => {
                        setIsShowConnectionAdminModal({
                            isShow: false,
                            isModify: false,
                        });
                    }}
                    size="fitContent"
                    title="담당 관리자 등록"
                    isModalShow={isShowConnectionAdminModal.isShow}
                    checkCloseModal={true}
                    deleteButton={true}
                >
                    <div css={connectionModalWrap}>
                        <div css={titleWrap}>
                            <div css={titleText}>
                                관리자 목록
                                <span>
                                    회원번호를 연결한 회원 리스트만 노출됩니다
                                </span>
                            </div>
                            <Input
                                placeholder="이름을 입력하세요"
                                handleChange={() => {}}
                                size="medium"
                            />
                        </div>
                        <ViewMoreTable
                            FixedHeight={300}
                            columns={columns}
                            data={
                                adminList?.result?.filter(
                                    (user) => user.userNo,
                                ) ?? []
                            }
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </div>
                </EditModal>
                <ConfirmModal
                    buttonText="확인"
                    handleCancel={() => setIsShowCancelModal(false)}
                    handleOk={() => onSubmitRemoveExpertMangaer()}
                    isModalShow={isShowCancelModal}
                >
                    <>담당 관리자를 해지 하시겠습니까?</>
                </ConfirmModal>

                <EditModal
                    buttonText="저장"
                    handleBtnClick={() => {
                        setIsShowInfoEditModal(false);
                        onClickCreateExpertBanner();
                    }}
                    handleOpenModal={() => {
                        setIsShowInfoEditModal(false);
                        setChangeAdInfoState({});
                    }}
                    size="fitContent"
                    title="광고 정보 수정"
                    isModalShow={isShowInfoEditModal}
                    checkCloseModal={true}
                >
                    <div css={editModalWrap}>
                        <div css={modalContentsWrap}>
                            <div css={modalContentsTitle}>물건정보</div>
                            <div css={modalContentsItem}>
                                <span>구분</span>
                                <span>{changeAdInfoState?.bidType}</span>
                                <div css={changeButtonDiv}>
                                    <span>{changeAdInfoState?.itemSubId}</span>
                                    <Button
                                        color="purple"
                                        handleClick={() => {
                                            setIsShowInfoEditModal(false);
                                            setIsShowGetItemInfoModal(true);
                                        }}
                                        label="변경"
                                        size="medium"
                                    />
                                </div>
                            </div>
                        </div>
                        <div css={modalContentsWrap}>
                            <div css={modalContentsTitle}>광고일시</div>
                            <div css={modalContentsDate}>
                                <span css={titleSpan}>
                                    <span css={essential}>*</span>광고 시작일시
                                </span>
                                <Calendar
                                    getUserDate={(date) => {
                                        setChangeAdInfoState({
                                            ...changeAdInfoState,
                                            createdAt: format(
                                                new Date(date),
                                                "yyyy-MM-dd",
                                            ),
                                        });
                                    }}
                                    setDate={changeAdInfoState?.createdAt}
                                />
                                <span css={graySpan}>부터</span>
                            </div>
                            <div css={modalContentsDate}>
                                <span css={titleSpan}>
                                    <span css={essential}>*</span>광고 종료일시
                                </span>
                                <Calendar
                                    getUserDate={(date) => {
                                        setChangeAdInfoState({
                                            ...changeAdInfoState,
                                            expiredAt: format(
                                                new Date(date),
                                                "yyyy-MM-dd",
                                            ),
                                        });
                                    }}
                                    setDate={changeAdInfoState?.expiredAt}
                                />
                                <span css={graySpan}>까지</span>
                            </div>
                        </div>
                    </div>
                </EditModal>
                <EditModal
                    buttonText="저장"
                    handleBtnClick={() => {}}
                    handleOpenModal={() => {
                        setSearchItemState({
                            cursor: {
                                sortType: 0,
                            },
                            limit: 10,
                            bidType: 0,
                            searchText: "",
                        });
                        setIsShowGetItemInfoModal(false);
                    }}
                    size="fitContent"
                    title="물건정보 가져오기"
                    isModalShow={isShowGetItemInfoModal}
                    checkCloseModal={true}
                    deleteButton
                >
                    <div css={itemModalWrap}>
                        <div css={modalSearchDiv}>
                            <DropDown
                                handleChange={(e, text, value) => {
                                    value &&
                                        setSearchItemState({
                                            ...searchItemState,
                                            bidType: Number(value),
                                        });
                                }}
                                data={bidTypeDropDownList}
                                defaultValue={""}
                            />
                            <Input
                                placeholder="사건번호/물건관리번호를 입력하세요"
                                handleChange={(e) => {
                                    setSearchItemState({
                                        ...searchItemState,
                                        searchText: e.target.value,
                                    });
                                }}
                                size="medium"
                                value={searchItemState.searchText}
                            />
                            <Button
                                color="purple"
                                handleClick={() => {
                                    searchItemState?.searchText &&
                                        refetchSearchItem();
                                }}
                                label="검색"
                                size="medium"
                            />
                        </div>
                        <div>
                            총 {searchItem?.pages[0].totalCount}건
                            <div css={tableCss}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>구분</th>
                                            <th>사건번호/물건관리번호</th>
                                            <th>용도</th>
                                            <th>소재지</th>
                                            <th>상태</th>
                                            <th>선택</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchItemState.searchText &&
                                        searchItem?.pages[0]?.list?.length ? (
                                            <>
                                                {searchItem?.pages.map(
                                                    (page: any) =>
                                                        page.list.map(
                                                            (
                                                                item: any,
                                                                index: number,
                                                            ) => (
                                                                <tr
                                                                    key={String(
                                                                        [
                                                                            item,
                                                                            index,
                                                                        ],
                                                                    )}
                                                                >
                                                                    <td>
                                                                        {
                                                                            item?.bidType
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.itemSubId
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.useDetail
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.address
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item?.status
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            size="small"
                                                                            color="purple"
                                                                            label="선택"
                                                                            handleClick={() => {
                                                                                setChangeAdInfoState(
                                                                                    {
                                                                                        ...changeAdInfoState,
                                                                                        itemId: item.itemId,
                                                                                        itemSubId:
                                                                                            item.itemSubId,
                                                                                        bidType:
                                                                                            item.bidType,
                                                                                    },
                                                                                );
                                                                                setIsShowGetItemInfoModal(
                                                                                    false,
                                                                                );
                                                                                setIsShowInfoEditModal(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        ),
                                                )}
                                                <tr ref={itemRef}>
                                                    <td
                                                        css={scrollDiv}
                                                        colSpan={8}
                                                    ></td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan={6}>
                                                    검색어를 입력해주세요
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </EditModal>
            </div>
        </>
    );
};

export default ExpertUserDetail;

const detailWrap = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const contentsWrap = css`
    display: flex;
    flex-direction: column;
    height: fit-content;
`;

const detailInfoWrap = css`
    display: flex;
    gap: 40px;
    padding: 25px 40px;
    border: 2px solid #f3f3f3;
    border-bottom: none;
`;

const profile = css`
    width: 147px;
    height: 147px;
    border-radius: 50%;
`;

const detailTextWrap = css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
`;

const detailTitle = css`
    font-size: 25px;
    font-weight: 700;
    line-height: 35px;
`;
const infoText = css`
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const countingWrap = css`
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    min-height: 184px;
    padding: 18px;
    gap: 20px;
`;

const countingBoxWrap = css`
    background-color: white;
    min-height: 115px;
    display: flex;
    justify-content: space-around;
`;

const countTitle = css`
    font-size: 25px;
    font-weight: 600;
    line-height: 24px;
`;

const dateButton = (isActive: boolean) => css`
    padding: 10px;
    margin: 5px;
    background-color: white;
    border: 1px solid gray;
    cursor: pointer;
    border-radius: 8px;
    ${isActive && { fontWeight: 700 }}
`;

const dateWrap = css`
    display: flex;
    align-items: center;
`;

const dateTitleWrap = css`
    display: flex;
    align-items: center;
    gap: 30px;
`;

const buttonWrap = css`
    display: flex;
    margin-top: 100px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
`;

const buttons = css`
    display: flex;
    gap: 20px;
`;

const connectionModalWrap = css`
    width: 700px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    & > div:last-of-type {
        & > button {
            display: none;
        }
    }
`;

const titleWrap = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > input {
        width: 50%;
        border: 1px solid #f3f3f3;
        border-radius: 10px;
    }
`;

const titleText = css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: max-content;
    font-weight: 700;
    line-height: 25px;
    & > span {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        white-space: nowrap;
    }
`;

const countingBoxStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 14px 10px;
    width: 200px;
    .title {
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        display: inline-flex;
        align-items: center;
        img {
            width: 30px;
            margin-right: 4px;
        }
    }
    .valueText {
        text-align: right;
        font-size: 30px;
        line-height: 42px;
        & > span {
            font-size: 17px;
            margin-left: 4px;
        }
    }
`;

const infoTextBoxStyle = css`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const modalContentsTitle = css`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: black;
`;

const editModalWrap = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    width: fit-content;
    gap: 60px;
`;

const modalContentsWrap = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    width: 500px;
`;

const modalContentsItem = css`
    display: flex;
    align-items: center;
    height: fit-content;
    width: 100%;
    box-sizing: border-box;
    & > span:nth-of-type(1) {
        margin-right: 79px;
    }
    & > span:nth-of-type(2) {
        margin-right: 38px;
    }
`;

const changeButtonDiv = css`
    display: flex;
    align-items: center;
    gap: 25px;
    & > button {
        width: 80px;
        height: 40px;
        min-width: 80px !important;
    }
`;

const modalContentsDate = css`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    & > div {
        width: 292px;
        & > div {
            & > div {
                & > input {
                    width: 292px;
                }
            }
        }
    }
`;

const titleSpan = css`
    font-size: 16px;
    margin-right: 24px;
    display: flex;
    align-items: center;
`;

const graySpan = css`
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    margin-left: 11px;
`;

const essential = css`
    font-size: 16px;
    color: #ff0000;
`;

const modalSearchDiv = css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    & > button {
        min-width: 80px;
        height: 40px;
        width: 80px;
    }
    & > input {
        width: 80%;
        margin: 0 10px;
        border: 1px solid #d9d9d9;
    }
    & > div {
        height: 40px;
        & > label {
            height: 100%;
            & > select {
                height: 100%;
            }
        }
    }
`;

const itemModalWrap = css`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 1000px;
`;

const tableCss = css`
    height: 500px;
    overflow-y: scroll;
    & > table {
        width: 100%;
        min-height: 300px;
        & > thead {
            background-color: #f3f3f3;
            & > tr {
                & > th {
                    text-align: center;
                    height: 40px;
                    vertical-align: middle;
                }
            }
        }
        & > tbody {
            width: 100%;
            & > tr {
                & > td {
                    text-align: center;
                    height: 40px;
                    vertical-align: middle;
                }
            }
        }
    }
`;

const scrollDiv = css`
    padding: 40px;
`;
