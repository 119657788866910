import React from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { useLocation, useNavigate } from "react-router-dom";
import { Constants } from "@src/constants/Constants";
import { Title } from "@src/components";

function PushNavigator() {
    const location = useLocation();
    const navigate = useNavigate();
    const pushMenu = [
        { link: "/notify/push", title: "PUSH 관리" },
        {
            link: "/notify/template",
            title: `${Constants.TEMPLATE} 관리`,
        },
        { link: "/notify/situation", title: `${Constants.SITUATION} 관리` },
    ];

    const handleClick = (link: string) => {
        navigate(link);
    };
    return (
        <div css={NavigatorWrapper}>
            {pushMenu.map((menu) => {
                return (
                    <div
                        key={menu.title}
                        className={
                            menu.link === location.pathname ? "selected" : ""
                        }
                        onClick={() => handleClick(menu.link)}
                    >
                        <Title title={menu.title} />
                    </div>
                );
            })}
        </div>
    );
}

const NavigatorWrapper = css`
    display: flex;
    margin: 10px 0;
    gap: 10px;
    align-items: center;
    & > div {
        height: 30px;
        margin-right: 20px;
        color: ${Common.colors.gray500};
        position: relative;
        &:after {
            content: "|";
            position: absolute;
            right: -17px;
            top: 10px;
            font-size: 10px;
        }
        &:last-of-type::after {
            display: none;
        }
        div {
            height: 30px;
            line-height: 30px;
        }
        cursor: pointer;
    }
    div.selected {
        color: ${Common.colors.purple500};
        div {
            font-size: 18px;
            letter-spacing: -0.5px;
        }
    }
`;
export default PushNavigator;
