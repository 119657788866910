import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import Menu from "@src/layout/SideMenu";
import { UpDown, Title } from "@src/components";
import { NavLink } from "react-router-dom";
import { useHomeCountData } from "@src/hooks/queries/useHomeCount";
import EC2StatsChart from "./containers/EC2StatsChart";
import APIHealthCheck from "./containers/APIHealthCheck";
import APIVersionCheck from "./containers/APIVersionCheck";
import NetworkStatsChart from "./containers/NetworkStatsChart";

function Monitor() {
    const Today = () => {
        const date = new Date();
        const weeks = ["일", "월", "화", "수", "목", "금", "토"];
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const week = weeks[date.getDay()];

        return `${year}.${month}.${day} ${week}요일`;
    };

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <Menu />
            <div css={monitorDiv}>
                <Title title={Today()} size={"big"} />
                <div className="left">
                    <APIHealthCheck />
                    <APIVersionCheck />
                    <div className="devInfo">
                        비상 연락망
                        <p>
                            유지수 (개발 1팀)
                            <br /> E. jsyoo@missgoauction.com
                            <br /> T. 010.2883.8533
                        </p>
                    </div>
                </div>
                <div className="right">
                    <EC2StatsChart />
                    <NetworkStatsChart />
                </div>
            </div>
        </div>
    );
}

const monitorDiv = css`
    padding: 40px;
    padding-left: 275px;
    width: 100%;
    min-width: 1250px;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;
    & > div:first-of-type {
        width: 100%;
        padding-bottom: 20px;
    }
    .left {
        display: flex;
        flex-direction: column;
        padding-right: 8px;
        margin-right: 30px;
    }
    .countBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        /* gap: 32px; */
        row-gap: 32px;
        & > div {
            width: 48%;
            margin-right: 10px;
        }
        a {
            display: block;
            width: 48%;
            margin-right: 10px;
        }
    }
    .right {
        & > div {
            padding: 16px;
            margin-bottom: 20px;
            border-radius: 8px;
            border: 1.5px solid ${Common.colors.gray200};
        }
        & > div:last-of-type {
            margin-bottom: 0;
        }
    }
    .devInfo {
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        color: #222;
        margin: 20px 0;

        p {
            font-size: 16px;
            margin: 10px 0;
            line-height: 1.7;
        }
    }
`;
export default Monitor;
