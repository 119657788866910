export interface ExpertAdvertisementFilterDto {
    page: number;
    limit: number;
    cursor?: string;
    types?: string[];
    searchType: string;
    searchValue?: string;
    searchStartDate?: string;
    searchEndDate?: string;
    sort: string;
    order: string;
}

export const initExpertAdvertisementFilter = {
    page: 1,
    limit: 10,
    searchType: "userName",
    sort: "expertBanner.created_at",
    order: "DESC",
};

export interface IExpertDetailCountDto {
    no: string;
    startDate?: string;
    endDate?: string;
}

export interface IExpertDetailCount {
    bannerCount: number;
    callCount: number;
    messageCount: number;
}

export interface IExpertManagerDto {
    expertUserNo: string;
    managerAdminNo?: number;
}

export interface IExpertDetail {
    expertUserNo: string;
    image: string;
    userId: string;
    joinedAt: string;
    managerType: string;
    managerAdminNo: string;
    managerName: string;
    bannerCount: number;
    callCount: number;
    messageCount: number;
    officeName: string;
    expertNo: number;
    managerUserNo: number;
}

export interface IExpertAdvertisement {
    bannerNo: number;
    expertUserNo: number;
    bannerType: string;
    name: string;
    phone: string;
    officeName: string;
    managerUserNo: number;
    managerAdminNo: number;
    managerName: string;
    bidType: string;
    itemSubId: string;
    itemAddress: string;
    itemUseDetail: string;
    totalCount: number;
    messageCount: number;
    callCount: number;
    bannerPeriod: string;
    bannerStatus: string;
    createdAt: string;
    itemStatus: string;
    isTicketCanceled: boolean;
    itemId: string;
}

export interface IExpertAdvertisementList {
    totalCount?: number;
    page: number;
    limit: number;
    cursor: string;
    list: IExpertAdvertisement[];
}

export interface IExpertSearchItemParams {
    cursor: {
        sortType: number;
        cursorValue?: string;
        cursorId?: string;
        updatedAt?: string;
    };
    limit?: number;
    bidType?: number;
    searchText: string;
}

export interface IExpertSearchItem {
    itemId: string;
    bidType: string;
    itemSubId: string;
    useDetail: string;
    address: string;
    status: string;
}

export interface IExpertSearchitemList {
    totalCnt: number;
    cursor?: {
        sortType: number;
        cursorId?: string;
        cursorValue?: string;
        updatedAt?: string;
    };
    totalCount: number;
    list: IExpertSearchItem[];
}

export interface IExpertInquiry {
    inquiryNo: number;
    itemId: number;
    bidType: string;
    itemSubId: string;
    useDetail: string;
    userName: string;
    userPhone: string;
    inquiryType: string;
    inquiryAt: string;
    address: string;
}

export interface IExpertInquiryList {
    totalCount: number;
    messageCount: number;
    callCount: number;
    list: IExpertInquiry[];
}

export interface IExpertInquiryListParams {
    inquiryType?: string;
    bannerNo: number;
    page?: number;
    limit?: number;
}
