import React from "react";
import { css } from "@emotion/react";
import { IconButton, Label } from "@src/components";
import {
    getPushCategoryType,
    getPushToType,
    PushCodeAndColors,
} from "@src/constants/PushStatus";

export const getColumns = ({
    navigate,
    setDeleteNo,
    setIsDelete,
    setIsList,
    setListNo,
}: any) => {
    return [
        {
            Header: "전송일시",
            accessor: "sendAt",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div
                        style={{
                            width: "180px",
                            fontSize: "13px",
                            textAlign: "center",
                        }}
                    >
                        {value.slice(0, -14)}
                    </div>
                );
            },
        },
        {
            Header: "전송유형",
            accessor: "toType",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        {getPushToType(value)}
                    </div>
                );
            },
        },
        {
            Header: "유형",
            accessor: "category",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "100px", textAlign: "center" }}>
                        {getPushCategoryType(value)}
                    </div>
                );
            },
        },
        {
            Header: "제목",
            accessor: "title",
            Cell: ({ cell: { value } }: any) => <div css={maxLow}>{value}</div>,
        },
        {
            Header: "내용",
            accessor: "contents",
            Cell: ({ cell: { value } }: any) => <div css={maxLow}>{value}</div>,
        },
        {
            Header: "링크",
            accessor: "link",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "inline-block",
                    }}
                >
                    <a
                        href={value}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "blue", wordBreak: "break-all" }}
                        css={maxLow}
                    >
                        {value}
                    </a>
                </div>
            ),
        },
        {
            Header: "발송",
            accessor: "messageCount",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "50px", textAlign: "center" }}>
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "오픈",
            accessor: "readCount",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "50px", textAlign: "center" }}>
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "오픈율",
            accessor: "readRate",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "50px", textAlign: "center" }}>
                        {value}%
                    </div>
                );
            },
        },
        {
            Header: "상태",
            accessor: "status",
            Cell: ({ cell: { value } }: any) => {
                const status = PushCodeAndColors.find((v) => v.code == value);
                const text = status?.value ?? "등록";
                const color = status?.color ?? "purpleBorder";
                return (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Label color={color} text={text} />
                    </div>
                );
            },
        },
        {
            Header: "관리",
            accessor: "viewControl",
            Cell: ({ cell: { value }, row }: any) => {
                const code = Number(row.original.status);
                const trashDisabled = code >= 400;
                const editDisabled = code >= 400;
                return (
                    <div
                        style={{
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="trash"
                            handleClick={() => {
                                setIsDelete({
                                    deleteNo: row.original.pushNo,
                                    isModalShow: true,
                                });
                                setDeleteNo(row.original.pushNo);
                            }}
                            isDisabled={trashDisabled}
                        />
                        <IconButton
                            type="edit"
                            handleClick={() => {
                                navigate(
                                    `/notify/push/update/${row.original.pushNo}`,
                                );
                            }}
                            isDisabled={editDisabled}
                        />
                    </div>
                );
            },
        },
        {
            Header: "수신인",
            accessor: "receivers",
            Cell: ({ cell: { value }, row }: any) => {
                const code = Number(row.original.status);
                const trashDisabled = code >= 400;
                const editDisabled = code >= 400;
                return (
                    <div
                        style={{
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="list"
                            handleClick={() => {
                                setIsList({
                                    No: row?.original.pushNo,
                                    isModalShow: true,
                                });
                                setListNo(row?.original.pushNo);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: "더보기",
            accessor: "viewMore",
            Cell: ({ cell: { value }, row }: any) => {
                const code = Number(row?.original?.status);
                const trashDisabled = code >= 400;
                const editDisabled = code >= 400;
                return (
                    <div
                        style={{
                            width: "80px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <IconButton
                            type="arrow"
                            handleClick={() => {
                                navigate(
                                    `/notify/push/detail/${row?.original?.pushNo}`,
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];
};
const maxLow = css`
    width: 300px;
    white-space: normal !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
`;
