import React, { Dispatch, SetStateAction } from "react";
import { IconButton, Label } from "@src/components";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { userExpertDto } from "./types";
import { ConvertPhoneNumber } from "@src/utils/units";

export const getColumns = (
    expertData: userExpertDto,
    setExpertData: Dispatch<SetStateAction<userExpertDto>>,
    setIsShowUserInfoModal: Dispatch<SetStateAction<boolean>>,
    setExpertName: Dispatch<SetStateAction<string>>,
) => {
    return [
        {
            Header: "회원번호",
            accessor: "no",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "이름",
            accessor: "name",
            Cell: ({ cell: { value }, row }: any) => {
                const userNo = row.original.no;
                return (
                    <div
                        style={{ width: "100px", textAlign: "center" }}
                        onClick={() => {
                            setExpertData({
                                ...expertData,
                                userNo,
                            });
                            setExpertName(value);
                            setIsShowUserInfoModal(false);
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "연락처",
            accessor: "phone",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "200px", textAlign: "center" }}>
                    {ConvertPhoneNumber(value)}
                </div>
            ),
        },

        {
            Header: "이메일",
            accessor: "email",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "생년월일",
            accessor: "birthdate",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "200px", textAlign: "center" }}>
                    {value?.slice(0, 4)}.{value?.slice(4, 6)}.
                    {value?.slice(6, 8)}
                </div>
            ),
        },
        {
            Header: "성별",
            accessor: "gender",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div style={{ width: "100px", textAlign: "center" }}>
                        {value === "0" ? "여성" : "남성"}
                    </div>
                );
            },
        },
        {
            Header: "가입일시",
            accessor: "createdAt",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div
                        style={{
                            width: "200px",
                            textAlign: "center",
                        }}
                    >
                        {ConvertDateTime(value)}
                    </div>
                );
            },
        },
        {
            Header: "정기구독 여부",
            accessor: "subscription",
            Cell: ({ cell: { value } }: any) => {
                return (
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        {value?.type === "none"
                            ? "미구독"
                            : value?.type === "paid"
                            ? "구독중-유료\n(" + value?.productName + ")"
                            : "구독중-무료\n(" + value?.productName + ")"}
                    </div>
                );
            },
        },
    ];
};
