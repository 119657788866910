import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import {
    ExpertAdvertisementFilterDto,
    IExpertAdvertisementList,
    IExpertInquiryList,
    IExpertInquiryListParams,
    IExpertSearchitemList,
    IExpertSearchItemParams,
} from "@src/pages/expert/Advertisement/types";
import { AxiosError } from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";

const fetchGetExpertsAdList = async (params: ExpertAdvertisementFilterDto) => {
    const response = await axiosInstance.get(
        `${axiosPath.userExpert}/banners`,
        { params },
    );

    return response.data;
};

export const useGetExpertsAdList = (
    filters: ExpertAdvertisementFilterDto,
    enabled: boolean = false,
) => {
    return useQuery<IExpertAdvertisementList>(
        ["getExpertsAdList", filters],
        () => fetchGetExpertsAdList(filters),
        { enabled },
    );
};

export const useEndAdvertisment = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}/terminateBanner`;
    return useMutation(
        async (data: { expertUserNo: string; bannerNo: string }) => {
            const params = {
                expertUserNo: data.expertUserNo,
            };
            try {
                const res = await axiosInstance.put(
                    `${url}/${data.bannerNo}`,
                    params,
                );
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export const useDeleteAdvertisment = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}/deleteBanner`;
    return useMutation(
        async (data: { expertUserNo: string; bannerNo: string }) => {
            try {
                const res = await axiosInstance.delete(
                    `${url}/${data.bannerNo}`,
                    {
                        params: {
                            expertUserNo: data.expertUserNo,
                        },
                    },
                );
                return res.data;
            } catch (Err) {
                console.log("deleteError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export const useRestoreAdvertisment = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}/restoreBanner`;
    return useMutation(
        async (data: { expertUserNo: string; bannerNo: string }) => {
            const params = {
                expertUserNo: data.expertUserNo,
            };
            try {
                const res = await axiosInstance.put(
                    `${url}/${data.bannerNo}`,
                    params,
                );
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export const useRestoreTicket = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}/restoreTicket`;
    return useMutation(
        async (data: { expertUserNo: string; bannerNo: string }) => {
            const params = {
                expertUserNo: data.expertUserNo,
            };
            try {
                const res = await axiosInstance.put(
                    `${url}/${data.bannerNo}`,
                    params,
                );
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const fetchGetSearchItem = async (body: IExpertSearchItemParams) => {
    const response = await axiosInstance.post(
        `${axiosPath.userExpert}/searchItem`,
        body,
    );
    return response.data;
};

export const useGetSearchItem = (
    params: IExpertSearchItemParams,
    enabled: boolean = false,
) => {
    return useInfiniteQuery<IExpertSearchitemList>({
        queryKey: "getSearchItem",
        queryFn: ({ pageParam }) =>
            fetchGetSearchItem({
                ...params,
                cursor: pageParam ?? params.cursor,
            }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage = lastPage.list.length ? lastPage.cursor : undefined;
            return newPage;
        },
        enabled,
    });
};

export const useChangeExpertBanner = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}/expertBanner`;
    return useMutation(
        async (data: any) => {
            const body = {
                itemId: data.itemId,
                startDate: data.startDate,
                endDate: data.endDate,
            };
            try {
                const res = await axiosInstance.put(
                    `${url}/${data.bannerNo}`,
                    body,
                );
                return res.data;
            } catch (Err) {
                console.log("updateError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

export const useCreatedExpertBanner = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    const url = `${axiosPath.userExpert}/expertBanner`;
    return useMutation(
        async (data: any) => {
            const body = {
                userNo: data.userNo,
                itemId: data.itemId,
                startDate: data.startDate,
                endDate: data.endDate,
            };
            try {
                const res = await axiosInstance.post(`${url}`, body);
                return res.data;
            } catch (Err) {
                console.log("postError>>", Err);
                throw Err;
            }
        },
        {
            onSuccess: onSuccess,
            onError: onError,
        },
    );
};

const fetchGetInquiryList = async (body: IExpertInquiryListParams) => {
    const response = await axiosInstance.post(
        `${axiosPath.userExpert}/getInquiryList`,
        body,
    );
    return response.data;
};

export const useGetInquiryList = (
    params: IExpertInquiryListParams,
    enabled: boolean = false,
) => {
    return useInfiniteQuery<IExpertInquiryList>({
        queryKey: "getInquiryList",
        queryFn: ({ pageParam = 1 }) =>
            fetchGetInquiryList({
                ...params,
                page: pageParam,
            }),
        getNextPageParam: (lastPage: any, pages: any) => {
            const newPage =
                (params.inquiryType
                    ? params.inquiryType === "call"
                        ? pages[0].totalCount
                        : pages[0].messageCount
                    : pages[0].totalCount) >
                pages.length * 10
                    ? pages.length + 1
                    : undefined;
            return newPage;
        },
        enabled,
    });
};
