import { createSlice } from "@reduxjs/toolkit";
import { BuyEstateSaleStateType } from "@src/pages/product/types";

const sampleData: BuyEstateSaleStateType = {
    table: [
        {
            id: "0",
            type: "건물",
            locationArea: "신도림동 646 (23층 중 10층)",
            approvedDate: "2011.12.06",
            occupied: "105.785㎡(32평)",
            typeInfo: "도시형생활주택(원룸형)",
            bidPrice: "45500000",
        },
        {
            id: "1",
            type: "토지",
            locationArea: "대지권",
            approvedDate: "",
            occupied: "573.1㎡",
            typeInfo: "",
            bidPrice: "19500000",
        },
    ],
    summary:
        "1. 구분건물감정평가요항표 1) 위치 및 주위환경 본건은 서울특별시 구로구 신도림동 소재 1호선 및 2호선&quot;신도림역&quot; 북서측 인근에 위치하며, 부근은 아파트단지, 상업용건물, 공공시설 등의 소재하는지",
    addInfo:
        "지분매각임. 공유자 우선매수권 행사 제한 있음(공유자 우선매수신청을 한 공유자는 매각기일 종결 전까지 보증금을 제공하여야 유효하며 매수신청권리를 행사하지 않는 경우에 차회기일부터는 우선권이 없음)",
};

const initialState = sampleData;

export const auctionProductSlice = createSlice({
    name: "auctionProduct",
    initialState,
    reducers: {
        createAuctionProduct: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateAuctionProduct: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createAuctionProduct, updateAuctionProduct } =
    auctionProductSlice.actions;

export default auctionProductSlice.reducer;
