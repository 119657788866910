import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Title, Button, ConfirmModal, PageTable } from "@src/components";
import { useNavigate } from "react-router-dom";
import { useNoticeFetchData } from "@src/hooks/queries/useNotice";
import { useDeleteNotice } from "@src/hooks/queries/useNoticeDetail";
import { NoticeType } from "@src/types/NoticeType";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { getColumns } from "./NoticeListColumns";

function NoticeList() {
    const navigate = useNavigate();
    const [data, setData] = useState<NoticeType[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const dispatch = useDispatch();
    const [params, setParams] = useState({ page: 1, limit: 10 });
    const {
        isLoading,
        data: noticeData = { result: [], total: 0 },
        isError,
        refetch,
    } = useNoticeFetchData(params.page !== 0 ? params : {});

    useEffect(() => {
        if (isError) {
            // error
        } else {
            if (!isLoading && !isError && noticeData) {
                setData(noticeData.result);
                if (params.page === 1) {
                    setTotalCount(noticeData.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [noticeData.result]);

    const { mutate: deleteMutate } = useDeleteNotice();
    const onDelete = (id: number) => {
        setIsModalShow((prev) => !prev);
        deleteMutate(id, {
            onSuccess: () => {
                setTotalCount(totalCount - 1);
            },
            onError: (error) => {
                console.log(error);
            },
            onSettled: () => refetch(),
        });
    };
    const [deleteNo, setDeleteNo] = useState(0);
    const [isModalShow, setIsModalShow] = useState(false);
    const columns = getColumns({ navigate, setDeleteNo, setIsModalShow });

    return (
        <div css={listStyle}>
            <Title title="공지 사항" />
            <div css={searchDiv}>
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/help/notice/create");
                    }}
                    label="신규 등록"
                    size="medium"
                />
            </div>
            <div css={tableDiv}>
                <PageTable
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                        hiddenColumns: ["noticeNo"],
                    }}
                    columns={columns}
                    data={data}
                    totalCount={totalCount}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setParams({ page, limit: 10 });
                    }}
                    FixedHeight={700}
                    showTotalCount={true}
                    forcePage={params.page === 1}
                ></PageTable>
            </div>
            <ConfirmModal
                buttonText="삭제"
                handleCancel={() => setIsModalShow((prev) => !prev)}
                handleOk={() => {
                    onDelete(deleteNo);
                    setIsModalShow(false);
                }}
                isModalShow={isModalShow}
            >
                <>정말 삭제하시겠습니까?</>
            </ConfirmModal>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const searchDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        padding: 10px 0;
        button:nth-of-type(1) {
            margin-right: 15px;
        }
    }
    .noticeContent {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .noticeTitle {
        text-align: left;
    }
`;

export default NoticeList;
