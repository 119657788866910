import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import AWS from "aws-sdk";
import ServerChart from "@src/components/chart/ServerChart";

function APIHealthCheck() {
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_BACKEND_USER_ACCESS_KEY,
        secretAccessKey:
            process.env.REACT_APP_AWS_BACKEND_USER_ACCESS_SECRET_KEY,
    });
    const [lastTimestamp, setLastTimestamp] = useState("");
    const [apiVersion, setApiVersion] = useState("");
    const [adminVersion, setAdminVersion] = useState("");
    const [timeInterval, setTimeInterval] = useState(500);
    const [apiState, setApiState] = useState([] as any);

    useEffect(() => {
        const timerId = setInterval(() => {
            AWS.config.update({
                region: "us-east-1",
            });

            const cw = new AWS.CloudWatch({ apiVersion: "2010-08-01" });
            const today = new Date();
            const params = {
                StartTime: new Date(today.getTime() - 600000),
                EndTime: new Date(today.getTime()),
                MetricDataQueries: [
                    {
                        Id: "v1",
                        Label: "HealthCheckStatus",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/Route53",
                                MetricName: "HealthCheckStatus",
                                Dimensions: [
                                    {
                                        Name: "HealthCheckId",
                                        Value: "9370f2e3-5aab-4970-8552-41edea37201f",
                                    },
                                ],
                            },
                            Period: 1,
                            Stat: "Average",
                        },
                    },
                    {
                        Id: "v2",
                        Label: "HealthCheckStatus",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/Route53",
                                MetricName: "HealthCheckStatus",
                                Dimensions: [
                                    {
                                        Name: "HealthCheckId",
                                        Value: "e3bed65f-c553-4789-a656-76ab19c7b8c7",
                                    },
                                ],
                            },
                            Period: 1,
                            Stat: "Average",
                        },
                    },
                    {
                        Id: "admin",
                        Label: "HealthCheckStatus",
                        MetricStat: {
                            Metric: {
                                Namespace: "AWS/Route53",
                                MetricName: "HealthCheckStatus",
                                Dimensions: [
                                    {
                                        Name: "HealthCheckId",
                                        Value: "7ddcb987-d874-4727-a98e-cb6c666959ff",
                                    },
                                ],
                            },
                            Period: 1,
                            Stat: "Average",
                        },
                    },
                ],
            };

            cw.getMetricData(params, (err, data) => {
                const arr = data.MetricDataResults || [];

                setApiState([
                    {
                        name: "API-V1",
                        values: arr[0].Values,
                        state: arr[0].Values && arr[0].Values[0] !== 0,
                    },
                    {
                        name: "API-V2",
                        values: arr[1].Values,
                        state: arr[1].Values && arr[1].Values[0] !== 0,
                    },
                    {
                        name: "ADMIN",
                        values: arr[2].Values,
                        state: arr[2].Values && arr[2].Values[0] !== 0,
                    },
                ]);
                setLastTimestamp(
                    new Date(new Date().getTime() + 32400000)
                        .toISOString()
                        .replace("T", " ")
                        .split(".")[0],
                );
                setTimeInterval(60 * 1000);
            });
        }, timeInterval);

        return () => clearInterval(timerId);
    }, [timeInterval]);

    useEffect(() => {
        AWS.config.update({
            region: "ap-northeast-2",
        });

        const ecs = new AWS.ECS();
        ecs.listTaskDefinitions(
            {
                familyPrefix: "AppApi",
                status: "ACTIVE",
                sort: "DESC",
                maxResults: 1,
            },
            (err, data) => {
                if (err) console.log(err, err.stack);

                setApiVersion(
                    (data.taskDefinitionArns &&
                        data.taskDefinitionArns[0].toString()) ||
                        "",
                );
            },
        );
        ecs.listTaskDefinitions(
            {
                familyPrefix: "AdminApi",
                status: "ACTIVE",
                sort: "DESC",
                maxResults: 1,
            },
            (err, data) => {
                if (err) console.log(err, err.stack);

                setAdminVersion(
                    (data.taskDefinitionArns &&
                        data.taskDefinitionArns[0].toString()) ||
                        "",
                );
            },
        );
    }, []);

    const StateBox = ({ state }: any) => {
        const flag =
            state?.values.length === undefined || state?.values.length === 0
                ? 0
                : state?.state
                ? 1
                : -1;
        return (
            <>
                <div
                    css={apibox}
                    className={
                        flag === 0 ? "normal" : flag === 1 ? "ok" : "fail"
                    }
                >
                    <small>{state?.name || "SERVER LISTING..."}</small>
                    <span>
                        {flag === 0
                            ? "확인중.."
                            : flag === 1
                            ? "정상"
                            : "비정상"}
                    </span>
                    <p>
                        {flag === 0
                            ? "Health Check -ing..."
                            : flag === 1
                            ? "Health Check OK !!"
                            : "Health Check FAIL !!"}
                    </p>
                </div>
            </>
        );
    };

    return (
        <div css={subChartDiv}>
            <p css={titleP}>실시간 API 상태 ({lastTimestamp})</p>
            <div className="mainBox">
                <StateBox state={apiState[0]} />
                <StateBox state={apiState[1]} />
                <StateBox state={apiState[2]} />
            </div>
        </div>
    );
}

const apibox = css`
    width: 33%;
    height: 150px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    text-align: center;
    transition: all 1s ease;

    &.fail {
        background: #e96479;
        color: #fff;
        animation: scaleBounceFail 1s infinite;
    }
    &.normal {
        background: #eee;
    }
    &.ok {
        background: #ccffd1;
        animation: scaleBounce 2s infinite;
    }
    small {
        display: block;
        margin-bottom: 25px;
        font-size: 12px;
        height: 12px;
        text-align: left;
    }
    span {
        font-size: 40px;
    }
    p {
        margin-top: 10px;
        font-size: 11px;
    }

    @keyframes scaleBounce {
        0% {
            scale: 1;
        }
        50% {
            scale: 1.01;
        }
        100% {
            scale: 1;
        }
    }

    @keyframes scaleBounceFail {
        0% {
            scale: 1;
        }
        50% {
            scale: 1.1;
        }
        100% {
            scale: 1;
        }
    }
`;

const subChartDiv = css`
    height: fit-content;
    max-height: 450px;
    margin-bottom: 20px;
    canvas {
        height: 330px !important;
    }

    .mainBox {
        display: flex;
        flex-direction: row;
        text-align: center;
        font-weight: bold;
    }
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
`;

export default APIHealthCheck;
