import React from "react";

export const getColumns = () => {
    return [
        {
            Header: "이름",
            accessor: "adminName",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "80px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "연락처",
            accessor: "adminPhone",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "120px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "이메일",
            accessor: "adminId",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "180px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "직위",
            accessor: "adminRank",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ width: "180px", textAlign: "center" }}>
                    {value}
                </div>
            ),
        },
        // {
        //     Header: "회원 연동",
        //     accessor: "userNo",
        //     Cell: ({ cell: { value } }: any) => {
        //         console.log(value);
        //         return (
        //             <div style={{ width: "180px" }}>
        //                 {value ? "연동" : "미연동"}
        //             </div>
        //         );
        //     },
        // },
    ];
};
