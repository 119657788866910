import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import PushForm from "./PushForm";
import { PushType } from "@src/pages/notify/types";
import { Title, DropDown, Button, ConfirmModal } from "@src/components";
import { arrayToDropdown } from "@src/utils/dropdownUtil";
import {
    PushCodeType,
    PushCategoryType,
    PushCodeAndColors,
} from "@src/constants/PushStatus";
import { Common } from "@src/styles/Common";
import { ConvertDate } from "@src/utils/dateUtil";
import {
    usePushDetailData,
    useUpdatePushData,
} from "@src/hooks/queries/usePushDetail";
import { useNavigate, useParams } from "react-router-dom";
import { isNotEmpty } from "@src/utils/textUtil";
import { getKeyByValue } from "@src/utils/objectUtil";
import { usePushList } from "@src/hooks/queries/usePush";
import BlockPageModal from "@src/pages/popup/BlockPageModal";
import { Constants } from "@src/constants/Constants";

interface IPushDetail {
    isUpdate: boolean;
}

const PushDetail = ({ isUpdate = false }: IPushDetail) => {
    const {
        REGISTER,
        TEST_PREV,
        TEST_ING,
        TEST_FAIL,
        TEST_SUCCESS,
        PUSH_APPLY,
        PUSH_ING,
        PUSH_FAIL,
        PUSH_SUCCESS,
    } = Constants;
    const { id = "0" } = useParams();
    const navigate = useNavigate();
    const [showBlockModal, setShowBlockModal] = useState<boolean>(false);
    const dropList = arrayToDropdown([
        REGISTER,
        TEST_PREV,
        TEST_ING,
        TEST_FAIL,
        TEST_SUCCESS,
        PUSH_APPLY,
        PUSH_ING,
        PUSH_FAIL,
        PUSH_SUCCESS,
    ]);
    const [initData, setInitData] = useState<PushType>({
        category: "",
        userTypes: [],
        subscriptionTypes: [],
        receivers: [],
        testers: [],
        reservationAt: ConvertDate(
            new Date().setHours(0, 0, 0),
            "yyyy.MM.dd HH:mm:ss",
        ),
        link: "",
        title: "",
        contents: "",
        pushStatus: "",
        edit: false,
        pushNo: 0,
        status: 100,
        originReservationAt: "",
    });
    const [pushStatusUpdate, setPushStatusUpdate] = useState("");
    const [isModalShow, setIsModalShow] = useState(false);
    const {
        isLoading,
        data,
        isError,
        refetch: singleRefetch,
    } = usePushDetailData(id, true);
    useEffect(() => {
        if (!isLoading && !isError) {
            if (data) {
                setInitData(data);
                setPushStatusUpdate(data?.pushStatus || "등록");
                setShowBlockModal(false);
            } else {
                setShowBlockModal(true);
            }
        }
    }, [isLoading, isError, data]);
    const [updateData, setUpdateData] = useState<PushType>({
        reservationAt: "2022.07.28",
        edit: false,
        pushNo: 0,
        status: 100,
        originReservationAt: "",
        receivers: [],
        testers: [],
        userTypes: [],
        subscriptionTypes: [],
    });
    const [disabled, setDisabled] = useState(false);
    const changeValidation = ({
        userTypes,
        subscriptionTypes,
        receivers,
        testers,
        link,
        title,
        contents,
        edit,
    }: any) => {
        if (
            (userTypes.length ||
                subscriptionTypes.length ||
                receivers.length) &&
            isNotEmpty(link) &&
            testers.length &&
            isNotEmpty(title) &&
            isNotEmpty(contents)
        ) {
            // 버튼 활성화
            return edit ? false : true;
        } else {
            return true;
        }
    };

    const { mutate: updateMutate } = useUpdatePushData(id);
    const { refetch: listRefetch } = usePushList({ limit: 10, page: 1 });

    const diffChecker = (updateData: PushType) => {
        let params: any = {};
        // 변경값이 있는지 비교

        for (let value of Object.keys(updateData)) {
            let v = value as keyof PushType;
            if (initData[v] !== updateData[v]) {
                const data = updateData[v];

                if (v == "category") {
                    params.category = Number(data) || 0;
                }
                if (v == "title") {
                    params.msg_title = data?.toString() || "";
                }
                if (v == "contents") {
                    params.msg_body = data?.toString() || "";
                }
                if (v == "link") {
                    params.msg_click_action = data?.toString() || "";
                }
                if (v == "pushStatus") {
                    params.status = Number(
                        PushCodeAndColors.find((v) => v.value === data)?.code,
                    );
                }
                if (v === "reservationAt") {
                    params.reservation_at = data?.toString() || "";
                }
                if (v === "receivers") {
                    params.to_go = data?.toString();
                }
                if (v === "testers") {
                    params.to_tester = data?.toString();
                }
                params.to_type = "user_no";
            }
        }
        return params;
    };
    const [errorMessage, setErrorMessage] = useState("");
    const handleSubmit = () => {
        const params = diffChecker(updateData);
        updateMutate(params, {
            onSuccess: (data) => {
                navigate("/notify/push");
            },
            onError: (error: any) => {
                setErrorMessage(
                    "전송일시는 현재 시간에서 최소 30분 뒤부터 가능합니다. 전송일시를 확인해주세요.",
                );
            },
            onSettled: () => {
                listRefetch();
                singleRefetch();
            },
        });
    };

    const handleOk = () => {
        handleSubmit();
        setIsModalShow((prev) => !prev);
    };

    useEffect(() => {
        const isUpdate = [
            PushCodeType.Register,
            PushCodeType.TestReady,
            PushCodeType.TestSuccess,
            PushCodeType.TestFailed,
        ].includes(initData.status);

        if (isUpdate) {
            const isChange = changeValidation({ ...updateData });
            setDisabled(isChange);
        } else {
            setDisabled(true);
        }
    }, [initData.status, updateData]);

    const handleClick = () => {
        if (isUpdate) {
            setIsModalShow((prev) => !prev);
        }
    };

    return (
        <div css={detailDiv}>
            <PushForm
                isUpdate={true}
                title={`PUSH 전송 ${isUpdate ? "수정" : "상세"}`}
                initData={initData}
                handleUpdate={setUpdateData}
                isDetail={false}
                isDisabledCheckbox={true}
            />
            <div css={optionDiv}>
                <Title title="상태" size={"small"} />
                <div>
                    <DropDown
                        data={dropList}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setPushStatusUpdate(value);
                            setUpdateData({ ...updateData, edit: true });
                        }}
                        defaultValue={pushStatusUpdate}
                        border={isUpdate}
                        placeholder={""}
                        isDisabled={true}
                    />
                </div>
            </div>
            {!isUpdate && (
                <p className="notifyBox">
                    알림 등록후, 테스트 발송이 완료 되어야 예약된 시간에
                    정상적으로 발송 가능합니다.
                    <br />
                    테스트를 실행 하지 않았거나 테스트 실패시 하단의 테스트발송
                    버튼을 눌러 테스트를 진행해주세요
                </p>
            )}
            <div className="buttonBox">
                <p>{errorMessage}</p>
                <Button
                    label={"수정"}
                    color={"gd"}
                    size="medium"
                    isDisabled={disabled}
                    handleClick={handleClick}
                    deleteBtn={false}
                    defaultValue={""}
                />
            </div>
            <ConfirmModal
                isModalShow={isModalShow}
                handleOk={handleOk}
                handleCancel={() => {
                    setIsModalShow((prev) => !prev);
                }}
                buttonText="수정"
            >
                수정하시겠습니까?
            </ConfirmModal>
            <BlockPageModal isShowModal={showBlockModal} url="/notify/push" />
        </div>
    );
};

const detailDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .notifyBox {
        ${Common.textStyle.body4_R12}
        margin: 10px 0;
        color: ${Common.colors.gray500};
        text-align: right;
    }
    .buttonBox {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        button {
            margin-left: 30px;
        }
        p {
            color: ${Common.colors.error};
            ${Common.textStyle.body4_R12}
        }
    }
`;
const optionDiv = css`
    padding-left: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    & > div:first-of-type {
        width: 100px;
    }
    select:disabled {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
`;

export default PushDetail;
