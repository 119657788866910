import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
    Input,
    ImageListView,
    RangeCalendar,
    ToggleButton,
    Button,
} from "@src/components";
import { Common } from "@src/styles/Common";
import { validChecker } from "@src/utils/validationCheck";
import { ConvertDate } from "@src/utils/dateUtil";
import { dateParse } from "@src/utils/dateUtil";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BannerIsOpenSelector from "./BannerIsOpenSelector";

interface IBannerForm {
    exposure: string;
    initData: any;
    passChildData: Function;
    isUpdate: boolean;
}
function BannerForm({
    exposure,
    initData,
    passChildData,
    isUpdate = false,
}: IBannerForm) {
    const [updateData, setUpdateData] = useState(initData);
    const [errorMessage, setErrorMessage] = useState({
        orderError: "",
        linkError: "",
        dateError: false,
        notInputError: "",
    });
    useEffect(() => {
        updateData.bannerImage === ""
            ? setAllFiles([])
            : setAllFiles([{ data_url: updateData.bannerImage }]);

        passChildData(updateData);
    }, [updateData]);
    useEffect(() => {
        updateData.bannerLink === ""
            ? setAllLinkFiles([])
            : setAllLinkFiles([{ data_url: updateData.bannerLink }]);

        passChildData(updateData);
    }, [updateData]);
    useEffect(() => {
        if (isUpdate) {
            setUpdateData(initData);
        }
    }, [initData]);

    const [allFiles, setAllFiles] = useState<any>([
        { data_url: updateData.bannerImage },
    ]);
    const [allLinkFiles, setAllLinkFiles] = useState<any>([
        { data_url: updateData.bannerLink },
    ]);
    useEffect(() => {
        updateData.bannerImage === ""
            ? setAllFiles([])
            : setAllFiles([{ data_url: updateData.bannerImage }]);
    }, [updateData.bannerImage]);
    useEffect(() => {
        updateData.bannerLink === ""
            ? setAllLinkFiles([])
            : setAllLinkFiles([{ data_url: updateData.bannerLink }]);
    }, [updateData.bannerLink]);
    useEffect(() => {
        //에러메세지가 한 개라도 있으면 수정 버튼 비활성화
        if (
            errorMessage.orderError ||
            errorMessage.linkError ||
            errorMessage.dateError
        ) {
            setUpdateData({
                ...updateData,
                edit: false,
            });
        }
    }, [errorMessage]);
    const onChangeRangeDate = (
        { startDate, endDate }: any,
        isError: boolean | undefined,
    ) => {
        const isStartEqual =
            initData.startDate !==
            ConvertDate(startDate, "yyyy-MM-dd HH:mm:ss");
        const isEndEqual =
            initData.endDate !== ConvertDate(endDate, "yyyy-MM-dd HH:mm:ss");

        if (isStartEqual || isEndEqual) {
            setUpdateData({
                ...updateData,
                startDate: ConvertDate(startDate, "yyyy-MM-dd HH:mm:ss"),
                endDate: ConvertDate(endDate, "yyyy-MM-dd HH:mm:ss"),
                edit: true,
            });
        }
        isError !== undefined &&
            setErrorMessage({
                ...errorMessage,
                dateError: isError,
            });
    };
    const dateOrTimeStamp = (dateAny: any) => {
        if (typeof dateAny === "number") {
            return dateAny;
        }

        return dateAny ? dateParse(dateAny, "yyyy-MM-dd HH:mm:ss") : null;
    };

    const handleCopyClipBoard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success("복사 성공");
        } catch (err) {
            toast.error("복사 실패");
        }
    };

    return (
        <div css={formDiv}>
            <ul>
                <li>
                    <span>노출 여부</span>
                    {/* <ToggleButton
                        name={"bannerView"}
                        handleToggle={(name, checked) => {
                            setUpdateData({
                                ...updateData,
                                ...{ bannerView: checked, edit: true },
                            });
                            setErrorMessage({
                                ...errorMessage,
                                notInputError: "",
                            });
                        }}
                        value={updateData.bannerView}
                    /> */}
                    <BannerIsOpenSelector
                        exposure={exposure}
                        updateData={updateData}
                        handleFunction={(checked) => {
                            setUpdateData({
                                ...updateData,
                                ...{ bannerView: checked, edit: true },
                            });
                            setErrorMessage({
                                ...errorMessage,
                                notInputError: "",
                            });
                        }}
                    />
                </li>
                <li>
                    <span>노출 순서</span>
                    <div>
                        <Input
                            type="number"
                            label={""}
                            placeholder={"1~1000 이하의 숫자로 입력해주세요"}
                            size={"medium"}
                            handleChange={(e) => {
                                const { value } = e.target;
                                const v = +value;
                                setUpdateData({
                                    ...updateData,
                                    ...{ order: v, edit: true },
                                });
                                // if (v < 1000 && v > 0) {
                                //     setErrorMessage({
                                //         ...errorMessage,
                                //         orderError: "",
                                //     });
                                // } else if (v > 1000) {
                                //     setErrorMessage({
                                //         ...errorMessage,
                                //         orderError:
                                //             "노출 순서는 1000을 넘을 수 없습니다.",
                                //     });
                                // } else if (v < 1) {
                                //     setErrorMessage({
                                //         ...errorMessage,
                                //         orderError:
                                //             "노출 순서는 1보다 작을 수 없습니다.",
                                //     });
                                // }
                            }}
                            isDisabled={false}
                            errorMessage={errorMessage.orderError}
                            name={"order"}
                            value={updateData.order || ""}
                        />
                    </div>
                </li>
                <li>
                    <span>배너 이미지</span>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            justifyContent: "center",
                        }}
                    >
                        <ImageListView
                            allFiles={allFiles}
                            isDisabled={false}
                            handleChange={(data: string[]) => {
                                setUpdateData({
                                    ...updateData,
                                    ...{
                                        bannerImage: data?.[0] || "",
                                        imageFileCount: data?.[0] ? 1 : 0,
                                        edit: true,
                                    },
                                });
                                setErrorMessage({
                                    ...errorMessage,
                                    notInputError: "",
                                });
                            }}
                            maxCount={1}
                        />
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="주소를 입력해주세요."
                                value={updateData.bannerImage}
                                size={"medium"}
                                handleChange={(e) => {
                                    const { value } = e.target;
                                    setUpdateData({
                                        ...updateData,
                                        ...{
                                            bannerImage: value,
                                            imageFileCount: value?.[0] ? 1 : 0,
                                            edit: true,
                                        },
                                    });
                                }}
                                name={"bannderImageInput"}
                                errorMessage={errorMessage.linkError}
                            />
                            <Button
                                label="복사"
                                color="purple"
                                size="small"
                                isDisabled={false}
                                handleClick={() =>
                                    handleCopyClipBoard(updateData.bannerImage)
                                }
                                styles={copyButton}
                            />
                        </div>
                    </div>
                </li>
                <li>
                    <span>연결 링크</span>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            justifyContent: "center",
                        }}
                    >
                        <ImageListView
                            allFiles={allLinkFiles}
                            isDisabled={false}
                            handleChange={(data: string[]) => {
                                setUpdateData({
                                    ...updateData,
                                    ...{
                                        bannerLink: data?.[0] || "",
                                        imageFileCount: data?.[0] ? 1 : 0,
                                        edit: true,
                                    },
                                });
                                setErrorMessage({
                                    ...errorMessage,
                                    notInputError: "",
                                });
                            }}
                            maxCount={1}
                        />
                        <div style={{ display: "flex" }}>
                            <Input
                                placeholder="URL을 입력해주세요."
                                value={updateData.bannerLink || ""}
                                size={"medium"}
                                handleChange={(e) => {
                                    const { value } = e.target;
                                    setUpdateData({
                                        ...updateData,
                                        ...{ bannerLink: value, edit: true },
                                    });
                                    const { errMsg: maxLengthMessage } =
                                        validChecker("maxLength", value, 1000);
                                }}
                                name={"linkInput"}
                                errorMessage={errorMessage.linkError}
                            />
                            <Button
                                label="복사"
                                color="purple"
                                size="small"
                                isDisabled={false}
                                handleClick={() =>
                                    handleCopyClipBoard(updateData.bannerLink)
                                }
                                styles={copyButton}
                            />
                        </div>
                    </div>
                </li>
                <li>
                    <span>게시 일시</span>
                    <ul css={calendarWrapperDiv}>
                        <li>
                            <RangeCalendar
                                setRangeDate={{
                                    startDate: dateOrTimeStamp(
                                        updateData.startDate,
                                    ),
                                    endDate: dateOrTimeStamp(
                                        updateData.endDate,
                                    ),
                                }}
                                getUserRangeDate={(date, isError) =>
                                    onChangeRangeDate(date, isError)
                                }
                                timePicker={true}
                            />
                        </li>
                        <li>
                            <ul css={calendarDiv}>
                                <li>
                                    <span>부터</span>
                                </li>
                                <li>
                                    <span>까지</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <span>테스터</span>
                    <Input
                        placeholder="테스터를 입력해주세요."
                        value={updateData.testers}
                        size={"medium"}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setUpdateData({
                                ...updateData,
                                ...{ testers: value, edit: true },
                            });
                        }}
                        name={"testersInput"}
                        errorMessage={errorMessage.notInputError}
                    />
                </li>
            </ul>
            <ToastContainer />
        </div>
    );
}

const formDiv = css`
    padding-top: 40px;

    & > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        li {
            width: 100%;
            ${Common.textStyle.title6_R14}
            margin-bottom: 27px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
        span {
            display: inline-block;
            width: 120px;
            min-width: 120px;
        }
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const calendarWrapperDiv = css`
    display: flex;
    flex-direction: row;

    .vertical {
        margin-right: 20px;
        height: 100%;
        justify-content: flex-start;
        gap: 0 !important;
        & > div {
            height: 50%;
            & > div {
                margin-top: 12px;
                & > input {
                    padding-bottom: 13px;
                    background-position: right 8px top 3px;
                }
            }
        }
    }
    & > li:nth-of-type(2) {
        ul > li {
            align-items: center;
            div {
                height: 100%;
                margin-right: 20px;

                select {
                    height: 100%;
                }
            }
        }
    }
    & > li:nth-of-type(1) {
        > div:nth-of-type(1) {
            flex-wrap: wrap;
            width: 175px;
            span {
                display: block;
            }
        }
        > div:nth-of-type(2) {
            position: relative;
            p {
                width: 250px;
                position: absolute;
                left: -170px;
                bottom: -90px;
            }
        }
    }
`;

const calendarDiv = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    & > li {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        height: 50px !important;
        margin-bottom: 0px !important;
        &:last-of-type {
            padding-top: 31px;
        }
    }
`;

const copyButton = css`
    margin-left: 20px;
`;

export default BannerForm;
