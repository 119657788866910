import React, { useState, useEffect, ChangeEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Calendar, Input, Title } from "@src/components";
import { ItemInformationProps } from "@src/interfaces/product/ItemInformationContainer.interface";
import { ConvertPrice } from "@src/utils/units";
import { useGetItemInformation } from "@src/hooks/queries/product/useItemInformation";

interface IItemInformation {
    isEdit: boolean;
}
const ItemInformationContainer = ({ isEdit = false }: IItemInformation) => {
    const PRODUCT_NO = "20210130088995-1";
    const [initData, setInitData] = useState<ItemInformationProps>({
        buildingName: "",
        type: "",
        highFloor: "",
        rowFloor: "",
        floorAreaRatio: 0,
        buildingToLandRatio: 0,
        mainUse: "",
        detailUse: "",
        approveDate: "",
        buildingArea: 0,
        buildingLandArea: 0,
        totalFloorArea: 0,
        address: "",
        ownerType: "",
        landArea: 0,
        landName: "",
        ownerUpdateDate: "",
        ownerUpdateReason: "",
        specialPurposeArea: "",
        landHigh: "",
        officialPrice: 0,
        landShape: "",
        dataDate: "",
        road: "",
        currentLandUse: "",
    });

    const [updateData, setUpdateData] =
        useState<ItemInformationProps>(initData);
    const { isLoading, isError, data } = useGetItemInformation(PRODUCT_NO);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setInitData(data);
        }
    }, [isLoading, isError, data]);
    useEffect(() => {
        setUpdateData(initData);
    }, [initData]);
    const {
        buildingName,
        type,
        highFloor,
        rowFloor,
        floorAreaRatio,
        buildingToLandRatio,
        mainUse,
        detailUse,
        approveDate,
        buildingArea,
        buildingLandArea,
        totalFloorArea,
        address,
        ownerType,
        landArea,
        landName,
        ownerUpdateDate,
        ownerUpdateReason,
        specialPurposeArea,
        landHigh,
        officialPrice,
        landShape,
        dataDate,
        road,
        currentLandUse,
    } = updateData;
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdateData({
            ...updateData,
            [name]: value,
        });
    };
    return (
        <div css={isEdit && ItemInformationDiv}>
            <Title title="건물정보" size="small" />
            <ul css={ItemInformationUl}>
                <li>
                    <Input
                        label={"건물명"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="buildingName"
                        value={buildingName}
                        border={isEdit}
                    />
                </li>
                <li>
                    <span>지하층/지상층</span>
                    {isEdit ? (
                        <>
                            <Input
                                label={""}
                                placeholder="-"
                                type="text"
                                size="default"
                                handleChange={handleInputChange}
                                isDisabled={!isEdit}
                                errorMessage={""}
                                name="rowFloor"
                                value={rowFloor}
                                border={isEdit}
                            />
                            <Input
                                label={""}
                                placeholder="-"
                                type="text"
                                size="default"
                                handleChange={handleInputChange}
                                isDisabled={!isEdit}
                                errorMessage={""}
                                name="highFloor"
                                value={highFloor}
                                border={isEdit}
                            />
                        </>
                    ) : (
                        <div>
                            {rowFloor}/{highFloor}
                        </div>
                    )}
                </li>

                <li>
                    <Input
                        label={"구분"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="type"
                        value={type}
                        border={isEdit}
                    />
                </li>
                <li>
                    <span>용적률/건폐율</span>
                    {isEdit ? (
                        <>
                            <Input
                                label={""}
                                placeholder="-"
                                type="text"
                                size="default"
                                handleChange={handleInputChange}
                                isDisabled={!isEdit}
                                errorMessage={""}
                                name="floorAreaRatio"
                                value={floorAreaRatio}
                                border={isEdit}
                            />
                            <Input
                                label={""}
                                placeholder="-"
                                type="text"
                                size="default"
                                handleChange={handleInputChange}
                                isDisabled={!isEdit}
                                errorMessage={""}
                                name="buildingToLandRatio"
                                value={buildingToLandRatio}
                                border={isEdit}
                            />
                        </>
                    ) : (
                        <div>
                            {floorAreaRatio}%/{buildingToLandRatio}%
                        </div>
                    )}
                </li>
                <li>
                    <Input
                        label={"주용도"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="mainUse"
                        value={mainUse}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"건물건축면적"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="buildingArea"
                        value={isEdit ? buildingArea : `${buildingArea}㎡`}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"세부용도"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="detailUse"
                        value={detailUse}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"건물대지면적"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="buildingLandArea"
                        value={
                            isEdit ? buildingLandArea : `${buildingLandArea}㎡`
                        }
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"사용승인일"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="approveDate"
                        value={approveDate}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"건물연면적"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="totalFloorArea"
                        value={isEdit ? totalFloorArea : `${totalFloorArea}㎡`}
                        border={isEdit}
                    />
                </li>
            </ul>
            <Title title="토지정보" size="small" />
            <ul css={ItemInformationUl}>
                <li>
                    <Input
                        label={"소재지"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="address"
                        value={address}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"소유구분"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="ownerType"
                        value={ownerType}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"토지면적"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="landArea"
                        value={isEdit ? landArea : `${landArea}㎡`}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"소유권변동일자"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="ownerUpdateDate"
                        value={ownerUpdateDate}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"지목명"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="landName"
                        value={landName}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"소유권변동원인"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="ownerUpdateReason"
                        value={ownerUpdateReason}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"용도지역"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="specialPurposeArea"
                        value={specialPurposeArea}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"지형높이"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="landHigh"
                        value={landHigh}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"공시지가"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="officialPrice"
                        value={
                            isEdit ? officialPrice : ConvertPrice(officialPrice)
                        }
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"지형형상"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="landShape"
                        value={landShape}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"데이터 기준일자"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="dataDate"
                        value={dataDate}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"도로접면"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="road"
                        value={road}
                        border={isEdit}
                    />
                </li>
                <li>
                    <Input
                        label={"토지이용상황"}
                        placeholder="-"
                        type="text"
                        size="default"
                        handleChange={handleInputChange}
                        isDisabled={!isEdit}
                        errorMessage={""}
                        name="currentLandUse"
                        value={currentLandUse}
                        border={isEdit}
                    />
                </li>
            </ul>
        </div>
    );
};
const ItemInformationUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    justify-content: flex-start;
    li {
        display: flex;
        align-items: center;
        width: 40%;
        & > span {
            width: 100px;
            display: inline-block;
            vertical-align: bottom;
            line-height: 60px;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
    }
    li:first-of-type {
        min-width: 400px;
    }
    li:last-of-type {
        width: 100%;
        height: 60px;
    }
    input:disabled {
        background-color: #fff;
        color: ${Common.colors.black};
    }
    div {
        text-indent: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
    }
`;
const ItemInformationDiv = css`
    ul:first-of-type li:nth-of-type(2) span,
    ul:first-of-type li:nth-of-type(4) span {
        width: 180px;
    }
`;
export default ItemInformationContainer;
