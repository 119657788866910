import React, { Dispatch, SetStateAction } from "react";
import { Button, IconButton, Label } from "@src/components";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { ConvertPhoneNumber } from "@src/utils/units";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

export const getExpertAdvertisementColumns = (
    setIsShowInfoEditModal: (isShow: boolean) => void,
    setConfirmModalState: Dispatch<
        SetStateAction<{
            isShowModal: boolean;
            modalText: string;
            modalType: number;
            bannerNo: number;
            expertUserNo: number;
        }>
    >,
    setChangeAdInfoState: Dispatch<SetStateAction<any>>,
    setIsShowInquiryList: Dispatch<SetStateAction<boolean>>,
    setGetInquiryState: Dispatch<SetStateAction<any>>,
    setErrorModalState: Dispatch<
        SetStateAction<{ isShowModal: boolean; errorMessage: string }>
    >,
) => {
    const navigate = useNavigate();
    return [
        {
            Header: "번호",
            accessor: "bannerNo",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>{value}</div>
            ),
        },
        {
            Header: "광고종류",
            accessor: "bannerType",
            Cell: ({ cell: { value }, row }: any) => {
                return (
                    <div style={{ textAlign: "center" }}>
                        {value === "item" ? "매물광고" : "검색광고"}
                    </div>
                );
            },
        },
        {
            Header: "이름",
            accessor: "name",
            Cell: ({ cell: { value }, row }: any) => {
                const no = row.original.expertUserNo;

                return (
                    <div
                        style={{ textAlign: "center" }}
                        onClick={() => {
                            navigate(`/experts/user/detail/${no}`);
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "휴대폰번호",
            accessor: "phone",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center" }}>
                    {ConvertPhoneNumber(value ?? 0)}
                </div>
            ),
        },

        {
            Header: "사무소명",
            accessor: "officeName",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        maxWidth: "250px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "담당관리자",
            accessor: "managerName",
            Cell: ({ cell: { value }, row }: any) => {
                const managerUserNo = row?.original?.managerUserNo;
                return (
                    <div
                        style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "fit-content",
                        }}
                        onClick={() => {
                            if (managerUserNo) {
                                navigate(`/users/detail/${managerUserNo}`);
                            } else {
                                setErrorModalState({
                                    isShowModal: true,
                                    errorMessage: "회원 번호가 없습니다",
                                });
                            }
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "구분",
            accessor: "itemSubId",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center", minWidth: "fit-content" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "소재지",
            accessor: "itemAddress",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        minWidth: "400px",
                        maxWidth: "400px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "용도",
            accessor: "itemUseDetail",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center", minWidth: "fit-content" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "진행상태",
            accessor: "itemStatus",
            Cell: ({ cell: { value } }: any) => (
                <div style={{ textAlign: "center", minWidth: "fit-content" }}>
                    {value}
                </div>
            ),
        },
        {
            Header: "총 문의건수",
            accessor: "totalCount",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerNo = row?.original?.bannerNo;
                return (
                    <div
                        style={{ textAlign: "center" }}
                        onClick={() => {
                            setIsShowInquiryList(true);
                            setGetInquiryState({
                                page: 1,
                                limit: 10,
                                bannerNo: bannerNo,
                            });
                        }}
                    >
                        {value}건
                    </div>
                );
            },
        },
        {
            Header: "전화문의",
            accessor: "callCount",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerNo = row?.original?.bannerNo;

                return (
                    <div
                        style={{ textAlign: "center", minWidth: "fit-content" }}
                        onClick={() => {
                            setIsShowInquiryList(true);
                            setGetInquiryState({
                                page: 1,
                                limit: 10,
                                bannerNo: bannerNo,
                                inquiryType: "call",
                            });
                        }}
                    >
                        {value}건
                    </div>
                );
            },
        },
        {
            Header: "문자문의",
            accessor: "messageCount",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerNo = row?.original?.bannerNo;
                return (
                    <div
                        style={{ textAlign: "center", minWidth: "fit-content" }}
                        onClick={() => {
                            setIsShowInquiryList(true);
                            setGetInquiryState({
                                page: 1,
                                limit: 10,
                                bannerNo: bannerNo,
                                inquiryType: "message",
                            });
                        }}
                    >
                        {value}건
                    </div>
                );
            },
        },
        {
            Header: "광고기간",
            accessor: "bannerPeriod",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        textAlign: "center",
                        width: "180px",
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "광고상태",
            accessor: "bannerStatus",
            Cell: ({ cell: { value } }: any) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "fit-content",
                    }}
                >
                    <Label
                        text={value}
                        color={
                            value === "광고중"
                                ? "blue"
                                : value === "광고종료"
                                ? "gray"
                                : "black"
                        }
                    />
                </div>
            ),
        },
        {
            Header: "관리",
            accessor: "unknown1",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerStatus = row?.original?.bannerStatus;
                const bannerPeriod = row?.original?.bannerPeriod;

                const createdAt = format(
                    new Date(bannerPeriod.split("~")[0].replaceAll("-", "/")),
                    "yyyy-MM-dd",
                );
                const expiredAt = format(
                    new Date(bannerPeriod.split("~")[1].replaceAll("-", "/")),
                    "yyyy-MM-dd",
                );
                const bannerNo = row?.original?.bannerNo;
                const expertUserNo = row?.original?.expertUserNo;
                const bidType = row?.original?.bidType;
                const itemSubId = row?.original?.itemSubId;
                const itemId = row?.original?.itemId;
                const userNo = row?.original?.expertUserNo;

                return (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                minWidth: "fit-content",
                            }}
                        >
                            {bannerStatus === "광고중" && (
                                <Button
                                    label="광고 종료"
                                    handleClick={() => {
                                        setConfirmModalState({
                                            isShowModal: true,
                                            modalText:
                                                "광고를 종료하시겠습니까? 광고 등록 20분이내에 광고권 반환됩니다.",
                                            modalType: 1,
                                            bannerNo,
                                            expertUserNo,
                                        });
                                    }}
                                    color="purple"
                                    size="small"
                                />
                            )}
                            {bannerStatus === "광고종료" && (
                                <Button
                                    label="재광고"
                                    handleClick={() => {
                                        setChangeAdInfoState({
                                            createdAt,
                                            expiredAt,
                                            bidType,
                                            itemSubId,
                                            itemId,
                                            bannerNo,
                                            userNo,
                                            type: "create",
                                        });
                                        setIsShowInfoEditModal(true);
                                    }}
                                    color="purple"
                                    size="small"
                                />
                            )}
                            {bannerStatus === "관리자삭제" ? (
                                new Date(createdAt) < new Date() &&
                                new Date(expiredAt) > new Date() ? (
                                    <Button
                                        label="광고 종료"
                                        handleClick={() => {
                                            setConfirmModalState({
                                                isShowModal: true,
                                                modalText:
                                                    "광고를 종료하시겠습니까? 광고 등록 20분이내에 광고권 반환됩니다.",
                                                modalType: 1,
                                                bannerNo,
                                                expertUserNo,
                                            });
                                        }}
                                        color="purple"
                                        size="small"
                                    />
                                ) : (
                                    <Button
                                        label="재광고"
                                        handleClick={() => {
                                            setChangeAdInfoState({
                                                createdAt,
                                                expiredAt,
                                                bidType,
                                                itemSubId,
                                                itemId,
                                                bannerNo,
                                                userNo,
                                                type: "create",
                                            });
                                            setIsShowInfoEditModal(true);
                                        }}
                                        color="purple"
                                        size="small"
                                    />
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            Header: "수정",
            accessor: "unknown2",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerPeriod = row?.original?.bannerPeriod;
                const createdAt = format(
                    new Date(bannerPeriod.split("~")[0].replaceAll("-", "/")),
                    "yyyy-MM-dd",
                );
                const expiredAt = format(
                    new Date(bannerPeriod.split("~")[1].replaceAll("-", "/")),
                    "yyyy-MM-dd",
                );
                const bidType = row?.original?.bidType;
                const itemSubId = row?.original?.itemSubId;
                const itemId = row?.original?.itemId;
                const bannerNo = row?.original.bannerNo;
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "fit-content",
                        }}
                    >
                        <Button
                            label="수정"
                            handleClick={() => {
                                setChangeAdInfoState({
                                    createdAt,
                                    expiredAt,
                                    bidType,
                                    itemSubId,
                                    itemId,
                                    bannerNo,
                                });
                                setIsShowInfoEditModal(true);
                            }}
                            color="orange"
                            size="small"
                        />
                    </div>
                );
            },
        },
        {
            Header: "광고권\n사용취소",
            accessor: "isTicketCanceled",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerNo = row?.original?.bannerNo;
                const expertUserNo = row?.original?.expertUserNo;
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "fit-content",
                        }}
                    >
                        {value ? (
                            <Button
                                label="취소완료"
                                handleClick={() => {}}
                                color="black"
                                size="small"
                            />
                        ) : (
                            <Button
                                label="취소"
                                handleClick={() => {
                                    setConfirmModalState({
                                        isShowModal: true,
                                        modalText:
                                            "광고권 사용을 취소하시겠습니까? 사용취소 시 광고권이 반환되고 광고는 종료되지 않습니다.",
                                        modalType: 4,
                                        bannerNo,
                                        expertUserNo,
                                    });
                                }}
                                color="realRed"
                                size="small"
                            />
                        )}
                    </div>
                );
            },
        },
        {
            Header: "삭제",
            accessor: "unknown3",
            Cell: ({ cell: { value }, row }: any) => {
                const bannerStatus = row?.original?.bannerStatus;
                const bannerNo = row?.original?.bannerNo;
                const expertUserNo = row?.original?.expertUserNo;
                return (
                    <div
                        style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <>
                            {bannerStatus === "관리자삭제" ? (
                                <IconButton
                                    type="restore"
                                    handleClick={() => {
                                        setConfirmModalState({
                                            isShowModal: true,
                                            modalText:
                                                "광고를 복구하시겠습니까?",
                                            modalType: 3,
                                            bannerNo,
                                            expertUserNo,
                                        });
                                    }}
                                />
                            ) : (
                                <IconButton
                                    type="trash"
                                    handleClick={() => {
                                        setConfirmModalState({
                                            isShowModal: true,
                                            modalText:
                                                "광고를 삭제하시겠습니까? 삭제 시 해당 회원의 광고내역에서 삭제됩니다.",
                                            modalType: 2,
                                            bannerNo,
                                            expertUserNo,
                                        });
                                    }}
                                />
                            )}
                        </>
                    </div>
                );
            },
        },
    ];
};
