import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { NavLink, useLocation } from "react-router-dom";
import { Common } from "@src/styles/Common";
import { pages } from "@src/constants/Menu";
import inactiveIcon from "@src/assets/sideMenu_unactive.png";
import activeIcon from "@src/assets/sideMenu_active.png";

function SideMenuList() {
    const [activeIndex, setActiveIndex] = useState(1);
    const [subPath, setSubPath] = useState<string>("");
    const { pathname } = useLocation();

    useEffect(() => {
        isActive();
    }, [pathname]);

    const isActive = () => {
        const subURL = pathname.split("/");
        const findParentMenu = subURL.length > 1 ? subURL[1] : subURL[0];
        const menuHighlight =
            Object.entries(pages).find((v) =>
                v[1].link.includes(findParentMenu),
            )?.[0] ?? -1;
        setActiveIndex(Number(menuHighlight));
        setSubPath(subURL[2]);
    };

    return (
        <>
            <ul css={GnbUl}>
                {pages.map((page, index) => {
                    return (
                        <ListItem
                            key={`${pathname}_${index}`}
                            link={page.link}
                            title={page.title}
                            subLinks={page.subLinks || undefined}
                            index={index}
                            activeIndex={activeIndex}
                            subPath={subPath}
                        />
                    );
                })}
            </ul>
        </>
    );
}

interface IListItem {
    link: string;
    title: string;
    subLinks: { link: string; title: string }[] | undefined;
    index: number;
    activeIndex: number;
    subPath: string;
}

const ListItem = ({
    link,
    title,
    subLinks,
    index,
    activeIndex,
    subPath,
}: IListItem) => {
    const [childActive, setChildActive] = useState(false);
    const [childActiveLink, setChildActiveLink] = useState<string>("");
    const handleClick = () => {
        sessionStorage.setItem("activeIndex", JSON.stringify(index));
        setChildActive(!childActive);
    };
    const handleChildClick = () => {
        setChildActive(true);
    };

    const isParentActive = activeIndex === index;
    const isSubMenu = subLinks !== undefined;

    useEffect(() => {
        if (subPath) {
            if (["template", "situation"].includes(subPath)) {
                setChildActiveLink("/notify/push");
            }
        }
    }, [subPath]);
    return (
        <>
            <li css={dep2Active} className={isParentActive ? "active" : ""}>
                {isSubMenu ? (
                    <>
                        <span
                            onClick={handleClick}
                            css={dep2Span}
                            className={childActive ? "active" : ""}
                        >
                            {title}
                        </span>
                        <ul className={childActive ? "active" : ""}>
                            {subLinks.map((subLink: any, index: number) => (
                                <li key={index} onClick={handleChildClick}>
                                    <NavLink
                                        to={subLink.link}
                                        className={
                                            subLink.link === childActiveLink
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        {subLink.title}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </>
                ) : (
                    <span onClick={handleClick} css={notBg}>
                        <NavLink
                            to={link}
                            className={isParentActive ? "active" : ""}
                        >
                            <span>{title}</span>
                        </NavLink>
                    </span>
                )}
            </li>
        </>
    );
};

const GnbUl = css`
    margin-top: 20px;
    height: calc(100vh - 215px);
    padding-bottom: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 2px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
    li {
        ${Common.textStyle.title6_R14}
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &.active {
            color: ${Common.colors.focus};
        }
        a {
            ${Common.textStyle.title6_R14}
            display: block;
            width: 100%;
            height: 100%;
        }
        span {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    li.active ul {
        height: auto;
    }
    li.active a.active {
        color: ${Common.colors.focus};
    }
    a.active {
        color: ${Common.colors.focus};
    }
`;

const dep2Active = css`
    cursor: pointer;
    ul {
        margin-left: 16px;
        overflow: hidden;
        height: 0;
        transition: all 0.3s;
    }
    ul.active {
        height: auto;
    }
    li:nth-of-type(1) {
        margin-top: 20px;
    }
    &.active > span {
        background-image: url(${activeIcon});
        background-size: 11px 7px;
    }
`;
const dep2Span = css`
    color: #000;
    background: url(${inactiveIcon}) no-repeat;
    background-size: 7px 11px;
    background-position: center right 10px;
    &.active {
        background-image: url(${activeIcon});
        background-size: 11px 7px;
    }
`;
const notBg = css`
    background-image: none !important;
`;
export default SideMenuList;
