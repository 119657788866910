import axios from "axios";
import { showErrorPopUp } from "@src/reducers/errorPopUp";
import { store } from "@src/store";

const axiosInstance = axios.create({
    header: {
        "Content-Type": "application/json",
    },
});

const createJWTToken = (token) => {
    return `Bearer ${token}`;
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = createJWTToken(token);
        }
        return config;
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        store.dispatch(showErrorPopUp(error));
        return Promise.reject(error);
    },
);

const axiosDownloadInstance = axios.create({
    header: {
        "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
});

axiosDownloadInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = createJWTToken(token);
        }
        return config;
    },
    (error) => Promise.reject(error),
);

axiosDownloadInstance.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        store.dispatch(showErrorPopUp(error));
        return Promise.reject(error);
    },
);

export { axiosInstance, axiosDownloadInstance };
