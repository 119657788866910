import { createSlice } from "@reduxjs/toolkit";

const registeredInfoPublic = [
    {
        id: 0,
        rightType: "위임기관",
        rightsHolder: "인천세무서",
        rightDate: "2020.06.19",
        rightPrice: "미표시",
    },
    {
        id: 1,
        rightType: "가압류",
        rightsHolder: "주택도시보증공사",
        rightDate: "2020.09.23",
        rightPrice: 185000000,
    },
    {
        id: 2,
        rightType: "압류",
        rightsHolder: "국민건강보험공단 남양주가평지사",
        rightDate: "2021.06.07",
        rightPrice: "미표시",
    },
];

const initialState = registeredInfoPublic;

export const registeredInfoPublicSlice = createSlice({
    name: "registeredInfoPublic",
    initialState,
    reducers: {
        createRegisteredInfoPublic: (state, action) => {
            const result = action.payload;
            return [...result];
        },
        updateRegisteredInfoPublic: (state, action) => {
            const result = action.payload;
            return [...result];
        },
    },
});

export const { createRegisteredInfoPublic, updateRegisteredInfoPublic } =
    registeredInfoPublicSlice.actions;

export default registeredInfoPublicSlice.reducer;
