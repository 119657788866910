import React, { forwardRef, useRef, useEffect, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import CompositionTable from "./CompositionTable";

interface ITable {
    columns: any;
    data: any;
    initialState?: {
        pageIndex: number;
        pageSize: number;
    };
    FixedHeight?: number;
    showCheckbox?: boolean;
    showTotalCount?: boolean;
    showPagination?: boolean;
    onSelectedRowsChange?: Function;
    selectedRows?: any;
    maxLimitCheckboxSelections?: number;
    showNumber?: boolean;
}

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
        const resolvedRef = useRef<HTMLInputElement>(null);

        useEffect(() => {
            if (resolvedRef && resolvedRef.current) {
                resolvedRef.current.indeterminate = indeterminate;
            }
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    },
);
IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

function Table({
    columns,
    data,
    showCheckbox,
    initialState = { pageIndex: 0, pageSize: 10 },
    FixedHeight = 250,
    showTotalCount = false,
    showPagination = true,
    onSelectedRowsChange = () => {},
    selectedRows = {},
    maxLimitCheckboxSelections,
    showNumber = false,
}: ITable) {
    const totalCount = data.length;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        rows,
        state: { pageIndex },
        selectedFlatRows,
    }: any = useTable(
        {
            columns,
            data,
            initialState: {
                ...initialState,
                selectedRowIds: selectedRows,
            },
        },
        usePagination,
        useRowSelect,
        (hooks) => {
            if (showCheckbox) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",
                        Header: ({
                            getToggleAllPageRowsSelectedProps,
                        }: any) => {
                            return (
                                <div>
                                    <IndeterminateCheckbox
                                        {...getToggleAllPageRowsSelectedProps()}
                                        disabled={isAllSelected()}
                                    />
                                </div>
                            );
                        },
                        Cell: ({ row }: any) => (
                            <div style={{ width: "80px", textAlign: "center" }}>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps({
                                        onChange: () => {
                                            const selected = isSelected(
                                                row.isSelected,
                                            );
                                            row.toggleRowSelected(selected);
                                        },
                                    })}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
            } else if (showNumber) {
                hooks.visibleColumns.push((columns) => [
                    {
                        Header: "번호",
                        Cell: ({ row }: any) => {
                            const page = pageIndex == 0 ? 1 : pageIndex;
                            const currentPage =
                                (page - 1) * initialState.pageSize;

                            const no = page
                                ? totalCount -
                                  (currentPage + (row.index + 1)) +
                                  1
                                : 1;

                            return <div style={{ width: "80px" }}>{no}</div>;
                        },
                    },
                    ...columns,
                ]);
            } else {
                return [...columns];
            }
        },
    );

    const getSelectedRowsCount = () => {
        return rows.filter((v: any) => v.isSelected).length;
    };

    const isAllSelected = () => {
        if (maxLimitCheckboxSelections) {
            // 선택된 갯수가 제한수를 넘어가면 전체 체크 기능을 막는다.
            const currentCount = getSelectedRowsCount() + initialState.pageSize;
            return currentCount > maxLimitCheckboxSelections ? true : false;
        }
        return false; // 기본은 무조건 전체 체크가 가능하다.
    };

    const isSelected = (selected: boolean = false) => {
        const currentCount = getSelectedRowsCount();
        if (
            maxLimitCheckboxSelections &&
            currentCount >= maxLimitCheckboxSelections
        ) {
            return false; // 강제로 체크를 해제한다.
        } else {
            return !selected; // 기본은 선택/해제 가능하다.
        }
    };

    const onPageChange = ({ selected }: any) => {
        gotoPage(selected);
    };

    useEffect(() => {
        onSelectedRowsChange(selectedFlatRows);
    }, [selectedFlatRows]);

    return (
        <>
            <CompositionTable
                columns={columns}
                data={data}
                FixedHeight={FixedHeight}
                showPagination={showPagination}
                showTotalCount={showTotalCount}
                totalCount={totalCount}
                onPageChange={onPageChange}
                pageCount={pageCount}
                forcePageNumber={pageIndex}
                getTableBodyProps={getTableBodyProps}
                getTableProps={getTableProps}
                page={page}
                prepareRow={prepareRow}
                headerGroups={headerGroups}
                selectedFlatRows={selectedFlatRows}
            />
        </>
    );
}

export default Table;
