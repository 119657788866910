import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { LocationLargeCategory } from "@src/data/LocationLargeCategory";
import { LocationMediumCategory } from "@src/data/LocationMediumCategory";

const fetchData = async () => {
    const response = await fetch("");

    if (!response.ok) {
        throw new Error("Problem fetching data");
    }

    return [];
};

const useLocationBigData = () => {
    return useQuery<any, Error>(queryKeys.LOCATION_BIG, fetchData, {
        initialData: LocationLargeCategory,
    });
};

const useLocationSmallData = () => {
    return useQuery<any, Error>(queryKeys.LOCATION_SMALL, fetchData, {
        initialData: LocationMediumCategory,
    });
};

export { useLocationBigData, useLocationSmallData };
