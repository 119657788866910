import React, { forwardRef, useRef, useEffect } from "react";

const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
        const defaultRef = useRef<HTMLInputElement>(null);
        const resolvedRef = (ref = null || defaultRef);

        useEffect(() => {
            if (resolvedRef && resolvedRef.current) {
                resolvedRef.current.indeterminate = indeterminate;
            }
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    },
);
IndeterminateCheckbox.displayName = "IndeterminateCheckbox";
export default IndeterminateCheckbox;
