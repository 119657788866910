import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import Menu from "@src/layout/SideMenu";
import { UpDown, Title } from "@src/components";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useHomeCountData } from "@src/hooks/queries/useHomeCount";
import UserStatsChart from "./containers/UserStatsChart";
import ItemSaleStatsChart from "./containers/ItemSaleStatsChart";
import SubsOsPercentChart from "./containers/SubsOsPercentChart";
import NewPaidSubsChart from "./containers/NewPaidSubsChart";
import NewMembersChart from "./containers/NewMembersChart";
import ItemRefundStatsChart from "./containers/ItemRefundStatsChart";
import PaymentStatusChart from "./containers/ PaymentStatusChart";
import LeavedMemberModal from "./containers/LeavedMemberModal";
import RefundMemberModal from "./containers/RefundMemberModal";
import NewMemberModal from "./containers/NewMemberModal";
import DeungiMemberModal from "./containers/DeungiMemberModal";
import { DateRangeCalendarType } from "@src/components/calendar/DateRangeCalendar";
import { closeModal, openModal, selectModal } from "@src/reducers/modal";
import { MODAL_TYPES } from "@src/types/modal";

export type ProductType = "all" | "subscription" | "deungi_ticket" | "campus_registration";

export const productTypeList = [
    { name: "전체", value: "all" },
    { name: "구독", value: "subscription" },
    { name: "등기열람", value: "deungi_ticket" },
    { name: "캠퍼스", value: "campus_registration" },
    { name: "매물 광고권", value: "items_ads_ticket" },
    { name: "지역 광고권", value: "region_ads_ticket" },
    { name: "국유지필터", value: "state_land_filter" },
    { name: "예정도로", value: "road_plan" },
    { name: "주거/토지 고스톱", value: "premium_filter" },
    { name: "상가 고스톱", value: "Retail_profit_rate" },
];

function Home() {
    const Today = () => {
        const date = new Date();
        const weeks = ["일", "월", "화", "수", "목", "금", "토"];
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const week = weeks[date.getDay()];

        return `${year}.${month}.${day} ${week}요일`;
    };

    const { isLoading, data: homeCount, isError } = useHomeCountData();

    const [homeCountData, setHomeCountData] = useState({
        deungiUserCount: "0",
        deungiUserToday: "0",
        inquiryUnresolved: "0",
        paidPerAllUser: "0",
        paidSubsAll: "0",
        paidTodayPerJoin: "0",
        paidTodayPerYesterday: 0,
        subscriptionToday: "0",
        useDeungiCount: "0",
        useDeungiToday: "0",
        visitorDiff: 0,
        visitorToday: "0",
        deungiUserDiff: 0,
        useDeungiDiff: 0,
        subscriptionDiff: 0,
        subscriptionLeave: 0,
    });

    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        if (isLoading) {
            setErrorMessage("데이터 조회 중입니다.");
        } else if (isError) {
            setErrorMessage("데이터 조회에 실패하였습니다.");
        } else {
            setErrorMessage("");
            homeCount && setHomeCountData(homeCount as any);
        }
    }, [homeCount, isLoading, isError]);

    const isSame = (data: number | string): boolean => {
        return data == 0;
    };

    const {
        deungiUserCount,
        deungiUserToday,
        inquiryUnresolved,
        paidPerAllUser,
        paidSubsAll,
        paidTodayPerJoin,
        paidTodayPerYesterday,
        subscriptionToday,
        useDeungiCount,
        useDeungiToday,
        visitorDiff,
        visitorToday,
        deungiUserDiff,
        useDeungiDiff,
        subscriptionDiff,
        subscriptionLeave,
    } = homeCountData || {};

    const [joinedDate, setJoinedDate] = useState<DateRangeCalendarType>({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [refundDate, setRefundDate] = useState<DateRangeCalendarType>({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [refundProductType, setRefundProductType] = useState<ProductType>("subscription");

    const [openLeavedMemberMoal, setOpenLeavedMemberMoal] = useState<boolean>(false);
    const [openRefundMemberMoal, setOpenRefundMemberMoal] = useState<boolean>(false);
    const [openJoinedMemberMoal, setOpenJoinedMemberMoal] = useState<boolean>(false);

    const { modalType, isOpen } = useSelector(selectModal);
    const dispatch = useDispatch();

    useEffect(() => {
        const storage = sessionStorage;

        if (!storage) {
            return;
        }

        const previousUrl = storage.getItem("CURRENT_URL");
        if (previousUrl?.includes("/users/detail")) {
            dispatch(
                openModal({
                    modalType: MODAL_TYPES.DeungiMemberModal,
                    isOpen: true,
                }),
            );
        }
    }, []);

    return (
        <>
            <div css={rootStyle}>
                <Menu />
                <div css={homeDiv}>
                    <Title title={Today()} size={"big"} />
                    <div className="styleContainer">
                        <div className="container">
                            <div className="left">
                                <div className="countBox">
                                    <UpDown
                                        title="일 방문자"
                                        mainText={visitorToday}
                                        upDownCount={Math.abs(visitorDiff)}
                                        isUp={visitorDiff > 0}
                                        same={isSame(visitorDiff)}
                                        errorMessage={errorMessage}
                                    />
                                    <div css={itemBox}>
                                        <div
                                            css={userCountInfo}
                                            className="cursor"
                                            onClick={() => {
                                                new Promise((resolve, _reject) => {
                                                    setJoinedDate({
                                                        ...joinedDate,
                                                        startDate: new Date(),
                                                        endDate: new Date(),
                                                    });
                                                    resolve(joinedDate);
                                                }).then(() => {
                                                    setOpenJoinedMemberMoal(true);
                                                });
                                            }}
                                        >
                                            <p>오늘 신규 상품(유료)가입자</p>
                                            <p className="countText">{subscriptionToday}</p>
                                        </div>
                                        <div css={userCountInfo}>
                                            <p>전체 구독(유료)가입자</p>
                                            <p className="countText">{paidSubsAll}</p>
                                        </div>
                                        <div
                                            css={userCountInfo}
                                            className="cursor"
                                            onClick={() => setOpenLeavedMemberMoal(true)}
                                        >
                                            <p>오늘 이탈자수</p>
                                            <p className="countText">{subscriptionLeave}</p>
                                        </div>
                                    </div>
                                    <UpDown
                                        title="일일 가입자 대비 유료 전환율"
                                        mainText={`${Number(paidTodayPerJoin).toFixed(1)}%`}
                                        upDownCount={paidTodayPerYesterday}
                                        isUp={paidTodayPerYesterday > 0}
                                        same={isSame(paidTodayPerYesterday)}
                                        errorMessage={errorMessage}
                                    />
                                    <UpDown
                                        title="누적 가입자 대비 유료 전환율"
                                        mainText={`${paidPerAllUser}%`}
                                        upDownCount={null}
                                        errorMessage={errorMessage}
                                    />
                                </div>
                                <div className="countBox" style={{ marginTop: "6px" }}>
                                    <div
                                        className="cursor"
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    modalType: MODAL_TYPES.DeungiMemberModal,
                                                    isOpen: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <UpDown
                                            title="등기열람권 일일 사용자"
                                            mainText={deungiUserToday}
                                            upDownCount={null}
                                            errorMessage={errorMessage}
                                        />
                                    </div>
                                    <div
                                        className="cursor"
                                        onClick={() => {
                                            dispatch(
                                                openModal({
                                                    modalType: MODAL_TYPES.DeungiMemberModal,
                                                    isOpen: true,
                                                }),
                                            );
                                        }}
                                    >
                                        <UpDown
                                            title="등기열람권 일 사용 수량"
                                            mainText={useDeungiToday}
                                            upDownCount={null}
                                            errorMessage={errorMessage}
                                        />
                                    </div>
                                    <div css={itemBox}>
                                        <div className="titleP">등기열람권 누적</div>
                                        <div
                                            className="cursor"
                                            onClick={() => {
                                                dispatch(
                                                    openModal({
                                                        modalType: MODAL_TYPES.DeungiMemberModal,
                                                        isOpen: true,
                                                    }),
                                                );
                                            }}
                                        >
                                            <div className="subTextP">
                                                <p>사용자</p>
                                                {deungiUserCount} /
                                            </div>
                                            <div className="subTextP">
                                                <p> 사용수량</p>
                                                {useDeungiCount}
                                            </div>
                                        </div>
                                    </div>
                                    <NavLink to="/help/support">
                                        <UpDown
                                            title="문의 답변 대기 &nbsp;&nbsp;&nbsp;&nbsp;>"
                                            mainText={inquiryUnresolved}
                                            upDownCount={null}
                                            errorMessage={errorMessage}
                                        />
                                    </NavLink>
                                </div>
                            </div>
                            {/* 구독자 회원별os비율 */}
                            <div className="right">
                                <SubsOsPercentChart />
                            </div>
                        </div>
                        {/* 방문자, 가입자 */}
                        <div css={homeBoxSecondStyle}>
                            <UserStatsChart />
                            <NewMembersChart />
                        </div>
                        {/* 상품별 매출현황 */}
                        <div css={homeBoxStyle}>
                            <ItemSaleStatsChart />
                        </div>
                        {/* 결재현황 */}
                        <div css={homeBoxStyle}>
                            <PaymentStatusChart
                                onClickOpenRefundMemberModal={(type: any, start: any, end: any) => {
                                    new Promise((resolve, _reject) => {
                                        setRefundProductType(type);
                                        setRefundDate({
                                            ...refundDate,
                                            startDate: start,
                                            endDate: end,
                                        });
                                        resolve(refundDate);
                                    }).then(() => {
                                        setOpenRefundMemberMoal(true);
                                    });
                                }}
                                onClickOpenJoinedMemberModal={(start: any, end: any) => {
                                    new Promise((resolve, _reject) => {
                                        setJoinedDate({
                                            ...joinedDate,
                                            startDate: start,
                                            endDate: end,
                                        });
                                        resolve(joinedDate);
                                    }).then(() => {
                                        setOpenJoinedMemberMoal(true);
                                    });
                                }}
                            />
                        </div>
                        {/* 유료구독자 전환 현황, 상품별 환불 현황 */}
                        <div css={homeBoxSecondStyle}>
                            <NewPaidSubsChart
                                onClickOpenJoinedMemberModal={(start: any, end: any) => {
                                    new Promise((resolve, _reject) => {
                                        setJoinedDate({
                                            ...joinedDate,
                                            startDate: start,
                                            endDate: end,
                                        });
                                        resolve(joinedDate);
                                    }).then(() => {
                                        setOpenJoinedMemberMoal(true);
                                    });
                                }}
                            />
                            <ItemRefundStatsChart
                                onClickOpenRefundMemberModal={(type: any, start: any, end: any) => {
                                    new Promise((resolve, _reject) => {
                                        setRefundProductType(type);
                                        setRefundDate({
                                            ...refundDate,
                                            startDate: start,
                                            endDate: end,
                                        });
                                        resolve(refundDate);
                                    }).then(() => {
                                        setOpenRefundMemberMoal(true);
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <LeavedMemberModal open={openLeavedMemberMoal} onCloseView={() => setOpenLeavedMemberMoal(false)} />
            <RefundMemberModal
                open={openRefundMemberMoal}
                onCloseView={() => setOpenRefundMemberMoal(false)}
                date={refundDate}
                refundProductType={refundProductType}
                setDate={setRefundDate}
            />
            <NewMemberModal
                open={openJoinedMemberMoal}
                onCloseView={() => setOpenJoinedMemberMoal(false)}
                date={joinedDate}
                setDate={setJoinedDate}
            />
            <DeungiMemberModal
                open={modalType === MODAL_TYPES.DeungiMemberModal && isOpen}
                onCloseView={() => dispatch(closeModal())}
            />
        </>
    );
}

const itemBox = css`
    width: 100%;
    min-width: 190px;
    height: 108px;
    border: 1.5px solid ${Common.colors.gray200};
    border-radius: 8px;
    padding: 20px;

    & > div {
        display: flex;
        align-items: center;
    }
    .titleP {
        display: flex;
        align-items: center;
        ${Common.textStyle.title8_R12}
        color: ${Common.colors.gray700};
        text-align: center;
    }
    .titleSub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${Common.textStyle.title8_R12}
        color: ${Common.colors.gray700};
        text-align: center;
        margin-bottom: 3px;
        .count {
            font-size: 20px;
            font-weight: 500;
            color: ${Common.colors.black};
        }
    }

    .subBox {
        display: flex;
    }
    .subTextP {
        display: flex;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.8px;
        color: ${Common.colors.black};
        margin-left: 7px;
        margin-top: 18px;
        & > p {
            font-size: 11px;
            margin-top: 3px;
            margin-right: 6px;
        }
    }
    .count {
        display: flex;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.8px;
        color: ${Common.colors.black};
        margin-bottom: 5px;
        margin-left: 10px;
    }
`;

const homeDiv = css`
    padding: 40px;
    padding-left: 275px;
    width: 100%;
    min-width: 1250px;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;

    & > div:first-of-type {
        width: 100%;
        padding-bottom: 20px;
    }
    .left {
        padding-right: 8px;
    }
    .countBox {
        display: flex;
        flex: 1;
        & > div {
            width: 48%;
            margin-right: 10px;
        }
        a {
            display: block;
            width: 48%;
            margin-right: 10px;
        }
    }
    .right {
        & > div:last-of-type {
            margin-bottom: 0;
        }
        display: flex;
        flex: 1;
        width: 45%;
    }
    .styleContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    .container {
        display: flex;
        flex-direction: row;
        gap: 6px;
        margin-top: 20px;
        width: 1500px;
        justify-content: space-between;
    }
    .cursor {
        cursor: pointer;
    }
`;

const homeBoxStyle = css`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 6px;
`;

const homeBoxSecondStyle = css`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    width: 1220px;
`;

const rootStyle = css`
    display: flex;
    width: 100%;
`;

const userCountInfo = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${Common.textStyle.title8_R12}
    color: ${Common.colors.gray700};
    text-align: center;
    line-height: 24px;
    .countText {
        font-size: 18px;
        font-weight: 700;
        color: ${Common.colors.black};
    }
    &.cursor {
        cursor: pointer;
    }
`;

export default Home;
