import React from "react";
import { IconButton, Label } from "@src/components";
import {
    UserStatus,
    ExpertStatus,
    SubscriptionStatus,
} from "@src/constants/UserStatus";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
export const getColumns = (navigate: any) => [
    {
        Header: "상품 번호",
        accessor: "no",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ width: "100%", minWidth: "80px", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품코드",
        accessor: "code",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ minWidth: "80px", width: "100%", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "구독이름",
        accessor: "subsGroupName",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "120px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "상품명",
        accessor: "name",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "200px",
                    width: "100%",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {value}
            </div>
        ),
    },
    {
        Header: "상품설명",
        accessor: "description",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "100px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "등록일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{ minWidth: "50px", width: "100%", textAlign: "center" }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "최종 수정일시",
        accessor: "updatedAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    minWidth: "180px",
                    fontSize: "13px",
                    width: "100%",
                    textAlign: "center",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "판매형태",
        accessor: "saleType",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    {value === 0 && "일반 판매 미노출"}
                    {value === 1 && "일반 판매 노출"}
                    {value === 2 && "파트너스 제휴 판매 노출"}
                    {value === 3 && "직원용"}
                    {value === 4 && "무료 쿠폰용"}
                    {value === 5 && "유료 쿠폰용"}
                    {value === 6 && "AOS노출용"}
                    {value === 7 && "IOS노출용"}
                    {value === 8 && "WEB노출용"}
                </div>
            );
        },
    },

    {
        Header: "판매여부",
        accessor: "status",
        Cell: ({ cell: { value } }: any) => {
            return (
                <div
                    style={{
                        minWidth: "80px",
                        width: "100%",
                        textAlign: "center",
                    }}
                >
                    {value === 200 && "판매중"}
                    {value === 100 && "판매중지"}
                </div>
            );
        },
    },

    {
        Header: "관리",
        accessor: "viewControl",
        Cell: ({ row }: any) => {
            const id = row.original.no;

            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    <IconButton
                        type="edit"
                        handleClick={() => {
                            navigate(`/commodity/update/${id}`);
                        }}
                    />
                </div>
            );
        },
    },
    {
        Header: "복제",
        accessor: "clone",
        Cell: ({ row }: any) => {
            const id = row.original.no;

            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        minWidth: "80px",
                        width: "100%",
                    }}
                >
                    <IconButton
                        type="copy"
                        handleClick={() => {
                            navigate(`/commodity/copy/${id}`);
                        }}
                    />
                </div>
            );
        },
    },
];
