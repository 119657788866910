import React, { useEffect, useState } from "react";
import {
    Button,
    Checkbox,
    ConfirmModal,
    EditModal,
    PageTable,
    TextBox,
    Title,
    ToggleButton,
    ViewMoreTable,
} from "@src/components";
import { getColumns } from "./CommodityProvisionColumns";
import { getUserColumns } from "./CommodityProvisionUserColumns";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { css } from "@emotion/react";
import {
    PaymentFilterType,
    useCommodityProvisionList,
    useDeleteProvision,
} from "@src/hooks/queries/useCommodityProvision";
import { getQueryData } from "@src/utils/useQueryClient";
import { queryKeys } from "@src/hooks/queries/queryKeys";
import { getQueryString } from "@src/hooks/queries/useCommodity";
import { endLoading, startLoading } from "@src/reducers/loading";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { makeQueryString } from "@src/utils/queryStringUtil";
import {
    useDeleteProvisionUser,
    useGetProvisionUserList,
    useGiveFreeForUser,
    usePushProvisionUser,
    useProvisionSendPush,
} from "@src/hooks/queries/useProvisionUser";
import { ProvisionUser } from "@src/interfaces/Commodity.interface";
import { isDisabled } from "@testing-library/user-event/dist/types/utils";

const CommodityProvision = () => {
    const provisionUserInitalState = {
        no: 0,
        toGo: "",
        userNo: 0,
        uname: "",
        status: 0,
        comments: "",
        delete: 0,
        issueNo: 0,
    };

    const navigate = useNavigate();
    const [isModalShow, setIsModalShow] = useState<boolean>(false);
    const [isCheckModalShow, setIsCheckModalShow] = useState<boolean>(false);
    const [issueNo, setIssueNo] = useState<number>(0);
    const [provisionUserList, setProvisionUserList] = useState<ProvisionUser[]>([provisionUserInitalState]);
    const [userParams, setUserParams] = useState<string[]>([]);
    const [waitingForPayment, setWaitingForPayment] = useState<any[]>();
    const [notFound, setNotFound] = useState<any[]>([]);
    const [paymentCompleted, setPaymentCompleted] = useState<any[]>();
    const [paymentFailed, setPaymentFailed] = useState<any[]>();
    const [paymentSubscription, setPaymentSubscription] = useState<any[]>();
    const [isDeleteModalShow, setIsDeleteModalShow] = useState<boolean>(false);
    const [isDeleteUserShow, setIsDeleteUserShow] = useState<boolean>(false);
    const [deleteUserData, setDeleteUserData] = useState({
        no: 0,
        issueNo: 0,
    });
    const [isViewModal, setIsViewModal] = useState<boolean>(false);
    const [issue, setIssue] = useState<any>({});
    const [willSendPush, setWillSendPush] = useState<boolean>(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const columns = getColumns(
        navigate,
        setIsModalShow,
        setIssueNo,
        setProvisionUserList,
        setUserParams,
        setIsDeleteModalShow,
        setIsViewModal,
        setIssue,
        setWillSendPush,
    );
    const userColumns = getUserColumns(
        navigate,
        setProvisionUserList,
        setDeleteUserData,
        setIsDeleteUserShow,
        isViewModal,
    );
    const location = useLocation();
    const isChangeUrl = location.state;

    const dispatch = useDispatch();
    const [list, setList] = useState<any>([]);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [isDisable, setIsDisable] = useState<boolean>(true);

    const pagesInitialState = {
        pageIndex: 0,
        pageSize: 10,
    };

    useEffect(() => {
        const waiting: any[] = [];
        const notFound: any[] = [];
        const success: any[] = [];
        const failed: any[] = [];
        const subscription: any[] = [];
        provisionUserList &&
            provisionUserList.map((item) => {
                !item.userNo && notFound.push(item);
                item.userNo && item.status === 200 && waiting.push(item);
                item.userNo &&
                    item.status === 202 &&
                    item.comments !== "이미 구독중인 상품이 있습니다." &&
                    failed.push(item);
                item.userNo &&
                    item.status === 202 &&
                    item.comments === "이미 구독중인 상품이 있습니다." &&
                    subscription.push(item);
                item.userNo && item.status === 300 && success.push(item);
            });
        setNotFound(notFound);
        setWaitingForPayment(waiting);
        setPaymentCompleted(success);
        setPaymentFailed(failed);
        setPaymentSubscription(subscription);
    }, [provisionUserList]);

    const initFilters: PaymentFilterType = {
        page: 1,
        limit: 10,
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState<PaymentFilterType>(initFilters);
    const copyQuery: PaymentFilterType = Object.fromEntries(searchParams);
    const { isLoading, data = { result: [], total: 0 }, isError, refetch } = useCommodityProvisionList(params, true);

    const queryData: { result: any[]; total: number } = getQueryData([queryKeys.COMMODITY_PROVISION_LIST]);
    const updateURL = (value: PaymentFilterType) => {
        const queryString = makeQueryString(value);
        setSearchParams(queryString);
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (waitingForPayment?.length) {
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
    }, [waitingForPayment]);

    // const {
    //     isLoading ,
    //     data = { result: [], total: 0 },
    //     isError,
    //     refetch,
    // } = useGetProvisionUserList(issueNo, true);

    useEffect(() => {
        if (queryData) {
            setList(queryData.result);
            setTotalCount(queryData.total);
            dispatch(endLoading());
        } else {
            if (!isLoading && !isError && data) {
                setList(data.result);
                if (initFilters.page === 1) {
                    setTotalCount(data.total);
                }
                dispatch(endLoading());
            } else if (isError) {
                dispatch(endLoading());
            } else {
                dispatch(startLoading());
            }
        }
    }, [isLoading, data.result, isError, queryData]);

    return (
        <div css={detailStyle}>
            <div className="btnDiv" style={{ display: "flex" }}>
                <Title title="상품 지급 관리" />
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/commodity/provision/create");
                    }}
                    label="상품 지급 등록"
                    size="medium"
                />
            </div>
            <PageTable
                FixedHeight={700}
                columns={columns}
                data={list}
                handlePageChange={(index: number) => {
                    const page = index + 1;
                    const limit = 10;
                    setParams(() => {
                        let newParams = { ...copyQuery, page, limit };
                        updateURL(newParams);
                        return newParams;
                    });
                }}
                initialState={pagesInitialState}
                totalCount={totalCount}
            />
            <EditModal
                buttonText="지급"
                handleBtnClick={() => {
                    setIsCheckModalShow(true);
                }}
                handleOpenModal={() => {
                    setIsModalShow(false);
                    refetch();
                }}
                size="big"
                title="지급 회원 추가"
                isModalShow={isModalShow}
                isDisabled={isDisable}
            >
                <StyledModalWrapper>
                    <div className="textDiv">
                        <span className="labelTitle">회원 추가</span>
                        <div>
                            <div className="textBoxArea">
                                <TextBox
                                    handleChange={(value) => {
                                        setUserParams(value.split(","));
                                    }}
                                    label=""
                                    maxLength={1000}
                                    placeholder="아이디(이메일) 혹은은  전화번호를 입력해주세요."
                                    size="big"
                                    isDisabled={isViewModal}
                                    defaultText={userParams}
                                />
                                <div className="description">
                                    {'","'}으로 구분하여 입력하면 여러 회원을 동시에 추가할 수 있습니다.{"("}예
                                    abc@example.com,def@example.com{")"}
                                </div>
                            </div>
                            <Button
                                color="purple"
                                handleClick={() => {
                                    usePushProvisionUser(issueNo, userParams).then(() => {
                                        useGetProvisionUserList(issueNo).then((res) => {
                                            setProvisionUserList(res);
                                        });
                                    });
                                }}
                                label="추가"
                                size="small"
                                styles={{
                                    width: 68,
                                    height: 34,
                                    marginLeft: 10,
                                }}
                                isDisabled={isViewModal}
                            />
                        </div>
                    </div>
                    <div className="textDiv" style={{ marginTop: 20 }}>
                        <span className="labelTitle">알림 전송</span>
                        <div>
                            <ToggleButton
                                name="알림"
                                value={willSendPush}
                                handleToggle={() => {
                                    setWillSendPush(!willSendPush);
                                }}
                                isDisabled={isViewModal}
                            />
                        </div>
                    </div>

                    <StyledTableDiv>
                        <StyledCntDiv>
                            <div className="item">총 : {provisionUserList.length.toLocaleString()}명</div>
                            <div className="item">지급대기 : {waitingForPayment?.length.toLocaleString()}명</div>
                            <div className="item">찾을 수 없음 : {notFound.length.toLocaleString()}명</div>
                            <div className="item">지급완료 : {paymentCompleted?.length.toLocaleString()}명</div>
                            <div className="item">지급실패 : {paymentFailed?.length.toLocaleString()}명</div>
                            {issue.type === "subscription" && (
                                <div className="item">기구독 : {paymentSubscription?.length.toLocaleString()}명</div>
                            )}
                        </StyledCntDiv>
                        <ViewMoreTable
                            FixedHeight={300}
                            columns={userColumns}
                            data={provisionUserList}
                            handleViewMore={function noRefCheck() {}}
                            isRefetching={true}
                        />
                    </StyledTableDiv>
                </StyledModalWrapper>
            </EditModal>
            <ConfirmModal
                buttonText="확인"
                isModalShow={isCheckModalShow}
                handleCancel={() => {
                    setIsCheckModalShow(false);
                    refetch;
                }}
                handleOk={() => {
                    if (!isButtonClicked) {
                        setIsButtonClicked(true);
                        waitingForPayment &&
                            useProvisionSendPush(issueNo, willSendPush).then(() => {
                                useGetProvisionUserList(issueNo).then((res) => {
                                    setProvisionUserList(res);
                                    setIsButtonClicked(false);
                                    setIsCheckModalShow(false);
                                    refetch();
                                });
                            });
                    }
                }}
            >
                정말 상품을 지급하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() => {
                    setIsDeleteModalShow(false);
                }}
                handleOk={() => {
                    setIsDeleteModalShow(false);
                    useDeleteProvision(issueNo).then((res) => {
                        refetch();
                        {
                            res.data.data.errCode !== 0 && alert("오류 발생");
                        }
                    });
                }}
                isModalShow={isDeleteModalShow}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() => {
                    setIsDeleteUserShow(false);
                    setIsModalShow(true);
                }}
                handleOk={() => {
                    setIsDeleteUserShow(false);
                    setIsModalShow(true);
                    useDeleteProvisionUser(deleteUserData.no, deleteUserData.issueNo).then(() => {
                        useGetProvisionUserList(deleteUserData.issueNo).then((res) => {
                            setProvisionUserList(res);
                        });
                    });
                }}
                isModalShow={isDeleteUserShow}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
        </div>
    );
};

export default CommodityProvision;

const detailStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 68px;
    gap: 20px;

    & > .btnDiv {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    & > .textDiv {
        display: flex;
        justify-content: space-between;
        & > div {
            display: flex;
            width: 80%;
            & > .labelTitle {
                font-weight: 400;
                color: #222222;
                font-size: 13px;
                width: 100px;
                padding-top: 12px;
            }
            & > .textBoxArea {
                width: 100%;
                & > .description {
                    width: 100%;
                    word-break: break-all;
                    font-weight: 400;
                    font-size: 8px;
                    color: #6d6d6d;
                    margin-top: 7px;
                }
            }
        }
    }
`;

const StyledTableDiv = styled.div`
    margin-top: 20px;
    & > div {
        & > button {
            display: none;
        }
    }
`;

const StyledCntDiv = styled.div`
    display: flex;
    margin-bottom: 5px;
    & > .item {
        font-weight: 600;
        font-size: 13px;
        color: #000000;
        margin-right: 15px;
    }
`;

const StyledRadioBtnDiv = styled.div`
    display: flex;
    margin-left: 5px;
    width: 104px;
    & > .item {
        font-weight: 600;
        font-size: 13px;
        color: #000000;
        margin-right: 15px;
    }
`;
