import styled from "@emotion/styled";
import { Button, Label, Title } from "@src/components";
import { PartnerLabelState } from "@src/constants/PartnerStatus";
import { ConvertDateTime } from "@src/utils/dateUtil";
import { ConvertPhoneNumber } from "@src/utils/units";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

interface PartnerDetailBoxProps {
    setIsShowCompleteModal: Dispatch<SetStateAction<boolean>>;
    detailData: any;
}

const PartnerDetailBox = ({ setIsShowCompleteModal, detailData }: PartnerDetailBoxProps) => {
    const navigate = useNavigate();
    const { no } = useParams();
    const [text, color] = PartnerLabelState[detailData?.status ?? "request"];

    return (
        <>
            <StyledContentsBox>
                <div className="titleDiv">
                    <span>기본정보</span>
                    <Button
                        label="기본 정보 수정"
                        color="whiteOp"
                        size="small"
                        handleClick={(e) => {
                            navigate(`/partner/update/${no}`, {
                                state: { type: "update" },
                            });
                        }}
                        styles={{
                            boxSizing: "border-box",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "21px",
                            width: "86px",
                            height: "24px",
                        }}
                    />
                </div>
                <div className="contents">
                    <div className="partnerStatus">
                        <span>파트너 상태</span>
                        <div className="labelDiv">
                            <Label color={color} text={text} />
                        </div>
                        {(detailData.status === "request" || detailData.status === "reject") && (
                            <Button
                                label="승인"
                                color="whiteOp"
                                size="small"
                                handleClick={(e) => {
                                    setIsShowCompleteModal(true);
                                }}
                                styles={{
                                    boxSizing: "border-box",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "21px",
                                    width: "fit-contents",
                                    height: "24px",
                                }}
                            />
                        )}
                    </div>
                    <div className="userInfo">
                        <div className="userInfoTitle">회원 정보</div>
                        <div className="infoContents">
                            <div className="infoTextBox">
                                <div className="itemRow">
                                    <span className="title">이름</span>
                                    <span className="text">
                                        {detailData.user.info.uname ? detailData.user.info.uname : "미입력"}
                                    </span>
                                </div>
                                <div className="itemRow">
                                    <span className="title">연락처</span>
                                    <span className="text">
                                        {ConvertPhoneNumber(detailData.user.info.phone).length === 0
                                            ? "미입력"
                                            : ConvertPhoneNumber(detailData.user.info.phone)}
                                    </span>
                                </div>
                                <div className="itemRow">
                                    <span className="title">이메일</span>
                                    <span className="text">
                                        {detailData.user.id.length === 0 ? "미입력" : detailData.user.id}
                                    </span>
                                </div>
                            </div>
                            <div className="infoTextBox">
                                <div className="itemRow">
                                    <span className="title">생년월일</span>
                                    <span className="text">
                                        {detailData.user.info.birthdate ? (
                                            <>
                                                {detailData.user.info.birthdate.slice(0, 4)}.
                                                {detailData.user.info.birthdate.slice(4, 6)}.
                                                {detailData.user.info.birthdate.slice(6, 8)}
                                            </>
                                        ) : (
                                            <>미입력</>
                                        )}
                                    </span>
                                </div>
                                <div className="itemRow">
                                    <span className="title">성별</span>
                                    <span className="text">
                                        {detailData.user.info.gender === "0" ? "여자" : "남자"}
                                    </span>
                                </div>
                                <div className="itemRow">
                                    <span className="title">가입일</span>
                                    <span className="text">
                                        {ConvertDateTime(detailData.createdAt ?? 0) ?? "미입력"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="partnerInfo">
                        <span className="titleSpan">파트너 정보</span>
                        <div className="partnerInfoContents">
                            <div className="partnerItemRow">
                                <span className="title">유형</span>
                                <span className="text">{detailData?.partnerType}</span>
                            </div>
                            <div className="partnerItemRow">
                                <span className="title">닉네임</span>
                                <span className="text">{detailData.name ? detailData.name : "미입력"}</span>
                            </div>
                            <div className="partnerItemRow">
                                <span className="title">참고 및 요청사항</span>
                                <span className="text">{detailData.reference ? detailData.reference : "미입력"}</span>
                            </div>
                            {detailData.status !== "request" && detailData.status !== "reject" && (
                                <>
                                    <div className="partnerItemRow">
                                        <span className="title">정산계좌</span>
                                        <span className="text">
                                            {detailData.account ? (
                                                <>
                                                    {detailData?.account?.account
                                                        ? `${detailData.account.bank} ${detailData.account.account}`
                                                        : "미입력"}
                                                </>
                                            ) : (
                                                <>미입력</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="partnerItemRow">
                                        <span className="title">파트너코드</span>
                                        <span className="text">{detailData.code ? detailData.code : "미입력"}</span>
                                    </div>
                                </>
                            )}
                            <div className="partnerItemRow">
                                <span className="title bold">관리자 메모</span>
                                <span className="text">{detailData.memo ? detailData.memo : "미입력"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledContentsBox>
        </>
    );
};

export default PartnerDetailBox;

const StyledContentsBox = styled.div`
    width: 1000px;
    height: fit-content;
    .titleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 13px;
        height: 43px;
        width: 100%;
        background-color: #fbf4fe;
        border: 1px solid #d8d8d8;
        & > span {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
        }
    }
    .contents {
        display: flex;
        flex-direction: column;
        height: fit-content;
        border-top: none;
        border: 1px solid #d8d8d8;
        padding: 17px 26px;
        gap: 42px;
        .partnerStatus {
            display: flex;
            align-items: center;
            gap: 20px;
            & > span {
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
            }
            .labelDiv {
                width: fit-content;
                height: 26px;
            }
        }
        .userInfo {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .userInfoTitle {
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
            }
            .infoContents {
                display: flex;
                gap: 32px;
                .infoTextBox {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    .itemRow {
                        display: flex;
                        gap: 24px;
                        & > .title {
                            width: 64px;
                            height: 21px;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            color: #9d9fa5;
                        }
                        & > .text {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 21px;
                            color: #222222;
                        }
                    }
                }
            }
        }
        .partnerInfo {
            display: flex;
            flex-direction: column;
            gap: 17px;
            & > .titleSpan {
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
            }
            .partnerInfoContents {
                display: flex;
                flex-direction: column;
                gap: 9px;
                .partnerItemRow {
                    display: flex;
                    gap: 24px;
                    & > .title {
                        width: 98px;
                        height: 21px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        color: #9d9fa5;
                    }
                    & > .text {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        color: #222222;
                    }
                    & > .bold {
                        font-weight: 700;
                        color: #222222;
                    }
                }
            }
        }
    }
`;
