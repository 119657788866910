import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { DropDown } from "@src/components";
// import { BannerType } from "@src/constants/BannerType";
import { useDispatch, useSelector } from "react-redux";
import { setBannerType } from "@src/reducers/bannerType";
import bannerView, { setBannerView } from "@src/reducers/bannerView";
import { BannerView } from "@src/constants/BannerView";

interface BannerIsOpenSelectorProps {
    exposure: string;
    updateData: any;
    handleFunction: (checked: any) => void;
}

function BannerIsOpenSelector({
    exposure,
    updateData,
    handleFunction,
}: BannerIsOpenSelectorProps) {
    const dispatch = useDispatch();
    const isOpen = useSelector((state: any) => state.bannerView.type);
    const handleChange = (
        e: ChangeEvent<HTMLSelectElement>,
        text?: string,
        value?: string,
    ) => {
        dispatch(setBannerView({ type: value }));
        handleFunction(value);
    };

    return (
        <>
            {exposure === "create" && (
                <DropDown data={BannerView} handleChange={handleChange} />
            )}
            {exposure === "update" && (
                <DropDown
                    data={BannerView}
                    handleChange={handleChange}
                    defaultValue={updateData.bannerView}
                />
            )}
        </>
    );
}

export default BannerIsOpenSelector;
