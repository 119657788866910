import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { rightsAnalysisSampleData } from "@src/data/AuctionData";

const fetchData = async (productNo: any) => {
    const response = await axiosInstance.get("");
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: [] = responseData.data.list;
    return parse(originData);
};

const parse = (originData: any) => {
    const newData = [];
    newData.push(
        originData.map((item: any) => {
            const { round, bidDate, minPrice, bidResult, returnText } = item;
            return { round, bidDate, minPrice, bidResult, returnText };
        }),
    );
    return newData;
};
const useGetRightAnalysis = (productNo: any) => {
    return useQuery(
        [queryKeys.RIGHTS_ANALYSIS, productNo],
        // () => fetchData(productNo),
        {
            initialData: rightsAnalysisSampleData,
        },
    );
};
export { useGetRightAnalysis };
