import styled from "@emotion/styled";
import { Checkbox, DropDown, Input, RangeCalendar } from "@src/components";
import { Common } from "@src/styles/Common";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import React, {
    Dispatch,
    Fragment,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { userExpertListDto } from "./types";

interface ExpertUserFilterProps {
    filters: userExpertListDto;
    setFilters: Dispatch<SetStateAction<userExpertListDto>>;
    expertChecked: any;
    setExpertChecked: Dispatch<SetStateAction<any>>;
    counselingChecked: any;
    setCounselingChecked: Dispatch<SetStateAction<any>>;
}

const ExpertUserFilter = ({
    filters,
    setFilters,
    expertChecked,
    setExpertChecked,
    counselingChecked,
    setCounselingChecked,
}: ExpertUserFilterProps) => {
    const dropList: {
        id: number;
        key: string;
        value: string;
    }[] = [
        { id: 0, key: "userInfo.uname", value: "이름" },
        { id: 1, key: "userInfo.phone", value: "휴대폰번호" },
        { id: 2, key: "userInfo.uname", value: "사무소명" },
    ];
    const dateLabel = [
        //날짜 라디오 버튼
        { id: 0, key: 0, value: "전체" },
        { id: 1, key: 1, value: "오늘" },
        { id: 2, key: 2, value: "7일" },
        { id: 3, key: 3, value: "1개월" },
        { id: 4, key: 4, value: "6개월" },
        { id: 5, key: 5, value: "12개월" },
    ];

    const TODAY = ConvertDate(new Date().getTime());
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const before7day = ConvertDate(new Date(year, month, day - 7).getTime());
    const before1Month = ConvertDate(new Date(year, month - 1, day).getTime());
    const before6Month = ConvertDate(new Date(year, month - 6, day).getTime());
    const before12Month = ConvertDate(new Date(year - 1, month, day).getTime());
    const [selectedDate, setSelectedDate] = useState<number | null>(0);

    const renderDate = (item: number) => {
        if (item == 0) {
            setFilters({
                ...filters,
                searchStartDate: "",
                searchEndDate: "",
            });
        } else if (item == 2) {
            setFilters({
                ...filters,
                searchStartDate: before7day,
                searchEndDate: TODAY,
            });
        } else if (item == 3) {
            setFilters({
                ...filters,
                searchStartDate: before1Month,
                searchEndDate: TODAY,
            });
        } else if (item == 4) {
            setFilters({
                ...filters,
                searchStartDate: before6Month,
                searchEndDate: TODAY,
            });
        } else if (item == 5) {
            setFilters({
                ...filters,
                searchStartDate: before12Month,
                searchEndDate: TODAY,
            });
        } else {
            // 오늘
            setFilters({
                ...filters,
                searchStartDate: TODAY,
                searchEndDate: TODAY,
            });
        }
        setSelectedDate(item);
    };

    // const initExpertChecked = {
    //     noneCounselingCategoryTrue: false,
    //     noneCounselingCategoryFalse: false,
    //     scrivener: false,
    //     agent: false,
    // };
    // const [expertChecked, setExpertChecked] = useState<any>(initExpertChecked);
    // const initCounselingChecked = {
    //     noneCounselingCategory: false,
    //     agent: false,
    //     claim: false,
    //     item: false,
    // };
    // const [counselingChecked, setCounselingChecked] = useState<any>(
    //     initCounselingChecked,
    // );

    const tryChangeExpertChecked = (text: string, value: boolean) => {
        if (text === "agent" && value) {
            setExpertChecked({
                ...expertChecked,
                [text]: value,
                noneCounselingCategoryFalse: true,
                noneCounselingCategoryTrue: true,
            });
        } else if (
            (text === "noneCounselingCategoryFalse" &&
                value &&
                !expertChecked.noneCounselingCategoryTrue) ||
            (text === "noneCounselingCategoryFalse" &&
                !value &&
                expertChecked.noneCounselingCategoryTrue)
        ) {
            setExpertChecked({
                ...expertChecked,
                [text]: value,
                agent: false,
            });
        } else if (
            (text === "noneCounselingCategoryTrue" &&
                value &&
                !expertChecked.noneCounselingCategoryFalse) ||
            (text === "noneCounselingCategoryTrue" &&
                !value &&
                expertChecked.noneCounselingCategoryFalse)
        ) {
            setExpertChecked({
                ...expertChecked,
                [text]: value,
                agent: false,
            });
        } else {
            setExpertChecked({
                ...expertChecked,
                [text]: value,
            });
        }
    };
    useEffect(() => {
        if (
            expertChecked.agent &&
            !filters.types?.join(",").includes("agent")
        ) {
            if (filters.types?.length) {
                setFilters({
                    ...filters,
                    types: filters.types?.concat("agent"),
                });
            } else {
                setFilters({ ...filters, types: ["agent"] });
            }
        } else if (
            (expertChecked.noneCounselingCategoryFalse &&
                expertChecked.noneCounselingCategoryTrue) ||
            (!expertChecked.noneCounselingCategoryFalse &&
                !expertChecked.noneCounselingCategoryTrue)
        ) {
            delete filters.isAgent;
        } else if (expertChecked.noneCounselingCategoryFalse) {
            setFilters({
                ...filters,
                isAgent: false,
                types: filters.types?.filter((item) => item !== "agent"),
            });
        } else if (expertChecked.noneCounselingCategoryTrue) {
            setFilters({
                ...filters,
                isAgent: true,
                types: filters.types?.filter((item) => item !== "agent"),
            });
        }
        if (
            expertChecked.scrivener &&
            !filters.types?.join(",").includes("scrivener")
        ) {
            if (filters.types?.length) {
                setFilters({
                    ...filters,
                    types: filters.types?.concat("scrivener"),
                });
            } else {
                setFilters({ ...filters, types: ["scrivener"] });
            }
        }
        if (
            !expertChecked.scrivener &&
            filters.types?.join(",").includes("scrivener")
        ) {
            setFilters({
                ...filters,
                types: filters.types?.filter((item) => item !== "scrivener"),
            });
        }
    }, [expertChecked]);

    const tryChangeCounselingChecked = (text: string, value: boolean) => {
        setCounselingChecked({ ...counselingChecked, [text]: value });
    };

    useEffect(() => {
        const newList = Object.entries(counselingChecked)
            .filter(
                ([name, value]) =>
                    value === true && name !== "noneCounselingCategory",
            )
            .map(([name, _]) => name);
        setFilters({
            ...filters,
            noneCounselingCategory: counselingChecked.noneCounselingCategory,
            counselingCategories: newList,
        });
    }, [counselingChecked]);
    return (
        <>
            <StyledExpertUserFilter>
                <ul>
                    <li>
                        <span className="subTitle">검색</span>
                        <div>
                            <DropDown
                                handleChange={(e, text, value) => {
                                    value &&
                                        setFilters({
                                            ...filters,
                                            searchType: value,
                                        });
                                }}
                                data={dropList}
                                defaultValue={filters.searchType}
                                // value={filters.searchType ?? ""}
                            />
                            <Input
                                label=""
                                value={filters.searchValue ?? ""}
                                name="searchInput"
                                type="text"
                                size="medium"
                                handleChange={(e) => {
                                    setFilters({
                                        ...filters,
                                        searchValue: e.target.value,
                                    });
                                }}
                                isDisabled={false}
                                errorMessage=""
                                placeholder="이름, 휴대폰번호, 사무소명을 입력하세요"
                            />
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">등록 일시</span>
                        <div>
                            {dateLabel.map((item) => (
                                <div key={item.id}>
                                    <button
                                        value={item.value}
                                        className={
                                            "datebtn" +
                                            (item.key == selectedDate
                                                ? " active"
                                                : "")
                                        }
                                        onClick={() => renderDate(item.key)}
                                    >
                                        {item.value}
                                    </button>
                                </div>
                            ))}
                            <RangeCalendar
                                setRangeDate={{
                                    startDate:
                                        filters.searchStartDate !== null &&
                                        filters.searchStartDate !== undefined
                                            ? dateParse(filters.searchStartDate)
                                            : null,

                                    endDate:
                                        filters.searchEndDate !== null &&
                                        filters.searchEndDate !== undefined
                                            ? dateParse(filters.searchEndDate)
                                            : null,
                                }}
                                getUserRangeDate={(date, isError) => {
                                    const { startDate: start, endDate: end } =
                                        date;
                                    setFilters({
                                        ...filters,
                                        searchStartDate: start
                                            ? ConvertDate(start)
                                            : "",
                                        searchEndDate: end
                                            ? ConvertDate(end)
                                            : "",
                                    });
                                }}
                                verticalAlign={false}
                                isDisabled={false}
                                timePicker={false}
                                clear={false}
                            />
                        </div>
                    </li>

                    <li>
                        <span className="subTitle">전문가</span>
                        <div className="chkBoxDivWrap">
                            <div className="chkBoxDiv">
                                {Object.keys(expertChecked)
                                    .reverse()
                                    .map((check: any, idx) => (
                                        <Fragment key={idx}>
                                            {idx < 2 && (
                                                <Checkbox
                                                    key={idx.toString()}
                                                    isDisabled={false}
                                                    isChecked={
                                                        expertChecked[check]
                                                    }
                                                    label={
                                                        idx === 0
                                                            ? `공인중개사`
                                                            : idx === 1
                                                            ? `법무사`
                                                            : idx === 2
                                                            ? `공인중개사(경매대리X)`
                                                            : `공인중개사(경매대리O)`
                                                    }
                                                    defaultValue={check}
                                                    handleChange={(n, v) => {
                                                        tryChangeExpertChecked(
                                                            n,
                                                            v,
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                            </div>
                            <div className="chkBoxDiv">
                                {Object.keys(expertChecked)
                                    .reverse()
                                    .map((check: any, idx) => (
                                        <Fragment key={idx}>
                                            {idx > 1 && (
                                                <Checkbox
                                                    key={idx.toString()}
                                                    isDisabled={false}
                                                    isChecked={
                                                        expertChecked[check]
                                                    }
                                                    label={
                                                        idx === 0
                                                            ? `공인중개사`
                                                            : idx === 1
                                                            ? `법무사`
                                                            : idx === 2
                                                            ? `공인중개사(경매대리X)`
                                                            : `공인중개사(경매대리O)`
                                                    }
                                                    defaultValue={check}
                                                    handleChange={(n, v) => {
                                                        tryChangeExpertChecked(
                                                            n,
                                                            v,
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                            </div>
                        </div>
                    </li>
                    <li>
                        <span className="subTitle">상담분야</span>
                        <div className="chkBoxDiv">
                            {Object.keys(counselingChecked)
                                .reverse()
                                .map((check, idx) => (
                                    <Checkbox
                                        key={check}
                                        isDisabled={false}
                                        isChecked={counselingChecked[check]}
                                        label={
                                            idx === 0
                                                ? "매물상담"
                                                : idx === 1
                                                ? "경공매상담"
                                                : idx === 2
                                                ? `경매 매수신청대리`
                                                : `선택안함`
                                        }
                                        defaultValue={check}
                                        handleChange={(n, v) => {
                                            tryChangeCounselingChecked(n, v);
                                            // const {
                                            //     setCheckedListSub,
                                            //     subType,
                                            // } = handleSubStatusChange(n, v);
                                            // setFilters({
                                            //     ...filters,
                                            //     subType: subType.join(),
                                            // });
                                            // setSubChecked(setCheckedListSub);
                                        }}
                                    />
                                ))}
                        </div>
                    </li>
                </ul>
            </StyledExpertUserFilter>
        </>
    );
};
export default ExpertUserFilter;

const StyledExpertUserFilter = styled.div`
    margin-top: 20px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: ${Common.colors.gray100};
    li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &:last-of-type {
            margin-bottom: 0;
        }
        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .subTitle {
        ${Common.textStyle.title6_R14}
        color: ${Common.colors.black};
        display: inline-block;
        width: 80px;
    }
    select {
        border-width: 1px;
    }
    input[name="searchInput"] {
        margin-left: 12px;
        height: 36px;
        border: 1px solid ${Common.colors.gray200};
        border-radius: 8px;
        &::placeholder {
            color: ${Common.colors.gray500};
        }
    }
    .chkBoxDivWrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }
    .chkBoxDiv {
        display: flex;
    }
    .chkBoxDiv > div {
        margin-right: 16px;
    }
    .datebtn {
        padding: 10px;
        margin: 5px;
        background-color: white;
        border: 1px solid gray;
        cursor: pointer;
        border-radius: 8px;
    }
    .datebtn.active {
        font-weight: 700;
    }
`;
