import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { DropDown } from "@src/components";
import { BannerType } from "@src/constants/BannerType";
import { useDispatch, useSelector } from "react-redux";
import { setBannerType } from "@src/reducers/bannerType";

interface BannerTypeSelectorProps {
    setParams: Dispatch<SetStateAction<any>>;
}

function BannerTypeSelector({ setParams }: BannerTypeSelectorProps) {
    const dispatch = useDispatch();
    const type = useSelector((state: any) => state.bannerType.type);
    const handleChange = (
        e: ChangeEvent<HTMLSelectElement>,
        text?: string,
        value?: string,
    ) => {
        dispatch(setBannerType({ type: value }));
        setParams({ page: 1, limit: 10, value });
    };

    return (
        <>
            <DropDown
                data={BannerType}
                handleChange={handleChange}
                defaultValue={type}
            />
        </>
    );
}

export default BannerTypeSelector;
