export const queryList = {
    totalCount: 0,
    originData: [],
    cursor: "",

    setTotalCount: function (dataCount: number) {
        this.totalCount = dataCount;
    },
    setCursor: function (data: string) {
        this.cursor = data;
    },
    setDataList: function (dataList: any) {
        if (dataList.length) {
            this.originData = dataList;
        }
    },
    getData: function (parse: Function) {
        return {
            result: this.totalCount > 0 ? parse(this.originData) : [],
            total: this.totalCount,
            cursor: this.cursor,
        };
    },
};
