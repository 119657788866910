import React, { ChangeEvent, useEffect, useState } from "react";
import "@src/styles/CommonTable.css";
import { Input } from "@src/components";
import { validNumberCheck } from "@src/utils/validationCheck";
import { useSelector } from "react-redux";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";

interface ISaleCase {
    isUpdate: boolean;
    handleUpdate?: (update: any) => void;
}
interface DefaultStateType {
    id?: number;
    period?: string;
    insuredCasesCount?: string;
    winningBidCount?: string;
    winningBidRate?: string;
    winningBidRateAppraisalL?: string;
    winningBidRateMinBidPrice?: string;
}
function SaleCaseContainer({ isUpdate = false, handleUpdate }: ISaleCase) {
    const headerMeta = [
        "통계기간(개월)",
        "부찰건수(건)",
        "낙찰건수(건)",
        "낙찰율(%)",
        "낙찰가율 감정가 대비(%)",
        "낙찰가율 최저입찰가 대비(%)",
    ];
    const [tableData, setTableData] = useState<any>();
    const defaultData = useSelector((state: any) => state.saleCase);

    useEffect(() => {
        setTableData(defaultData);
    }, [defaultData]);

    const handleChange = (updateData: any, index: number) => {
        let newTableData = [...tableData];
        newTableData[index] = updateData;
        setTableData([...newTableData]);
    };

    useEffect(() => {
        if (handleUpdate !== undefined) {
            handleUpdate(tableData);
        }
    }, [tableData]);

    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: DefaultStateType) => {
                            return (
                                <TableRow
                                    key={data.id}
                                    data={data}
                                    isUpdate={isUpdate}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}
const TableRow = ({ data, isUpdate, handleChange }: any) => {
    const [rowData, setRowData] = useState<DefaultStateType>(data);
    useEffect(() => {
        setRowData(data);
    }, [data]);
    const {
        period,
        insuredCasesCount,
        winningBidCount,
        winningBidRate,
        winningBidRateAppraisalL,
        winningBidRateMinBidPrice,
    } = rowData;
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let [validCheckResult, validCheckValue] = validNumberCheck(value);
        if (validCheckResult) {
            setRowData({
                ...rowData,
                [name]: validCheckValue,
            });
        }
    };
    useEffect(() => {
        handleChange(rowData, data.id);
    }, [rowData]);
    return (
        <tr
            key={data.id}
            style={isUpdate ? { borderBottom: "none" } : undefined}
        >
            <td>
                <Input
                    name="period"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={ConvertEmptyValue(period)}
                />
            </td>
            <td>
                <Input
                    name="insuredCasesCount"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? insuredCasesCount
                            : resultPrice(insuredCasesCount)
                    }
                />
            </td>
            <td>
                <Input
                    name="winningBidCount"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? winningBidCount
                            : resultPrice(winningBidCount)
                    }
                />
            </td>
            <td>
                <Input
                    name="winningBidRate"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? winningBidRate
                            : ConvertEmptyValue(winningBidRate)
                    }
                />
            </td>
            <td>
                <Input
                    name="winningBidRateAppraisalL"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? winningBidRateAppraisalL
                            : ConvertEmptyValue(winningBidRateAppraisalL)
                    }
                />
            </td>
            <td>
                <Input
                    name="winningBidRateMinBidPrice"
                    errorMessage={""}
                    handleChange={onChange}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={
                        isUpdate
                            ? winningBidRateMinBidPrice
                            : ConvertEmptyValue(winningBidRateMinBidPrice)
                    }
                />
            </td>
        </tr>
    );
};
export default SaleCaseContainer;
