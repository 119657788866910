import React, { Fragment, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { EditModal } from "@src/components";
import { useGetLogList } from "@src/hooks/queries/useDeungiChange";
import useInfiniteScroll from "react-infinite-scroll-hook";

interface Props {
    open: boolean;
    onCloseView: () => void;
    data: any;
}

const DeungiChangeAlarmLogModal: React.FC<Props> = ({
    open,
    onCloseView,
    data,
}) => {
    return (
        <>
            <EditModal
                buttonText="닫기"
                handleBtnClick={onCloseView}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="전송내역"
                isModalShow={open}
            >
                <div css={rootStyle}>
                    <div css={tableRootStyle}>
                        <table>
                            <colgroup>
                                <col width="11%" />
                                <col width="12%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="27%" />
                                <col width="15%" />
                                <col width="15%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>접수일자</th>
                                    <th>사유</th>
                                    <th>처리상태</th>
                                    <th>접수번호</th>
                                    <th>관할등기소</th>
                                    <th>전송일시</th>
                                    <th>방식</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.list?.length > 0 ? (
                                    data.list?.map((item: any, idx: number) => (
                                        <tr key={idx?.toString()}>
                                            <td>{item?.receptionDate}</td>
                                            <td>{item?.purpose}</td>
                                            <td>{item?.state}</td>
                                            <td>{item?.id}</td>
                                            <td>{item?.depart}</td>
                                            <td>{item?.inDate}</td>
                                            <td>{item?.methods}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={7} css={noDataStyle}>
                                            <div>전송내역이 없습니다.🥲</div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </EditModal>
        </>
    );
};

export default DeungiChangeAlarmLogModal;

const titleRootStyle = css`
    display: flex;
    align-items: center;
    p {
        font-size: 1rem;
        color: #424242;
        font-weight: 600;
        margin-right: 1rem;
        span {
            font-weight: 500;
            color: #333333;
        }
    }
`;

const tableRootStyle = css`
    width: 80vw;
    max-width: 1200px;
    margin-top: 2rem;
    height: 500px;
    overflow: scroll;
    max-height: fit-content;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            background-color: #eeeeee;
            position: sticky;
            top: 0px;
        }
        th {
            padding: 1.2rem;
            span {
                color: #ff0000;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
            border-bottom: 1px solid #b5b5b5;
        }
    }
`;

const scrollStyle = css`
    height: 3rem;
`;

const rootStyle = css`
    background-color: #ffffff;
`;

const noDataStyle = css`
    padding: 10rem 0 !important;
`;
