import styled from "@emotion/styled";
import {
    Button,
    Checkbox,
    ConfirmModal,
    DropDown,
    EditModal,
    Input,
    Title,
} from "@src/components";
import {
    CancelState,
    NoReservation,
    NormalState,
} from "@src/constants/SubscriptionState";
import {
    useCheckRefundPay,
    useSubscriptionForceDelete,
    useSubscriptionStateEdit,
} from "@src/hooks/queries/useUserDetail";
import { DetailUserProps } from "@src/interfaces/User.interface";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
} from "react-query";
import { subscriptionInfoType } from "./UserDetail";

interface UserSubscriptionStateEditModalProps {
    isModalShow: boolean;
    setIsSubscriptionInfoModalShow: Dispatch<SetStateAction<boolean>>;
    setIsSubscriptionStateEditModalShow: Dispatch<SetStateAction<boolean>>;
    user?: DetailUserProps;
    subscriptionInfo?: subscriptionInfoType;
    refetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<DetailUserProps | null, Error>>;
    showSubscriptionDetail: () => void;
}

const UserSubscriptionStateEditModal = ({
    isModalShow,
    setIsSubscriptionInfoModalShow,
    setIsSubscriptionStateEditModalShow,
    user,
    subscriptionInfo,
    refetch,
    showSubscriptionDetail,
}: UserSubscriptionStateEditModalProps) => {
    const [reasonValue, setReasonValue] = useState<string>("");
    const [subscriptionState, setSubscriptionState] = useState<number>();
    const [refundPrice, setRefuntPrice] = useState<number>();
    const [checked, setChecked] = useState<boolean>(false);
    const [isShowSubsDelete, setIsShowSubsDelete] = useState<boolean>(false);

    const tryGetRefundPrice = async () => {
        if (subscriptionState === 1) {
            const result = await useCheckRefundPay(
                user?.userNo,
                subscriptionInfo?.subs_no,
                subscriptionInfo?.order_code,
            );

            setRefuntPrice(result.data.data.data.refund_price);
        }
    };

    const subscriptionForceDelete = async () => {
        await useSubscriptionForceDelete(user?.userNo);
        location.reload();
    };

    useEffect(() => {
        tryGetRefundPrice();
    }, [subscriptionState]);

    useEffect(() => {
        subscriptionInfo?.paid_price &&
            checked &&
            setRefuntPrice(subscriptionInfo?.paid_price);
    }, [checked]);

    const showContents = () => {
        return (
            <>
                <StyleModalContents>
                    <StyledModalWrapper>
                        <StyledModalItem>
                            <span>구독이름</span>
                            <div className="subText">
                                {subscriptionInfo?.subs_group_name}
                            </div>
                        </StyledModalItem>

                        <StyledModalItem>
                            <span>결제액</span>
                            <div className="subText">
                                {subscriptionInfo?.paid_price?.toLocaleString(
                                    "ko-KR",
                                )}
                            </div>
                        </StyledModalItem>
                        {subscriptionState === 1 && (
                            <div>
                                <Title title="환불정보" />
                                <StyledModalItem>
                                    <span>환불예정액</span>
                                    <StyledInputCheckBox>
                                        <Input
                                            border={false}
                                            errorMessage=""
                                            handleChange={(e: any) => {
                                                setRefuntPrice(e.target.value);
                                            }}
                                            label=""
                                            placeholder="환불예정액"
                                            size="medium"
                                            type="text"
                                            value={refundPrice?.toLocaleString(
                                                "ko-KR",
                                            )}
                                        />
                                        <div className="CheckBoxWrapper">
                                            <Checkbox
                                                defaultValue=""
                                                handleChange={(
                                                    CHECKBOX_ID,
                                                    checked,
                                                    cdisplayName,
                                                ) => {
                                                    setChecked(checked);
                                                }}
                                                label=""
                                            />
                                            <span>전액환불</span>
                                        </div>
                                    </StyledInputCheckBox>
                                </StyledModalItem>
                            </div>
                        )}
                    </StyledModalWrapper>
                    <StyledModalWrapper>
                        <StyledModalItem>
                            <span>상품명</span>
                            <div className="subText">
                                {subscriptionInfo?.product_name}
                            </div>
                        </StyledModalItem>
                        <StyledModalItem>
                            <span>상태</span>

                            {subscriptionInfo?.cancel_reason !==
                            "(해지예약)" ? (
                                <DropDown
                                    // data={NormalState}
                                    data={
                                        subscriptionInfo?.renew_term === "none"
                                            ? NoReservation
                                            : NormalState
                                    }
                                    defaultValue="정상"
                                    handleChange={(e, text, value) => {
                                        value === "cancelReservation" &&
                                            setSubscriptionState(0);
                                        value === "cancel" &&
                                            setSubscriptionState(1);
                                        value === "normal" &&
                                            setSubscriptionState(2);
                                    }}
                                    label=""
                                />
                            ) : (
                                <DropDown
                                    data={CancelState}
                                    defaultValue="해지예약"
                                    handleChange={(e, text, value) => {
                                        value === "cancelReservation" &&
                                            setSubscriptionState(0);
                                        value === "cancel" &&
                                            setSubscriptionState(1);
                                        value === "normal" &&
                                            setSubscriptionState(2);
                                    }}
                                    label=""
                                />
                            )}
                            <Button
                                color="purple"
                                handleClick={() => {
                                    setIsShowSubsDelete(true);
                                }}
                                label="강제 해지"
                                size="small"
                            />
                            <ConfirmModal
                                isModalShow={isShowSubsDelete}
                                buttonText="확인"
                                handleCancel={() => {
                                    setIsShowSubsDelete(false);
                                }}
                                handleOk={() => {
                                    subscriptionForceDelete();
                                }}
                            >
                                정말 강제 해지하시겠습니까?
                            </ConfirmModal>
                        </StyledModalItem>
                    </StyledModalWrapper>
                </StyleModalContents>
                <StyledReasonWrapper>
                    <span>
                        <span className="essential">*</span>사유
                    </span>
                    <input
                        type="text"
                        placeholder="사유를 작성해 주세요 (ex: 사용자 문의(123456번)로 등록)"
                        onChange={(e: any) => {
                            setReasonValue(e.target.value);
                        }}
                        value={reasonValue}
                    />
                </StyledReasonWrapper>
            </>
        );
    };
    return (
        <>
            {(subscriptionInfo?.cancel_reason !== "(해지예약)" &&
                subscriptionState !== 2 &&
                reasonValue) ||
            (subscriptionInfo?.cancel_reason === "(해지예약)" &&
                subscriptionState !== 0 &&
                reasonValue) ? (
                <EditModal
                    isModalShow={isModalShow}
                    buttonText="저장"
                    handleBtnClick={() => {
                        useSubscriptionStateEdit(
                            user?.userNo,
                            subscriptionInfo?.subs_no,
                            subscriptionInfo?.order_code,
                            refundPrice,
                            reasonValue,
                            subscriptionState,
                        ).then(() => {
                            refetch();
                            showSubscriptionDetail();
                            setReasonValue("");
                        });
                    }}
                    handleOpenModal={() =>
                        setIsSubscriptionStateEditModalShow(false)
                    }
                    size="big"
                    title="구독 상태 변경"
                    deleteButton={false}
                >
                    {showContents()}
                </EditModal>
            ) : (
                <EditModal
                    isModalShow={isModalShow}
                    buttonText="저장"
                    handleBtnClick={function noRefCheck() {}}
                    handleOpenModal={() =>
                        setIsSubscriptionStateEditModalShow(false)
                    }
                    size="big"
                    title="구독 상태 변경"
                    deleteButton={false}
                    isDisabled
                >
                    {showContents()}
                </EditModal>
            )}
        </>
    );
};

export default UserSubscriptionStateEditModal;

const StyledModalItem = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 28px;
    & > span {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        width: 88px;
        height: 20px;
        display: flex;
        align-items: center;
    }
    & > .subText {
        font-size: 14px;
        font-weight: 400;
        color: #222222;
    }
`;

const StyledModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 50%;
`;

const StyleModalContents = styled.div`
    display: flex;
    gap: 35px;
    width: 100%;
    margin-bottom: 40px;
`;

const StyledReasonWrapper = styled.div`
    position: absolute;
    bottom: 20px;
    width: 648px;
    height: 48px;
    display: flex;
    gap: 16px;
    align-items: center;
    & > span {
        font-weight: 400;
        font-size: 13px;
        width: 80px;
        .essential {
            color: #f45b5b;
        }
    }
    & > input {
        height: 100%;
        width: calc(100% - 96px);
        padding: 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 170%;
        color: #283237;
        border: 1px solid #e3e3e6;
        border-radius: 8px;
        outline: none;
    }
`;

const StyledInputCheckBox = styled.div`
    display: flex;
    width: calc(100% - 116px);
    border-bottom: 1px solid #e3e3e6;
    & > .CheckBoxWrapper {
        display: flex;
        align-items: center;
        gap: 4px;
        & > span {
            font-weight: 400;
            font-size: 14px;
            color: #000000;
        }
    }
    & > input {
        width: 75%;
    }
`;

const StyledNextPaidText = styled.div<{ value: boolean }>`
    font-size: 14px;
    font-weight: 400;

    color: ${(value) => (value ? "#222222" : "#D9D9D9")};
`;
