import { axiosInstance } from "./axiosConfig";
import { axiosPath } from "./axiosPath";
class AuthService {
    constructor() {
        this.api = {
            login: axiosPath.login_v2,
        };
    }

    executeService(username, password) {
        return axiosInstance.post(this.api.login, {
            id: username,
            passwd: password,
        });
    }

    registerLoginSuccess(username, token) {
        localStorage.setItem("token", token);
        localStorage.setItem("user", username);
    }

    logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
    }

    isUserLogin() {
        const token = localStorage.getItem("token");

        return token ? true : false;
    }

    getUserName() {
        let user = localStorage.getItem("user");
        return user === null ? "" : user;
    }
}

export default new AuthService();
