import styled from "@emotion/styled";
import { Button, Title } from "@src/components";
import { IUserStateLandData } from "@src/hooks/queries/useStateLand";
import { IUserRoadExpansionData } from "@src/hooks/queries/userRoadExpansion";
import React, { Dispatch, SetStateAction } from "react";

interface ProductInfoContainerProps {
    setIsProductModalShow: Dispatch<SetStateAction<boolean>>;
    deungiTicketCnt: number;
    existingDeunggiCount: any;
    classCount: number;
    isShowSugangModal: () => void;
    isShowLiveDeunggiModal: () => void;
    refetchExistingDeunggiCount: any;
    adTicket: number;
    isShowAdTicket: () => void;
    isShowStateLandModal: () => void;
    isShowRoadExpansionModal: () => void;
    userStateLandData?: IUserStateLandData;
    userRoadExpansionData?: IUserRoadExpansionData;
    goStopProductName?: string;
    isShowGoStopModal: () => void;
    storeGoStopProductName?: string;
    isShowStoreGoStopModal: () => void;
}

const ProductInfoContainer = ({
    setIsProductModalShow,
    deungiTicketCnt,
    isShowSugangModal,
    classCount,
    isShowLiveDeunggiModal,
    existingDeunggiCount,
    refetchExistingDeunggiCount,
    isShowRoadExpansionModal,
    adTicket,
    isShowAdTicket,
    isShowStateLandModal,
    userStateLandData,
    userRoadExpansionData,
    goStopProductName,
    isShowGoStopModal,
    storeGoStopProductName,
    isShowStoreGoStopModal,
}: ProductInfoContainerProps) => {
    return (
        <>
            <Title title="상품정보" size="small" />
            <StyledContents>
                <StyledRow>
                    <span>실시간 등기열람권</span>
                    <StyledSubText>{deungiTicketCnt}장</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={() => {
                            // navigate("/commodity/provision");
                            setIsProductModalShow(true);
                        }}
                        label="등기열람권 정보"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>기존 등기열람권</span>
                    <StyledSubText>{existingDeunggiCount?.curHaveCnt ?? 0}장</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowLiveDeunggiModal}
                        label="등기열람권 정보"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>수강강의</span>
                    <StyledSubText>{classCount}개</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowSugangModal}
                        label="수강강의 정보"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>매물광고권</span>
                    <StyledSubText>{adTicket}개</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowAdTicket}
                        label="매물광고권 정보"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>국공유지 필터 이용권</span>
                    <StyledSubText>
                        {userStateLandData?.productName === "" ? "이용권 없음" : userStateLandData?.productName}
                    </StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowStateLandModal}
                        label="국공유지 필터 정보"
                        size="small"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>확장예정 도로 데이터맵 이용권</span>
                    <StyledSubText>
                        {userRoadExpansionData?.productName === "" ? "이용권 없음" : userRoadExpansionData?.productName}
                    </StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowRoadExpansionModal}
                        label="확장예정 도로 필터 정보"
                        size="medium"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>주거/토지 수익 필터</span>
                    <StyledSubText>{!goStopProductName ? "이용권 없음" : goStopProductName}</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowGoStopModal}
                        label="주거/토지 수익 필터 정보"
                        size="medium"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
                <StyledRow>
                    <span>상가 수익률 고스톱</span>
                    <StyledSubText>{!storeGoStopProductName ? "이용권 없음" : storeGoStopProductName}</StyledSubText>
                    <Button
                        color="gd"
                        handleClick={isShowStoreGoStopModal}
                        label="상가 수익률 고스톱정보"
                        size="medium"
                        styles={{
                            width: 123,
                            height: 23,
                        }}
                    />
                </StyledRow>
            </StyledContents>
        </>
    );
};

export default ProductInfoContainer;

const StyledContents = styled.div`
    margin-top: 20px;
    gap: 12px;
    padding-bottom: 40px;
`;

const StyledRow = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    & > span {
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        white-space: nowrap;
        width: 160px;
    }
`;

const StyledSubText = styled.span`
    font-size: 14px;
    font-weight: 400;
    color: #9a49f2;
    margin: 0;
    min-width: 50px !important;
    width: fit-content !important;
`;
