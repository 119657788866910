import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import {
    BaseUser,
    ListUserProps,
    FilterUserProps,
    ReadUserListDto,
} from "@src/interfaces/User.interface";
import { ConvertDate } from "@src/utils/dateUtil";
import { queryList } from "./queryList";
import { CommodityFilterType } from "@src/pages/commodity/CommodityFilter";
import {
    BaseCommodity,
    BasePayment,
    CommodityList,
    CreateProvisionParams,
    PaymentList,
    ReadCommodityListDto,
    ReadPaymentListDto,
} from "@src/interfaces/Commodity.interface";
import { CommodityDataType } from "@src/pages/commodity/CommodityCreateContents";
import { ProductDataType } from "@src/pages/commodity/provision/ProvisionUpdate";

export interface PaymentFilterType {
    page?: number;
    limit?: number;
}

const fetchData = async (params: PaymentFilterType) => {
    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");
    const url = `${axiosPath.nestFreeIssue}?${queryString}`;
    const response = await axiosInstance.get(url);
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;

    const originData: BasePayment[] = responseData.list;
    queryList.setDataList(originData);

    if (Object.hasOwnProperty.call(params, "page")) {
        params.page === 1 && queryList.setTotalCount(responseData.totalCount);
        // queryList.setTotalCount(6);
    } else {
        queryList.setTotalCount(responseData.totalCount);
        // queryList.setTotalCount(6);
    }

    return queryList.getData(parse);
};

const parse = (originData: BasePayment[]) => {
    // const newData: PaymentList[] = [];

    const newData = originData.map((item, index) => {
        return {
            no: item.no,
            code: item.code,
            name: item.name,
            status: item.status,
            comments: item.comments,
            createdAt: item.createdAt,
            isSentPush: item.isSentPush,
            user: item.no,
            adminName: item.updaterName,
            subsGroupName: item.subsGroupName,
            viewMore: item.no,
            type: item.type,
        };
    });

    return newData;
};

const getQueryString = (searchParams: PaymentFilterType) => {
    let queryParams: any = {};
    const { limit, page } = searchParams;
    const createParams: ReadPaymentListDto = {
        limit: Number(limit),
        page: Number(page),
    };
    Object.keys(createParams).map((value: any) => {
        let name = value as keyof ReadPaymentListDto;
        if (createParams[name]) {
            queryParams[name] = createParams[name];
        }
    });
    return queryParams;
};

const useCommodityProvisionList = (
    params: PaymentFilterType,
    enabled: boolean = true,
) => {
    const queryParams = getQueryString(params);

    return useQuery(
        [queryKeys.COMMODITY_PROVISION_LIST, queryParams],
        () => fetchData(queryParams),
        {
            enabled,
        },
    );
};

const useGetProducts = async () => {
    // const url = `${axiosPath.commodity}/free_issue_products`;
    const url = `${axiosPath.nestProducts}/free-issue`;
    const response = await axiosInstance.get(url);

    return response;
};

const useGetPushCode = async (params: any) => {
    const queryParams = getQueryString(params);

    const queryString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

    const url = `${axiosPath.postboxSituation}/?${queryString}`;
    const response = await axiosInstance.get(url);

    return response;
};

const useGetViewData = async (no: any) => {
    const url = `${axiosPath.nestFreeIssue}/${no}`;
    const response = await axiosInstance.get(url);

    return response;
};

const useCreateProvision = async (productData: ProductDataType) => {
    // const body = {
    //     type: productData.type,
    //     code: productData.code,
    //     name: productData.name,
    //     subs_group: productData.subs_group,
    //     subs_group_name: productData.subs_group_name,
    //     postbox_situation_code: productData.postbox_situation_code,
    //     issue_type: productData.issue_type,
    //     status: 200,
    //     comments: productData.comments,
    // };
    const body = {
        productNo: productData.productNo,
        comments: productData.comments,
    };

    const url = `${axiosPath.nestFreeIssue}`;
    const response = await axiosInstance.post(url, body);

    return response;
};

const useUpdateProvision = async (productData: ProductDataType) => {
    const body = {
        // type: productData.type,
        // code: productData.code,
        productNo: productData.productNo,
        // subs_group: productData.subs_group,
        // subs_group_name: productData.subs_group_name,
        // postbox_situation_code: productData.postbox_situation_code,
        // issue_type: productData.issue_type,
        // status: 200,
        comments: productData.comments,
    };
    const url = `${axiosPath.nestFreeIssue}/${productData.no}`;
    const response = await axiosInstance.put(url, body);

    return response;
};

const useDeleteProvision = async (no: number) => {
    const url = `${axiosPath.nestFreeIssue}/${no}`;
    const response = await axiosInstance.delete(url);

    return response;
};

export {
    useCommodityProvisionList,
    useGetProducts,
    useGetPushCode /*getQueryString*/,
    useGetViewData,
    useCreateProvision,
    useUpdateProvision,
    useDeleteProvision,
};
