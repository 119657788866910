import React, { ChangeEvent, useEffect, useState } from "react";

import { css } from "@emotion/react";
import "@src/styles/CommonTable.css";
import { Calendar, Input, RangeCalendar } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { useSelector } from "react-redux";
import { BidHistoryStateType } from "@src/types";
import { ConvertEmptyValue, resultPrice } from "@src/utils/textUtil";
import { dateParse } from "@src/utils/dateUtil";

interface IBidHistoryContainer {
    isUpdate: boolean;
    handleUpdate?: Function;
}

function BidHistoryContainer({
    isUpdate = false,
    handleUpdate,
}: IBidHistoryContainer) {
    const headerMeta = [
        "입찰번호",
        "회차/차수",
        "대금납부/기한",
        "입찰기간",
        "개찰일시",
        "매각결정일시",
        "최저입찰가",
    ];
    const defaultData = useSelector((state: any) => state.bidHistory);
    const [tableData, setTableData] =
        useState<BidHistoryStateType[]>(defaultData);
    const [updateCount, setUpdateCount] = useState<number>(0);

    useEffect(() => {
        setTableData([...defaultData]);
        setUpdateCount(updateCount + 1);
    }, [defaultData]);

    const handleChange = (updateData: BidHistoryStateType, id: string) => {
        let newTableData = [...tableData];
        const index = newTableData.findIndex((v) => v.id === id);
        newTableData[index] = updateData;

        setTableData(newTableData);
    };

    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);
    return (
        <div>
            {tableData && (
                <table className="commonTable">
                    <thead>
                        <tr>
                            {headerMeta.map((headerText, index) => (
                                <th key={index}>{headerText}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data: any, index: number) => {
                            return (
                                <TableRow
                                    key={`${updateCount}_${index}`}
                                    item={{ ...data }}
                                    isUpdate={isUpdate}
                                    handleChange={handleChange}
                                />
                            );
                        })}
                    </tbody>
                </table>
            )}
        </div>
    );
}

const TableRow = ({ item, isUpdate, handleChange }: any) => {
    const [rowData, setRowData] = useState<BidHistoryStateType>(item);
    const {
        id,
        text,
        limit,
        bidStartDate,
        bidEndDate,
        openDate,
        saleDate,
        minPrice,
    } = rowData;

    useEffect(() => {
        handleChange(rowData, item.id);
    }, [rowData]);
    const onChangeDate = (name: string, value: number) => {
        setRowData((prev) => ({
            ...prev,
            [name]: ConvertDate(value, "yyyy.MM.dd hh:mm:ss"),
        }));
    };
    const onChangeRangeDate = ([startDate, endDate]: any) => {
        setRowData((prev) => ({
            ...prev,
            bidStartDate: ConvertDate(startDate, "yyyy.MM.dd hh:mm:ss"),
            bidEndDate: ConvertDate(endDate, "yyyy.MM.dd hh:mm:ss"),
        }));
    };
    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newRowData = { ...rowData };
        let key: keyof BidHistoryStateType;

        for (key in newRowData) {
            if (key === name) {
                newRowData[name] = value;
            }
        }
        setRowData({ ...newRowData });
    };

    return (
        <tr
            key={item.id}
            style={isUpdate ? { borderBottom: "none" } : undefined}
        >
            <td style={{ width: "100px" }}>
                <Input
                    name="id"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={true}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={`${id}`}
                />
            </td>
            <td style={{ width: "100px" }}>
                <Input
                    name="text"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? text : ConvertEmptyValue(text)}
                />
            </td>
            <td>
                <Input
                    name="limit"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? limit : ConvertEmptyValue(limit)}
                />
            </td>
            <td css={WideCalendarArea}>
                <RangeCalendar
                    setRangeDate={{
                        startDate: dateParse(bidStartDate),
                        endDate: dateParse(bidEndDate),
                    }}
                    getUserRangeDate={onChangeRangeDate}
                    isDisabled={!isUpdate}
                    timePicker={true}
                />
            </td>
            <td>
                <Calendar
                    setDate={dateParse(openDate)}
                    getUserDate={(value) => onChangeDate("openDate", value)}
                    isDisabled={!isUpdate}
                    timePicker={true}
                />
            </td>
            <td>
                <Calendar
                    setDate={dateParse(saleDate)}
                    getUserDate={(value) => onChangeDate("saleDate", value)}
                    isDisabled={!isUpdate}
                    timePicker={true}
                />
            </td>
            <td>
                <Input
                    name="minPrice"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isUpdate}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={isUpdate ? minPrice : resultPrice(minPrice)}
                />
            </td>
        </tr>
    );
};

const WideCalendarArea = css`
    min-width: 330px;
    display: flex;
    justify-content: center;
`;

export default BidHistoryContainer;
