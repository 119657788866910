import { css } from "@emotion/react";
import { EditModal } from "@src/components";
import { useUsersCouponList } from "@src/hooks/queries/useCampus";
import { ConvertDateTime } from "@src/utils/dateUtil";
import React, { Fragment, useEffect, useRef } from "react";

interface Props {
    open: boolean;
    onCloseView: () => void;
    no: string;
}

const CampusCouponUserModal: React.FC<Props> = ({ open, onCloseView, no }) => {
    const tableRootRef = useRef<any>(null);
    const scrollRef = useRef<any>(null);
    const { data, fetchNextPage, hasNextPage } = useUsersCouponList(no);

    const handleObserver = (entries: any[]) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage) {
            fetchNextPage();
        }
    };
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                const option = {
                    root: tableRootRef.current,
                    rootMargin: "0px",
                    threshold: 0.5,
                };
                const observer = new IntersectionObserver(
                    handleObserver,
                    option,
                );
                if (scrollRef.current && !hasNextPage) {
                    observer.disconnect();
                    return;
                }
                if (scrollRef.current) {
                    observer.observe(scrollRef.current);
                }
            }, 500);
        }
    }, [handleObserver, open]);

    return (
        <>
            <EditModal
                buttonText="닫기"
                handleBtnClick={onCloseView}
                handleOpenModal={onCloseView}
                size="fitContent"
                title="지급 쿠폰 리스트"
                isModalShow={open}
            >
                <div css={rootStyle}>
                    <div css={tableRootStyle} ref={tableRootRef}>
                        <table>
                            <colgroup>
                                <col width="9%" />
                                <col width="9%" />
                                <col width="6%" />
                                <col width="13%" />
                                <col width="12%" />
                                <col width="9%" />
                                <col width="11%" />
                                <col width="9%" />
                                <col width="9%" />
                                <col width="20%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>지급일시</th>
                                    <th>수강번호</th>
                                    <th>이름</th>
                                    <th>연락처</th>
                                    <th>이메일</th>
                                    <th>
                                        쿠폰
                                        <br />
                                        고유번호
                                    </th>
                                    <th>쿠폰 코드번호</th>
                                    <th>유효기간</th>
                                    <th>
                                        쿠폰
                                        <br />
                                        등록일시
                                    </th>
                                    <th>쿠폰상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.pages.map((group, i) => (
                                    <Fragment key={i.toString()}>
                                        {group.list.length ? (
                                            group.list.map((d: any) => (
                                                <tr
                                                    key={ConvertDateTime(
                                                        d.createdAt,
                                                    )}
                                                >
                                                    <td>
                                                        {ConvertDateTime(
                                                            d.createdAt,
                                                        )}
                                                    </td>
                                                    <td>{d.no}</td>
                                                    <td>{d.name}</td>
                                                    <td>
                                                        {d.phone.slice(0, 3)}-
                                                        {d.phone.slice(4, 7)}-
                                                        {d.phone.slice(7, 11)}
                                                    </td>
                                                    <td>{d.email}</td>
                                                    <td>{d.couponIssuedNo}</td>
                                                    <td>{d.couponCode}</td>
                                                    <td>
                                                        ~
                                                        {ConvertDateTime(
                                                            d.expiredAt,
                                                        )}
                                                    </td>
                                                    <td>
                                                        {d.regedAt
                                                            ? ConvertDateTime(
                                                                  d.regedAt,
                                                              )
                                                            : "-"}
                                                    </td>
                                                    <td>
                                                        <div
                                                            css={{
                                                                padding:
                                                                    ".5rem .8rem",
                                                                borderRadius:
                                                                    "20px",
                                                                backgroundColor:
                                                                    d.statusToString ===
                                                                    "정상"
                                                                        ? "#2979FF"
                                                                        : d.statusToString ===
                                                                          "사용완료"
                                                                        ? "#37474F"
                                                                        : d.statusToString ===
                                                                          "기간만료"
                                                                        ? "#424242"
                                                                        : d.statusToString ===
                                                                          "정지"
                                                                        ? "#FF1744"
                                                                        : "#FF5722",
                                                                color: "#ffffff",
                                                            }}
                                                        >
                                                            {d.statusToString}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={8}
                                                    css={noDataStyle}
                                                >
                                                    <div>
                                                        수강생이 없습니다.🥲
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div ref={scrollRef} css={scrollStyle} />
                    </div>
                </div>
            </EditModal>
        </>
    );
};

export default CampusCouponUserModal;

const titleRootStyle = css`
    display: flex;
    align-items: center;
    p {
        font-size: 1rem;
        color: #424242;
        font-weight: 600;
        margin-right: 1rem;
        span {
            font-weight: 500;
            color: #333333;
        }
    }
`;

const tableRootStyle = css`
    width: 80vw;
    max-width: 1200px;
    margin-top: 2rem;
    height: 500px;
    overflow: scroll;
    max-height: fit-content;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
    table {
        width: 100%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
            background-color: #eeeeee;
            position: sticky;
            top: 0px;
        }
        th {
            padding: 1.2rem;
            span {
                color: #ff0000;
            }
        }
        tbody {
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
            border-bottom: 1px solid #b5b5b5;
        }
    }
`;

const scrollStyle = css`
    height: 3rem;
`;

const rootStyle = css`
    background-color: #ffffff;
`;

const noDataStyle = css`
    padding: 10rem 0 !important;
`;
