import { objectToDropdown } from "@src/utils/dropdownUtil";

export const TemplateCategory: { [key: number]: string } = {
    0: "서비스",
    10: "관심매물",
    100: "매물추천",
    1000: "혜택이벤트",
};

export const TemplateStatus: { [key: number]: string } = {
    100: "발송전",
    200: "발송됨", // 템플릿 수정불가
};

export const ddlTemplateCategory = objectToDropdown(TemplateCategory);

export const ddlTemplateStatus = objectToDropdown(TemplateStatus);
