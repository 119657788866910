import { createSlice } from "@reduxjs/toolkit";
import { PublicDetailStateType } from "@src/types";

const detailInformationPublicData: PublicDetailStateType = {
    propertyType: "압류재산(캠코)",
    agency: "이천세무서",
    disposeWay: "처분방식",
    executiveAgency: "한국자산관리공사",
    bidWay: "일반경쟁(최고가방식)/총액",
    assignee: "서울동부지역본부/조세정리2팀/1588-5321",
    initDate: "2019.08.21",
    duty: "매수인",
    requestDate: "2019.10.07",
    conditional:
        "본건은 권리신고한 임차인의 서류에 의하여 대항력 있는 임차인이 있을 수 있으므로 사전조사 후 입찰 바람",
};

const initialState = detailInformationPublicData;

export const detailInfoPublicSlice = createSlice({
    name: "publicInformation",
    initialState,
    reducers: {
        createDetailInfoPublic: (state, action) => {
            const result = action.payload;
            return { ...state, ...result };
        },
        updateDetailInfoPublic: (state, action) => {
            const result = action.payload;
            return { ...state, ...result };
        },
    },
});

export const { createDetailInfoPublic, updateDetailInfoPublic } =
    detailInfoPublicSlice.actions;

export default detailInfoPublicSlice.reducer;
