import { asyncCall } from "@src/api/asyncCall";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { Constants } from "@src/constants/Constants";
import { getPushCategoryType, PushCodeAndColors } from "@src/constants/PushStatus";
import { BasePushType, PushNowRequestBodyType, PushRequestBodyType, PushType } from "@src/pages/notify/types";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";

const { REGISTER } = Constants;
const initialData: PushType = {
    category: "혜택/이벤트",
    receivers: [
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
    ],
    testers: [
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
        "김수한무",
    ],
    reservationAt: "2022.07.20 11:20:30",
    link: "https://missgoauction.com",
    title: "미스고 회원 전용 이벤트 알림",
    contents: "(광고) 미스고 회원 전용 이벤트 알림 내용입니다 :)",
    pushStatus: "등록",
    edit: false,
    pushNo: 0,
    status: 100,
    originReservationAt: "",
};
const fetchData = async (pushNo: string) => {
    const response = await axiosInstance.get(`${axiosPath.postbox}/${pushNo}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const originData: BasePushType = response.data.data.data;

    return originData && parse(originData);
};

const parse = (originData: BasePushType) => {
    let newData: PushType = {
        reservationAt: "",
        edit: false,
        pushNo: 0,
        status: 100,
        originReservationAt: "",
        createdAt: "",
    };
    const {
        no,
        status,
        // to_type,
        to_go,
        to_tester,
        category,
        msg_click_action,
        reservation_at,
        created_at,
        msg_title,
        msg_body,
        user_type,
        subscription_type,
    } = originData;

    newData = {
        category: category.toString(),
        receivers: (to_go && to_go.split(",").filter((v) => v !== "")) || [],
        testers: (to_tester && to_tester.split(",").filter((v) => v !== "")) || [],
        reservationAt: reservation_at ?? "",
        link: msg_click_action ?? "",
        title: msg_title,
        contents: msg_body,
        pushStatus: PushCodeAndColors.find((v) => v.code === status)?.value || REGISTER,
        edit: false,
        pushNo: no,
        createdAt: created_at,
        status,
        originReservationAt: reservation_at ?? "",
        userTypes: user_type ? JSON.parse(user_type) : [],
        subscriptionTypes: subscription_type ? JSON.parse(subscription_type) : [],
    };

    return newData;
};

const usePushDetailData = (pushNo: string, isUpdate: boolean) => {
    return useQuery<PushType, Error>([queryKeys.PUSH_DETAIL_DATA, pushNo], () => fetchData(pushNo), {
        // initialData,
        enabled: isUpdate,
        refetchInterval: 60000, // 매분마다 정각에 푸시 상태값 갱신
    });
};

const useCreatePushData = () => {
    const createPushData = async (params: PushRequestBodyType) => {
        const promise = await axiosInstance.post(axiosPath.postbox, params);
        return asyncCall(promise, "Common");
    };

    return useMutation(createPushData);
};

const useCreatePushNowData = () => {
    const createPushNowData = async (params: PushNowRequestBodyType) => {
        const promise = await axiosInstance.post(axiosPath.pushNow, params);
        return asyncCall(promise, "Common");
    };

    return useMutation(createPushNowData);
};

const useUpdatePushData = (pushNo: string) => {
    const createPushData = async (params: PushRequestBodyType) => {
        const promise = await axiosInstance.patch(`${axiosPath.postbox}/${pushNo}`, params);

        return asyncCall(promise, "Common");
    };

    return useMutation(createPushData);
};

const useDeletePushData = () => {
    const deletePushData = async (pushNo: string) => {
        const promise = await axiosInstance.delete(`${axiosPath.postbox}/${pushNo}`);
        return asyncCall(promise, "Common");
    };

    return useMutation(deletePushData);
};

export { usePushDetailData, useCreatePushNowData, useCreatePushData, useUpdatePushData, useDeletePushData };
