import { css } from "@emotion/react";
import { Button, ConfirmModal, ToggleButton } from "@src/components";
import { Common } from "@src/styles/Common";
import React, { useEffect, useState } from "react";
import LectureVideoManagementModal from "./LectureVideoManagementModal";
import LectureScoreManagemnetModal from "./LectureScoreManagementModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    useCampusAdminReviews,
    useCampusCouponRemover,
    useCampusCurriculums,
    useCampusOne,
    useCampusProductRemover,
    useCampusResources,
    useCampusUpdate,
} from "@src/hooks/queries/useCampus";
import { ConvertDateTime, ConvertDateTimeNoSecond } from "@src/utils/dateUtil";
import LectureDataManagementModal from "./LectureDataManagementModal";
import CampusSugangUserModal from "./CampusSugangUserModal";
import CampusReviewModal from "./CampusReviewModal";
import sideBtn from "@src/assets/sideMenu_unactive.png";
import CampusProductsListModal from "./CampusProductsListModal";
import { IReview } from "@src/interfaces/Campus.interface";
import CampusCouponListModal from "./CampusCouponListModal";
import CampusCouponUserModal from "./CampusCouponUserModal";

interface Props {}

const Campus: React.FC<Props> = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [productType, setProductType] = useState<number>(8);
    const [openSugangUser, setOpenSugangUser] = useState<boolean>(false);
    const [openReviewList, setOpenReviewList] = useState<boolean>(false);
    const [openProductList, setOpenProductList] = useState<boolean>(false);
    const [openCouponList, setOpenCouponList] = useState<boolean>(false);
    const [openCouponUserList, setOpenCouponUserList] =
        useState<boolean>(false);
    const [openDataManagement, setOpenDataManagement] =
        useState<boolean>(false);
    const [openVideoManagement, setOpenVideoManagement] =
        useState<boolean>(false);
    const [openScoreManagement, setOpenScoreManagement] =
        useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [curriculumMainList, setCurriculumMainList] = useState<any>([]);
    const [showControlList, setShowControlList] = useState<any>();
    //! 1 => 수강생수, 수강평 보이는거 처리 // 2 => 상품 삭제 // 3 => 쿠폰 삭제
    const [isShowConfirmModal, setIsShowConfirmModal] = useState<number>(0);
    const { data, refetch } = useCampusOne(id);
    const { data: resources, refetch: refetchResources } = useCampusResources(
        id ?? "",
    );
    const { data: curriculums, refetch: refetchCurriculums } =
        useCampusCurriculums(id ?? "");

    const { data: adminReviews, refetch: refetchAdminReviews } =
        useCampusAdminReviews(id ?? "");

    const campusUpdate = useCampusUpdate(() => {
        setIsShowConfirmModal(0);
        refetch();
    });

    const couponRemover = useCampusCouponRemover(() => {
        setIsShowConfirmModal(0);
        refetch();
    });

    const productRemover = useCampusProductRemover(
        () => {
            setIsShowConfirmModal(0);
            refetch();
        },
        () => {
            setIsShowConfirmModal(0);
        },
    );

    const onClickDeleteCoupon = () => {
        const param = id;
        couponRemover.mutate(param);
    };

    const onClickDeleteProduct = () => {
        const param = selectedProduct.no;
        productRemover.mutate(param);
    };

    const onClickUpdateCampus = () => {
        const param = {
            ...data,
            ...showControlList,
        };
        campusUpdate.mutate(param);
    };

    const setTime = (time: number) => {
        if (time / 3600 >= 1) {
            const hour = Math.floor(time / 3600);
            const minute = time % 3600;
            return `${hour}시간${minute}분`;
        }
        if (time / 60 >= 1) {
            const minute = Math.floor(time / 60);
            const seconds = time % 60;
            return `${minute}분${seconds}초`;
        }
        return `${time}초`;
    };

    useEffect(() => {
        !openCouponList && !openProductList && refetch();
    }, [openProductList, pathname, openCouponList]);

    useEffect(() => {
        !openVideoManagement && refetchCurriculums();
    }, [openVideoManagement]);

    useEffect(() => {
        !openScoreManagement && refetchAdminReviews();
    }, [openScoreManagement]);

    useEffect(() => {
        !openDataManagement && refetchResources();
    }, [openDataManagement]);

    useEffect(() => {
        if (data?.no) {
            setShowControlList({
                showReview: data.showReview,
                showUserCnt: data.showUserCnt,
            });
        }
    }, [data]);

    useEffect(() => {
        if (curriculums && curriculums.list.length) {
            const newMainOrderList = [...curriculums.list].reduce(
                (q: any, val: any) => {
                    if (
                        q.findIndex(
                            (w: any) => w.mainOrder === val.mainOrder,
                        ) === -1
                    ) {
                        q = q.concat([
                            { mainOrder: val.mainOrder, title: val.title },
                        ]);
                    }
                    return q;
                },
                [],
            );
            setCurriculumMainList(newMainOrderList);
        }
    }, [curriculums]);

    return (
        <>
            <div css={studentCountStyle}>
                <p css={titleStyle}>수강생 정보</p>
                <div css={countDivStyle}>
                    <div className="countCheck">
                        <span>수강생 수</span>
                        <span>{data?.totalUserCnt} 명</span>
                    </div>
                    <Button
                        label="리스트보기"
                        color="gray"
                        size="small"
                        handleClick={(e) => setOpenSugangUser(true)}
                    />
                    <div className="isOpenCheck">
                        <span>노출여부</span>
                        <ToggleButton
                            name="노출여부"
                            value={showControlList?.showUserCnt}
                            handleToggle={(n: string, c: boolean) => {
                                setShowControlList({
                                    ...showControlList,
                                    showUserCnt: c,
                                });
                            }}
                        />
                        <Button
                            label="노출여부 수정"
                            color="white"
                            size="small"
                            handleClick={(e) => setIsShowConfirmModal(1)}
                            styles={{ color: `${Common.colors.purple500}` }}
                        />
                    </div>
                    {openSugangUser && (
                        <CampusSugangUserModal
                            no={id ?? ""}
                            open={openSugangUser}
                            onCloseView={() => setOpenSugangUser(false)}
                        />
                    )}
                </div>
            </div>
            <div>
                <div css={boxStyle}>
                    <p css={titleStyle}>강의 기본 정보</p>
                    <Button
                        label="강의 기본 정보 수정"
                        color="white"
                        size="small"
                        handleClick={() => {
                            navigate(`/campus/management/update/${id}`, {
                                state: {
                                    param: data,
                                },
                            });
                        }}
                        styles={{ color: `${Common.colors.purple500}` }}
                    />
                </div>
                <div>
                    <div css={boxStyle}>
                        <p css={boxTitleStyle}>
                            <span>* </span>강의명
                        </p>
                        <p>{data?.title}</p>
                    </div>
                    <div css={boxStyle}>
                        <p css={boxTitleStyle}>
                            <span>* </span>강의 노출 순서
                        </p>
                        <p>{data?.order}</p>
                    </div>
                    <div css={boxStyle}>
                        <p css={boxTitleStyle}>
                            <span>* </span>리스트용 썸네일
                        </p>
                        <img
                            src={data?.thumbnailUrl}
                            alt=""
                            style={{ width: "7rem", height: "7rem" }}
                        />
                    </div>
                    <div css={boxStyle}>
                        <p css={boxTitleStyle}>
                            <span>* </span>맛보기 영상
                        </p>
                        <p>{data?.sampleVideoUrl}</p>
                    </div>
                    <div css={boxStyle} style={{ alignItems: "flex-start" }}>
                        <p css={boxTitleStyle}>카운트다운</p>
                        <div css={tableRootStyle}>
                            <table>
                                <colgroup>
                                    <col width="20%" />
                                    <col width="40%" />
                                    <col width="40%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>문구</th>
                                        <th>시작일시</th>
                                        <th>종료일시</th>
                                    </tr>
                                </thead>
                                <tbody css={whiteTableBody}>
                                    <tr>
                                        <td>{data?.eventTitle ?? "-"}</td>
                                        <td>
                                            {data?.eventStartAt
                                                ? ConvertDateTime(
                                                      data?.eventStartAt,
                                                  )
                                                : "-"}
                                        </td>
                                        <td>
                                            {data?.eventExpiredAt
                                                ? ConvertDateTime(
                                                      data?.eventExpiredAt,
                                                  )
                                                : "-"}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div css={boxStyle}>
                        <p css={boxTitleStyle}>해시태그</p>
                        <p>
                            {data?.hashTag
                                ? `${
                                      data?.hashTag[0] === "#" ? "" : "#"
                                  }${data?.hashTag.replaceAll(",", " #")}`
                                : "-"}
                        </p>
                    </div>
                    <div css={boxStyle} style={{ alignItems: "flex-start" }}>
                        <p css={boxTitleStyle}>요약정보</p>
                        <div css={{ width: "80%" }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data?.contents ?? "",
                                }}
                            ></div>
                        </div>
                    </div>
                    <div css={boxStyle} style={{ alignItems: "flex-start" }}>
                        <p css={boxTitleStyle}>
                            <span>* </span>강의소개
                        </p>
                        <img
                            src={data?.introImgUrl}
                            alt=""
                            style={{ width: "7rem", height: "7rem" }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div css={titleBoxStyle}>
                    <p css={titleStyle}>강의 자료</p>
                    {!!resources?.list?.length && (
                        <Button
                            color="white"
                            size="small"
                            label="관리"
                            handleClick={() => setOpenDataManagement(true)}
                        />
                    )}
                </div>
                <div>
                    <div css={boxStyle}>
                        <p css={boxTitleStyle}>
                            <span>* </span>강의 자료
                        </p>
                        <div css={tableRootStyle}>
                            <table>
                                <colgroup>
                                    <col width="25%" />
                                    <col width="15%" />
                                    <col width="40%" />
                                    <col width="20%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>파일 이름</th>
                                        <th>파일 크기</th>
                                        <th>파일 URL</th>
                                        <th>파일 업로드일</th>
                                    </tr>
                                </thead>
                                {resources?.list?.length ? (
                                    <tbody>
                                        {resources?.list?.map(
                                            (resource: any) => (
                                                <tr key={resource.name}>
                                                    <td>{resource.name}</td>
                                                    <td>
                                                        {resource.pdfSize /
                                                            1000 >
                                                        100
                                                            ? `${
                                                                  resource.pdfSize /
                                                                  1000000
                                                              }MB`
                                                            : `${
                                                                  resource.pdfSize /
                                                                  1000
                                                              }KB`}
                                                    </td>
                                                    <td
                                                        css={{
                                                            whiteSpace: "pre",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            maxWidth: "500px",
                                                        }}
                                                    >
                                                        <a
                                                            href={
                                                                resource.pdfUrl
                                                            }
                                                        >
                                                            {resource.pdfUrl}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        {resource.createdAt
                                                            ? ConvertDateTimeNoSecond(
                                                                  new Date(
                                                                      resource.createdAt,
                                                                  ),
                                                              )
                                                            : "-"}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan={4} css={noDateStyle}>
                                                <p>
                                                    등록된 강의자료가 없습니다.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="강의자료 등록"
                                                    isDisabled={false}
                                                    handleClick={() =>
                                                        setOpenDataManagement(
                                                            true,
                                                        )
                                                    }
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                    <LectureDataManagementModal
                        open={openDataManagement}
                        onCloseView={() => setOpenDataManagement(false)}
                        data={resources?.list}
                        id={id}
                    />
                </div>
            </div>
            <div>
                <div css={titleBoxStyle}>
                    <p css={titleStyle}>강의영상 정보</p>
                    {!!curriculums?.list.length && (
                        <Button
                            color="white"
                            size="small"
                            label="관리"
                            handleClick={() => setOpenVideoManagement(true)}
                        />
                    )}
                </div>
                <div
                    css={boxStyle}
                    style={{
                        alignItems: "flex-start",
                    }}
                >
                    <p css={boxTitleStyle}>강의 영상</p>
                    <div css={lectureInfoRootStyle}>
                        <div css={lectureInfoStyle}>
                            <p css={lectureInfoContentStyle}>
                                총 강의 수<span>{curriculums?.totalCnt}강</span>
                            </p>
                            <p css={lectureInfoContentStyle}>
                                총 강의 시간
                                <span>{setTime(curriculums?.totalLength)}</span>
                            </p>
                        </div>
                        {curriculums?.list.length ? (
                            curriculumMainList?.map(
                                (main: any, idx: number) => (
                                    <div
                                        key={idx.toString()}
                                        css={curriculumMainStyle}
                                    >
                                        <div className="title">
                                            <p>
                                                <span>* </span>목차명
                                            </p>
                                            <p>
                                                Part{idx + 1} {main.title}
                                            </p>
                                        </div>
                                        <div
                                            css={tableRootStyle}
                                            style={{ padding: "2.4rem 0 1rem" }}
                                        >
                                            <table
                                                css={{
                                                    width: "100% !important",
                                                }}
                                            >
                                                <colgroup>
                                                    <col width="40%" />
                                                    <col width="45%" />
                                                    <col width="15%" />
                                                </colgroup>
                                                <thead>
                                                    <tr>
                                                        <th>세부 강의명</th>
                                                        <th>영상주소</th>
                                                        <th>영상시간</th>
                                                    </tr>
                                                </thead>
                                                <tbody css={whiteTableBody}>
                                                    {curriculums?.list
                                                        .filter(
                                                            (c: any) =>
                                                                c.mainOrder ===
                                                                main.mainOrder,
                                                        )
                                                        .map(
                                                            (
                                                                val: any,
                                                                i: number,
                                                            ) => (
                                                                <tr
                                                                    key={i.toString()}
                                                                    css={{
                                                                        borderBottom:
                                                                            "1px solid #eeeeee",
                                                                    }}
                                                                >
                                                                    <td>
                                                                        {
                                                                            val.subTitle
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            val.videoUrl
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {setTime(
                                                                            val.videoLength,
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ),
                            )
                        ) : (
                            <div css={tableRootStyle}>
                                <table>
                                    <colgroup>
                                        <col width="10%" />
                                        <col width="40%" />
                                        <col width="40%" />
                                        <col width="10%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>문구</th>
                                            <th>시작일시</th>
                                            <th>종료일시</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={4} css={noDateStyle}>
                                                <p>
                                                    등록된 강의영상이 없습니다.
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="강의영상 등록"
                                                    isDisabled={false}
                                                    handleClick={() =>
                                                        setOpenVideoManagement(
                                                            true,
                                                        )
                                                    }
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <LectureVideoManagementModal
                        no={id}
                        data={curriculums?.list}
                        mainData={curriculumMainList}
                        open={openVideoManagement}
                        onCloseView={() => setOpenVideoManagement(false)}
                    />
                </div>
            </div>
            <div>
                <div css={titleBoxStyle}>
                    <p css={titleStyle}>수강평 정보</p>
                    {!!adminReviews?.list.length && (
                        <Button
                            color="white"
                            size="small"
                            label="관리"
                            handleClick={() => setOpenScoreManagement(true)}
                        />
                    )}
                </div>
                <div css={commentStyle}>
                    <div
                        css={lectureInfoStyle}
                        style={{ paddingBottom: "10px" }}
                    >
                        <p css={boxTitleStyle}>회원 평점</p>
                        <div css={lectureInfoRootStyle}>
                            <div css={countDivStyle}>
                                <div
                                    className="countCheck"
                                    css={{ marginTop: 0 }}
                                >
                                    <span>0 명</span>
                                </div>
                                <Button
                                    label="리스트보기"
                                    color="gray"
                                    size="small"
                                    handleClick={(e) => setOpenReviewList(true)}
                                />
                                <div className="isOpenCheck">
                                    <span>노출여부</span>
                                    <ToggleButton
                                        name="노출여부"
                                        value={showControlList?.showReview}
                                        handleToggle={(
                                            n: string,
                                            c: boolean,
                                        ) => {
                                            setShowControlList({
                                                ...showControlList,
                                                showReview: c,
                                            });
                                        }}
                                    />
                                    <Button
                                        label="노출여부 수정"
                                        color="white"
                                        size="small"
                                        handleClick={(e) =>
                                            setIsShowConfirmModal(1)
                                        }
                                        styles={{
                                            color: `${Common.colors.purple500}`,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <CampusReviewModal
                            open={openReviewList}
                            onCloseView={() => setOpenReviewList(false)}
                            no={id ?? ""}
                        />
                    </div>
                    <div css={lectureInfoStyle}>
                        <p css={boxTitleStyle}>
                            강의상세
                            <br />
                            노출 수강평
                        </p>
                        <div css={tableRootStyle}>
                            <table>
                                <colgroup>
                                    <col width="10%" />
                                    <col width="10%" />
                                    <col width="10%" />
                                    <col width="10%" />
                                    <col width="60%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>노출여부</th>
                                        <th>노출순서</th>
                                        <th>이름</th>
                                        <th>평점</th>
                                        <th>수강평</th>
                                    </tr>
                                </thead>
                                {adminReviews?.list?.length ? (
                                    <tbody css={whiteTableBody}>
                                        {adminReviews.list.map(
                                            (review: IReview, idx: number) => (
                                                <tr key={idx.toString()}>
                                                    <td>
                                                        {review.showReview
                                                            ? "노출"
                                                            : "미노출"}
                                                    </td>
                                                    <td>{review.order}</td>
                                                    <td>{review.name}</td>
                                                    <td>{review.score}</td>
                                                    <td
                                                        style={{
                                                            wordBreak:
                                                                "break-all",
                                                        }}
                                                    >
                                                        {review.contents}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan={5} css={noDateStyle}>
                                                <p>등록된 수강평이 없습니다.</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={5}>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="수강평 등록"
                                                    isDisabled={false}
                                                    handleClick={() =>
                                                        setOpenScoreManagement(
                                                            true,
                                                        )
                                                    }
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </table>
                        </div>
                        <LectureScoreManagemnetModal
                            open={openScoreManagement}
                            onCloseView={() => setOpenScoreManagement(false)}
                            id={id}
                            adminReviews={adminReviews?.list}
                        />
                    </div>
                </div>
            </div>
            <div>
                <p css={titleStyle}>쿠폰 연결</p>
                <div css={boxStyle}>
                    <p css={boxTitleStyle}>쿠폰 연결</p>
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    <th>쿠폰 고유번호</th>
                                    <th>쿠폰명</th>
                                    <th>상품코드</th>
                                    <th>진행 상태</th>
                                    <th>사용/발행</th>
                                    <th>유효기간</th>
                                    <th>관리</th>
                                    <th>내역</th>
                                </tr>
                            </thead>
                            {data?.couponInfo?.name ? (
                                <tbody css={whiteTableBody}>
                                    <tr>
                                        <td>{data?.couponInfo.no}</td>
                                        <td>{data?.couponInfo.name}</td>
                                        <td>{data?.couponInfo.productCode}</td>
                                        <td>
                                            {data?.couponInfo.status === 200 &&
                                                "진행중"}
                                            {data?.couponInfo.status === 100 &&
                                                "정지"}
                                        </td>
                                        <td>
                                            {data?.couponInfo.usedCount} /{" "}
                                            {data?.couponInfo.issuedCount}
                                        </td>
                                        <td>
                                            {ConvertDateTime(
                                                data?.couponInfo.regEnd,
                                            )}
                                        </td>
                                        <td>
                                            <div css={couponBtnBoxStyle}>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="교체"
                                                    isDisabled={false}
                                                    handleClick={() =>
                                                        setOpenCouponList(true)
                                                    }
                                                />
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="삭제"
                                                    isDisabled={false}
                                                    handleClick={() => {
                                                        setIsShowConfirmModal(
                                                            3,
                                                        );
                                                    }}
                                                    styles={{
                                                        marginLeft: "5px",
                                                        color: `${Common.colors.gray700}`,
                                                        borderColor: `${Common.colors.gray700}`,
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <img
                                                src={sideBtn}
                                                alt=""
                                                style={{
                                                    height: "1rem",
                                                    width: ".8rem",
                                                    cursor: "pointer",
                                                    marginBottom: "-3px",
                                                }}
                                                aria-hidden
                                                onClick={() => {
                                                    const option =
                                                        "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=1600,height=1200,top=250,left=250";
                                                    const checkDev =
                                                        window.location.host ===
                                                        "dev.missgoauction.com";
                                                    const newUrl = checkDev
                                                        ? `${window.location.origin}/admin/coupon/detail/${data?.couponInfo.no}?usedCount=${data?.couponInfo.usedCount}&issuedCount=${data?.couponInfo.issuedCount}`
                                                        : `${window.location.origin}/coupon/detail/${data?.couponInfo.no}?usedCount=${data?.couponInfo.usedCount}&issuedCount=${data?.couponInfo.issuedCount}`;
                                                    window.open(
                                                        `${newUrl}?usedCount=${data?.couponInfo.usedCount}&issuedCount=${data?.couponInfo.issuedCount}`,
                                                        "detail",
                                                        option,
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan={8} css={noDateStyle}>
                                            <p>등록된 쿠폰이 없습니다.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={8}>
                                            <Button
                                                color="white"
                                                size="small"
                                                label="쿠폰 등록"
                                                isDisabled={false}
                                                handleClick={() =>
                                                    setOpenCouponList(true)
                                                }
                                                styles={{
                                                    display: "inline-block",
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <CampusCouponListModal
                            open={openCouponList}
                            onCloseView={() => setOpenCouponList(false)}
                            id={id}
                        />
                    </div>
                </div>
                <div css={boxStyle}>
                    <p css={boxTitleStyle}>지급 쿠폰</p>
                    <div css={couponListBoxStyle}>
                        <p>{data?.totalCouponCnt}장</p>
                        <Button
                            color="gray"
                            size="small"
                            label="리스트보기"
                            isDisabled={false}
                            handleClick={() => setOpenCouponUserList(true)}
                            styles={{
                                marginLeft: "1rem",
                            }}
                        />
                        <CampusCouponUserModal
                            open={openCouponUserList}
                            onCloseView={() => setOpenCouponUserList(false)}
                            no={id ?? ""}
                        />
                    </div>
                </div>
            </div>
            <div>
                <p css={titleStyle}>상품 연결</p>
                <div css={connectProductStyle}>
                    <p css={boxTitleStyle}>상품 연결</p>
                    <div css={tableRootStyle}>
                        <table>
                            <thead>
                                <tr>
                                    <th>판매형태</th>
                                    <th>상품명</th>
                                    <th>상품코드</th>
                                    <th>판매여부</th>
                                    <th>연결해지</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td css={productTdStyle}>AOS노출용</td>
                                    {data?.productInfos?.findIndex(
                                        (info: any) => info.saleType === 6,
                                    ) !== -1 ? (
                                        <>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 6,
                                                    )[0].name
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 6,
                                                    )[0].code
                                                }
                                            </td>
                                            <td>
                                                {data?.productInfos.filter(
                                                    (info: any) =>
                                                        info.saleType === 6,
                                                )[0].status === 200
                                                    ? "정상"
                                                    : data?.productInfos.filter(
                                                          (info: any) =>
                                                              info.saleType ===
                                                              6,
                                                      )[0].status === 100
                                                    ? "정지"
                                                    : "삭제"}
                                            </td>
                                            <td>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="해지"
                                                    isDisabled={false}
                                                    handleClick={() => {
                                                        setSelectedProduct(
                                                            data?.productInfos.filter(
                                                                (info: any) =>
                                                                    info.saleType ===
                                                                    6,
                                                            )[0],
                                                        );
                                                        setIsShowConfirmModal(
                                                            2,
                                                        );
                                                    }}
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <td colSpan={5}>
                                            <Button
                                                color="white"
                                                size="small"
                                                label="상품 연결"
                                                isDisabled={false}
                                                handleClick={() => {
                                                    setProductType(6);
                                                    setOpenProductList(true);
                                                }}
                                                styles={{
                                                    display: "inline-block",
                                                }}
                                            />
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td css={productTdStyle}>iOS노출용</td>
                                    {data?.productInfos?.findIndex(
                                        (info: any) => info.saleType === 7,
                                    ) !== -1 ? (
                                        <>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 7,
                                                    )[0].name
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 7,
                                                    )[0].code
                                                }
                                            </td>
                                            <td>
                                                {data?.productInfos.filter(
                                                    (info: any) =>
                                                        info.saleType === 7,
                                                )[0].status === 200
                                                    ? "정상"
                                                    : data?.productInfos.filter(
                                                          (info: any) =>
                                                              info.saleType ===
                                                              7,
                                                      )[0].status === 100
                                                    ? "정지"
                                                    : "삭제"}
                                            </td>
                                            <td>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="해지"
                                                    isDisabled={false}
                                                    handleClick={() => {
                                                        setSelectedProduct(
                                                            data?.productInfos.filter(
                                                                (info: any) =>
                                                                    info.saleType ===
                                                                    7,
                                                            )[0],
                                                        );
                                                        setIsShowConfirmModal(
                                                            2,
                                                        );
                                                    }}
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <td colSpan={5}>
                                            <Button
                                                color="white"
                                                size="small"
                                                label="상품 연결"
                                                isDisabled={false}
                                                handleClick={() => {
                                                    setProductType(7);
                                                    setOpenProductList(true);
                                                }}
                                                styles={{
                                                    display: "inline-block",
                                                }}
                                            />
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td css={productTdStyle}>WEB노출용</td>
                                    {data?.productInfos?.findIndex(
                                        (info: any) => info.saleType === 8,
                                    ) !== -1 ? (
                                        <>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 8,
                                                    )[0].name
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 8,
                                                    )[0].code
                                                }
                                            </td>
                                            <td>
                                                {data?.productInfos.filter(
                                                    (info: any) =>
                                                        info.saleType === 8,
                                                )[0].status === 200
                                                    ? "정상"
                                                    : data?.productInfos.filter(
                                                          (info: any) =>
                                                              info.saleType ===
                                                              8,
                                                      )[0].status === 100
                                                    ? "정지"
                                                    : "삭제"}
                                            </td>
                                            <td>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="해지"
                                                    isDisabled={false}
                                                    handleClick={() => {
                                                        setSelectedProduct(
                                                            data?.productInfos.filter(
                                                                (info: any) =>
                                                                    info.saleType ===
                                                                    8,
                                                            )[0],
                                                        );
                                                        setIsShowConfirmModal(
                                                            2,
                                                        );
                                                    }}
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <td colSpan={5}>
                                            <Button
                                                color="white"
                                                size="small"
                                                label="상품 연결"
                                                isDisabled={false}
                                                handleClick={() => {
                                                    setProductType(8);
                                                    setOpenProductList(true);
                                                }}
                                                styles={{
                                                    display: "inline-block",
                                                }}
                                            />
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td css={productTdStyle}>
                                        일반판매
                                        <br />
                                        미노출
                                    </td>
                                    {data?.productInfos?.findIndex(
                                        (info: any) => info.saleType === 0,
                                    ) !== -1 ? (
                                        <>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 0,
                                                    )[0].name
                                                }
                                            </td>
                                            <td>
                                                {
                                                    data?.productInfos.filter(
                                                        (info: any) =>
                                                            info.saleType === 0,
                                                    )[0].code
                                                }
                                            </td>
                                            <td>
                                                {data?.productInfos.filter(
                                                    (info: any) =>
                                                        info.saleType === 0,
                                                )[0].status === 200
                                                    ? "정상"
                                                    : data?.productInfos.filter(
                                                          (info: any) =>
                                                              info.saleType ===
                                                              0,
                                                      )[0].status === 100
                                                    ? "정지"
                                                    : "삭제"}
                                            </td>
                                            <td>
                                                <Button
                                                    color="white"
                                                    size="small"
                                                    label="해지"
                                                    isDisabled={false}
                                                    handleClick={() => {
                                                        setSelectedProduct(
                                                            data?.productInfos.filter(
                                                                (info: any) =>
                                                                    info.saleType ===
                                                                    0,
                                                            )[0],
                                                        );
                                                        setIsShowConfirmModal(
                                                            2,
                                                        );
                                                    }}
                                                    styles={{
                                                        display: "inline-block",
                                                    }}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        <td colSpan={5}>
                                            <Button
                                                color="white"
                                                size="small"
                                                label="상품 연결"
                                                isDisabled={false}
                                                handleClick={() => {
                                                    setProductType(0);
                                                    setOpenProductList(true);
                                                }}
                                                styles={{
                                                    display: "inline-block",
                                                }}
                                            />
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        <CampusProductsListModal
                            open={openProductList}
                            onCloseView={() => setOpenProductList(false)}
                            productType={productType}
                            id={id}
                        />
                    </div>
                </div>
            </div>
            <ConfirmModal
                isModalShow={isShowConfirmModal !== 0}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowConfirmModal(0);
                }}
                handleOk={() => {
                    isShowConfirmModal === 1
                        ? onClickUpdateCampus()
                        : isShowConfirmModal === 2
                        ? onClickDeleteProduct()
                        : onClickDeleteCoupon();
                }}
            >
                <>
                    {isShowConfirmModal === 1
                        ? "정말 저장하시겠습니까?"
                        : "정말 해지하시겠습니까?"}
                </>
            </ConfirmModal>
        </>
    );
};

export default Campus;

const titleStyle = css`
    font-size: 1.1rem;
    font-weight: 600;
    width: 10rem;
    padding: 1rem 0;
`;

const boxStyle = css`
    display: flex;
    align-items: center;
    padding: 1rem 0;
`;

const titleBoxStyle = css`
    display: flex;
    align-items: center;
`;

const boxTitleStyle = css`
    font-size: 1rem;
    line-height: 120%;
    color: #959595;
    font-weight: 500;
    width: 10rem;
    span {
        color: #ff0000;
    }
`;

const lectureInfoRootStyle = css`
    display: flex;
    width: 100%;
    padding: 0 0 30px;
    flex-direction: column;
`;

const lectureInfoStyle = css`
    display: flex;
    align-items: center;
    padding: 0 0 30px;
`;

const lectureInfoContentStyle = css`
    font-size: 1rem;
    color: #959595;
    font-weight: 500;
    span {
        color: #222222;
        padding: 0 1rem;
    }
`;

const tableRootStyle = css`
    width: 100%;
    table {
        width: 88%;
        thead {
            border-top: 1px solid #b5b5b5;
            border-bottom: 1px solid #b5b5b5;
        }
        th {
            padding: 10px;
        }
        tbody {
            background-color: #eeeeee;
            border-bottom: 1px solid #b5b5b5;
        }
        td {
            padding: 15px 10px;
            text-align: center;
        }
    }
`;

const whiteTableBody = css`
    background-color: #ffffff !important;
`;

const studentCountStyle = css`
    display: flex;
    flex-direction: column;
    margin: 20px 0 40px;
`;

const countDivStyle = css`
    margin-top: 20px;
    display: flex;
    align-items: center;
    .countCheck {
        span {
            font-size: 0.95rem;
            padding-right: 2rem;
        }
        span:first-of-type {
            color: #959595;
            padding-right: 1rem;
        }
    }
    .isOpenCheck {
        padding: 0 2rem;
        display: flex;
        align-items: center;
        span {
            font-size: 0.95rem;
            color: #959595;
            padding-right: 1rem;
        }
        button {
            margin: 0 1rem;
        }
    }
`;

const commentStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const connectProductStyle = css`
    display: flex;
    align-items: flex-start;
`;

const productTdStyle = css`
    background-color: #ffffff;
`;

const curriculumMainStyle = css`
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #d8d8d8;
    width: 88%;
    .title {
        display: flex;
        align-items: center;
        p:first-of-type {
            span {
                color: #ff0000;
                font-weight: 500;
            }
            font-weight: 700;
            font-size: 1.1rem;
            width: 5rem;
        }
        p {
            font-weight: 500;
            font-size: 1rem;
        }
    }
`;

const couponBtnBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const couponListBoxStyle = css`
    display: flex;
    align-items: center;
    p {
        font-size: 1rem;
        font-weight: 600;
    }
`;

const noDateStyle = css`
    padding-bottom: 0 !important;
    p {
        font-size: 0.9rem;
        font-weight: 400;
        color: ${Common.colors.gray600};
    }
`;
