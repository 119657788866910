import { createSlice } from "@reduxjs/toolkit";

const detailInformationData = {
    saleProduct: "토지 및 건물 매각",
    productOwner: "박은자",
    openDate: "2022.06.10",
    debtor: "박은자",
    caseName: "강제 경매",
    creditor: "기명이, 이재희의 승계인 김현구 외 1",
    relatedCase: "2020타경112352(중복)",
};

const initialState = { ...detailInformationData };

export const detailInfoSlice = createSlice({
    name: "detailInfo",
    initialState,
    reducers: {
        createDetailInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateDetailInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createDetailInfo, updateDetailInfo } = detailInfoSlice.actions;

export default detailInfoSlice.reducer;
