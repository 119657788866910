import { asyncCall } from "@src/api/asyncCall";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import {
    BaseSituation,
    CreateSituationDto,
    UpdateSituationDto,
} from "@src/interfaces/Situation.interface";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";

const useSituationDetail = (SituationNo: string, isUpdate: boolean) => {
    return useQuery<BaseSituation, Error>(
        [queryKeys.SITUATION_DETAIL_DATA, SituationNo],
        () => fetchData(SituationNo),
        {
            // initialData,
            enabled: isUpdate,
        },
    );
};

const fetchData = async (SituationNo: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.postboxSituation}/${SituationNo}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: BaseSituation = responseData.data.data;

    return originData;
};

const useCreateSituation = () => {
    const createData = async (params: CreateSituationDto) => {
        const promise = await axiosInstance.post(
            axiosPath.postboxSituation,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(createData);
};

const useUpdateSituation = (SituationNo: string) => {
    const updateData = async (params: UpdateSituationDto) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.postboxSituation}/${SituationNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateData);
};

const useDeleteSituation = () => {
    const updateData = async (SituationNo: string) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.postboxSituation}/${SituationNo}`,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateData);
};

const useDuplicateSituation = () => {
    const createData = async (SituationCode: string) => {
        const promise = await axiosInstance.post(
            `${axiosPath.postboxSituation}/duplicate`,
            { situation_code: SituationCode },
        );
        return asyncCall(promise, "Situation");
    };

    return useMutation(createData);
};

export {
    useSituationDetail,
    useCreateSituation,
    useUpdateSituation,
    useDeleteSituation,
    useDuplicateSituation,
};
