import { PartnerListDto } from "@src/interfaces/Partner.interface";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type ReturnFilterState = [PartnerListDto, React.Dispatch<React.SetStateAction<PartnerListDto>>];

const usePartnerFilter = (initParams: PartnerListDto): ReturnFilterState => {
    const location = useLocation();
    const [filter, setFilter] = useState(initParams);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const page = queryParams.get("page") || initParams.page.toString();
        const limit = queryParams.get("limit") || initParams.limit.toString();
        const searchColumn = queryParams.get("searchColumn") || initParams.searchColumn;

        if (page && limit && searchColumn) {
            const searchValue = queryParams.get("searchValue");
            const userStatuses = queryParams.getAll("userStatuses").map(Number);
            const partnerStatuses = queryParams.getAll("partnerStatuses");
            const subscriptionStatuses = queryParams.getAll("subscriptionStatuses");
            const partnerType = queryParams.getAll("partnerType");

            const parsedFilter: PartnerListDto = {
                page: Number(page),
                limit: Number(limit),
                searchColumn: searchColumn,
                searchValue: searchValue ? decodeURIComponent(searchValue) : undefined,
                cursor: queryParams.get("cursor") ?? undefined,
                requestStartedAt: queryParams.get("requestStartedAt") ?? undefined,
                requestEndedAt: queryParams.get("requestEndedAt") ?? undefined,
                userStatuses: userStatuses.length > 0 ? userStatuses : undefined,
                partnerStatuses: partnerStatuses.length > 0 ? partnerStatuses : undefined,
                subscriptionStatuses: subscriptionStatuses.length > 0 ? subscriptionStatuses : undefined,
                partnerType: partnerType.length > 0 ? partnerType : undefined,
            };
            setFilter(parsedFilter);
        } else {
            setFilter(initParams);
        }
    }, []);

    return [filter, setFilter];
};

export default usePartnerFilter;
