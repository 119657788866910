// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Common } from "@src/styles/Common";
import { css } from "@emotion/react";

interface Props {
    data: any;
    options: any;
    onScrollEnd?: (val: number) => void;
    height?: number;
    boxStyles?: any;
}
Chart.register(ChartDataLabels);

function MixedChart({ data, options, onScrollEnd, height, boxStyles }: Props) {
    useEffect(() => {
        Chart.defaults.set("plugins.datalabels", {
            display: false,
        });
    }, [data]);
    const scrollRef = useRef<any>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasRightYRef = useRef<any>(null);
    const canvasleftYRef = useRef<any>(null);
    const chartRef = useRef<any>(null);
    const config = {
        data: data,
        options: {
            animation: {
                duration: 0,
            },
            aspectRatio: 2,
            responsive: false,
            maintainAspectRatio: false,
            maxBarThickness: 40,
            interaction: {
                mode: "index",
                intersect: false,
            },
            scale: {
                pan: {
                    enabled: true,
                    mode: "x",
                },
                zoom: {
                    pinch: {
                        enabled: true,
                    },
                    mode: "x",
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        stepSize: 10,
                        maxTicksLimit: 5,
                    },
                },
            },
            tooltip: {
                enabled: true,
                mode: "index",
                titleColor: Common.colors.black,
                bodyColor: Common.colors.black,
                backgroundColor: Common.colors.white,
                boxWidth: 8,
                boxPadding: 3,
                usePointStyle: true,
                borderColor: Common.colors.black,
                borderWidth: 1,
                padding: 10,
            },
        },
        plugins: [ChartDataLabels],
    };

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
            canvasRef.current.width = 100;
        }
        if (canvasRef.current && data) {
            const mergeOptions = { ...config.options, ...options };
            config.options = {
                ...mergeOptions,
                spanGaps: true,
                grouped: true,
                x: {
                    grid: {
                        offset: true,
                    },
                    type: "time",
                    time: {
                        displayFormats: {
                            hour: "HH:mm",
                            day: "MM.dd",
                            week: "MM.dd",
                            month: "yyyy.MM",
                        },
                        tooltipFormat: "yyyy.MM.dd",
                    },
                },

                left: {
                    id: "left",
                    type: "linear",
                    position: "left",
                    title: {
                        display: true,
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        stepSize: 1,
                        maxTicksLimit: 5,
                    },
                },
                right: {
                    id: "right",
                    type: "linear",
                    position: "right",
                    title: {
                        display: true,
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        stepSize: 1,
                        maxTicksLimit: 5,
                    },
                },
            };
            canvasRef.current.width =
                height && data?.labels?.length * 30 < 300
                    ? 1100
                    : (data?.labels?.length ?? 10) * 30 < 300
                    ? 550
                    : (data?.labels?.length ?? 40) * 100;

            chartRef.current = new Chart(canvasRef.current.getContext("2d"), {
                ...config,
            });
            const sourceCanvas = chartRef.current.canvas;
            const currentHeight = chartRef.current.height;
            const leftWidth = chartRef.current?.scales?.["left"]?.width;
            const rightWidth = chartRef.current?.scales?.["right"]?.width;
            const targetLeftCtx = canvasleftYRef.current?.getContext("2d");
            const targetRightCtx = canvasRightYRef.current?.getContext("2d");
            if (!leftWidth || !rightWidth || !currentHeight) {
                return;
            }
            const ratio = sourceCanvas.height / currentHeight;
            targetLeftCtx.canvas.width = leftWidth;
            targetLeftCtx.canvas.height = currentHeight;
            targetRightCtx.canvas.width = rightWidth;
            targetRightCtx.canvas.height = currentHeight;
            console.log(
                sourceCanvas.height,
                currentHeight,
                sourceCanvas.height / currentHeight,
                ratio,
                rightWidth,
                targetRightCtx.canvas.width,
            );
            targetLeftCtx?.drawImage(
                sourceCanvas,
                0,
                0,
                leftWidth * ratio,
                currentHeight * ratio,
                0,
                0,
                leftWidth,
                currentHeight,
            );
            targetRightCtx?.drawImage(
                sourceCanvas,
                sourceCanvas.width - rightWidth * ratio,
                0,
                rightWidth * ratio,
                sourceCanvas.height,
                targetRightCtx.canvas.width * ratio -
                    rightWidth * ratio -
                    (sourceCanvas.height -
                        targetRightCtx.canvas.height * ratio) /
                        15,
                0,
                rightWidth,
                currentHeight,
            );
            onScrollEnd && onScrollEnd(canvasRef.current.width);
            scrollRef.current?.scrollTo(canvasRef.current.width, 0);
            if (
                scrollRef.current?.clientWidth > canvasRef.current.clientWidth
            ) {
                setScrollWidthCheck(true);
            }
            scrollRef.current.addEventListener("scroll", (evt) => {
                const sourceCanvas = chartRef.current.canvas;
                const currentHeight = chartRef.current.height;
                const ratio = sourceCanvas.height / currentHeight;
                if (
                    evt.target.scrollLeft + evt.target.clientWidth >=
                    canvasRef.current?.width / ratio
                ) {
                    setScrollWidthCheck(true);
                } else {
                    setScrollWidthCheck(false);
                }
            });
        }
    }, [data, height]);

    const [scrollWidthCheck, setScrollWidthCheck] = useState<boolean>(false);

    return (
        <div css={rootBoxStyle(scrollWidthCheck)}>
            <div css={[chartBoxStyle, boxStyles]} ref={scrollRef}>
                <div css={{ position: "relative" }}>
                    <canvas
                        ref={canvasRef}
                        height={height ? height : 200}
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            background: "white",
                        }}
                    />
                </div>
            </div>
            <canvas ref={canvasleftYRef} className="leftY" />
            <canvas ref={canvasRightYRef} className="rightY" />
        </div>
    );
}

export default MixedChart;

const rootBoxStyle = (scrollWidthCheck: any) => css`
    position: relative;
    .leftY {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
    }
    .rightY {
        ${scrollWidthCheck
            ? {
                  opacity: 0,
                  visibility: "hidden",
              }
            : {
                  opacity: 1,
                  visibility: "visible",
              }}
        transition: 0.2s all;
        position: absolute;
        right: 18px;
        top: 0;
        z-index: 3;
    }
`;

const chartBoxStyle = css`
    overflow: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 8px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
`;
