import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
    Title,
    Button,
    Input,
    Label,
    TextBox,
    Calendar,
} from "@src/components";
import { useGetRightAnalysis } from "@src/hooks/queries/product/useRightsAnalysis";
import { Common } from "@src/styles/Common";
import { ConvertPrice } from "@src/utils/units";
import { dateParse } from "@src/utils/dateUtil";
interface IRightsAnalysisContainer {
    isEdit: boolean;
    handleUpdate?: Function;
}
function RightsAnalysisContainer({
    isEdit,
    handleUpdate,
}: IRightsAnalysisContainer) {
    const PRODUCT_NO = "20200130012488";
    const HEADER_META = [
        "소멸여부",
        "항목",
        "접수일자",
        "권리종류",
        "채권액(채권최고액)",
        "관리자",
        "기타사항",
    ];
    const TABLE_FORCE_META = [
        "대항력",
        "배당예상금액",
        "보증금/차임",
        "전입/확정/배당",
        "임차인",
        "점유부분",
    ];
    const [tableData, setTableData] = useState<any>();
    const [tableCounterForceData, setTableCounterForceData] = useState<any>();
    const [updateData, setUpdateData] = useState<any>();
    const { isLoading, isError, data } = useGetRightAnalysis(PRODUCT_NO);

    useEffect(() => {
        if (!isLoading && !isError && data) {
            setTableData(data.list);
            setUpdateData({
                bidDate: data.bidDate,
                bidAnalysis: data.bidAnalysis,
            });
            setTableCounterForceData(data.list2);
        }
    }, [data, isLoading, isError]);

    useEffect(() => {
        handleUpdate && handleUpdate(tableData);
    }, [tableData]);

    const handleChange = (updateData: any, id: string) => {
        let newTableData = [...tableData];
        const index = newTableData.findIndex((v) => v.id === id);
        newTableData[index] = updateData;
        setTableData(newTableData);
    };

    return (
        <ul css={MainContainerUl}>
            <li>
                <Button
                    color="purple"
                    defaultValue="BTN_PURPLE"
                    handleClick={function noRefCheck() {}}
                    label="등기열람"
                    size="xSmall"
                />
            </li>
            <li>
                <Title title="건물등기현황" size="small" />
            </li>
            <li>
                {tableData && (
                    <table className="commonTable">
                        <thead>
                            <tr>
                                {HEADER_META.map((headerText, index) => (
                                    <th key={index}>{headerText}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData &&
                                tableData.map((data: any, index: number) => {
                                    return (
                                        <TableRow
                                            key={`${index}_${index + 1}`}
                                            data={data}
                                            isEdit={isEdit}
                                            handleChange={handleChange}
                                        />
                                    );
                                })}
                        </tbody>
                    </table>
                )}
            </li>
            <li>
                <Title title="임차인현황" size="small" />
            </li>
            <li>
                <span>배당요구종기일</span>
                <Calendar
                    getUserDate={() => {}}
                    setDate={dateParse(updateData?.bidDate) || null}
                    isDisabled={!isEdit}
                />
            </li>
            <li>
                {tableCounterForceData && (
                    <table className="commonTable">
                        <thead>
                            <tr>
                                {TABLE_FORCE_META.map((headerText, index) => (
                                    <th key={index}>{headerText}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableCounterForceData &&
                                tableCounterForceData.map(
                                    (data: any, index: number) => {
                                        return (
                                            <TableCounterForceRow
                                                key={`${index}_${index + 1}`}
                                                data={data}
                                                isEdit={isEdit}
                                                handleChange={handleChange}
                                            />
                                        );
                                    },
                                )}
                        </tbody>
                    </table>
                )}
            </li>
            <li>
                <Button
                    color="purple"
                    defaultValue="BTN_PURPLE"
                    handleClick={function noRefCheck() {}}
                    label="현황조사서"
                    size="xSmall"
                />
                <Button
                    color="purple"
                    defaultValue="BTN_PURPLE"
                    handleClick={function noRefCheck() {}}
                    label="매각물건명세서"
                    size="xSmall"
                />
            </li>
            <li>
                <span>임차인분석</span>{" "}
                <TextBox
                    label={""}
                    placeholder="-"
                    handleChange={() => {}}
                    isDisabled={!isEdit}
                    defaultText={updateData?.bidAnalysis || ""}
                    size={"small"}
                />
            </li>
        </ul>
    );
}

interface ITableRow {
    data: any;
    isEdit: boolean;
    handleChange: Function;
}
const TableCounterForceRow = ({ data, isEdit, handleChange }: ITableRow) => {
    const [rowData, setRowData] = useState(data);
    const { counterForce, expectPrice, deposit, detail, lessee, occupied } =
        rowData;

    useEffect(() => {
        handleChange(rowData);
    }, [rowData]);

    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newRowData = { ...rowData };
        let key: keyof any;

        for (key in newRowData) {
            if (key === name) {
                newRowData[name] = value;
            }
        }
    };

    return (
        <tr key={data.id} style={isEdit ? { borderBottom: "none" } : undefined}>
            <td style={{ width: "60px", paddingLeft: "30px" }}>
                <Label
                    color={counterForce ? "redBorder" : "gray"}
                    text={counterForce ? "있음" : "없음"}
                />
            </td>
            <td style={{ width: "180px" }}>
                <Input
                    name="expectPrice"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={expectPrice}
                />
            </td>
            <td style={{ width: "180px" }}>
                <Input
                    name="deposit"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={deposit}
                />
            </td>
            <td style={{ width: "180px" }}>
                <Input
                    name="detail"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={detail}
                />
            </td>
            <td style={{ width: "180px" }}>
                <Input
                    name="lessee"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={lessee}
                />
            </td>
            <td style={{ width: "180px" }}>
                <Input
                    name="occupied"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={occupied}
                />
            </td>
        </tr>
    );
};
const TableRow = ({ data, isEdit, handleChange }: ITableRow) => {
    const [rowData, setRowData] = useState(data);
    const {
        dispose,
        type,
        createDate,
        rightType,
        creditPrice,
        creditor,
        option,
    } = rowData;

    useEffect(() => {
        handleChange(rowData);
    }, [rowData]);

    const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newRowData = { ...rowData };
        let key: keyof any;

        for (key in newRowData) {
            if (key === name) {
                newRowData[name] = value;
            }
        }
    };

    return (
        <tr key={data.id} style={isEdit ? { borderBottom: "none" } : undefined}>
            <td style={{ width: "180px" }}>
                <Input
                    name="dispose"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={dispose}
                />
            </td>
            <td>
                <Input
                    name="type"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={type}
                />
            </td>
            <td>
                <Calendar
                    getUserDate={() => {}}
                    setDate={dateParse(createDate) || null}
                    isDisabled={!isEdit}
                />
            </td>
            <td>
                <Input
                    name="rightType"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={rightType}
                />
            </td>
            <td>
                <Input
                    name="creditPrice"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={creditPrice ? ConvertPrice(creditPrice) : ""}
                />
            </td>
            <td>
                <Input
                    name="creditor"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={creditor}
                />
            </td>
            <td>
                <Input
                    name="option"
                    errorMessage={""}
                    handleChange={onChangeField}
                    isDisabled={!isEdit}
                    label=""
                    placeholder=""
                    size="dynamic"
                    type="text"
                    value={option}
                />
            </td>
        </tr>
    );
};

const MainContainerUl = css`
    min-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    justify-content: flex-start;

    li {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        & > span {
            width: 100px;
            min-width: 100px;
            display: inline-block;
            vertical-align: bottom;
            font-size: 14px;
            font-weight: 500;
            color: ${Common.colors.gray400};
        }
        & > button {
            margin-left: 0 !important;
            margin-right: 10px !important;
        }
    }

    thead > tr > th {
        text-align: center;
    }
`;

export default RightsAnalysisContainer;
