import { createSlice } from "@reduxjs/toolkit";

const name = "bannerType";
const initialState = "home";

export const bannerTypeSlice = createSlice({
    name,
    initialState,
    reducers: {
        setBannerType: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setBannerType } = bannerTypeSlice.actions;
export default bannerTypeSlice.reducer;
