import styled from "@emotion/styled";
import { Button, Input, PageTable } from "@src/components";
import { useGetOfficeList } from "@src/hooks/queries/useExpert";
import { Common } from "@src/styles/Common";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./SearchOfficeColumns";
import { OfficeParamsDto, userExpertDto } from "./types";

interface SearchOfficeProps {
    expertData: userExpertDto;
    setExpertData: Dispatch<SetStateAction<userExpertDto>>;
    setIsShowOfficeModal: Dispatch<SetStateAction<boolean>>;
}

const SearchOffice = ({
    expertData,
    setExpertData,
    setIsShowOfficeModal,
}: SearchOfficeProps) => {
    const [active, setActive] = useState<any>();
    const [officeSearchParams, setOfficeSearhParams] =
        useState<OfficeParamsDto>({ searchText: "", limit: 10, page: 1 });
    const columns = getColumns(active, setActive, officeSearchParams);
    const [officeName, setofficeName] = useState<string>("");

    const { data, refetch } = useGetOfficeList(officeSearchParams);

    useEffect(() => {
        if (officeSearchParams?.page && officeSearchParams?.page > 1) {
            refetch();
        }
    }, [officeSearchParams?.page]);

    return (
        <>
            <StyledSearchOffice>
                <StyledSearch>
                    <Input
                        placeholder="성명 / 소재지(시,군,구,동 등)"
                        value={officeSearchParams.searchText}
                        size={"medium"}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setOfficeSearhParams({
                                ...officeSearchParams,
                                searchText: value,
                            });
                        }}
                    />
                    <Button
                        color="gd"
                        handleClick={() => {
                            refetch();
                        }}
                        label="조회"
                        size="medium"
                    />
                </StyledSearch>
                {active && (
                    <StyledSearch>
                        <Input
                            placeholder="사무소명을 입력하세요"
                            value={officeName}
                            size={"medium"}
                            handleChange={(e) => {
                                const { value } = e.target;

                                setofficeName(value);
                            }}
                        />
                        <Button
                            color="gd"
                            handleClick={() => {
                                setExpertData({
                                    ...expertData,
                                    info: {
                                        ...expertData.info,
                                        officeInfo: {
                                            name: officeName,
                                            ceo: active.ceo,
                                            registrationNumber:
                                                active.registrationNumber,
                                            type: "scrivener",
                                            phone: active.phone,
                                            address: active.address,
                                        },
                                    },
                                });
                                setIsShowOfficeModal(false);
                            }}
                            label="확인"
                            size="medium"
                            isDisabled={!officeName}
                        />
                    </StyledSearch>
                )}

                <PageTable
                    columns={columns}
                    data={data?.result.list ?? []}
                    initialState={{
                        pageIndex: 0,
                        pageSize: 10,
                    }}
                    FixedHeight={500}
                    showTotalCount={true}
                    totalCount={data?.result?.totalCount ?? 0}
                    handlePageChange={(index: number) => {
                        const page = index + 1;
                        setOfficeSearhParams({
                            ...officeSearchParams,
                            page,
                        });
                    }}
                    forcePageNumber={officeSearchParams.page ?? 0}
                ></PageTable>
            </StyledSearchOffice>
        </>
    );
};

export default SearchOffice;

const StyledSearchOffice = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
`;

const StyledSearch = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    height: 48px;
    width: 100%;
    & > input {
        border: 1px solid #d9d9d9;
    }
    & > button {
        height: 100%;
    }
`;
