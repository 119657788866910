export const LocationLargeCategory: { [key: string]: any } = {
    서울: {
        name: "서울",
        code: "11",
    },
    경기: {
        name: "경기",
        code: "41",
    },
    인천: {
        name: "인천",
        code: "28",
    },
    부산: {
        name: "부산",
        code: "26",
    },
    대전: {
        name: "대전",
        code: "30",
    },
    대구: {
        name: "대구",
        code: "27",
    },
    울산: {
        name: "울산",
        code: "31",
    },
    세종: {
        name: "세종",
        code: "36",
    },
    광주: {
        name: "광주",
        code: "29",
    },
    강원: {
        name: "강원",
        code: "42",
    },
    충북: {
        name: "충북",
        code: "43",
    },
    충남: {
        name: "충남",
        code: "44",
    },
    경북: {
        name: "경북",
        code: "47",
    },
    경남: {
        name: "경남",
        code: "48",
    },
    전북: {
        name: "전북",
        code: "45",
    },
    전남: {
        name: "전남",
        code: "46",
    },
    제주: {
        name: "제주",
        code: "50",
    },
};
