import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import { ResponseMissgoApi } from "@src/interfaces/MissgoApi";

interface PushLogType {
    no: number;
    messageNo: number;
    status: number;
    froms: number;
    results: string;
    createdAt: string;
}

interface BasePushLogType {
    no: number;
    msg_no: number;
    status: number;
    froms: number;
    results: string;
    created_at: string;
}

const fetchData = async (pushNo: string) => {
    const url = `${axiosPath.postbox}/${pushNo}/history`;
    const response = await axiosInstance.get(url);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const originData: BasePushLogType[] = response.data.data.list;

    if (originData) {
        return {
            result: parse(originData),
            total: response.data.totalCnt || 100,
        };
    }

    return { result: [], total: 0 };
};

const parse = (originData: BasePushLogType[]) => {
    let newData: PushLogType[] = [];
    originData.forEach((item: BasePushLogType) => {
        const { no, msg_no, status, froms, results, created_at } = item;
        newData.push({
            no,
            messageNo: msg_no,
            status,
            froms,
            results,
            createdAt: created_at,
        });
    });

    return newData;
};

const usePushLog = (pushNo: string) => {
    return useQuery<ResponseMissgoApi<PushLogType>, Error>(
        [queryKeys.PUSH_LOG_LIST, pushNo],
        () => fetchData(pushNo),
        {},
    );
};

export { usePushLog };
