import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { paymentHistory } from "@src/data/UserDetailSampleData";
import { Title, Table, IconButton, Button } from "@src/components";
import { Common } from "@src/styles/Common";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { getColumns } from "../UserPaymentListColumns";
import { useNavigate } from "react-router-dom";
import { useGetUserPaymentHistory } from "@src/hooks/queries/useUserPaymentHistory";
import { DetailUserProps } from "@src/interfaces/User.interface";

interface IPaymentListContainer {
    handleOpenModal: Function;
    handleIndex: Function;
    userNo: number;
}

function PaymentListContainer({
    handleOpenModal,
    handleIndex,
    userNo,
}: IPaymentListContainer) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = getColumns(navigate);
    const handleModal = (index: number) => {
        handleOpenModal();
        handleIndex(index);
    };
    const [listData, setListData] = useState<any[]>([]);

    const [isSearch, setIsSearch] = useState(false);
    const [params, setParams] = useState<{ user_no: number; next_it?: string }>(
        { user_no: userNo },
    );
    const setInit = () => {
        setListData([]);
        setParams({ user_no: userNo });
    };
    const getPaymentList = () => {
        dispatch(startLoading());
        setInit();
        setIsSearch(true);
    };
    const { isLoading, data, isError, refetch, isRefetching } =
        useGetUserPaymentHistory(params, isSearch);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setListData(data);
            dispatch(endLoading());
        } else if (isError) {
            dispatch(endLoading());
        }
        setIsSearch(false);
    }, [isLoading, isError, data]);
    useEffect(() => {
        if (!isRefetching) {
            dispatch(endLoading());
        }
    }, [isRefetching]);
    useEffect(() => {
        if (data) {
            let lastIndex = data.length - 1;
            setParams({
                ...params,
                next_it: data[lastIndex]?.nextItem,
            });
        }
    }, [data]);

    const handleViewMore = () => {
        refetch();
    };

    return (
        <div css={[paymentListWrap, paymentList]}>
            <div>
                <Title title="결제 내역" size="small" />
                <Button
                    label="조회"
                    color={"gd"}
                    size={"medium"}
                    isDisabled={false}
                    handleClick={() => getPaymentList()}
                    deleteBtn={false}
                    defaultValue={"조회"}
                />
            </div>
            <Table
                columns={columns}
                data={listData}
                initialState={{ pageIndex: 0, pageSize: paymentHistory.length }}
                FixedHeight={450}
                showPagination={false}
                showNumber={false}
            />
        </div>
    );
}

const paymentList = css`
    margin-top: 60px;
    & > div:nth-of-type(2) {
        overflow-y: scroll;
    }
    & > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

const paymentListWrap = css`
    width: 100%;
    min-width: 980px;
    margin-top: 70px;
    & > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const afterBtnClick = css`
    tbody {
        opacity: 1;
        background-color: ${Common.colors.white};
    }
`;
export default PaymentListContainer;
