import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { IconButton, Label } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { ExpertStatus, AdStatus } from "@src/constants/ExpertStatus";
import { OfficeParamsDto, userExpertDto } from "./types";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";

export const getColumns = (
    active: any,
    setActive: Dispatch<SetStateAction<any>>,
    officeSearchParams: OfficeParamsDto,
) => {
    const trRef = useRef<any>(null);

    useEffect(() => {
        if (active) {
            const row = document.getElementById(active.no)?.parentElement
                ?.parentElement;
            if (trRef.current) {
                trRef.current.style.backgroundColor = "";
            }
            if (row) row.style.backgroundColor = Common.colors.purple200;
            trRef.current = row;
        }
    }, [active, officeSearchParams?.page]);
    return [
        {
            Header: "번호",
            accessor: "no",
            Cell: ({ cell: { value }, row }: any) => {
                return (
                    <div
                        style={{ width: "80px", textAlign: "center" }}
                        onClick={() => {
                            setActive(row.original);
                        }}
                        id={value}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            Header: "이름",
            accessor: "ceo",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{ width: "150px", textAlign: "center" }}
                    onClick={() => {
                        setActive(row.original);
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "연락처",
            accessor: "phone",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{
                        width: "150px",
                        height: "100%",
                        textAlign: "center",
                    }}
                    onClick={() => {
                        setActive(row.original);
                    }}
                >
                    {value}
                </div>
            ),
        },
        {
            Header: "소재지",
            accessor: "address",
            Cell: ({ cell: { value }, row }: any) => (
                <div
                    style={{ textAlign: "center" }}
                    onClick={() => {
                        setActive(row.original);
                    }}
                >
                    {value}
                </div>
            ),
        },
    ];
};
