import { css } from "@emotion/react";
import React, { useRef } from "react";
import uploadIcon from "@src/assets/imgUploadIcon.png";
import imageCompression from "browser-image-compression";
import { uploadFile } from "@src/api/uploadImage";
import Button from "./button/Button";

interface Props {
    bucket: string;
    onChangeFile: (val: any) => void;
    style?: string;
    multi?: boolean;
    possible?: string;
}

const FileUploadView: React.FC<Props> = ({
    bucket,
    onChangeFile,
    style = "box",
    multi = false,
    possible = "image",
}) => {
    const onClickToUpload = () => {
        const fileInput = document.getElementById("fileUpload");
        fileInput?.click();
    };
    const handleFileUpload = async (evt: any) => {
        const newUploadFile = evt.target.files[0];
        const newName = newUploadFile?.name.split(".")[0] ?? "noName";

        if (!(possible && newUploadFile.type.includes(possible))) {
            return;
        }
        // uploadFile(bucket, [newUploadFile]).then((res: any) => {
        //     onChangeFile({ ...res.data, name: newName });
        // });
        if (newUploadFile && newUploadFile.type.includes("image")) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 5760,
                useWebWorker: true,
            };
            try {
                const compressedFile = await imageCompression(
                    newUploadFile,
                    options,
                );
                const newFile = new File(
                    [compressedFile],
                    `${compressedFile.name.split(".")[0]}.${
                        compressedFile.name.split(".")[1]
                    }`,
                    {
                        type: newUploadFile.type,
                    },
                );
                uploadFile(bucket, [newFile]).then((res: any) => {
                    onChangeFile({ ...res.data, name: newName });
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            uploadFile(bucket, [newUploadFile]).then((res: any) => {
                onChangeFile({ ...res.data, name: newName });
            });
        }
    };
    return (
        <>
            {style === "box" ? (
                <button css={inputStyle} onClick={onClickToUpload}>
                    <img src={uploadIcon} alt="" />
                </button>
            ) : (
                <Button
                    label="파일 선택"
                    color="white"
                    size="small"
                    handleClick={(e) => {
                        onClickToUpload();
                    }}
                    styles={{ width: "100%", marginTop: "10px" }}
                />
            )}
            <input
                type="file"
                id="fileUpload"
                multiple={multi}
                hidden
                onChange={handleFileUpload}
            />
        </>
    );
};

export default FileUploadView;

const inputStyle = css`
    width: 7rem;
    height: 7rem;
    border: 1px solid #b5b5b5;
    background-color: #b5b5b5;
    border-radius: 8px;
    cursor: pointer;
`;
