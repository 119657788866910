import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";

const fetchGetTicketCount = async (params: any) => {
    const response = await axiosInstance.get(
        `${axiosPath.ticket}/count/${params.ticketType}`,
        { params: { userNo: params.userNo } },
    );
    return response.data;
};

export const useGetTickectCount = (params: any, enabled: boolean = false) => {
    return useQuery(
        ["getTicketCount", params],
        () => fetchGetTicketCount(params),
        { enabled },
    );
};

const fetchgetChargeAdTicketList = async (params: any) => {
    const response = await axiosInstance.get(`${axiosPath.ticket}/items`, {
        params,
    });
    return response.data;
};

export const useGetChargeAdTicketList = (
    params: any,
    enabled: boolean = false,
) => {
    return useQuery(
        ["getChargeAdTicketList", [params]],
        () => fetchgetChargeAdTicketList(params),
        { enabled },
    );
};

export const useDeleteAdTicket = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (ticketNo: number) => {
            try {
                const response = await axiosInstance.delete(
                    `${axiosPath.ticket}/${ticketNo}`,
                );
                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};

const fetchGetTicketDetailData = async (ticketNo: number) => {
    const response = await axiosInstance.get(`${axiosPath.ticket}/${ticketNo}`);
    return response.data;
};

export const useGetTicketDetailData = (
    ticketNo: number,
    enabled: boolean = false,
) => {
    return useQuery(
        ["getTicketDetailData", ticketNo],
        () => fetchGetTicketDetailData(ticketNo),
        { enabled },
    );
};

export const useRefundTicket = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: {
            ticketNo: number;
            refundPrice: number;
            reason: string;
        }) => {
            try {
                const body = {
                    ticketNo: params.ticketNo,
                    refundPrice: params.refundPrice,
                    reason: params.reason,
                };
                const response = await axiosInstance.post(
                    `${axiosPath.ticket}/refund`,
                    body,
                );
                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};

export const useEditTicketExpiredAt = (
    onSuccess: (datum: any) => void = () => {},
    onError: (err: AxiosError) => void = () => {},
) => {
    return useMutation(
        async (params: { ticketNo: number; expiredAt: string }) => {
            try {
                const body = {
                    ticketNo: params.ticketNo,
                    expiredAt: params.expiredAt,
                };
                const response = await axiosInstance.post(
                    `${axiosPath.ticket}/expired`,
                    body,
                );
                return response.data;
            } catch (err) {
                console.log("ERR", err);
                throw err;
            }
        },
        { onSuccess: onSuccess, onError: onError },
    );
};
