import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Title, Button, Input, ConfirmModal, ToggleButton, EditModal } from "@src/components";
import PaymentDetailModal from "@src/pages/popup/PaymentDetailModal";
import {
    UserInfoContainer,
    PaymentList,
    RegisterList,
    FavoriteInfo,
    LogBox,
    RecentViewListContainer,
    SubscriptionInfoContainer,
} from "./containers";
import { useNavigate, useParams } from "react-router-dom";
import {
    useDeletePartner,
    useDeungiTicketCnt,
    useFindExistingDeunggiTicketCount,
    useGetPartnerInfo,
    useGetUserDetailData,
    useRefundDeungi,
    useRefundPGSaver,
    useSubscriptionInfo,
    useUpdateDeungi,
} from "@src/hooks/queries/useUserDetail";
import { DetailUserProps, DeungiDetailList, PartnerInfoProps } from "@src/interfaces/User.interface";
import { ConvertDate, ConvertDateTime } from "@src/utils/dateUtil";
import styled from "@emotion/styled";
import UserSubscriptionInfoModal from "./UserSubscriptionInfoModal";
import UserSubscriptionEditModal from "./UserSubscriptionEditModal";
import UserSubscriptionStateEditModal from "./UserSubscriptionStateEditModal";
import ProductInfoContainer from "./containers/ProductInfoContainer";
import UserProductInfoModal from "./UserProductInfoModal";
import UserDeungiEditModal from "./UserDeungiEditModal";
import UserDeungiRefundModal from "./UserDeungiRefundModal";
import UserSugangInfoModal from "./UserSugangInfoModal";
import { useUserCampusCount } from "@src/hooks/queries/useUserCampus";
import PartnerInfoContainer from "./containers/PartnerInfoContainer";
import UserPartnerAddModal from "./UserPartnerAddModal";
import UserExistingDeunggiListModal from "./UserExistingDeunggiListModal";
import {
    useDeleteAdTicket,
    useEditTicketExpiredAt,
    useGetChargeAdTicketList,
    useGetTickectCount,
    useGetTicketDetailData,
    useRefundTicket,
} from "@src/hooks/queries/useTicket";
import UserAdTicketModal from "./UserAdTicketModal";
import UserAdTicketRefundModal from "./UserAdTicketRefundModal";
import UserAdTicketEditModal from "./UserAdTicketEditModal";
import UserStateLandInfoModal from "./UserStateLandInfoModal";
import UserStateLandRefundModal from "./UserStateLandRefundModal";
import {
    useDeleteStateLand,
    useEditStateLandExpiredAt,
    useGetStateLandItems,
    useGetUserStateLandData,
} from "@src/hooks/queries/useStateLand";

import {
    useDeleteRoadExpansion,
    useEditRoadExpansionExpiredAt,
    useGetRoadExpansionItems,
    useGetUserRoadExpansionData,
} from "@src/hooks/queries/userRoadExpansion";

import UserStateLandEditModal from "./UserStateLandEditModal";
import UserRoadExpansionEditModal from "./UserRoadExpansionEditModal";
import UserRoadExpansionInfoModal from "./UserRoadExpansionInfoModal";
import UserRoadExpansionRefundModal from "./UserRoadExpansionRefundModal";
import {
    useDeleteGoStop,
    useEditGoStopExpiredAt,
    useGetGoStopItems,
    useGetUserGoStopData,
    useGetUserStoreGoStopData,
} from "@src/hooks/queries/useGoStop";
import UserGoStopInfoModal from "./UserGoStopInfoModal";
import UserGoStopRefundModal from "./UserGoStopRefundModal";
import UserGoStopEditModal from "./UserGoStopEditModal";

export interface subscriptionInfoType {
    cancel_reason: string | null;
    expired_at: string;
    first_paid_at: string | null;
    is_real_paid: number | null;
    last_paid_at: string | null;
    next_renew_at: string;
    order_code: string | null;
    paid_completed: number | null;
    paid_price: number | null;
    price: number | null;
    product_name: string | null;
    product_name_color: string | null;
    product_name_color_bg: string | null;
    refund_price: number | null;
    remain_price: number | null;
    renew_cnt: number | null;
    renew_term: string | null;
    serve_duration: number | null;
    serve_duration_type: string | null;
    started_at: string | null;
    subs_group: string | null;
    subs_no: number | null;
    subs_status: number | null;
    user_no: number | null;
    subs_group_name: string | null;
}

const initialState: subscriptionInfoType = {
    cancel_reason: "",
    expired_at: "",
    first_paid_at: "",
    is_real_paid: 0,
    last_paid_at: "",
    next_renew_at: "",
    order_code: "",
    paid_completed: 0,
    paid_price: 0,
    price: 0,
    product_name: "",
    product_name_color: "",
    product_name_color_bg: "",
    refund_price: 0,
    remain_price: 0,
    renew_cnt: 0,
    renew_term: "",
    serve_duration: 0,
    serve_duration_type: "",
    started_at: "",
    subs_group: "",
    subs_no: 0,
    subs_status: 0,
    user_no: 0,
    subs_group_name: "",
};

function UserDetail() {
    const navigate = useNavigate();
    const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
    const [isSubscriptionInfoModalShow, setIsSubscriptionInfoModalShow] = useState(false);
    const [isSubscriptionEditModalShow, setIsSubscriptionEditModalShow] = useState(false);

    const [isSubscriptionStateEditModalShow, setIsSubscriptionStateEditModalShow] = useState(false);
    const [user, setUser] = useState<DetailUserProps>();
    const [isRefresh, setIsRefresh] = useState<boolean>(false);
    const [isProductModalShow, setIsProductModalShow] = useState<boolean>(false);
    const [isDeungiEditModalShow, setIsDeungiEditModalShow] = useState<boolean>(false);
    const [isDeungiRefundModalShow, setIsDeungiRefundModalShow] = useState<boolean>(false);
    const [isSugangModalShow, setIsSugangModalShow] = useState<boolean>(false);
    const [liveDeunggiModalOpen, setLiveDeunggiModalOpen] = useState<boolean>(false);

    const [editTime, setEditTime] = useState<string>("");
    const [deungiTicketCnt, setDeungiTicketCnt] = useState<number>(0);
    const [deungiTicket, setDeungiTicket] = useState<DeungiDetailList>({
        no: 0,
        startedAt: "",
        productName: "",
        usedQuantity: 0,
        paidPrice: 0,
        paidNo: 0,
        refundQuantity: 0,
        refundPrice: 0,
        expiredAt: "",
        state: "",
        status: 0,
    });
    const initalPartnerInfo = {
        no: 0,
        expiredAt: "",
        userPaybackPartner: {
            name: "",
            code: "",
            no: 0,
            deletedAt: null,
            status: "",
            user: {
                no: 0,
                info: {
                    uname: "",
                },
            },
        },
    };
    const [deungiRefetch, setDeungiRefech] = useState<boolean>(false);
    const [isCheckModalShow, setIsCheckModalShow] = useState<boolean>(false);
    const [deungiRefundPrice, setDeungiRefundPrice] = useState<string>("");
    const [deungiType, setDeungiType] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [isPartnerModal, setIsPartnerModal] = useState<boolean>(false);
    const [isPartnerEditModal, setIsPartnerEditModal] = useState<{
        isShowModal: boolean;
        type: string;
    }>({
        isShowModal: false,
        type: "create",
    });
    const [partnerInfo, setPartnerInfo] = useState<PartnerInfoProps>(initalPartnerInfo);
    const { id } = useParams();
    const userNo = Number(id);
    const { isLoading, data, isError, refetch } = useGetUserDetailData(userNo);
    const { data: existingDeunggiCount, refetch: refetchExistingDeunggiCount } =
        useFindExistingDeunggiTicketCount(userNo);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setUser(data);
            setIsRefresh(!isRefresh);
        } else if (data === null) {
            // setIsDeleteModalShow((prev) => !prev);
        }
    }, [isLoading, isError, data]);
    const [isModalShow, setIsModalShow] = useState(false);
    const handleOpenModal = () => setIsModalShow((prev) => !prev);
    const [paymentIndex, setPaymentIndex] = useState(0);
    const handleIndex = (index: number) => {
        setPaymentIndex(index);
    };

    const [subscriptionInfo, setSubscriptionInfo] = useState<subscriptionInfoType | undefined>(initialState);

    const [nextPaid, setNextPaid] = useState<string | undefined>("");
    useEffect(() => {
        setNextPaid(subscriptionInfo?.next_renew_at);
    }, [subscriptionInfo]);

    const showSubscriptionDetail = async () => {
        if (user) {
            const result = await useSubscriptionInfo(user?.userNo);
            setSubscriptionInfo(result.data.data.data);
        }
    };

    const showDeungiTicketCnt = async () => {
        if (user) {
            const response = await useDeungiTicketCnt(userNo);

            setDeungiTicketCnt(response.data.curHaveCnt);
        }
    };

    const tryEditDeungi = async () => {
        const response = await useUpdateDeungi(deungiTicket.no, editTime);
        setDeungiRefech((prev) => !prev);
        setIsCheckModalShow(false);
    };
    const tryRefundDeungi = async () => {
        setIsCheckModalShow(false);
        const response = await useRefundDeungi(deungiTicket.no, Number(deungiRefundPrice), reason);
        setDeungiRefech((prev) => !prev);
    };

    const { data: classCount, refetch: classCountRefetch } = useUserCampusCount(id ?? "");

    useEffect(() => {
        showDeungiTicketCnt();
        showSubscriptionDetail();
    }, [user]);

    useEffect(() => {
        useGetPartnerInfo(userNo).then((res) => {
            setPartnerInfo(res);
        });
        refetchExistingDeunggiCount();
    }, []);

    const { data: adTicketCount, refetch: refetchAdTicketCount } = useGetTickectCount({ userNo: id, ticketType: 3 });
    useEffect(() => {
        refetchAdTicketCount();
    }, []);
    const [isShowAdTicketModal, setIsShowAdTicketModal] = useState<boolean>(false);
    const [isShowDeleteAdTicketModal, setIsShowDeleteAdTicketModal] = useState<boolean>(false);

    const { data: adTicketData, refetch: refetchAdTicketData } = useGetChargeAdTicketList({
        userNo: id,
        limit: 9999999999,
        page: 1,
        type: 0,
        ticketType: 3,
    });
    useEffect(() => {
        refetchAdTicketData();
    }, []);
    const [isShowStateLandModal, setIsShowStateLandModal] = useState<boolean>(false);
    const [isShowStateLandRefundModal, setIsShowStateLandRefundModal] = useState<boolean>(false);
    const [seletedDeleteAdTickNo, setSeletedDeleteAdTicketNo] = useState<number>(0);
    const [isShowRoadExpansionModal, setIsShowRoadExpansionModal] = useState<boolean>(false);
    const [isShowRoadExpansionRefundModal, setIsShowRoadExpansionRefundModal] = useState<boolean>(false);
    const [isShowGoStopModal, setIsShowGoStopModal] = useState(false);
    const [isShowGoStopRefundModal, setIsShowGoStopRefundModal] = useState(false);
    const [isShowStoreGoStopModal, setIsShowStoreGoStopModal] = useState(false);
    const [isShowStoreGoStopRefundModal, setIsShowStoreGoStopRefundModal] = useState(false);

    const deleteAdTicket = useDeleteAdTicket(
        () => {
            setSeletedDeleteAdTicketNo(0);
            refetchAdTicketCount();
            refetchAdTicketData();
            setIsShowDeleteAdTicketModal(false);
        },
        () => {
            setSeletedDeleteAdTicketNo(0);
            setIsShowDeleteAdTicketModal(false);
        },
    );
    const onClickDeleteAdTicket = (ticketNo: number) => {
        deleteAdTicket.mutate(ticketNo);
    };
    const [seletedAdTicketNo, setSeletedAdTickNo] = useState<number>(0);
    const [isShowAdTicketRefundModal, setIsShowAdTicketRefundModal] = useState<boolean>(false);
    const { data: AdTicketDetailData, refetch: refetchAdTicketDetailData } = useGetTicketDetailData(seletedAdTicketNo);

    useEffect(() => {
        seletedAdTicketNo && refetchAdTicketDetailData();
    }, [seletedAdTicketNo]);
    const [adTicketRefundState, setAdTicketRefundState] = useState<{
        ticketNo: number;
        refundPrice: number;
        reason: string;
    }>({ ticketNo: 0, refundPrice: 0, reason: "" });
    const [isShowAdTicketRefundConfirmModal, setIsShowAdTicketRefundConfirmModal] = useState<boolean>(false);

    const refundAdTicket = useRefundTicket(
        () => {
            setIsShowAdTicketRefundConfirmModal(false);
            setAdTicketRefundState({ ticketNo: 0, refundPrice: 0, reason: "" });
            setSeletedAdTickNo(0);
            refetchAdTicketCount();
            refetchAdTicketData();
            setIsShowAdTicketModal(true);
        },
        () => {
            setAdTicketRefundState({ ticketNo: 0, refundPrice: 0, reason: "" });
            setSeletedAdTickNo(0);
            setIsShowAdTicketRefundConfirmModal(false);
            setIsShowAdTicketModal(true);
        },
    );

    const onClickRefundAdTicket = () => {
        refundAdTicket.mutate(adTicketRefundState);
    };

    useEffect(() => {
        seletedAdTicketNo &&
            setAdTicketRefundState({
                ...adTicketRefundState,
                ticketNo: seletedAdTicketNo,
            });
    }, [seletedAdTicketNo]);

    const [isShowAdTicketEditModal, setIsShowAdTicketEditModal] = useState<boolean>(false);
    const [adTicketEditTime, setAdTicketEditTime] = useState<string>("");

    const [isShowAdTicketEditConfirmModal, setIsShowAdTicketEditConfirmModal] = useState<boolean>(false);

    const editExpiredAtAdTicket = useEditTicketExpiredAt(
        () => {
            setIsShowAdTicketEditConfirmModal(false);
            setAdTicketEditTime("");
            setSeletedAdTickNo(0);
            refetchAdTicketCount();
            refetchAdTicketData();
            setIsShowAdTicketModal(true);
        },
        () => {
            setAdTicketEditTime("");
            setSeletedAdTickNo(0);
            setIsShowAdTicketEditConfirmModal(false);
            setIsShowAdTicketModal(true);
        },
    );

    const onClickEditExpiredAtAdTicket = () => {
        editExpiredAtAdTicket.mutate({
            ticketNo: seletedAdTicketNo,
            expiredAt: adTicketEditTime,
        });
    };
    const { data: userStateLandData, refetch: refetchUserStateLandData } = useGetUserStateLandData(Number(id));

    const { data: stateLandItems, refetch: refetchStateLandItems } = useGetStateLandItems({
        page: 1,
        limit: 9999,
        userNo: Number(id),
    });

    const { data: userGoStopData, refetch: refetchUserGoStopData } = useGetUserGoStopData(Number(id));
    const { data: goStopItems, refetch: refetchGoStopItems } = useGetGoStopItems({
        page: 1,
        limit: 9999,
        userNo: Number(id),
        gostopType: 1,
    });
    const { data: userStoreGoStopData, refetch: refetchUserStoreGoStopData } = useGetUserStoreGoStopData(Number(id));
    const { data: storeGoStopItems, refetch: refetchStoreGoStopItems } = useGetGoStopItems({
        page: 1,
        limit: 9999,
        userNo: Number(id),
        gostopType: 2,
    });

    const { data: userRoadExapnsionData, refetch: refetchUserRoadExpansionData } = useGetUserRoadExpansionData(
        Number(id),
    );

    const { data: roadExpansionItems, refetch: refetchRoadExpansionItems } = useGetRoadExpansionItems({
        page: 1,
        limit: 9999,
        userNo: Number(id),
    });

    useEffect(() => {
        refetchUserStateLandData();
        refetchStateLandItems();
        refetchUserRoadExpansionData();
        refetchRoadExpansionItems();
        refetchUserGoStopData();
        refetchGoStopItems();
        refetchUserStoreGoStopData();
        refetchStoreGoStopItems();
    }, []);

    const [isShowStateLandEditModal, setIsShowStateLandEditModal] = useState<boolean>(false);
    const [stateLandEditTime, setStateLandEditTime] = useState<string>("");
    const [stateLandItem, setStateLandItem] = useState<any>({});
    const [isShowStateLandEditConfirmModal, setIsShowStateLandEditConfirmModal] = useState<boolean>(false);

    const [isShowRoadExpansionEditModal, setIsShowRoadExpansionEditModal] = useState<boolean>(false);
    const [roadExpansionEditTime, setRoadExpansionEditTime] = useState<string>("");
    const [roadExpansionItem, setRoadExpansionItem] = useState<any>({});
    const [isShowRoadExpansionEditConfirmModal, setIsShowRoadExpansionEditConfirmModal] = useState<boolean>(false);

    const [isShowGoStopEditModal, setIsShowGoStopEditModal] = useState<boolean>(false);
    const [goStopEditTime, setGoStopEditTime] = useState<string>("");
    const [goStopItem, setGoStopItem] = useState<any>({});
    const [isShowGoStopEditConfirmModal, setIsShowGoStopEditConfirmModal] = useState<boolean>(false);

    const [isShowStoreGoStopEditModal, setIsShowStoreGoStopEditModal] = useState<boolean>(false);
    const [storeGoStopEditTime, setStoreGoStopEditTime] = useState<string>("");
    const [storeGoStopItem, setStoreGoStopItem] = useState<any>({});
    const [isShowStoreGoStopEditConfirmModal, setIsShowStoreGoStopEditConfirmModal] = useState<boolean>(false);

    const editExpiredAtStateLand = useEditStateLandExpiredAt(
        () => {
            refetchStateLandItems();
            setStateLandItem({});
            setStateLandEditTime("");
            setIsShowStateLandEditConfirmModal(false);
            setIsShowStateLandModal(true);
        },
        () => {
            setStateLandItem({});
            setStateLandEditTime("");
            setIsShowStateLandEditConfirmModal(false);
            setIsShowStateLandModal(true);
        },
    );

    const editExpiredAtRoadExpansion = useEditRoadExpansionExpiredAt(
        () => {
            refetchRoadExpansionItems();
            setRoadExpansionItem({});
            setRoadExpansionEditTime("");
            setIsShowRoadExpansionEditModal(false);
            setIsShowRoadExpansionModal(true);
        },
        () => {
            setRoadExpansionItem({});
            setRoadExpansionEditTime("");
            setIsShowRoadExpansionEditConfirmModal(false);
            setIsShowRoadExpansionModal(true);
        },
    );

    const editExpiredAtGoStop = useEditGoStopExpiredAt(
        () => {
            refetchGoStopItems();
            setGoStopItem({});
            setGoStopEditTime("");
            setIsShowGoStopEditModal(false);
            setIsShowGoStopModal(true);
        },
        () => {
            setGoStopItem({});
            setGoStopEditTime("");
            setIsShowGoStopEditConfirmModal(false);
            setIsShowGoStopModal(true);
        },
    );

    const editExpiredAtStoreGoStop = useEditGoStopExpiredAt(
        () => {
            refetchStoreGoStopItems();
            setStoreGoStopItem({});
            setStoreGoStopEditTime("");
            setIsShowStoreGoStopEditModal(false);
            setIsShowStoreGoStopModal(true);
        },
        () => {
            setStoreGoStopItem({});
            setStoreGoStopEditTime("");
            setIsShowStoreGoStopEditConfirmModal(false);
            setIsShowStoreGoStopModal(true);
        },
    );

    const onClickEditExpiredAtStateLand = () => {
        editExpiredAtStateLand.mutate({
            stateLandNo: stateLandItem.no,
            expiredAt: stateLandEditTime,
        });
    };

    const onClickEditExpiredAtRoadExpansion = () => {
        editExpiredAtRoadExpansion.mutate({
            roadPlanNo: roadExpansionItem.no,
            expiredAt: roadExpansionEditTime,
        });
    };

    const onClickEditExpiredAtGoStop = () => {
        editExpiredAtGoStop.mutate({
            gostopNo: goStopItem.no,
            expiredAt: goStopEditTime,
        });
    };

    const onClickEditExpiredAtStoreGoStop = () => {
        editExpiredAtStoreGoStop.mutate({
            gostopNo: storeGoStopItem.no,
            expiredAt: storeGoStopEditTime,
        });
    };

    const [isShowStateLandDeleteConfirmModal, setIsShowStateLandDeleteConfirmModal] = useState<boolean>(false);

    const deleteStateLand = useDeleteStateLand(
        () => {
            refetchStateLandItems();
            setStateLandItem({});
            setIsShowStateLandDeleteConfirmModal(false);
            setIsShowStateLandModal(true);
        },
        () => {
            refetchStateLandItems();
            setStateLandItem({});
            setIsShowStateLandDeleteConfirmModal(false);
            setIsShowStateLandModal(true);
        },
    );

    const [isShowRoadExpansionDeleteConfirmModal, setIsShowRoadExpansionDeleteConfirmModal] = useState<boolean>(false);

    const deleteRoadExpansion = useDeleteRoadExpansion(
        () => {
            refetchRoadExpansionItems();
            setRoadExpansionItem({});
            setIsShowRoadExpansionDeleteConfirmModal(false);
            setIsShowRoadExpansionModal(true);
        },
        () => {
            refetchRoadExpansionItems();
            setRoadExpansionItem({});
            setIsShowRoadExpansionDeleteConfirmModal(false);
            setIsShowRoadExpansionModal(true);
        },
    );

    const [isShowGoStopDeleteConfirmModal, setIsShowGoStopDeleteConfirmModal] = useState<boolean>(false);

    const deleteGoStop = useDeleteGoStop(
        () => {
            refetchGoStopItems();
            setGoStopItem({});
            setIsShowGoStopDeleteConfirmModal(false);
            setIsShowGoStopModal(true);
        },
        () => {
            refetchGoStopItems();
            setGoStopItem({});
            setIsShowGoStopDeleteConfirmModal(false);
            setIsShowGoStopModal(true);
        },
    );

    const [isShowStoreGoStopDeleteConfirmModal, setIsShowStoreGoStopDeleteConfirmModal] = useState<boolean>(false);

    const deleteStoreGoStop = useDeleteGoStop(
        () => {
            refetchStoreGoStopItems();
            setStoreGoStopItem({});
            setIsShowStoreGoStopDeleteConfirmModal(false);
            setIsShowStoreGoStopModal(true);
        },
        () => {
            refetchStoreGoStopItems();
            setStoreGoStopItem({});
            setIsShowStoreGoStopDeleteConfirmModal(false);
            setIsShowStoreGoStopModal(true);
        },
    );

    const onClickDeleteStateLand = () => {
        deleteStateLand.mutate(stateLandItem.no);
    };

    const onClickDeleteRoadExpansion = () => {
        deleteRoadExpansion.mutate(roadExpansionItem.no);
    };

    const onClickDeleteGoStop = () => {
        deleteGoStop.mutate(goStopItem.no);
    };

    const onClickDeleteStoreGoStop = () => {
        deleteStoreGoStop.mutate(storeGoStopItem.no);
    };

    const [refundStateLandState, setRefundStateLandState] = useState<{
        userNo: number;
        orderNo: number;
        amount: number;
        comments: string;
    }>({
        userNo: Number(id),
        orderNo: 0,
        amount: 0,
        comments: "",
    });

    const [refundRoadExpansionState, setRefundRoadExpansionState] = useState<{
        userNo: number;
        orderNo: number;
        amount: number;
        comments: string;
    }>({
        userNo: Number(id),
        orderNo: 0,
        amount: 0,
        comments: "",
    });

    const [refundGoStopState, setRefundGoStopState] = useState<{
        userNo: number;
        orderNo: number;
        amount: number;
        comments: string;
    }>({
        userNo: Number(id),
        orderNo: 0,
        amount: 0,
        comments: "",
    });

    const [refundStoreGoStopState, setRefundStoreGoStopState] = useState<{
        userNo: number;
        orderNo: number;
        amount: number;
        comments: string;
    }>({
        userNo: Number(id),
        orderNo: 0,
        amount: 0,
        comments: "",
    });

    const [isShowStateLandRefundConfirmModal, setIsShowStateLandRefundConfirmModal] = useState<boolean>(false);
    const [isShowRoadExpansionRefundConfirmModal, setIsShowRoadExpansionRefundConfirmModal] = useState<boolean>(false);
    const [isShowGoStopRefundConfirmModal, setIsShowGoStopRefundConfirmModal] = useState<boolean>(false);
    const [isShowStoreGoStopRefundConfirmModal, setIsShowStoreGoStopRefundConfirmModal] = useState<boolean>(false);

    const refundStateLand = useRefundPGSaver(
        () => {
            refetchStateLandItems();
            setStateLandItem({});
            setRefundStateLandState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });

            setIsShowStateLandRefundConfirmModal(false);
            setIsShowStateLandModal(true);
        },
        () => {
            refetchStateLandItems();
            setStateLandItem({});
            setRefundStateLandState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });
            setIsShowStateLandRefundConfirmModal(false);
            setIsShowStateLandModal(true);
        },
    );

    const refundRoadExpansion = useRefundPGSaver(
        () => {
            refetchRoadExpansionItems();
            setRoadExpansionItem({});
            setRefundRoadExpansionState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });

            setIsShowRoadExpansionRefundConfirmModal(false);
            setIsShowRoadExpansionModal(true);
        },
        () => {
            refetchRoadExpansionItems();
            setRoadExpansionItem({});
            setRefundRoadExpansionState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });
            setIsShowRoadExpansionRefundConfirmModal(false);
            setIsShowRoadExpansionModal(true);
        },
    );

    const refundGoStop = useRefundPGSaver(
        () => {
            refetchGoStopItems();
            setGoStopItem({});
            setRefundGoStopState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });

            setIsShowGoStopRefundConfirmModal(false);
            setIsShowGoStopModal(true);
        },
        () => {
            refetchGoStopItems();
            setGoStopItem({});
            setRefundGoStopState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });
            setIsShowGoStopRefundConfirmModal(false);
            setIsShowGoStopModal(true);
        },
    );

    const refundStoreGoStop = useRefundPGSaver(
        () => {
            refetchStoreGoStopItems();
            setStoreGoStopItem({});
            setRefundStoreGoStopState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });

            setIsShowStoreGoStopRefundConfirmModal(false);
            setIsShowStoreGoStopModal(true);
        },
        () => {
            refetchStoreGoStopItems();
            setStoreGoStopItem({});
            setRefundStoreGoStopState({
                userNo: 0,
                orderNo: 0,
                amount: 0,
                comments: "",
            });
            setIsShowStoreGoStopRefundConfirmModal(false);
            setIsShowStoreGoStopModal(true);
        },
    );

    const onClickRefundStateLand = () => {
        refundStateLand.mutate(refundStateLandState);
    };

    const onClickRefundRoadExpansion = () => {
        refundRoadExpansion.mutate(refundRoadExpansionState);
    };

    const onClickRefundGoStop = () => {
        refundGoStop.mutate(refundGoStopState);
    };
    const onClickRefundStoreGoStop = () => {
        refundStoreGoStop.mutate(refundStoreGoStopState);
    };

    useEffect(() => {
        setRefundStateLandState({
            ...refundStateLandState,
            orderNo: stateLandItem.orderNo,
        });
    }, [stateLandItem]);

    useEffect(() => {
        setRefundRoadExpansionState({
            ...refundRoadExpansionState,
            orderNo: roadExpansionItem.orderNo,
        });
    }, [roadExpansionItem]);

    useEffect(() => {
        setRefundGoStopState({
            ...refundGoStopState,
            orderNo: goStopItem.orderNo,
        });
    }, [goStopItem]);

    useEffect(() => {
        setRefundStoreGoStopState({
            ...refundStoreGoStopState,
            orderNo: storeGoStopItem.orderNo,
        });
    }, [storeGoStopItem]);

    return (
        <div css={detailStyle}>
            <Title title="회원 상세 정보" />
            <UserInfoContainer user={user} isUpdate={false} />
            <FavoriteInfo initData={user?.favorite} />
            <ConsentBox
                isUpdate={false}
                initData={{
                    agreements: user?.agreements[0],
                    trial: user?.trial,
                    membership: user?.subscriptionStatus,
                }}
                handlePatchUserData={() => {}}
            />
            <SubscriptionInfoContainer
                user={user}
                setIsSubscriptionInfoModalShow={setIsSubscriptionInfoModalShow}
                subscriptionInfo={subscriptionInfo}
            />
            <ProductInfoContainer
                setIsProductModalShow={setIsProductModalShow}
                deungiTicketCnt={deungiTicketCnt}
                classCount={classCount}
                existingDeunggiCount={existingDeunggiCount}
                refetchExistingDeunggiCount={refetchExistingDeunggiCount}
                isShowSugangModal={() => setIsSugangModalShow(true)}
                isShowLiveDeunggiModal={() => setLiveDeunggiModalOpen(true)}
                adTicket={adTicketCount?.curHaveCnt}
                isShowAdTicket={() => setIsShowAdTicketModal(true)}
                isShowStateLandModal={() => setIsShowStateLandModal(true)}
                isShowRoadExpansionModal={() => setIsShowRoadExpansionModal(true)}
                userStateLandData={userStateLandData}
                userRoadExpansionData={userRoadExapnsionData}
                goStopProductName={userGoStopData?.list[0] ? userGoStopData?.list[0].productName : ""}
                isShowGoStopModal={() => setIsShowGoStopModal(true)}
                storeGoStopProductName={userStoreGoStopData?.list[0] ? userStoreGoStopData?.list[0].productName : ""}
                isShowStoreGoStopModal={() => setIsShowStoreGoStopModal(true)}
            />
            <PartnerInfoContainer
                setIsPartnerModal={setIsPartnerModal}
                partnerInfo={partnerInfo}
                setIsPartnerEditModal={setIsPartnerEditModal}
            />
            <LogBox userNo={userNo} />
            <PaymentList handleOpenModal={handleOpenModal} handleIndex={handleIndex} userNo={userNo} />
            <RegisterList />
            <RecentViewListContainer userNo={userNo} />
            <PaymentDetailModal
                isModalShow={isModalShow}
                handleOpenModal={handleOpenModal}
                paymentIndex={paymentIndex}
            />
            {/* 탈퇴/탈퇴 예정 회원의 페이지 진입시 진입불가 알림 팝업 */}
            {/* <EditModal
                isModalShow={isDeleteModalShow}
                title={"알림"}
                buttonText={"확인"}
                children={
                    "이미 탈퇴하였거나 탈퇴 예정인 회원입니다. 회원목록으로 이동합니다."
                }
                handleOpenModal={() => {
                    navigate("/users/list");
                }}
                size={"medium"}
                handleBtnClick={() => {
                    navigate("/users/list");
                    setIsDeleteModalShow((prev) => !prev);
                }}
                isDisabled={false}
                errorMessage={""}
            /> */}
            <UserSubscriptionInfoModal
                isModalShow={isSubscriptionInfoModalShow}
                setIsSubscriptionInfoModalShow={setIsSubscriptionInfoModalShow}
                setIsSubscriptionEditModalShow={setIsSubscriptionEditModalShow}
                setIsSubscriptionStateEditModalShow={setIsSubscriptionStateEditModalShow}
                user={user}
                subscriptionInfo={subscriptionInfo}
            />
            <UserSubscriptionEditModal
                isModalShow={isSubscriptionEditModalShow}
                setIsSubscriptionEditModalShow={setIsSubscriptionEditModalShow}
                user={user}
                subscriptionInfo={subscriptionInfo}
                nextPaid={nextPaid}
                setNextPaid={setNextPaid}
            />
            <UserSubscriptionStateEditModal
                isModalShow={isSubscriptionStateEditModalShow}
                setIsSubscriptionInfoModalShow={setIsSubscriptionInfoModalShow}
                setIsSubscriptionStateEditModalShow={setIsSubscriptionStateEditModalShow}
                user={user}
                subscriptionInfo={subscriptionInfo}
                refetch={refetch}
                showSubscriptionDetail={showSubscriptionDetail}
            />
            <UserProductInfoModal
                isModalShow={isProductModalShow}
                setIsProductModalShow={setIsProductModalShow}
                deungiTicketCnt={deungiTicketCnt}
                userNo={userNo}
                setIsDeungiEditModalShow={setIsDeungiEditModalShow}
                setDeungiTicket={setDeungiTicket}
                deungiRefetch={deungiRefetch}
                setIsDeungiRefundModalShow={setIsDeungiRefundModalShow}
                setDeungiRefundPrice={setDeungiRefundPrice}
                setReason={setReason}
            />
            <UserDeungiEditModal
                isModalShow={isDeungiEditModalShow}
                setIsModalShow={setIsDeungiEditModalShow}
                deungiTicket={deungiTicket}
                setDeungiRefech={setDeungiRefech}
                setIsCheckModalShow={setIsCheckModalShow}
                setEditTime={setEditTime}
                editTime={editTime}
                setDeungiType={setDeungiType}
            />
            <UserDeungiRefundModal
                setIsDeungiRefundModalShow={setIsDeungiRefundModalShow}
                isModalShow={isDeungiRefundModalShow}
                deungiTicket={deungiTicket}
                setDeungiRefundPrice={setDeungiRefundPrice}
                deungiRefundPrice={deungiRefundPrice}
                setDeungiType={setDeungiType}
                setIsCheckModalShow={setIsCheckModalShow}
                setReason={setReason}
                reason={reason}
                isCheckModalShow={isCheckModalShow}
            />
            <UserSugangInfoModal open={isSugangModalShow} onCloseView={() => setIsSugangModalShow(false)} id={id} />
            <UserExistingDeunggiListModal
                open={liveDeunggiModalOpen}
                existingDeunggiCount={existingDeunggiCount}
                onCloseView={() => setLiveDeunggiModalOpen(false)}
                reOpenView={() => setLiveDeunggiModalOpen(true)}
                userNo={userNo}
                refetchExistingDeunggiCount={refetchExistingDeunggiCount}
            />
            <ConfirmModal
                isModalShow={isCheckModalShow}
                buttonText="확인"
                handleCancel={() => {
                    setIsCheckModalShow(false);
                }}
                handleOk={() => {
                    if (deungiType === "edit") {
                        tryEditDeungi();
                    } else {
                        tryRefundDeungi();
                    }
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isPartnerModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsPartnerModal(false);
                }}
                handleOk={() => {
                    useDeletePartner(userNo).then(() => {
                        useGetPartnerInfo(userNo).then((res) => {
                            setPartnerInfo(res);
                        });
                    });
                    setIsPartnerModal(false);
                }}
            >
                정말 파트너를 해지 하시겠습니까??
            </ConfirmModal>
            <UserPartnerAddModal
                isPartnerModal={isPartnerEditModal}
                setIsPartnerModal={setIsPartnerEditModal}
                partnerInfo={partnerInfo}
                setPartnerInfo={setPartnerInfo}
            />
            <UserAdTicketModal
                totalCount={adTicketCount?.curHaveCnt}
                closeModal={() => {
                    setIsShowAdTicketModal(false);
                }}
                isModalShow={isShowAdTicketModal}
                setIsShowDeleteAdTicketModal={(isShow: boolean) => {
                    setIsShowDeleteAdTicketModal(isShow);
                }}
                setSeletedDeleteAdTicketNo={(ticketNo: number) => {
                    setSeletedDeleteAdTicketNo(ticketNo);
                }}
                dataList={adTicketData?.list}
                setIsShowAdTicketRefundModal={(isShow: boolean) => {
                    setIsShowAdTicketRefundModal(isShow);
                }}
                setSeletedAdTickNo={(no: number) => {
                    setSeletedAdTickNo(no);
                }}
                setIsShowAdTicketEditModal={(isShow: boolean) => {
                    setIsShowAdTicketEditModal(isShow);
                }}
                // refetchAdTicketDetailData={refetchAdTicketDetailData}
            />
            <ConfirmModal
                isModalShow={isShowDeleteAdTicketModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowDeleteAdTicketModal(false);
                }}
                handleOk={() => {
                    onClickDeleteAdTicket(seletedDeleteAdTickNo);
                }}
            >
                정말 취소하시겠습니까? 취소 시 환불없이 광고권이 취소됩니다
            </ConfirmModal>
            <UserAdTicketRefundModal
                isShowAdTicketRefundModal={isShowAdTicketRefundModal}
                setIsShowModal={(isShow: boolean) => {
                    setIsShowAdTicketRefundModal(isShow);
                }}
                AdTicketDetailData={AdTicketDetailData}
                adTicketRefundState={adTicketRefundState}
                setAdTicketRefundState={setAdTicketRefundState}
                setIsShowAdTicketRefundConfirmModal={(isShow: boolean) => {
                    setIsShowAdTicketRefundConfirmModal(isShow);
                }}
            />
            <ConfirmModal
                isModalShow={isShowAdTicketRefundConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowAdTicketRefundConfirmModal(false);
                }}
                handleOk={() => {
                    onClickRefundAdTicket();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <UserAdTicketEditModal
                setAdTicketEditTime={(time: string) => {
                    setAdTicketEditTime(time);
                }}
                setIsShowAdTicketEditModal={(isShow: boolean) => {
                    setIsShowAdTicketEditModal(isShow);
                }}
                isShowAdTicketEditModal={isShowAdTicketEditModal}
                AdTicketDetailData={AdTicketDetailData}
                setIsShowAdTicketEditConfirmModal={(isShow: boolean) => {
                    setIsShowAdTicketEditConfirmModal(isShow);
                }}
            />
            <ConfirmModal
                isModalShow={isShowAdTicketEditConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setAdTicketEditTime("");
                    setSeletedAdTickNo(0);
                    setIsShowAdTicketEditConfirmModal(false);
                    setIsShowAdTicketModal(true);
                }}
                handleOk={() => {
                    onClickEditExpiredAtAdTicket();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <UserStateLandInfoModal
                isModalShow={isShowStateLandModal}
                closeModal={() => {
                    setIsShowStateLandModal(false);
                }}
                setIsShowRefundModal={(isShow: boolean) => {
                    setIsShowStateLandRefundModal(isShow);
                }}
                dataList={stateLandItems?.list}
                totalCount={stateLandItems?.totalCount}
                setIsShowStateLandEditModal={(isShow: boolean) => {
                    setIsShowStateLandEditModal(isShow);
                }}
                setStateLandItem={setStateLandItem}
                setIsShowStateLandDeleteConfirmModal={setIsShowStateLandDeleteConfirmModal}
            />
            <UserStateLandRefundModal
                isShowStateLandRefundModal={isShowStateLandRefundModal}
                setIsShowModal={(isShow: boolean) => {
                    setIsShowStateLandRefundModal(isShow);
                }}
                stateLandItem={stateLandItem}
                setRefundStateLandState={setRefundStateLandState}
                refundStateLandState={refundStateLandState}
                setIsShowStateLandRefundConfirmModal={setIsShowStateLandRefundConfirmModal}
            />
            <UserStateLandEditModal
                setStateLandEditTime={(time: string) => {
                    setStateLandEditTime(time);
                }}
                setIsShowStateLandEditModal={(isShow: boolean) => {
                    setIsShowStateLandEditModal(isShow);
                }}
                isShowStateLandEditModal={isShowStateLandEditModal}
                stateLandItem={stateLandItem}
                setIsShowStateLandEditConfirmModal={(isShow: boolean) => {
                    setIsShowStateLandEditConfirmModal(isShow);
                }}
            />
            <ConfirmModal
                isModalShow={isShowStateLandEditConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    console.log("cancle");
                    setStateLandItem({});
                    setStateLandEditTime("");
                    setIsShowStateLandEditConfirmModal(false);
                    setIsShowStateLandModal(true);
                }}
                handleOk={() => {
                    // onClickEditExpiredAtAdTicket();
                    console.log("@@!#!@#");
                    onClickEditExpiredAtStateLand();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowStateLandDeleteConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowStateLandDeleteConfirmModal(false);
                    setIsShowStateLandModal(true);
                }}
                handleOk={() => {
                    onClickDeleteStateLand();
                }}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowStateLandRefundConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowStateLandRefundConfirmModal(false);
                    setIsShowStateLandModal(true);
                }}
                handleOk={() => {
                    onClickRefundStateLand();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            {/* roadExpansion */}
            <UserRoadExpansionInfoModal
                isModalShow={isShowRoadExpansionModal}
                closeModal={() => {
                    setIsShowRoadExpansionModal(false);
                }}
                setIsShowRefundModal={(isShow: boolean) => {
                    setIsShowRoadExpansionRefundModal(isShow);
                }}
                dataList={roadExpansionItems?.list}
                totalCount={roadExpansionItems?.totalCount}
                setIsShowRoadExpansionEditModal={(isShow: boolean) => {
                    setIsShowRoadExpansionEditModal(isShow);
                }}
                setRoadExpansionItem={setRoadExpansionItem}
                setIsShowRoadExpansionDeleteConfirmModal={setIsShowRoadExpansionDeleteConfirmModal}
            />
            <UserRoadExpansionRefundModal
                isShowRoadExpansionRefundModal={isShowRoadExpansionRefundModal}
                setIsShowModal={(isShow: boolean) => {
                    setIsShowStateLandRefundModal(isShow);
                }}
                roadExpansionItem={roadExpansionItem}
                setRefundRoadExpansionState={setRefundRoadExpansionState}
                refundRoadExpansionState={refundRoadExpansionState}
                setIsShowRoadExpansionRefundConfirmModal={setIsShowRoadExpansionRefundConfirmModal}
            />

            <UserRoadExpansionEditModal
                setRoadExpansionEditTime={(time: string) => {
                    setRoadExpansionEditTime(time);
                }}
                setIsShowRoadExpansionEditModal={(isShow: boolean) => {
                    setIsShowRoadExpansionEditModal(isShow);
                }}
                isShowRoadExpansionEditModal={isShowRoadExpansionEditModal}
                roadExpansionItem={roadExpansionItem}
                setIsShowRoadExpansionEditConfirmModal={(isShow: boolean) => {
                    setIsShowRoadExpansionEditConfirmModal(isShow);
                }}
            />
            <ConfirmModal
                isModalShow={isShowRoadExpansionEditConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    console.log("cancle");
                    setRoadExpansionItem({});
                    setRoadExpansionEditTime("");
                    setIsShowRoadExpansionEditConfirmModal(false);
                    setIsShowRoadExpansionModal(true);
                }}
                handleOk={() => {
                    // onClickEditExpiredAtAdTicket();
                    console.log("@@!#!@#");
                    onClickEditExpiredAtRoadExpansion();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowRoadExpansionDeleteConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowRoadExpansionDeleteConfirmModal(false);
                    setIsShowRoadExpansionModal(true);
                }}
                handleOk={() => {
                    onClickDeleteRoadExpansion();
                }}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowRoadExpansionRefundConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowRoadExpansionRefundConfirmModal(false);
                    setIsShowRoadExpansionModal(true);
                }}
                handleOk={() => {
                    onClickRefundRoadExpansion();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            {/* GoStop */}
            <UserGoStopInfoModal
                title="주거/토지 수익 필터 상품 정보"
                isModalShow={isShowGoStopModal}
                closeModal={() => {
                    setIsShowGoStopModal(false);
                }}
                setIsShowRefundModal={(isShow: boolean) => {
                    setIsShowGoStopRefundModal(isShow);
                }}
                dataList={goStopItems?.list}
                totalCount={goStopItems?.totalCount}
                setIsShowEditModal={(isShow: boolean) => {
                    setIsShowGoStopEditModal(isShow);
                }}
                setItem={setGoStopItem}
                setIsShowDeleteConfirmModal={setIsShowGoStopDeleteConfirmModal}
            />
            <UserGoStopRefundModal
                isShowRefundModal={isShowGoStopRefundModal}
                setIsShowModal={(isShow: boolean) => {
                    setIsShowGoStopRefundModal(isShow);
                }}
                item={goStopItem}
                setRefundState={setRefundGoStopState}
                refundState={refundGoStopState}
                setIsShowRefundConfirmModal={setIsShowGoStopRefundConfirmModal}
            />
            <UserGoStopEditModal
                setEditTime={(time: string) => {
                    setGoStopEditTime(time);
                }}
                setIsShowEditModal={(isShow: boolean) => {
                    setIsShowGoStopEditModal(isShow);
                }}
                isShowEditModal={isShowGoStopEditModal}
                item={goStopItem}
                setIsShowEditConfirmModal={(isShow: boolean) => {
                    setIsShowGoStopEditConfirmModal(isShow);
                }}
            />
            <ConfirmModal
                isModalShow={isShowGoStopEditConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    console.log("cancle");
                    setGoStopItem({});
                    setGoStopEditTime("");
                    setIsShowGoStopEditConfirmModal(false);
                    setIsShowGoStopModal(true);
                }}
                handleOk={() => {
                    // onClickEditExpiredAtAdTicket();
                    console.log("@@!#!@#");
                    onClickEditExpiredAtGoStop();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowGoStopDeleteConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowGoStopDeleteConfirmModal(false);
                    setIsShowGoStopModal(true);
                }}
                handleOk={() => {
                    onClickDeleteGoStop();
                }}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowGoStopRefundConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowGoStopRefundConfirmModal(false);
                    setIsShowGoStopModal(true);
                }}
                handleOk={() => {
                    onClickRefundGoStop();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <UserGoStopInfoModal
                title="상가 수익률 고스톱"
                isModalShow={isShowStoreGoStopModal}
                closeModal={() => {
                    setIsShowStoreGoStopModal(false);
                }}
                setIsShowRefundModal={(isShow: boolean) => {
                    setIsShowStoreGoStopRefundModal(isShow);
                }}
                dataList={storeGoStopItems?.list}
                totalCount={storeGoStopItems?.totalCount}
                setIsShowEditModal={(isShow: boolean) => {
                    setIsShowStoreGoStopEditModal(isShow);
                }}
                setItem={setStoreGoStopItem}
                setIsShowDeleteConfirmModal={setIsShowStoreGoStopDeleteConfirmModal}
            />
            <UserGoStopRefundModal
                isShowRefundModal={isShowStoreGoStopRefundModal}
                setIsShowModal={(isShow: boolean) => {
                    setIsShowStoreGoStopRefundModal(isShow);
                }}
                item={storeGoStopItem}
                setRefundState={setRefundStoreGoStopState}
                refundState={refundStoreGoStopState}
                setIsShowRefundConfirmModal={setIsShowStoreGoStopRefundConfirmModal}
            />
            <UserGoStopEditModal
                setEditTime={(time: string) => {
                    setStoreGoStopEditTime(time);
                }}
                setIsShowEditModal={(isShow: boolean) => {
                    setIsShowStoreGoStopEditModal(isShow);
                }}
                isShowEditModal={isShowStoreGoStopEditModal}
                item={storeGoStopItem}
                setIsShowEditConfirmModal={(isShow: boolean) => {
                    setIsShowStoreGoStopEditConfirmModal(isShow);
                }}
            />
            <ConfirmModal
                isModalShow={isShowStoreGoStopEditConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    console.log("cancle");
                    setStoreGoStopItem({});
                    setStoreGoStopEditTime("");
                    setIsShowStoreGoStopEditConfirmModal(false);
                    setIsShowStoreGoStopModal(true);
                }}
                handleOk={() => {
                    // onClickEditExpiredAtAdTicket();
                    console.log("@@!#!@#");
                    onClickEditExpiredAtStoreGoStop();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowStoreGoStopDeleteConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowStoreGoStopDeleteConfirmModal(false);
                    setIsShowStoreGoStopModal(true);
                }}
                handleOk={() => {
                    onClickDeleteStoreGoStop();
                }}
            >
                정말 삭제하시겠습니까?
            </ConfirmModal>
            <ConfirmModal
                isModalShow={isShowStoreGoStopRefundConfirmModal}
                buttonText="확인"
                handleCancel={() => {
                    setIsShowStoreGoStopRefundConfirmModal(false);
                    setIsShowStoreGoStopModal(true);
                }}
                handleOk={() => {
                    onClickRefundStoreGoStop();
                }}
            >
                정말 저장하시겠습니까?
            </ConfirmModal>
        </div>
    );
}

export function ConsentBox({ isUpdate = false, initData, handlePatchUserData }: any) {
    const initDate = ConvertDate(new Date().getTime()) + " 00:00:00";

    const alarmTitle: any = [
        { title: "관심매물 알림", value: "agree_push_like_item" },
        { title: "매물추천 알림", value: "agree_push_recommend_item" },
        { title: "혜택, 이벤트알림", value: "agree_push_event" },
        { title: "광고성 정보 수신 동의", value: "agree_marketing" },
        {
            title: "등기변동 알림(앱푸시)",
            value: "agree_push_deungi_change_alarm",
        },
        {
            title: "등기변동 알림(이메일)",
            value: "agree_email_deungi_change_alarm",
        },
    ];

    const newData = initData?.agreements?.map((dataItem: any) => {
        const matchedTitle = alarmTitle.find((titleItem: any) => titleItem.value === dataItem.type);
        return matchedTitle ? { ...dataItem, title: matchedTitle.title } : dataItem;
    });

    const [updateData, setUpdateData] = useState({
        changeAgreement: {},
        edit: false,
    });
    const handleToggle = (name: string, checked: boolean) => {
        setUpdateData({
            changeAgreement: {
                ...updateData.changeAgreement,
                [name]: checked ? 1 : 0,
            },
            edit: true,
        });
    };
    useEffect(() => {
        if (updateData.edit) {
            handlePatchUserData({
                agreements: updateData.changeAgreement,
                edit: updateData.edit,
            });
        }
    }, [updateData]);

    return (
        <div css={consentBox}>
            <Title title="알림 수신" size="small" />
            <ul className={"notifyBox"}>
                {newData
                    ?.slice()
                    .reverse()
                    .map((item: any) => {
                        return (
                            <li key={item?.type}>
                                <ul>
                                    <li>
                                        <span>{item?.title}</span>
                                        {isUpdate ? (
                                            <div className="toggleBox">
                                                <ToggleButton
                                                    name={item?.type}
                                                    handleToggle={(v, c) => {
                                                        handleToggle(v, c);
                                                    }}
                                                    value={item?.agreement}
                                                />
                                            </div>
                                        ) : (
                                            <span
                                                style={{
                                                    color: item?.agreement
                                                        ? Common.colors.purple500
                                                        : Common.colors.gray500,
                                                }}
                                            >
                                                {item?.agreement ? "ON" : "OFF"}
                                            </span>
                                        )}
                                    </li>
                                    <li>
                                        <span className="dateLabelStyle">{item?.agreement ? "동의일" : "거부일"}</span>
                                        <span>{item?.date ? ConvertDateTime(item?.date) : initDate}</span>
                                    </li>
                                </ul>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}
const detailStyle = css`
    padding: 40px;
    width: 100%;
    min-width: 1250px;
    max-width: 1800px;
    & > div:first-of-type {
        margin-bottom: 60px;
    }
    & > div:nth-of-type(n + 2) {
        & > div {
            margin-top: 10px;
            margin-bottom: 25px;
        }
    }
    & > ul {
        margin-top: 20px;
        margin-bottom: 60px;
    }
`;
const consentBox = css`
    padding-bottom: 40px;
    ul {
        display: flex;
        align-items: center;
        li {
            ${Common.textStyle.title5_M14}
            margin-bottom: 15px;
            &:nth-of-type(1) {
                width: 210px;
                margin-right: 30px;
            }
            span.dateLabelStyle {
                padding-left: 40px;
                color: ${Common.colors.gray500};
            }
        }
        span {
            &:nth-of-type(1) {
                width: 150px;
                display: inline-block;
            }
        }
    }
    ul.notifyBox {
        display: block;
        & > li {
            display: flex;
            &:nth-of-type(1) {
                width: unset;
                ul {
                    width: unset;
                }
            }
        }
    }
    div.toggleBox {
        display: inline-block;
    }
    .modal {
        position: relative;
        p {
            position: absolute;
            left: 73px;
            bottom: -30px;
        }
    }
    ul.freeStatus {
        margin-top: 40px;
    }
`;
const membershipBox = css`
    ${Common.textStyle.title5_M14}
    display: flex;
    gap: 24px;
    align-items: center;

    .modal {
        margin-top: 30px;
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        & > span {
            display: inline-block;
            width: 80px;
        }
    }
    span:nth-of-type(1) {
        display: inline-block;
        width: 126px;
    }
`;

export default UserDetail;
