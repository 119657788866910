import { useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";
import {
    BaseNews,
    FormNewsProps,
    CreateNewsDto,
    UpdateNewsDto,
} from "@src/interfaces/News.interface";
import { axiosPath } from "@src/api/axiosPath";
import { noImage } from "@src/constants/Images";
import { axiosInstance } from "@src/api/axiosConfig";
import { asyncCall } from "@src/api/asyncCall";
import { dateParse } from "@src/utils/dateUtil";

const fetchData = async (id: string) => {
    const response = await axiosInstance.get(`${axiosPath.article2}/${id}`);

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: BaseNews = responseData.data.result;
    return parse(originData);
};

const parse = (originData: BaseNews): FormNewsProps => {
    const {
        thumb,
        url,
        date,
        title,
        is_show,
        press,
        keyword,
        portal,
        keyword_code,
        close_at,
    } = originData;
    return {
        newsImage: thumb === "" ? noImage : thumb,
        newsLink: url,
        startDate: dateParse(date),
        mediaCompany: press,
        newsTitle: title,
        view: is_show ? true : false,
        keyword,
        portal,
        edit: false,
        keywordCode: keyword_code,
        endDate: dateParse(close_at),
    };
};

const useNewsDetailData = (id: string) => {
    return useQuery<FormNewsProps, Error>(
        [queryKeys.NEWS_DETAIL_DATA, id],
        () => fetchData(id),
        {
            // initialData,
        },
    );
};

const useCreateNews = () => {
    const createNews = async (params: CreateNewsDto) => {
        const promise = await axiosInstance.post(
            `${axiosPath.article2}`,
            params,
        );
        return asyncCall(promise, "News");
    };
    return useMutation(createNews);
};

const useUpdateNews = (newsId: string | undefined) => {
    const updateNews = async (params: UpdateNewsDto) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.article2}/${newsId}`,
            params,
        );
        return asyncCall(promise, "News");
    };
    return useMutation(updateNews);
};

const useDeleteNews = () => {
    const deleteNews = async (newsId: string) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.article2}/${newsId}`,
        );
        return asyncCall(promise, "News");
    };
    return useMutation(deleteNews);
};
export { useNewsDetailData, useCreateNews, useUpdateNews, useDeleteNews };
