import React, { useEffect, useRef, useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { MixedChart } from "@src/components";
import ChartHeader, { changeDateType } from "./ChartHeader";
import {
    initTime,
    paymentInitTime,
    useHomePaymentStatChart,
    SalesStatType,
} from "@src/hooks/queries/useHomeChart";
import { dateFormat, noYearFormat } from "@src/utils/dateUtil";
import { add, set, sub } from "date-fns";
import moment from "moment";
import "moment/locale/ko";
import { ProductType, productTypeList } from "../Home";
import { getRelativePosition } from "chart.js/helpers";
moment.locale("ko");

function PaymentStatusChart({
    onClickOpenRefundMemberModal,
    onClickOpenJoinedMemberModal,
}: any) {
    const productTypeRef = useRef<ProductType>("subscription");
    const [productType, setProductType] = useState<ProductType>("subscription");
    const [filterPaymentChart, setFilterPaymentChart] = useState<SalesStatType>(
        {
            cycle: "monthly",
            startDate: "",
            endDate: "",
        },
    );
    const filterPaymentChartRef = useRef<SalesStatType>({
        cycle: "monthly",
        startDate: "",
        endDate: "",
    });

    useEffect(() => {
        filterPaymentChartRef.current = filterPaymentChart;
    }, [filterPaymentChart]);

    useEffect(() => {
        productTypeRef.current = productType;
    }, [productType]);

    const parseHomeUserRefundChartAndCreateOption = (
        data: any,
        cycle: string,
    ) => {
        return {
            labels: data.map((v: any) =>
                cycle === "monthly"
                    ? `${noYearFormat(new Date(v.date))} ~ ${noYearFormat(
                          sub(
                              add(new Date(v.date), {
                                  months: 1,
                              }),
                              { days: 1 },
                          ),
                      )}`
                    : cycle === "weekly"
                    ? `${noYearFormat(new Date(v.date))} ~ ${noYearFormat(
                          add(new Date(v.date), {
                              days: 6,
                          }),
                      )}`
                    : v?.date,
            ),
            datasets: [
                {
                    type: "line",
                    label: "결제금액",
                    yAxisID: "right",
                    order: 1,
                    data: data.map((v: any) => v?.paymentAmount),
                    borderColor: "#FF007A",
                    backgroundColor: "#FF007A",
                    borderWidth: 3,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "bar",
                    label: "결제건수",
                    yAxisID: "left",
                    order: 2,
                    data: data.map((v: any) => v?.paymentCount),
                    backgroundColor: "#CDF629",
                    borderColor: "#CDF629",
                    borderWidth: 5,
                    barThickness: 26,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
                {
                    type: "bar",
                    label: "환불건수",
                    yAxisID: "left",
                    order: 2,
                    data: data.map((v: any) => v?.refundCount),
                    backgroundColor: "#88A121",
                    borderColor: "#88A121",
                    borderWidth: 5,
                    barThickness: 26,
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },

                {
                    type: "line",
                    label: "환불금액",
                    yAxisID: "right",
                    order: 1,
                    data: data.map((v: any) => v?.refundAmount),
                    borderColor: "#9A49F2",
                    backgroundColor: "#9A49F2",
                    pointRadius: 2,
                    pointHoverBackgroundColor: Common.colors.white,
                    pointHoverBorderWidth: 3,
                    pointHoverRadius: 5,
                },
            ],
        };
    };

    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        const dataType = e.currentTarget.getAttribute(
            "product-type",
        ) as ProductType;
        setProductType(dataType);
    };
    const {
        isLoading: paymentStatLoading,
        data: paymentStatData,
        isError: paymentStatError,
    } = useHomePaymentStatChart(
        filterPaymentChart,
        productType,
        parseHomeUserRefundChartAndCreateOption,
    );

    const getDateStatsUserChart = ({ range, type }: changeDateType) => {
        const { startDate, endDate } = range;
        setFilterPaymentChart({
            cycle: type,
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
        });
    };

    const [options, setOptions] = useState({});
    useEffect(() => {
        if (filterPaymentChart.startDate) {
            switch (filterPaymentChart.cycle) {
                case "daily": {
                    setOptions({
                        interaction: {
                            mode: "index",
                            axis: "left",
                        },
                        responsive: false,
                        maxBarThickness: 15,
                        scales: {
                            x: {
                                type: "time",
                                time: {
                                    unit: "day",
                                    displayFormats: {
                                        day: "MM.dd",
                                    },
                                    tooltipFormat: "yyyy.MM.dd",
                                },
                            },
                        },
                        events: ["mousemove", "mouseout", "click"],
                        onClick: (evt: any) => {
                            const chart = evt.chart;
                            const canvasPosition = getRelativePosition(
                                evt,
                                chart,
                            );
                            const index =
                                chart.scales.x.getValueForPixel(
                                    canvasPosition.x,
                                ) + 32400000;
                            const currentWidth = evt.x;
                            const refundData =
                                chart.getActiveElements()?.[3]?.element?.x;
                            if (chart.getActiveElements()?.length) {
                                if (refundData - 7.5 <= currentWidth) {
                                    onClickOpenRefundMemberModal(
                                        productTypeRef.current,
                                        new Date(index),
                                        new Date(index),
                                    );
                                } else {
                                    onClickOpenJoinedMemberModal(
                                        new Date(index),
                                        new Date(index),
                                    );
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    title: (context: any) => {
                                        const label = context[0].label;
                                        const date = moment(label);
                                        return `${date.format(
                                            "YYYY.MM.DD (dd)",
                                        )}`;
                                    },
                                },
                            },
                        },
                    });
                    break;
                }
                case "weekly": {
                    setOptions({
                        interaction: {
                            mode: "index",
                            axis: "left",
                        },
                        scales: {
                            x: {
                                stackWeight: 1,
                                time: {
                                    time: {
                                        tooltipFormat: "yyyy.MM.dd",
                                    },
                                },
                            },
                        },
                        events: ["mousemove", "mouseout", "click"],
                        onClick: (evt: any) => {
                            const chart = evt.chart;
                            const canvasPosition = getRelativePosition(
                                evt,
                                chart,
                            );
                            const index = chart.scales.x.getValueForPixel(
                                canvasPosition.x,
                            );
                            const currentWidth = evt.x;
                            if (chart.getActiveElements()?.length) {
                                const refundData =
                                    chart.getActiveElements()?.[3]?.element?.x;
                                const totalData = evt.chart.scales.x.ticks;
                                const checkStartDate =
                                    totalData[0]?.label.split("~")[0];
                                const newStartDate = set(
                                    new Date(
                                        filterPaymentChartRef.current.startDate,
                                    ),
                                    {
                                        month: checkStartDate.split(".")[0] - 1,
                                        date: checkStartDate.split(".")[1],
                                    },
                                );
                                if (refundData - 7.5 <= currentWidth) {
                                    onClickOpenRefundMemberModal(
                                        productTypeRef.current,
                                        add(new Date(newStartDate), {
                                            days: index * 7,
                                        }),
                                        add(new Date(newStartDate), {
                                            days: index * 7 + 6,
                                        }),
                                    );
                                } else {
                                    onClickOpenJoinedMemberModal(
                                        add(new Date(newStartDate), {
                                            days: index * 7,
                                        }),
                                        add(new Date(newStartDate), {
                                            days: index * 7 + 6,
                                        }),
                                    );
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    });
                    break;
                }
                case "monthly": {
                    setOptions({
                        interaction: {
                            mode: "index",
                            axis: "left",
                        },
                        scales: {
                            responsive: true,
                            x: {
                                barThickness: 40,
                                time: {
                                    unit: "month",
                                    displayFormats: {
                                        month: "yyyy.MM",
                                    },
                                    tooltipFormat: "yyyy.MM.dd",
                                },
                                ticks: {
                                    stepSize: 100,
                                    grace: "10%",
                                },
                            },
                        },
                        events: ["mousemove", "mouseout", "click"],
                        onClick: (evt: any) => {
                            const chart = evt.chart;
                            const canvasPosition = getRelativePosition(
                                evt,
                                chart,
                            );
                            const index = chart.scales.x.getValueForPixel(
                                canvasPosition.x,
                            );
                            const currentWidth = evt.x;
                            const currentDataIndex =
                                chart.getActiveElements()?.[0]?.index;
                            if (chart.getActiveElements()?.length) {
                                const firstTick =
                                    chart.scales.x.ticks[0].label?.split(
                                        " ~",
                                    )?.[0];
                                const refundData =
                                    chart.getActiveElements()?.[3]?.element?.x;
                                const newStartDate = set(
                                    add(
                                        set(
                                            new Date(
                                                filterPaymentChartRef.current.startDate,
                                            ),
                                            {
                                                month:
                                                    firstTick.split(".")[0] - 1,
                                                date: firstTick.split(".")[1],
                                            },
                                        ),
                                        {
                                            months: index,
                                        },
                                    ),
                                    {
                                        date: 1,
                                    },
                                );
                                if (refundData - 7.5 <= currentWidth) {
                                    onClickOpenRefundMemberModal(
                                        productTypeRef.current,
                                        newStartDate,
                                        sub(
                                            add(newStartDate, {
                                                months: 1,
                                            }),
                                            {
                                                days: 1,
                                            },
                                        ),
                                    );
                                } else {
                                    onClickOpenJoinedMemberModal(
                                        newStartDate,
                                        sub(
                                            add(newStartDate, {
                                                months: 1,
                                            }),
                                            {
                                                days: 1,
                                            },
                                        ),
                                    );
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    footer: (statusData: any) => {
                                        const paymentAmount =
                                            statusData?.[0]?.dataset?.data?.[
                                                statusData?.[0]?.dataIndex
                                            ];
                                        const refundAmount =
                                            statusData?.[1]?.dataset?.data?.[
                                                statusData?.[1]?.dataIndex
                                            ];
                                        const sum = (
                                            paymentAmount - refundAmount
                                        ).toLocaleString();
                                        return "순매출 : " + sum;
                                    },
                                },
                            },
                        },
                    });
                    break;
                }
                default: {
                    setOptions({
                        scales: {
                            x: {
                                grouped: true,
                                befinAtZero: true,
                                type: "time",
                                time: {
                                    displayFormats: { hour: "HH:mm" },
                                    tooltipFormat: "YYY.MM.dd HH:mm",
                                },
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 12,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                    });
                }
            }
        }
    }, [filterPaymentChart]);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    return (
        <div css={subChartDiv}>
            <p css={titleP}>결제 현황</p>
            <div css={chartHeaderBoxWrap}>
                {productTypeList.map((type) => (
                    <div
                        key={type.value}
                        css={
                            productType === type.value
                                ? [chartHeaderBox, selected]
                                : chartHeaderBox
                        }
                        product-type={type.value}
                        onClick={handleClick}
                    >
                        {type.name}
                    </div>
                ))}
            </div>
            <div className="mainChartBox">
                <ChartHeader
                    getChangeDate={getDateStatsUserChart}
                    defaultBasisType="monthly"
                    paymentType="paymentStatus"
                />
                <div css={subContainer}>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#FF007A"} />
                        <p>결제 금액</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#CDF629"} />
                        <p>결제 건수</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#88A121"} />
                        <p>환불 건수</p>
                    </div>
                    <div css={subTitle}>
                        <LabelName nameColorBg={"#9A49F2"} />
                        <p>환불 금액</p>
                    </div>
                </div>
                {/* <div css={chartBoxStyle} ref={scrollRef}> */}
                <MixedChart
                    height={300}
                    data={paymentStatData}
                    options={options}
                    onScrollEnd={(val: number) =>
                        scrollRef.current?.scrollTo(val, 0)
                    }
                    boxStyles={chartBoxStyle}
                />
                {/* </div> */}
            </div>
        </div>
    );
}

const selected = css`
    border: 1px solid ${Common.colors.purple500};
    color: ${Common.colors.purple500};
`;
const chartHeaderBoxWrap = css`
    display: flex;
    flex-direction: row;
    gap: 2px;
    cursor: pointer;
`;
const chartHeaderBox = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 8px 0px;
    height: 24px;
    background: ${Common.colors.white};
    border: 1px solid ${Common.colors.gray300};
    border-radius: 4px;
    font-size: 12px;
    line-height: 180%;
`;
const subChartDiv = css`
    width: 1220px;
    height: 460px;
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1.5px solid ${Common.colors.gray200};
`;
const titleP = css`
    ${Common.textStyle.title7_M12}
    color: ${Common.colors.black};
    margin-bottom: 12px;
    font-size: 15px;
`;
const chartBoxStyle = css`
    min-width: 1200px;
    overflow: auto;
    ::-webkit-scrollbar {
        border-radius: 4px;
        width: 2px;
        height: 8px;
        margin-right: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    ::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: transparent;
    }
`;
const subTitle = css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    justify-items: center;
    ${Common.textStyle.title7_M12}
`;

const subContainer = css`
    display: flex;
    flex-direction: row;
`;

const LabelName = ({ nameColorBg }: { nameColorBg: string }) => {
    const chartColor = css`
        width: 15px !important;
        height: 15px;
        margin: 8px 3px;
        border-radius: 3px;
        background-color: ${nameColorBg};
    `;
    return <div css={chartColor} />;
};
export default PaymentStatusChart;
