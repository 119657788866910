import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { EditModal, Input } from "@src/components";
import { paymentHistory } from "@src/data/UserDetailSampleData";
import { Common } from "@src/styles/Common";

function PaymentDetailModal({
    isModalShow,
    handleOpenModal,
    paymentIndex,
}: any) {
    const [paymentIndexData, setPaymentIndexData] = useState<any>("");
    useEffect(() => {
        setPaymentIndexData(paymentHistory[paymentIndex]);
    }, [paymentIndex]);
    const paymentData = [
        {
            key: "번호",
            value: paymentIndexData.no,
        },
        {
            key: "결제일시",
            value: paymentIndexData.date,
        },
        {
            key: "상품명",
            value: paymentIndexData.product,
        },
        {
            key: "금액",
            value: paymentIndexData.price,
        },
        {
            key: "결제 방식",
            value: "카드결제",
        },
        {
            key: "상태",
            value: paymentIndexData.state ? "결제 성공" : "결제 실패",
        },
        {
            key: "상태사유",
            value: "",
        },
        {
            key: "영수증",
            value: paymentIndexData.receipt ? "발송 완료" : "발송 실패",
        },
        {
            key: "영수증 발송 채널",
            value: "aaa@missgoauction.com",
        },
        {
            key: "영수증 발송일시",
            value: paymentIndexData.date,
        },
    ];
    return (
        <div css={paymentDetailDiv} className={"paymentDetailDiv"}>
            <EditModal
                buttonText=""
                handleBtnClick={() => {}}
                handleOpenModal={handleOpenModal}
                size="big"
                title="결제 상세 내역"
                isModalShow={isModalShow}
            >
                <ul className="InputList">
                    {paymentData.map((data, index) => {
                        return (
                            <li key={index}>
                                <Input
                                    errorMessage=""
                                    handleChange={() => {}}
                                    label={data.key}
                                    placeholder=""
                                    value={data.value}
                                    size="medium"
                                    type="text"
                                    isDisabled={true}
                                />
                            </li>
                        );
                    })}
                </ul>
            </EditModal>
        </div>
    );
}

const paymentDetailDiv = css`
    .InputList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        padding-right: 40px;
    }
    .InputList li {
        width: 45%;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 120px;
            margin-right: 20px;
            word-break: keep-all;
            line-height: 1.5;
        }
        &:nth-of-type(5) {
            margin-right: 50%;
            margin-bottom: 50px;
        }
    }
    input:disabled {
        background-color: ${Common.colors.white};
        color: ${Common.colors.black};
    }
    &.paymentDetailDiv {
        button {
            display: none;
        }
        p + button {
            display: block;
        }
        div.shadow {
            height: 350vh !important;
        }
    }
`;
export default PaymentDetailModal;
