import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { PageTable, Title } from "@src/components";
import { ISettlementDTO, useFindAllSettlement, useFindPaymentList } from "@src/hooks/queries/usePartnerSettlement";
import SettlementFilter from "./SettlementFilter";
import { getColumns } from "./SettlementColumns";
import SettlementPaymenyListModal from "./SettlementPaymenyListModal";

interface Props {}

const pagesInitialState = {
    pageIndex: 0,
    pageSize: 10,
};

const Settlement: React.FC<Props> = () => {
    const navigate = useNavigate();
    const [paymentListParams, setPaymentListParams] = useState<any>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const columns = getColumns(navigate, setPaymentListParams);
    const [params, setParams] = useState<ISettlementDTO>({ page: 1 });
    const { data, refetch } = useFindAllSettlement(params);
    const { data: paymentList, refetch: refetchPaymentList } = useFindPaymentList(paymentListParams);

    useEffect(() => {
        params.page && refetch();
    }, [params]);

    useEffect(() => {
        if (paymentListParams?.partnerNo) {
            refetchPaymentList().then((res) => {
                if (res.isFetched) {
                    setOpenModal(true);
                }
            });
        }
    }, [paymentListParams?.partnerNo]);

    return (
        <>
            <div css={rootBoxStyle}>
                <Title title="추천인 정산 관리" />
                <SettlementFilter setParams={setParams} />
                <div css={tableBoxStyle}>
                    <PageTable
                        FixedHeight={700}
                        columns={columns}
                        data={data?.result ?? []}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParams({
                                ...params,
                                page: page,
                            });
                        }}
                        initialState={pagesInitialState}
                        totalCount={data?.total ?? 1}
                        forcePage={params.page === 1}
                    />
                </div>
                <SettlementPaymenyListModal
                    open={openModal}
                    onCloseView={() => {
                        setOpenModal(false);
                        setPaymentListParams(null);
                    }}
                    paymentList={paymentList}
                    paymentListParams={paymentListParams}
                />
            </div>
        </>
    );
};

export default Settlement;

const rootBoxStyle = css`
    padding: 60px 40px;
    width: 100%;
    box-sizing: border-box;
    max-width: 1400px;
`;

const tableBoxStyle = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    width: 100%;
    overflow: auto;
    th {
        white-space: pre-wrap;
    }
    td {
        padding: 8px;
        button:nth-of-type(2) {
            margin-left: 15px;
        }
    }
`;
