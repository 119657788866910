import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { registerHistory } from "@src/data/UserDetailSampleData";
import { Title, Table, Button } from "@src/components";
import { Common } from "@src/styles/Common";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";

function RegisterListContainer() {
    const dispatch = useDispatch();
    const [isBtnClick, setIsBtnClick] = useState(false);
    const getRegisterList = () => {
        isBtnClick || dispatch(startLoading());
        setIsBtnClick(true);
    };
    useEffect(() => {
        if (isBtnClick) {
            setTimeout(() => dispatch(endLoading()), 200);
        }
    }, [isBtnClick]);
    const columns = [
        {
            Header: "열람일시",
            accessor: "date",
            Cell: ({ cell: { value } }: any) => <div>{value}</div>,
        },
        {
            Header: "소재지",
            accessor: "address",
            Cell: ({ cell: { value } }: any) => <div>{value}</div>,
        },
        {
            Header: "상태",
            accessor: "state",
            Cell: ({ cell: { value } }: any) => (
                <div>{value ? "열람 성공" : "열람 실패"}</div>
            ),
        },
        {
            Header: "사유",
            accessor: "errorMessage",
            Cell: ({ cell: { value } }: any) => (
                <div>{value ? value : null}</div>
            ),
        },
    ];
    return (
        <div css={isBtnClick && afterBtnClick}>
            <div css={flexBox}>
                <Title title="등기 열람 내역" size="small" />
                <Button
                    label="조회"
                    color={"gd"}
                    size={"medium"}
                    isDisabled={false}
                    handleClick={() => getRegisterList()}
                    deleteBtn={false}
                    defaultValue={"조회"}
                />
            </div>
            <Table
                columns={columns}
                data={[]}
                initialState={{ pageIndex: 0, pageSize: 10 }}
                FixedHeight={500}
            />
        </div>
    );
}
const flexBox = css`
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const afterBtnClick = css`
    tbody {
        opacity: 1;
        background-color: ${Common.colors.white};
    }
`;
export default RegisterListContainer;
