import { useQuery } from "react-query";
import { queryKeys } from "../queryKeys";
import { axiosPath } from "@src/api/axiosPath";
import { axiosInstance } from "@src/api/axiosConfig";
import { sampleData } from "@src/data/MainInfoSampleData";
import {
    BaseMainInfoContainer,
    MainInfoContainerProps,
} from "@src/interfaces/product/MainInfoContainer.interface";

const useGetMainInformation = (productNo: any) => {
    return useQuery<MainInfoContainerProps, Error>(
        [queryKeys.MAIN_INFORMATION, productNo],
        () => fetchData(productNo),
        {
            // initialData: sampleData,
        },
    );
};

const fetchData = async (productNo: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.mainInfo}/${productNo}`,
    );
    if (!response.status) {
        throw new Error("Problem fetching data");
    }
    const responseData = response.data;
    const originData: BaseMainInfoContainer = responseData.data.data;

    return parse(originData);
};

const parse = (origin: any) => {
    const newObj: MainInfoContainerProps = {
        productNo: origin.item_id,
        productName: origin.sub_id,
        bidType: origin.bid_type === 1 ? "경매" : "공매",
        assign: origin.court,
        expectPrice: origin.sale_price ?? "0",
        expectDueDate: origin.sale_due_date,
        expectDate: origin.sale_day,
        allFiles: origin.image.map((img: string) => {
            return { data_url: img };
        }),
        favoriteCount: 0,
        viewCount: 0,
        location: origin.address,
        roadLocation: origin.road_names,
        usage: `${origin.use} ${origin.use_detail}`,
        skpCnt: origin.skp_cnt,
    };

    return newObj;
};

export { useGetMainInformation };
