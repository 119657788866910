import { createSlice } from "@reduxjs/toolkit";

import img from "@src/assets/product.png";
import { StandardInfo } from "@src/pages/product/types";

const SampleData: StandardInfo = {
    index: 0,
    productId: "2020타경7276",
    favorite: 124,
    viewCount: 2345,
    type: "경매",
    assign: "서울남부지방법원 | 경매3계",
    dueDate: "2022.01.03",
    url: "https://www.courtauction.go.kr",
    allFiles: [{ data_url: img }],
    location: "서울시 구로구 신도림동 646, 신도림4차 이편한세상아파트 1107호",
    load: "서울시 구로구 경인로65길 16-15",
    price: "804000000",
    minPrice: "643200000",
    deposit: "643200000",
    land: "56.942",
    buildingUsage: "주거용 아파트",
    buildingArea: "117.74",
    buildingStatus: "진행중",
    bidding: "5",
    aggregateBuilding: 1, //집합건물일 때: 1, 아닐 때: 0,
    expectPrice: "804000000",
    landArea: "56.942",
    violation: false,
};

const initialState = SampleData;

export const standardInformationSlice = createSlice({
    name: "standardInfo",
    initialState,
    reducers: {
        createStandardInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
        updateStandardInfo: (state, action) => {
            const result = action.payload;
            return { ...result };
        },
    },
});

export const { createStandardInfo, updateStandardInfo } =
    standardInformationSlice.actions;

export const getStandardInfo = (state: any) => state.standardInfo;

export default standardInformationSlice.reducer;
