import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { TextBox } from "@src/components";
import { useSelector } from "react-redux";

interface ICommentContainer {
    isEdit?: boolean;
    handleUpdate?: Function;
    handleSubmitDisabled?: Function;
}
function CommentContainer({
    isEdit = false,
    handleUpdate,
    handleSubmitDisabled,
}: ICommentContainer) {
    const defaultComment = useSelector((state: any) => state.comment);
    const [comment, setComment] = useState(defaultComment);

    return (
        <div css={isEdit ? [commentDiv, updateStyle] : commentDiv}>
            <TextBox
                handleChange={(value, isError) => {
                    setComment(value);
                    handleSubmitDisabled && handleSubmitDisabled(isError);
                    handleUpdate && handleUpdate(value);
                }}
                label=""
                placeholder="코멘트를 작성해주세요. (1000자 이내)"
                size="big"
                isDisabled={!isEdit}
                defaultText={comment}
            ></TextBox>
        </div>
    );
}

CommentContainer.propTypes = {};
const commentDiv = css`
    width: 100%;
    height: fit-content;
    & > div {
        width: 100%;
        display: unset;
    }
    textarea {
        display: block;
        width: 100%;
        overflow-y: scroll;
        ${Common.textStyle.body2_R16};
    }
    textarea:disabled {
        max-width: unset;
        overflow-y: hidden;
        background-color: ${Common.colors.white};
        color: ${Common.colors.black};
        width: 100%;
        height: 100%;
    }
`;
const updateStyle = css`
    textarea {
        height: 224px;
    }
`;
export default CommentContainer;
