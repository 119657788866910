import { asyncCall } from "@src/api/asyncCall";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import {
    BaseTemplate,
    CreateTemplateDto,
    UpdateTemplateDto,
} from "@src/interfaces/Template.interface";
import { useMutation, useQuery } from "react-query";
import { queryKeys } from "./queryKeys";

const useTemplateDetail = (templateNo: string, isUpdate: boolean) => {
    return useQuery<BaseTemplate, Error>(
        [queryKeys.TEMPLATE_DETAIL_DATA, templateNo],
        () => fetchData(templateNo),
        {
            // initialData,
            enabled: isUpdate,
        },
    );
};

const fetchData = async (templateNo: string) => {
    const response = await axiosInstance.get(
        `${axiosPath.postboxTemplate}/${templateNo}`,
    );

    if (!response.status) {
        throw new Error("Problem fetching data");
    }

    const responseData = response.data;
    const originData: BaseTemplate = responseData.data.data;

    return originData;
};

const useCreateTemplate = () => {
    const createData = async (params: CreateTemplateDto) => {
        const promise = await axiosInstance.post(
            axiosPath.postboxTemplate,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(createData);
};

const useUpdateTemplate = (templateNo: string) => {
    const updateData = async (params: UpdateTemplateDto) => {
        const promise = await axiosInstance.patch(
            `${axiosPath.postboxTemplate}/${templateNo}`,
            params,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateData);
};

const useDeleteTemplate = () => {
    const updateData = async (templateNo: string) => {
        const promise = await axiosInstance.delete(
            `${axiosPath.postboxTemplate}/${templateNo}`,
        );
        return asyncCall(promise, "Common");
    };

    return useMutation(updateData);
};

const useDuplicateTemplate = () => {
    const createData = async (templateCode: string) => {
        const promise = await axiosInstance.post(
            `${axiosPath.postboxTemplate}/duplicate`,
            { template_code: templateCode },
        );
        return asyncCall(promise, "Template");
    };

    return useMutation(createData);
};

export {
    useTemplateDetail,
    useCreateTemplate,
    useUpdateTemplate,
    useDeleteTemplate,
    useDuplicateTemplate,
};
