import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { ConfirmModal, TextBox } from "@src/components";
import { Common } from "@src/styles/Common";

interface IUpdateCommentModal {
    isModalShow: boolean;
    setIsModalShow: Function;
    handleSubmit: Function;
    title: string;
}
const UpdateCommentModal = ({
    isModalShow,
    setIsModalShow,
    handleSubmit,
    title,
}: IUpdateCommentModal) => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [updateComment, setUpdateComment] = useState("");

    return (
        <ConfirmModal
            isModalShow={isModalShow}
            handleOk={() => {
                setIsModalShow((prev: boolean) => !prev);
                handleSubmit(updateComment);
            }}
            handleCancel={() => {
                setIsModalShow((prev: boolean) => !prev);
            }}
            buttonText="수정"
            disabled={submitButtonDisabled}
        >
            <div css={commentBox}>
                <p>{title}</p>
                <TextBox
                    label=""
                    placeholder="수정 사유를 작성해주세요(ex: 사용자 문의(123456번)로 수정)"
                    size="big"
                    maxLength={1000}
                    handleChange={(value, isError) => {
                        setUpdateComment(value);
                        if (value !== "" && !isError) {
                            setSubmitButtonDisabled(false);
                        } else {
                            setSubmitButtonDisabled(true);
                        }
                    }}
                    isDisabled={false}
                    defaultText={updateComment}
                />
            </div>
        </ConfirmModal>
    );
};
const commentBox = css`
    margin-bottom: 70px;
    p {
        ${Common.textStyle.title2_M16}
        margin-bottom: 27px;
    }
`;

export default UpdateCommentModal;
