import React, { Dispatch, SetStateAction } from "react";
import { IconButton } from "@src/components";
import { INVALID_VALUE } from "@src/constants/InvalidValue";
import { ConvertDateTimeNoSecond } from "@src/utils/dateUtil";

export const CampusListColumns = (
    navigate: any,
    setDeletedId: Dispatch<SetStateAction<number>>,
    setOpenDialog: Dispatch<SetStateAction<boolean>>,
) => [
    {
        Header: "등록 일시",
        accessor: "createdAt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textAlign: "center",
                    maxWidth: "12.5%",
                }}
            >
                {value ? ConvertDateTimeNoSecond(value) : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "강의명",
        accessor: "title",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textAlign: "center",
                    maxWidth: "33%",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "강의 노출순서",
        accessor: "order",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textAlign: "center",
                    maxWidth: "6%",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "연결상품",
        accessor: "productInfos",
        Cell: ({ row }: any) => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        textAlign: "center",
                        lineHeight: "95%",
                        maxWidth: "300px",
                    }}
                >
                    {row?.original?.productInfos?.map((product: any) => (
                        <p key={product.code}>
                            {product.saleTypeToString.includes("PG")
                                ? "PG"
                                : product.saleTypeToString.includes("AOS")
                                ? "AOS"
                                : "IOS"}
                            {" : "}
                            {product.code}
                        </p>
                    ))}
                </div>
            );
        },
    },
    {
        Header: "수강생 수",
        accessor: "totalUserCnt",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    maxWidth: "7%",
                    overflow: "visible",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "평점",
        accessor: "avgReviewScore",
        Cell: ({ cell: { value } }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    textAlign: "center",
                    textOverflow: "ellipsis",
                    maxWidth: "7%",
                    overflow: "visible",
                }}
            >
                {value ? value : INVALID_VALUE}
            </div>
        ),
    },
    {
        Header: "삭제",
        accessor: "delete",
        Cell: ({ row }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    maxWidth: "50px",
                    overflow: "visible",
                }}
            >
                <IconButton
                    type="trash"
                    handleClick={() => {
                        setDeletedId(row.original.no);
                        setOpenDialog(true);
                    }}
                />
            </div>
        ),
    },
    {
        Header: "관리",
        accessor: "viewControl",
        Cell: ({ row }: any) => (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    maxWidth: "50px",
                    overflow: "visible",
                }}
            >
                <IconButton
                    type="edit"
                    handleClick={() => {
                        navigate(
                            `/campus/management/lecture/${row.original.no}`,
                            {
                                state: {
                                    ...row.original,
                                },
                            },
                        );
                    }}
                />
            </div>
        ),
    },
];
