import React from "react";
import { Title } from "@src/components";

function PaymentExpert() {
    return (
        <div>
            <Title />
        </div>
    );
}

export default PaymentExpert;
