import React, { ChangeEvent, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { PushType } from "@src/pages/notify/types";
import { Calendar, DropDown, Input, TextBox, Title } from "@src/components";
import { Common } from "@src/styles/Common";
import { ConvertDate, dateParse } from "@src/utils/dateUtil";
import { Constants } from "@src/constants/Constants";
import ReceiverBox from "@src/pages/notify/containers/ReceiverBox";
import { validChecker } from "@src/utils/validationCheck";
import { useGetPushCategories } from "@src/hooks/queries/usePushForm";
import { IDropDownData } from "@src/components/select/DropDown";
import PushReceiverType from "@src/pages/notify/containers/PushReceiverType";

interface IPushForm {
    title: string;
    isUpdate?: boolean;
    initData: PushType;
    handleUpdate: Function;
    isDetail?: boolean;
    isDisabledCheckbox?: boolean;
}
interface ErrorMessageType {
    link?: string;
    title?: string;
    contents?: boolean;
    notInputType?: string;
}
export default function PushForm({
    isUpdate = false,
    title,
    initData,
    handleUpdate,
    isDetail = false,
    isDisabledCheckbox = false,
}: IPushForm) {
    const { RECEIVER, TESTER } = Constants;
    const [initChkData, setInitChkData] = useState<any>(initData);
    const [updateData, setUpdateData] = useState<PushType>(initData);
    const [errorMessage, setErrorMessage] = useState<ErrorMessageType>({
        link: "",
        title: "",
        contents: false,
    });
    const [reserve, setReserve] = useState<any>(false);
    const { data: pushCategories } = useGetPushCategories();
    const [pushCategoryDropDownData, setPushCategoryDropDownData] = useState<IDropDownData[]>([]);
    useEffect(() => {
        if (isDetail || isUpdate) {
            setUpdateData(initData);
        }
        setInitChkData({
            userTypes: initData.userTypes,
            subscriptionTypes: initData.subscriptionTypes,
        });
    }, [initData]);
    useEffect(() => {
        if (pushCategories) {
            setPushCategoryDropDownData(
                pushCategories.map((category) => {
                    return {
                        key: String(category.no),
                        value: String(category.name),
                    } as IDropDownData;
                }),
            );
        }
    }, [pushCategories]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setUpdateData({
            ...updateData,
            ...{
                [name]: value,
                edit: true,
            },
        });
        checkErrorCondition(name, value);
    };
    const checkErrorCondition = (name: string, value: string) => {
        let MAX_LENGTH = name === "link" ? 800 : 100;
        const { errMsg: spacebarMsg } = validChecker("spacebar", value);
        const { errMsg: maxLengthMsg } = validChecker("maxLength", value, MAX_LENGTH);
        setErrorMessage({
            ...errorMessage,
            [name]: spacebarMsg || maxLengthMsg,
        });
    };
    useEffect(() => {
        if (errorMessage.link || errorMessage.title || errorMessage.contents) {
            setUpdateData({
                ...updateData,
                edit: false,
            });
        }
    }, [errorMessage]);

    useEffect(() => {
        handleUpdate(updateData);
    }, [updateData]);

    //수신인과 테스터가 변경되었을 때
    const handleReceiver = (pageName: string, selectionUserNo: string[]) => {
        if (pageName === RECEIVER) {
            setUpdateData({
                ...updateData,
                ...{ receivers: selectionUserNo },
            });
        }

        if (pageName === TESTER) {
            setUpdateData({
                ...updateData,
                ...{ testers: selectionUserNo },
            });
        }
    };

    const transformedArray = updateData?.receivers?.map((item: any) => {
        try {
            const parsedItem = JSON.parse(item);
            return parsedItem;
        } catch (error) {
            console.error(`Error parsing JSON for item: ${item}`, error);
            return "";
        }
    });

    return (
        <div css={formDiv}>
            <Title title={title} />
            <ul>
                <li css={alignCenter}>
                    <Title title={"알림 유형"} size={"small"} />
                    <div className="flexBox">
                        <DropDown
                            data={pushCategoryDropDownData}
                            handleChange={(e) => {
                                const { value } = e.target;
                                setUpdateData({
                                    ...updateData,
                                    ...{ category: value, edit: true },
                                });
                            }}
                            defaultValue={updateData.category}
                            border={!isUpdate}
                            placeholder={""}
                            isDisabled={isDetail}
                        />
                    </div>
                </li>
                <li>
                    <Title title={"수신인 유형"} size={"small"} />
                    <ul>
                        <li>
                            <Title title={"주제"} size={"small"} />
                            <PushReceiverType
                                initData={initChkData}
                                isUpdate={isDisabledCheckbox}
                                handleSubmit={(data: any) => {
                                    setUpdateData({
                                        ...updateData,
                                        ...data,
                                    });
                                }}
                            />
                        </li>
                        <li css={alignCenter}>
                            <Title title={"개별 유저"} size={"small"} />
                            <ReceiverBox
                                receiverUserNo={transformedArray || []}
                                placeholder={"수신인을 추가해주세요 (150명 까지 가능)"}
                                pageName={RECEIVER}
                                isDetail={isDetail}
                                isUpdate={isUpdate}
                                handleUpdate={handleReceiver}
                            />
                        </li>
                    </ul>
                </li>
                <li css={alignCenter}>
                    <Title title={TESTER} size={"small"} />
                    <ReceiverBox
                        receiverUserNo={updateData.testers || []}
                        placeholder={"테스터를 추가해주세요 (10명 까지 가능)"}
                        pageName={TESTER}
                        isDetail={isDetail}
                        isUpdate={isUpdate}
                        handleUpdate={handleReceiver}
                    />
                </li>
                <li css={alignCenter}>
                    <Title title={"전송일시"} size={"small"} />
                    <div>
                        <div className="sendContainer">
                            <button
                                onClick={() => {
                                    setReserve(false);
                                    setUpdateData({ ...updateData, ...{ sendNow: true } });
                                }}
                                style={{
                                    background:
                                        !reserve ||
                                        (initData.createdAt !== undefined &&
                                            initData.reservationAt !== undefined &&
                                            initData.createdAt === initData.reservationAt)
                                            ? "#882eaf"
                                            : "#bf96ea",
                                }}
                                className="sendNow"
                            >
                                즉시 발송
                            </button>
                            <button
                                onClick={() => {
                                    setReserve(true);
                                    setUpdateData({ ...updateData, ...{ sendNow: false } });
                                }}
                                style={{
                                    background:
                                        reserve ||
                                        (initData.createdAt !== undefined &&
                                            initData.reservationAt !== undefined &&
                                            initData.createdAt !== initData.reservationAt)
                                            ? "#882eaf"
                                            : "#bf96ea",
                                }}
                                className="sendReserve"
                            >
                                예약 발송
                            </button>
                        </div>
                        {reserve && (
                            <>
                                <Calendar
                                    getUserDate={(date) => {
                                        setUpdateData({
                                            ...updateData,
                                            ...{
                                                reservationAt: ConvertDate(date, "yyyy-MM-dd HH:mm:ss"),
                                                edit: true,
                                            },
                                        });
                                    }}
                                    setDate={
                                        (updateData.reservationAt &&
                                            dateParse(updateData.reservationAt, "yyyy-MM-dd HH:mm:ss")) ||
                                        null
                                    }
                                    isDisabled={isDetail}
                                    timePicker={true}
                                />
                                {!isUpdate && <p className="info">※현재 시간에서 최소 30분 뒤부터 설정해 주세요</p>}
                            </>
                        )}
                    </div>
                </li>
                <li css={alignCenter}>
                    <Title title={"링크"} size={"small"} />
                    <div className="flexBox">
                        <Input
                            label={""}
                            placeholder={"http(s)://URL 링크 (800자 이내)"}
                            type={"text"}
                            size={"medium"}
                            handleChange={(e) => handleInputChange(e)}
                            isDisabled={isDetail}
                            errorMessage={errorMessage.link}
                            name={"link"}
                            value={updateData.link || ""}
                        />
                    </div>
                </li>
                <li css={alignCenter}>
                    <Title title={"제목"} size={"small"} />
                    <div className="flexBox">
                        <Input
                            label={""}
                            placeholder={"제목 입력 (100자 이내)"}
                            type={"text"}
                            size={"medium"}
                            handleChange={(e) => handleInputChange(e)}
                            isDisabled={isDetail}
                            errorMessage={errorMessage.title}
                            name={"title"}
                            value={updateData.title || ""}
                        />
                    </div>
                </li>
                <li>
                    <Title title={"내용"} size={"small"} />
                    <div>
                        <TextBox
                            label={""}
                            placeholder={"내용을 입력해주세요 (800자 이내)"}
                            size={"big"}
                            maxLength={800}
                            handleChange={(value, isError) => {
                                setUpdateData({
                                    ...updateData,
                                    ...{ contents: value, edit: true },
                                });
                                setErrorMessage({
                                    ...errorMessage,
                                    contents: isError,
                                });
                            }}
                            isDisabled={isDetail}
                            defaultText={updateData?.contents || ""}
                        />
                    </div>
                </li>
            </ul>
        </div>
    );
}

const formDiv = css`
    padding: 40px;
    li {
        display: flex;
        align-items: flex-start;
        .flexBox {
            display: flex;
            align-items: center;
            & > div {
                margin-right: 10px;
            }
        }
        & > div:first-of-type {
            //Title
            width: 100px;
            font-weight: 500;
        }
    }
    & > ul > li {
        padding-top: 30px;
        padding-bottom: 20px;
    }
    & > ul > li:last-of-type > div:last-of-type {
        width: 100%;
    }
    input:disabled {
        background: transparent;
    }
    textarea {
        width: 100%;
    }
    .mapButton {
        margin-right: 10px;
    }
    .info {
        color: ${Common.colors.error};
        ${Common.textStyle.body4_R12}
        margin-top: 5px;
    }
    & > ul:first-of-type {
        select:disabled {
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }
`;
const alignCenter = css`
    align-items: center !important;

    .sendContainer {
        display: flex;
        align-items: center;
        gap: 20px;

        & > button {
            padding: 5px 10px;
            color: white;
            border-radius: 4px;
            cursor: pointer;
        }
    }
`;
