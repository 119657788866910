import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import {
    IconButton,
    Table,
    Title,
    Button,
    ConfirmModal,
    PageTable,
} from "@src/components";
import { useNavigate } from "react-router-dom";
import { FormBannerType } from "./types";
import { useBannerList } from "@src/hooks/queries/useBanner";
import { useDeleteBannerData } from "@src/hooks/queries/useBannerDetail";
import { getColumns } from "./BannerListColumns";
import BannerTypeSelector from "./BannerTypeSelector";
import { useDispatch, useSelector } from "react-redux";
import { setBannerType } from "@src/reducers/bannerType";

function BannerList() {
    const [isDelete, setIsDelete] = useState<{
        isModalShow: boolean;
        deleteNo: number;
    }>({
        isModalShow: false,
        deleteNo: -1,
    });

    const value = useSelector((state: any) => state.bannerType.type);

    const navigate = useNavigate();

    const [data, setData] = useState<FormBannerType[]>([
        {
            listNo: 0,
            type: "",
            bannerNo: 0,
            bannerImage: "",
            bannerLink: "",
            bannerDate: "",
            startDate: "",
            endDate: "",
            bannerView: 0,
            order: 0,
            viewMore: 0,
            index: 0,
            testers: "",
        },
    ]);
    const [totalCount, setTotalCount] = useState(0);
    const [params, setParams] = useState({ page: 1, limit: 10, value: value });
    const {
        isLoading,
        data: bannerData = { result: [], total: 0, type: value },
        isError,
        refetch: listRefetch,
    } = useBannerList(params);
    useEffect(() => {
        if (!isLoading && !isError && bannerData) {
            setData(bannerData.result);
            if (params.page === 1) {
                setTotalCount(bannerData.total);
            }
        }
    }, [bannerData.result]);

    useEffect(() => {
        listRefetch();
    }, [params]);

    const columns = getColumns({ navigate, setIsDelete });
    const { mutate: deleteMutate } = useDeleteBannerData(value);
    const onDelete = (bannerId: number) => {
        setIsDelete({ ...isDelete, ...{ isModalShow: false } });
        deleteMutate(bannerId, {
            onError: (error) => {
                console.log(error);
            },
            onSettled: () => listRefetch(),
        });
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (!value) {
            dispatch(setBannerType({ type: "home" }));
            setParams({ page: 1, limit: 10, value: "home" });
        }
    }, []);
    return (
        <div css={listStyle}>
            <div css={titleWrapper}>
                <Title title="배너 관리" />
                <BannerTypeSelector setParams={setParams} />
            </div>
            <div css={searchDiv}>
                <Button
                    color="gd"
                    handleClick={() => {
                        navigate("/banners/create");
                    }}
                    label="배너 등록"
                    size="medium"
                />
            </div>

            <div css={tableDiv}>
                {data?.length !== 0 && (
                    <PageTable
                        initialState={{
                            pageIndex: 0,
                            pageSize: 10,
                            hiddenColumns: ["bannerNo"],
                        }}
                        columns={columns}
                        data={data}
                        FixedHeight={900}
                        totalCount={totalCount}
                        handlePageChange={(index: number) => {
                            const page = index + 1;
                            setParams({ page, limit: 10, value: value });
                        }}
                        showTotalCount={true}
                        forcePage={params.page === 1}
                    ></PageTable>
                )}
                {data?.length === 0 && (
                    <div style={{ marginTop: "20px" }}>데이터가 없습니다</div>
                )}
            </div>
            <ConfirmModal
                buttonText="확인"
                handleCancel={() =>
                    setIsDelete({ ...isDelete, ...{ isModalShow: false } })
                }
                handleOk={() => onDelete(isDelete.deleteNo)}
                isModalShow={isDelete.isModalShow}
            >
                <>정말 삭제하시겠습니까?</>
            </ConfirmModal>
        </div>
    );
}

const listStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
`;
const searchDiv = css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 20px;
`;
const tableDiv = css`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: fit-content;
    td {
        button:nth-of-type(1) {
            margin-right: 15px;
        }
    }
`;

const titleWrapper = css`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export default BannerList;
