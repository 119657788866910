import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import { Table, Title, Button } from "@src/components";
import { ConvertDate } from "@src/utils/dateUtil";
import { updateLogData } from "@src/data/ExpertsSampleData";
import { useGetUserUpdateHistory } from "@src/hooks/queries/useUserUpdateHistory";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";

interface iLogBoxContainer {
    userNo: number;
}
function LogBoxContainer({ userNo }: iLogBoxContainer) {
    const dispatch = useDispatch();
    const [logData, setLogData] = useState<any>([]);
    const [isBtnClick, setIsBtnClick] = useState(false);
    const getUserUpdateHistory = () => {
        dispatch(startLoading());
        refetch();
        setIsBtnClick(true);
    };
    const { isLoading, isError, data, refetch, isRefetching } =
        useGetUserUpdateHistory(userNo);
    useEffect(() => {
        if (!isLoading && !isError && data) {
            setLogData(data[0]);
            dispatch(endLoading());
        }
    }, [isLoading, isError, data]);
    useEffect(() => {
        if (!isRefetching) {
            dispatch(endLoading());
        }
    }, [isRefetching]);
    const columns = [
        {
            Header: "수정 일시",
            accessor: "date",
            Cell: ({ cell: { value } }: any) => <div>{ConvertDate(value)}</div>,
        },
        {
            Header: "관리자명",
            accessor: "operatorName",
            Cell: ({ cell: { value } }: any) => <div>{value}</div>,
        },
        {
            Header: "사유",
            accessor: "reason",
            Cell: ({ cell: { value } }: any) => <div>{value}</div>,
        },
    ];
    return (
        <div css={isBtnClick ? [logBoxDiv, afterBtnClick] : logBoxDiv}>
            <div>
                <Title title="수정 로그" size="small" />
                <Button
                    label="조회"
                    color={"gd"}
                    size={"medium"}
                    isDisabled={false}
                    handleClick={() => getUserUpdateHistory()}
                    deleteBtn={false}
                    defaultValue={"조회"}
                />
            </div>
            <Table
                columns={columns}
                data={logData}
                initialState={{ pageIndex: 0, pageSize: 5 }}
                FixedHeight={270}
                showNumber={false}
            />
        </div>
    );
}
const logBoxDiv = css`
    width: 100%;
    min-width: 980px;
    margin-top: 70px;
    p {
        ${Common.textStyle.title6_R14}
        margin-bottom: 20px;
    }
    & > div:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
const afterBtnClick = css`
    tbody {
        opacity: 1;
        background-color: ${Common.colors.white};
    }
`;
export default LogBoxContainer;
