export const Constants = {
    CERTIFICATION_APPLICATION: "인증 신청",
    CERTIFICATED: "인증 완료",
    CERTIFICATED_DENIED: "인증 거절",
    CERTIFICATED_STOP: "자격 정지",
    AD_APPLICATION: "광고 신청",
    AD_PROGRESSION: "광고 진행",
    AD_ENDED: "광고 만료",
    AD_STOP: "광고 정지",
    AD_WAITING: "결제 대기",
    AD_NON_APPLICATION: "미신청",
    RECEIVER: "수신인",
    TESTER: "테스터",
    REGISTER: "등록",
    TEST_PREV: "테스트 발송 준비",
    TEST_ING: "테스트 발송중",
    TEST_FAIL: "테스트 발송 실패",
    TEST_SUCCESS: "테스트 발송 완료",
    PUSH_APPLY: "발송승인",
    PUSH_ING: "발송중",
    PUSH_FAIL: "발송실패",
    PUSH_SUCCESS: "발송완료",
    TEMPLATE: "템플릿",
    SITUATION: "상황",
    STANDARD_INFORMATION: "메인 상단정보",
    EXPECTED_RETURN: "예상 수익률",
    COMMENT: "미스고 코멘트",
    DETAIL_INFORMATION: "세부 정보",
    DATE_HISTORY: "기일 내역",
    AUCTION_PRODUCT: "매각 물건",
    LESSEE: "임차인",
    REGISTERED_INFORMATION: "등기 정보",
    BUILDING_INFORMATION: "건물 정보",
    REAL_PRICE: "실거래가",
    SURROUND_INFORMATION: "주변 정보",
    CASE_ANALYSIS: "인근매각사례",
    BID_HISTORY: "입찰 이력",
    LOCATION_STATUS: "위치 및 현황",
    SEIZE_INFORMATION: "압류 정보",
    SALE_CASE: "인근 매각 사례",
    REGISTERED_INFO_PUBLIC: "등기 사항 증명서 주요 정보",
    RENT_INFO_PUBLIC: "임대차 정보",
    OCCUPANCY_RELATION: "점유 관계",
    DISTRIBUTION_OR_BOND: "배분 요구 및 채권 신고",
    BUILDING_REGIS_INFO: "건물 등기 정보",
    LAND_REGIS_INFO: "토지 등기 정보",
    SUMMARY_INFO: "간략정보",
    SKIP_BID_HISTORY: "유찰내역",
    DEFAULT_INFO: "기본정보",
    CASE_INFO: "사건개요",
    ITEM_INFO: "물건정보",
    SALES_STATUS: "매각물건현황",
    RIGHTS_ANALYSIS: "권리분석",
};
