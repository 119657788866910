import React, { useMemo } from "react";
import ReactPaginate from "react-paginate";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import "./Table.css";
import { ConvertPrice } from "@src/utils/units";

interface ICompositionTable {
    columns: any;
    data: any;
    FixedHeight?: number;
    showCheckbox?: boolean;
    showTotalCount?: boolean;
    showPagination?: boolean; // pagination show/hide
    totalCount: number;
    onPageChange: ({ selected }: any) => void;
    pageCount: number;
    showUserCount?: number;
    showClickCount?: number;
    forcePageNumber: number;
    getTableBodyProps: () => object;
    getTableProps: () => object;
    page: [];
    prepareRow: (row: any) => void;
    headerGroups: [];
    selectedFlatRows?: []; // checkbox 체크 유무
    showNumber?: boolean;
}

function CompositionTable({
    columns,
    FixedHeight = 250,
    showTotalCount = false,
    showPagination = true,
    totalCount,
    onPageChange,
    pageCount,
    showUserCount,
    showClickCount,
    forcePageNumber,
    getTableBodyProps,
    getTableProps,
    page,
    prepareRow,
    headerGroups,
    showCheckbox = false,
    showNumber = true,
}: ICompositionTable) {
    const renderPagination = useMemo(() => {
        return (
            <div>
                <ReactPaginate
                    nextLabel=">"
                    previousLabel="<"
                    onPageChange={onPageChange}
                    pageCount={pageCount}
                    breakLabel="..."
                    activeClassName="pagination-current"
                    containerClassName="pagination-wrapper"
                    pageClassName="pagination-item"
                    previousClassName="pagination-arrow"
                    nextClassName="pagination-arrow"
                    forcePage={
                        forcePageNumber > pageCount // 삭제로 페이지가 사라지면, 이전 페이지 번호로 강제 업데이트
                            ? pageCount - 1
                            : forcePageNumber
                    }
                />
            </div>
        );
    }, [pageCount, forcePageNumber]);

    const tableBody = (
        <tbody {...getTableBodyProps()}>
            {page.map((row: any, index: number) => {
                prepareRow(row);

                return (
                    <tr key={index} css={trStyle} {...row.getRowProps()}>
                        {row.cells.map((cell: any, index: number) => {
                            return (
                                <td key={index} css={tdStyle} {...cell.getCellProps()}>
                                    {cell.render("Cell")}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );

    const noData = (
        <tbody css={noDataStyle}>
            <tr css={trStyle} style={{ height: `${FixedHeight}px` }}>
                <td colSpan={columns.length + showCheckbox + showNumber} css={tdStyle}>
                    데이터 없음
                </td>
            </tr>
        </tbody>
    );



    return (
        <>
            <div css={tableStyle}>

                {showTotalCount && <div css={countDiv}>총 {ConvertPrice(totalCount)}건</div>}
                <div css={countDivContainer}>
                    {showUserCount && <div>총 회원 수 {ConvertPrice(showUserCount)}건</div>}
                    {showClickCount && <div>총 클릭 수 {ConvertPrice(showClickCount)}건</div>}
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <table {...getTableProps()} className="compostionTable">
                        <thead css={theadStyle}>
                            {headerGroups.map((headerGroup: any, index: number) => (
                                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column: any, index: number) => (
                                        <th key={index} css={thStyle}>
                                            {column.render("Header")}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <>{page.length > 0 ? tableBody : noData}</>
                    </table>
                </div>
                {showPagination && totalCount >= 1 && renderPagination}
            </div>
            {/* debugging 확인용 */}
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            pageCount,
                            totalCount,
                        },
                        null,
                        2,
                    )}
                </code>
            </pre> */}
        </>
    );
}

export default CompositionTable;

const tableStyle = css`
    width: 100%;
    display: flex;
    flex-direction: column;

    table {
        width: 100%;
        /* width: fit-content; */
        border: 1px solid ${Common.colors.gray100};
    }
    & > div:nth-of-type(1) {
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    & > div:nth-of-type(2) {
        align-items: flex-end;
        padding-bottom: 30px;
    }
`;
const theadStyle = css`
    border-top: 1px solid ${Common.colors.gray100};
    border-bottom: 1px solid ${Common.colors.gray100};
    height: 40px;
    text-align: center;
`;

const thStyle = css`
    ${Common.textStyle.text1_R13}
    vertical-align: middle;
    display: table-cell;
    width: fit-content;
    border: 1px solid ${Common.colors.gray100};
    border-collapse: collapse;
    white-space: nowrap;
    padding: 0px 0.25rem;
`;

const trStyle = css`
    height: 40px;
    border-bottom: 1px solid ${Common.colors.gray100};
    &:hover {
        background-color: ${Common.colors.gray100};
    }
`;

const tdStyle = css`
    white-space: nowrap;
    word-break: keep-all;
    text-align: center;
    ${Common.textStyle.text1_R13}
    vertical-align: middle;
    display: table-cell;
    border: 1px solid ${Common.colors.gray100};
    border-collapse: collapse;

    & > div {
        /* white-space: wrap; */
        word-break: keep-all;
        white-space: nowrap;
        padding: 0px 0.25rem;
        margin: 0 auto;
    }
`;

const countDiv = css`
    ${Common.textStyle.body3_R14}
    margin: 15px;
`;

const countDivContainer = css`
    display: flex;
    gap: 20px;
    font-size: 14px;
    margin: 15px;
`

const noDataStyle = css`
    background-color: ${Common.colors.gray100};
    opacity: 0.6;
`;
