import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { PushType, BasePushType } from "@src/pages/notify/types";
import { Title, DropDown, Button, ConfirmModal } from "@src/components";
import { arrayToDropdown } from "@src/utils/dropdownUtil";
import { PushCodeType } from "@src/constants/PushStatus";
import { Common } from "@src/styles/Common";
import { usePushDetailData } from "@src/hooks/queries/usePushDetail";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "@src/reducers/loading";
import { useParams } from "react-router-dom";
import { usePushList, usePushMessage } from "@src/hooks/queries/usePush";
import { Constants } from "@src/constants/Constants";
import PushForm from "./PushForm";
import BlockPageModal from "@src/pages/popup/BlockPageModal";
import PushLogBox from "@src/pages/notify/containers/PushLogBox";
import { dateParse } from "@src/utils/dateUtil";

const PushDetail = () => {
    const { id = "0" } = useParams();
    const dispatch = useDispatch();
    const [showBlockModal, setShowBlockModal] = useState<boolean>(false);
    const pushStatus = arrayToDropdown([
        Constants.REGISTER,
        Constants.TEST_PREV,
        Constants.TEST_ING,
        Constants.TEST_FAIL,
        Constants.TEST_SUCCESS,
        Constants.PUSH_APPLY,
        Constants.PUSH_ING,
        Constants.PUSH_FAIL,
        Constants.PUSH_SUCCESS,
    ]);
    const [data, setData] = useState<PushType>({
        edit: false,
        status: 100,
        originReservationAt: "",
        createdAt: "",
    });
    const [pushStatusUpdate, setPushStatusUpdate] = useState("");
    const [isTestModalShow, setIsTestModalShow] = useState(false);
    const [isLiveModalShow, setIsLiveModalShow] = useState(false);
    const { isLoading, data: pushData, refetch: singleRefetch, isError } = usePushDetailData(id, true);
    useEffect(() => {
        if (!isLoading && !isError) {
            if (pushData) {
                console.log(pushData);
                setData(pushData);
                setPushStatusUpdate(pushData?.pushStatus || "등록");
                setShowBlockModal(false);
            } else {
                setShowBlockModal(true);
            }
        }
    }, [isLoading, isError, pushData]);

    const [updateData, setUpdateData] = useState<PushType>({
        edit: false,
        status: 100,
        originReservationAt: "",
        createdAt: "",
    });

    const [isTestPush, setIsTestPush] = useState(false);
    const [isLiveCancel, setIsLiveCancel] = useState(false);
    const [isLivePush, setIsLivePush] = useState(false);
    const [availablePushDate, setAvailablePushDate] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { Register, TestReady, Testing, TestSuccess, TestFailed, PushReady, PushSuccess, PushFailed, Pushing } =
        PushCodeType;
    const PushStatus = data?.status;
    useEffect(() => {
        setIsTestPush([Register, TestReady, Testing, TestFailed].includes(PushStatus));
        setIsLiveCancel([PushReady].includes(PushStatus));
        setIsLivePush([TestSuccess, PushFailed, PushSuccess].includes(PushStatus));

        const d = new Date();
        const toDay = dateParse(
            `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`,
            "yyyy-MM-dd HH:mm",
        );
        const setDay = dateParse(data.originReservationAt, "yyyy-MM-dd HH:mm:ss");

        if ([Register, TestReady, PushReady].includes(PushStatus)) {
            if (setDay >= toDay) {
                setAvailablePushDate(true);
                setErrorMessage("");
            } else {
                setAvailablePushDate(false);
                setErrorMessage("전송일시는 현재 시간에서 최소 30분 뒤부터 가능합니다. 전송일시를 확인해주세요.");
            }
        } else {
            setAvailablePushDate(true);
            setErrorMessage("");
        }
    }, [data]);

    const { mutate: pushMutate } = usePushMessage(id);
    const { refetch: listRefetch } = usePushList({
        page: 1,
        limit: 10,
    });

    const TestButton = () => {
        let disabled = [TestReady, Testing, TestSuccess, TestFailed].includes(PushStatus);

        if (!availablePushDate) {
            // 발송일시가 지났을 경우 비활성화
            disabled = true;
        }

        return (
            <div className="buttonBox">
                <div css={pageErrorBox}>{errorMessage}</div>
                <Button
                    label={"테스트 발송"}
                    color={"gd"}
                    size="medium"
                    isDisabled={disabled}
                    handleClick={() => setIsTestModalShow(true)}
                    deleteBtn={false}
                    defaultValue={""}
                />
                <ConfirmModal
                    isModalShow={isTestModalShow}
                    handleOk={() => {
                        setIsTestModalShow((prev) => !prev);
                        dispatch(startLoading());
                        pushMutate(
                            { type: "test" },
                            {
                                onSuccess: () => {
                                    setIsLivePush(true);
                                    setIsLiveCancel(false);
                                    setIsTestPush(false);
                                    singleRefetch();
                                },
                                onError: (error: any) => {
                                    setErrorMessage(
                                        "전송일시는 현재 시간에서 최소 30분 뒤부터 가능합니다. 전송일시를 확인해주세요.",
                                    );
                                },
                                onSettled: () => {
                                    listRefetch();
                                    dispatch(endLoading());
                                },
                            },
                        );
                    }}
                    handleCancel={() => {
                        setIsTestModalShow((prev) => !prev);
                    }}
                    buttonText="테스트"
                >
                    테스트하시겠습니까?
                </ConfirmModal>
            </div>
        );
    };

    const LiveButton = () => {
        let disabled = [Pushing, PushSuccess, PushFailed].includes(PushStatus);
        if (!availablePushDate) {
            // 발송일시가 지났을 경우 비활성화
            disabled = true;
        }
        return (
            <div className="buttonBox">
                <div css={pageErrorBox}>{errorMessage}</div>
                <Button
                    label={"발송 승인"}
                    color={"gd"}
                    size="medium"
                    isDisabled={disabled}
                    handleClick={() => setIsLiveModalShow(true)}
                    deleteBtn={false}
                    defaultValue={""}
                />
                <ConfirmModal
                    isModalShow={isLiveModalShow}
                    handleOk={() => {
                        setIsLiveModalShow((prev) => !prev);
                        dispatch(startLoading());
                        pushMutate(
                            {
                                type: "live",
                                reservation_at: data.reservationAt,
                            },
                            {
                                onSuccess: () => {
                                    setIsLiveCancel(true);
                                    setIsLivePush(false);
                                    setIsTestPush(false);
                                    singleRefetch();
                                },
                                onError: (error: any) => {
                                    setErrorMessage(
                                        "전송일시는 현재 시간에서 최소 30분 뒤부터 가능합니다. 전송일시를 확인해주세요.",
                                    );
                                },
                                onSettled: () => {
                                    listRefetch();
                                    dispatch(endLoading());
                                },
                            },
                        );
                    }}
                    handleCancel={() => {
                        setIsLiveModalShow((prev) => !prev);
                    }}
                    buttonText="승인"
                >
                    승인하시겠습니까?
                </ConfirmModal>
            </div>
        );
    };

    const CancelButton = () => {
        return (
            <div className="buttonBox">
                <div css={pageErrorBox}>{errorMessage}</div>
                <Button
                    label={"발송 취소"}
                    color={"red"}
                    size="medium"
                    isDisabled={false}
                    handleClick={() => setIsLiveModalShow(true)}
                    deleteBtn={false}
                    defaultValue={""}
                />
                <ConfirmModal
                    isModalShow={isLiveModalShow}
                    handleOk={() => {
                        setIsLiveModalShow((prev) => !prev);
                        dispatch(startLoading());
                        pushMutate(
                            {
                                type: "cancel",
                                reservation_at: data.reservationAt,
                            },
                            {
                                onSuccess: () => {
                                    setIsLiveCancel(false);
                                    setIsLivePush(true);
                                    setIsTestPush(false);
                                    singleRefetch();
                                },
                                onError: (error: any) => {
                                    setErrorMessage(
                                        "전송일시는 현재 시간에서 최소 30분 뒤부터 가능합니다. 전송일시를 확인해주세요.",
                                    );
                                },
                                onSettled: () => {
                                    listRefetch();
                                    dispatch(endLoading());
                                },
                            },
                        );
                    }}
                    handleCancel={() => {
                        setIsLiveModalShow((prev) => !prev);
                    }}
                    buttonText="확인"
                >
                    발송 취소를 하시겠습니까?
                </ConfirmModal>
            </div>
        );
    };

    return (
        <div css={detailDiv}>
            <PushForm
                isUpdate={false}
                title={`PUSH 전송 상세`}
                initData={data}
                handleUpdate={setUpdateData}
                isDetail={true}
            />
            <div css={logDiv}>
                <PushLogBox pushNo={id} />
            </div>
            <div css={optionDiv}>
                <Title title="상태" size={"small"} />
                <div>
                    <DropDown
                        data={pushStatus}
                        handleChange={(e) => {
                            const { value } = e.target;
                            setPushStatusUpdate(value);
                            setUpdateData({ ...updateData, edit: true });
                        }}
                        defaultValue={pushStatusUpdate}
                        border={isTestPush}
                        placeholder={""}
                        isDisabled={true}
                    />
                </div>
            </div>
            {!isTestPush && (
                <p className="notifyBox">
                    알림 등록후, 테스트 발송이 완료 되어야 예약된 시간에 정상적으로 발송 가능합니다.
                    <br />
                    테스트를 실행 하지 않았거나 테스트 실패시 하단의 테스트발송 버튼을 눌러 테스트를 진행해주세요
                </p>
            )}
            {isTestPush && TestButton()}
            {isLiveCancel && CancelButton()}
            {isLivePush && LiveButton()}
            <BlockPageModal isShowModal={showBlockModal} url="/notify/push" />
        </div>
    );
};

const detailDiv = css`
    padding-right: 40px;
    padding-bottom: 40px;
    width: 100%;
    .notifyBox {
        ${Common.textStyle.body4_R12}
        margin: 10px 0;
        color: ${Common.colors.gray500};
        text-align: right;
    }
    .buttonBox {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        button {
            margin-left: 30px;
        }
    }
`;
const optionDiv = css`
    padding-left: 40px;
    padding-top: 40px;
    display: flex;
    align-items: center;
    & > div:first-of-type {
        width: 100px;
    }
    select:disabled {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
`;

const logDiv = css`
    padding-left: 40px;
    margin-bottom: 40px;
`;

const pageErrorBox = css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;
    ${Common.textStyle.body2_R16}
    color: ${Common.colors.error};
`;

export default PushDetail;
